import { IdentityTypeNameDecorator } from '../decorators';
import { BaseIdentity } from '../base-identity';
import { Expose } from '../../serialization/class-transformer/decorators';

@IdentityTypeNameDecorator('UserIdentity')
export class UserIdentity extends BaseIdentity {

    private _id: number;

    @Expose()
    get id(): number {
        return this.getValue<number>(() => this._id, 'id');
    }
    set id(value: number) {
        this.setValue<number>(() => { this._id = value; }, value, 'id');
    }
}


<div class="checkbox" [class.is-enabled]="!isDisabled" [class.is-disabled]="isDisabled" [class.is-checked]="value">
    <input #check [disabled]="isDisabled || readOnly" [readOnly]="readOnly" [attr.aria-checked]="value" type="checkbox"
        [(ngModel)]="value" (ngModelChange)="valueChange.emit(value)" [attr.id]="id" [attr.name]="id"
        [attr.aria-label]="title">
    <label [attr.title]="title" class="checkbox-label" [attr.for]="id">
        <div class="checkbox-checkbox" [class.read-only]="readOnly">
            <i data-icon-name="CheckMark" [class.read-only]="readOnly" aria-hidden="true"
                class="ms-Icon--CheckMark checkmark"></i>
        </div>
        <span aria-hidden="true" [class.read-only]="readOnly" class="checkbox-text">{{ label }}</span>
    </label>
</div>

import { Injectable } from '@angular/core';
import { ZoomApiClientInterface } from './zoom-api-client.interface';
import { CacheOptionsInterface, WebApiServiceAgent } from '../web-api-service-agent';
import { Observable } from 'rxjs';
import { MetaDataResponse } from '../../responses/meta-data-response';
import { MetaDataRequest } from '../../requests/meta-data-request';
import { FindRequest } from '../../requests/find-request';
import { FindValuesOptions } from '../../domain-models/find-options/find-values-options';
import { FindValuesResponse } from '../../responses/find-values-response';
import { MetaDataExternalRequest } from '../../requests/meta-data-external-request';
import { FindValuesExternalOptions } from '../../domain-models/find-options/find-values-external-options';
import { FindValuesRequest } from '../../requests/find-values-request';
import { FindValuesExternalRequest } from '../../requests/find-values-external-request';
import { ZoomDataListDto } from '../../domain-models/zoom/dto/zoom-data-list.dto';
import { ServiceInfoIdentity } from '../../domain-models/service-info/service-info.identity';
import { GenericServiceRequest } from '../../requests/generic-service-request';
import { GenericServiceResponse } from '../../responses/generic-service-response';
import { ZoomIdentity } from '../../domain-models/zoom/zoom.identity';
import { ZoomDataDto } from '../../domain-models/zoom/dto/zoom-data.dto';
import { UserZoomIdentity } from '../../domain-models/zoom/user-zoom.identity';
import { ServiceResponse } from '../../responses/service-response';
import { ZoomConfigurationDto } from '../../domain-models/zoom/dto/zoom-configuration.dto';
import { PinZoomToDashboardDto } from '../../domain-models/zoom/dto/pin-zoom-to-dashboard.dto';
import { AutoCompleteExternalOptions } from '../../domain-models/autocomplete/auto-complete-external-options';
import { AutoCompleteRequest } from '../../requests/auto-complete-request';

@Injectable()
export class ZoomApiClient implements ZoomApiClientInterface {
    
    private internalRootDomainModelName: string;
    get rootDomainModelName() {
        return this.internalRootDomainModelName;
    }
    
    protected setPredefinedQueryUri = 'SetPredefinedQuery';
    protected getMetaDataRequestUri = 'GetMetaData';
    protected getMetaDataExternalRequestUri = 'GetMetaData';
    protected getPredefinedQueryUri = 'GetPredefinedQuery';
    protected zoomQueryExportUri = 'ZoomQueryExport';
    protected getZoomDataListUri = 'GetZoomDataList'
    protected setZoomPreferredUri = 'SetZoomPreferred'
    protected removeZoomPreferredUri = 'RemoveZoomPreferred'
    protected setZoomDataUri = 'SetZoomData'
    protected addZoomDataUri = 'AddZoomData'
    protected editZoomDataUri = 'EditZoomData'
    protected getZoomConfigurationUri = 'GetZoomConfiguration'
    protected userHasFeatureRequestUri = 'UserHasFeature';
    protected findValuesRequestUri = 'FindValues';
    protected pinZoomToDashboardUri = 'PinZoomToDashboard';
    
    constructor(protected readonly agent: WebApiServiceAgent) { }

    getMetaData(request: MetaDataRequest): Observable<MetaDataResponse> {
        return this.agent.invokePostWithResponse<
            MetaDataRequest, MetaDataResponse>(
                this.getMetaDataRequestUri, request, MetaDataResponse);
    }

    getMetaDataExternal(request: MetaDataExternalRequest): Observable<MetaDataResponse> {

        return this.agent.invokePostWithResponse<
            MetaDataExternalRequest, MetaDataResponse>(
                this.getMetaDataExternalRequestUri, request, MetaDataResponse, true);
    }

    initRootDomainModelName(rootDomainModelName: string) {
        this.internalRootDomainModelName = rootDomainModelName;
        this.agent.rootModelName = rootDomainModelName;
    }

    setPredefinedQueryAsync(request: any): Observable<any> {
        return this.agent.invokePostWithResponse<any, any>
            (this.setPredefinedQueryUri, request, Object);
    }


    autoComplete(
        autoCompleteOptions: AutoCompleteExternalOptions,
        cacheOptions: CacheOptionsInterface = null,
        retryRequest = 0
    ): Observable<FindValuesResponse> {

        // Default for autoComplete
        cacheOptions = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,
            ...cacheOptions
        };

        const request = new AutoCompleteRequest();
        request.requestData = autoCompleteOptions;
        const response: FindValuesResponse = new FindValuesResponse(FindValuesResponse);
        return this.agent.invokePostWithResponseWithInstance
            <AutoCompleteRequest, FindValuesResponse>(
                'AutoComplete', 
                request, 
                response, 
                true,
                null,
                null,
                null,
                cacheOptions,
                retryRequest
            );
    }


    getPredefinedQueryAsync(request: any): Observable<any> {
        return this.agent.invokePostWithResponse<any, any>
            (this.getPredefinedQueryUri, request, Object);
    }

    zoomQueryExportAsync(request: any): Observable<any> {
        return this.agent.invokePostWithResponse<any, any>(
            this.zoomQueryExportUri, request, Object);
    }

    getZoomConfiguration(
        identity: ZoomIdentity,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
    ): Observable<GenericServiceResponse<ZoomConfigurationDto>> {
        const request: GenericServiceRequest<ZoomIdentity> = new GenericServiceRequest<ZoomIdentity>();
        request.requestData = identity;
        const response: GenericServiceResponse<ZoomConfigurationDto> = new GenericServiceResponse<ZoomConfigurationDto>(ZoomConfigurationDto);
        return this.agent.invokePostWithResponseWithInstance<
            GenericServiceRequest<ZoomIdentity>, 
            GenericServiceResponse<ZoomConfigurationDto>>(
                this.getZoomConfigurationUri, 
                request, 
                response,
                true,
                null,
                null,
                null,
                cacheOptions
            )
    }

    editZoomDataAsync(
        zoomData: ZoomDataDto,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
    ): Observable<GenericServiceResponse<ZoomDataDto>> {
        const request: GenericServiceRequest<ZoomDataDto> = new GenericServiceRequest<ZoomDataDto>();
        request.requestData = zoomData;
        const response: GenericServiceResponse<ZoomDataDto> = new GenericServiceResponse<ZoomDataDto>(ZoomDataDto);
        return this.agent.invokePostWithResponseWithInstance<
            GenericServiceRequest<ZoomDataDto>, GenericServiceResponse<ZoomDataDto>>(
                this.editZoomDataUri, 
                request, 
                response,
                true,
                null,
                null,
                null,
                cacheOptions
            )
    }

    setZoomDataAsync(
        zoomConfiguration: ZoomConfigurationDto,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
    ): Observable<GenericServiceResponse<ZoomIdentity>> {
        const request: GenericServiceRequest<ZoomConfigurationDto> = new GenericServiceRequest<ZoomConfigurationDto>();
        request.requestData = zoomConfiguration;
        const response: GenericServiceResponse<ZoomIdentity> = new GenericServiceResponse<ZoomIdentity>(ZoomIdentity);
        return this.agent.invokePostWithResponseWithInstance<
            GenericServiceRequest<ZoomConfigurationDto>, GenericServiceResponse<ZoomIdentity>>(
                this.setZoomDataUri, 
                request, 
                response,
                true,
                null,
                null,
                null,
                cacheOptions
            )
    } 

    removeZoomPreferredAsync(
        identity: ZoomIdentity,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
    ): Observable<ServiceResponse> {
        const request: GenericServiceRequest<ZoomIdentity> = new GenericServiceRequest<ZoomIdentity>();
        request.requestData = identity;
        return this.agent.invokePostWithResponse<
            GenericServiceRequest<ZoomIdentity>, ServiceResponse>(
                this.removeZoomPreferredUri, 
                request, 
                ServiceResponse,
                true,
                null,
                null,
                null,
                cacheOptions
            )
    }

    setZoomPreferredAsync(
        identity: ZoomIdentity,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
    ): Observable<GenericServiceResponse<UserZoomIdentity>> {
        const request: GenericServiceRequest<ZoomIdentity> = new GenericServiceRequest<ZoomIdentity>();
        request.requestData = identity;
        const response: GenericServiceResponse<UserZoomIdentity> = new GenericServiceResponse<UserZoomIdentity>(UserZoomIdentity);
        return this.agent.invokePostWithResponseWithInstance<
            GenericServiceRequest<ZoomIdentity>, GenericServiceResponse<UserZoomIdentity>>(
                this.setZoomPreferredUri, 
                request, 
                response,
                true,
                null,
                null,
                null,
                cacheOptions
            )
    }

    getZoomDataListAsync(
        identity: ServiceInfoIdentity,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
    ): Observable<GenericServiceResponse<ZoomDataListDto>> {
        const request: GenericServiceRequest<ServiceInfoIdentity> = new GenericServiceRequest<ServiceInfoIdentity>();
        request.requestData = identity;
        const response: GenericServiceResponse<ZoomDataListDto> = new GenericServiceResponse<ZoomDataListDto>(ZoomDataListDto);
        return this.agent.invokePostWithResponseWithInstance<
            GenericServiceRequest<ServiceInfoIdentity>, GenericServiceResponse<ZoomDataListDto>>(
                this.getZoomDataListUri, 
                request, 
                response,
                true,
                null,
                null,
                null,
                cacheOptions
            )
    }

    findValuesAsync(entityToLookUp: string, findOptions: FindValuesOptions, entityToLookUpFullName: string): Observable<FindValuesResponse> {
        if (this.agent.rootModelName === entityToLookUp) {
            const request = new FindValuesRequest();
            request.requestData = findOptions;
            return this.agent.invokePostWithResponse<
                FindRequest, FindValuesResponse>(
                    this.findValuesRequestUri, request, FindValuesResponse);
        } else {
            const request = new FindValuesExternalRequest();
            const requestData = new FindValuesExternalOptions(findOptions);
            requestData.fullRootModelName = entityToLookUpFullName;
            request.requestData = requestData;
            return this.agent.invokePostWithResponse<
                FindValuesExternalRequest, FindValuesResponse>(
                    this.findValuesRequestUri, request, FindValuesResponse, true);
        }
    }

    userHasFeatureAsync(featureCodes: Array<string>): Observable<any> {
        const request = new Object() as any;
        request.featureCodes = new Array<string>();
        featureCodes.forEach(element => {
            request.featureCodes.push(element);
        });

        return this.agent.invokePostWithResponse<any, any>(
            this.userHasFeatureRequestUri, request, Object);
    }

    pinZoomToDashboardAsync(
        pinZoomToDashboard: PinZoomToDashboardDto,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
    ): Observable<ServiceResponse> {
        const request: GenericServiceRequest<PinZoomToDashboardDto> = new GenericServiceRequest<PinZoomToDashboardDto>();
        request.requestData = pinZoomToDashboard;
        return this.agent.invokePostWithResponse<
            GenericServiceRequest<PinZoomToDashboardDto>, ServiceResponse>(
                this.pinZoomToDashboardUri, 
                request, 
                ServiceResponse,
                true,
                null,
                null,
                null,
                cacheOptions
            )
    }
}

import { PropertyViewModel } from '../../../lib/view-models/property-view-model';
import { PropertyViewModelInitializationInfo } from '../property-view-model-initialization-info';

export class GuidPropertyViewModel extends PropertyViewModel<string> {

    override get value(): string {
        // Casistica in cui abbiamo il decoratore custom property view model impostato
        if (this._isCustom && this.customGetter == null) {
            throw new Error('You must implement value getter');
        }
        // Casistica in cui viene ridefinito il custom getter
        // Di default viene definito in tutti gli external view model
        return this.getValue();
    }
    override set value(updatedValue: string) {
        this.setValueAsync(updatedValue);
    }

    constructor(initInfo: PropertyViewModelInitializationInfo, valueIsNullable: boolean) {
        super(initInfo, valueIsNullable);

        // di defualt il guid parte sempre disabilitato
        this.isEnabled = false;
    }

    override async setValueAsync(updatedValue: string) {
        if (this._isCustom && this.customSetter == null) {
            throw new Error('You must implement value setter');
        }

        // Check undefined and null
        if (this.value != updatedValue) {
            if (this.customSetter != null) {
                await this.customSetter(updatedValue);

                if (this.model != null) {
                    this.validate();
                } else if (this.parent != null && this.parent.validate) {
                    this.parent.validate();
                }

                this.onPropertyChanged(this.bindedValuePropertyName);
            } else {
                // quano faccio il sync model scatta onPropertyChanged partendo dal modello
                this.syncModel(updatedValue);

                if (this.model != null) {
                    this.validate();
                } else if (this.parent != null && this.parent.validate) {
                    this.parent.validate();
                }
            }
        }
    }

    setDefaultValueFromLayoutMetaData(value: string) {
        // quando è un guid non esiste la gestione del default value da template
    }
    
    getFormattedValue(updatedValue: string) {
        // Uso Nullish Coalescing - richiede Typescrypt >= 3.7
        // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-7.html#nullish-coalescing
        return updatedValue ?? '';
    }
}

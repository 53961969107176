export enum TenantType {
    /**
     * Provider: gestisce il sistema
     */
    Provider = 'prv',
    /**
     * Partner: rivenditore dei prodotti del sistema
     */
    Partner = 'prt',
    /**
     * Azienda: utente finale del sistema
     */
    EndUser = 'end'
}

import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { BaseCellRendererComponent } from './base_cell_renderer.component';
import { EnumPropertyViewModel, NEnumPropertyViewModel } from '../../../../view-models/base-type/enum-property-view-model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopperHelper } from '@nts/std/src/lib/utility';
import { NgxPopperjsDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';

// create your cellEditor as a Angular component
@UntilDestroy()
@Component({
    selector: 'nts-enum-text-box-renderer-cell',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgxPopperjsModule,
        NgFor,
        NgIf,
        AsyncPipe
    ],
    template: `
        <span>
            <div *ngIf="!params?.data" class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </span>
        <div class="read-template" *ngIf="params?.data">
            <div #popperError="popper"
                [popper]="tooltipErrTemplate"
                popperAppendTo="body"
                popperApplyClass="error"
                [popperPreventOverflow]="false"
                [popperHideOnScroll]="true"
                [popperDisabled]="!propertyViewModel?.hasErrors"
                [popperTrigger]="ngxPopperjsTriggers.hover"
                [popperPlacement]="ngxPopperjsPlacements.TOP"
                [style.height.px]="cellHeight" class="renderer-cell">
                <div>{{ value }}</div>
            </div>

            <popper-content #tooltipErrTemplate>
                <div *ngFor="let item of propertyViewModel?.errors$ | async">
                    {{ item }}
                </div>
            </popper-content>
        </div>`
})
export class EnumCellRendererComponent extends BaseCellRendererComponent implements OnDestroy {

    @ViewChild('popperError', { static: false }) popperError!: NgxPopperjsDirective;

    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;

    get value() {
        if (this.enumPropertyViewModel) {
            for (let i = 0; i < this.enumPropertyViewModel.valueDescriptions.length; i++) {
                if (this.enumPropertyViewModel.valueDescriptions[i].key === this.enumPropertyViewModel.currentItem) {
                    return this.enumPropertyViewModel.valueDescriptions[i].description;
                }
            }
        }
        return '';
    }

    private destroy$: Subject<boolean> = new Subject<boolean>();

    private get enumPropertyViewModel(): EnumPropertyViewModel | NEnumPropertyViewModel {
        if (this.propertyViewModel instanceof EnumPropertyViewModel
            || this.propertyViewModel instanceof NEnumPropertyViewModel) {
            return this.propertyViewModel;
        }
        return null;
    }

    constructor(
        private readonly cd: ChangeDetectorRef,
        public readonly el: ElementRef,
        private readonly renderer: Renderer2) {
        super();
    }

    override agInit(params: any): void {
        super.agInit(params);
        if (this.params?.data) {

            (this.propertyViewModel as EnumPropertyViewModel).propertyChanged.pipe(takeUntil(this.destroy$)).subscribe(() => {
                this.cd.detectChanges();
            });

            this.propertyViewModel.onFocusRequested.pipe(
                untilDestroyed(this),
            ).subscribe(() => {
                this.el.nativeElement.parentElement.parentElement.scrollIntoView();
                if (this.propertyViewModel?.hasErrors) {
                    PopperHelper.show(this.popperError);
                }
            });
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        // Now let's also unsubscribe from the subject itself:
        this.destroy$.unsubscribe();
    }
}

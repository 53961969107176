<ng-template *ngIf="propertyViewModel?.securityAccess == null; then baseComponent else securityComponent"></ng-template>

<ng-template #baseComponent>
    <nts-base-date-text-box 
        #baseDateTextBox 
        (onDateValueChange)="onDateValueChange($event)"
        (onDateFocus)="onDateFocus($event)" 
        (onDateSelect)="onDateSelect($event)"
        (onDateBlur)="onDateBlur()"
        (onFinishEditing)="onFinishEditing.emit($event)" 
        [valueDate]="valueDate" 
        [defaultBorderColor]="propertyViewModel.defaultColor"
        [activeBorderColor]="propertyViewModel.activeColor"
        [hoverBorderColor]="propertyViewModel.hoverColor"
        [appendTo]="appendTo"
        [listenClickOutside]="listenClickOutside" 
        [isRequired]="!isNullable" 
        [showTime]="propertyViewModel.showTime"
        [showButtonBar]="showButtonBar"
        [initialChar]="initialChar"
        [isDisabled]="isDisabled || !propertyViewModel.isEnabled || propertyViewModel?.securityAccess != null"
        [errorList]="propertyViewModel?.errors$ | async"
        [showErrorTooltip]="showErrorTooltip"
        [showErrorBorder]="showErrorBorder"
        [maskSettings]="maskSettings" [title]="propertyViewModel.formattedValue"
        [customCommandList]="customCommandsName"></nts-base-date-text-box>
</ng-template>

<ng-template #securityComponent>
    <nts-base-security-text-box #baseSecurityTextBox [accessMode]="propertyViewModel.securityAccess"
        [value]="initialValue != null ? initialValue : propertyViewModel.formattedValue"></nts-base-security-text-box>
</ng-template>

<div class="modal-header" mat-dialog-title cdkDrag [cdkDragFreeDragPosition]="dragPosition"
cdkDragHandle
cdkDragRootElement=".cdk-overlay-pane">
  <h4 class="modal-title">
    {{ viewModel?.modalTitle }}
  </h4>
  <div class="modal-header-commands">
    <nts-text-button class="expand-command only-desktop" [icon]="'expand'" (onClicked)="toggleMaximize()" *ngIf="maximizable && !maximized"></nts-text-button>
    <nts-text-button class="collapse-command only-desktop" [icon]="'collapse'" (onClicked)="toggleMaximize()" *ngIf="maximizable && maximized"></nts-text-button>
    <nts-text-button class="close-command" [icon]="'cancel'" (onClicked)="closeModal()"></nts-text-button>
  </div>
</div>

<div class="modal-body" mat-dialog-content [class.has-footer]="viewModel?.showFooter">
  <div #documentPlaceHolder></div>
</div>
  
<div *ngIf="viewModel?.showFooter" class="modal-footer" mat-dialog-actions>

  <nts-text-button *ngIf="cancelCommand" class="modal-footer-button"
    [title]="cancelCommand.tooltip"
    [label]="cancelCommand.displayName"
    (onClicked)="cancelCommand.execute()" 
    [isDisabled]="!(cancelCommand.canExecute$ | async)">
    {{ cancelCommand.displayName }}
  </nts-text-button>

  <ng-container *ngFor="let command of commands">
    <div *ngIf="command.internalCommand.isDefault; then filledButton else textButton"></div>
    
    <ng-template #filledButton>
      <nts-filled-button *ngIf="command.internalCommand.isVisible$ | async" class="modal-footer-filled-button"
        [title]="command.internalCommand.tooltip"
        [label]="command.internalCommand.displayName"
        [type]="3"
        (onClicked)="command.execute(command)" [isDisabled]="!(command.canExecute$ | async)">
        {{ command.internalCommand.displayName }}
      </nts-filled-button>
    </ng-template>

    <ng-template #textButton>
      <nts-text-button *ngIf="command.internalCommand.isVisible$ | async" class="modal-footer-text-button"
        [title]="command.internalCommand.tooltip"
        [label]="command.internalCommand.displayName"
        (onClicked)="command.execute(command)" [isDisabled]="!(command.canExecute$ | async)">
        {{ command.internalCommand.displayName }}
      </nts-text-button>
    </ng-template>

  </ng-container>

</div>

<nts-loader [class.has-footer]="viewModel?.showFooter" *ngIf="this.viewModel?.onActionInProgress" [skipHeader]="false" [animation]="this.viewModel?.onActionInProgressAnimation | async" [isVisible]="this.viewModel?.onActionInProgress | async"></nts-loader>

  <!-- <div class="modal-header" cdkDragHandle cdkDragBoundary=".modal" cdkDrag cdkDragRootElement=".modal-content">
    <h4 class="modal-title">
      {{ viewModel?.modalTitle }}
      <br>
      <small>{{ viewModel?.modalSubtitle }}</small>
    </h4>
    <div class="modal-header-commands">
      <nts-text-button class="expand-command only-desktop" [icon]="'expand'" (onClicked)="toggleMaximize()" *ngIf="maximizable && !maximized"></nts-text-button>
      <nts-text-button class="collapse-command only-desktop" [icon]="'collapse'" (onClicked)="toggleMaximize()" *ngIf="maximizable && maximized"></nts-text-button>
      <nts-text-button class="close-command" [icon]="'cancel'" (onClicked)="closeModal()"></nts-text-button>
    </div>
  </div>
  <div class="modal-body" [class.has-footer]="viewModel?.showFooter">
    <div #documentPlaceHolder></div>
  </div>
  <div *ngIf="viewModel?.showFooter" class="modal-footer">
    <nts-text-button *ngIf="cancelCommand" class="moda-footer-button"
      [title]="cancelCommand.tooltip"
      [label]="cancelCommand.displayName"
      (onClicked)="cancelCommand.execute()" [isDisabled]="!(cancelCommand.canExecute$ | async)">
      {{ cancelCommand.displayName }}
    </nts-text-button>

    <ng-container *ngFor="let command of commands">
      <div *ngIf="command.internalCommand.isDefault; then filledButton else textButton"></div>
      
      <ng-template #filledButton>
        <nts-filled-button *ngIf="command.internalCommand.isVisible$ | async" class="modal-footer-filled-button"
          [title]="command.internalCommand.tooltip"
          [label]="command.internalCommand.displayName"
          [type]="3"
          (onClicked)="command.execute(command)" [isDisabled]="!(command.canExecute$ | async)">
          {{ command.internalCommand.displayName }}
        </nts-filled-button>
      </ng-template>

      <ng-template #textButton>
        <nts-text-button *ngIf="command.internalCommand.isVisible$ | async" class="modal-footer-text-button"
          [title]="command.internalCommand.tooltip"
          [label]="command.internalCommand.displayName"
          (onClicked)="command.execute(command)" [isDisabled]="!(command.canExecute$ | async)">
          {{ command.internalCommand.displayName }}
        </nts-text-button>
      </ng-template>

    </ng-container>
  </div>
  <nts-loader [isVisible]="onActionInProgress"></nts-loader> -->

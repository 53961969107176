import { Component } from "@angular/core";
import { ExternalFieldMetaData } from "../../../../layout-meta-data/external-field-meta-data";
import { FieldTypes } from "../../../../layout-meta-data/field-types.enum";
import { BaseFieldComponent } from "../base-field/base-field.component";

@Component({
    template: ''
})
export abstract class ExternalFieldComponent extends BaseFieldComponent {
    override type = FieldTypes.External;
    abstract get showCodeInDescription(): boolean|null;
    abstract get externalCodes(): ExternalFieldMetaData[];
    abstract get externalDecodes(): string[];
    abstract get externalDecodesAvailables(): string[];
    abstract get externalCodesAvailables(): string[];
}

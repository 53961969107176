import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MasterViewModelInterface } from "../../view-models/master-view-model.interface";
import { SnapShotListViewModelInterface } from "../../view-models/snap-shot-list-view-model.interface";
import { ViewModelEventDispatcher } from "../../view-models/view-model-event-dispatcher";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge } from "rxjs";
import { TextButtonComponent } from "../shared/buttons/text-button/text-button.component";
import { NTSTranslatePipe } from "../pipe/nts-translation-pipe";
import { MasterAreaContainerComponent } from "../master-area-container/master-area-container.component";
import { SnapShotListContainerComponent } from "../snap-shot-list-container/snap-shot-list-container.component";
import { NgIf } from "@angular/common";

@UntilDestroy()
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'nts-navigation-panel',
    templateUrl: './navigation-panel.component.html',
    styleUrls: ['./navigation-panel.component.scss'],
    standalone: true,
    imports: [
        TextButtonComponent,
        NTSTranslatePipe,
        MasterAreaContainerComponent,
        SnapShotListContainerComponent,
        NgIf
    ]
})
export class NavigationPanelComponent implements OnInit {
    
    @Input() masterViewModel: MasterViewModelInterface;
    @Input() eventDispatcher: ViewModelEventDispatcher;
    @Input() snapShotListViewModel: SnapShotListViewModelInterface;

    @Output() onNavigationPanelCollapsed = new EventEmitter();
    @Output() onMasterAreaSelected = new EventEmitter();
    @Output() onSnapShotSelected = new EventEmitter();

    constructor(
        private _cd: ChangeDetectorRef
    ) { }

    ngOnInit() {
        
        merge(
            this.eventDispatcher.onSnapShotSelected,
            this.eventDispatcher.onMasterAreaSelected,
            this.eventDispatcher.onNavigationPanelCollapsed,
        ).pipe(untilDestroyed(this)).subscribe(
            () => this._cd.detectChanges()
        );
    }
}
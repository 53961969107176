import { BaseViewModel } from '../base-view-model';
import { MessageContainer } from '../message-container';

export class ZoomQueryContext extends BaseViewModel {
    
    override removeError(item: MessageContainer) {

    }
    
    get queryId(): number {
        return this._queryId;
    }
    set queryId(value: number) {
        this._queryId = value;
    }
    
    get queryName(): string {
        return this._queryName;
    }
    set queryName(value: string) {
        this._queryName = value;
    }

    get queryDescription(): string {
        return this._queryDescription;
    }
    set queryDescription(value: string) {
        this._queryDescription = value;
    }
    
    get isPredefined(): boolean {
        return this._isPredefined;
    }
    set isPredefined(value: boolean) {
        this._isPredefined = value;
    }
    
    get unchanged(): boolean {
        return this._unchanged;
    }
    set unchanged(value: boolean) {
        this._unchanged = value;
    }

    get zoomFromZoom(): boolean {
        return this._zoomFromZoom;
    }
    set zoomFromZoom(value: boolean) {
        this._zoomFromZoom = value;
    }

    private _queryId: number;
    private _queryName = '';
    private _zoomFromZoom = false;
    private _unchanged = true;
    private _isPredefined = false;
    private _queryDescription = '';

    setPredefinedZoomState(queryId: number, queryName: string, queryDescription: string): void {
        this.isPredefined = true;
        this.setStoreZoomState(queryId, queryName, queryDescription);
    }
    
    setStoreZoomState(queryId: number, queryName: string, queryDescription: string): void {
        this.queryId = queryId;
        this.queryName = queryName;
        this.queryDescription = queryDescription;
        this.setUnchangedState();
    }

    canStoreZoomExecute(x: Object): boolean {
        return (this.isNewState() || !this.unchanged) && (!this.zoomFromZoom);
    }

    canPredefinedExecute(x: Object): boolean {
        return !this.zoomFromZoom;
    }

    canNewZoomExecute(x: Object): boolean {
        return !this.zoomFromZoom;
    }

    canRestoreQuery(x: Object): boolean {
        return this.queryId != null && !this.unchanged && !this.zoomFromZoom;
    }

    isNewState(): boolean {
        return this.queryId == null;
    }
    
    newQueryZoomState(): void {
        this.queryId = null;
        this.queryName = null;
        this.queryDescription = null;
        this.isPredefined = false;
        this.unchanged = true;
    }

    setChangedState(): void {
        this.unchanged = false;
    }

    setUnchangedState(): void {
        this.unchanged = true;
    }
}

<div class="searchBoxWrapper">
    <div role="search" [ngClass]="{ 'is-active': isActive, 'root-203': isActive, 'root-95': !isActive, 'search-hover': (isHover && !isActive)  }"
        (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()"            
        class="ms-SearchBox is-underlined searchBox">
        <div [ngClass]="{ 'iconContainer-96': !isActive, 'iconContainer-204': isActive }"
            class="searchBox-iconContainer" aria-hidden="true">
            <i data-icon-name="Search" aria-hidden="true" [ngClass]="{ 'icon-101': !isActive, 'icon-206': isActive }"
                class="ms-Icon--Search ms-SearchBox-icon"></i>
        </div>
        <input [ngModel]="searchModel" (ngModelChange)="updateSearchModel($event)" (blur)="onBlur()" (focus)="onFocus()"
            id="searchBox" class="searchBox-field field-99" placeholder="Cerca" role="searchbox" value="">
        <div *ngIf="searchModel?.length > 0" class="ms-SearchBox-clearButton clearButton-170">
            <button (click)="onClear()" type="button" class="ms-Button ms-Button--icon root-215" aria-label="Clear text"
                data-is-focusable="true">
                <span class="ms-Button-flexContainer flexContainer-99" data-automationid="splitbuttonprimary">
                    <i data-icon-name="Clear" role="presentation" aria-hidden="true"
                        class="ms-Icon ms-Icon--Clear root-38 css-96 ms-Button-icon icon-216"></i>
                </span>
            </button>
        </div>
    </div>
</div>

<input #securityTextBox 
    [value]="value" 
    [attr.disabled]="true" 
    [attr.tabindex]="tabIndex" 
    [attr.readonly]="true"
    type="text" 
    spellcheck="false" 
    autocomplete="autocomplete-off" />

<span [ngClass]="securityAccessModeClass"></span>

<svg-icon key="locked"
    [popper]="securityTooltipDescription"
    [popperDisabled]="!securityTooltipDescription || securityTooltipDescription?.length == 0"
    [popperTrigger]="ngxPopperjsTriggers.hover"
    [popperApplyClass]="securityTooltipClass"
    [popperPlacement]="ngxPopperjsPlacements.TOP"
    [popperPreventOverflow]="false"
    [popperHideOnScroll]="true"
    popperAppendTo="body"
    [popperDelay]="1000">
</svg-icon>

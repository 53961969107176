import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseZoomFilterPropertyComponent } from '../base-zoom-filter-property-component';
import { BaseCheckboxTextBoxComponent } from '../../../controls/core/base/base-checkbox-text-box/base-checkbox-text-box.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BoolZoomFilterViewModel } from '../../../../view-models/zoom/filter-view-model/bool-zoom-filter-view-model';

@UntilDestroy()
@Component({
  selector: 'nts-zoom-filter-bool-text-box',
  templateUrl: './zoom-filter-bool-text-box.component.html',
  styleUrls: ['./zoom-filter-bool-text-box.component.scss'],
  standalone: true,
  imports: [
    BaseCheckboxTextBoxComponent
  ]
})
export class ZoomFilterBoolTextBoxComponent extends BaseZoomFilterPropertyComponent<boolean> implements OnInit, AfterViewInit {

  @Input() filter: BoolZoomFilterViewModel;

  @ViewChild('baseCheckboxTextBox', { static: false }) baseCheckboxTextBox!: BaseCheckboxTextBoxComponent;

  constructor(private cd: ChangeDetectorRef) {
    super()

  }

  ngOnInit() {
    if (!this.propertyViewModel) { throw new Error('Missing viewModel!'); }
   
    this.filter.isEnableStatusChanged.pipe(untilDestroyed(this)).subscribe(() => {
      this.cd.detectChanges();
    });
  }

  ngAfterViewInit(): void {   
    super.init();
  }

  protected get input(): HTMLInputElement {
    return this.baseCheckboxTextBox.checkboxBox.nativeElement;
  }
}

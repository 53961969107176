import { MessageCodes } from '../../../resources/message-codes';
import { BaseValidation } from './base-validation';
import { ValidationArguments } from 'class-validator';

export class StringLengthValidation extends BaseValidation<string> {

    private minLength: number;
    private maxLength: number;

    constructor(minLength: number, maxLength: number) {
        super();
        this.minLength = minLength;
        this.maxLength = maxLength;
    }

    validateImplementation(value: string, args: ValidationArguments): boolean {
        if (this.maxLength > 0 && value.length > this.maxLength) {
            this.errorMessage = this.getMessageWithCodeValues(MessageCodes.PropertyStringLongerThenMaxLength, [
                {
                    code: MessageCodes.PropertyStringLongerThenMaxLength_TAG_NomeCampo,
                    value: this.getDisplayName(args)
                },
                {
                    code: MessageCodes.PropertyStringLongerThenMaxLength_TAG_NumeroCaratteri,
                    value: this.maxLength.toString()
                },
            ]);
            return false;
        }
        if (this.minLength > 0 && value.length < this.minLength) {
            this.errorMessage = this.getMessageWithCodeValues(MessageCodes.PropertyStringLongerThenMaxLength, [
                {
                    code: MessageCodes.PropertyStringShortedThenMinLength_TAG_NomeCampo,
                    value: this.getDisplayName(args)
                },
                {
                    code: MessageCodes.PropertyStringShortedThenMinLength_TAG_NumeroCaratteri,
                    value: this.minLength.toString()
                },
            ]);
            return false;
        }
        return true;
    }
}

<div #popperError="popper"
    [class.has-error]="showErrorBorder && errorList?.length > 0"
    [popper]="tooltipErrTemplate"
    [popperDisabled]="!showErrorTooltip || !errorList || errorList?.length === 0"
    [popperTrigger]="ngxPopperjsTriggers.hover"
    popperApplyClass="error"
    [popperPlacement]="ngxPopperjsPlacements.TOP"
    [popperPreventOverflow]="false"
    popperAppendTo="body"
    class='radio-button-wrapper'>
    <label *ngFor=" let option of options"
        (click)="click($event, option)"
        (keydown.arrowdown)="keydownArrowDown($event, option)"
        (keydown.arrowup)="keydownArrowUp($event, option)"
        (keydown.arrowleft)="keydownArrowLeft($event, option)"
        (keydown.arrowright)="keydownArrowRight($event, option)"
        #popperInfo="popper"
        [popper]="option.descriptionExt"
        [popperDisabled]="!option?.descriptionExt || option?.descriptionExt?.length === 0"
        [popperTrigger]="ngxPopperjsTriggers.hover"
        popperApplyClass="info"
        [popperPlacement]="ngxPopperjsPlacements.TOP"
        [popperPreventOverflow]="false"
        [popperHideOnScroll]="true"
        popperAppendTo="body"
        [popperDelay]="1000"
        [attr.aria-label]="option.description" 
        [attr.aria-disabled]="isDisabled || option.disabled"
        [attr.for]="option.key"
        [class.disabled]="isDisabled || option.disabled"
        class="radio-button">
        <input type="radio" 
            [attr.name]="name" 
            [attr.id]="option.key" 
            [disabled]="isDisabled || option.disabled"
            [ngModel]="value?.key" 
            [value]="option.key">
        <span class="item-text">{{option.description}}</span>
        <span class="checkmark"></span>
    </label>
</div>

<popper-content #tooltipErrTemplate>
    <ng-container *ngFor="let item of errorList">
        {{ item }}
    </ng-container>
</popper-content>
<div class="messages-container card">
    <div class="messages-container-content card-block">
        <div>
            <ul *ngIf="viewModelLoaded" class="list-group">
                <li class="list-group-item" [ngClass]="{'list-group-item-info': message.isInformationMessage}"
                    *ngFor="let message of orchestratorViewModel?.allViewModelInformationsBuildedList">
                    {{ message.message }}
                </li>
            </ul>
        </div>
    </div>
</div>

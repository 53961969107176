import { UserIdentity } from './user.identity';
import { IdentityTypeDecorator } from '../../decorators/identity-type.decorator';
import { ModelTypeNameDecorator } from '../decorators/model-type-name.decorator';
import { OCCModel } from '../occ-model';
import { NumberDecorator } from '../decorators/number.decorator';
import { StringDecorator } from '../decorators/string.decorator';
import { DateDecorator } from '../decorators/date.decorator';
import { DateTime } from '../date-time';
import { UserLogin } from './user-login';
import { ExternalDecorator } from '../decorators/external.decorator';
import { Expose, Transform, Type } from '../../serialization/class-transformer/decorators';

@IdentityTypeDecorator(UserIdentity)
@ModelTypeNameDecorator('User')
export class User extends OCCModel<UserIdentity> {

    private _id: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'id' })
    get id(): number {
        return this.getValueForIdentityValue<number>(() => this._id, 'id');
    }
    set id(value: number) {
        this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
    }

    private _name: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'name', maxLength: 100 })
    get name(): string {
        return this.getValueForIdentityValue<string>(() => this._name, 'name');
    }
    set name(value: string) {
        this.setValueForIdentityValue<string>(() => { this._name = value; }, value, 'name');
    }

    private _lastName: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'lastName', maxLength: 100 })
    get lastName(): string {
        return this.getValueForIdentityValue<string>(() => this._lastName, 'lastName');
    }
    set lastName(value: string) {
        this.setValueForIdentityValue<string>(() => { this._lastName = value; }, value, 'lastName');
    }

    private _birthday: Date;

    @Expose()
    @DateDecorator({ displayNameKey: 'birthday' })
    @Transform((value, obj, type) => {
        return DateTime.transform('birthday', value, obj, type);
    })
    get birthday(): Date {
        return this.getValueForIdentityValue<Date>(() => this._birthday, 'birthday');
    }
    set birthday(value: Date) {
        this.setValueForIdentityValue<Date>(() => { this._birthday = value; }, value, 'birthday');
    }

    private _loginInformation: UserLogin;

    @ExternalDecorator({ displayNameKey: 'User_LoginInformation', principalPName1: 'userLoginId', dependantPName1: 'id' })
    @Expose()
    @Type(() => UserLogin)
    get loginInformation(): UserLogin {
        return this.getValueForExternal<UserLogin>(
            () => this._loginInformation,
            'loginInformation',
            UserLogin
        );
    }
    set loginInformation(value: UserLogin) {
        this.setValueForExternal(() => {
            this._loginInformation = value;
        }, value, 'loginInformation');
    }
}


export class MessageFormatter {
    static formatString(str: string, ...replacements: string[]): string {
        let count = 0;
        return str.replace(/({\w+})/g, (matchWithBrackets, matchWithoutBrackets) => {
            const replace = replacements[count++];
            return typeof replace !== 'undefined' ? replace : matchWithBrackets;
        });
    }

    static formatNumber(str: string, ...replacements: string[]): string {
        return str.replace(/{(\d+)}/g, (match, number) => {
            return typeof replacements[number] !== 'undefined' ? replacements[number] : match;
        });
    }
}

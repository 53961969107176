import { NTSReflection } from '@nts/std/src/lib/utility';
import { CoreModel } from '../core-model';
import { BaseIdentity } from '../base-identity';
import { ClassType } from '../../serialization/class-transformer/ClassTransformer';

const MODEL_TYPE_META_DATA_IDENTIFICATION_KEY = 'modelTypeDecoratorMetaDataKey';

/**
 * Definisce quale è la classe del modello
 */
export function ModelTypeDecorator<TIdentity extends BaseIdentity, TModel extends CoreModel<TIdentity>>(modelType: ClassType<TModel>) {
    return (target: any) => {
        Reflect.defineMetadata(MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, modelType, target);
    };
}

export class ModelTypeInspector {

    static META_DATA_KEY = MODEL_TYPE_META_DATA_IDENTIFICATION_KEY;
    static DECORATOR_NAME = '@ModelTypeDecorator';

    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): any {
        return NTSReflection.getClassMetadata(
            MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }
}

import { Observable, of } from 'rxjs';
import { LogEntry } from './log-entry';
import { LogLevel } from './log-level.enum';
import { LogPublisher } from './log-publisher';

export class LogConsole extends LogPublisher {

    log(entry: LogEntry): Observable<boolean> {
        // Log to console

        switch (entry.level) {
            case LogLevel.Debug:
                console.debug(entry);
                break;
            case LogLevel.Warn:
                console.warn(entry.buildLogString());
                break;
            case LogLevel.Error:
                console.error(entry.buildLogString());
                break;

            default:
                console.log(entry.buildLogString());
        }

        return of(true);
    }

    clear(): Observable<boolean> {
        console.clear();
        return of(true);
    }
}

import { CommandsCategory } from './commands/commands-category';
import { CommandsPage } from './commands/commands-page';
import { UICommandInterface } from './commands/ui-command.interface';
import { CommandsGroup } from './commands/commands-group';
import { CoreToolBarViewModelInterface } from './core-tool-bar-view-model.interface';
import { CoreOrchestratorViewModelInterface } from './core-orchestrator-view-model.interface';
import { BehaviorSubject, firstValueFrom, forkJoin, lastValueFrom, Observable, of, Subject } from 'rxjs';
import { RelatedUICommandSettingsManager } from './commands/related-ui-command-settings-manager';
import { ReportUICommandSettingsManager } from './commands/report-ui-command-settings-manager';
import { CommandFactory } from './commands/command-factory';
import { MessageCodes } from '../resources/message-codes';
import { MessageResourceManager } from '../resources/message-resource-manager';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { CommandTypes } from './commands/command-types';
import { UICommandSettingsManagerInterface } from './commands/ui-command-settings-manager.interface';
import { UICommandSettingsManager } from './commands/ui-command-settings-manager';
import { PrintOutDemandDto } from '../domain-models/report/print-out-demand.dto';
import { ClassType } from '../serialization';
import { MsgClearMode } from './core-orchestrator-view-model';
import { LogService } from '@nts/std/src/lib/utility';
import { ViewModelStates } from './states/view-model-states';
import { OperationIdentity } from '../domain-models/report/operation.identity';
import { FilledButtonType } from '../components/shared/buttons/filled-button/filled-button.component';
import { MenuItem } from 'primeng/api';
import { SlideMenu } from 'primeng/slidemenu';
import { RoutingService } from '../routing/routing.service';
import { TelemetryService } from '@nts/std/src/lib/telemetry';

export abstract class CoreToolBarViewModel implements CoreToolBarViewModelInterface {

    loadedCompleted = new BehaviorSubject<boolean>(false);
    menuItemChanged = new Subject<UICommandInterface>();

    readonly homeCommandGroup = new CommandsGroup(
        MessageResourceManager.Current.getMessage(MessageCodes.HomeCommandGroupsName),
        MessageResourceManager.Current.getMessage(MessageCodes.HomeCommandGroupsDisplayName));

    readonly sidebarCommandGroup = new CommandsGroup(
        MessageResourceManager.Current.getMessage(MessageCodes.SidebarCommandGroupsName),
        MessageResourceManager.Current.getMessage(MessageCodes.SidebarCommandGroupsDisplayName));

    readonly actionCommandGroup = new CommandsGroup(
        MessageResourceManager.Current.getMessage(MessageCodes.ActionsCommandGroupsName),
        MessageResourceManager.Current.getMessage(MessageCodes.ActionsCommandGroupsDisplayName));

    readonly relatedCommandGroup = new CommandsGroup(
        MessageResourceManager.Current.getMessage(MessageCodes.RelatedCommandGroupsName),
        MessageResourceManager.Current.getMessage(MessageCodes.RelatedCommandGroupsDisplayName));

    readonly reportCommandGroup = new CommandsGroup(
        MessageResourceManager.Current.getMessage(MessageCodes.ReportCommandGroupsName),
        MessageResourceManager.Current.getMessage(MessageCodes.ReportCommandGroupsDisplayName));

    readonly aboutCommandGroup = new CommandsGroup(
        MessageResourceManager.Current.getMessage(MessageCodes.AboutCommandGroupsName),
        MessageResourceManager.Current.getMessage(MessageCodes.AboutCommandGroupsDisplayName));

    readonly customizeFieldsCommandGroup = new CommandsGroup(
        MessageResourceManager.Current.getMessage(MessageCodes.CustomizeFieldsCommandGroupsName),
        MessageResourceManager.Current.getMessage(MessageCodes.CustomizeFieldsCommandGroupsDisplayName));

    readonly securityCommandGroup = new CommandsGroup(
        MessageResourceManager.Current.getMessage(MessageCodes.SecurityCommandGroupsName),
        MessageResourceManager.Current.getMessage(MessageCodes.SecurityCommandGroupsDisplayName));

    get homeTab(): CommandsPage { return this._homeTab; }
    get sidebarsTab(): CommandsPage { return this._sidebarsTab; }
    get relatedTab(): CommandsPage { return this._relatedTab; }
    get additionalCommandsTab(): CommandsPage { return this._additionalCommandsTab; }
    get defaultCommand(): UICommandInterface { return this._defaultCommand; };
    get defaultCommandType(): FilledButtonType { return this._defaultCommandType; };
    get orchestrator(): CoreOrchestratorViewModelInterface {
        return this._orchestrator;
    }
    get commandsPage(): Array<CommandsPage> {
        return this._commandsPage;
    }

    get sidebarCommandsPage(): Array<CommandsPage> {
        return this._sidebarCommandsPage;
    }

    get relatedCommandsPage(): Array<CommandsPage> {
        return this._relatedCommandsPage;
    }

    get additionalCommandsPage(): Array<CommandsPage> {
        return this._additionalCommandsPage;
    }

    protected _orchestrator: CoreOrchestratorViewModelInterface;
    protected _defaultCommand: UICommandInterface = null;
    protected _defaultCommandType: FilledButtonType;
    protected _defaultCategory: CommandsCategory;
    protected _defaultSidebarsCategory: CommandsCategory;
    protected _defaultRelatedCategory: CommandsCategory;
    protected _defaultAdditionalCommandsCategory: CommandsCategory;
    externalReturnCommandGroup: CommandsGroup;
        
    private _homeTab: CommandsPage;
    private _sidebarsTab: CommandsPage;
    private _relatedTab: CommandsPage;
    private _additionalCommandsTab: CommandsPage;
    private _commandsPage: Array<CommandsPage>;
    private _sidebarCommandsPage: Array<CommandsPage>;
    private _relatedCommandsPage: Array<CommandsPage>;
    private _additionalCommandsPage: Array<CommandsPage>;
    private _historyBackButtonCommandGroup: CommandsGroup;

    constructor(orchestrator: any) {
        this._orchestrator = orchestrator;
        this._defaultCategory = new CommandsCategory();
        this._defaultSidebarsCategory = new CommandsCategory();
        this._defaultRelatedCategory = new CommandsCategory();
        this._defaultAdditionalCommandsCategory = new CommandsCategory();
        this._homeTab = new CommandsPage('HomeTabName', 'HomeTabDisplayName', 'H');
        this._sidebarsTab = new CommandsPage('SidebarsTabName', 'SidebarsTabDisplayName', 'H');
        this._relatedTab = new CommandsPage('RelatedTabName', 'RelatedTabDisplayName', 'H');
        this._additionalCommandsTab = new CommandsPage('AdditionalCommandsTabName', 'AdditionalCommandsTabDisplayName', 'I');
        this._defaultCategory.addPage(this._homeTab);
        this._defaultSidebarsCategory.addPage(this._sidebarsTab);
        this._defaultRelatedCategory.addPage(this._relatedTab);
        this._defaultAdditionalCommandsCategory.addPage(this._additionalCommandsTab);
        this.buildMenu(this._defaultCategory).then(() => {
            this._defaultCategory.removeEmptyPages();
            this._commandsPage = this._defaultCategory.pages;
            if (!(this.commandsPage?.length > 0)) {
                this._commandsPage = [this._homeTab];
            }
            this._sidebarCommandsPage = this._defaultSidebarsCategory.pages;
            this._relatedCommandsPage = this._defaultRelatedCategory.pages;
            this._additionalCommandsPage = this._defaultAdditionalCommandsCategory.pages;

            // Aggiunge externalReturn commands group come primo gruppo
            if (this.externalReturnCommandGroup?.commands?.length > 0) {
                this.commandsPage[0].commandGroups.splice(0, 0, this.externalReturnCommandGroup);
            }

            this.loadedCompleted.next(true);
        });

    }

    abstract buildMenu(defaultCategory: CommandsCategory): Promise<void>;

    addActionCommandGroup() {

        this.addActionCommand(this.actionCommandGroup);

        this.addActionCommands().forEach(actionCommand => {
            this.actionCommandGroup.addCommand(actionCommand);
        });

        this.homeTab.addGroup(this.actionCommandGroup, true);
    }

    addHomeCommandGroup() {
        this.addHomeCommands().forEach((command: UICommandInterface) => {
            this.homeCommandGroup.addCommand(command);
        });

        this.homeTab.addGroup(this.homeCommandGroup, true);
    }

    addSidebarCommandGroup() {
        this.addSidebarCommands().forEach((command: UICommandInterface) => {
            this.sidebarCommandGroup.addCommand(command);
        });

        this._sidebarsTab.addGroup(this.sidebarCommandGroup, true);
    }

    async addHistoryBackButtonCommand(routingService: RoutingService): Promise<void> {
        await firstValueFrom(this.loadedCompleted.pipe(filter((loaded) =>loaded)));
        this._historyBackButtonCommandGroup = new CommandsGroup(
            MessageResourceManager.Current.getMessage(MessageCodes.HistoryBackButtonCommandGroupsName),
            MessageResourceManager.Current.getMessage(MessageCodes.HistoryBackButtonCommandGroupsDisplayName));
        this._historyBackButtonCommandGroup.addCommand(
            this.getHistoryBackButtonCommand(routingService)
        );

        this.commandsPage[0].commandGroups.splice(0, 0, this._historyBackButtonCommandGroup);
    }

    getHistoryBackButtonCommand(routingService: RoutingService): UICommandInterface {
        return CommandFactory.createUICommandWithType(
            CommandTypes.HistoryBack,
            async () => {
                const result = await this.orchestrator.confirmLosingUnsavedDataAsync();
                if (result) {
                    routingService.redirectToEncodedUri(this.orchestrator.queryParams['history-back-url']);
                }
            },
            () => of(true)
        )
    }

    async getMoreOptionsMenuItems(moreOptionsMenu?: SlideMenu): Promise<MenuItem[]> {

        const moreOptionsMenuItemList: MenuItem[] = [];
    
        for (const commandPage of this.commandsPage) {
          for (const commandGroup of commandPage.commandGroups) {
            const commandGroupMenu = {
              label: commandGroup.displayName,
              items: [],
            } as MenuItem;
            for (const command of commandGroup.commands) {
              if (command?.children?.length > 0) {
    
                for (const child of command.children) {
                  commandGroupMenu.items.push({
                    label: child.displayName,
                    // icon: e.loading ? 'pi pi-spin pi-spinner' : e.iconClass,
                    disabled: !child.canExecute$.value,
                    command: (event) => {
                        if (moreOptionsMenu) {
                            moreOptionsMenu.hide();
                        }
                      
                        setTimeout(() => child.execute(child, event.item), 10)
                        TelemetryService.trackEvent({
                            name: `Command ${child.displayName} executed`,
                        }, {command: child})
                    }
                  });
                }
    
              } else {
                const isVisible = command.isVisible$.value;
                if (isVisible) {
                  commandGroupMenu.items.push({
                    label: command.displayName,
                    icon: await firstValueFrom(command.loading$.pipe(take(1))) ? 'pi pi-spin pi-spinner' : command.iconClass,
                    disabled: !command.canExecute$.value,
                    styleClass: await firstValueFrom(command.isHighlighted(null).pipe(take(1))) ? 'is-highlighted' : '',
                    command: (event) => {
                        if (moreOptionsMenu) {
                            moreOptionsMenu.hide();
                        }
                        setTimeout(() => command.execute(command, event.item), 10)
                        TelemetryService.trackEvent({
                            name: `Command ${command.displayName} executed`,
                        }, {command})
                    }
                  });
                }
              }
            }
            moreOptionsMenuItemList.push(commandGroupMenu);
          }
        }
    
        // aggiunge i additionalCommands solo nei more options
        for (const commandPage of this.additionalCommandsPage) {
          for (const commandGroup of commandPage.commandGroups) {
            const commandGroupMenu = {
              label: commandGroup.displayName,
              items: [],
            } as MenuItem;
            for (const command of commandGroup.commands) {
              commandGroupMenu.items.push({
                label: command.displayName,
                icon: await lastValueFrom(command.loading$.pipe(take(1))) ? 'pi pi-spin pi-spinner' : command.iconClass,
                disabled: !command.canExecute$.value,
                styleClass: await lastValueFrom(command.isHighlighted(null).pipe(take(1))) ? 'is-highlighted' : '',
                command: async (event) => {
                    if (moreOptionsMenu) {
                        moreOptionsMenu.hide();
                    }
                    setTimeout(() => command.execute(command, event.item), 10)
                    TelemetryService.trackEvent({
                        name: `Command ${command.displayName} executed`,
                    }, {command})
                }
              });
            }
            moreOptionsMenuItemList.push(commandGroupMenu);
          }
        }
    
        // Move info commandGroup to last position
        moreOptionsMenuItemList.push(moreOptionsMenuItemList.splice(moreOptionsMenuItemList.findIndex((c) => c.label === MessageResourceManager.Current.getMessage(MessageCodes.AboutCommandGroupsDisplayName)), 1)[0]);
    
        return moreOptionsMenuItemList;
    }

    private moreOptionsCommand = null;

    getMoreOptionsCommand(): UICommandInterface {

        if (this.moreOptionsCommand) {
            return this.moreOptionsCommand;
        }

        this.moreOptionsCommand = CommandFactory.createUICommandWithType(
            CommandTypes.MoreOptions,
            async (x) => null,                          // Execute
            () => of(true),                            // CanExecute
            (x) => of(false),                           // IsHighlight
            () => this.isVisibleMoreOptionsCommand()   // Visibility
        )

        const moreOptionsCommandName = MessageResourceManager.Current.getMessage(MessageCodes.MoreOptionsMenuCommand);
        this.moreOptionsCommand.displayName = moreOptionsCommandName;
        this.moreOptionsCommand.description = moreOptionsCommandName;
        this.moreOptionsCommand.tooltip = moreOptionsCommandName;

        return this.moreOptionsCommand ;
    }

    async getMobileMenuItems(mobileMenu?: SlideMenu): Promise<MenuItem[]> {

        const mobileMenuItemList: MenuItem[] = [];
    
        for (const commandPage of this.commandsPage) {
          for (const commandGroup of commandPage.commandGroups) {
            const commandGroupMenu = {
              label: commandGroup.displayName,
              items: [],
            } as MenuItem;
            for (const command of commandGroup.commands) {
              if (command?.children?.length > 0) {
    
                for (const child of command.children) {
                  commandGroupMenu.items.push({
                    label: child.displayName,
                    // icon: e.loading ? 'pi pi-spin pi-spinner' : e.iconClass,
                    disabled: !child.canExecute$.value,
                    command: (event) => {
                        if (mobileMenu) {
                            mobileMenu.hide();
                        }
                      
                        setTimeout(() => child.execute(child, event.item), 10)
                        TelemetryService.trackEvent({
                            name: `Command ${child.displayName} executed`,
                        }, {command:child})
                    }
                  });
                }
    
              } else {
                const isVisible = command.isVisible$.value;
                if (isVisible) {
                  commandGroupMenu.items.push({
                    label: command.displayName,
                    icon: await firstValueFrom(command.loading$.pipe(take(1))) ? 'pi pi-spin pi-spinner' : command.iconClass,
                    disabled: !command.canExecute$.value,
                    styleClass: await firstValueFrom(command.isHighlighted(null)) ? 'is-highlighted' : '',
                    command: (event) => {
                        if (mobileMenu) {
                            mobileMenu.hide();
                        }

                        setTimeout(() => command.execute(command, event.item), 10)
                        TelemetryService.trackEvent({
                            name: `Command ${command.displayName} executed`,
                        }, {command})
                    }
                  });
                }
              }
            }
            mobileMenuItemList.push(commandGroupMenu);
          }
        }
    
        // aggiunge i additionalCommands solo nei more options
        for (const commandPage of this.additionalCommandsPage) {
          for (const commandGroup of commandPage.commandGroups) {
            const commandGroupMenu = {
              label: commandGroup.displayName,
              items: [],
            } as MenuItem;
            for (const command of commandGroup.commands) {
              commandGroupMenu.items.push({
                label: command.displayName,
                icon: await firstValueFrom(command.loading$) ? 'pi pi-spin pi-spinner' : command.iconClass,
                disabled: !command.canExecute$.value,
                styleClass: await firstValueFrom(command.isHighlighted(null)) ? 'is-highlighted' : '',
                command: (event) => {
                    if (mobileMenu) {
                        mobileMenu.hide();
                    }

                    setTimeout(() => command.execute(command, event.item), 10);
                    TelemetryService.trackEvent({
                        name: `Command ${command.displayName} executed`,
                    }, {command})
                }
              });
            }
            mobileMenuItemList.push(commandGroupMenu);
          }
        }
    
        // Move info commandGroup to last position
        mobileMenuItemList.push(mobileMenuItemList.splice(mobileMenuItemList.findIndex((c) => c.label === MessageResourceManager.Current.getMessage(MessageCodes.AboutCommandGroupsDisplayName)), 1)[0]);
    
        return mobileMenuItemList;
    }

    private mobileMenuCommand: UICommandInterface;

    getMobileMenuCommand(): UICommandInterface {

        if (this.mobileMenuCommand) {
            return this.mobileMenuCommand;
        }

        this.mobileMenuCommand = CommandFactory.createUICommandWithType(
            CommandTypes.MobileMenu,
            async (x) => null,                      // Execute
            () => of(true),                        // CanExecute
            (x) => of(false),                       // IsHighlight
            () => this.isVisibleMobileMenuCommand()  // Visibility
        )

        const mobileMenuCommandName = MessageResourceManager.Current.getMessage(MessageCodes.MobileMenuCommand);
        this.mobileMenuCommand.displayName = mobileMenuCommandName;
        this.mobileMenuCommand.description = mobileMenuCommandName;
        this.mobileMenuCommand.tooltip = mobileMenuCommandName;

        return this.mobileMenuCommand;
    }

    addExternalReturnCommand(titleForSaveRequest: string, action: (serializedObjectOrString: string) => void) {
        this.orchestrator.setExternalReturnMode(true);

        this.externalReturnCommandGroup = new CommandsGroup(
            MessageResourceManager.Current.getMessage(MessageCodes.ReturnCommandGroupsName),
            MessageResourceManager.Current.getMessage(MessageCodes.ReturnCommandGroupsDisplayName));
        this.externalReturnCommandGroup.addCommand(
            CommandFactory.createUICommandWithType(
                CommandTypes.ExternalReturn,
                async () => await this.externalReturnExecuted(titleForSaveRequest, action),
                () => this.canReturnCommand(),
                () => this.isHighlightReturnCommand(),
                () => this.isVisibleReturnCommand()
            )
        );
    }

    isVisibleMoreOptionsCommand(): Observable<boolean> {
        return of(true);
    }

    isVisibleMobileMenuCommand(): Observable<boolean> {
        return of(true);
    }

    protected addCustomCommandGroups() {
        this.addCommandGroups().forEach(group => {
            this.homeTab.addGroup(group);
        });
    }

    protected addCustomPages() {
        this.addPages().forEach(page => {
            this._defaultCategory.addPage(page);
        });
    }

    protected addPages(): Array<CommandsPage> {
        return new Array<CommandsPage>();
    }

    /// <summary>
    /// Permette di aggiungere gruppi alla pagina principale della ribbon
    /// </summary>
    protected addCommandGroups(): Array<CommandsGroup> {
        return new Array<CommandsGroup>();
    }

    // Fare override del metodo per aggiungere i comandi al gruppo Azioni
    protected addActionCommands(): Array<UICommandInterface> {
        return new Array<UICommandInterface>();
    }

    protected async addRelatedCommandGroup(): Promise<void> {
        await this.addRelatedCommands();
        this.relatedTab.addGroup(this.relatedCommandGroup, true);
    }

    protected addReportCommandGroup(): Observable<UICommandInterface | null> {
        return this.addReportCommands();        
    }

    protected addReportCommands(): Observable<UICommandInterface | null> {
        return of(null);
    }

    protected async addRelatedCommands(): Promise<void> {
    }

    protected addActionCommand(actionCommandGroup: CommandsGroup): void {

    }

    protected async executeRelatedClient(
        relatedDomainModelFullName: string, 
        isRoot: boolean, 
        blank = false,
        additionalQueryParams: URLSearchParams = new URLSearchParams(),
    ) {
        const result = await this.orchestrator.confirmLosingUnsavedDataAsync();
        if ((this.orchestrator.metadata.rootFullName !== relatedDomainModelFullName) && result) {
            await this.orchestrator.startRelatedClient(
                relatedDomainModelFullName,
                this.orchestrator.rootViewModel.getDomainModel().currentIdentity,
                additionalQueryParams,
                isRoot,
                blank
            );
        }
    }

    protected addRelatedClientCommandWithCustomLogic(
        relatedDomainModelFullName: string,
        relatedDomainModelName: string,
        uiCommandSettingsManager: UICommandSettingsManagerInterface,
        canExecute: () => Observable<boolean>,
        isVisible: () => Observable<boolean> = null,
        isRoot: boolean = false,
        blank = false,
        additionalQueryParams: URLSearchParams = new URLSearchParams(),
    ) {
        this.addRelatedClientCommandWithActions(
            relatedDomainModelFullName,
            uiCommandSettingsManager,
            async () => this.executeRelatedClient(relatedDomainModelFullName, isRoot, blank, additionalQueryParams),
            canExecute,
            undefined,
            (x) => this.orchestrator.rootViewModelChanged.pipe(map(() =>this.orchestrator?.rootViewModel?.domainModelName === relatedDomainModelName)),
            isVisible,
        );
    }

    protected addRelatedClientCommand(
        relatedDomainModelFullName: string,
        relatedDomainModelName: string,
        isRoot = false,
        uiCommandSettingsManager: UICommandSettingsManagerInterface = new RelatedUICommandSettingsManager(),
        commandsGroup: CommandsGroup = undefined,
        additionalQueryParams = new URLSearchParams()
    ) {

        this.addRelatedClientCommandWithActions(
            relatedDomainModelFullName,
            uiCommandSettingsManager,
            async ($event) => {
                this.executeRelatedClient(
                    relatedDomainModelFullName, 
                    isRoot, 
                    $event?.originalEvent?.ctrlKey == true,
                    additionalQueryParams
                )
            },
            // Disabilita solo se il pulsante non è quello evidenziato o se non ha i requisiti per fare l'azione
            () => this.orchestrator.currentStateChanged.pipe(switchMap(() => 
                this.orchestrator.canStartRelatedClient().pipe(map(can => can || this.orchestrator?.rootViewModel?.domainModelName === relatedDomainModelName))
            )),
            
            commandsGroup,
            (x) => this.orchestrator.rootViewModelChanged.pipe(map(() =>this.orchestrator?.rootViewModel?.domainModelName === relatedDomainModelName)),
            () => of(true)
        );
    }

    protected addReportClientCommand<TParams, TRequestDto extends PrintOutDemandDto<TParams>>(
        requestDtoType: ClassType<TRequestDto>,
        paramsGetter: () => TParams,
        uiCommandSettingsManager: UICommandSettingsManagerInterface = new ReportUICommandSettingsManager(),
    ): Observable<UICommandInterface | null> {

        const canExecute = () => this.orchestrator.canGenerateReport();
        const isHighlighted = (x) => of(false);
        const isVisible = () => of(true);

        const commandName = 'Report_Command';

        let command = null;

        command = CommandFactory.createUICommand(
            async (command) => {}, 
            canExecute, 
            isHighlighted, 
            isVisible
        ) as UICommandInterface<any, any, OperationIdentity>;

        command.loading$ = new BehaviorSubject(true);

        uiCommandSettingsManager.setUICommand(commandName, command);

        if (this.reportCommandGroup.commands?.length > 0) {
            this.reportCommandGroup.commands[0] = command;
        } else {
            this.reportCommandGroup.commands.push(command);
            this.homeTab.addGroup(this.reportCommandGroup, true);
        }        

        return this.orchestrator.apiClient.getAvailableReports().pipe(
            map((response) => {

                if (response.operationSuccedeed) {

                    // Verifico che reportInfo abbia tutti i dati necessari
                    const reportInfoList = response.result?.reportInfoList.filter((r) => {
                        if (r.apiAddress?.length > 0 && r.baseAddress?.length > 0) {
                            return true;
                        }
                        LogService.warn('Manca il baseAddress o apiAddress nell\'oggetto report info.', r);
                        return false;
                    })

                    if (reportInfoList?.length > 1) {
        
                        command = CommandFactory.createUICommand(
                            () => null, canExecute, isHighlighted, isVisible
                        ) as UICommandInterface;

                        command.children = response.result.reportInfoList.map((reportInfo) => {
                            const child = CommandFactory.createUICommand(
                                async (x) => {
        
                                    // fix LowerCase url
                                    reportInfo.apiAddress = reportInfo?.apiAddress?.toLowerCase();
                                    reportInfo.baseAddress = reportInfo?.baseAddress?.toLowerCase();
                                    reportInfo.uiFullAddress = reportInfo?.uiFullAddress?.toLowerCase();
        
                                    if (reportInfo.isUIRequired) {
                                        this.orchestrator.openLongOpReport(reportInfo);    
                                    } else {
                        
                                        // Remove slash and backslash at the end of the string
                                        reportInfo.apiAddress = reportInfo.apiAddress.endsWith('/') ? reportInfo.apiAddress.slice(0, -1) : reportInfo.apiAddress;
                                        reportInfo.apiAddress = reportInfo.apiAddress.endsWith('\\') ? reportInfo.apiAddress.slice(0, -1) : reportInfo.apiAddress;
            
                                        // Remove slash and backslash at the end of the string
                                        reportInfo.baseAddress = reportInfo.baseAddress.endsWith('/') ? reportInfo.baseAddress.slice(0, -1) : reportInfo.baseAddress;
                                        reportInfo.baseAddress = reportInfo.baseAddress.endsWith('\\') ? reportInfo.baseAddress.slice(0, -1) : reportInfo.baseAddress;
            
                                        // Fix per local
                                        if (this.orchestrator.env.baseAppUrl.indexOf('//localhost') > -1) {
            
                                            reportInfo.apiAddress = reportInfo.apiAddress.replace(reportInfo.baseAddress, '');
                                            reportInfo.apiAddress = this.orchestrator.env.baseAppUrl + reportInfo.apiAddress;
                                            reportInfo.baseAddress = this.orchestrator.env.baseAppUrl;
                                        }
            
                                        this.orchestrator.generateReport<TParams, TRequestDto>(reportInfo, requestDtoType, paramsGetter, reportInfo.apiAddress, child)
                                    }
        
                                }, () => of(true), () => child.loading$, () => of(true)
                            ) as UICommandInterface<any, any, OperationIdentity>;
                            child.displayName = reportInfo.displayName;
                            child.description = reportInfo.description ?? reportInfo.displayName;
                            return child;
                        })
                        // command.valueDescriptions = response.result.reportInfoList.map((r) => {
                        //     return {
                        //         label: r.displayName,
                        //         value: new EnumPropertyViewModelItem<ReportInfoDto>(r, r.displayName),
                        //         disabled: false,
                        //         iconClass: r.isUIRequired ? 'pi pi-external-link' : 'pi pi-cloud-download',
                        //         loading: false,
                        //     };
                        // })
        
                        uiCommandSettingsManager.setUICommand(commandName, command);
        
                        this.reportCommandGroup.commands[0] = command;

                    } else if (reportInfoList?.length === 1) {
        
                        const reportInfo = reportInfoList[0];
        
                        command = CommandFactory.createUICommand(
                            async (command) => {
                                if (reportInfo.isUIRequired) {
                                    LogService.log('TODO: redirected a ' + reportInfo.uiFullAddress);
                                } else {
        
                                    // Remove slash and backslash at the end of the string
                                    reportInfo.apiAddress = reportInfo.apiAddress.endsWith('/') ? reportInfo.apiAddress.slice(0, -1) : reportInfo.apiAddress;
                                    reportInfo.apiAddress = reportInfo.apiAddress.endsWith('\\') ? reportInfo.apiAddress.slice(0, -1) : reportInfo.apiAddress;
        
                                    // Remove slash and backslash at the end of the string
                                    reportInfo.baseAddress = reportInfo.baseAddress.endsWith('/') ? reportInfo.baseAddress.slice(0, -1) : reportInfo.baseAddress;
                                    reportInfo.baseAddress = reportInfo.baseAddress.endsWith('\\') ? reportInfo.baseAddress.slice(0, -1) : reportInfo.baseAddress;
        
                                    // Fix per local
                                    if (this.orchestrator.env.baseAppUrl.indexOf('//localhost') > -1) {
        
                                        reportInfo.apiAddress = reportInfo.apiAddress.replace(reportInfo.baseAddress, '');
                                        reportInfo.apiAddress = this.orchestrator.env.baseAppUrl + reportInfo.apiAddress;
                                        reportInfo.baseAddress = this.orchestrator.env.baseAppUrl;
                                    }
        
                                    await this.orchestrator.generateReport<TParams, TRequestDto>(reportInfo, requestDtoType, paramsGetter, reportInfo.apiAddress, command);
                                }
                            }, canExecute, isHighlighted, isVisible
                        ) as UICommandInterface<any, any, OperationIdentity>;
        
                        command.displayName = reportInfo.displayName;
                        command.description = reportInfo.description;
                        command.tooltip = reportInfo.description;
                        command.iconClass = reportInfo.isUIRequired ? 'link' : 'print';
        
                        this.reportCommandGroup.commands[0] = command;
                    } 

                } else {
                    this.orchestrator.showFromResponse(response, MsgClearMode.ClearAllMessages);

                    // Retry to download report list
                    command = CommandFactory.createUICommand(
                        async (command) => {
                            await firstValueFrom(
                                this.addReportClientCommand(
                                    requestDtoType,
                                    paramsGetter,
                                    uiCommandSettingsManager
                                ).pipe(map((cmd) => this.menuItemChanged.next(cmd)))
                            )
                        }, 
                        canExecute, 
                        isHighlighted, 
                        isVisible
                    ) as UICommandInterface<any, any, OperationIdentity>;
            
                    uiCommandSettingsManager.setUICommand(commandName, command);

                    command.iconClass = 'refresh'

                    this.reportCommandGroup.commands[0] = command;

                }

                command.loading$.next(false);

                return command;
            })
        );
    }

    protected addRelatedClientCommandWithActions(
        relatedDomainModelFullName: string,
        uiCommandSettingsManager: UICommandSettingsManagerInterface,
        execute: (x: any) => Promise<void>,
        canExecute: () => Observable<boolean>,
        commandsGroup: CommandsGroup = null,
        isHighlighted: (x: any) => Observable<boolean> = null,
        isVisible: () => Observable<boolean> = null,
        hasSecurity: boolean = null
    ) {

        hasSecurity = hasSecurity ?? ((this.orchestrator.metadata.rootMetaData.fullName === relatedDomainModelFullName) && (this.orchestrator?.metadata?.rootMetaData?.userMetaData?.securityAccess != null));

        // To make the method replace() replace all occurrences of the pattern you have to enable the global flag on the regular expression:
        // Append g after at the end of regular expression literal: /search/g
        const searchRegExp = /\./g;
        const commandName = 'Related_' + relatedDomainModelFullName.replace(searchRegExp, '_');
        
        const command = CommandFactory.createUICommand(execute, canExecute, isHighlighted, isVisible);
        command.iconClass = hasSecurity ? 'pi pi-lock' : null;

        const uiCommand = uiCommandSettingsManager.setUICommand(commandName, command);

        if (!commandsGroup) {
            commandsGroup = this.relatedCommandGroup;
        }

        commandsGroup.commands.push(uiCommand);
    }

    protected setDefaultCommand() {

    }

    // Fare override del metodo per aggiungere i comandi al gruppo Home
    protected addHomeCommands(): Array<UICommandInterface> {
        return new Array<UICommandInterface>();
    }

    // Fare override del metodo per aggiungere i comandi al gruppo Sidebar
    protected addSidebarCommands(): Array<UICommandInterface> {
        return new Array<UICommandInterface>();
    }
    
    // #region AdditionalCommand
    protected addAdditionalCommandsGroups(): Observable<UICommandInterface | null> {

        const moreOptionsCommand = this.getMoreOptionsCommand();
        moreOptionsCommand.loading$.next(true);

        const mobileMenuCommand = this.getMobileMenuCommand();
        mobileMenuCommand.loading$.next(true);

        // About window command
        const manager = new UICommandSettingsManager();
        const cmd = manager.setUICommand(
            CommandTypes.About, 
            CommandFactory.createUICommand(
                async (x) => { await this.orchestrator.about() }, 
                () => of(true)
            )
        );
        this.aboutCommandGroup.addCommand(cmd);
        this.additionalCommandsTab.addGroup(this.aboutCommandGroup, true);
        
        return forkJoin({
            // Verifico se l'utente ha i permessi per gestire il layout
            canAccessToLayout: this.orchestrator.canAccessToLayout(),

            // Verifico se l'utente ha i permessi per accedere alla security
            canAccessToSecurity: this.orchestrator.canAccessToSecurity(),

        }).pipe(map(({ canAccessToLayout, canAccessToSecurity }) => {

            if (canAccessToLayout) {

                // Reset current layout
                const cmdResetCurrentLayout = manager.setUICommand(CommandTypes.ResetLayout,
                    CommandFactory.createUICommand(
                        async (x) => { await this.orchestrator.resetLayout(this.orchestrator.currentLayout.identity) },
                        () => this.orchestrator.currentStateChanged.pipe(map(() => {
                            return this.orchestrator.currentState.value === ViewModelStates.Unchanged || this.orchestrator.currentState.value === ViewModelStates.New;
                        })),
                        (x) => of(false),
                        () => of(true)
                    )
                );
    
                this.customizeFieldsCommandGroup.addCommand(cmdResetCurrentLayout);
    
                // Customize fields
                const cmdCustomizeFields = manager.setUICommand(CommandTypes.CustomizeFields,
                    CommandFactory.createUICommand(
                        async (x) => { await this.orchestrator.customizeLayouts() },
                        () => this.orchestrator.currentStateChanged.pipe(map(() => {
                            return this.orchestrator.currentState.value === ViewModelStates.Unchanged || this.orchestrator.currentState.value === ViewModelStates.New;
                        })),
                        (x) => of(false),
                        () => of(true)
                    )
                );
    
                this.customizeFieldsCommandGroup.addCommand(cmdCustomizeFields);
    
                this.additionalCommandsTab.addGroup(this.customizeFieldsCommandGroup, true);
            }

            if (canAccessToSecurity) {

                // Customize security
                const cmdCustomizeSecurity = manager.setUICommand(CommandTypes.CustomizeSecurity,
                    CommandFactory.createUICommand(
                        async (x) => { await this.orchestrator.customizeSecurity() },
                        () => of(true),
                        (x) => of(false),
                        () => of(true)
                    )
                );
    
                this.securityCommandGroup.addCommand(cmdCustomizeSecurity);
    
                this.additionalCommandsTab.addGroup(this.securityCommandGroup, true);
            }

            moreOptionsCommand.loading$.next(false);

            if (this.reportCommandGroup?.commands?.length > 0) {
                this.menuItemChanged.pipe(switchMap(() => this.reportCommandGroup.commands[0].loading$))
                     .subscribe((loading) => {
                        mobileMenuCommand.loading$.next(loading);
                })
            } else {
                mobileMenuCommand.loading$.next(false);
            }            

            return moreOptionsCommand;
        }));
    }
    // #endregion AdditionalCommand

    protected canReturnCommand(): Observable<boolean> {
        return of(true);
    }
    
    protected isHighlightReturnCommand(): Observable<boolean> {
        return of(true);        
    }

    protected isVisibleReturnCommand(): Observable<boolean> {
        return of(true);
    }

    protected async externalReturnExecuted(title: string, action: (serializedObjectOrString: string) => void) {
        // await this.orchestrator.waitForPendingChangesAsync();

        if (await this.orchestrator.confirmLosingUnsavedDataAsync()) {
            this.orchestrator.currentState.restore();
            action(this.orchestrator.getCurrentIdentity());
            setTimeout(() => {
                window.close();
            }, 100);
        }
    }
}

import { Expose, Type } from "../../../serialization/class-transformer/decorators";
import { FindValuesOptions } from "../../find-options/find-values-options";
import { ServiceInfoIdentity } from "../../service-info/service-info.identity";
import { ZoomIdentity } from "../zoom.identity";
import { OutputDataOrderDto } from "./output-data-order.dto";

export class ZoomConfigurationDto {

    constructor() {
        this.outputDataOrderList = [];
    }

    @Expose()
    public title: string;
    
    @Expose()
    @Type(() => ZoomIdentity)
    public zoomIdentity: ZoomIdentity;

    @Expose()
    @Type(() => ServiceInfoIdentity)
    public serviceInfoIdentity: ServiceInfoIdentity;

    @Expose()
    @Type(() => FindValuesOptions)
    public findValuesOptions: FindValuesOptions;

    @Expose()
    @Type(() => OutputDataOrderDto)
    public outputDataOrderList: OutputDataOrderDto[];

    @Expose()
    public allowEdit: boolean
}
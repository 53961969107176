<div class="container" [attr.data-label]="label">
    <span *ngIf="isLocked" class="is-locked">
        <svg-icon key="locked"></svg-icon>
    </span>
    <a [class.is-disabled]="!isEnabled"
        draggable="false"
        class="description-container"
        tabindex="-1"
        role="button">
        <span [popper]="title"
            [popperDisabled]="!title || title?.length == 0"
            [popperTrigger]="ngxPopperjsTriggers.hover"
            popperApplyClass="info"
            [popperPlacement]="ngxPopperjsPlacements.TOP"
            [popperPreventOverflow]="false"
            [popperHideOnScroll]="true"
            popperAppendTo="body"
            [popperDelay]="1000"
            class="label">
            {{label}}
        </span>
        <span *ngIf="isRequired" class="is-required">*</span>
    </a>
</div>

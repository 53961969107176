<div (keydown.space)="keydownSpace($event)" 
    (click)="click($event)"
    (mouseenter)="mouseEnter($event)"  
    (mouseleave)="mouseLeave($event)"
    #popperError="popper"
    [popper]="tooltipErrTemplate"
    [popperDisabled]="!showErrorTooltip || !errorList || errorList?.length === 0"
    [popperTrigger]="ngxPopperjsTriggers.hover"
    popperApplyClass="error"
    [popperPlacement]="ngxPopperjsPlacements.TOP"
    [popperPreventOverflow]="false"
    [popperHideOnScroll]="true"
    popperAppendTo="body"
    [class.has-error]="showErrorBorder && errorList?.length > 0"
    [class.disabled]="isDisabled"
    class="checkbox-wrapper">

    <label [attr.aria-label]="label"
        [attr.aria-disabled]="isDisabled"
        [popper]="label"
        #popperInfo="popper"
        [popperDisabled]="!showTitleTooltip || !label || label?.length === 0 || errorList?.length > 0"
        [popperTrigger]="ngxPopperjsTriggers.hover"
        [popperDelay]="1000"
        popperApplyClass="info"
        [popperPlacement]="ngxPopperjsPlacements.TOP"
        [popperPreventOverflow]="false"
        [popperHideOnScroll]="true"
        popperAppendTo="body"        
        [attr.for]="id"
        [attr.tabindex]="tabIndex"
        class="checkbox" 
        role="checkbox">
        <input #checkboxBox 
            (change)="change($event)"
            (focus)="onFocus($event)"
            (blur)="onBlur($event)"
            [attr.id]="id"
            [checked]="checkStatus"
            [attr.readonly]="isReadOnly ?? null"
            [disabled]="isDisabled"
            type="checkbox" 
            aria-hidden="true">
        <span class="checkmark" [style.borderColor]="overrideBorderColor ? overrideBorderColor : null" role="checkbox"></span>
    </label>

    <label *ngIf="labelText" class="text-checkbox">{{labelText}}</label>
</div>

<popper-content #tooltipErrTemplate>
    <ng-container *ngFor="let item of errorList">
        {{ item }}
    </ng-container>
</popper-content>

<input #textBox 
    (blur)="blur($event)"
    (ngModelChange)="valueChange($event)"
    (focus)="focus($event)"
    [ngModel]="stringValue"
    #popperError="popper"
    [popper]="tooltipErrTemplate"
    [popperDisabled]="!showErrorTooltip || !errorList || errorList?.length === 0"
    [popperTrigger]="ngxPopperjsTriggers.hover"
    popperApplyClass="error"
    [popperPlacement]="ngxPopperjsPlacements.TOP"
    [popperPreventOverflow]="false"
    [popperHideOnScroll]="true"
    popperAppendTo="body"
    [attr.tabindex]="tabIndex"
    [disabled]="isDisabled ? true: null" 
    [attr.placeholder]="placeholder"
    [ngClass]="customClasses" 
    [class.is-disabled]="isDisabled" 
    [class.has-error]="showErrorBorder && errorList?.length > 0"
    type="text" 
    spellcheck="false"
    autocomplete="autocomplete-off" />

<ng-template ngFor let-commandName [ngForOf]="customCommandList">
    <button class="command-bar-button" (click)="commandName.execute()"
        [disabled]="isDisabled || !(commandName.canExecute$ | async)"
        [attr.title]="commandName.tooltip"><i [ngClass]="commandName.iconClass"></i></button>
</ng-template>

<popper-content #tooltipErrTemplate>
    <ng-container *ngFor="let item of errorList">
        {{ item }}
    </ng-container>
</popper-content>
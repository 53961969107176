import { firstValueFrom, Subject, timer } from 'rxjs';
import { debounce, debounceTime, switchMap, take, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { SnapShotAPIClient } from '../api-clients';
import { OCCAuditDeactivableModel } from '../domain-models';
import { BaseIdentity } from '../domain-models/base-identity';
import { SearchResultlCollection as SearchResultCollection } from '../domain-models/search-result.collection';
import { SnapShotFrameIdentity } from '../domain-models/snap-shot-frame-identity';
import { SnapShotFrame } from '../domain-models/snap-shot-frame';
import { AggregateMetaData } from '../meta-data/aggregate-meta-data';
import { DomainModelMetaData } from '../meta-data/domain-model-meta-data';
import { MetaDataUtils } from '../meta-data/meta-data-utils';
import { GetAllSnapShotsFrameRequest } from '../requests/get-all-snap-shots-frame-request';
import { MessageResourceManager } from '../resources/message-resource-manager';
import { ClassInformationType, ClassInformationUtility, LogService } from '@nts/std/src/lib/utility';
import { BaseViewModelInterface } from './base-view-model.interface';
import { ColumnInfoCollection } from './column-info-collection';
import { ColumnsProvider } from './columns-provider';
import { ColumnsProviderInterface } from './columns_provider.interface';
import { ColumnsUtils } from './columns_utils';
import { CollectionViewModelTypeInspector } from './decorators/collection-view-model-type.decorator';
import { ExtendedFilterAwareInterface } from './extended-filter-aware.interface';
import { OrchestratorViewModelInterface } from './orchestrator-view-model.interface';
import { SearchResultCollectionViewModel } from './search-result.collection-view-model';
import { SnapShotFrameViewModel } from './snap-shot-frame-view-model';
import { SnapShotListViewModelInterface } from './snap-shot-list-view-model.interface';
import { ViewModelStates } from './states/view-model-states';
import { ViewModel } from './view-model';
import { PropertyViewModelPropertyChangedEventArgs } from './property-view-property-changed-event-args';
import { EventEmitter } from '@angular/core';

export class SnapShotListViewModel<
    TModel extends OCCAuditDeactivableModel<TIdentity>,
    TIdentity extends BaseIdentity>
    extends ViewModel<TModel, TIdentity>
    implements SnapShotListViewModelInterface, ExtendedFilterAwareInterface {

    //#region PUBLIC VARS
    
    private _showExtendedFilter = false;

    get showExtendedFilter(): boolean {
        return this._showExtendedFilter;
    }

    set showExtendedFilter(value: boolean) {
        this._showExtendedFilter = value;
        this.onPropertyChanged('showExtendedFilter');
    }
    
    private _enableExtendedFilter = false;

    get enableExtendedFilter(): boolean {
        return this._enableExtendedFilter;
    }

    set enableExtendedFilter(value: boolean) {
        this._enableExtendedFilter = value;
        this.onPropertyChanged('enableExtendedFilter');
    }

    searchResult: SearchResultCollectionViewModel<SnapShotFrameViewModel, SnapShotFrame, SnapShotFrameIdentity>;
    
    /**
     * Passa true se è il primo caricamento
     */
    searchCompleted: Subject<boolean> = new Subject<boolean>();
    selectionChanging = new Subject<SnapShotFrame>();
    selectionChanged = new Subject<SnapShotFrame>();
    selectionUpdated = new Subject<SnapShotFrame>();
    onActionInProgress: Subject<boolean> = new Subject<boolean>();

    collectionViewModelInitialized = new Subject<void>();

    override get currentState(): ViewModelStates {
        return ViewModelStates.Unchanged;
    }

    get selectedItem() {
        return this._selectedItem;
    }

    get isSelected(): boolean {
        return this._isSelected;
    }

    get isFullScreen() {
        return this._isFullScreen;
    }

    set isFullScreen(value) {
        this._isFullScreen = value;
        if (value === true) {
            this.searchResultColumns.forEach((c) => {
                c.isVisible = true;
            });
        } else {
            this.searchResultColumns.forEach((c) => {
                // if (this._mainDescriptionProperty && MetaDataUtils.toCamelCase(this._mainDescriptionProperty.name) === c.propertyName) {
                //     c.isVisible = true;
                // } else if (this._identityPropertyName && this._identityPropertyName === c.propertyName) {
                //     c.isVisible = true;
                // } else {
                //     c.isVisible = false;
                // }
            });

            // se ho cambiato selezione durante lo stato di fullscreen, aggiorno la selezione quando esco dal fullscreen
            if (this.searchResult.selection.length > 0) {
                const selectedDomainModel = this.searchResult.selection[0].getDomainModel();
                if (!selectedDomainModel.currentIdentity.equals(this.selectedItem?.currentIdentity)) {
                    this.selectionChanging.next(selectedDomainModel);
                }
            }
        }
        this.searchResult.columnsChanged.emit();
    }

    get searchResultColumns(): ColumnInfoCollection {
        return this._searchResultColumns;
    }

    get searchInProgress(): boolean {
        return this._searchInProgress;
    }
    //#endregion PUBLIC VARS

    //#region PROTECTED VARS
    protected unsubscribe$: Subject<boolean> = new Subject();
    //#endregion PROTECTED VARS

    //#region PRIVATE VARS
    private _isSelected = true;
    private _orchestratorViewModel: OrchestratorViewModelInterface;
    private _columnsProvider: ColumnsProviderInterface;
    private _rootDomainModelType: any;
    private _searchInProgress = false;    
    private _isFullScreen = false;
    private _selectedItem: SnapShotFrame;
    private _searchResultColumns: ColumnInfoCollection;
    //#endregion PRIVATE VARS

    //#region PUBLIC METHODS

    override async postInit(): Promise<void> {

        this.searchResult.selectionChanged
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((selection) => {
                if (selection.length > 0 && !this.isFullScreen) {
                    this.selectionChanging.next(selection[0].getDomainModel());
                } else {
                    this.selectionChanging.next(null);
                }
            });

        this.searchResult.paginatedCollectionItemsLoaded
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((firstLoad) => {
                this._searchInProgress = false;
                this.searchCompleted.next(firstLoad);
                this.onActionInProgress.next(false);
            });

        this.searchResult.paginatedCollectionItemsError
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((firstLoad) => {
                this._searchInProgress = false;
                this.searchCompleted.next(firstLoad);
                this.onActionInProgress.next(false);
            });
    }

    get actionInProgress(): boolean {
        return this._actionInProgress;
    }

    private _actionInProgress: boolean;

    override onDestroy() {
        super.onDestroy();
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    async init(
        domainModel: TModel,
        aggregateMetaData: AggregateMetaData,
        orchestratorViewModel: OrchestratorViewModelInterface,
        rootDomainModelType: any
    ) {
        this.aggregateMetaData = aggregateMetaData;
        this._modifiedSubscriber = orchestratorViewModel;
        this._orchestratorViewModel = orchestratorViewModel;
        this._rootDomainModelType = rootDomainModelType;
        this.externalRetriever = orchestratorViewModel;

        this._orchestratorViewModel.eventDispatcher.onSnapShotSelected.subscribe(async (isSelected) => {
            
            if (this._orchestratorViewModel.navigationPanelCollapsed && isSelected) {
                this._orchestratorViewModel.eventDispatcher.onNavigationPanelCollapsed.next(false);
            }            

            // Verifico se era già selezionata
            if (this._isSelected === true) {
                this._isSelected = isSelected;
                if (isSelected === true) {
                    this._orchestratorViewModel.eventDispatcher.onMasterAreaSelected.next(false);
                    this.execSearch();
                } else {
                    if (this._orchestratorViewModel.currentState.value === ViewModelStates.Versioned) {
                        this._orchestratorViewModel.restore();
                    }
                }
            } else {
                this._isSelected = isSelected;
                if (isSelected === true) {
                    this._orchestratorViewModel.eventDispatcher.onMasterAreaSelected.next(false);
                    this.collectionViewModelInitialized.next();
                    // qui scatta la collectionViewModelInitialized quindi non è necessario fare la chiamata a execSearch
                } else {
                    if (this._orchestratorViewModel.currentState.value === ViewModelStates.Versioned) {
                        this._orchestratorViewModel.restore();
                    }
                }
            }
        });

        // quando la collection è inizializzata mi sottoscrivo al onNavigationPanelCollapsed
        this.collectionViewModelInitialized.pipe(throttleTime(1000), switchMap(() => this._orchestratorViewModel.eventDispatcher.onNavigationPanelCollapsed))
            .subscribe((isCollapsed) => {
            // Se chiudo la barra di navigazione e avevo selezionato lo snapshot
            // faccio il restore
            if (isCollapsed === true && this.isSelected) {
                if (this._orchestratorViewModel.currentState.value === ViewModelStates.Versioned) {
                    this._orchestratorViewModel.restore();
                }
            } else {
                // Se apro la barra di navigazione e non ho la master view model
                if (this.isSelected) {
                    this.execSearch();
                }
            }
        })

        this.onActionInProgress.subscribe((actionInProgress: boolean) => {
            this._actionInProgress = actionInProgress;
        });

        await this.initViewModel(domainModel, aggregateMetaData, orchestratorViewModel);
        await this.initColumns();

        // Preseleziono lo snapshot se non ho la master view model e non l'ho selezionato
        if (!ClassInformationUtility.checkClassType(this._orchestratorViewModel, ClassInformationType.MasterDetailOrchestratorViewModel) && !this.isSelected) {
            this._isSelected = true;
        }
    }

    async initColumns(): Promise<void> {
        const columnList = ['updateDate', 'updateUser'];
        this._searchResultColumns = this.getGridColumns(
            'SnapShotFrame',
            columnList,
        );
    }

    setSelectedItem(newItem: SnapShotFrame) {
        this._selectedItem = newItem;
        this.selectionChanged.next(newItem);
    }

    resetSelectedItem() {
        this.searchResult.selection = [];
    }

    /**
     *
     * @param entityTypeName
     * @returns
     */
    getGridColumns(
        entityTypeName: string,
        visibilityDefinition: string[] = null,
        editableDefinition: string[] | boolean = null,
        orderDefinition: string[] = null
    ): ColumnInfoCollection {
        return ColumnsUtils.getGridColumns(
            this._columnsProvider,
            entityTypeName,
            visibilityDefinition,
            editableDefinition,
            orderDefinition
        );
    }
    
    async execSearch() {
        this._searchInProgress = true;
        this.onActionInProgress.next(true);
        this.searchResult.getPaginatedCollectionItems = async (take: number, skip: number) => {
            if (this.isSelected === false ||
                this._orchestratorViewModel.currentState.value === ViewModelStates.New ||
                this._orchestratorViewModel.currentState.value === ViewModelStates.NewModified
            ) {
                return [];
            }
            return await this.retrieveSnapShotListAsync(take, skip);
        };
        this.searchResult.datasourceUpdated.emit();
    }
    //#endregion PUBLIC METHODS

    //#region PROTECTED METHODS
    protected override async initViewModel(
        domainModel: TModel,
        aggregateMetadata: AggregateMetaData,
        orchestratorViewModel: OrchestratorViewModelInterface,
    ) {

        // Build grid columns
        const gridColumns = new Map<string, ColumnInfoCollection>();
        const columns = ColumnsUtils.getGridColumnsForBaseTypes('SnapShotFrame', aggregateMetadata);

        columns.metadataShortDescription = orchestratorViewModel.useMessageResourceKey ? MessageResourceManager.Current.getMessageIfExists(
            this.aggregateMetaData.rootMetaData.descriptions.displayNameKey) : this.aggregateMetaData.rootMetaData.descriptions.displayName;

        columns.metadataDescription = orchestratorViewModel.useMessageResourceKey ? MessageResourceManager.Current.getMessageIfExists(
            this.aggregateMetaData.rootMetaData.descriptions.descriptionKey) : this.aggregateMetaData.rootMetaData.descriptions.description;

        gridColumns.set('SnapShotFrame', columns);
        this._columnsProvider = new ColumnsProvider(gridColumns);

        await this.initViewModelWithoutModel(aggregateMetadata, orchestratorViewModel, 'SnapShotList');

        // Popolo la search SearchCollectionViewModel
        await this.buildSearchCollectionViewModel();
    }

    protected async buildSearchCollectionViewModel(models: SnapShotFrame[] = []) {

        const propertyName = 'searchResult';
        const collection = new SearchResultCollection<SnapShotFrame, SnapShotFrameIdentity>(
            null, propertyName, models, false, SnapShotFrame
        );

        // Creo il ViewModel
        const ivm = new SearchResultCollectionViewModel<SnapShotFrameViewModel, SnapShotFrame, SnapShotFrameIdentity>();
        ivm.parent = this;
        await ivm.preInit(SnapShotFrameViewModel);

        const internalCollectionMetaData = this.aggregateMetaData.rootMetaData.internalCollections.find((i) => MetaDataUtils.toCamelCase(i.principalPropertyName) === propertyName);

        await ivm.init(
            collection,
            this.aggregateMetaData,
            this.modifiedSubscriber as OrchestratorViewModelInterface,
            internalCollectionMetaData,
            '',
            true,
            SnapShotFrame
        );

        await ivm.postInit();
        this.searchResult = ivm;
        this.relationViewModels.set(propertyName, ivm as BaseViewModelInterface);

    }

    protected async initViewModelWithoutModel(
        aggregateMetaData: AggregateMetaData,
        orchestratorViewModel: OrchestratorViewModelInterface,
        domainModelTypeName: string
    ): Promise<void> {

        if (aggregateMetaData != null) {
            this.aggregateMetaData = aggregateMetaData;
            this.externalRetriever = orchestratorViewModel;
            if (orchestratorViewModel) {
                this.eventDispatcher = orchestratorViewModel.eventDispatcher;
            }

            const domainModelMetaData = aggregateMetaData.domainModels.find(
                (element: DomainModelMetaData) => MetaDataUtils.toCamelCase(element.name) === MetaDataUtils.toCamelCase(domainModelTypeName));
            if (domainModelMetaData != null) {
                this.domainModelMetaData = domainModelMetaData;
                await this.buildPropertyLists();
                this.metadataShortDescription = this.domainModelMetaData.descriptions.displayName;
                this.metadataDescription = this.domainModelMetaData.descriptions.description;
            }

        }
    }

    protected getCollectionViewModelType(propertyName: string): any {
        return CollectionViewModelTypeInspector.getValue(this, propertyName);
    }

    protected async retrieveSnapShotListAsync(take?: number, skip?: number): Promise<SnapShotFrame[]> {
        const ovm = this.externalRetriever as OrchestratorViewModelInterface;
        const apiClient: SnapShotAPIClient<TModel, TIdentity> = ovm.apiClient instanceof SnapShotAPIClient ? ovm.apiClient : null;
        if (apiClient) {
            const request = new GetAllSnapShotsFrameRequest<TIdentity>();
            request.identity = ovm.rootViewModel.getDomainModel().currentIdentity as TIdentity;
            request.take = take;
            request.skip = skip;
            const response = await firstValueFrom(apiClient.getSnapShotFrameList(request));

            if (response.operationSuccedeed && response.result != null) {
                return response.result.map((i) => new SnapShotFrame(i));
            } else {
                LogService.warn('retrieveSnapShotListAsync failed', response)
            }

        }
        return null;
    }
    //#endregion PROTECTED METHODS
}

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ModalComponentInterface } from '../modal/modal-component.interface';
import { ExternalModalViewModel } from '../../view-models/modal/external-modal-view-model';
import { ActivatedRoute } from '@angular/router';
import { IframeComponent } from '../iframe/iframe.component';

@Component({
    selector: 'nts-external-modal-container',
    templateUrl: 'external-modal-container.component.html',
    styleUrls: ['./external-modal-container.component.scss'],
    standalone: true,
    imports: [
        IframeComponent
    ]
})

export class ExternalModalContainerComponent implements OnInit, ModalComponentInterface {
    viewModel: ExternalModalViewModel;
    inIframe = false;

    @ViewChild('iframe', { static: true }) iframe: ElementRef;

    url: string;
    constructor(private route: ActivatedRoute) {

    }
  
    ngOnInit(): void {
        if (this.route.snapshot.queryParams['iframe'] != null) {
            this.inIframe = this.route.snapshot.queryParams['iframe'] === 'true';
        }
        this.viewModel.initialize(async (iframeUrl: string) => {
            this.url = iframeUrl;
            // Necessario per dare tempo al componente di caricarsi
            // non compromette blocchi ui
            await new Promise(resolve => setTimeout(resolve, 500));
            return this.iframe.nativeElement.contentWindow;
        });
    }
}

<nts-base-date-text-box 
    #baseDateTextBox
    (onDateValueChange)="onDateValueChange($event)"
    (onDateFocus)="onDateFocus($event)" 
    (onDateSelect)="onDateSelect($event)"
    (onDateBlur)="onDateBlur()"
    (onFinishEditing)="onFinishEditing.emit($event)"
    [valueDate]="value"
    [tabIndex]="dateTabIndex"
    [appendTo]="'body'" 
    [isRequired]="false"
    [showButtonBar]="true"
    [isDisabled]="!propertyViewModel.enabled"></nts-base-date-text-box>
import { ColumnInfo } from './column-info';
import { ExtColumnInfo } from './ext-column-info';

export class ColumnInfoCollection extends Array<ColumnInfo | ExtColumnInfo> {

  metadataDescription: string;
  metadataShortDescription: string;
  editableDefinition = null;

}

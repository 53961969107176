import { AggregateMetaData } from '../meta-data/aggregate-meta-data';
import { PropertyMetaData } from '../meta-data/property-meta-data';
import { AssociationMetaData, ExternalMetaData, DomainModelMetaData, InternalCollectionMetaData } from '../meta-data';
import { InternalRelationMetaData } from '../meta-data/internal-relation-meta-data';


export class MetaDataUtils {

    static getPropertyMetaDataFromPath(domainModelMetaData: DomainModelMetaData, propertyMetaDataFullPathName: string): PropertyMetaData {
        const propertyMetaDataFullPathNameSplitted = propertyMetaDataFullPathName.split('.').filter((path) => MetaDataUtils.toCamelCase(path) !== 'selectedItem');
        return this.recursiveFindPropertyMetaDataFromSplittedPath(propertyMetaDataFullPathNameSplitted, domainModelMetaData);
    }

    private static recursiveFindPropertyMetaDataFromSplittedPath(propertyMetaDataFullPathNameSplitted: string[], domainModelMetaData: DomainModelMetaData): PropertyMetaData {
        let ret = null;
        if (propertyMetaDataFullPathNameSplitted.length == 1) {
            ret = domainModelMetaData.getPropertyMetaData(propertyMetaDataFullPathNameSplitted[0]);
        } else if (propertyMetaDataFullPathNameSplitted.length > 1) {
            
            const propertyName = MetaDataUtils.toCamelCase(propertyMetaDataFullPathNameSplitted[0]);
            if (domainModelMetaData.propertyNames.find((p) =>MetaDataUtils.toCamelCase(p) === propertyName)) {
                ret = null;
            } else {
                let assoc: AssociationMetaData = domainModelMetaData.internalRelations.find((r) => MetaDataUtils.toCamelCase(r.principalPropertyName) == propertyName);
                domainModelMetaData = assoc ? (assoc as InternalRelationMetaData)?.dependentMetaData : domainModelMetaData;
                if (assoc == null) {
                    assoc = domainModelMetaData.internalCollections.find((r) => MetaDataUtils.toCamelCase(r.principalPropertyName) == propertyName);
                    domainModelMetaData = assoc ? (assoc as InternalCollectionMetaData)?.dependentMetaData : domainModelMetaData;
                }
                if (assoc == null) {
                    assoc = domainModelMetaData.externals.find((r) => MetaDataUtils.toCamelCase(r.principalPropertyName) == propertyName);
                    domainModelMetaData = assoc ? (assoc as ExternalMetaData)?.dependentAggregateMetaData?.rootMetaData : domainModelMetaData;
                }
                if (assoc != null && domainModelMetaData != null) {
                    propertyMetaDataFullPathNameSplitted.shift();
                    ret = this.recursiveFindPropertyMetaDataFromSplittedPath(propertyMetaDataFullPathNameSplitted, domainModelMetaData);
                }
            }
        }

        return ret;
    }

    static getAssociationMetaDataDataFromPath(domainModelMetaData: DomainModelMetaData, associationFullPathName: string): AssociationMetaData {
        
        const associationFullPathNameSplitted = associationFullPathName.split('.').filter((path) => MetaDataUtils.toCamelCase(path) !== 'selectedItem');
        return this.recursiveFindAssociationMetaDataFromSplittedPath(associationFullPathNameSplitted, domainModelMetaData);
    } 

    private static recursiveFindAssociationMetaDataFromSplittedPath(associationFullPathNameSplitted: string[], domainModelMetaData: DomainModelMetaData): AssociationMetaData {
        
        
        if ((associationFullPathNameSplitted==null) || (associationFullPathNameSplitted.length == 0)) {
            return null;
        }
        const currentProperty = MetaDataUtils.toCamelCase(associationFullPathNameSplitted[0]);
        let ret: AssociationMetaData = domainModelMetaData?.internalCollections.find((c) => MetaDataUtils.toCamelCase(c.principalPropertyName) === currentProperty )
        domainModelMetaData = ret ? (ret as InternalCollectionMetaData)?.dependentMetaData : domainModelMetaData;
        if (ret == null) {
            ret = domainModelMetaData?.internalRelations.find((c) => MetaDataUtils.toCamelCase(c.principalPropertyName) === currentProperty )
            domainModelMetaData = ret ? (ret as InternalRelationMetaData)?.dependentMetaData : domainModelMetaData;
        }
        if (ret == null) {
            ret = domainModelMetaData?.externals.find((c) => MetaDataUtils.toCamelCase(c.principalPropertyName) === currentProperty )
            domainModelMetaData = ret ? (ret as ExternalMetaData)?.dependentAggregateMetaData?.rootMetaData : domainModelMetaData;
        }
        if (ret != null) {
            associationFullPathNameSplitted.shift();
            if (associationFullPathNameSplitted.length > 0) {
                ret = this.recursiveFindAssociationMetaDataFromSplittedPath(associationFullPathNameSplitted, domainModelMetaData as DomainModelMetaData);
            }
        }
        return ret;
    }

    static checkIfPropertyIsACodeForRelation(domainModelMetaData: DomainModelMetaData, propertyName: string, excludeExternalRemotes = false): boolean {
        return (
            this.checkIfPropertyIsACodeForInternal(domainModelMetaData, propertyName) ||
            this.checkIfPropertyIsACodeForInternalCollection(domainModelMetaData, propertyName) ||
            this.checkIfPropertyIsACodeForExternal(domainModelMetaData, propertyName, excludeExternalRemotes)
        );
    }

    static checkIfPropertyIsACodeForInternal(domainModelMetaData: DomainModelMetaData, propertyName: string): boolean {
        const result = domainModelMetaData.internalRelations.find(
            (internal: InternalRelationMetaData) =>
                internal.associationProperties &&
                internal.associationProperties.length > 0 &&
                internal.associationProperties.find(
                    ass => ass.principalPropertyName === propertyName)) != null;

        return result != null ? result : false;
    }
    
    static checkIfPropertyIsAnInternalAssociationCode(parentDomainModelMetaData: DomainModelMetaData, domainModelMetaData: DomainModelMetaData, propertyName: string): boolean {
        const relationMetaData = parentDomainModelMetaData.internalRelations.find((internal: InternalRelationMetaData) => 
            internal.dependentMetaData.name === domainModelMetaData.name
        )
        if (relationMetaData == null) {
            return false;
        }
        if (relationMetaData.associationProperties?.length > 0) {
            return relationMetaData.associationProperties.find(
                ass => ass.dependentPropertyName === propertyName
            ) != null;
        } else {
            return parentDomainModelMetaData.identityNames.indexOf(propertyName) > -1;
        }
    }

    static checkIfPropertyIsAnInternalCollectionAssociationCode(parentDomainModelMetaData: DomainModelMetaData, domainModelMetaData: DomainModelMetaData, propertyName: string): boolean {
        const relationMetaData = parentDomainModelMetaData.internalCollections.find((internal: InternalCollectionMetaData) => 
            internal.dependentMetaData.name === domainModelMetaData.name
        )
        if (relationMetaData == null) {
            return false;
        }
        if (relationMetaData.associationProperties?.length > 0) {
            return relationMetaData.associationProperties.find(
                ass => ass.dependentPropertyName === propertyName
            ) != null;
        } else {
            return parentDomainModelMetaData.identityNames.indexOf(propertyName) > -1;
        }
    }

    static checkIfPropertyIsACodeForInternalCollection(domainModelMetaData: DomainModelMetaData, propertyName: string): boolean {
        const result = domainModelMetaData.internalCollections.find(
            (internal: InternalCollectionMetaData) =>
                internal.associationProperties &&
                internal.associationProperties.length > 0 &&
                internal.associationProperties.find(
                    ass => ass.principalPropertyName === propertyName)) != null;

        return result != null ? result : false;
    }

    static checkIfPropertyIsACodeForExternal(
        domainModelMetaData: DomainModelMetaData,
        propertyName: string,
        excludeRemotes = false
    ): boolean {

        const result = domainModelMetaData.externals.find(
            (external: ExternalMetaData) =>
                external.associationProperties &&
                external.associationProperties.length > 0 &&
                external.associationProperties.find(
                    ass => ass.principalPropertyName === propertyName && (excludeRemotes ? external.isRemote === false : true)
                )
        ) != null;

        return result != null ? result : false;
    }

    static getExternalRelationFromIdentityProperties(domainModelMetadata: DomainModelMetaData): ExternalMetaData {

        // Calcolo i campi chiave della Entity
        const identityProperties = domainModelMetadata.identityNames;

        let externalRelationWithidentities: ExternalMetaData;

        domainModelMetadata.externals.some(externalRelation => {
            if (externalRelation.associationProperties.find((ap) => identityProperties.indexOf(ap.principalPropertyName) > -1)) {
                externalRelationWithidentities = externalRelation;
                return true;
            }
            return null;
        });

        return externalRelationWithidentities;

    }

    static getPropertiesForBaseTypes(domainModelTypeName: string, aggregateMetaData: AggregateMetaData, excludedPropertyList: string[] = []): Map<string, PropertyMetaData> {
        const domainModelMetaData = aggregateMetaData.domainModels.find((dm) => dm.name === domainModelTypeName);
        return new Map<string, PropertyMetaData>(domainModelMetaData?.propertyNames
            .filter((pn) => excludedPropertyList.indexOf(pn) === -1)
            .map((propertyName: string) => {
                const property = this.toCamelCase(propertyName);
                return [
                    property, domainModelMetaData?.getPropertyMetaData(property)
                ]
            }));
    }

    static getExternalRelationsProperties(domainModelTypeName: string, propertyNames: string[], aggregateMetaData: AggregateMetaData): ExternalMetaData[] {
        const domainModel = aggregateMetaData.domainModels.find((metaData: DomainModelMetaData) => metaData.name === domainModelTypeName);
        return domainModel?.externals.filter((
            externalMetaData: ExternalMetaData) => propertyNames.indexOf(this.toCamelCase(externalMetaData.principalPropertyName)) > -1);
    }

    /**
     * TODO: ATTENZIONE toPascalCase non è simmetrico a CamelCase
     */
    static toPascalCase(value: string): string {
        const splitted = value.split('.');
        let stringResult = '';

        for (let i = 0; i < splitted.length; i++) {
            stringResult += splitted[i].charAt(0).toUpperCase() + splitted[i].slice(1);
            if (i !== splitted.length - 1) {
                stringResult += '.';
            }
        }
        return stringResult;
    }

    // OLD
    // static toCamelCase(value: string): string {
    //     const splitted = value.split('.');
    //     let stringResult = '';

    //     for (let i = 0; i < splitted.length; i++) {
    //         stringResult += splitted[i].charAt(0).toLowerCase() + splitted[i].slice(1);
    //         if (i !== splitted.length - 1) {
    //             stringResult += '.';
    //         }
    //     }
    //     return stringResult;
    // }

    // Convertita in typescript da:
    // https://github.com/JamesNK/Newtonsoft.Json/blob/666d9760719e5ec5b2a50046f7dbd6a1267c01c6/Src/Newtonsoft.Json/Utilities/StringUtils.cs#L155
    static toCamelCase(s: string): string {
        if (!s || s.charAt(0) !== s.charAt(0).toUpperCase()) {
            return s;
        }

        const chars = s.split('');

        for (let i = 0; i < chars.length; i++) {
            if (i === 1 && s[i] !== s[i].toUpperCase()) {
                break;
            }

            const hasNext: boolean = (i + 1 < chars.length);
            if (i > 0 && hasNext && chars[i + 1] !== chars[i + 1].toUpperCase()) {
                // if the next character is a space, which is not considered uppercase
                // (otherwise we wouldn't be here...)
                // we want to ensure that the following:
                // 'FOO bar' is rewritten as 'foo bar', and not as 'foO bar'
                // The code was written in such a way that the first word in uppercase
                // ends when if finds an uppercase letter followed by a lowercase letter.
                // now a ' ' (space, (char)32) is considered not upper
                // but in that case we still want our current character to become lowercase
                if (chars[i + 1] === ' ') {
                    chars[i] = chars[i].toLowerCase();
                }

                break;
            }

            chars[i] = chars[i].toLowerCase();
        }

        return chars.join('');
    }
}

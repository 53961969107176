import moment from 'moment';
import { PropertyViewModel } from '../property-view-model';
import { PropertyViewModelInitializationInfo } from '../property-view-model-initialization-info';

export abstract class BaseDateTimePropertyViewModel extends PropertyViewModel<Date>  {

    showTime = false;
    advancingCaret = true;

    private readonly MASKDATEANDTIME: string = 'g'; // General date/time pattern(short time)
    private readonly MASKDATE_DEFAULT: string = 'd'; // d	Short date pattern
    private _editMask: string;


    get editMask(): string {
        if (this._editMask) {
            return this._editMask;
        }

        if (this.showTime) {
            return this.MASKDATEANDTIME;
        } else {
            return this.MASKDATE_DEFAULT;
        }
    }
    set editMask(value: string) {
        this._editMask = value;
    }

    setDefaultValueFromLayoutMetaData(value: string) {
        // TODO
    }
}

export class DateTimePropertyViewModel extends BaseDateTimePropertyViewModel {

    constructor(initInfo: PropertyViewModelInitializationInfo) {
        super(initInfo, false);
    }

    override async resetValue(useDefaultValue: boolean): Promise<void> {
        if (useDefaultValue) {
            await this.setValueAsync(new Date());
        } else {
            await this.setValueAsync(this._defaultFaultBackValue);
        }
    }

    protected getFormattedValue(value: Date) {
        let formattedValue = moment(this.value).format('L');
        if (this.showTime) {
            formattedValue += ' ' + moment(this.value).format('LT');
        }
        return formattedValue;
    }
}

export class NDateTimePropertyViewModel extends BaseDateTimePropertyViewModel {

    constructor(initInfo: PropertyViewModelInitializationInfo) {
        super(initInfo, true);
    }

    override async resetValue(useDefaultValue: boolean): Promise<void> {
        if (useDefaultValue) {
            await this.setValueAsync(new Date());
        } else {
            await this.setValueAsync(this._defaultFaultBackValue);
        }
    }

    protected getFormattedValue(value: Date) {
        if (!value) {
            return '';
        }

        if (this.value == null) { return ''; }
        let formattedValue = moment(this.value).format('L');
        if (this.showTime) {
            formattedValue += ' ' + moment(this.value).format('LT');
        }
        return formattedValue;
    }
}

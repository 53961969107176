import { Component, HostBinding, Input } from '@angular/core';
import { FieldTypes } from '../../../layout-meta-data/field-types.enum';
import { MessageResourceManager } from '../../../resources';
import { BaseFieldComponent } from '../core/base-field/base-field.component';

@Component({
    selector: 'nts-spacer-box',
    templateUrl: './spacer-box.component.html',
    styleUrls: ['./spacer-box.component.scss'],
    standalone: true,
    imports: []
})
export class SpacerBoxComponent extends BaseFieldComponent {

    @HostBinding('class.additional-field')
    @Input() override additionalField = false;

    @HostBinding('class.is-hidden')
    @Input() override isHidden = false;

    @HostBinding('class.full-column')
    @Input() fullColumn = false;

    override type = FieldTypes.Spacer;

    get isDisabled(): boolean {
        return false;
    }
    get path(): string {
        return '';
    }
    get name(): string {
        return '';
    }
    get description(): string {
        return MessageResourceManager.Current.getMessage('std_FieldTypes_' + FieldTypes[FieldTypes.Spacer]);
    }
    get displayName(): string {
        return MessageResourceManager.Current.getMessage('std_FieldTypes_' + FieldTypes[FieldTypes.Spacer]);
    }    
}

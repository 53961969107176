import { ValidationError } from 'class-validator';
import { BaseMessage } from './base-message';

export class BaseError extends BaseMessage {

    stackTrace?: string;
    helpLink?: string;
    innerError?: BaseError;
    exceptionTypeName?: string;

    override get type(): string {
        return 'error';
    }

    override toString(): string {
        let sb = '';
        if (this.description) {
            sb += this.description;
            if ((this.innerError != null) && (this.innerError.description)) {
                sb += '\n' + this.innerError.description;
            }
        } else {
            sb += super.toString();
        }
        return sb;
    }

    static create(validationError: ValidationError, domainModelName: string): BaseError[] {
        const res: BaseError[] = [];

        for (const constraint in validationError.constraints) {
            if (!validationError.constraints.hasOwnProperty(constraint)) {
                continue;
            }
            const messageError = new BaseError();
            messageError.propertyName = validationError.property;
            messageError.objectName = domainModelName;
            messageError.description = '';
            if (this.isJson(validationError.constraints[constraint])) {
                const errorValidationObject = JSON.parse((validationError.constraints[constraint]));
                messageError.description = errorValidationObject.message;
                messageError.code = errorValidationObject.code;
            } else {
                messageError.description = validationError.constraints[constraint];
                messageError.code = 'VALIDATION_ERROR_' + constraint;
            }
            res.push(messageError);
        }

        return res;
    }

    private static isJson(str: string): boolean {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
}

import { NgFor, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from "@angular/core";
import { PopperHelper } from "@nts/std/src/lib/utility";
import { NgxPopperjsDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from "ngx-popperjs";

let nextId = 0;

@Component({
    selector: 'nts-base-checkbox-text-box',
    templateUrl: './base-checkbox-text-box.component.html',
    standalone: true,
    imports: [
        NgxPopperjsModule,
        NgIf,
        NgFor
    ],
    styleUrls: ['./base-checkbox-text-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseCheckboxTextBoxComponent implements OnInit, OnChanges {
    @Input() label = '';
    @Input() tabIndex = -1;
    @Input() isDisabled = false;
    @Input() isReadOnly = false;
    @Input() checkStatus = false;
    @Input() customClasses = '';
    @Input() errorList: string[] = [];
    @Input() showErrorTooltip = true;
    @Input() showTitleTooltip = true;    
    @Input() showErrorBorder = true;
    @Input() labelText: string;
    @Input() listenClickOutside = false;
    @Input() defaultBorderColor = null;
    @Input() activeBorderColor = null;
    @Input() hoverBorderColor = null;

    /**
     * @deprecated use checkStatusChange
     */
    @Output() onChange = new EventEmitter<boolean>();
    
    @Output() onClick = new EventEmitter<void>();

    @Output() checkStatusChange = new EventEmitter<boolean>();
    @Output() onFinishEditing = new EventEmitter();

    @ViewChild('checkboxBox', { static: true }) checkboxBox: ElementRef;
    @ViewChild('popperError', { static: false }) popperError: NgxPopperjsDirective;
    @ViewChild('popperInfo', { static: false }) popperInfo: NgxPopperjsDirective;
    @ViewChild('labelElement', { static: false }) labelElement: ElementRef;

    id = `chk-${nextId++}`;
    documentClickListener: any;
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;
    isActive = false;
    isHover = false;
    overrideBorderColor = null;

    constructor(
        public readonly el: ElementRef,
        private readonly renderer: Renderer2,
        private readonly zone: NgZone,
        private readonly cd: ChangeDetectorRef,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['defaultBorderColor'] || changes['activeBorderColor'] || changes['hoverBorderColor']) {
            this.handleOverridesColors();
        }
        if (changes['errorList']) {
            this.checkPopper();
        }
    }

    mouseEnter(e) {
        this.isHover = true;
        this.handleOverridesColors();
    }

    mouseLeave(e) {
        this.isHover = false;
        this.handleOverridesColors();
    }

    ngOnInit() {
        if (this.listenClickOutside) {
            this.bindDocumentClickListener();
        }
        this.handleOverridesColors();
    }

    onFocus(e) {
        this.isActive = true;
        this.handleOverridesColors();
    }

    onBlur(e) {
        this.isActive = false;
        this.handleOverridesColors();
    }

    handleOverridesColors() {
        if (!this.defaultBorderColor || !this.activeBorderColor || !this.hoverBorderColor) {
            // devono essere impostate tutte e tre le variabili
            return;
        }

        this.overrideBorderColor = this.defaultBorderColor;
        if (this.isActive && !this.isDisabled) {
            this.overrideBorderColor = this.activeBorderColor;
        }
        if (this.isHover && !this.isDisabled) {
            this.overrideBorderColor = this.hoverBorderColor;
        }
    }

    keydownSpace(e: any): void {
        if (!this.isDisabled) {
            e.preventDefault();
            e.stopPropagation();
            this.toggleValue();
        }
    }

    click(e: any): void {
        if (!this.isDisabled && !this.isReadOnly) {
            e.preventDefault();
            e.stopPropagation();
            this.toggleValue();
        } else {
            e.preventDefault();
            e.stopPropagation();
        }
        this.onClick.emit();
    }

    // for testing
    change(e: any): void {
        if (!this.isDisabled && !this.isReadOnly) {
            this.checkStatus = this.checkboxBox.nativeElement.checked;
            this.onChange.emit(this.checkStatus);
            this.checkStatusChange.emit(this.checkStatus);
            this.cd.detectChanges();
        }       
    }

    toggleValue(): void {
        if (!this.isDisabled && !this.isReadOnly) {
            this.checkStatus = !this.checkStatus;
            this.checkboxBox.nativeElement.checked = this.checkStatus;
            this.onChange.emit(this.checkStatus);
            this.checkStatusChange.emit(this.checkStatus);
            this.cd.detectChanges();
        }
    }

    private isOutsideClicked(event: Event) {
        return !(this.el.nativeElement.isSameNode(event.target) || this.el.nativeElement.contains(event.target));
    }

    private bindDocumentClickListener() {
        if (!this.documentClickListener) {
            this.zone.runOutsideAngular(() => {
                const documentTarget: any = this.el ? this.el.nativeElement.ownerDocument : 'document';

                this.documentClickListener = this.renderer.listen(documentTarget, 'click', (event) => {
                    if (this.isOutsideClicked(event)) {
                        this.zone.run(() => {
                            this.onFinishEditing.emit();

                            this.cd.markForCheck();
                        });
                    }

                });
            });
        }
    }

    private checkPopper() {
        if (this.popperError && (this.errorList == null || this.errorList.length === 0)) {
            PopperHelper.hide(this.popperError);
        }   
        if (this.popperInfo && this.errorList?.length > 0) {
            PopperHelper.hide(this.popperInfo);
        }
    }
}

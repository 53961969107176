import { Injectable } from '@angular/core';
import { NumeralService } from './numeral.service';
// Load it locales

@Injectable({
    providedIn: 'root'
})
export class NumeralLoaderService {

    instance: any;

    init() {
        this.instance = NumeralService.Current;
    }
}


<div class="app-body" *ngIf="usePlaceHolder == false">
    <!-- Main content -->
    <main class="main full-width">
        <!-- /.conainer-fluid -->
        <div class="p-3">
            <a class="my-3 entity-nav" [ngClass]="'entity-link-' + i"
                *ngFor="let entity of registeredEntities; let i = index" queryParamsHandling="merge" [routerLink]="[ '/manage/' + entity ]">
                <h4>{{ entity }}</h4>
            </a>
        </div>
    </main>
</div>

<!-- documentPlaceHolder utilizzato per sostituire il contenuto -->
<ng-template #documentPlaceHolder></ng-template>
import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-menu-search',
  templateUrl: './menu-search.component.html',
  styleUrls: ['./menu-search.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    NgIf
  ]
})
export class MenuSearchComponent implements OnInit {

  @Input() searchModel = '';
  @Output() searchModelChange: EventEmitter<any> = new EventEmitter();

  isActive = false;
  isHover = false;
  
  constructor() { }

  ngOnInit(): void {

  }

  onFocus() {
    this.isActive = true;
  }

  onBlur() {
    this.isActive = false;
  }

  onMouseEnter() {
    this.isHover = true;
  }

  onMouseLeave() {
    this.isHover = false;
  }

  updateSearchModel(value: string) {
    this.searchModel = value;
    this.searchModelChange.emit(this.searchModel);
  }

  onClear() {
    this.updateSearchModel('');
  }
}

import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { MessageResourceManager } from '../../../../resources/message-resource-manager';
import { CollectionViewModelInterface } from '../../../../view-models/collection-view-model.interface';
import { ColumnInfoCollection } from '../../../../view-models/column-info-collection';
import { ColumnInfo } from '../../../../view-models/column-info';
import { CdkDrag, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { CheckboxComponent } from '../../../shared/checkbox/checkbox.component';
import { FormsModule } from '@angular/forms';

@UntilDestroy()
@Component({
  selector: 'nts-grid-columns-sidebar',
  templateUrl: './columns-side-bar.html',
  styleUrls: ['./columns-side-bar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: ColumnsSideBarComponent.animations,
  standalone: true,
  imports: [
    NgClass,
    CheckboxComponent,
    FormsModule,
    NgFor,
    NgIf,
    CdkDrag
  ]
})
export class ColumnsSideBarComponent implements OnInit, OnChanges {

  onSafeCollapsing: EventEmitter<boolean> = new EventEmitter<boolean>();
  isSafeCollapsed: boolean = true;
  selectDeselectAllTitle = MessageResourceManager.Current.getMessage('selectDeselectAll_DisplayName');

  allColumnsHide = true;
  filterText = '';
  filterTextPlaceholder = 'filtra...'; // MessageResourceManager.Current.getMessage('filterAllPlaceholder_DisplayName');

  static readonly animations: AnimationTriggerMetadata[] = [
    trigger('isCollapsedChanged', [
      state('false', style({
        width: '350px'
      })),
      state('true', style({
        width: '0',
        // display: 'none'
      })),
      transition('* => *', animate('.3s'))
    ]),
    trigger('isClosing', [
      state('false', style({
        opacity: '1'
      })),
      state('true', style({
        opacity: '0',
      })),
      transition('* => *', animate('.3s'))
    ])
  ];

  @Input() collectionViewModel: CollectionViewModelInterface<any>;

  @Input() isCollapsed: boolean = true;

  @Input() actionInProgress = false;

  @Input() columns: ColumnInfoCollection;

  @Output() columnsSideBarCollapsed: EventEmitter<boolean> = new EventEmitter<boolean>();

  isOpening = false;
  isClosing = false;

  columnsPanelTitle = 'Colonne'; // TODO traduzione

  get isCollapsing() {
    return this.isOpening || this.isClosing;
  }

  constructor(private cd: ChangeDetectorRef) {

  }

  toggleCollapse() {
    if (!this.isOpening && !this.isClosing) {
      setTimeout(() => {
        this.isCollapsed = !this.isCollapsed;
        this.onSafeCollapsing.emit(this.isCollapsed);
      });
    }
  }

  ngOnInit() {
    this.isSafeCollapsed = this.isCollapsed;
    this.onSafeCollapsing.pipe(untilDestroyed(this), debounceTime(150)).subscribe(() => {
      if (!this.isOpening && !this.isClosing) {
        this.isSafeCollapsed = this.isCollapsed;
      }
      this.cd.detectChanges();
    });
    this.collectionViewModel.columnsChanged.pipe(untilDestroyed(this)).subscribe(() => {
      this.cd.detectChanges();
    })

    this.allColumnsHide = !this.columns.every((c) => c.isVisible === true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isCollapsed']) {
      this.onSafeCollapsing.emit(this.isCollapsed);
    }
  }

  animationStarted($event: any) {
    const isCollapsing = $event.toState;
    if (isCollapsing) {
      this.isClosing = true;
      this.isOpening = false;
    } else {
      this.isOpening = true;
      this.isClosing = false;
    }
  }

  animationDone($event: any) {
    setTimeout(() => {
      const isCollapsed = $event.toState;
      this.isOpening = false;
      this.isClosing = false;
      this.columnsSideBarCollapsed.emit(!!(isCollapsed));
    });
  }

  onColumnChangeEvent(isVisible: boolean, col: ColumnInfo) {
    col.isVisible = isVisible;
    this.allColumnsHide = !this.columns.every((c) => c.isVisible === true);
    this.collectionViewModel.columnsChanged.next(null);
  }

  onAllColumnsHideEvent() {
    this.allColumnsHide = !this.allColumnsHide;
    this.columns.forEach(col => {
      col.isVisible = !this.allColumnsHide;
    });
    this.collectionViewModel.columnsChanged.next(null);
  }

  filterFunction(): ColumnInfoCollection {
    if (this.filterText.length > 1) {
      return this.columns.filter(c => (<string>c.header).toLocaleLowerCase().indexOf(this.filterText.toLocaleLowerCase()) > -1) as ColumnInfoCollection;
    } else {
      return this.columns;
    }
  }

  drop(event: any) {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    this.collectionViewModel.columnsChanged.next(null);
  }

  debug(i) {
    console.log(i);
  }
}


import { Component, Input, Inject, forwardRef, ViewEncapsulation, ContentChildren, QueryList, TemplateRef } from '@angular/core';
import { DomHandler } from 'primeng/dom';
import { MenuItem, PrimeTemplate } from 'primeng/api';
import { MenuComponent } from './menu.component';
import { Observable } from 'rxjs';
import { AsyncPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';

export interface ExtendedMenuItem extends MenuItem {
    loading?: Observable<boolean>;
    hightlighted?: Observable<boolean>;
}

@Component({
    selector: '[ntsMenuItemContent]',
    standalone: true,
    template: `
        <ng-container *ngTemplateOutlet="template; context: {$implicit: item, menu, onItemKeyDown}"></ng-container>
        <a *ngIf="!template" (keydown)="onItemKeyDown($event)" 
            [attr.href]="item.url||null" 
            class="p-menuitem-link" [class.is-loading]="item.loading | async" [class.is-hightlighted]="item.hightlighted | async"
            [attr.tabindex]="item.disabled ? null : '0'" 
            [attr.data-automationid]="item.automationId" 
            [attr.target]="item.target" 
            [attr.title]="item.title"
            [attr.id]="item.id"
            [ngClass]="{'p-disabled':item.disabled}" 
            (click)="menu.itemClick($event, item)" role="menuitem">
            <span class="p-menuitem-icon" *ngIf="item.icon" [ngClass]="item.icon"></span>
            <span class="p-menuitem-text" *ngIf="item.escape !== false; else htmlLabel">{{item.label}}</span>
            <ng-template #htmlLabel>
                <span class="p-menuitem-text" [innerHTML]="item.label"></span>
            </ng-template>
            <span class="p-loader-container">
                <svg-icon key="refresh-animated"></svg-icon>
            </span>
        </a>
    `,
    encapsulation: ViewEncapsulation.None,
    imports: [
        NgClass,
        NgIf,
        SvgIconComponent,
        AsyncPipe,
        NgTemplateOutlet
    ],
    host: {
        'class': 'p-element'
    }
})
export class MenuItemContentComponent {

    @Input("ntsMenuItemContent") item: ExtendedMenuItem;
    @Input() template: TemplateRef<any>;

    @ContentChildren(PrimeTemplate) templates: QueryList<any>;

    menu: MenuComponent;

    constructor(@Inject(forwardRef(() => MenuComponent)) menu) {
        this.menu = menu as MenuComponent;
        this.onItemKeyDown = this.onItemKeyDown.bind(this);
    }

    onItemKeyDown(event) {
        let listItem = event.currentTarget.parentElement;

        switch (event.code) {
            case 'ArrowDown':
                var nextItem = this.findNextItem(listItem);
                if (nextItem) {
                    nextItem.children[0].focus();
                }

                event.preventDefault();
                break;

            case 'ArrowUp':
                var prevItem = this.findPrevItem(listItem);
                if (prevItem) {
                    prevItem.children[0].focus();
                } else {
                    // Riporta il focus sul pulsante toggle
                    this.menu.target.focus();
                    // Nasconde il menu
                    this.menu.hide();
                }

                event.preventDefault();
                break;

            case 'Space':
            case 'Enter':
                if (listItem && !DomHandler.hasClass(listItem, 'p-disabled')) {
                    listItem.children[0].click();
                }

                event.preventDefault();
                break;

            default:
                break;
        }
    }

    findNextItem(item) {
        let nextItem = item.nextElementSibling;

        if (nextItem)
            return DomHandler.hasClass(nextItem, 'p-disabled') || !DomHandler.hasClass(nextItem, 'p-menuitem') ? this.findNextItem(nextItem) : nextItem;
        else
            return null;
    }

    findPrevItem(item) {
        let prevItem = item.previousElementSibling;

        if (prevItem)
            return DomHandler.hasClass(prevItem, 'p-disabled') || !DomHandler.hasClass(prevItem, 'p-menuitem') ? this.findPrevItem(prevItem) : prevItem;
        else
            return null;
    }
}
import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'nts-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports: [
    NgIf
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {

  @Input() isVisible = false;
  @Input() skipHeader = true;
  @Input() animation = false;
  @Input() text = '';
  // @Input() icon = '';
  // @Input() iconColor = '#3d73eb';
  // @Input() iconSize = '60px';

  constructor() {
  }
}

import { ModalViewModelInterface } from '../modal/modal-view-model.interface';
import { ZoomResult } from '../../domain-models/zoom/zoom-result';
import { ModalService } from '../modal/modal.service';
import { Injectable } from '@angular/core';
import { ViewModelTypeDecorator } from '../../decorators/view-model-type.decorator';
import { BaseZoomOrchestratorViewModel } from './base-zoom-orchestrator-view-model';
import { ZoomApiClient } from '../../api-clients/zoom/zoom-api-client';
import { ZoomUIStarterArgs } from './zoom-ui-starter-args';
import { UIResultCommandInterface } from '../commands/ui-result-command.interface';
import { UIResultCommand } from '../commands/ui-result-command';
import { ToastMessageService } from '../../components/layout/toast-message/toast-message.service';
import { ZoomAvailablesFilterPipe } from '../../components/zoom/zoom-availables-filter.pipe.ts';

@Injectable()
@ViewModelTypeDecorator(ZoomOrchestratorViewModel)
export class ZoomOrchestratorViewModel extends BaseZoomOrchestratorViewModel implements ModalViewModelInterface<ZoomUIStarterArgs, ZoomResult> {

    modalCommands: Array<UIResultCommandInterface<ZoomResult>> = new Array<UIResultCommand<ZoomResult>>();
    modalTitle = '';
    modalSubtitle = '';
    showFooter = true;
    cancelAction = null; // TODO verificare e sistemare

    constructor(
        apiClient: ZoomApiClient,
        modalService: ModalService,
        toastMessageService: ToastMessageService,
        zoomAvailablesFilterPipe: ZoomAvailablesFilterPipe,
    ) {
        super(apiClient, modalService, toastMessageService, zoomAvailablesFilterPipe);
        this.modalCommands.push(this.results.rowDoubleClickCommand);
    }
}

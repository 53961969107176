import { BaseValidation } from './base-validation';
import { ValidationArguments } from 'class-validator';
import { MessageCodes } from '../../../resources/message-codes';
import { TimeSpan } from '../../time-span';
import { ValidationErrorCodes } from '../../../resources/validation-error-codes';
import { CodeValueMessageArg } from '../../../resources/code-value-message-arg';
import { MessageResourceManager } from '../../../resources/message-resource-manager';


export class RangeValidator {

    private from: Date | number | TimeSpan;
    private to: Date | number | TimeSpan;
    private displayName: string;

    errorMessage: string;
    messageCode: string;

    constructor(from: Date | number | TimeSpan, to: Date | number | TimeSpan, displayName: string) {
        this.from = from;
        this.to = to;
        this.displayName = displayName;
    }

    validate(value: Date | number | TimeSpan){
        let isValid = true;
        switch (typeof value) {
            case 'number':
                isValid = isValid && this.validateNumberRange(
                    value as number,
                    this.from as number,
                    this.to as number,
                    this.displayName
                );
                break;
            case 'object':
                if (value instanceof TimeSpan) {
                    isValid = isValid && this.validateTimeSpanRange(
                        value as TimeSpan,
                        this.from as TimeSpan,
                        this.to as TimeSpan,
                        this.displayName
                    );
                    this.messageCode = null;
                } else {
                    isValid = isValid && this.validateDateRange(
                        value as Date,
                        this.from as Date,
                        this.to as Date);
                    this.errorMessage = `Data non compresa tra ${this.dateToString(this.from as Date)} e ${this.dateToString(this.to as Date)}`
                    this.messageCode = null;
                }
                break;                
        }
        return isValid;
    }

    getMessageWithTag(value: string, tag: string, messageCode: string): string {
        return this.getMessageWithCodeValues(messageCode, [{ code: tag, value }]);
    }

    getMessageWithCodeValues(baseMessage: string, tags: { code: string, value: string }[]): string {
        const arr = new Array<CodeValueMessageArg>();
        for (const tag of tags) {
            const arg = new CodeValueMessageArg();
            arg.code = tag.code;
            arg.value = tag.value;
            arr.push(arg);
        }
        return MessageResourceManager.Current.getMessageWithArgs(baseMessage, arr);
    }

    private validateNumberRange(value: number, minValue: number, maxValue: number, displayName: string): boolean {
        if (minValue && maxValue) {
            if (value < minValue || value > maxValue) {
                this.messageCode = ValidationErrorCodes.NumberOutOfRange;
                this.errorMessage = this.getMessageWithCodeValues(
                    MessageCodes.PropertyValueNotInRange,
                    [
                        { code: MessageCodes.PropertyValueNotInRange_TAG_NomeCampo, value: displayName },
                        { code: MessageCodes.PropertyValueNotInRange_TAG_MinValue, value: minValue.toString() },
                        { code: MessageCodes.PropertyValueNotInRange_TAG_MaxValue, value: maxValue.toString() },
                    ]
                )
                return false;
            }
        }        
        if (minValue) {
            if (value < minValue) {
                this.messageCode = ValidationErrorCodes.NumberMustBeGreater;
                this.errorMessage = this.getMessageWithCodeValues(
                    MessageCodes.PropertyMustBeGreaterThen,
                    [
                        { code: MessageCodes.PropertyMustBeGreaterThen_TAG_NomeCampo, value: displayName },
                        { code: MessageCodes.PropertyMustBeGreaterThen_TAG_Valore, value: minValue.toString() }
                    ]
                )
                return false;
            }
        }
        if (maxValue) {
            if (value > maxValue) {
                this.messageCode = ValidationErrorCodes.NumberMustBeLess;
                this.errorMessage = this.getMessageWithCodeValues(
                    MessageCodes.PropertyMustBeLessThen,
                    [
                        { code: MessageCodes.PropertyMustBeLessThen_TAG_NomeCampo, value: displayName },
                        { code: MessageCodes.PropertyMustBeLessThen_TAG_Valore, value: maxValue.toString() }
                    ]
                )
                return false;
            }
        }
        return true;
    }

    private validateTimeSpanRange(value: TimeSpan, min: TimeSpan, max: TimeSpan, displayName: string): boolean {
        if (min) {
            if (value < min) {
                this.errorMessage = this.getMessageWithCodeValues(
                    MessageCodes.PropertyMustBeGreaterThen,
                    [
                        { code: MessageCodes.PropertyMustBeGreaterThen_TAG_NomeCampo, value: displayName },
                        { code: MessageCodes.PropertyMustBeGreaterThen_TAG_Valore, value: min.toString() }
                    ]
                )
                return false;
            }
        }

        if (max) {
            if (value > max) {
                this.errorMessage = this.getMessageWithCodeValues(
                    MessageCodes.PropertyMustBeGreaterThen,
                    [
                        { code: MessageCodes.PropertyMustBeLessThen_TAG_NomeCampo, value: displayName },
                        { code: MessageCodes.PropertyMustBeLessThen_TAG_Valore, value: max.toString() }
                    ]
                )
                return false;
            }
        }
        return true;
    }

    private validateDateRange(value: Date, minDate: Date, maxDate: Date): boolean {
        if (minDate) {
            if (value < minDate) {
                return false;
            }
        }

        if (maxDate) {
            if (value > maxDate) {
                return false;
            }
        }
        return true;
    }

    private dateToString(value: Date): string {
        if (value) {
            return value.toLocaleString();
        }
        return '';
    }
}

export class RangeValidation extends BaseValidation<Date | number | TimeSpan> {

    private from: Date | number | TimeSpan;
    private to: Date | number | TimeSpan;

    constructor(from: Date | number | TimeSpan, to: Date | number | TimeSpan) {
        super()
        this.from = from;
        this.to = to;
    }

    validateImplementation(value: Date | number | TimeSpan, args: ValidationArguments): boolean {
        
        const validator = new RangeValidator(this.from, this.to, this.getDisplayName(args));
        const success = validator.validate(value);
        if (!success) {
            this.messageCode = validator.messageCode;
            this.errorMessage = validator.errorMessage;
        }
        return success;
    }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { merge, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ExternalFieldInputMode, ExternalFieldMetaData } from "../../../layout-meta-data/external-field-meta-data";
import { FieldTypes } from "../../../layout-meta-data/field-types.enum";
import { MetaDataUtils } from "../../../meta-data/meta-data-utils";
import { MessageResourceManager } from "../../../resources/message-resource-manager";
import { ExternalViewModelInterface } from "../../../view-models/external-view-model.interface";
import { PropertyViewModelInterface } from "../../../view-models/property-view-model.interface";
import { BaseExternalPropertyTextBox } from "../core/base-external-property-text-box";
import { ExternalFieldComponent } from "../core/external-field/external-field.component";
import { LabelBoxComponent } from "../core/base/label-box/label-box.component";
import { ExtEnumTextBoxComponent } from "../core/ext-enum-text-box/ext-enum-text-box.component";
import { ExtNewAutocompleteTextBoxComponent } from "../core/ext-new-autocomplete-text-box/ext-new-autocomplete-text-box.component";

@UntilDestroy()
@Component({
    selector: 'nts-external-list',
    templateUrl: './external-list.component.html',
    styleUrls: ['./external-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        LabelBoxComponent,
        ExtEnumTextBoxComponent,
        ExtNewAutocompleteTextBoxComponent
    ]
})
export class ExternalListComponent extends ExternalFieldComponent implements OnInit, OnChanges {
    
    /**
     * Property valorizzata solo dal layout custom, vincerà sul externalPropertyViewModel.isVisible solo se sarà impostata a false
     */
    @Input() override isHidden = false;

    @HostBinding('class.additional-field')
    @Input() override additionalField = false;

    @HostBinding('class.full-column')
    @Input() fullColumn = false;

    @Input() externalPropertyViewModel: ExternalViewModelInterface;
    @Input() decodeDescription?: PropertyViewModelInterface;

    @Input() decodeProperties: string[] = null;
    @Input() searchProperties: string[] = null;

    @Input() code: PropertyViewModelInterface;
    @Input() showCodeInDescription: boolean = false;
    @Input() labelPosition = '';
    @Input() isDisabled: boolean = false;
    @Input() tabIndex: number;
    @Input() isLabelZoomEnabled = true;
    @Input() primaryColor = null;
    @Input() scrollElementClass = 'layout-main scrollable-content'
    @Input() appendTo = 'app-root' //'.layout-main.scrollable-content';

    @Output() externalPropertyViewModelInitialized = new EventEmitter<void>();

    @ViewChild('textBox', { static: false }) set content(content: BaseExternalPropertyTextBox<any>) {
        this.textBox = content;
    }

    /**
     * Property calcolata in base alle due property: isHidden e externalPropertyViewModel.isVisible
     */
    @HostBinding('class.is-hidden')
    isHiddenCalculated = false;

    override type = FieldTypes.ExternalList;

    get isFullWidth(){
        return (this.labelPosition === 'top' ? true: false);
    }

    get name(): string {
        return MetaDataUtils.toCamelCase(this.externalPropertyViewModel.externalMetaData.principalPropertyName);
    }

    get path(): string {
        return this.externalPropertyViewModel.reservedPath;
    }

    get description(): string {
        return this.externalPropertyViewModel.externalMetaData.descriptions.description?.length > 0 ? this.externalPropertyViewModel.externalMetaData.descriptions.description : MessageResourceManager.Current.getMessage(this.externalPropertyViewModel.externalMetaData.descriptions.descriptionKey);
    }

    get displayName(): string {
        return this.externalPropertyViewModel.externalMetaData.descriptions.displayName?.length > 0 ? this.externalPropertyViewModel.externalMetaData.descriptions.displayName : MessageResourceManager.Current.getMessage(this.externalPropertyViewModel.externalMetaData.descriptions.displayNameKey);
    }

    get externalCodes(): ExternalFieldMetaData[] {
        const externalFieldCode = new ExternalFieldMetaData();
        externalFieldCode.inputMode = ExternalFieldInputMode.ExternalList;
        externalFieldCode.name = this.code.propertyName;
        externalFieldCode.displayName = this.code.metadataShortDescription;
        externalFieldCode.path = '' //(this.path.length > 0 ? (this.path + '.') : '') + this.name;
        return [externalFieldCode];
    }

    get externalCodesAvailables(): string[] {
        return this.externalPropertyViewModel.externalMetaData.dependentAggregateMetaData.rootMetaData.identityNames.map((s) => MetaDataUtils.toCamelCase(s));
    }

    get externalDecodes(): string[] {
        return [this.decodeDescription.propertyName];
    }

    get externalDecodesAvailables(): string[] {
        if (!this._externalDecodesAvailables) {
            this._externalDecodesAvailables = this.externalPropertyViewModel.externalMetaData.dependentAggregateMetaData.rootMetaData.strings.map((s) => MetaDataUtils.toCamelCase(s.name)).filter((s) => this.externalCodesAvailables.indexOf(s) === -1);
        }
        return this._externalDecodesAvailables;
    }

    protected destroyOnChange$: Subject<void> = new Subject<void>();

    private textBox: BaseExternalPropertyTextBox<any>;
    private _externalDecodesAvailables: string[];
    
    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        if (!this.externalPropertyViewModel) { throw new Error('Missing viewModel for externalPropertyViewModel!'); }
        if (!this.code) { throw new Error('Missing viewModel for code!'); }
        let decodeProperties = null;
        if (this.decodeDescription) {

            if (this.decodeDescription?.isCustom === false) {
                decodeProperties =  [this.decodeDescription.propertyMetaData.name];
            } else {
                decodeProperties = [this.decodeDescription.propertyName];
            }
                       
        }
        if (this.decodeProperties?.length > 0) {
            decodeProperties = this.decodeProperties;
        }

        if (this.showCodeInDescription == null) {  
            this.showCodeInDescription = this.externalPropertyViewModel.showCode;
        }
        this.decodeProperties = decodeProperties;
    }

    ngOnChanges(changes: SimpleChanges) {
        
        if (changes['isHidden']) {
            if (changes['isHidden']?.currentValue === true) {
                this.isHiddenCalculated = true;
            } else {
              this.isHiddenCalculated = !this.externalPropertyViewModel?.isVisible;
            }
        } else if (changes['externalPropertyViewModel']) {
            this.destroyOnChange$.next();

            merge(
                this.externalPropertyViewModel.externalDomainModelChanged,
                this.externalPropertyViewModel.decodeCompleted,
            ).pipe(
                untilDestroyed(this),
                takeUntil(this.destroyOnChange$)
            ).subscribe(() => {
                this.cd.detectChanges();
            });
        
            this.externalPropertyViewModelInitialized.emit();
        }
    }

    openZoom() {
        if ((this.isDisabled || !this.code.isEnabled || this.externalPropertyViewModel.isEnabled === false) && this.externalPropertyViewModel?.securityAccess == null) {
            this.textBox.startPresentation(null, true, false);
            return;
        } 
        if (this.externalPropertyViewModel?.securityAccess == null) {
            this.textBox.zoom();
        }
    }
}
export class RouteChangeParams {
    fullUrl?: string;
    inBlank?: boolean;   // Funziona solo con fullUrl
    rootModelFullName?: string;
    routeParam?: string;
    queryParams?: string;
    addCredentials?: boolean;
    updateOnlyUrl: boolean;
    shortDescription?: string;

    /**
     * Passare un internal Full url se si vuole navigare verso un link interno, che non ha un rootModelFullName
     */
    internalFullUrl?: string;
}
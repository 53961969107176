import { Injectable, InjectionToken } from '@angular/core';

/**
 * Utile per accedere alle informazioni dell'ambiente dev dalla libreria
 */
export const UNPROXIED_ENVIRONMENT = new InjectionToken<EnvironmentConfiguration>('unproxied.environment');

export const unproxiedEnvironmentFactory = (devEnvironment: EnvironmentConfiguration) => {
    return (env: EnvironmentConfiguration) => {
        if (env.envType === 'DEV - PROXY') {
            return devEnvironment
        }
        return null;
    }
}; 

// la costante environment deve contenere le stesse chiavi tra il file environment.ts e il file environment.prod.ts
// inoltre se vuoi utilizzare le chiavi fuori devi aggiornare l'environment-configuration.ts (STD) e inizializzare
// le chiavi in std.module.ts.

@Injectable()
export class EnvironmentConfiguration {
    production!: boolean;
    baseAppUrl!: string;
    authenticationAppUrl!: string;
    prefixApi!: string;
    envType!: string;
    appTitle!: string;
    appSubTitle!: string;
    logLevel!: number;
    isEnterpriseBarrierRequired!: boolean;
}
import moment from 'moment';
import { TransformationType } from '../serialization/class-transformer/enums/transformation-type.enum';

export class DateTime {

    static formatToString(value: Date): string {
        return value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null;
    }

    static formatToStringLocalHumanReadable(value: DateTime, format = 'DD-MM-YYYY'): string {
        return value ? moment(value).format(format) : null;
    }

    static getLocalFormat(showTime = true) {
        let inputFormat = moment().localeData().longDateFormat('L')
        if (showTime === true) {
            inputFormat += ' HH:mm';
        }
        return inputFormat;
    }

    static formatFromString(value: string, customFormat = 'YYYY-MM-DD HH:mm:ss'): Date {

        if (value == null) {
            return null;
        }

        // Check if is a date
        if (value as any instanceof Date) {
            return value as any;
        }

        // 2018-09-27 10:00:00.0 +02:00 ma anche 2018-10-02T00:00:00-03:00"
        const dateString = value.substring(0, 18);
        return moment(dateString, customFormat).toDate();
    }

    static transform(propertyName, value, obj, type) {
        // 2018-09-27 10:00:00.0 +02:00
        if (type === TransformationType.CLASS_TO_PLAIN) {
            return DateTime.formatToString(obj[propertyName]);
        } else {
            return DateTime.formatFromString(value);
        }
    }
}

import { Component } from '@angular/core';
import { EnterpriseSelectorViewModel } from '../../view-models/modal/enterprise-selector-modal-view-model';
import { ModalComponentInterface } from '../modal';
import { BaseEnumTextBoxComponent } from '../controls/core/base/base-enum-text-box/base-enum-text-box.component';

@Component({
  selector: 'nts-enterprise-selector-container',
  templateUrl: './enterprise-selector-container.component.html',
  styleUrls: ['enterprise-selector-container.component.scss'],
  standalone: true,
  imports: [
    BaseEnumTextBoxComponent
  ]
})
export class EnterpriseSelectorContainerComponent implements ModalComponentInterface {

  viewModel: EnterpriseSelectorViewModel;

  async initialize() :Promise<void> {
    await this.viewModel.initialize();
  }
}

import { HttpClient } from '@angular/common/http';
import { StaticProvider } from '@angular/core';
import { WebApiServiceAgent } from './web-api-service-agent';
import { ProvidersForServiceAgent } from './providers-for-service-agent';
import { AuthService } from '../auth/auth.service';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { OnlineService } from '@nts/std/src/lib/utility';
import { ResponseCacheService } from '../responses/response-cache.service';

export class ProvidersForApiClient {

    static staticProvidersForApiClient(
        apiClientType: any,
        httpClient: HttpClient,
        environmentConfiguration: EnvironmentConfiguration,
        authService: AuthService,
        onlineService: OnlineService,
        responseCacheService: ResponseCacheService,
        webApiServiceAgent: WebApiServiceAgent
    ): StaticProvider[] {

        return [
            ...ProvidersForServiceAgent.allStatic(
                httpClient, 
                environmentConfiguration, 
                authService, 
                onlineService,
                responseCacheService,
                webApiServiceAgent
            ),
            {
                provide: apiClientType,
                deps: [WebApiServiceAgent, OnlineService]
            }
        ];
    }
}

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AngularPlugin } from "@microsoft/applicationinsights-angularplugin-js";
import { ApplicationInsights, IConfig, IConfiguration, ICustomProperties, IDependencyTelemetry, IEventTelemetry, IExceptionTelemetry, IMetricTelemetry, IPageViewTelemetry, ITelemetryPlugin, ITraceTelemetry, Tags } from "@microsoft/applicationinsights-web";
import { EnvironmentConfiguration } from "@nts/std/src/lib/environments";
import { TenantProfileTelemetryAwareInterface } from "./tenant-profile-telemetry-aware.interface";
import { UserProfileTelemetryAwareInterface } from "./user-profile-telemetry-aware.interface";

@Injectable({
    providedIn: 'root'
})
export class TelemetryService {
    private angularPlugin = new AngularPlugin();
    private appInsights!: ApplicationInsights;
    private internalInitialized = false;
    private userProfile!: UserProfileTelemetryAwareInterface | null;
    private tenantProfile!: TenantProfileTelemetryAwareInterface | null;
    private internalCanUseAuthenticatedUserContext!: boolean;

    static telemetryService: TelemetryService;

    get initialized() {
        return this.internalInitialized;
    }

    constructor(
        private router: Router,
        private env: EnvironmentConfiguration
    ) {}
    
    init(
        connectionString: string,
        autoRouteTracking: boolean,
        telemetryDataContext: ICustomProperties,
        canUseAuthenticatedUserContext: boolean,
        accountId: string | null = null
    ) {

        if (!connectionString || connectionString?.length === 0) {
            return;
        }

        this.internalCanUseAuthenticatedUserContext = canUseAuthenticatedUserContext;

        let config: IConfiguration & IConfig = {
            connectionString,
            extensions: [this.angularPlugin as any],
            extensionConfig: undefined,
            accountId: accountId && accountId?.length > 0 ? 
                accountId
                    .trim()
                    .replace(/ /gi, '')
                    .replace(/\./gi, '') :
                undefined,
            cookieDomain: (this.env.production && this.env.envType === 'PROD') ? ".ntsinformatica.it" : "",
        };

        if (autoRouteTracking) {
            // auto router tracking, default pageview duration will be set to 0
            const extensionConfig = {
                [this.angularPlugin.identifier]: {
                    router: this.router
                }
            };

            config = {...config, extensionConfig}
        }

        this.appInsights = new ApplicationInsights({
            config
        });

        this.appInsights.loadAppInsights();
        this.appInsights.addTelemetryInitializer(envelope => {
            envelope.tags = envelope.tags || [];
            //Add a custom property
            envelope.data = {...telemetryDataContext};
        });
        this.internalInitialized = true;
    }

    private eraseCookie(name: string) {   
        document.cookie = name+'=; Max-Age=-99999999;';
    }

    set setCanUseAuthenticatedUserContext(value: boolean) {
        this.internalCanUseAuthenticatedUserContext = value;
    }

    setAuthenticatedUserContext(
        userProfile: UserProfileTelemetryAwareInterface | null = null, 
        tenantProfile: TenantProfileTelemetryAwareInterface | null = null
    ) {

        
        userProfile = userProfile ?? this.userProfile;
        tenantProfile = tenantProfile ?? this.tenantProfile;
        if (this.internalCanUseAuthenticatedUserContext && userProfile && tenantProfile) {
            const uniqueUserProfile = this.getHumanReadableIdFromUserProfile(userProfile);
            const uniqueTenantProfile = this.getHumanReadableIdFromTenantProfile(tenantProfile);
    
            this.appInsights.setAuthenticatedUserContext(
                uniqueUserProfile,
                uniqueTenantProfile?.length > 0 ? uniqueTenantProfile : undefined,
                true
            )
        }        
    }

    getHumanReadableIdFromUserProfile(userProfile: UserProfileTelemetryAwareInterface): string {
        return (userProfile?.id ?? '') + 
            (
                userProfile?.loginInformation?.email ? 
                ('-' + userProfile?.loginInformation?.email
                    .split(' ').join('')    // https://github.com/microsoft/ApplicationInsights-JS/blob/master/API-reference.md#setauthenticatedusercontext
                    .split(',').join('')
                    .split(';').join('')
                    .split('=').join('')
                    .split('|').join('')
                ) : ''
            );        
    }

    
    getHumanReadableIdFromTenantProfile(tenantProfile: TenantProfileTelemetryAwareInterface): string {
        return (tenantProfile?.id ?? '') + 
        (
            tenantProfile?.businessName ? 
            ('-' + tenantProfile?.businessName
                .split(' ').join('')    // https://github.com/microsoft/ApplicationInsights-JS/blob/master/API-reference.md#setauthenticatedusercontext
                .split(',').join('')
                .split(';').join('')
                .split('=').join('')
                .split('|').join('')
              ) :
            ''
        ) + 
        (
            tenantProfile?.vatNumber ? 
            ('-' + tenantProfile?.vatNumber
                .split(' ').join('')    // https://github.com/microsoft/ApplicationInsights-JS/blob/master/API-reference.md#setauthenticatedusercontext
                .split(',').join('')
                .split(';').join('')
                .split('=').join('')
                .split('|').join('')
              ) :
            ''
        );    
    }

    clearAuthenticatedUserContext() {
        if (this.initialized) {
            this.appInsights.clearAuthenticatedUserContext();
        }
    }

    // expose tracking methods 
    static trackEvent(event: IEventTelemetry, customProperties?:ICustomProperties) {
        this.telemetryService.trackEvent(event, customProperties);
    }
    
    startTrackEvent(name?: string) {
        this.appInsights.startTrackEvent(name);
    }
    
    trackEvent(event: IEventTelemetry, customProperties?: ICustomProperties) {
        if (this.initialized) {
            this.appInsights.trackEvent(event, customProperties);
        }
    }

    stopTrackEvent(name: string, properties?: { [p: string]: string }, measurements?: { [p: string]: number }): any {
        this.appInsights.stopTrackEvent(name, properties, measurements);
    }

    static trackPageView(pageView?:IPageViewTelemetry) {
        this.telemetryService.trackPageView(pageView);
    }
    
    trackPageView(pageView?:IPageViewTelemetry) {
        if (this.initialized) {
            this.appInsights.trackPageView(pageView);
        }        
    }
    
    startTrackPage(name?: string) {
        this.appInsights.startTrackPage(name);
    }
    
    stopTrackPage(name?: string, url?: string, properties?: { [name: string]: string }, measurements?: { [name: string]: number }) {
        this.appInsights.stopTrackPage(name, url, properties, measurements);
    }
    
    trackMetric(metric:IMetricTelemetry, properties?: ICustomProperties) {
        this.appInsights.trackMetric(metric, properties);
    }
    
    trackException(exception: IExceptionTelemetry,  properties?: ICustomProperties) {
        this.appInsights.trackException(exception, properties);
    }
    
    trackTrace(message: ITraceTelemetry, properties?: ICustomProperties) {
        this.appInsights.trackTrace(message, properties);
    }
    
    trackDependencyData(dependency: IDependencyTelemetry) {
        this.appInsights.trackDependencyData(dependency);
    }
    
    flush() {
        this.appInsights.flush();
    }

    updateUserProfile(userProfile: UserProfileTelemetryAwareInterface) {
        this.userProfile = userProfile;
        this.setAuthenticatedUserContext();
    }

    updateTenantProfile(tenantProfile: TenantProfileTelemetryAwareInterface) {
        this.tenantProfile = tenantProfile;
        this.setAuthenticatedUserContext();
    }

    clearProfiles() {
        this.userProfile = null; 
        this.tenantProfile = null;
        this.clearAuthenticatedUserContext();
    }
}
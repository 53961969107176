import { Expose } from '../../serialization/class-transformer/decorators';
import { ClientTypes } from '../client-types';

export class FindUIInfo {
    @Expose()
    objectFullName: string;

    @Expose()
    clientType: ClientTypes;
}

import { EventEmitter } from '@angular/core';
import { UUIDHelper } from '@nts/std/src/lib/utility';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { RelayCommandInterface } from './relay-command.interface';

export class RelayCommand implements RelayCommandInterface {
    name: string;
    tag: object;
    executed: EventEmitter<any> = new EventEmitter<any>();
    displayName$ = new BehaviorSubject<string>('');
    tooltip$ = new BehaviorSubject<string>('');
    iconClass$ = new BehaviorSubject<string>('');
    loading$ = new BehaviorSubject<boolean>(false);
    showDisplayName$ = new BehaviorSubject<boolean>(true);
    isVisible$ = new BehaviorSubject<boolean>(true);
    canExecute$ = new BehaviorSubject<boolean>(true);
    data: any;
    isCancel = false;
    uid: string;

    protected readonly _execute: (x: any, y?: any) => void;

    constructor(
        canExecute: () => Observable<boolean>,
        execute: (x: any) => void,
        isVisible: () => Observable<boolean> = null
    ) {
        if (execute == null) {
            throw new Error('ArgumentNullException: execute');
        }

        this._execute = execute;
        if (isVisible != null) {
            isVisible().subscribe({
                next: (isVisible) => {
                    this.isVisible$.next(isVisible)
                },
                error: (err) => {
                    this.isVisible$.error(err)
                },
                complete: () => {
                    // this.isVisible$.complete()
                },
            })
        }
        if (canExecute != null) {
            canExecute().subscribe({
                next: (canExecute) => {
                    this.canExecute$.next(canExecute)
                },
                error: (err) => {
                    this.canExecute$.error(err)
                },
                complete: () => {
                    // this.canExecute$.complete()
                },
            })
        }
        this.uid = UUIDHelper.generateUUID()
    }

    execute(parameter: any, otherParam?: any) {
        this._execute(parameter, otherParam);
        this.executed.emit(parameter);
    }
}

import { CoreModel } from '../domain-models/core-model';
import { GenericArrayServiceResponse } from './generic-array-service-response';
import { BaseIdentity } from '../domain-models/base-identity';

export class FindResponse<
    TModel extends CoreModel<TIdentity>,
    TIdentity extends BaseIdentity>
    extends GenericArrayServiceResponse<TModel> {

}

import { Component, Input } from '@angular/core';

@Component({
    selector: 'nts-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    standalone: true,
    imports: []
})
export class SpinnerComponent {

    @Input() isVisible = false;

    constructor() {
    }
}

import { ChangeDetectorRef, Component } from '@angular/core';
import { PinIdentityToDashboardModalViewModel } from '../../view-models/modal/pin-identity-to-dashboard-modal-view-model';
import { ModalComponentInterface } from '../modal/modal-component.interface';
import { LabelledTextBoxComponent } from '../controls/labelled-text-box/labelled-text-box.component';


@Component({
    selector: 'nts-pin-identity-to-dashboard-modal-container',
    templateUrl: 'pin-identity-to-dashboard-modal-container.component.html',
    styleUrls: ['./pin-identity-to-dashboard-modal-container.component.scss'],
    standalone: true,
    imports: [
        LabelledTextBoxComponent
    ]
})
export class PinIdentityToDashboardModalContainerComponent implements ModalComponentInterface {
    viewModel: PinIdentityToDashboardModalViewModel;

    public async initialize(): Promise<void> {
        await this.viewModel.initialize();
    }

    constructor(
        private cd: ChangeDetectorRef
    ) { }
}

import { Expose, Type } from "../../serialization/class-transformer/decorators";
import { EnterpriseInformationDto } from "./enterprise-information.dto";

export class EnterprisesListDto {

    constructor() {
        this.enterprises = [];
    }

    @Expose()
    @Type(() => EnterpriseInformationDto)
    enterprises: EnterpriseInformationDto[];
}
export enum ToastMessageType {
  success = 'success',
  info = 'info',
  warn = 'warn',
  error = 'error'
}
export interface ToastMessage {

  // enum su stringa
  /*
  severity: string,   //	string	null	Severity level of the message, valid values are "success", "info", "warn" and "error".
  summary: string,    //	string	null	Summary text of the message.
  detail: string,     //	string	null	Detail text of the message.
  id: any,            //	any	null	Identifier of the message.
  
  life: number,        //	number	3000	Number of time in milliseconds to wait before closing the message.
  sticky: boolean,     //	boolean	false	Whether the message should be automatically closed based on life property or kept visible.
  closable: boolean,   //	boolean	true	When enabled, displays a close icon to hide a message manually.
  data: any,           // any	null	Arbitrary object to associate with the message.
  */
  key?: string;        //	string	null	Key of the message in case message is targeted to a specific toast component.
  title?: string;
  message?: string;
  type: ToastMessageType;   // error, info, warnig, success
  id?: any;                 // any	null	Identifier of the message.
  data?: any;               // any	null	Arbitrary object to associate with the message.
  time?: number;            // life
  sticky?: boolean;
  closable?: boolean;
}

import { BaseIdentity } from '../domain-models/base-identity';
import { CollectionViewModel } from './collection-view-model';
import { BaseRowNumberModel } from '../domain-models/base-row-number-model';
import { BaseRowNumberItemViewModel } from './base-row-number-item-view-model';
import { DomainModelRowNumberCollection } from '../domain-models/domain-model-row-number-collection';
import { BaseRowNumberCollectionViewModelHelper } from './base-row-number-collection-view-model-helper';
import { UICommandInterface } from './commands/ui-command.interface';
import { CommandTypes } from './commands/command-types';
import { CommandFactory } from './commands/command-factory';
import { map, merge, Observable, of } from 'rxjs';
import { CollectionChangedAction, CollectionChangedEventArgs } from './collection-changed-event-args';

export class BaseRowNumberCollectionViewModel<
    TItemViewModel extends BaseRowNumberItemViewModel<TModel, TIdentity>,
    TModel extends BaseRowNumberModel<TIdentity>,
    TIdentity extends BaseIdentity
    > extends CollectionViewModel<TItemViewModel, TModel, TIdentity> {

    moveItemUpCommand: UICommandInterface;
    moveItemDownCommand: UICommandInterface;

    override getDefaultCommandNames(): Array<string> {
        return super.getDefaultCommandNames().concat(['moveItemUpCommand', 'moveItemDownCommand']);
    }

    protected override getCollection(entities: DomainModelRowNumberCollection<TModel, TIdentity>): Array<TModel> {
        return BaseRowNumberCollectionViewModelHelper.getDomainModelCollection(entities);
    }

    // Inserts an item into the collection at the specified index
    protected override insertItem(index: number, itemViewModel: TItemViewModel, selectNewItem = false): number {
        const addedIndex = super.insertItem(index, itemViewModel, selectNewItem);
        BaseRowNumberCollectionViewModelHelper.syncRowNumbers(this);
        return addedIndex;
    }

    // Removes the item at the specified index of the collection
    protected override removeItem(index: number) {
        super.removeItem(index);
        BaseRowNumberCollectionViewModelHelper.syncRowNumbers(this);
    }

    protected override initCommand() {
        super.initCommand();
        const manager = this.getUICommandDefaultSettingsManager();
        this.moveItemUpCommand = manager.setUICommand(CommandTypes.MoveItemUp,
            CommandFactory.createUICommand(
                (x) => this.moveItemUp(), 
                () => this.canMoveItemUp(), 
                null, 
                () => of(true)
            )
        );
        this.standardCommandList.set('moveItemUpCommand', this.moveItemUpCommand);

        this.moveItemDownCommand = manager.setUICommand(CommandTypes.MoveItemDown,
            CommandFactory.createUICommand(
                (x) => this.moveItemDown(), 
                () => this.canMoveItemDown(), 
                null, 
                () => of(true)
            )
        );
        this.standardCommandList.set('moveItemDownCommand', this.moveItemDownCommand);

    }

    protected canMoveItemUp(): Observable<boolean> {
        return merge(
            this.selectionChanged,
            this.propertyChanged,
            this.collectionChanged
        ).pipe(map(() => {
            return this.selectedItem != null && this.isEnabled === true && this.selectedItem.rowNumber.value > 0;
        }));
    }

    protected canMoveItemDown(): Observable<boolean> {
        return merge(
            this.selectionChanged,
            this.propertyChanged,
            this.collectionChanged
        ).pipe(map(() => {
            return this.selectedItem != null && this.isEnabled === true && this.selectedItem.rowNumber.value < this.collection.length - 1;
        }));
    }

    protected async moveItemDown(itemIndex: number = null): Promise<void> {
        const selectedItem = (itemIndex > -1 && itemIndex != null) ? this[itemIndex] : this.selectedItem;
        const currentRowNumber = selectedItem.rowNumber.value;
        const nextRow = this[this.indexOf(selectedItem) + 1];
        const newRowNumber = nextRow.rowNumber.value;
        nextRow.rowNumber.value = currentRowNumber;
        selectedItem.rowNumber.value = newRowNumber;

        // Riordino la collection
        this.sort(
            (a, b) => a.rowNumber.value < b.rowNumber.value ? -1 : b.rowNumber.value < a.rowNumber.value ? 1 : 0
        );

        // Forzo l'aggiornamento della collection
        this.collectionChanged.emit(
            new CollectionChangedEventArgs(CollectionChangedAction.edit, [selectedItem])
        );
    }

    protected async moveItemUp(itemIndex: number = null): Promise<void> {
        const selectedItem = (itemIndex > -1 && itemIndex != null) ? this[itemIndex] : this.selectedItem;
        const currentRowNumber = selectedItem.rowNumber.value;
        const previousRow = this[this.indexOf(selectedItem) - 1];
        const newRowNumber = previousRow.rowNumber.value;
        previousRow.rowNumber.value = currentRowNumber;
        selectedItem.rowNumber.value = newRowNumber;

        // Riordino la collection
        this.sort(
            (a, b) => a.rowNumber.value < b.rowNumber.value ? -1 : b.rowNumber.value < a.rowNumber.value ? 1 : 0
        );

        // Forzo l'aggiornamento della collection
        this.collectionChanged.emit(
            new CollectionChangedEventArgs(CollectionChangedAction.edit, [selectedItem])
        );
    }
}

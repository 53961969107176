import { Expose } from '../serialization/class-transformer/decorators';
import { PanelState } from './panel-state.enum';

export class UserPanelMetaData {
    constructor() {

    }

    @Expose()
    panelState: PanelState;

    @Expose()
    panelId: string;
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopperHelper } from '@nts/std/src/lib/utility';
import { BaseBoolPropertyViewModel } from '../../../../view-models/base-type/bool-property-view-model';
import { merge, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { BasePropertyComponent } from '../base-property-component';
import { BaseCheckboxTextBoxComponent } from '../base/base-checkbox-text-box/base-checkbox-text-box.component';
import { BaseSecurityTextBoxComponent } from '../base/base-security-text-box/base-security-text-box.component';
import { NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'nts-checkbox-text-box',
    templateUrl: './checkbox-text-box.component.html',
    styleUrls: ['./checkbox-text-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        BaseCheckboxTextBoxComponent,
        BaseSecurityTextBoxComponent,
        NgIf
    ]
})
export class CheckboxTextBoxComponent extends BasePropertyComponent<boolean> implements OnInit, OnChanges {
    
    @Input() isDisabled = false;
    @Input() isReadOnly = false;
    @Input() showErrorTooltip = true;
    @Input() showTitleTooltip = true;
    @Input() showErrorBorder = true;
    @Input() tabIndex: number;
    @Input() primaryColor = null;
    
    @Output() onClick = new EventEmitter<void>();

    @ViewChild('baseCheckboxTextBox', { static: false }) baseCheckboxTextBox: BaseCheckboxTextBoxComponent;
    @ViewChild('baseSecurityTextBox', { static: false }) baseSecurityTextBox: BaseSecurityTextBoxComponent;

    checkStatus: boolean;
    valueChanged$: Subject<boolean> = new Subject();

    get input(): HTMLInputElement {
        return (this.propertyViewModel?.securityAccess == null ? this.baseCheckboxTextBox.checkboxBox.nativeElement : this.baseSecurityTextBox.securityTextBox.nativeElement);
    }

    get showLabel(): boolean {
        return this.propertyViewModel instanceof BaseBoolPropertyViewModel && this.propertyViewModel.showLabel;
    }    

    untilDestroyedThis(): <U>(source: Observable<U>) => Observable<U> {
        return untilDestroyed<this>(this)
    }

    isMobile() {
        const ua = navigator.userAgent;
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
    }

    override ngOnChanges(): void {
        this.valueChanged$.next(true);

        // Rimane in ascolto quando cambia il pvm e il valore
        merge(
            this.propertyViewModel.customGetterValueChanged,
            this.propertyViewModel.propertyViewModelChanged,
            this.propertyViewModel.onErrorStatusChanged,
            this.propertyViewModel.modelChanged,
            this.propertyViewModel.propertyChanged.pipe(filter((args) => args.propertyName === this.propertyViewModel.bindedValuePropertyName))
        ).pipe(
            this.untilDestroyedThis(),
            takeUntil(this.destroyOnChange$)
        ).subscribe(() => {
            this.checkStatus = this.propertyViewModel.value;
            this.cd.detectChanges();
        });

        // Rimane in ascolto quando cambiano le property diverse dal valore: isVisible, isEnabled, ecc
        this.propertyViewModel.propertyChanged.pipe(
            this.untilDestroyedThis(),
            takeUntil(this.destroyOnChange$),
            filter((args) => args.propertyName !== this.propertyViewModel.bindedValuePropertyName)
        ).subscribe(() => {
            this.cd.detectChanges();
        });

        this.checkStatus = this.propertyViewModel.value;
    }

    ngOnInit(): void {
        if (!this.propertyViewModel) { throw new Error('Missing viewModel!'); }
    }

    change(value): void {
        if (!this.isDisabled && this.propertyViewModel.isEnabled) {
            this.checkStatus = value;

            this.valueChange.emit(this.checkStatus);
            if
                (this.modelUpdate) {
                this.propertyViewModel.value = this.checkStatus;
            }
        }
    }

    showErroTooltip(): void {
        if (this.baseCheckboxTextBox?.popperError) {
            PopperHelper.show(this.baseCheckboxTextBox?.popperError);
        }
    }
}

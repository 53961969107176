import { BaseIdentity } from '../base-identity';
import { CoreModel } from '../core-model';
import { InternalDecorator } from '../decorators/internal.decorator';
import { Expose, Type } from '../../serialization/class-transformer/decorators';
import { ExternalDecorator } from '../decorators/external.decorator';
import { DomainModelCollection } from '../domain-model-collection';
import { OCCModel } from '../occ-model';
import { NmItemsTypeInspector, NmRootTypeInspector } from '../decorators/nm';

export abstract class NmOCCModel<
    TIdentity extends BaseIdentity,
    TRoot extends CoreModel<TIdentity>,
    TItems extends DomainModelCollection<TAssociation, TAssociationIdentity>,
    TAssociation extends CoreModel<TAssociationIdentity>,
    TAssociationIdentity extends BaseIdentity>
    extends OCCModel<TIdentity> {
        
    abstract discriminatorPropertiesName: Array<string>;
    
    // tslint:disable-next-line: variable-name
    private _root: TRoot;

    private _nmOccRootType: any;

    @Expose()
    @Type((options) => {
        return (options?.newObject as NmOCCModel<TIdentity, TRoot, TItems, TAssociation, TAssociationIdentity>)?._nmOccRootType;
    })
    get root(): TRoot {
        return this.getValueForExternal<TRoot>(
            () => this._root,
            'root',
            this._nmOccRootType
        );
    }

    set root(value: TRoot) {
        this.setValueForExternal(() => {
            this._root = value;
        }, value, 'root');
    }
    
    // tslint:disable-next-line: variable-name
    private _items: TItems;

    private _nmOccItemsType: any;

    @Expose()
    @Type((options) => {
        return (options?.newObject as NmOCCModel<TIdentity, TRoot, TItems, TAssociation, TAssociationIdentity>)?._nmOccItemsType;
    })
    get items(): TItems {        
        return this.getValueForInternalCollectionWithDifferentIdentity<
        TItems, TAssociation, TAssociationIdentity>(
            (value) => {
                this._items = value;
            },
            this._items,
            'items',
            this._nmOccItemsType
        );
    }

    set items(value: TItems) {
        this.setValueForInternalCollectionWithDifferentIdentity<
        TItems, TAssociation, TAssociationIdentity>(() => {
            this._items = value;
        }, value, 'items');
    }
    
    constructor() {
        super();

        this._nmOccRootType = NmRootTypeInspector.getValue(this);
        if (this._nmOccRootType === undefined) {
            throw new Error(
                `MetaData ${NmRootTypeInspector.META_DATA_KEY} not defined. You must use ${NmRootTypeInspector.DECORATOR_NAME} in ${this.constructor.name}.`
            );
        }

        this._nmOccItemsType = NmItemsTypeInspector.getValue(this);
        if (this._nmOccItemsType === undefined) {
            throw new Error(
                `MetaData ${NmItemsTypeInspector.META_DATA_KEY} not defined. You must use ${NmItemsTypeInspector.DECORATOR_NAME} in ${this.constructor.name}.`
            );
        }
    }
}

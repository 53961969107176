import { EventEmitter, Output, Input, Component } from '@angular/core';
import { BasePropertyComponent } from './base-property-component';

@Component({
    template: ''
})
export abstract class NewBasePropertyTextBox<T> extends BasePropertyComponent<T> {

    @Input() override modelUpdate: boolean = true;
    @Input() updateOnBlur: boolean;
    @Input() placeholder: string = '';
    @Input() tabIndex: number;
    @Input() notRegEvents: boolean = false;

    @Output() keyDown: EventEmitter<KeyboardEvent> = new EventEmitter();

    abstract get inputValue(): T;
    
    override init(): void {
        super.init();
        this.input.tabIndex = this.tabIndex;
        if (!this.notRegEvents) {
            this.registerEvents();
        }
    }

    updateModel() {
        this.valueChange.emit(this.inputValue);
        if (!this.modelUpdate ||
            (this.propertyViewModel.value == null &&
                (this.inputValue == null || (typeof this.inputValue === 'string' && this.inputValue === '')
                )
            ) || (this.propertyViewModel.value === this.inputValue)) {
            return;
        }

        this.propertyViewModel.value = this.inputValue;
    }
   
    onInput(ev: Event) {
        if (!this.updateOnBlur) {
            this.updateModel();
        }
    }

    onFocusOut(ev: any) {
        this.updateModel();
    }

    protected onKeyDown(ev: KeyboardEvent) {
        this.keyDown.emit(ev);
    }

    protected registerEvents() {
        this.input.onkeydown = (ev: KeyboardEvent) => {
            // setTimeout(() => {
            this.onKeyDown(ev);
            // });
        };

        this.input.oninput = (ev: Event) => {
            this.onInput(ev);
        };

        this.input.addEventListener('focusout', (ev: any) => {
            this.onFocusOut(ev);
        });
    }
}

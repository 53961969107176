import moment from 'moment';
import { ZoomPropertyViewModel } from './zoom-property-view-model';
import { MessageContainer } from '../../message-container';
import { DateTimeMetaData } from '../../../meta-data/date-time-meta-data';
import { ModifiedSubscriberInterface } from '../../modified-subscriber.interface';

export class DateTimeZoomPropertyViewModel extends ZoomPropertyViewModel<Date>  {

  constructor(propertyMetaData: DateTimeMetaData, modifiedSubscriber: ModifiedSubscriberInterface) {
    super(propertyMetaData, modifiedSubscriber);

  }

  override removeError(item: MessageContainer) {
    throw new Error("Method not implemented.");
  }

  setValue(propertyValue: Date) {
    if (propertyValue === null) {
      this.typedValue = null;
    } else {
      // TODO
      // let ic = propertyValue as IConvertible;
      // if (ic == null)
      // {
      //     throw new ArgumentException();
      // }

      // this.typedValue = ic.ToDateTime(CultureInfo.InvariantCulture);
      this.typedValue = moment(propertyValue, 'YYYY-MM-DD').toDate();
    }
    this.modifiedSubscriber.notifyModified();
  }

  private internalDateTimeValues!: Array<Date>|null;

  setDateTimeValues(valuesToSet: Array<Date>) {
      if (this.internalDateTimeValues != valuesToSet) {
          this.internalDateTimeValues = valuesToSet;
          this.modifiedSubscriber.notifyModified();
      }   
  }

  get dateTimeValues(): Array<Date>|null {
      return this.internalDateTimeValues;
  }
}

import { BaseDecoratorInterface } from './commons/base-decorator.interface';
import { BaseValidator } from './commons/base-validator';
import { ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments, registerDecorator } from 'class-validator';
import { InternalRelationMetaData, InternalAssociationMetaData, InternalCollectionMetaData, AssociationPropertyMetaData, MetaDataUtils } from '../../meta-data';
import { AssociationOptionsInterface } from './association-options.interface';
import { DomainModelCollectionInterface } from '../domain-model-collection.interface';
import { UniqueValidation } from './validations/unique-validation';

export interface InternalDecoratorInterface extends BaseDecoratorInterface, AssociationOptionsInterface {
    isCollection?: boolean;
    isUniqueFunc?: (val) => any;
    uniqueFields?: string[];
}

@ValidatorConstraint({ name: 'internalValidator', async: false })
export class InternalValidator extends BaseValidator<any> implements ValidatorConstraintInterface {

    override validate(value: any, args: ValidationArguments): boolean {
        const decorator = args.constraints[0] as InternalDecoratorInterface;
        const result = super.validate(value, args) &&
            decorator.isCollection ? this.validateUnique(value, decorator.uniqueFields, args) : true;
        return result;
    }

    validateUnique(value: DomainModelCollectionInterface,  uniqueFields: string[], args: ValidationArguments): boolean {
        if (uniqueFields != null && uniqueFields?.length > 0) {
            const uniqueValidation = new UniqueValidation(uniqueFields);
            if (!uniqueValidation.validate(value, args)) {
                this.errorMessage = uniqueValidation.errorMessage;
                return false;
            }
        }
        return true;
    }

    protected  static override getPropertyMetaDataFromDecoratorData<TDecoratorData extends InternalDecoratorInterface>(
        decoratorData: TDecoratorData, propertyName: string) {
        let propertyMetaData: InternalCollectionMetaData|InternalRelationMetaData;

        if (decoratorData.isCollection) {
            propertyMetaData = new InternalCollectionMetaData();
            (propertyMetaData as InternalCollectionMetaData).isUniqueFunc =(decoratorData as InternalDecoratorInterface)?.isUniqueFunc;
            (propertyMetaData as InternalCollectionMetaData).uniqueFields =(decoratorData as InternalDecoratorInterface)?.uniqueFields;
        } else {
            propertyMetaData = new InternalRelationMetaData();
        }
        propertyMetaData.context = decoratorData?.context;
        propertyMetaData.descriptions.descriptionKey = decoratorData.descriptionKey;
        propertyMetaData.descriptions.displayNameKey = decoratorData.displayNameKey;
        propertyMetaData.descriptions.shortNameKey = decoratorData.shortNameKey;

        if (decoratorData.principalPName1) {
            const ass = new AssociationPropertyMetaData();
            ass.principalPropertyName = decoratorData.principalPName1;
            ass.dependentPropertyName = decoratorData.dependantPName1;
            propertyMetaData.associationProperties.push(ass);
        }

        if (decoratorData.principalPName2) {
            const ass = new AssociationPropertyMetaData();
            ass.principalPropertyName = decoratorData.principalPName2;
            ass.dependentPropertyName = decoratorData.dependantPName2;
            propertyMetaData.associationProperties.push(ass);
        }

        if (decoratorData.principalPName3) {
            const ass = new AssociationPropertyMetaData();
            ass.principalPropertyName = decoratorData.principalPName3;
            ass.dependentPropertyName = decoratorData.dependantPName3;
            propertyMetaData.associationProperties.push(ass);
        }

        if (decoratorData.principalPName4) {
            const ass = new AssociationPropertyMetaData();
            ass.principalPropertyName = decoratorData.principalPName4;
            ass.dependentPropertyName = decoratorData.dependantPName4;
            propertyMetaData.associationProperties.push(ass);
        }

        propertyMetaData.principalPropertyName = MetaDataUtils.toPascalCase(propertyName);
        return propertyMetaData;
    }
}

export function InternalDecorator(decoratorInterface: InternalDecoratorInterface) {
    return (object: object, propertyName: string) => {

        // Metodo di base per tutti i decoratori
        BaseValidator.initBaseValidator(decoratorInterface, object, propertyName);

        // Aggiunge informazioni alla property sulla validazione sul tipo della classe
        InternalValidator.buildPropertyMetaData<InternalDecoratorInterface>(
            object.constructor, propertyName, decoratorInterface);
        // Aggiunge informazioni alla property sulla validazione sull'instanza della classe
        InternalValidator.buildPropertyMetaData<InternalDecoratorInterface>(
            object, propertyName, decoratorInterface);

        registerDecorator({
            target: object.constructor,
            propertyName,
            constraints: [decoratorInterface],
            options: {context: decoratorInterface.context},
            validator: InternalValidator
        });
    };
}

export class InternalInspector {

    static isApplied(subject: any, propertyName: string): boolean {
        let propertyMetaData = InternalValidator.getPropertyMetaData(subject, propertyName);
        if (!propertyMetaData) {
            propertyMetaData = InternalValidator.getPropertyMetaData(subject.constructor, propertyName);
        }
        return propertyMetaData instanceof InternalAssociationMetaData;
    }

    static isCollection(subject: any, propertyName: string): any {
        let propertyMetaData = InternalValidator.getPropertyMetaData(subject, propertyName);
        if (!propertyMetaData) {
            propertyMetaData = InternalValidator.getPropertyMetaData(subject.constructor, propertyName);
        }
        return propertyMetaData instanceof InternalCollectionMetaData;
    }

    static getValue(subject: any, propertyName: string): InternalAssociationMetaData {
        let propertyMetaData = InternalValidator.getPropertyMetaData<InternalAssociationMetaData>(subject, propertyName);
        if (!propertyMetaData) {
            propertyMetaData = InternalValidator.getPropertyMetaData<InternalAssociationMetaData>(subject.constructor, propertyName);
        }
        return propertyMetaData;
    }
}

import { NTSReflection } from '@nts/std/src/lib/utility';

const META_DATA_IDENTIFICATION_KEY = 'CustomPropertyViewModelKey';
const META_DATA_DISPLAY_NAME_KEY = 'Name';
const META_DATA_DESCRIPTION_KEY = 'Description';

export interface CustomPropertyViewModelDecoratorInterface {
    displayName: string;
    description?: string;
}

export function CustomPropertyViewModelDecorator() {
    return (target: any, propertyKey: any) => {
        Reflect.defineMetadata(META_DATA_IDENTIFICATION_KEY, true, target, propertyKey);
    };
}

export class CustomPropertyViewModelInspector {
    static isApplied(subject: any, propertyName: string): boolean {
        return NTSReflection.hasPropertyMetadata(
            META_DATA_IDENTIFICATION_KEY, subject, propertyName);
    }

    static getDisplayName(subject: any, propertyName: string): string {
        return NTSReflection.getPropertyMetadata(
            META_DATA_DISPLAY_NAME_KEY, subject, propertyName);
    }

    static getDescription(subject: any, propertyName: string): string {
        return NTSReflection.getPropertyMetadata(
            META_DATA_DESCRIPTION_KEY, subject, propertyName);
    }

    static getValue(subject: any, propertyName: string): CustomPropertyViewModelDecoratorInterface {
        const displayName = CustomPropertyViewModelInspector.getDisplayName(subject, propertyName);
        const description = CustomPropertyViewModelInspector.getDescription(subject, propertyName);
        return { displayName, description };
    }

}

<div class="toolbar-section">
    <div class="toolbar-control-content">
      <div class="horizontal-container">
        <div *ngIf="title?.length > 0" class="caption-control" role="heading" aria-level="3">{{ title }}</div>
        <div class="root-container">
          <div class="inline-command-bar">
            <div class="action-list-control-container">
              <div class="horizontal-flex-container" [class.flex-row-reverse]="rtl">
                <div *ngIf="title?.length > 0" class="navigation-bar-row-separator">
                </div>
               
                <ng-template ngFor let-command [ngForOf]="commands" let-i="index">
                  <div class="horizontal-flex-container-item-layout">
                    <div class="command-bar-button-container">
                      <nts-ribbon-button 
                        *ngIf="command.isVisible$ | async"
                        [title]="command.tooltip"
                        [tabIndex]="0" [label]="command.displayName"
                        [icon]="command.iconClass"
                        [commands]="command.children"
                        [isDisabled]="!(command.canExecute$ | async)"
                        (onClicked)="command.execute()">
                      </nts-ribbon-button>
                    </div>
                  </div>
                </ng-template>

              </div>
            </div>
          </div>
        </div>

        <!-- <ng-template ngFor let-commandName
          [ngForOf]="customViewCommandsName.concat(defaultViewCommandsName)">
          <div class="command-bar-button-container"
            *ngIf="this.collectionViewModel.getCommand(commandName).isVisible$ | async">

            <nts-ribbon-button [title]="this.collectionViewModel.getCommand(commandName).tooltip" [tabIndex]="0"
              [icon]="this.collectionViewModel.getCommand(commandName).iconClass"
              [isDisabled]="!(this.collectionViewModel.getCommand(commandName).canExecute$ | async)"
              (onClicked)="this.collectionViewModel.getCommand(commandName).execute()"></nts-ribbon-button>
          </div>
        </ng-template> -->

      </div>
    </div>

  </div>

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ColumnCellEditorParams } from '../column_cell_editor_params.interface';
import { StringCellEditorComponent } from '../string-cell-editor/string-cell-editor.component';
import { NgIf } from '@angular/common';
import { AgEditorComponent } from 'ag-grid-angular';
import { NumericCellEditorComponent } from '../numeric-cell-editor/numeric-cell-editor.component';
import { BaseNumericPropertyViewModel } from '../../../../../view-models/base-type/base-numeric-property-view-model';
import { StringPropertyViewModel } from '../../../../../view-models/base-type/string-property-view-model';
import { PropertyViewModelInterface } from '../../../../../view-models/property-view-model.interface';
import { BaseDateTimePropertyViewModel } from '../../../../../view-models/base-type/date-time-property-view-model';
import { BaseEnumPropertyViewModel } from '../../../../../view-models/base-type/enum-property-view-model';
import { DateCellEditorComponent } from '../date-cell-editor/date-cell-editor.component';
import { EnumCellEditorComponent } from '../enum-cell-editor/enum-cell-editor.component';
import { BoolCellEditorComponent } from '../bool-cell-editor/bool-cell-editor.component';
import { BaseBoolPropertyViewModel } from '../../../../../view-models/base-type/bool-property-view-model';
import { DateOffsetCellEditorComponent } from '../date-offset-cell-editor/date-offset-cell-editor.component';
import { BaseDateTimeOffsetPropertyViewModel } from '../../../../../view-models/base-type/date-time-offset-property-view-model';
// create your cellEditor as a Angular component

@Component({
    selector: 'nts-dynamic-text-box-editor-cell',
    styleUrls: ['./dynamic-cell-editor.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        StringCellEditorComponent,
        NumericCellEditorComponent,
        DateCellEditorComponent,
        DateOffsetCellEditorComponent,
        EnumCellEditorComponent,
        BoolCellEditorComponent,
        NgIf
    ],
    template: `
        <nts-string-text-box-editor-cell #stringTextBox *ngIf="isString" [params]="params"></nts-string-text-box-editor-cell>
        <nts-numeric-text-box-editor-cell #numericTextBox *ngIf="isNumeric" [params]="params"></nts-numeric-text-box-editor-cell>
        <nts-date-text-box-editor-cell #dateTextBox *ngIf="isDate" [params]="params"></nts-date-text-box-editor-cell>
        <nts-date-offset-text-box-editor-cell #dateOffsetTextBox *ngIf="isDateOffset" [params]="params"></nts-date-offset-text-box-editor-cell>
        <nts-enum-text-box-editor-cell #enumTextBox *ngIf="isEnum" [params]="params"></nts-enum-text-box-editor-cell>
        <nts-bool-text-box-editor-cell #boolTextBox *ngIf="isBool" [params]="params"></nts-bool-text-box-editor-cell>
    `
})
export class DynamicCellEditorComponent implements AfterViewInit {

    params: ColumnCellEditorParams;
    currentComponent: AgEditorComponent;
    isNumeric: boolean;
    isString: boolean;
    isDate: boolean;
    isDateOffset: boolean;
    isEnum: boolean;
    isBool: boolean;

    @ViewChild('stringTextBox', { static: false }) stringTextBox: StringCellEditorComponent;
    @ViewChild('numericTextBox', { static: false }) numericTextBox: NumericCellEditorComponent;
    @ViewChild('dateTextBox', { static: false }) dateTextBox: DateCellEditorComponent;
    @ViewChild('dateOffsetTextBox', { static: false }) dateOffsetTextBox: DateOffsetCellEditorComponent;
    @ViewChild('enumTextBox', { static: false }) enumTextBox: EnumCellEditorComponent;
    @ViewChild('boolTextBox', { static: false }) boolTextBox: BoolCellEditorComponent;

    constructor(
        private readonly cd: ChangeDetectorRef,
    ) {}
    
    ngAfterViewInit(): void {
        if (this.isNumeric) {
            this.currentComponent = this.numericTextBox;
        } else if (this.isString) {
            this.currentComponent = this.stringTextBox;
        } else if (this.isDate) {
            this.currentComponent = this.dateTextBox;
        } else if (this.isDateOffset) {
            this.currentComponent = this.dateOffsetTextBox;
        } else if (this.isEnum) {
            this.currentComponent = this.enumTextBox;
        } else if (this.isBool) {
            this.currentComponent = this.boolTextBox;
        }
    }

    agInit(params: ColumnCellEditorParams): void {
        this.params = params;
        this.isNumeric =  this.propertyViewModel instanceof BaseNumericPropertyViewModel;
        this.isString = this.propertyViewModel instanceof StringPropertyViewModel;
        this.isDate = this.propertyViewModel instanceof BaseDateTimePropertyViewModel;
        this.isDateOffset = this.propertyViewModel instanceof BaseDateTimeOffsetPropertyViewModel;
        this.isEnum = this.propertyViewModel instanceof BaseEnumPropertyViewModel;
        this.isBool = this.propertyViewModel instanceof BaseBoolPropertyViewModel;
        this.cd.detectChanges();
    }

    isCancelBeforeStart(): boolean {
        return this.currentComponent.isCancelBeforeStart();
    }

    getValue(): any {
        return this.currentComponent.getValue();
    }

    isPopup(): boolean {
        return this.currentComponent.isPopup && this.currentComponent.isPopup();
    }

    private _propertyViewModel: PropertyViewModelInterface;

    get propertyViewModel(): PropertyViewModelInterface {
        if (!this._propertyViewModel) {
            this._propertyViewModel =  this.params.columnInfo.fieldName.split('.').reduce(
                (o, i) => {
                    if (i === 'value') {
                        return o;
                    }
                    if(o != null){
                        return o[i];
                    }
                    return null;
                }, this.params.data) as PropertyViewModelInterface;
        }
        return this._propertyViewModel;
    }
}

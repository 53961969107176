import { BaseSecurityTextBoxComponent } from './../base/base-security-text-box/base-security-text-box.component';
import { OCCAuditDeactivableModel } from './../../../../domain-models/occ-audit-deactivable-model';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild, SimpleChanges, OnChanges, EventEmitter, Output, AfterViewInit, OnDestroy, ChangeDetectorRef, NgZone, ElementRef, Renderer2 } from '@angular/core';
import { ExternalViewModelInterface } from '../../../../../lib/view-models/external-view-model.interface';
import { PropertyViewModelInterface } from '../../../../../lib/view-models/property-view-model.interface';
import { Observable, of, Subject, concat, merge, fromEvent, throttleTime } from 'rxjs';
import { tap, catchError, takeUntil, switchMap, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { BaseExternalPropertyTextBox } from '../base-external-property-text-box';
import { DropdownPosition, NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { UICommandInterface } from '../../../../../lib/view-models/commands/ui-command.interface';
import { CommandFactory } from '../../../../../lib/view-models/commands/command-factory';
import { UICommandSettingsManager } from '../../../../../lib/view-models/commands/ui-command-settings-manager';
import { CommandTypes } from '../../../../../lib/view-models/commands/command-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxPopperjsDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { OnlineService, PopperHelper } from '@nts/std/src/lib/utility';
import { MessageResourceManager } from '../../../../resources/message-resource-manager';
import { NNumericPropertyViewModel } from '../../../../../lib/view-models/base-type/nnumeric-property-view-model';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NTSTranslatePipe } from '../../../pipe/nts-translation-pipe';
import { TextButtonComponent } from '../../../shared/buttons/text-button/text-button.component';


@UntilDestroy()
@Component({
    selector: 'nts-ext-autocomplete-text-box',
    templateUrl: './ext-autocomplete-text-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./ext-autocomplete-text-box.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgxPopperjsModule,
        FormsModule,
        NgSelectModule,
        AsyncPipe,
        NTSTranslatePipe,
        NgOptionHighlightModule,
        TextButtonComponent,
        BaseSecurityTextBoxComponent,
        NgFor
    ]
})
export class ExtAutocompleteTextBoxComponent extends
    BaseExternalPropertyTextBox<string> implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    
    @Input() override externalPropertyViewModel: ExternalViewModelInterface;
    @Input() appendTo = 'app-root' //'.nts-document-content';
    @Input() scrollElementClass = 'layout-main scrollable-content'
    @Input() code: PropertyViewModelInterface;
    @Input() decodeDescription: PropertyViewModelInterface;
    @Input() decodeProperties: string[] = [];
    @Input() autogenerateCodeDescription = false;
    @Input() enableOutsideClickListener = false;
    @Input() showCodeInDescription = true;
    @Input() isDisabled = false;
    @Input() showErrorTooltip = true;
    @Input() showErrorBorder = true;

    @Output() onFinishEditing = new EventEmitter();
    @Output() propertyViewModelInitialized = new EventEmitter<void>();

    @ViewChild('popperInfo', { static: false }) popperInfo: NgxPopperjsDirective;
    @ViewChild('popperError', { static: false }) popperError: NgxPopperjsDirective;
    @ViewChild(NgSelectComponent, { static: false }) inputRef: NgSelectComponent;
    @ViewChild('baseSecurityTextBox', { static: false }) baseSecurityTextBox: BaseSecurityTextBoxComponent;

    dropDownClosed = new Subject<void>();
    isOpen = false;
    dropdownPosition: DropdownPosition = 'bottom';
    lastInputText: string;
    itemLoading = false;
    items$: Observable<{ description: string, identity: any, all: any }[]>;
    itemInput$ = new Subject<string>();
    language: { twoLetterISOLanguageName, cultureName, description };
    languages: Array<{ twoLetterISOLanguageName, cultureName, description }>;
    selectedItem: { description: string, identity: any, all: any } = null;
    selectedItemChanged: Subject<void> = new Subject();
    searchFinished: Subject<void> = new Subject();
    addItemCommand: UICommandInterface;
    viewItemCommand: UICommandInterface;
    copyCommand: UICommandInterface;
    documentClickListener: any;
    searchInputMouseDownListener: any;
    searchInputMouseUpListener: any;
    elementClickListener: any;
    typeToSearchText: string;
    lastSearchFeatureEnabled = false;
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;
    showButtonLabels = false;
    overrideBorderColor = null;
    isActive = false;
    isHover = false;

    get inputValue(): string {
        return this.code.getValue();
    }

    get input(): HTMLInputElement {
        return (this.propertyViewModel?.securityAccess == null ? this.inputRef.searchInput.nativeElement : this.baseSecurityTextBox.securityTextBox.nativeElement);
    }

    private dropdownOpening = false;
    private mouseDownOnSearchInput = false;
    private resizeEvent = null;
    private onFocusEnabled = true;
    
    constructor(
        cd: ChangeDetectorRef,
        public readonly el: ElementRef,
        private readonly zone: NgZone,
        private readonly renderer: Renderer2,
        private readonly onlineService: OnlineService
    ) {
        super(cd);
    }

    override ngOnChanges(changes: SimpleChanges): void {

        this.destroyOnChange$.next(true);

        this.externalPropertyViewModel.externalDomainModelChanged.pipe(
            this.untilDestroyedThis(),
            takeUntil(this.destroyOnChange$)
        ).subscribe(() => {
            this.checkAsyncSelected();
            // this.typeaheadLoading = false;
            this.cd.detectChanges();
        });

        this.externalPropertyViewModel.decodeCompleted.pipe(
            this.untilDestroyedThis(),
            takeUntil(this.destroyOnChange$)
        ).subscribe(() => {
            this.checkAsyncSelected();
            this.itemLoading = false;
            this.cd.detectChanges();
        });

        merge(
            this.externalPropertyViewModel.propertyChanged,
            this.onlineService.isOnline$
        ).pipe(
            this.untilDestroyedThis(),
            takeUntil(this.destroyOnChange$)
        ).subscribe(() => {
            this.cd.detectChanges();
        });

        this.externalPropertyViewModel.resettedData.pipe(
            this.untilDestroyedThis(),
            takeUntil(this.destroyOnChange$)
        ).subscribe(async () => {
            if (this.selectedItem != null) {
                await this.onSelectedItem(null);
                this.cd.detectChanges();
            }            
        });
    
        this.externalPropertyViewModel.codeProperties.forEach(pvm => {
            merge( 
                pvm.propertyViewModelChanged,
                pvm.propertyChanged,
                pvm.onErrorStatusChanged
            ).pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$)
            ).subscribe(() => {
                if (!pvm?.hasErrors) {
                    PopperHelper.hide(this.popperError); 
                }
                PopperHelper.hide(this.popperInfo);
                this.cd.detectChanges();

                // this.checkAsyncSelected();
            });

            pvm.decodePendingUpdated.pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$)
            ).subscribe((decodePending: boolean) => {
                this.itemLoading = decodePending;
                this.cd.detectChanges();

                // this.checkAsyncSelected();
            });
        });

        this.checkAsyncSelected();

        this.lastInputText = '';

        if (this.autogenerateCodeDescription) {
            this.code = this.externalPropertyViewModel.codeProperties.values().next().value;
            this.decodeDescription = this.externalPropertyViewModel.automaticDecodeDescription;
        }

        this.propertyViewModelInitialized.emit();
    }
    
    ngOnDestroy(): void {
        this.unbindListener();
    }

    untilDestroyedThis(): <U>(source: Observable<U>) => Observable<U> {
        return untilDestroyed<this>(this)
    }

    override startPresentation(event: any, showRead: boolean): void {
        if (!this.isDisabled && (showRead ? this.externalPropertyViewModel.zoomViewIsEnabled : this.externalPropertyViewModel.zoomAddIsEnabled)) {
            this.inputRef.close();
            PopperHelper.hide(this.popperInfo);
            PopperHelper.hide(this.popperError);
            super.startPresentation(event, showRead);
        }
    }

    override updateModel() {
        // Override update model
    }

    override async zoom(): Promise<void> {
        this.inputRef.close();
        await super.zoom(this.inputRef);
        this.inputRef.close();
    }

    ngOnInit() {

        const manager = new UICommandSettingsManager();
        this.addItemCommand = manager.setUICommand(CommandTypes.CreateItem,
            CommandFactory.createUICommand(
                async (x) => this.startPresentation(null, false),
                () => this.externalPropertyViewModel.propertyChanged.pipe(
                    startWith(null),
                    map(() => this.externalPropertyViewModel.zoomAddIsEnabled)),
                null,
                () => this.externalPropertyViewModel.propertyChanged.pipe(
                    startWith(null),
                    map(() => this.externalPropertyViewModel.zoomAddIsVisible)),
            ));
        this.addItemCommand.description =  MessageResourceManager.Current.getMessage('CMD_Create');
        this.viewItemCommand = manager.setUICommand(CommandTypes.ViewItem,
            CommandFactory.createUICommand(
                async (x) => this.startPresentation(null, true),
                () =>
                    merge(
                        this.selectedItemChanged,
                        this.externalPropertyViewModel.propertyChanged
                    ).pipe(
                        startWith(null),
                        map(() => this.selectedItem != null && this.externalPropertyViewModel.zoomViewIsEnabled)),
                null,
                () => this.externalPropertyViewModel.propertyChanged.pipe(
                    startWith(null),
                    map(() => this.externalPropertyViewModel.zoomViewIsVisible)),
            ));
        this.viewItemCommand.description = MessageResourceManager.Current.getMessage('CMD_View_Tooltip');

        this.copyCommand = manager.setUICommand(CommandTypes.Copy,
            CommandFactory.createUICommand(
                async (x) => {
                    this.copyMessage(this.showCodeInDescription ? this.getCodeAndDescription() : [this.code?.getValue(), this.decodeDescription?.getValue()].filter(s => s?.length > 0).join(' - '));
                    this.inputRef.close();
                },
                () => this.selectedItemChanged.pipe(
                    startWith(null),
                    map(() => this.selectedItem != null)),
                null,
                () => of(true)
            ));
        this.copyCommand.description = MessageResourceManager.Current.getMessage('CMD_CopyToClipboard_Tooltip');

        if (!this.externalPropertyViewModel) { throw new Error('Missing viewModel for externalPropertyViewModel!'); }

        if (this.autogenerateCodeDescription) {
            this.code = this.externalPropertyViewModel.codeProperties.values().next().value;
            this.decodeDescription = this.externalPropertyViewModel.automaticDecodeDescription;
        }

        if (!this.code) { throw new Error('Missing viewModel for code!'); }
        if (!this.decodeDescription) { throw new Error('Missing viewModel for decodeDescription!'); }

        this.loadItems();

        if (this.enableOutsideClickListener) {
            this.bindDocumentClickListener();
        }

        this.cd.detectChanges();
    }

    ngAfterViewInit(): void {
        this.init();

        this.searchInputMouseDownListener = this.inputRef?.searchInput?.nativeElement.addEventListener('mousedown', () => {
            this.mouseDownOnSearchInput = true;
        });

        this.searchInputMouseUpListener = this.inputRef?.searchInput?.nativeElement.addEventListener('mouseup', () => {
            this.mouseDownOnSearchInput = false;
        });

        this.elementClickListener = this.inputRef?.element.addEventListener('click', () => {
            if (!this.code.isEnabled) {
                const range = document.createRange();
                const node = this.inputRef.element.getElementsByClassName('ng-value-label')[0];
                if (node) {
                    range.selectNode(node);
                    window.getSelection().removeAllRanges();
                    window.getSelection().addRange(range);
                }

                // this.copyCommand.execute();
            }
        });

        this.handleOverridesColors();
    }

    handleOverridesColors() {
        if (!this.externalPropertyViewModel.defaultColor || !this.externalPropertyViewModel.activeColor || !this.externalPropertyViewModel.hoverColor) {
            // devono essere impostate tutte e tre le variabili
            return;
        }

        this.overrideBorderColor = this.externalPropertyViewModel.defaultColor;
        if (this.isActive && !this.isDisabled && this.propertyViewModel.isEnabled && this.externalPropertyViewModel.isEnabled) {
            this.overrideBorderColor = this.externalPropertyViewModel.activeColor;
        }
        if (this.isHover && !this.isDisabled && this.propertyViewModel.isEnabled && this.externalPropertyViewModel.isEnabled) {
            this.overrideBorderColor = this.externalPropertyViewModel.hoverColor;
        }

        const container = this.inputRef.element.querySelector(".ng-select-container") as any;
        if (container){
            container.style.borderColor = this.overrideBorderColor 
        }

        const dropdown = (this.inputRef.dropdownPanel as any)?._dropdown;
        if (dropdown){
            dropdown.style.borderColor = this.overrideBorderColor;
        }

        const dropdownFooter = (this.inputRef.dropdownPanel as any)?._dropdown?.querySelector(".ng-dropdown-footer");
        if (dropdownFooter){
            dropdownFooter.style.borderColor = this.overrideBorderColor; 
        }
    }

    async onClear(e): Promise<void> {
        this.inputRef.close();
        await this.onSelectedItem(null);
    }

    isSelectedItemDeactivated(): boolean {
        const dm = this.externalPropertyViewModel.getDomainModel();
        return dm instanceof OCCAuditDeactivableModel ? !dm.isActive : false;
    }

    isItemDeactivated(item): boolean {
        const dm = new this.externalPropertyViewModel.externalDomainModelType();
        if (dm instanceof OCCAuditDeactivableModel) {
            if (item.all) {
                const obj = item?.all.find(i => i.hasOwnProperty('IsActive'));
                if (obj) {
                    return !obj.IsActive;
                }
            }
        }
        return false;
    }

    selectLastSearchItem(value: string): void {
        this.inputRef.searchInput.nativeElement.value = value;
        this.inputRef.searchInput.nativeElement.dispatchEvent(new Event('input'));
    }

    onFocus(event): void {

        this.isActive = true;
        this.handleOverridesColors();

        if (this.isDisabled || !this.propertyViewModel.isEnabled || !this.externalPropertyViewModel.isEnabled) {
            return;
        }
        // if (this.selectedItem != null && this.onFocusEnabled) {
        //     this.inputRef.searchInput.nativeElement.value = this.initialValue ? this.initialValue : this.code.getValue();
        //     this.initialValue = null;
        //     this.inputRef.searchInput.nativeElement.dispatchEvent(new Event('input'));
        //     const pos = this.inputRef.searchInput.nativeElement.value ? this.inputRef.searchInput.nativeElement.value.length : 0;
        //     // this.inputRef.searchInput.nativeElement.setSelectionRange(pos, pos);
        //     this.inputRef.searchInput.nativeElement.selectionStart = 0;
        //     this.inputRef.searchInput.nativeElement.selectionEnd = pos;
        //     // if (!this.mouseDownOnSearchInput) {
        //     this.inputRef.searchInput.nativeElement.select();
        //     // }
        // }
        if (this.selectedItem != null && this.onFocusEnabled) {
            this.inputRef.searchInput.nativeElement.value = this.initialValue ? this.initialValue : (this.showCodeInDescription ? this.code.getValue() : (this.decodeDescription.getValue() ?? ''));
            this.initialValue = null;
            this.inputRef.searchInput.nativeElement.dispatchEvent(new Event('input'));
            const pos = this.inputRef.searchInput.nativeElement.value ? this.inputRef.searchInput.nativeElement.value.length : 0;
            //     // this.inputRef.searchInput.nativeElement.setSelectionRange(pos, pos);
            this.inputRef.searchInput.nativeElement.selectionStart = 0;
            this.inputRef.searchInput.nativeElement.selectionEnd = pos;
            //     // if (!this.mouseDownOnSearchInput) {
            this.inputRef.searchInput.nativeElement.select();
            // }
        }
    }

    closeDropDown(): void {
        this.inputRef.close();
    }

    openDropDown(): void {
        this.inputRef.open();
    }

    updateButtonLabelsVisibility() {
        this.showButtonLabels = this.inputRef.element.clientWidth > 300;
        this.cd.detectChanges();
    }

    mouseEnter(e) {
        this.isHover = true;
        this.handleOverridesColors();
    }

    mouseLeave(e) {
        this.isHover = false;
        this.handleOverridesColors();
    }

    async onBlur(): Promise<void> {

        this.isActive = false;
        this.handleOverridesColors();

        this.popperError?.hide();
        this.popperInfo?.hide();

        if (this.externalPropertyViewModel.decodeInProgress || this.onSelectionInProgress) {
            return;
        }

        if (this.lastInputText != null &&
            this.lastInputText !== (this.showCodeInDescription ? this.code.getValue() : (this.decodeDescription.getValue() ?? '')) &&
            this.lastInputText !== ''
        ) {
            // Solo nel caso in cui il dropdown non è visibile o se è visibile non ha un elemento preselezionato
            if (this.inputRef?.dropdownPanel?.markedItem == null) {
                this.selectedItem = null;
                await this.externalPropertyViewModel.setCodeValue(this.lastInputText);
                this.valueChange.emit(this.lastInputText);
            } else {
                // devo resettare il last input text
                this.lastInputText = null;
            }
        }

        if (!this.isDisabled && this.externalPropertyViewModel.isEnabled) {
            this.externalPropertyViewModel.validate();
        }
    }

    private onSelectionInProgress = false;

    async onSelectedItem(selectedItem: { description: string, identity: any, all: any }): Promise<void> {
        this.onSelectionInProgress = true;
        if (selectedItem && selectedItem.identity != null) {
            await this.externalPropertyViewModel.setCodeValue(selectedItem.identity);
        } else {
            await this.externalPropertyViewModel.setCodeValue(null);
            this.selectedItem = null;
            this.inputRef.searchInput.nativeElement.value = '';
        }
        this.lastInputText = '';
        // this.cd.detectChanges();
        this.onSelectionInProgress = false;
        this.valueChange.emit(selectedItem?.identity);
        
        this.selectedItemChanged.next();

    }

    trackByFn(item: { description: string, identity: any, all: any }) {
        return item.identity;
    }

    keyDownFn(event: KeyboardEvent): boolean {
        if (event.key === 'Tab') {
            // Necessario per forzare il blur nella griglia

            // Se ho un elemento marcato nel dropdown
            if (this.inputRef?.dropdownPanel?.markedItem?.value) {
                const markedItem = this.inputRef.dropdownPanel.markedItem.value as { description: string, identity: any, all: any }

                // Se l'identity è diversa da quella selezionata
                if (this.code.getValue()?.toString() !== markedItem.identity.toString()) {
                    this.onSelectedItem(markedItem);
                }

            }

            this.onBlur();

            // if (this)
            // setTimeout(() => this.onFocus(null))
        } else if (event.key === 'Del') {
            // TODO se si trova alla poszione zero con il cursore ed esiste un modello cancellare il modello
            // if (this.inputRef.)
        } else if (event.key === 'F6' && event.ctrlKey && this.externalPropertyViewModel.isEnabled) {
            this.zoom();
        }
        return true;
    }

    onDropdownOpen(): void {
        this.isOpen = true;
        this.updateButtonLabelsVisibility();

        fromEvent(document.getElementsByClassName(this.scrollElementClass), 'scroll').pipe(
            this.untilDestroyedThis(),
            takeUntil(this.dropDownClosed),
            throttleTime(1000)
        ).subscribe((e: Event) =>  {
            this.onFocus(null);
            this.inputRef.close();
        });

        setTimeout(() => {
            this.handleOverridesColors()  
        })  
    }

    onDropdownClose(): void {
        this.isOpen = false;
        this.dropDownClosed.next();
    }

    showErroTooltip(): void {
        if (this.popperError) {
            PopperHelper.show(this.popperError);
        }
    }

    private isOutsideClicked(event: Event): boolean {
        return !(this.el.nativeElement.isSameNode(event.target) || this.isDropDownClicked(event) || this.isSearchInputClicked(event) || this.dropdownOpening ||
            this.isDropdownItem(event) || this.el.nativeElement.contains(event.target));
    }

    private unbindListener() {
        if (this.documentClickListener) {
            this.documentClickListener();
            this.documentClickListener = null;
        }

        if (this.searchInputMouseDownListener) {
            this.searchInputMouseDownListener();
            this.searchInputMouseDownListener = null;
        }

        if (this.searchInputMouseUpListener) {
            this.searchInputMouseUpListener();
            this.searchInputMouseUpListener = null;
        }

        if (this.elementClickListener) {
            this.elementClickListener();
            this.elementClickListener = null;
        }
    }

    private isDropDownClicked(event: Event): boolean {
        if (this.inputRef.dropdownPanel?.scrollElementRef?.nativeElement) {
            return (this.inputRef?.dropdownPanel as any)?._dropdown.contains(event.target);
        } else {
            return false;
        }
    }

    private isSearchInputClicked(event: Event): boolean {
        if (this.inputRef.searchInput?.nativeElement) {
            return (this.inputRef.searchInput?.nativeElement as any)?.contains(event.target);
        } else {
            return false;
        }
    }

    private isDropdownItem(event): boolean {
        return (event?.target?.className?.includes && event?.target?.className?.includes(this.inputRef?.dropdownId)) || 
        (event?.target?.parentNode?.className?.includes && event?.target?.parentNode?.className?.includes(this.inputRef?.dropdownId));
    }

    private bindDocumentClickListener(): void {
        if (!this.documentClickListener) {
            this.zone.runOutsideAngular(() => {
                const documentTarget: any = this.el ? this.el.nativeElement.ownerDocument : 'document';

                this.documentClickListener = this.renderer.listen(documentTarget, 'click', (event) => {
                    if (this.isOutsideClicked(event)) {
                        this.zone.run(() => {
                            this.onFinishEditing.emit();
                            this.cd.markForCheck();
                        });
                    }

                });
            });
        }
    }

    private getCodeAndDescription() {

        if(this.decodeProperties.length == 0){
            return this.decodeDescription.getValue() == null ?
            `${this.code.getValue()}` :
            this.showCodeInDescription ? [this.code?.getValue().toString(), this.decodeDescription?.getValue()].filter(v => v?.length > 0).join(' - ') : this.decodeDescription.getValue();
        } else {
            let values = [];
            this.decodeProperties.forEach( p => {
                values.push(this.externalPropertyViewModel.getProperty(p).value.toString());
            })
            return values.filter(v => v?.length > 0).join(' - ');
        }
    }

    private copyMessage(val: string): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    private checkAsyncSelected(): void {
        const codeValue = this.code?.getValue();
        const isNullable = this.code instanceof NNumericPropertyViewModel;
        if (this.code?.hasErrors && this.selectedItem?.identity === codeValue) {
            // Se il codice ha errori, e ho già selezionato un elemento con lo stesso codice,
            // non faccio niente
        } else if (codeValue != null && (isNullable ? true : (codeValue !== 0))) {
            this.selectedItem = {
                identity: this.code.getValue(),
                description: this.getCodeAndDescription(),
                all: null
            };

        } else {
            this.selectedItem = null;
        }
        PopperHelper.hide(this.popperInfo);
        this.cd.detectChanges();
    }

    private loadItems(): void {

        // Quando termina la ricerca
        this.searchFinished.pipe(
            this.untilDestroyedThis(),
        ).subscribe(() => {
            // se esiste un marked item
            if (this.inputRef?.dropdownPanel?.markedItem) {
                // // resetto lastInputText
                // this.lastInputText = null;
                if (this.inputRef?.dropdownPanel?.markedItem?.value) {
                    const identity = (this.inputRef?.dropdownPanel?.markedItem?.value as { description: string, identity: any, all: any })?.identity;
                    if (identity) {
                        this.valueChange.emit(identity);
                    }
                }
            }
        });

        this.itemInput$.pipe(
            this.untilDestroyedThis(),
            distinctUntilChanged()
        ).subscribe((term) => {
            if (term != null) {
                this.lastInputText = term;
                // Gestisce la casistica di cancellazione senza pulire l'elemento selezionato
                if (term !== '') {
                    this.valueChange.emit(term);
                }
            }
        });

        this.items$ = concat(
            of([]), // default items
            this.itemInput$.pipe(
                this.untilDestroyedThis(),
                distinctUntilChanged(),
                tap((term) => {
                    if (term?.length > 0) {
                        this.itemLoading = true
                    }                    
                }),
                switchMap(
                    (term) => {
                        if (
                            term?.length > 0 &&
                            this.code?.getValue() &&
                            term === this.code?.getValue().toString() &&
                            this.decodeDescription.getValue() != null &&
                            !this.code.hasErrors
                        ) {
                            this.itemLoading = false;
                            this.cd.detectChanges();
                            return of([
                                {
                                    identity: this.code.getValue(),
                                    description: this.getCodeAndDescription(),
                                    all: [{
                                        IsActive: !this.isSelectedItemDeactivated()
                                    }]
                                }
                            ]);
                        } else if (term?.length >= this.externalPropertyViewModel.minSearchLength) {

                            return this.externalPropertyViewModel.execAutocomplete(
                                term, this.language ? this.language.twoLetterISOLanguageName : null, this.showCodeInDescription
                            ).pipe(
                                map((items) => items.sort((x, y) => {

                                    const lowerTerm = term.toLowerCase();

                                    if (x.description.toLowerCase().startsWith(lowerTerm) && y.description.toLowerCase().startsWith(lowerTerm)) {
                                        return x.description.localeCompare(y.description);
                                    } else if (x.description.toLowerCase().startsWith(lowerTerm) && !y.description.toLowerCase().startsWith(lowerTerm)) {
                                        return -1;
                                    } else if (!x.description.toLowerCase().startsWith(lowerTerm) && y.description.toLowerCase().startsWith(lowerTerm)) {
                                        return 1;
                                    } else {
                                        // (!x.description.toLowerCase().startsWith(lowerTerm) && !y.description.toLowerCase().startsWith(lowerTerm)) {
                                        if (x.description.toLowerCase().indexOf(lowerTerm) > -1 && y.description.toLowerCase().indexOf(lowerTerm) == 0) {
                                            return -1;
                                        } else if (x.description.toLowerCase().indexOf(lowerTerm) == 0 && y.description.toLowerCase().indexOf(lowerTerm) > -1) {
                                            return 1;
                                        } else {
                                            return x.description.localeCompare(y.description);
                                        }
                                    }
                                })),
                                catchError(() => of([])), // empty list on error
                                tap(
                                    () => {
                                        this.itemLoading = false;
                                        this.cd.detectChanges();
                                        this.searchFinished.next();
                                    }
                                )
                            );
                        } else {
                            this.itemLoading = false;
                            this.searchFinished.next();
                            this.cd.detectChanges();
                            return of([]);
                        }
                    }
                )
            )
        );
    }
}

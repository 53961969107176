import { BaseIdentity } from '../domain-models/base-identity';
import { Expose } from '../serialization/class-transformer/decorators';

export class PinIdentityToDashboardDto<TIDentity extends BaseIdentity> {

    @Expose()
    identity: TIDentity;

    @Expose()
    title: string;
}

import numeral from 'numeral';
import { LogService } from './log/log.service';
// Load it locales
export class NumeralService {

    private static internalInstance: NumeralService;

    static get Current(): any {
        if (this.internalInstance == null) {
            this.internalInstance = new NumeralService();
        }
        return this.internalInstance.numeralInstance;
    }

    numeralInstance: any;

    constructor() {
        
        // Register locales
        // https://github.com/adamwdraper/Numeral-js/blob/master/locales/it.js

        this.numeralInstance = numeral as any;

        try {
            this.registerLocales();
        } catch(err) {
            LogService.debug(err as any);
        }        

        
    }

    private registerLocales() {
        this.numeralInstance.register('locale', 'it', {
            delimiters: {
                thousands: '.',
                decimal: ','
            },
            abbreviations: {
                thousand: 'mila',
                million: 'mil',
                billion: 'b',
                trillion: 't'
            },
            ordinal: function () {
                return 'º';
            },
            currency: {
                symbol: '€'
            }
        });

        this.numeralInstance.register('locale', 'en-gb', {
            delimiters: {
                thousands: ',',
                decimal: '.'
            },
            abbreviations: {
                thousand: 'k',
                million: 'm',
                billion: 'b',
                trillion: 't'
            },
            ordinal: function (number) {
                var b = number % 10;
                return (~~ (number % 100 / 10) === 1) ? 'th' :
                    (b === 1) ? 'st' :
                    (b === 2) ? 'nd' :
                    (b === 3) ? 'rd' : 'th';
            },
            currency: {
                symbol: '£'
            }
        });

        this.numeralInstance.register('locale', 'en-US', {
            delimiters: {
                thousands: ',',
                decimal: '.'
            },
            abbreviations: {
                thousand: 'k',
                million: 'm',
                billion: 'b',
                trillion: 't'
            },
            ordinal: function(number) {
                var b = number % 10;
                return (~~(number % 100 / 10) === 1) ? 'th' :
                    (b === 1) ? 'st' :
                    (b === 2) ? 'nd' :
                    (b === 3) ? 'rd' : 'th';
            },
            currency: {
                symbol: '$'
            }
        });
    }    
}


import { map, Observable, of, Subject, takeUntil, tap } from "rxjs";
import { ViewModelTypeDecorator } from "../../decorators";
import { StringDecorator } from "../../domain-models/decorators/string.decorator";
import { StringMetaData } from "../../meta-data/string-meta-data";
import { MessageResourceManager } from "../../resources/message-resource-manager";
import { StringPropertyViewModel } from "../base-type/string-property-view-model";
import { CommandFactory } from "../commands/command-factory";
import { UIResultCommandInterface } from "../commands/ui-result-command.interface";
import { CustomPropertyViewModelDecorator } from "../decorators/custom-property-view-model.decorator";
import { ModifiedSubscriberInterface } from "../modified-subscriber.interface";
import { PropertyViewModelFactory } from "../property-view-model-factory";
import { ViewModelEventDispatcher } from "../view-model-event-dispatcher";
import { ModalViewModelInterface } from "./modal-view-model.interface";

@ViewModelTypeDecorator(PinIdentityToDashboardModalViewModel)
export class PinIdentityToDashboardModalViewModel implements ModalViewModelInterface<any, any> {

    modalTitle = MessageResourceManager.Current.getMessage('PinIdentityToDashboard_Title');
    modalSubtitle = '';
    showFooter = true;
    modalCommands = [];
    pinCommand: UIResultCommandInterface<boolean>;

    modifiedSubscriber: ModifiedSubscriberInterface;
    eventDispatcher: ViewModelEventDispatcher;
    destroySubscribers$ = new Subject<void>();

    constructor() {
        this.setupCommands();
    }

    private async pin(): Promise<boolean> {
        this.pinCommand.closeModal = this.title?.value != '';
        return true;
    }

    private canPin(): Observable<boolean> {
        return this.title.propertyChanged.pipe(takeUntil(this.destroySubscribers$), 
            map(() => this.title.hasErrors === false)
        )
    }

    private setupCommands(): void {
        this.pinCommand = CommandFactory.createResultUICommand(
            () => this.pin(),
            () => this.canPin()
        );
        this.pinCommand.displayName = MessageResourceManager.Current.getMessage('PinIdentityToDashboard_CMD_Pin');
        this.pinCommand.tooltip = MessageResourceManager.Current.getMessage('PinIdentityToDashboard_CMD_Pin_Tooltip');
        this.pinCommand.isDefault = true;
        this.modalCommands.push(this.pinCommand);
    }
    async initialize(): Promise<void> { }

    // tslint:disable-next-line: member-ordering variable-name
    private _title: StringPropertyViewModel;

    @CustomPropertyViewModelDecorator()
    @StringDecorator({ 
        displayNameKey: 'std_PinIdentityToDashboard_Description', 
        isRequired: true, 
        maxLength: 100
    })
    public get title(): StringPropertyViewModel {
        if (this._title == null) {
            const init = PropertyViewModelFactory.createPVMInitializationInfo<StringMetaData>(
                this,
                'title', 
                null,
                this,
                this.modifiedSubscriber, 
                this.eventDispatcher, 
                null, 
                false, 
                true
            );
            this._title = new StringPropertyViewModel(init);
            this._title.instantModelChange = true;
        }
        return this._title;
    }
}


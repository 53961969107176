import { NTSReflection } from '@nts/std/src/lib/utility';
import { ClassType } from '../../serialization/class-transformer/ClassTransformer';
import { InternalViewModelInterface } from '../internal-view-model.interface';

const INTERNAL_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY = 'internalViewModelTypeDecoratorMetadataKey';

export function InternalViewModelTypeDecorator<T extends InternalViewModelInterface>(internalViewModelType: ClassType<T> | ((target: any, propertyKey: string) => any)) {
    return (target: any, propertyKey: any) => {
        Reflect.defineMetadata(INTERNAL_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, internalViewModelType, target, propertyKey);
    };
}

export class InternalViewModelTypeInspector {
    static isApplied(subject: any, propertyName: string): boolean {
        return NTSReflection.hasPropertyMetadata(
            INTERNAL_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject, propertyName);
    }

    static getValue(subject: any, propertyName: string): any {
        let value = NTSReflection.getPropertyMetadata(
            INTERNAL_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject, propertyName);

        // Verifica che sia una funzione con due parametri
        if (typeof value === 'function' && value.length === 2) {
            value = value(subject, propertyName);
        }
        return value;
    }
}

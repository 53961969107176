import { Expose, Type } from '../serialization/class-transformer';
import { GridColumnsMetaData } from './grid-columns-meta-data';
import { FieldMetaData } from './field-meta-data';
import { FieldTypes } from './field-types.enum';

export class GridMetaData extends FieldMetaData {

    constructor() {
        super();
        this.fieldType = FieldTypes.Grid;
        this.gridColumns = new GridColumnsMetaData();
    }

    @Expose()
    @Type(() => GridColumnsMetaData)
    gridColumns: GridColumnsMetaData;
    
    @Expose()
    areAllColumnsDisabled: boolean;

    @Expose()
    areAllCommandsDisabled: boolean;
}

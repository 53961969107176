import { Component, OnInit } from '@angular/core';
import { PopupViewModel } from '../../view-models/modal/popup-view-model';
import { ModalComponentInterface } from '../modal/modal-component.interface';
import { NgFor, NgIf } from '@angular/common';
import { FieldsetModule } from 'primeng/fieldset';

@Component({
    selector: 'nts-popup',
    templateUrl: 'popup-container.component.html',
    styleUrls: ['popup-container.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        FieldsetModule,
        NgFor
    ]
})

export class PopupContainerComponent implements OnInit, ModalComponentInterface {
    viewModel: PopupViewModel;

    constructor() { }

    ngOnInit(): void {
    }
}

import { Expose, Type } from "../../serialization/class-transformer";
import { AttachmentIdentity } from "./attachment.identity";
import { OperationState } from "./operation-state";

export class OperationStateResultDto {

    constructor() {
        this.attachmentIdentities = [];
    }

    @Expose()
    currentOperationState: OperationState

    @Expose()
    @Type(() => AttachmentIdentity)
    attachmentIdentities: AttachmentIdentity[]

}

export enum ClassInformationType {
    RootViewModel = 'RootViewModel',
    ItemViewModel = 'ItemViewModel',
    PropertyViewModel = 'PropertyViewModel',
    CollectionViewModel = 'CollectionViewModel',
    InternalViewModel = 'InternalViewModel',
    ExternalViewModel = 'ExternalViewModel',
    MasterViewModel = 'MasterViewModel',
    OrchestratorViewModel = 'OrchestratorViewModel',
    MasterDetailOrchestratorViewModel = 'MasterDetailOrchestratorViewModel',
}

import { Expose } from "../../serialization/class-transformer/decorators";

export class UIInfo {

    @Expose()
    baseAddress: string;

    @Expose()
    fullAddress: string;

    // serviceType: ServiceTypes;

    // clientType: ClientTypes;

    // enviromentType: EnvType;

}

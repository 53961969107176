import { EventEmitter, Inject, Injectable, NgZone } from '@angular/core';
import { Subscriber, Subscription } from 'rxjs';
import { PostMessageBridgeInterface } from './post-message-bridge.interface';
import { PostMessageEventTargetInterface } from './post-message-event-target.interface';
import { PostMessageBusSource, PostMessageBusSink } from './post-message-bus';
import { PostMessageInterface } from './post-message.interface';



@Injectable()
export class PostMessageBridgeImpl implements PostMessageBridgeInterface {

    // TODO
    private static logger = console;

    private busSource!: PostMessageBusSource;
    private busSink!: PostMessageBusSink;

    private _sources: Map<string, EventEmitter<any>> = new Map<string, EventEmitter<any>>();
    private _targets: Map<string, EventEmitter<any>> = new Map<string, EventEmitter<any>>();
    private _subscribers: Map<string, Map<Function, Subscriber<any>>> = new Map<string, Map<Function, Subscriber<any>>>();

    private loggingEnable = true;

    constructor(@Inject(NgZone) private ngZone: NgZone) {
    }

    /**
     * @override
     */
    connect(source: PostMessageEventTargetInterface, target: PostMessageEventTargetInterface, targetOrigin: string = '*'): PostMessageBridgeInterface {

        this.busSource = new PostMessageBusSource(source);
        this.busSource.attachToZone(this.ngZone);

        this.busSink = new PostMessageBusSink({

            postMessage: (messages: PostMessageInterface[]): void => {
                if (source !== target) {
                    target.postMessage(messages, targetOrigin);

                    if (this.loggingEnable) {
                        PostMessageBridgeImpl.logger.debug(`[$PostMessageBridgeImpl] The messages`, messages, `were sent from the source`, source, `to the target`, target);
                    }
                } else {
                    if (this.loggingEnable) {
                        PostMessageBridgeImpl.logger.warn(`[$PostMessageBridgeImpl] It's impossible to send the messages `, messages, ` because the source and the target are equal! The source is`, source);
                    }
                }
            }
        });
        this.busSink.attachToZone(this.ngZone);

        if (this.loggingEnable) {
            PostMessageBridgeImpl.logger.debug(`[$PostMessageBridgeImpl] The bridge service was successfully initiated for the target origin '${targetOrigin}'.`);
        }
        return this;
    }

    /**
     * @override
     */
    makeBridge(bridgeName: string): PostMessageBridgeInterface {
        this.busSource.initChannel(bridgeName, true);
        this._sources.set(bridgeName, this.busSource.from(bridgeName));

        this.busSink.initChannel(bridgeName, true);
        this._targets.set(bridgeName, this.busSink.to(bridgeName));

        if (this.loggingEnable) {
            PostMessageBridgeImpl.logger.debug(`[$PostMessageBridgeImpl] The bridge '${bridgeName}' was successfully registered.`);
        }
        return this;
    }

    /**
     * @override
     */
    sendMessage(bridgeName: string, message?: any): PostMessageBridgeInterface {
        this._targets.get(bridgeName)?.emit(message);
        return this;
    }

    /**
     * @override
     */
    addListener(bridgeName: string, listener: Function): PostMessageBridgeInterface {
        let subscriber: Subscription|any;
        
        const source = this._sources.get(bridgeName);
        if (source) {
            subscriber = source.subscribe(listener);
        }


        let subscribers: Map<Function, Subscription>|any = this._subscribers.get(bridgeName);
        if (!subscribers) {
            this._subscribers.set(bridgeName, subscribers = new Map<Function, Subscriber<any>>());
        }

        if (subscriber) {
            subscribers.set(listener, subscriber);
        }        
        return this;
    }

    /**
     * @override
     */
    removeListener(bridgeName: string, listener: Function): PostMessageBridgeInterface {
        const subscribers: Map<Function, Subscriber<any>>|any = this._subscribers.get(bridgeName);

        if (subscribers) {
            const subscriber: Subscriber<any> = subscribers.get(listener);
            if (subscriber) {
                subscriber.unsubscribe();
                subscribers.delete(listener);
            } else {
                PostMessageBridgeImpl.logger.warn(`[$PostMessageBridgeImpl] There is no existing listener for '${bridgeName}'.`);
            }
        } else {
            PostMessageBridgeImpl.logger.warn(`[$PostMessageBridgeImpl] There are no existing listeners for '${bridgeName}'.`);
        }
        return this;
    }

    /**
     * @override
     */
    removeAllListeners(bridgeName: string): PostMessageBridgeInterface {
        const subscribers: Map<Function, Subscriber<any>>|any = this._subscribers.get(bridgeName);

        if (subscribers) {
            subscribers.forEach((subscriber: Subscriber<any>, listener: Function) => subscriber.unsubscribe());
            this._subscribers.delete(bridgeName);
        } else {
            PostMessageBridgeImpl.logger.warn(`[$PostMessageBridgeImpl] There are no existing listeners for '${bridgeName}'.`);
        }
        return this;
    }

    /**
     * @override
     */
    setEnableLogging(enabled: boolean): PostMessageBridgeInterface {
        this.loggingEnable = enabled;
        return this;
    }

    createUUID(): string {
        // http://www.ietf.org/rfc/rfc4122.txt
        const s = [];
        const hexDigits = '0123456789abcdef';
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = '4';  // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr(((s[19]as any) & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = '-';

        const uuid = s.join('');
        return uuid;
    }
}

import { Expose } from "../../serialization/class-transformer";

export class EnterpriseDataDto {
    
    /**
     * Azienda:
     * - è valorizzato a 0 se non è stato passato
     * - è valorizzato a -1 se non è stato trovato
     */
    @Expose()
    enterpriseId: number;
    
    /**
     * Ditta:
     * - è valorizzato a 0 se non è stato passato
     * - è valorizzato a -1 se non è stato trovato
     */
    @Expose()
    companyId: number;
}
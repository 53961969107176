import { Injectable, NgZone } from '@angular/core';
import { PostMessageBridgeInterface } from './post-message-bridge.interface';
import { PostMessageBridgeImpl } from './post-message-bridge-impl';


@Injectable()
export class PostMessageBridgeFactory {

    constructor(private ngZone: NgZone) {
    }

    makeInstance(): PostMessageBridgeInterface {
        return new PostMessageBridgeImpl(this.ngZone);
    }
}

import moment from 'moment';
import { TransformationType } from '../serialization/class-transformer/enums/transformation-type.enum';
import { TimeZoneData } from '@nts/std/src/lib/timezone';
export class DateTimeOffset {

    dateTime: Date|null;
    offset: number|null; // Minutes from UTC
    timeZoneData!: TimeZoneData|null;

    constructor(dateTime: Date|null = null, offset: number|null = null) {
        this.dateTime = dateTime;
        this.offset = offset;
    }

    static formatToString(value: DateTimeOffset): string|null {

        if (value == null || value.dateTime == null) {
            return null;
        }

        const offset = value.offset != null ? moment().utcOffset(value.offset) : moment().utcOffset(-value.dateTime.getTimezoneOffset());
        
        let  result;
        if(value?.offset != null && (value?.offset != -value.dateTime.getTimezoneOffset())){
            const newOffset = value.offset;
            result =  moment(value.dateTime).clone().utcOffset(newOffset).format('YYYY-MM-DDTHH:mm:ss') + offset.format('Z');
        }
        else{
            const newOffset = value?.offset ? value.offset : -value.dateTime.getTimezoneOffset();
            result =  moment(value.dateTime).clone().utcOffset(newOffset).format('YYYY-MM-DDTHH:mm:ss') + offset.format('Z');
        }

        return result;

    }
    static formatToStringLocalHumanReadable(value: DateTimeOffset, format = 'DD-MM-YYYY'): string|null {


        if (value == null || value.dateTime == null) {
            return null;
        }

        const offset = value.offset != null ? value.offset : -value.dateTime.getTimezoneOffset();
        return moment(value.dateTime).clone().utcOffset(offset).format(format);

    }

    static formatFromString(value: string): DateTimeOffset|null {


        if (value == null) {
            return null;
        }

        const offsetString = value.substr(value.length - 6, 6);

        const dateTime = new Date(value);

        const offset = moment().utcOffset(offsetString, false).utcOffset();

        const dateTimeOffset = new DateTimeOffset(dateTime, offset);

        return dateTimeOffset;

    }

    static transform(propertyName: string, value: string, obj: any, type: TransformationType) {
        // 2018-09-27 10:00:00.0 +02:00
        if (type === TransformationType.CLASS_TO_PLAIN) {
            if(Array.isArray(obj[propertyName])) {
                if (obj[propertyName]?.length > 0) {
                    return obj[propertyName].map((v) => DateTimeOffset.formatToString(v))
                } else if (obj[propertyName]?.length == 0) {
                    return [];
                }
                return null;
            }
            return DateTimeOffset.formatToString(obj[propertyName]);
        } else {
            if(Array.isArray(value)) {
                if (value?.length > 0) {
                    return value.map((v) => DateTimeOffset.formatFromString(v))
                }
                return null;
            }
            return DateTimeOffset.formatFromString(value);
        }
    }

    valueOf = () => {
        const offset = this.offset != null ? this.offset : -(this.dateTime ?? new Date()).getTimezoneOffset();
        return moment.utc(this.dateTime).utcOffset(offset).toDate();
    }

    toString = () : string => {
        return DateTimeOffset.formatToString(this)
    }
}

import { BaseViewModel } from '../../base-view-model';
import { ZoomOperatorViewModelProperty } from '../zoom-operator-view-model-property';
import { ZoomPropertyViewModelInterface } from '../property-view-model/zoom-property-view-model.interface';
import { FilterVariablesType } from '../../../domain-models/find-options/filter-variables-type.enum';
import { ZoomParametersViewModel } from '../zoom-parameters-view-model';
import { FilterOperators, RuntimeVariables } from '../../../domain-models/find-options/filter';
import { MessageResourceManager } from '../../../resources/message-resource-manager';
import { FilterVariableViewModel } from './filter-variable-view-model';
import { ZoomMetaData } from '../../../meta-data/zoom-meta-data';
import { CollapsableFilterViewModelInterface } from './collapsable-filter-view-model.interface';
import { ChildrenAwareInterface } from './children-aware.interface';
import { OrderByType } from '../../../domain-models';
import { Subject, takeUntil } from 'rxjs';

export class PropertyNameMap {
  displayName: string;
  propertyPath: string;
}

export class ZoomFilterViewModel<T = Object> extends BaseViewModel implements CollapsableFilterViewModelInterface, ChildrenAwareInterface {

  children: ZoomFilterViewModel[] = [];
  
  // Viene popolato nel caso in cui sia relazionato con altri filter view model, ad esempio nel bakcing field<->ext view model
  related: ZoomFilterViewModel;

  isSelectable = true;
  
  metaData: ZoomMetaData;
  
  get isRootIdentity() {
    return this.metaData?.isRootIdentity ?? false;
  }

  get isIdentity() {
    return this.metaData?.isIdentity ?? false;
  }

  propertyNameMap: PropertyNameMap;
  operator: ZoomOperatorViewModelProperty;
  filterValue: ZoomPropertyViewModelInterface<T>;
  filterValue2: ZoomPropertyViewModelInterface<T>;

  orderBy: OrderByType = null;
  orderByIndex: number = null;

  filterVariable?: RuntimeVariables;
  filterVariable2?: RuntimeVariables;
  filterVariablesType?: FilterVariablesType;

  zoomParametersViewModel: ZoomParametersViewModel;

  externalDomainModelNameToZoom = '';
  externalDomainModelFullNameToZoom = '';

  dependentAssociationProperty: string;

  showVariables = false; // Prop di VM: serve per mostrare il dropdown con lista delle variabili
  showVariables2 = false;
  filterVariables = [];
  filterVariableValue: FilterVariableViewModel;
  filterVariable2Value: FilterVariableViewModel;
  isExternalRemote = false;
  isExternal = false;
  isInternal = false;
  isExpanded = false;
  isBackingField = false;

  /**
   * Utilizzata per nascondere i filtri nei parameters
   */
  isHidden = false;
  isEnableStatusChanged = new Subject<boolean>();

  private _originalOperatorValues = [];


  private _islocked = false;

  get islocked(): boolean {
    return this._islocked;
  }

  set islocked(newValue: boolean) {
    this._islocked = newValue;
    this.isEnabled = !newValue
    this.operator.isEnabled = !newValue;
  }

  private _isCollapsed = true;

  get isCollapsed(): boolean {
    return this._isCollapsed;
  }

  set isCollapsed(newValue: boolean) {
    this._isCollapsed = newValue;
    this.setVisibilityToChildren(!newValue);

    // disabilita l'operatore quando apro espando l'external
    if (this.metaData.external && !this.isExternalRemote) {
      this.operator.isEnabled = newValue;
      if (!this.operator.isEnabled) {
        this.operator.currentValue = FilterOperators.None;
      }
    }
  }

  private internalIsEnabled = true;

  override get isEnabled(): boolean {
    return this.internalIsEnabled;
  }

  override set isEnabled(newValue: boolean) {
    this.internalIsEnabled = newValue;
    this.isEnableStatusChanged.next(newValue);
  }

  private _isSelected = true;

  get isSelected(): boolean {
    return this._isSelected;
  }

  set isSelected(newValue: boolean) {
    this._isSelected = newValue;
    this.setSelectedToChildren(newValue);
  }

  get OperatorDoesNotRequireValue(): boolean {
    return this.operator.currentValue === FilterOperators.None || this.operator.currentValue === FilterOperators.IsNull || this.operator.currentValue === FilterOperators.IsNotNull;
  }

  /**
   * Serve per indicare quando la lista degli operatori sono stati cambiati
   */
  private operatorListChanged = new Subject<void>()

  constructor(metaData: ZoomMetaData) {
    super();
    this.metaData = metaData;
    this.propertyNameMap = new PropertyNameMap();
    this.propertyNameMap.displayName = metaData.displayName;
    this.propertyNameMap.propertyPath = metaData.propertyPath;
    this.isExternalRemote = metaData.external?.isRemote ?? false;
    this.isInternal = metaData.internalRelation != null;
    this.isExternal = metaData.external != null;

    if(this.isExternalRemote || this.isExternal || this.isInternal){
      // let newPath = this.propertyNameMap.propertyPath.split('.').slice(0, -2).concat(this.metaData.external.associationProperties[0].principalPropertyName).join('.');
      // if (this.metaData?.external?.associationProperties?.length === 0) {
      //   newPath = this.propertyNameMap.propertyPath.split('.').slice(0, -2).concat(this.metaData.external.principalPropertyName).join('.');
      // }
      this.propertyNameMap.propertyPath = metaData.rootPath;
    }
  }

  addNewFilterInValues()  {
    
  }

  deleteFilterInValues(inToRemove: ZoomPropertyViewModelInterface<T>) {

  }

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  updateOperatorList(operator: ZoomOperatorViewModelProperty) {
    this.operatorListChanged.next();
    this.operator = operator;
    this.applyOperatorLogics();
  }

  protected init() {
    this.setVariables(this.filterVariablesType);
    this.applyOperatorLogics();
  }

  protected applyOperatorLogics() {
    this.operator.operatorChanged.pipe(takeUntil(this.operatorListChanged)).subscribe(async (value: FilterOperators) => {
      this.onOperatorChanged(value);
    });

    this._originalOperatorValues = this.operator.values;
    // by default: solo operatori standard
    this.operator.values = this.operator.values.filter((value) => !value.isAdvanced);
    this.operator.advancedOperatorValuesChanged.subscribe((showAdvancedFilters) => {
      if (showAdvancedFilters) {
        this.operator.values = this._originalOperatorValues;
      } else {
        this.operator.values = this.operator.values.filter((value) => !value.isAdvanced);
      }
      this.operator.operatorValuesChanged.next(); // Notifica ai componenti che è stata modificata la lista degli operatori
    });
  }

  protected async onOperatorChanged(value: FilterOperators): Promise<void> {
    if (this.OperatorDoesNotRequireValue) {
      this.filterValue.setEnabled(false);
      await this.filterValue.resetValue();
      this.zoomParametersViewModel.isActiveF6 = false;
    } else {
      this.filterValue.setEnabled(true);
      if (this.filterValue2 != null) {
        this.filterValue2.setEnabled(true);
        await this.filterValue2.resetValue();
      }
      this.zoomParametersViewModel.isActiveF6 = !!(this.externalDomainModelNameToZoom);
    }
  }

  private setSelectedToChildren(newValue: boolean) {
    if (this.children.length > 0) {
      this.children.forEach((c) => {
        c.isSelected = newValue;
      });
    }
  }

  private setVisibilityToChildren(newValue: boolean) {
    if (this.children.length > 0) {
      this.children.forEach((c) => {
        c.isVisible = newValue;
        if (newValue === false) {
          c.isCollapsed = true;
        }
      });
    }
  }

  private setVariables(type: FilterVariablesType) {
    if (type != null) {
      let variableOption: any;
      switch (type) {
        case FilterVariablesType.DateFilter:
          variableOption = new FilterVariableViewModel(RuntimeVariables.Today, MessageResourceManager.Current.getMessage('std_FilterVariables_Today'));
          this.filterVariables.push(variableOption);
          break;
        case FilterVariablesType.UserFilter:
          variableOption = new FilterVariableViewModel(RuntimeVariables.CurrentUser, MessageResourceManager.Current.getMessage('std_FilterVariables_CurrentUser'));
          this.filterVariables.push(variableOption);
          break;
      }
    }
  }

  setDefault() {
    this.operator.currentValue = FilterOperators.None;
  }

  setValue(value: Object) {
  }

  setVariable(variable: RuntimeVariables, variable2: RuntimeVariables) {
    if (variable != null) {
      this.filterVariableValue = new FilterVariableViewModel(variable, '');
      this.filterVariable = variable;
      this.showVariables = true;
    }
    if (variable2 != null) {
      this.filterVariable2Value = new FilterVariableViewModel(variable2, '');
      this.filterVariable2 = variable2;
      this.showVariables2 = true;
    }
  }
}

import { Expose } from '../serialization/class-transformer/decorators';

export class UserLayoutColumnMetaData {

    @Expose()
    name: string;

    @Expose()
    path: string;

    private _fullPathName: string;

    @Expose()
    get fullPathName() {
        if (this._fullPathName?.length > 0) {
            return this._fullPathName;
        }
        let ret = this.path?.length > 0 ? `${this.path}.${this.name}` : this.name;
        ret = ret.replace(/.selectedItem/gi, '');
        return ret?.length > 0 ? ret : null;
    };
    set fullPathName(fullPathName: string) {
        this._fullPathName = fullPathName;
    }

    @Expose()
    position: number;

    @Expose()
    width: number;

    @Expose()
    isAutoSize: boolean;

    @Expose()
    isEnabled: boolean;

    @Expose()
    isUserVisible: boolean;
}

import { FieldMetaData } from './field-meta-data';
import { FieldTypes } from './field-types.enum';

export class SpacerMetaData extends FieldMetaData {

    constructor() {
        super();
        this.fieldType = FieldTypes.Spacer;
    }
}

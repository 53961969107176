import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { BaseError } from '../../../messages/base-error';
import { MessageResourceManager } from '../../../resources/message-resource-manager';
import { ComponentErrorInterface } from '../component-error.interface';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NTSTranslatePipe } from '../../pipe/nts-translation-pipe';
import { NgIf } from '@angular/common';
import { FilledButtonComponent } from '../../shared/buttons/filled-button/filled-button.component';

@Component({
    selector: 'nts-forbidden',
    templateUrl: './forbidden.component.html',
    styleUrls: ['./forbidden.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        SvgIconComponent,
        NTSTranslatePipe,
        NgIf,
        FilledButtonComponent
    ]
})
export class ForbiddenComponent implements ComponentErrorInterface, OnInit {

    currentLogin = '';

    // popolata tramite injecton
    errors: BaseError[];
    errorTitle: string;
    errorDescription: string;
    errorDetailTitle: string;
    errorDetailDescription: string;
    errorDetailMoreDescription: string;
    showDetailMore = false;
    expandDetailMore = false;
    showMoreLimit = 0; // 0 disabilitato

    constructor(
        public readonly environmentConfiguration: EnvironmentConfiguration,
        private readonly authService: AuthService,
        private readonly cd: ChangeDetectorRef
    ) {
        
    }

    async ngOnInit(): Promise<void> {
        const userProfile = await this.authService.getUserProfile();
        const tenantProfile = await this.authService.getTenantProfile();
        this.currentLogin = `${userProfile.name} ${userProfile.lastName}${tenantProfile?.businessName?.length > 0 ? ' (' + tenantProfile?.businessName + ')' : ''}`;

        this.errorTitle = MessageResourceManager.Current.getMessage('std_PageError403_Title');
        this.errorDescription = MessageResourceManager.Current.getMessage('std_PageError403_Description');
        this.errorDetailDescription = MessageResourceManager.Current.getMessageWithStrings('PageError403_DetailDescription', this.currentLogin);

        if (this.errorDetailDescription.length > this.showMoreLimit && this.showMoreLimit > 0) {
            this.errorDetailMoreDescription = this.errorDetailDescription.substring(this.showMoreLimit, this.errorDetailDescription.length);
            this.errorDetailDescription = this.errorDetailDescription.substring(0, this.showMoreLimit);
            this.showDetailMore = true;
        }

        this.cd.detectChanges();
    }

    toggleShowMore(): void {
        this.expandDetailMore = !this.expandDetailMore;
    }

    logOut(): void {
        this.authService.logOut();
    }

}

import { Component } from '@angular/core';
import { BaseNumericPropertyViewModel } from '../../../view-models/base-type/base-numeric-property-view-model';
import { NNumericPropertyViewModel } from '../../../view-models/base-type/nnumeric-property-view-model';
import { NumericMaskSettings } from './base/base-numeric-box/base-numeric-box.component';
import { NewBasePropertyTextBox } from './new-base-property-text-box';

@Component({
    template: ''
})
export abstract class BaseNumericPropertyTextBox extends NewBasePropertyTextBox<number> {

    override propertyViewModel: BaseNumericPropertyViewModel;
    
    calculateMaxValue(decimalDigits: number, integerDigits: number): number {
        const integerDigitsString = "9".repeat(integerDigits);
        let decimalDigitsString = "";
        if (decimalDigits > 0) {
            decimalDigitsString = BaseNumericPropertyViewModel.getDecimalSeparator() + "9".repeat(decimalDigits);
        }
        return parseFloat(integerDigitsString + decimalDigitsString);
    }

    calculateMinValue(decimalDigits: number, integerDigits: number): number {
        return this.calculateMaxValue(decimalDigits, integerDigits) * -1 ;
    }

    protected getMaskSettings(): NumericMaskSettings {
        const nullable = this.propertyViewModel instanceof NNumericPropertyViewModel;
        const digits = BaseNumericPropertyViewModel.getDecimalDigits(this.propertyViewModel.propertyName, this.propertyViewModel.integerLimit, this.propertyViewModel.decimalLimit);
        const integerDigits = BaseNumericPropertyViewModel.getIntegerDigits(this.propertyViewModel.propertyName, this.propertyViewModel.integerLimit, this.propertyViewModel.decimalLimit);
        const max = this.propertyViewModel.maxValue != null ? this.propertyViewModel.maxValue : this.calculateMaxValue(digits, integerDigits);
        const min = this.propertyViewModel.minValue != null ? this.propertyViewModel.minValue : this.calculateMinValue(digits, integerDigits);

        return {
            nullable,
            decimalLimit: digits,
            integerLimit: integerDigits,
            useThousandSeparator: this.propertyViewModel.useThousandSeparator,
            min,
            max,

            // alias: 'numeric',
            // nullable,
            // positionCaretOnClick: 'none' as any,
            // allowMinus: true,
            // allowPlus: false,
            // unmaskAsNumber: true,
            // radixPoint: BaseNumericPropertyViewModel.getDecimalSeparator(),
            // groupSeparator: this.propertyViewModel.useThousandSeparator ? BaseNumericPropertyViewModel.getThousandSeparator() : '',
            // SetMaxOnOverflow: false,
            // showMaskOnHover: false,
            // digits,
            // digitsOptional: true,
            // min,
            // max,
            // placeholder: nullable ? '' : '0',
            // rightAlign: true,
            // clearMaskOnLostFocus: true
        };
    }
}

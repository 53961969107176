export enum MessageType {
    Message = 0,
    /**
     * @Deprecated
     * Richiesta di pending changes
     */
    AskPendingChanges = 1,

     /**
     * Richiesta di external return
     */
    ExternalReturn = 2,

    /**
     * La connessione è stata chiusa
     */
    ClosedConnection = 3,
     
    /**
     * Verifica se ci sono modifiche pending
     */
    AnswerPendingChanges = 5,

    /**
     * Richiesta stato
     */
    AskStatus = 6,

    /**
     * Risposta stato
     */
    AnswerStatus = 7,

    /**
     * Cross-site storage
     */
    CrossSiteStorageSet = 10,

    /**
     * Cross-site storage
     */
    CrossSiteStorageGet = 11,

    /**
     * Cross-site storage
     */
    CrossSiteStorageRemove = 12

    
}

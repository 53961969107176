import { EnumItem } from './../base-type/enum-property-view-model';
import { FilterOperators } from '../../domain-models/find-options/filter';

export class FilterOperatorInfo extends EnumItem {
    shortName: string;
    value: FilterOperators;
    isAdvanced = false; // operatore avanzato: es. Between o contains

    constructor(op: FilterOperators, name: string, isAdvanced?: boolean) {
        super(op, name);
        this.value = op;
        this.shortName = name;
        if (isAdvanced != null) {
            this.isAdvanced = isAdvanced;
        }
    }

}

import { OnChanges, Component, Input, ComponentFactoryResolver, SimpleChanges, ViewContainerRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { WingViewModelInterface } from '../../view-models/wing-view-model.interface';
import { ComponentLocator } from '../component-locator';
import { WingViewComponentInterface } from '../wing-view-component.interface';
import { WingViewModelTypeInspector } from '../../view-models/decorators/wing-view-model-type.decorator';
import { LoaderComponent } from '../shared/loader/loader.component';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'nts-wing-container',
    templateUrl: './wing-container.component.html',
    styleUrls: ['./wing-container.component.scss'],
    standalone: true,
    imports: [
        LoaderComponent,
        AsyncPipe
    ]
})
export class WingContainerComponent implements OnChanges {

    @Input() wingViewModel!: WingViewModelInterface;
    @ViewChild('wingPlaceHolder', { read: ViewContainerRef, static: true }) wingPlaceHolder: ViewContainerRef;

    isCollapsed!: boolean;

    private _componentInstance: any = null;

    constructor(
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected componentLocator: ComponentLocator) {

    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {

        if (changes['wingViewModel'] && this.wingViewModel !== undefined) {

            this.wingPlaceHolder.clear();

            // Composition e DI dinamica
            this.clearComponentInstance();

            const viewModelType = WingViewModelTypeInspector.getValue(this.wingViewModel);
            const componentType = this.componentLocator.getComponentType(viewModelType);
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
            const componentRef = this.wingPlaceHolder.createComponent(componentFactory);
            this._componentInstance = componentRef.instance as WingViewComponentInterface;
            this._componentInstance.wingViewModel = this.wingViewModel;
            await this._componentInstance.initialize();
            await (this._componentInstance.wingViewModel as WingViewModelInterface)?.postInit();

        }
    }

    clearComponentInstance() {
        if (this._componentInstance != null) {
            this.wingPlaceHolder.remove(this.wingPlaceHolder.indexOf(this._componentInstance));
        }
    }
}

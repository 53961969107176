import { EventEmitter } from '@angular/core';
import { UUIDHelper } from '@nts/std/src/lib/utility';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ResultCommandInterface } from './result-command.interface';
import { UIResultCommandInterface } from './ui-result-command.interface';

export class ModalCommandWrapper<TCommandResult> implements ResultCommandInterface<TCommandResult> {

    closeModal = true;
    executed: EventEmitter<TCommandResult> = new EventEmitter<TCommandResult>();
    name: string;
    isCancel: boolean;
    internalCommand: UIResultCommandInterface<TCommandResult>;
    loading$ = new BehaviorSubject<boolean>(false);
    displayName$ = new BehaviorSubject<string>('');

    /**
     * Property per gestire la visualizzazione dell'etichetta del pulsante, di default è true
     */
    showDisplayName$ = new BehaviorSubject<boolean>(true);

    tooltip$ = new BehaviorSubject<string>('');
    iconClass$ = new BehaviorSubject<string>('');
    isVisible$ = new BehaviorSubject<boolean>(true);
    canExecute$ = new BehaviorSubject<boolean>(true);
    data: any;
    uid: string;
    onlyInMoreOptions: boolean;

    postExecute: (x: TCommandResult) => TCommandResult;

    isHighlighted(parameter: any): Observable<boolean> {
        return this.internalCommand.isHighlighted(parameter);
    }

    async execute(parameter: any): Promise<TCommandResult> {
        return this.internalCommand.execute(parameter);
    }

    constructor(
        command: UIResultCommandInterface<TCommandResult>,
        postExecute: (x: TCommandResult) => TCommandResult
    ) {
        this.internalCommand = command;
        this.isVisible$ = command.isVisible$;
        this.canExecute$ = command.canExecute$;
        this.loading$ = command.loading$;
        this.postExecute = postExecute;
        this.name = command.name;
        this.isCancel = command.isCancel;
        this.uid = UUIDHelper.generateUUID();
        this.internalCommand.executed.subscribe(async (s: TCommandResult) => {
            if (s instanceof Promise) {
                postExecute(await s);
            } else {
                postExecute(s);
            }
        });
    }
}

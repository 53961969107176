import { BaseEnumTextBoxComponent } from './base-enum-text-box/base-enum-text-box.component';
import { NgModule } from "@angular/core";
import { BaseCheckboxTextBoxComponent } from "./base-checkbox-text-box/base-checkbox-text-box.component";
import { BaseDateTextBoxComponent } from "./base-date-text-box/base-date-text-box.component";
import { BaseNumericBoxComponent } from "./base-numeric-box/base-numeric-box.component";
import { BaseRadioButtonTextBoxComponent } from "./base-radio-button-text-box/base-radio-button-text-box.component";
import { BaseTextAreaBoxComponent } from "./base-text-area-box/base-text-area-box.component";
import { BaseTimeSpanTextBoxComponent } from './base-time-span-text-box/base-time-span-text-box.component';
import { BaseDateMaskTextBoxComponent } from './base-date-mask-text-box/base-date-mask-text-box.component';
import { BaseSecurityTextBoxComponent } from './base-security-text-box/base-security-text-box.component';
import { BaseToggleTextBoxComponent } from './base-toggle-text-box/base-toggle-text-box.component';
import { BaseTextBoxComponent } from './base-text-box/base-text-box.component';
import { LabelBoxComponent } from './label-box/label-box.component';
import { BaseChipsBoxComponent } from './base-chips-box/base-chips-box.component';
import { BaseExternalBoxComponent } from './base-external-box/base-external-box.component';

@NgModule({
    imports: [
        BaseCheckboxTextBoxComponent,
        BaseChipsBoxComponent,
        BaseDateMaskTextBoxComponent,
        BaseDateTextBoxComponent,
        BaseEnumTextBoxComponent,
        BaseNumericBoxComponent,
        BaseRadioButtonTextBoxComponent,
        BaseSecurityTextBoxComponent,
        BaseTextAreaBoxComponent,
        BaseTextBoxComponent,
        BaseTimeSpanTextBoxComponent,
        BaseToggleTextBoxComponent,
        BaseExternalBoxComponent,
        LabelBoxComponent
    ],
    exports: [
        BaseCheckboxTextBoxComponent,
        BaseChipsBoxComponent,
        BaseDateMaskTextBoxComponent,
        BaseDateTextBoxComponent,
        BaseEnumTextBoxComponent,
        BaseNumericBoxComponent,
        BaseRadioButtonTextBoxComponent,
        BaseSecurityTextBoxComponent,
        BaseTextAreaBoxComponent,
        BaseTextBoxComponent,
        BaseTimeSpanTextBoxComponent,
        BaseToggleTextBoxComponent,
        BaseExternalBoxComponent,
        LabelBoxComponent
    ]
})
export class BaseControlsModule {

}

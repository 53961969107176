import { NgModule } from "@angular/core";
import { FilledButtonComponent } from "./filled-button/filled-button.component";
import { RibbonButtonComponent } from "./ribbon-button/ribbon-button.component";
import { TextButtonComponent } from "./text-button/text-button.component";

@NgModule({
    imports: [
        FilledButtonComponent,
        TextButtonComponent,
        RibbonButtonComponent
    ],
    declarations: [
       
    ],
    exports: [
        FilledButtonComponent,
        TextButtonComponent,
        RibbonButtonComponent
    ]
})
export class ButtonsModule {

}
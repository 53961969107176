import { ZoomFilterViewModel } from './zoom-filter-view-model';
import { ZoomMetaData } from '../../../meta-data/zoom-meta-data';
import { ModifiedSubscriberInterface } from '../../modified-subscriber.interface';
import { ZoomOperatorViewModelProperty } from '../zoom-operator-view-model-property';
import { BoolZoomPropertyViewModel } from '../property-view-model/bool-zoom-property-view-model';
import { FilterOperators } from '../../../domain-models/find-options/filter';

export class BoolZoomFilterViewModel extends ZoomFilterViewModel {
  constructor(metadata: ZoomMetaData, modifiedSubscriber: ModifiedSubscriberInterface) {
    super(metadata);
    this.operator = new ZoomOperatorViewModelProperty(
      metadata.propertyMetadata.isRequired ? ZoomOperatorViewModelProperty.operatorsForBool : ZoomOperatorViewModelProperty.operatorsForNBool,
      modifiedSubscriber);
    this.filterValue = new BoolZoomPropertyViewModel(metadata.booleanMetaData, modifiedSubscriber);
    this.init();
  }

  override setDefault() {
    super.setDefault();
    if (this.propertyNameMap.propertyPath === 'IsActive') {
      this.operator.currentValue = FilterOperators.Equals;
      this.filterValue.setValue(true);
    }
  }
}

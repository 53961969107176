import { Inject, Injectable, Optional } from '@angular/core';
import { LocalstorageHelper, LogService, UUIDHelper } from '@nts/std/src/lib/utility';
import { fromWorker } from 'observable-webworker';
import { filter, from, map, Observable, of, share, Subject, switchMap, take } from 'rxjs';
import { JsonNetDecycle } from '../serialization/json-net-decycle';
import { ResponseCacheWebWorkerParamsInterface, ResponseCacheWebWorkerResult, ResponseCacheWebWorkerType, ResponseCacheWebWorkerSettings } from '@nts/std/src/lib/worker';
import { ResponseCachedDecoratorInterface, ResponseCachedInspector } from './decorators/response-cached.decorator';
import { RESPONSE_CACHE_WEBWORKER_FACTORY } from '../token/response-cache.token';
import { RESPONSE_CACHE_SETTINGS_WEBWORKER_FACTORY } from '../token/response-cache-settings.token';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';

export const WITHOUT_EXPIRATION_TIME = 0;

@Injectable()
export class ResponseCacheService {

    private _cache = new Map<string, any>();
    private WITHOUT_BODY_KEY = 'without-body'
    private CACHE_KEY = 'offlineCache';
    private inputWorker$: Subject<ResponseCacheWebWorkerParamsInterface> = new Subject();
    private outputWorker$: Observable<ResponseCacheWebWorkerResult<any>>;

    constructor(
        private env: EnvironmentConfiguration,
        @Optional()
        @Inject(RESPONSE_CACHE_WEBWORKER_FACTORY) private readonly cacheWebworkerFactory: () => Worker,
        @Optional()
        @Inject(RESPONSE_CACHE_SETTINGS_WEBWORKER_FACTORY) private readonly responseCacheWebworkerSettings: ResponseCacheWebWorkerSettings
    ) {
        if (cacheWebworkerFactory && responseCacheWebworkerSettings?.isEnabled === true) {
            this.outputWorker$ = fromWorker<ResponseCacheWebWorkerParamsInterface, ResponseCacheWebWorkerResult<boolean>>(this.cacheWebworkerFactory, this.inputWorker$)
        }
     }

    /**
     * Verifica in base al tipo di risposta, se è necessario utilizzare una risposta cacheta
     * Ritorna false quando la risposta non è cachata o non è presente nella cache
     * Ritorn true solo quando la risposta è chachabile ed è presente nella cache
     *
     * @param responseType  Tipo della risposta 
     * @param requestUri    Uri della richiesta
     * @param cycledRequestBody body della request
     */
    check(
        responseType: any, 
        requestUri: string, 
        cycledRequestBody?: string, 
        isOnline: boolean = true, 
        expirationTime: number = undefined,
        onlyOffline: boolean = false,
        forceUseCache: boolean = false,
        tenantBarrier: boolean = true,
        enterpriseBarrier: boolean = undefined,
        userBarrier: boolean = false,
        overrideBarrierValues: {
            tenantBarrierValue?: number,
            enterpriseBarrierValue?: number,
            userBarrierValue?: number,
        } = {
            tenantBarrierValue: undefined,
            enterpriseBarrierValue: undefined,
            userBarrierValue: undefined
        }
    ): Observable<boolean> {

        // Se isEnterpriseBarrierRequired è false forzo enterpriseBarrier a false
        if (this.env.isEnterpriseBarrierRequired === false) {
            enterpriseBarrier = false
        } else {
            // Se enterpriseBarrier non viene passato utilizzo il valore dall'env
            enterpriseBarrier = enterpriseBarrier ?? this.env.isEnterpriseBarrierRequired;
        }

        if (ResponseCachedInspector.isApplied(responseType) || forceUseCache) {
            if (
                typeof Worker !== 'undefined' && 
                this.cacheWebworkerFactory && 
                this.responseCacheWebworkerSettings?.isEnabled === true
            ) {
                if (this.responseCacheWebworkerSettings.showLogs) {
                    LogService.log(`check cache from worker for ${requestUri}`)
                }
                
                return from(this.getCacheKey()).pipe(switchMap((cacheKey: string) => 
                    from(LocalstorageHelper.getStorageItem(
                        cacheKey,
                        undefined,
                        tenantBarrier,
                        enterpriseBarrier,
                        userBarrier,
                        overrideBarrierValues
                    )).pipe(switchMap((cache: Map<string, Object>) => {
                        const input: ResponseCacheWebWorkerParamsInterface ={
                            requestUri, 
                            showLogs: this.responseCacheWebworkerSettings.showLogs,
                            cycledRequestBody, 
                            isOnline, 
                            expirationTime,
                            onlyOffline,
                            forceUseCache,
                            serializedCache: JsonNetDecycle.decycle(cache) as any,
                            configuration: ResponseCachedInspector.getValue(responseType),
                            serializedMemoryCache: JSON.stringify(this._cache),
                            workType: ResponseCacheWebWorkerType.Check,
                            workerId: UUIDHelper.generateUUID()
                        };
                        setTimeout(() => this.inputWorker$.next(input));
                        return this.outputWorker$.pipe(
                            share(),
                            filter((o) => o.workerId === input.workerId), take(1), map((r) => {
                                if (this.responseCacheWebworkerSettings.showLogs) {
                                    LogService.log(`check cache from worker for ${requestUri} result: ${r.result}`);
                                }
                                return r.result;
                        }));
                    }))  
                ))                              
            }

            if(isOnline) {

                // LogService.log(`check cache for ${requestUri}`)
                let configuration: ResponseCachedDecoratorInterface | boolean = ResponseCachedInspector.getValue(responseType);
                
                // Se forzo l'utilizzo della cache e non passo expirationTime, forzo il passaggio per la memory cache
                if (forceUseCache && expirationTime == null) {
                    configuration = true;
                }

                if (configuration !== true) {

                    if (configuration == null) {
                        configuration = {};
                    }

                    if (onlyOffline) {
                        (configuration as ResponseCachedDecoratorInterface).onlyOffline = onlyOffline;
                    }

                    // OnlyOffline
                    if((configuration as ResponseCachedDecoratorInterface)?.onlyOffline === true && forceUseCache === false) {
                        return of(false);
                    }

                    // Se è stato passato un expiration time faccio l'override
                    if (expirationTime >= 0) {
                        (configuration as ResponseCachedDecoratorInterface).expirationTime = expirationTime;
                    }

                    // expirationTime
                    if((configuration as ResponseCachedDecoratorInterface).expirationTime > -1) { 
                        
                        return from(this.getTimestampFromOffLineCacheForSpecificRequest(
                            requestUri, 
                            cycledRequestBody,
                            tenantBarrier,
                            enterpriseBarrier,
                            userBarrier,
                            overrideBarrierValues
                        )).pipe(map((timestampInCache: number) => {

                            // se il timestamp in cache è nullo non esiste la voce in cache
                            if (timestampInCache == null) {
                                return false;
                            }

                            if ((configuration as ResponseCachedDecoratorInterface).expirationTime === WITHOUT_EXPIRATION_TIME) { // expirationTime 0 = non ha scadenza
                                return true;
                            }

                            if ((this.getTimestampInSeconds() - timestampInCache) > (configuration as ResponseCachedDecoratorInterface).expirationTime) {
                                return false;
                            }

                            return true;
                        }))
                    }

                } else {
                    // Comportamento standard
                    // Verifica nella cache temporanea del browser

                    // Se è stato passato un expiration
                    if (expirationTime >= 0) {
                        
                        return from(this.getTimestampFromOffLineCacheForSpecificRequest(
                            requestUri, 
                            cycledRequestBody,
                            tenantBarrier,
                            enterpriseBarrier,
                            userBarrier,
                            overrideBarrierValues
                        )).pipe(map((timestampInCache: number) => {
                            
                            // se il timestamp in cache è nullo non esiste la voce in cache
                            if (timestampInCache == null) {
                                return false;
                            }

                            if (expirationTime === WITHOUT_EXPIRATION_TIME) { // expirationTime 0 = non ha scadenza
                                return true;
                            }

                            if ((this.getTimestampInSeconds() - timestampInCache) > expirationTime) {
                                return false;
                            }

                            return true;
                        }))                        
                    }

                    if(this._cache.has(requestUri)) {
                        const currentData: any = this._cache.get(requestUri);
                        if (cycledRequestBody) {
                            return of(currentData[cycledRequestBody] !== undefined);
                        } else {
                            return of(currentData[this.WITHOUT_BODY_KEY] !== undefined);
                        }
                    }
                }
            } else {
                return from(this.hasResponseInOffLineCache(
                    requestUri, 
                    cycledRequestBody,
                    tenantBarrier,
                    enterpriseBarrier,
                    userBarrier,
                    overrideBarrierValues
                ))
            }
        }

        return of(false); 
    }

    /**
     * Restituisce la risposta cacheta solo la risposta è cachabile ed è presente sulla cache
     * In tutti gli altri casi restituisce null
     *
     * @param responseType  Tipo della risposta
     * @param requestUri    Uri della richiesta
     * @param cycledRequestBody body della request
     */
    get(
        responseType: any, 
        requestUri: string, 
        cycledRequestBody?: string, 
        isOnline: boolean = true, 
        expirationTime: number = undefined, // se paso expiration time = 0, la cache non ha scadenza
        onlyOffline: boolean = false,
        forceUseCache: boolean = false,
        tenantBarrier: boolean = true,
        enterpriseBarrier: boolean = undefined,
        userBarrier: boolean = false,
        overrideBarrierValues: {
            tenantBarrierValue?: number,
            enterpriseBarrierValue?: number,
            userBarrierValue?: number,
        } = {
            tenantBarrierValue: undefined,
            enterpriseBarrierValue: undefined,
            userBarrierValue: undefined
        }
    ): Observable<any> {
       
        // Se isEnterpriseBarrierRequired è false forzo enterpriseBarrier a false
        if (this.env.isEnterpriseBarrierRequired === false) {
            enterpriseBarrier = false
        } else {
            // Se enterpriseBarrier non viene passato utilizzo il valore dall'env
            enterpriseBarrier = enterpriseBarrier ?? this.env.isEnterpriseBarrierRequired;
        }        

        let result: any = null;
        if (ResponseCachedInspector.isApplied(responseType) || forceUseCache) {

            if (
                typeof Worker !== 'undefined' && 
                this.cacheWebworkerFactory &&
                this.responseCacheWebworkerSettings?.isEnabled === true
            ) {
                if (this.responseCacheWebworkerSettings.showLogs) {
                    LogService.log(`get cache from worker for ${requestUri}`)
                }                
                return from(this.getCacheKey()).pipe(switchMap((cacheKey: string) => 
                    from(LocalstorageHelper.getStorageItem(
                        cacheKey,
                        undefined,
                        tenantBarrier,
                        enterpriseBarrier,
                        userBarrier,
                        overrideBarrierValues
                    )).pipe(switchMap((cache: Map<string, Object>) => {
                        const input: ResponseCacheWebWorkerParamsInterface ={
                            requestUri,
                            showLogs: this.responseCacheWebworkerSettings.showLogs,
                            cycledRequestBody, 
                            isOnline,
                            expirationTime,
                            onlyOffline,
                            forceUseCache,
                            serializedCache: JsonNetDecycle.decycle(cache) as any,
                            configuration: ResponseCachedInspector.getValue(responseType),
                            serializedMemoryCache: JSON.stringify(this._cache),
                            workType: ResponseCacheWebWorkerType.Get,
                            workerId: UUIDHelper.generateUUID()
                        };
                        setTimeout(() => this.inputWorker$.next(input));
                        return this.outputWorker$.pipe(
                            share(),
                            filter((o) => o.workerId === input.workerId), take(1), map((r) => {
                                if (this.responseCacheWebworkerSettings.showLogs) {
                                    LogService.log(`get cache from worker for ${requestUri} result: ${r.result}`);
                                }                    
                                return r.result;
                        }));
                    }))
                ))                                

            } else {
                if (!isOnline) { 
                    return from(this.getResponseFromOffLineCache(
                        requestUri, 
                        cycledRequestBody, 
                        tenantBarrier, 
                        enterpriseBarrier,
                        userBarrier, 
                        overrideBarrierValues
                    ));
                }
    
                let configuration: ResponseCachedDecoratorInterface | boolean = ResponseCachedInspector.getValue(responseType);
    
                // Se forzo l'utilizzo della cache e non passo expirationTime, forzo il passaggio per la memory cache
                if (forceUseCache && expirationTime == null) {
                    configuration = true;
                }

                if (configuration !== true) {
    
                    if (configuration == null) {
                        configuration = {};
                    }
    
                    // OnlyOffline, utile per verificare problemi di flusso nella cache (abilitare solo in debug)
                    if((configuration as ResponseCachedDecoratorInterface).onlyOffline === true && forceUseCache === false) {
                        throw new Error(`Errore! La response ${responseType} non deve usare la cache, modalità onlyOffline attiva!`);
                    }
    
                    // Se è stato passato un expiration time faccio l'override
                    if (expirationTime >= 0) {
                        (configuration as ResponseCachedDecoratorInterface).expirationTime = expirationTime;
                    }
    
                    // expirationTime
                    if((configuration as ResponseCachedDecoratorInterface).expirationTime >= 0) { 
                                
                        return from(this.getTimestampFromOffLineCacheForSpecificRequest(
                            requestUri, 
                            cycledRequestBody,
                            tenantBarrier,
                            enterpriseBarrier,
                            userBarrier,
                            overrideBarrierValues
                        )).pipe(switchMap((timestampInCache: number) => {
                            // se il timestamp in cache è nullo non esiste la voce in cache
                            if (timestampInCache == null) {
                                throw new Error(`Errore! La response ${responseType} non ha una cache!`);
                            }

                            if (
                                (configuration as ResponseCachedDecoratorInterface).expirationTime > 0 &&
                                (this.getTimestampInSeconds() - timestampInCache) > (configuration as ResponseCachedDecoratorInterface).expirationTime) {
                                throw new Error(`Errore! La response ${responseType} ha la cache scaduta!`);
                            }

                            return from(this.getResponseFromOffLineCache(
                                requestUri, 
                                cycledRequestBody,
                                tenantBarrier,
                                enterpriseBarrier,
                                userBarrier,
                                overrideBarrierValues
                            ));
                        }))                        
                    }
    
                } else {
                    // Comportamento standard
                    // Verifica nella cache temporanea del browser se è online
    
                    // Se è stato passato un expiration time faccio l'override
                    if (expirationTime >= 0) {
    
                        return from(this.getTimestampFromOffLineCacheForSpecificRequest(
                            requestUri, 
                            cycledRequestBody,
                            tenantBarrier,
                            enterpriseBarrier,
                            userBarrier,
                            overrideBarrierValues
                        )).pipe(switchMap((timestampInCache: number) => {    // se il timestamp in cache è nullo non esiste la voce in cache
                            if (timestampInCache == null) {
                                throw new Error(`Errore! La response ${responseType} non ha una cache!`);
                            }

                            if (
                                expirationTime > 0 &&                                
                                (this.getTimestampInSeconds() - timestampInCache) > expirationTime
                            ) {
                                throw new Error(`Errore! La response ${responseType} ha la cache scaduta!`);
                            }

                            return from(this.getResponseFromOffLineCache(
                                requestUri, 
                                cycledRequestBody,
                                tenantBarrier,
                                enterpriseBarrier,
                                userBarrier,
                                overrideBarrierValues
                            ));
                        }))
                        
                    } else {
                        if (isOnline) {
                            result = this._cache.get(requestUri);
                            if (cycledRequestBody) {
                                return of(result[cycledRequestBody]);
                            } else {
                                return of(result[this.WITHOUT_BODY_KEY]);
                            } 
                        } else { 
                            return from(this.getResponseFromOffLineCache(
                                requestUri, 
                                cycledRequestBody,
                                tenantBarrier,
                                enterpriseBarrier,
                                userBarrier,
                                overrideBarrierValues
                            ));
                        } 
                    }
                                     
                }  
            }                      
        }
        return of(result);
    }

    /**
     * Salva la risposta nella cache
     *
     * @param responseType Tipo della risposta
     * @param requestUri Uri della richiesta
     * @param response Risposta da cachare
     * @param cycledRequestBody body della request
     */
    set(
        responseType: any, 
        requestUri: string, 
        response: any, 
        cycledRequestBody?: string,
        forceUseCache: boolean = false,
        tenantBarrier: boolean = true, 
        enterpriseBarrier: boolean = undefined,
        userBarrier: boolean = false,
        overrideBarrierValues: {
            tenantBarrierValue?: number,
            enterpriseBarrierValue?: number,
            userBarrierValue?: number,
        } = {
            tenantBarrierValue: undefined,
            enterpriseBarrierValue: undefined,
            userBarrierValue: undefined
        }
    ): Observable<boolean> {

        // Se isEnterpriseBarrierRequired è false forzo enterpriseBarrier a false
        if (this.env.isEnterpriseBarrierRequired === false) {
            enterpriseBarrier = false
        } else {
            // Se enterpriseBarrier non viene passato utilizzo il valore dall'env
            enterpriseBarrier = enterpriseBarrier ?? this.env.isEnterpriseBarrierRequired;
        }

        if (ResponseCachedInspector.isApplied(responseType) || forceUseCache) {
            
            if (
                typeof Worker !== 'undefined' && 
                this.cacheWebworkerFactory && 
                this.responseCacheWebworkerSettings?.isEnabled === true
            ) {

                if (this.responseCacheWebworkerSettings.showLogs) {
                    LogService.log(`set cache from worker for ${requestUri}`)
                }                
                return from(
                    this.getCacheKey()
                ).pipe(switchMap((cacheKey: string) => 
                    from(LocalstorageHelper.getStorageItem(
                        cacheKey,
                        undefined,
                        tenantBarrier,
                        enterpriseBarrier,
                        userBarrier,
                        overrideBarrierValues
                    )).pipe(switchMap((cache: Map<string, Object>) => {
                        const input: ResponseCacheWebWorkerParamsInterface = {
                            requestUri,
                            showLogs: this.responseCacheWebworkerSettings.showLogs,
                            cycledRequestBody, 
                            isOnline: undefined, 
                            expirationTime: undefined,
                            onlyOffline: undefined,
                            forceUseCache,
                            serializedCache: JsonNetDecycle.decycle(cache) as any,
                            configuration: ResponseCachedInspector.getValue(responseType),
                            serializedMemoryCache: JSON.stringify(this._cache),
                            response,
                            workType: ResponseCacheWebWorkerType.Set,
                            workerId: UUIDHelper.generateUUID()
                        };
                        setTimeout(() => this.inputWorker$.next(input));
                        return this.outputWorker$.pipe(
                            share(),
                            filter((o) => o.workerId === input.workerId), 
                            take(1), 
                            switchMap((r) => {
                                if (this.responseCacheWebworkerSettings.showLogs) {
                                    LogService.log(`set cache from worker for ${requestUri}: storing`)
                                }
                                let setResponse = r as ResponseCacheWebWorkerResult<{
                                    serializedLocalStorageToWrite: string,
                                    serializedMemoryCacheToWrite: string
                                }>
                                return from(this.getCacheKey()).pipe(switchMap((cacheKey: string) => {
                                    this._cache.set(requestUri, JSON.parse(setResponse.result.serializedMemoryCacheToWrite));
                                    return from(LocalstorageHelper.setStorageItem(
                                        cacheKey, 
                                        JSON.parse(setResponse.result.serializedLocalStorageToWrite),
                                        undefined,
                                        tenantBarrier,
                                        enterpriseBarrier,
                                        userBarrier,
                                        overrideBarrierValues
                                    )).pipe(map((result) => {
                                        return result;
                                    }));
                                }))
                                
                            }));
                    }))
                ));                

            } else {

                let currentData: any = this._cache.get(requestUri);
                return from(this.getOffLineCacheForSpecificRequest(
                    requestUri, 
                    tenantBarrier, 
                    enterpriseBarrier, 
                    userBarrier,
                    overrideBarrierValues
                )).pipe(switchMap((currentDataOffline) => {

                    if (!currentData) {
                        currentData = {};
                    }
        
                    if (!currentDataOffline) {
                        currentDataOffline = {}
                    }
        
                    if (cycledRequestBody) {
                        currentData[cycledRequestBody] = response;
                        currentDataOffline[cycledRequestBody] = {response, timestamp: this.getTimestampInSeconds()};
                    } else {
                        currentData[this.WITHOUT_BODY_KEY] = response;
                        currentDataOffline[this.WITHOUT_BODY_KEY] = {response, timestamp: this.getTimestampInSeconds()};;
                    }            
        
                    this._cache.set(requestUri, currentData);
                    
                    return from(this.updateOffLineCacheForSpecificRequest(
                        requestUri,
                        currentDataOffline,
                        tenantBarrier,
                        enterpriseBarrier,
                        userBarrier,
                        overrideBarrierValues
                    )).pipe(map(() => true))
                }))
            }            
        }
        return of(false);
    }

    async getResponseFromOffLineCache(
        requestUri: string, 
        cycledRequestBody?: string,
        tenantBarrier: boolean = true, 
        enterpriseBarrier: boolean = false, 
        userBarrier: boolean = false,
        overrideBarrierValues: {
            tenantBarrierValue?: number,
            enterpriseBarrierValue?: number,
            userBarrierValue?: number,
        } = {
            tenantBarrierValue: undefined,
            enterpriseBarrierValue: undefined,
            userBarrierValue: undefined
        }
    ): Promise<null | any> {

        // Se isEnterpriseBarrierRequired è false forzo enterpriseBarrier a false
        if (this.env.isEnterpriseBarrierRequired === false) {
            enterpriseBarrier = false
        } else {
            // Se enterpriseBarrier non viene passato utilizzo il valore dall'env
            enterpriseBarrier = enterpriseBarrier ?? this.env.isEnterpriseBarrierRequired;
        }

        const offlineCache: Map<string, string> = await this.getOffLineCacheForSpecificRequest(
            requestUri, 
            tenantBarrier,
            enterpriseBarrier, 
            userBarrier, 
            overrideBarrierValues
        );
        if (!offlineCache) {
            return null;
        }
        if (cycledRequestBody) {
            if (offlineCache[cycledRequestBody] !== undefined) {
                const timstampedResponse: {response: any, timestamp: number} = offlineCache[cycledRequestBody];
                return timstampedResponse.response;
            };
        } else {
            if (offlineCache[this.WITHOUT_BODY_KEY] !== undefined) {
                const timstampedResponse: {response: any, timestamp: number} = offlineCache[this.WITHOUT_BODY_KEY];
                return timstampedResponse.response;
            };
        }
        return null;
    }

    async getCacheKey(): Promise<string> {
        return this.CACHE_KEY;
    }

    async getOffLineCache(
        tenantBarrier: boolean, 
        enterpriseBarrier: boolean, 
        userBarrier: boolean,
        overrideBarrierValues: {
            tenantBarrierValue?: number,
            enterpriseBarrierValue?: number,
            userBarrierValue?: number,
        } = {
            tenantBarrierValue: undefined,
            enterpriseBarrierValue: undefined,
            userBarrierValue: undefined
        }
    ): Promise<Map<string, Object>> {

        // Se isEnterpriseBarrierRequired è false forzo enterpriseBarrier a false
        if (this.env.isEnterpriseBarrierRequired === false) {
            enterpriseBarrier = false
        } else {
            // Se enterpriseBarrier non viene passato utilizzo il valore dall'env
            enterpriseBarrier = enterpriseBarrier ?? this.env.isEnterpriseBarrierRequired;
        }
        
        let offlineCache: Map<string, Object> = await LocalstorageHelper.getStorageItem(
            await this.getCacheKey(),
            undefined, 
            tenantBarrier,
            enterpriseBarrier,
            userBarrier,
            overrideBarrierValues
        ) as Map<string, Object>;
        if (!offlineCache || !(offlineCache instanceof Map)) {
            offlineCache = new Map<string, Object>();
        }
        return offlineCache;
    }

    async getOffLineCacheForSpecificRequest(
        requestUri: string,
        tenantBarrier: boolean, 
        enterpriseBarrier: boolean, 
        userBarrier: boolean,
        overrideBarrierValues: {
            tenantBarrierValue?: number,
            enterpriseBarrierValue?: number,
            userBarrierValue?: number,
        } = {
            tenantBarrierValue: undefined,
            enterpriseBarrierValue: undefined,
            userBarrierValue: undefined
        }
    ): Promise<null | any> {

        // Se isEnterpriseBarrierRequired è false forzo enterpriseBarrier a false
        if (this.env.isEnterpriseBarrierRequired === false) {
            enterpriseBarrier = false
        } else {
            // Se enterpriseBarrier non viene passato utilizzo il valore dall'env
            enterpriseBarrier = enterpriseBarrier ?? this.env.isEnterpriseBarrierRequired;
        }

        const offlineCache: Map<string, Object> = await this.getOffLineCache(
            tenantBarrier,
            enterpriseBarrier,
            userBarrier,
            overrideBarrierValues
        );
        if (offlineCache.has(requestUri)) {
            try {
                return JsonNetDecycle.retrocycle(offlineCache.get(requestUri));
            } catch(err) {
                return null;
            }            
        }
        return null;
    }

    async getTimestampFromOffLineCacheForSpecificRequest(
        requestUri: string, 
        cycledRequestBody?: string,
        tenantBarrier = true,
        enterpriseBarrier = false,
        userBarrier = false,
        overrideBarrierValues: {
            tenantBarrierValue?: number,
            enterpriseBarrierValue?: number,
            userBarrierValue?: number,
        } = {
            tenantBarrierValue: undefined,
            enterpriseBarrierValue: undefined,
            userBarrierValue: undefined
        }
    ): Promise<number | null> {

        // Se isEnterpriseBarrierRequired è false forzo enterpriseBarrier a false
        if (this.env.isEnterpriseBarrierRequired === false) {
            enterpriseBarrier = false
        } else {
            // Se enterpriseBarrier non viene passato utilizzo il valore dall'env
            enterpriseBarrier = enterpriseBarrier ?? this.env.isEnterpriseBarrierRequired;
        }

        const offlineCache: null | any = await this.getOffLineCacheForSpecificRequest(
            requestUri,
            tenantBarrier,
            enterpriseBarrier,
            userBarrier,
            overrideBarrierValues
        );
        if (!offlineCache) {
            return null;
        }

        if (cycledRequestBody) {
            if (offlineCache[cycledRequestBody] !== undefined) {
                const timstampedResponse: {response: any, timestamp: number} = offlineCache[cycledRequestBody];
                return timstampedResponse.timestamp;
            };
        } else {
            if (offlineCache[this.WITHOUT_BODY_KEY] !== undefined) {
                const timstampedResponse: {response: any, timestamp: number} = offlineCache[this.WITHOUT_BODY_KEY];
                return timstampedResponse.timestamp;
            };
        }
        return null;
    }

    getTimestampInSeconds(): number {
        return Math.floor(Date.now() / 1000)
    }

    async updateOffLineCacheForSpecificRequest(
        requestUri: string, 
        currentData: any,
        tenantBarrier: boolean, 
        enterpriseBarrier: boolean, 
        userBarrier: boolean,
        overrideBarrierValues: {
            tenantBarrierValue?: number,
            enterpriseBarrierValue?: number,
            userBarrierValue?: number,
        } = {
            tenantBarrierValue: undefined,
            enterpriseBarrierValue: undefined,
            userBarrierValue: undefined
        }
    ): Promise<boolean> {

        // Se isEnterpriseBarrierRequired è false forzo enterpriseBarrier a false
        if (this.env.isEnterpriseBarrierRequired === false) {
            enterpriseBarrier = false
        } else {
            // Se enterpriseBarrier non viene passato utilizzo il valore dall'env
            enterpriseBarrier = enterpriseBarrier ?? this.env.isEnterpriseBarrierRequired;
        }

        const offlineCache: Map<string, Object> = await this.getOffLineCache(
            tenantBarrier, 
            enterpriseBarrier, 
            userBarrier,
            overrideBarrierValues
        );
        offlineCache.set(
            requestUri, JsonNetDecycle.decycle(currentData)
        );
        return await this.setOffLineCache(
            offlineCache,
            tenantBarrier,
            enterpriseBarrier,
            userBarrier,
            overrideBarrierValues
        );
    }

    async setOffLineCache(
        offlineCache: Map<string, Object>,
        tenantBarrier: boolean, 
        enterpriseBarrier: boolean, 
        userBarrier: boolean,
        overrideBarrierValues: {
            tenantBarrierValue?: number,
            enterpriseBarrierValue?: number,
            userBarrierValue?: number,
        } = {
            tenantBarrierValue: undefined,
            enterpriseBarrierValue: undefined,
            userBarrierValue: undefined
        }
    ): Promise<boolean> {

        // Se isEnterpriseBarrierRequired è false forzo enterpriseBarrier a false
        if (this.env.isEnterpriseBarrierRequired === false) {
            enterpriseBarrier = false
        } else {
            // Se enterpriseBarrier non viene passato utilizzo il valore dall'env
            enterpriseBarrier = enterpriseBarrier ?? this.env.isEnterpriseBarrierRequired;
        }

        return await LocalstorageHelper.setStorageItem(
            await this.getCacheKey(),
            offlineCache,
            undefined,
            tenantBarrier,
            enterpriseBarrier,
            userBarrier,
            overrideBarrierValues
        );
    }

    async hasResponseInOffLineCache(
        requestUri: string, 
        cycledRequestBody: string = null,
        tenantBarrier: boolean = true,
        enterpriseBarrier: boolean = false,
        userBarrier: boolean = false,
        overrideBarrierValues: {
            tenantBarrierValue?: number,
            enterpriseBarrierValue?: number,
            userBarrierValue?: number,
        } = {
            tenantBarrierValue: undefined,
            enterpriseBarrierValue: undefined,
            userBarrierValue: undefined
        }
    ): Promise<boolean> {

        // Se isEnterpriseBarrierRequired è false forzo enterpriseBarrier a false
        if (this.env.isEnterpriseBarrierRequired === false) {
            enterpriseBarrier = false
        } else {
            // Se enterpriseBarrier non viene passato utilizzo il valore dall'env
            enterpriseBarrier = enterpriseBarrier ?? this.env.isEnterpriseBarrierRequired;
        }

        const offlineCache: null | any = await this.getOffLineCacheForSpecificRequest(
            requestUri,
            tenantBarrier,
            enterpriseBarrier,
            userBarrier,
            overrideBarrierValues
        );

        if (!offlineCache) {
            return false;
        }

        if (cycledRequestBody) {
            return offlineCache[cycledRequestBody] !== undefined && offlineCache[cycledRequestBody].response != null;
        } else {
            return offlineCache[this.WITHOUT_BODY_KEY] !== undefined  && offlineCache[this.WITHOUT_BODY_KEY].response != null;;
        }
    }    
}

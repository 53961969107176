<div (keydown.space)="keydownSpace($event)" 
    (click)="click($event)"
    #popperError="popper"
    [popper]="tooltipErrTemplate"
    [popperDisabled]="!showErrorTooltip || !errorList || errorList?.length === 0"
    [popperTrigger]="ngxPopperjsTriggers.hover"
    popperApplyClass="error"
    [popperPlacement]="ngxPopperjsPlacements.TOP"
    [popperPreventOverflow]="false"
    [popperHideOnScroll]="true"
    popperAppendTo="body"
    [class.disabled]="isDisabled"
    [class.has-error]="showErrorBorder && errorList?.length > 0"
    class="toggle-wrapper">

    <label 
        class="toggle"
        role="checkbox"
        [popper]="label"
        #popperInfo="popper"
        [popperDisabled]="!showTitleTooltip || !label || label?.length === 0 || errorList?.length > 0"
        [popperTrigger]="ngxPopperjsTriggers.hover"
        [popperDelay]="1000"
        popperApplyClass="info"
        [popperPlacement]="ngxPopperjsPlacements.TOP"
        [popperPreventOverflow]="false"
        [popperHideOnScroll]="true"
        popperAppendTo="body"
        [attr.aria-label]="label"
        [attr.aria-disabled]="isDisabled"
        [attr.tabindex]="tabIndex" [attr.title]="label" [attr.for]="id">
        <input #toggleBox type="checkbox" [attr.id]="id" [checked]="checkStatus" [disabled]="isDisabled"
            aria-hidden="true">
        <span class="slider round" role="checkbox"></span>
    </label>

    <label *ngIf="labelText && showLabelText" class="text-toggle">{{labelText}}</label>
</div>

<popper-content #tooltipErrTemplate>
    <ng-container *ngFor="let item of errorList">
        {{ item }}
    </ng-container>
</popper-content>

import { Provider, NgModule, ModuleWithProviders } from '@angular/core';
import { UpdateService } from './update.service';
import { OnlineService } from './offline.service';

@NgModule({
    declarations: [
    ],
    providers: [
    ],
    imports: [
    ],
    exports: [
    ]
})
export class UtilityModule {
    static forRoot(): ModuleWithProviders<UtilityModule> {
        return {
            ngModule: UtilityModule,
            providers: [
                UpdateService,
                OnlineService
            ]
        };
    }

    static forChild(): ModuleWithProviders<UtilityModule> {
        return {
            ngModule: UtilityModule,
            providers: []
        };
    }

    static getRootProviders(): Provider[] {
        return [
            UpdateService,
            OnlineService
        ];
    }
}

import { Injectable } from '@angular/core';
import 'reflect-metadata';
import * as uuid from 'uuid';

@Injectable()
export class UUIDHelper {

    constructor() {
    }

    // legge il device id se c'è da local storage altrimenti lo genera
    static generateUUID() {

        return uuid.v4();
    }
}

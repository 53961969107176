import { UserLoginIdentity } from './user-login.identity';
import { OCCModel } from '../occ-model';
import { IdentityTypeDecorator } from '../../decorators/identity-type.decorator';
import { ModelTypeNameDecorator } from '../decorators/model-type-name.decorator';
import { NumberDecorator } from '../decorators/number.decorator';
import { StringDecorator } from '../decorators/string.decorator';
import { Expose } from '../../serialization/class-transformer/decorators';

@IdentityTypeDecorator(UserLoginIdentity)
@ModelTypeNameDecorator('UserLogin')
export class UserLogin extends OCCModel<UserLoginIdentity> {

    private _id: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'id' })
    get id(): number {
        return this.getValueForIdentityValue<number>(() => this._id, 'id');
    }
    set id(value: number) {
        this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
    }

    private _email: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'email', maxLength: 255, isRequired: true, isEmail: true, isMainDescription: true })
    get email(): string {
        return this.getValueForIdentityValue<string>(() => this._email, 'email');
    }
    set email(value: string) {
        this.setValueForIdentityValue<string>(() => { this._email = value; }, value, 'email');
    }
}


import { Injectable } from '@angular/core';
import { ActionApiClient } from './action-api-client';
import { RootModelTypeNameDecorator } from './decorators/root-model-type-name.decorator';

@Injectable()
@RootModelTypeNameDecorator('FrameworkService')
export class FrameworkServiceApiClient extends ActionApiClient {


}

import { BaseCommandItem } from './base-command-item';
import { UICommandInterface } from './ui-command.interface';

export class CommandsGroup extends BaseCommandItem {
    commands: Array<UICommandInterface> = new Array<UICommandInterface>();

    constructor(name: string, displayName: string) {
        super(name, displayName);
    }

    addCommand(command: UICommandInterface) {
        this.commands.push(command);
    }
}

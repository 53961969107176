import { CoreOrchestratorViewModelInterface } from './core-orchestrator-view-model.interface';
import { AggregateMetaData } from '../meta-data';
import { ItemViewModelInterface } from './item-view-model.interface';
import { CoreModel } from '../domain-models/core-model';
import { BaseIdentity } from '../domain-models/base-identity';
import { InternalRootViewModel } from './internal-root-view-model';
import { CollectionViewModelInterface } from './collection-view-model.interface';
import { ClassType } from '../serialization/class-transformer/ClassTransformer';

export class MasterDetailRootViewModel<TModel extends CoreModel<TIdentity>, TIdentity extends BaseIdentity>
    extends InternalRootViewModel<TModel, TIdentity> implements ItemViewModelInterface {

    async initItemViewModel(
        domainModel: TModel,
        metadata: AggregateMetaData,
        orchestratorViewModel: CoreOrchestratorViewModelInterface,
        ownerCollection?: CollectionViewModelInterface<any>,
        isNewItem?: boolean,
        path?: string,
        isMockedViewModel = false,
        domainModelType: ClassType<TModel> = null
    ) {
        this.isMock = isMockedViewModel;
        
        await this.initAggregateElementViewModel(
            domainModel,
            metadata, 
            orchestratorViewModel, 
            undefined, 
            this.isMock,
            domainModelType
        );
    }
}

import { ZoomFilterViewModel } from './zoom-filter-view-model';
import { ZoomMetaData } from '../../../meta-data/zoom-meta-data';
import { ModifiedSubscriberInterface } from '../../modified-subscriber.interface';
import { ZoomOperatorViewModelProperty } from '../zoom-operator-view-model-property';
import { FilterVariablesType } from '../../../domain-models/find-options/filter-variables-type.enum';
import { StringZoomPropertyViewModel } from '../property-view-model/string-zoom-property-view-model';
import { ZoomPropertyViewModelInterface } from '../property-view-model/zoom-property-view-model.interface';
import { FilterOperators } from '../../../domain-models/find-options/filter';

export class StringZoomFilterViewModel extends ZoomFilterViewModel {

    constructor(metadata: ZoomMetaData, private modifiedSubscriber: ModifiedSubscriberInterface) {
        super(metadata);
        this.operator = new ZoomOperatorViewModelProperty(ZoomOperatorViewModelProperty.operatorsForString, modifiedSubscriber);
        this.filterValue = new StringZoomPropertyViewModel(metadata.stringMetaData, modifiedSubscriber);
        this.filterValue2 = new StringZoomPropertyViewModel(metadata.stringMetaData, modifiedSubscriber);
        this.filterVariablesType = FilterVariablesType.UserFilter;
        this.init();
    }

    protected override async onOperatorChanged(value: FilterOperators): Promise<void> {
        await super.onOperatorChanged(value);
        if (this.OperatorDoesNotRequireValue) {
            for (const filter of this.filterValues) {
                filter.setEnabled(false);
                await filter.resetValue();
            }
        } else {
            for (const filter of this.filterValues) {
                filter.setEnabled(true);
                await filter.resetValue();
            }
        }
    }

    getEmptyFilter(): StringZoomPropertyViewModel {
        return new StringZoomPropertyViewModel(this.metaData.stringMetaData, this.modifiedSubscriber);
    }

    setZoomPropertyViewModelValuesFromEntities(entities: Array<string>) {
        this.setZoomPropertyViewModelValues(entities.map((e:string) => {
            const vm: ZoomPropertyViewModelInterface<string> = this.getEmptyFilter();
            vm.setValue(e);
            return vm;
        }))
    }

    get filterValues(): Array<ZoomPropertyViewModelInterface<string>> {
        return this.internalZoomPropertyViewModelValues;
    }

    private internalZoomPropertyViewModelValues: Array<ZoomPropertyViewModelInterface<string>> = [];

    setZoomPropertyViewModelValues(pvmToSet: Array<ZoomPropertyViewModelInterface<string>>) {
        if (this.internalZoomPropertyViewModelValues != pvmToSet) {
            this.internalZoomPropertyViewModelValues = pvmToSet;
            this.modifiedSubscriber.notifyModified();
        }   
    }
}

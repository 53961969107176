import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AddZoomModalViewModel } from '../../../../../view-models/zoom/zoom-list-view-model/modals/add-zoom-modal-view-model';
import { ModalComponentInterface } from '../../../../modal/modal-component.interface';
import { LabelledTextBoxComponent } from '../../../../controls/labelled-text-box/labelled-text-box.component';

@Component({
    selector: 'nts-add-zoom-modal-container',
    templateUrl: 'add-zoom-modal-container.component.html',
    styleUrls: ['./add-zoom-modal-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        LabelledTextBoxComponent
    ]
})
export class AddZoomModalContainerComponent implements ModalComponentInterface {

    viewModel: AddZoomModalViewModel;

    public async initialize(): Promise<void> {
        await this.viewModel.initialize();
    }

    constructor(
        private cd: ChangeDetectorRef
    ) { }
}

import { EnvironmentConfiguration } from "./environment-configuration";

export class EnvironmentHelper {

    /**
     * Restituisce un url partendo dall'sourceUrl sostituendo se necessario il suo base address con quello del proxy server
     * 
     * @param sourceUrl 
     * @param currentEnvironment 
     * @param unproxiedEnvironment 
     * @returns 
     */
    public static getProxiedUrl(
        sourceUrl: string, 
        currentEnvironment: EnvironmentConfiguration,
        unproxiedEnvironment: EnvironmentConfiguration
    ) {

        if (currentEnvironment.envType.indexOf('PROXY') > -1 && unproxiedEnvironment?.baseAppUrl?.length > 0) {
      
            let unproxiedEnvironmentBaseAppUrl = unproxiedEnvironment?.baseAppUrl;
    
            // Fix slash and backslash at the end of the string
            unproxiedEnvironmentBaseAppUrl = unproxiedEnvironmentBaseAppUrl.endsWith('/') ? unproxiedEnvironmentBaseAppUrl.slice(0, -1) : unproxiedEnvironmentBaseAppUrl;
            unproxiedEnvironmentBaseAppUrl = unproxiedEnvironmentBaseAppUrl.endsWith('\\') ? unproxiedEnvironmentBaseAppUrl.slice(0, -1) : unproxiedEnvironmentBaseAppUrl;
            unproxiedEnvironmentBaseAppUrl = unproxiedEnvironmentBaseAppUrl + '/';
            
            let currentEnvironmentBaseAppUrl = currentEnvironment?.baseAppUrl;
    
            // Remove slash and backslash at the end of the string
            currentEnvironmentBaseAppUrl = currentEnvironmentBaseAppUrl.endsWith('/') ? currentEnvironmentBaseAppUrl.slice(0, -1) : currentEnvironmentBaseAppUrl;
            currentEnvironmentBaseAppUrl = currentEnvironmentBaseAppUrl.endsWith('\\') ? currentEnvironmentBaseAppUrl.slice(0, -1) : currentEnvironmentBaseAppUrl;
            currentEnvironmentBaseAppUrl = currentEnvironmentBaseAppUrl + '/';
    
            if (sourceUrl?.indexOf(unproxiedEnvironmentBaseAppUrl) > -1 ) {
                return sourceUrl.replace(unproxiedEnvironmentBaseAppUrl, currentEnvironmentBaseAppUrl);
            } else {
                return sourceUrl;
            }
        }

        return sourceUrl;
    }
}
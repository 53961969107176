import { BaseIdentity } from '../domain-models/base-identity';
import { Expose } from '../serialization/class-transformer/decorators';
import { ServiceRequest } from './service-request';

export class GetByIdentityRequest<TIDentity extends BaseIdentity> extends ServiceRequest {

    @Expose()
    identity: TIDentity;

    constructor(identity?: TIDentity) {
        super();
        this.identity = identity;
    }

}

import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, Renderer2, NgZone, Output, EventEmitter } from '@angular/core';
import { fromEvent, BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { CommandsPage } from '../../../view-models/commands/commands-page';
import { MenuItem } from 'primeng/api';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { CommandsGroup, UICommandInterface } from '../../../view-models';
import { SlideMenu, SlideMenuModule } from 'primeng/slidemenu';
import { FilledButtonComponent, FilledButtonType } from '../../shared/buttons/filled-button/filled-button.component';
import { TelemetryService } from '@nts/std/src/lib/telemetry';
import { RibbonButtonComponent } from '../../shared/buttons/ribbon-button/ribbon-button.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'nts-tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    RibbonButtonComponent,
    AsyncPipe,
    SlideMenuModule,
    FilledButtonComponent,
    NgFor
  ]
})

export class ToolBarComponent implements AfterViewInit {
  
  @Input() moreOptionsMenuItemList$: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);
  @Input() mobileMenuItemList$: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);
  @Input() moreOptionsCommand: UICommandInterface;
  @Input() mobileMenuCommand: UICommandInterface;
  @Input() commandsGroup: Array<CommandsGroup>;
  @Input() defaultCommand?: UICommandInterface;
  @Input() defaultCommandType?: FilledButtonType;
  
  @Output() onMoreOptionClick = new EventEmitter<SlideMenu>();
  @Output() onMobileMenuClick = new EventEmitter<SlideMenu>();
  @Output() onToolBarReady = new EventEmitter<void>();

  @ViewChild('moreOptionsMenu', { static: false }) moreOptionsMenu: SlideMenu;
  @ViewChild('mobileMenu', { static: false }) mobileMenu: SlideMenu;

  moreOptionMenuViewportHeight$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  mobileMenuViewportHeight$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  mobileMenuViewportWidth$: BehaviorSubject<number> = new BehaviorSubject<number>(0);;
  menuDocumentClickListener: any;

  constructor(
    protected cd: ChangeDetectorRef,
    protected environment: EnvironmentConfiguration,
    private readonly renderer: Renderer2,
    private readonly zone: NgZone,
    private readonly telemetryService: TelemetryService
  ) {}

  ngAfterViewInit(): void {
    this.checkMoreOptionsMenu();
    this.checkMobileMenu();
    fromEvent(window, 'resize').pipe(untilDestroyed(this), debounceTime(100)).subscribe(evt => {
      this.checkMoreOptionsMenu();
      this.checkMobileMenu();
    })
    this.onToolBarReady.emit();
  }

  closeAllMenu() {
    if (this.moreOptionsMenu) {
      this.moreOptionsMenu.hide();
    }
    if (this.mobileMenu) {
      this.mobileMenu.hide();
    }
  }

  async executeCommand(command: UICommandInterface, e: any){
    this.closeAllMenu(); 
   
    // E' stato aggiunto un timeout di 100 ms per dare tempo ai menù di chiudersi e quindi non lasciarli aperti se si cambia pagina, #3887
    setTimeout(() => {
      command.execute(command, e);
      this.telemetryService.trackEvent({
        name: `Command ${command.displayName} executed`,
      }, {command})
    }, 100)    
  }

  async moreOptionClick(moreOptionsMenu: SlideMenu, event: any, component: RibbonButtonComponent) {
    if (!moreOptionsMenu.visible) {
      this.onMoreOptionClick.emit(this.moreOptionsMenu);

      this.checkMoreOptionsMenu();
      setTimeout(() => this.bindMenuDocumentClickListener(this.moreOptionsMenu?.containerViewChild?.nativeElement, component.element.nativeElement), 250);
      this.telemetryService.trackEvent({name: 'MoreOptions Menu Opened'});
    } else {
      this.unbindMenuDocumentClickListener();
      this.telemetryService.trackEvent({name: 'MoreOptions Menu Closed'});
    }
    moreOptionsMenu.toggle(event);
  }

  async mobileMenuClick(mobileMenu: SlideMenu, event: any, component: RibbonButtonComponent) {
      if (this.moreOptionsMenu) {
        this.moreOptionsMenu.hide();
      }
      if (!mobileMenu.visible) {
        this.onMobileMenuClick.emit(this.moreOptionsMenu);

        this.checkMobileMenu();
        setTimeout(() => this.bindMenuDocumentClickListener(this.mobileMenu.containerViewChild.nativeElement, component.element.nativeElement), 250);
        this.telemetryService.trackEvent({name: 'Mobile Menu Opened'});
      } else {
        this.unbindMenuDocumentClickListener();
        this.telemetryService.trackEvent({name: 'Mobile Menu Closed'});
      }
      mobileMenu.toggle(event);
  }

  trackById(index: number, command: UICommandInterface) {
    return command.uid;
  }

  private bindMenuDocumentClickListener(nativeElement, buttonNativeElement) {
    if (!nativeElement) {
      return;
    }
    this.unbindMenuDocumentClickListener();
    if (!this.menuDocumentClickListener) {
        this.zone.runOutsideAngular(() => {
            const documentTarget: any = nativeElement.ownerDocument;

            this.menuDocumentClickListener = this.renderer.listen(documentTarget, 'mousedown', (event) => {
                if (this.isOutsideClicked(event, nativeElement) && this.isOutsideClicked(event, buttonNativeElement)) {
                    this.unbindMenuDocumentClickListener();
                    this.zone.run(() => {
                        this.cd.markForCheck();
                        this.moreOptionsMenu?.hide();
                        this.mobileMenu?.hide();                        
                    });
                }

            });
        });
    }
  }

  private unbindMenuDocumentClickListener() {
    if (this.menuDocumentClickListener) {
        this.menuDocumentClickListener();
        this.menuDocumentClickListener = null;
    }
  }

  private isOutsideClicked(event: Event, nativeElement) {
    return !(nativeElement.isSameNode(event.target) || nativeElement.contains(event.target));
  }

  private async checkMoreOptionsMenu() {
    if (this.moreOptionsMenuItemList$.value?.length > 0) {
      const maxViewportHeight = window.innerHeight - 140;
      const height = 48 * (this.moreOptionsMenuItemList$?.value.length + 2);
      this.moreOptionMenuViewportHeight$.next(height > maxViewportHeight ? maxViewportHeight : height);
    } else {
      this.moreOptionMenuViewportHeight$.next(0);
    }
  }

  private async checkMobileMenu() {
    if (this.mobileMenuItemList$.value?.length > 0) {
      const maxViewportHeight = window.innerHeight - 140;
      const height = maxViewportHeight;
      this.mobileMenuViewportHeight$.next(height > maxViewportHeight ? maxViewportHeight : height);
      
      const maxViewportWidth = 560;
      const width = window.innerWidth - 15;
      this.mobileMenuViewportWidth$.next(width > maxViewportWidth ? maxViewportWidth : width);
      // this.mobileMenu.container.nativeElement.focus()
    } else {
      this.mobileMenuViewportHeight$.next(0);
      this.mobileMenuViewportWidth$.next(0);
    }
  }
}

import { FindOptions } from '../../domain-models/find-options/find-options';
import { ZoomStarterMode } from '../../domain-models/zoom/zoom-starter-mode';
import { ZoomAdvancedOptions } from '../../domain-models/find-options/zoom-advanced-options';
import { AggregateMetaData } from '../../meta-data/aggregate-meta-data';
import { DomainModelMetaData, ExternalMetaData } from '../../meta-data';

export class ZoomUIStarterArgs {

    callerDomainModelName: string;
    callerDomainModelFullName: string;
    options: ZoomAdvancedOptions;
    requestedDomainModelName: string;
    requestedDomainModelFullName: string;
    zoomStarterMode: ZoomStarterMode;
    aggregateMetaData: AggregateMetaData;
    isRequestedDomainModelRemote: boolean;
    rootDomainModelName: string;

    constructor(
        aggregateMetaData: AggregateMetaData,
        requestedDomainModelName: string = null,
        requestedDomainModelFullName: string = null,
        isRemote: boolean,
        callerAggregateMetaData: AggregateMetaData,
        rootDomainModelName: string,
        zoomOptions?: ZoomAdvancedOptions
    ) {

        const callerDomainModelName = callerAggregateMetaData.rootMetaData.name;
        const callerDomainModelFullName = callerAggregateMetaData.rootMetaData.fullName;

        this.rootDomainModelName = rootDomainModelName;
        this.aggregateMetaData = aggregateMetaData;
        this.callerDomainModelName = callerDomainModelName;
        this.callerDomainModelFullName = callerDomainModelFullName;

        if (callerDomainModelName == null && requestedDomainModelName != null) {
            this.callerDomainModelName = requestedDomainModelName;
        }

        if (callerDomainModelFullName == null && requestedDomainModelFullName != null) {
            this.callerDomainModelFullName = requestedDomainModelFullName;
        }

        if (zoomOptions == null) {
            zoomOptions = new ZoomAdvancedOptions();
        }
        this.options = zoomOptions;

        this.requestedDomainModelName = requestedDomainModelName;
        this.requestedDomainModelFullName = requestedDomainModelFullName;

        // Verifico se il domain model richiesto è remoto
        this.isRequestedDomainModelRemote = isRemote;
    }
}

import { MessageResourceManager } from './../../../../resources/message-resource-manager';
import { AccessMode } from './../../../../meta-data/access-mode.enum';
import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { CustomHeaderParamsInterface } from './custom_header_params.interface';
import { NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgIf } from '@angular/common';

@Component({
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [
        SvgIconComponent,
        NgxPopperjsModule,
        NgIf,
    ],
    template: `
    <div class="ag-header-cell-label" (click)="onSortRequested($event)">
        <span class="locked-field" *ngIf="params.columnInfo.securityAccess != null">
            <svg-icon key="locked"
                [popper]="securityTooltipDescription"
                [popperDisabled]="!securityTooltipDescription || securityTooltipDescription?.length == 0"
                [popperTrigger]="ngxPopperjsTriggers.hover"
                [popperDelay]="1000"
                [popperApplyClass]="securityTooltipClass"
                [popperPlacement]="ngxPopperjsPlacements.TOP"
                [popperPreventOverflow]="false"
                [popperHideOnScroll]="true"
                popperAppendTo="body">
            </svg-icon>
        </span>
        <span class="ag-header-cell-text" [style.color]="headerColor()">{{params.displayName}}</span>
        <span class="ag-header-icon ag-sort-ascending-icon" [hidden]="!params.enableSorting || sorted !== 'asc'">
            <svg-icon key="arrow-up">
            </svg-icon>
        </span>
        <span class="ag-header-icon ag-sort-descending-icon" [hidden]="!params.enableSorting || sorted !== 'desc'">
            <svg-icon key="arrow-down">
            </svg-icon>
        </span>
    </div>`
})
export class CustomHeaderComponent implements IHeaderAngularComp {
    params: CustomHeaderParamsInterface;
    sorted: string;
    isGhost = false;
    isExternalCode = false;
    securityTooltipDescription: string;
    securityTooltipClass: string;
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;

    agInit(params: any): void {
        this.params = params;

        this.isGhost = params.columnInfo.isGhost;
        this.isExternalCode = params.columnInfo.propertyTypeName.indexOf('ExternalCode') !== -1;

        this.params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));

        if (this.params.columnInfo.securityAccess === AccessMode.ReadOnly) {
            this.securityTooltipDescription = MessageResourceManager.Current.getMessage('std_Security_Access_Mode_ReadOnly');
            this.securityTooltipClass = 'tooltip-content alert';
        } else {
            this.securityTooltipDescription = MessageResourceManager.Current.getMessage('std_Security_Access_Mode_Deny');
            this.securityTooltipClass = 'tooltip-content error';
        }

        this.onSortChanged();
    }

    /** Get the header to refresh. Gets called whenever Column Defs are updated. */
    refresh(params: IHeaderParams): boolean {
        return true;
    }

    headerColor(): string {
        if (this.isExternalCode) {
            return 'blue';
        } else if (this.isGhost) {
            return '#f9a12e';
        } else {
            return 'inherit';
        }
    }

    onSortRequested(event): void {

        if (!this.params.enableSorting) {
            return;
        }

        let nextSort: 'asc' | 'desc';
        if (this.sorted === 'asc') {
            nextSort = 'desc';
        } else if (this.sorted === 'desc') {
            nextSort = null;
        } else {
            nextSort = 'asc';
        }
        this.params.setSort(nextSort, event.shiftKey);
    }

    onSortChanged(): void {
        if (this.params.column.isSortAscending()) {
            this.sorted = 'asc';
        } else if (this.params.column.isSortDescending()) {
            this.sorted = 'desc';
        } else {
            this.sorted = '';
        }
    }
}

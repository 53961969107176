import { Observable } from 'rxjs';
import { CacheOptionsInterface, WebApiServiceAgent } from './web-api-service-agent';
import { ActionHttpCommunication } from './action-http-communication';
import { MetaDataResponse } from '../responses/meta-data-response';
import { ActionApiClientInterface } from './action-api-client.interface';
import { RootModelTypeNameInspector } from './decorators/root-model-type-name.decorator';
import { GenericServiceResponse } from '../responses/generic-service-response';
import { AuthTokenDto } from '../domain-models/dto/auth-token.dto';
import { GenericArrayServiceResponse } from '../responses/generic-array-service-response';
import { TenantProfile } from '../domain-models/license/tenant-profile';
import { AutoCompleteExternalOptions } from '../domain-models/autocomplete/auto-complete-external-options';
import { FindValuesResponse } from '../responses/find-values-response';
import { UserProfile } from '../domain-models/user/user-profile';
import { VersionDataDto } from '../domain-models/dto/version-data.dto';
import { UserCanAccessToServiceResponse } from '../responses/user-can-access-to-service-response';
import { MultiReportInfoDto } from '../domain-models/report/multi-report-info.dto';
import { OperationIdentity } from '../domain-models/report/operation.identity';
import { PrintOutDemandDto } from '../domain-models/report/print-out-demand.dto';
import { OperationStateResultDto } from '../domain-models/spool/operation-state-result-dto';
import { AttachmentIdentity } from '../domain-models/spool/attachment.identity';
import { LayoutMetaData } from '../layout-meta-data/layout-meta-data';
import { DraftLayoutUIInfoDto } from '../domain-models/layout/draft-layout-ui-info.dto';
import { AvailableLayoutsInfoDto } from '../domain-models/layout/available-layouts-info.dto';
import { LayoutDefinitionIdentity } from '../domain-models/layout/layout-definition.identity';
import { PanelUserLayoutDataDto } from '../domain-models/layout/panel-user-layout-data.dto';
import { ServiceResponse } from '../responses/service-response';
import { UserLayoutMetaData } from '../layout-meta-data/user-layout-meta-data';
import { GridUserLayoutDataDto } from '../domain-models/layout/grid-user-layout-data.dto';
import { BaseLayoutDataDto } from '../domain-models/layout/base-layout-data.dto';
import { ServiceDataAccessDto } from '../auth/dto/service-data-access.dto';
import { OnlineService } from '@nts/std/src/lib/utility';
import { CookiePreferencesResponse } from '../responses/cookie-preferences-response';
import { FindValuesOptions } from '../domain-models/find-options/find-values-options';
import { Injectable } from '@angular/core';
import { EnterpriseListResponse } from '../responses/enterprise-list-response';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class ActionApiClient implements ActionApiClientInterface {

    protected agent: WebApiServiceAgent;

    constructor(
        agent: WebApiServiceAgent,
        public onlineService: OnlineService
    ) {

        const rootModelName = RootModelTypeNameInspector.getValue(this);
        if (rootModelName === undefined) {
            throw new Error(
                `MetaData ${RootModelTypeNameInspector.META_DATA_KEY} not defined. You must use ${RootModelTypeNameInspector.DECORATOR_NAME} in ${this.constructor.name}.`
            );
        }

        // ATTENZIONE Patch per Injection Singleton !!!
        // Questo è necessario perchè WebApiServiceAgent injettato è singleton quindi viene utilizzato da tutti gli api client
        // In questo caso in fase di inizializzazione del modulo l'api client sovrascrive WebApiServiceAgent con una sua istanza
        // con il suo rootModelName
        // TODO Tommy da rifare refactor e spostare il rootModelName al livello delle chiamate dell'api client
        // sarà un parametyro aggiuntivo nelle chiamate passato in automatico
        this.agent = new WebApiServiceAgent(
            agent['httpService'],
            agent['endPointResolver'],
            agent['responseCache'],
            agent['onlineService'],
            agent['serializationWebworkerFactory'],
            agent['responseCacheWebworkerSettings']
        );
        this.agent.rootModelName = rootModelName;
    }

    /**
     * AutoComplete di un aggregato
     * @param autoCompleteOptions 
     * @returns 
     */
    autoComplete(
        autoCompleteOptions: AutoCompleteExternalOptions,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
        retryRequest = 2
    ): Observable<FindValuesResponse> {
        const communication = this.createActionHttpCommunication();
        return communication.autoComplete(autoCompleteOptions, cacheOptions, retryRequest);
    }

    findValues(
        entityToLookUp = null,
        findOptions: FindValuesOptions, 
        entityToLookUpFullName: string, 
        rootModelName = null,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
        retryRequest = 2,
        customHeaders: HttpHeaders = null
    ): Observable<FindValuesResponse> {
        const communication = this.createActionHttpCommunication();
        return communication.findValues(
            entityToLookUp, 
            findOptions, 
            entityToLookUpFullName, 
            null,
            cacheOptions,
            retryRequest,
            customHeaders,
        );
    }

    /**
     * Restituisce i metadati dell'oggetto richiesto tramite MetaDataExternalRequest.FullRootModelName
     * @param includeDescriptions 
     * @returns 
     */
    // @SaveResultsForOffline()
    getMetaDataAsync(
        includeDescriptions = false,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,
            enableTimeout: true,
            tenantBarrier: true,
            enterpriseBarrier: false,
            userBarrier: false                                                   
        },
        retryRequest = 0
    ): Observable<MetaDataResponse> {
        const communication = this.createActionHttpCommunication();
        return communication.getMetaDataAsync(
            includeDescriptions,
            cacheOptions,
            retryRequest
        );
    }

    getPresentationMetaDataAsync(
        identity: LayoutDefinitionIdentity,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
        retryRequest = 2
    ): Observable<MetaDataResponse> {
        const communication = this.createActionHttpCommunication();
        return communication.getPresentationMetaDataAsync(identity, cacheOptions, retryRequest);
    }

    createDraftLayout(
        layoutMetaData: LayoutMetaData,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
    ): Observable<GenericServiceResponse<DraftLayoutUIInfoDto>> {
        const communication = this.createActionHttpCommunication();
        return communication.createDraftLayout(layoutMetaData, cacheOptions);
    }

    getAvailableLayoutsAsync(
        layoutDefinitionIdentity?: LayoutDefinitionIdentity,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
        retryRequest = 2
    ): Observable<GenericServiceResponse<AvailableLayoutsInfoDto>> {
        const communication = this.createActionHttpCommunication();
        return communication.getAvailableLayoutsAsync(layoutDefinitionIdentity, cacheOptions, retryRequest);
    }

    setPanelUserLayoutDataAsync(
        dto: PanelUserLayoutDataDto
    ): Observable<ServiceResponse> {
        const communication = this.createActionHttpCommunication();
        return communication.setPanelUserLayoutDataAsync(dto);
    }

    setGridUserLayoutDataAsync(dto: GridUserLayoutDataDto): Observable<ServiceResponse> {
        const communication = this.createActionHttpCommunication();
        return communication.setGridUserLayoutDataAsync(dto);
    }

    getUserLayoutMetaDataAsync(
        dto: BaseLayoutDataDto,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
        retryRequest = 2
    ): Observable<GenericServiceResponse<UserLayoutMetaData>> {
        const communication = this.createActionHttpCommunication();
        return communication.getUserLayoutMetaDataAsync(dto, cacheOptions, retryRequest);
    }

    /**
     * Richiede il profilo dell'utente indicato nel token di accesso inviato nell'Auth Bearer
     * @returns 
     */
    getUserProfile(
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
        retryRequest = 2,
        customHeaders = null
    ): Observable<GenericServiceResponse<UserProfile>> {
        const communication = this.createActionHttpCommunication();
        return communication.getUserProfile(cacheOptions, retryRequest, customHeaders);
    }

    getCookiePreferences(
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,
            enableTimeout: true,
            tenantBarrier: false,
            enterpriseBarrier: false,
            userBarrier: true                                                              
        },
        retryRequest = 0,
        customHeaders = null
    ): Observable<CookiePreferencesResponse> {
        const communication = this.createActionHttpCommunication();
        return communication.getCookiePreferences(cacheOptions, retryRequest, customHeaders);
    }

    /**
     * La lista delle aziende e delle ditte
     * 
     * @param cacheOptions 
     * @returns 
     */
    getEnterprisesList(
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
        retryRequest = 2,
        customHeaders = null
    ): Observable<EnterpriseListResponse> {
        const communication = this.createActionHttpCommunication();
        return communication.getEnterprisesList(cacheOptions, retryRequest, customHeaders);
    }

    /**
     * Ottiene un nuovo token di accesso autenticandosi con il refresh token passato nell'Auth Bearer
     * @param refreshToken 
     * @returns 
     */
    refreshToken(refreshToken: string, retryRequest = 2): Observable<GenericServiceResponse<AuthTokenDto>> {
        const communication = this.createActionHttpCommunication();
        return communication.refreshToken(refreshToken, retryRequest);
    }

    /**
     * Richiede il profilo del tenant indicato nel token di accesso inviato nell'Auth Bearer
     * @returns 
     */
    getTenantProfile(
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
        retryRequest = 5
    ): Observable<GenericServiceResponse<TenantProfile>> {
        const communication = this.createActionHttpCommunication();
        return communication.getTenantProfile(cacheOptions, retryRequest);
    }

    /**
     * Ottiene la lista di tutte i tenant disponibili per l'utente corrente
     */
    getAvailableTenants(
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
        retryRequest = 5
    ): Observable<GenericArrayServiceResponse<TenantProfile>> {
        const communication = this.createActionHttpCommunication();
        return communication.getAvailableTenants(cacheOptions, retryRequest);
    }       

    /**
     * Ottiene la lista di tutti i report disponibili associati al modello corrente
     */
    getAvailableReports(
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
        retryRequest = 5
    ): Observable<GenericServiceResponse<MultiReportInfoDto>> {
        const communication = this.createActionHttpCommunication();
        return communication.getAvailableReports(cacheOptions, retryRequest);
    }

    /**
     * Verifica se l'utente corrente ha accesso ad uno specifico servizio
     * @param objectName 
     * @returns 
     */
    userCanAccessToService(
        objectName: string,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
        retryRequest = 2
    ): Observable<UserCanAccessToServiceResponse> {
        const communication = this.createActionHttpCommunication();
        return communication.userCanAccessToService(
            objectName,
            cacheOptions,
            retryRequest
        );
    }

    /**
     * Verifica se l'utente corrente ha accesso ad uno specifico servizio
     * @param objectName 
     * @returns 
     */
    userCanAccessToServices(
        objectNames: string[],
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
        retryRequest = 2
    ): Observable<GenericArrayServiceResponse<ServiceDataAccessDto>> {
        const communication = this.createActionHttpCommunication();
        return communication.userCanAccessToServices(objectNames, cacheOptions, retryRequest);
    }

    getWebApiServiceAgent(): WebApiServiceAgent {
        return this.agent;
    }

    /**
     * Resetta le impostazioni utente per un determinato layout o per il layout standard se non si passa il LayoutIdentity
     * @param dto
     * @returns
     */
    clearUserLayoutMetaDataAsync(
        dto: BaseLayoutDataDto
    ): Observable<GenericServiceResponse<Boolean>> {
        const communication = this.createActionHttpCommunication();
        return communication.clearUserLayoutMetaDataAsync(dto);
    }

    getVersionData(
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
        retryRequest = 2
    ): Observable<GenericServiceResponse<VersionDataDto>> {
        const communication = this.createActionHttpCommunication();
        return communication.getVersionData(cacheOptions, retryRequest);
    }

    printOutFileDemand<TParams, TRequestDto extends PrintOutDemandDto<TParams>>(customControllerAddress: string, dto: TRequestDto): Observable<GenericServiceResponse<OperationIdentity>> {
        const communication = this.createActionHttpCommunication();
        return communication.printOutFileDemand(customControllerAddress, dto);
    }

    downloadPrintOutFile(customControllerAddress: string, attachmentIdentity: AttachmentIdentity): Observable<Blob> {
        const communication = this.createActionHttpCommunication();
        return communication.downloadPrintOutFile(customControllerAddress, attachmentIdentity);
    }

    /**
     * Ottiene lo stato corrente di una operation
     */
    getOperationState(
        customControllerAddress: string, 
        operationIdentity: OperationIdentity,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
        retryRequest = 2
    ): Observable<GenericServiceResponse<OperationStateResultDto>> {
        const communication = this.createActionHttpCommunication();
        return communication.getOperationState(customControllerAddress, operationIdentity, cacheOptions, retryRequest);
    }

    protected createActionHttpCommunication(): ActionHttpCommunication {
        return new ActionHttpCommunication(this.agent);
    }
}


<div class="date-container"
    #dateContainer
    #popperError="popper"
    [popper]="tooltipErrTemplate"
    [popperDisabled]="!showErrorTooltip || !errorList || errorList?.length === 0"
    [popperTrigger]="ngxPopperjsTriggers.hover"
    popperApplyClass="error"    
    [popperPlacement]="ngxPopperjsPlacements.TOP"
    [popperPreventOverflow]="false"
    [popperHideOnScroll]="true"
    popperAppendTo="body">

    <nts-base-date-mask-text-box 
    #dateMaskBox 
    [defaultBorderColor]="defaultBorderColor"
    [activeBorderColor]="activeBorderColor"
    [hoverBorderColor]="hoverBorderColor"
    [errorList]="errorList" 
    [initialChar]="initialChar" 
    [valueDate]="valueDate" 
    [isDisabled]="isDisabled"
    (onDateBlur)="onBlur($event)" 
    (valueDateChange)="inputDateValueChange($event)" 
    [maskSettings]="maskSettings"></nts-base-date-mask-text-box>

    <p-calendar #calendar 
        (ngModelChange)="calendarDateValueChange($event)" 
        (onSelect)="onSelect($event)"
        (onClickOutside)="onClickOutside($event)"
        (onClose)="onClose($event)"
        (onInput)="onInput($event)"
        (onShow)="onShow($event)"
        (onFocus)="onDateFocus.emit($event)"
        [ngModel]="getValueDate()"
        [baseZIndex]="1050"
        [dateFormat]="dateFormat"
        [icon]="showTimeZone ? 'icon-calendar-timezone' : 'icon-calendar'"
        [monthNavigator]="monthNavigator"
        [yearNavigator]="yearNavigator"
        [appendTo]="appendTo"
        [hideOnDateTimeSelect]="hideOnDateTimeSelect"
        [selectOtherMonths]="selectOtherMonths"
        [required]="isRequired"
        [showIcon]="showIcon"
        [defaultDate]="defaultDate"
        [showOnFocus]="showOnFocus"
        [yearRange]="'1900:2100'"
        [showTime]="showTime"
        [disabled]="isDisabled"
        [showButtonBar]="showButtonBar" 
        [tabindex]="dateTabIndex"
        [class.has-error]="showErrorBorder && errorList?.length > 0"
        inputStyleClass="base-datepicker-input"
        panelStyleClass="base-datepicker-panel"
        styleClass="base-datepicker"
        todayButtonStyleClass="base-datepicker-today-button"
        clearButtonStyleClass="base-datepicker-clear-button">

        <p-footer [hidden]="!showTimeZone">
            <nts-base-enum-text-box *ngIf="timeZoneOptions?.length > 0" #timeZoneCombo [value]="valueTimeZone" [options]="timeZoneOptions" [appendTo]="'body'"
                [isDisabled]="isDisabled" [placeholder]="timeZonePlaceholder"
                [tabindex]="timeZoneTabIndex" dataKey="key" (onChange)="timeZoneChange($event)"
                (onBlur)="onTimeZoneBlur.emit()" [isNullable]="false" [title]="timeZonePlaceholder">
            </nts-base-enum-text-box>
        </p-footer>
    </p-calendar>

    <popper-content #tooltipErrTemplate>
        <ng-container *ngFor="let item of errorList">
            {{ item }}
        </ng-container>
    </popper-content>

    <ng-template ngFor let-commandName [ngForOf]="customCommandList">
        <button class="command-bar-button" (click)="commandName.execute()"
            [disabled]="isDisabled || !(commandName.canExecute$ | async)" [attr.title]="commandName.tooltip"><i
                [ngClass]="commandName.iconClass"></i></button>
    </ng-template>
</div>
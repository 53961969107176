export interface VersionInterface {
    /**
     * Frontend Framework version
     */
    FrameworkJsVersion: string;

    /**
     * Frontend Framework commit
     */
    FrameworkJsInformativeVersion: string;

    /**
     * Frontend version
     */
    JsVersion: string;

    /**
     * Frontend commit
     */
    JsInformativeVersion: string;
}

export class VersionManager {

    version: VersionInterface;

    private static instance: VersionManager;    

    static get Current(): VersionManager {
        if (this.instance == null) {
            if (this.instance == null) {
                this.instance = new VersionManager();
            }
        }
        return this.instance;
    }


}



import { AllowedChars } from '../../../meta-data/allowed-chars';
import { BaseValidation } from './base-validation';
import { ValidationArguments } from 'class-validator';
import { MessageCodes } from '../../../resources/message-codes';

export class AllowedCharsValidation extends BaseValidation<string> {

    private allowedChars: AllowedChars;

    constructor(allowedChars: AllowedChars) {
        super();
        this.allowedChars = allowedChars;
    }

    validateImplementation(value: string, args: ValidationArguments) {
        let bIsValid = true;
        if (value != null && this.allowedChars != null) {
            switch (this.allowedChars) {
                case AllowedChars.NumbersOnly:
                    for (const char of value) {
                        if (!this.isDigit(char)) {
                            bIsValid = false;
                            this.messageCode = MessageCodes.PropertyStringNumberOnly;
                            this.errorMessage = this.getMessageWithTag(args, MessageCodes.PropertyStringNumberOnly_TAG_NomeCampo);
                            break;
                        }
                    }
                    break;
                case AllowedChars.LowercaseLettersOrNumbersOnly:
                    for (const char of value) {
                        if (!this.isLetterOrDigit(char) || (!this.isDigit(char) && !this.isLowerCase(char))) {
                            bIsValid = false;
                            this.messageCode = MessageCodes.PropertyStringLowerLettersOrNumbersOnly;
                            this.errorMessage = this.getMessageWithTag(args, MessageCodes.PropertyStringLowerLettersOrNumbersOnly_TAG_NomeCampo);
                            break;
                        }
                    }
                    break;
                case AllowedChars.UppercaseLettersOrNumbersOnly:
                    for (const char of value) {
                        if (!this.isLetterOrDigit(char) || (!this.isDigit(char) && !this.isUpperCase(char))) {
                            bIsValid = false;
                            this.messageCode = MessageCodes.PropertyStringUpperLettersOrNumbersOnly;
                            this.errorMessage = this.getMessageWithTag(args, MessageCodes.PropertyStringUpperLettersOrNumbersOnly_TAG_NomeCampo);
                            break;
                        }
                    }
                    break;
                case AllowedChars.LowercaseLettersOnly:
                    for (const char of value) {
                        if (!this.isLetter(char) || !this.isLowerCase(char)) {
                            bIsValid = false;
                            this.messageCode = MessageCodes.PropertyStringLowerLettersOnly;
                            this.errorMessage = this.getMessageWithTag(args, MessageCodes.PropertyStringLowerLettersOnly_TAG_NomeCampo);
                            break;
                        }
                    }
                    break;
                case AllowedChars.UppercaseLettersOnly:
                    for (const char of value) {
                        if (!this.isLetter(char) || !this.isUpperCase(char)) {
                            bIsValid = false;
                            this.messageCode = MessageCodes.PropertyStringUpperLettersOnly;
                            this.errorMessage = this.getMessageWithTag(args, MessageCodes.PropertyStringUpperLettersOnly_TAG_NomeCampo);
                            break;
                        }
                    }
                    break;
                case AllowedChars.DecimalOnly:
                    for (const char of value) {
                        if (!this.isDecimalDigits(char)) {
                            bIsValid = false;
                            this.messageCode = MessageCodes.PropertyStringNumberOnly;
                            this.errorMessage = this.getMessageWithTag(args, MessageCodes.PropertyStringNumberOnly_TAG_NomeCampo);
                            break;
                        }
                    }
                    break;
            }
        }
        return bIsValid;
    }

    private isDigit(value: string): boolean {
        return /^[0-9]$/.test(value);
    }

    private isDecimalDigits(value: string): boolean {
        return this.decimalDigitsRegex(value);
    }

    private isUpperCase(value: string): boolean {
        return value === value.toUpperCase();
    }

    private isLowerCase(value: string): boolean {
        return value === value.toLowerCase();
    }

    private isLetter(value: string): boolean {
        return /^[a-zA-Z]*$/.test(value);
    }

    private isLetterOrDigit(value: string): boolean {
        return this.isDigit(value) || this.isLetter(value);
    }

    private alphaNumericRegex(value: string): boolean { return /(^[0-9a-zA-Z]*$)|(^[0-9a-zA-Z][0-9a-zA-Z]+[0-9a-zA-Z]$)/.test(value); }
    private alphabeticalRegex(value: string): boolean { return /^[A-Za-z]+$/.test(value); }
    private numericRegex(value: string): boolean { return /^[0-9]+$/.test(value); }
    private alphaUpperRegex(value: string): boolean { return /^[A-Z]+$/.test(value); }
    private alphaLowerRegex(value: string): boolean { return /^[a-z]+$/.test(value); }
    private alphaUpperNumRegex(value: string): boolean { return /^[0-9A-Z]+$/.test(value); }
    private alphaLowerNumRegex(value: string): boolean { return /^[0-9a-z]+$/.test(value); }
    private alphaUpperNumberPunctuationNoAccentRegex(value: string): boolean { return /^([0-9A-Z_/\\\-!%&\(\)\[\]\?'\*#@]+$)/.test(value); }


    // Qualsiasi carattere usabile in una parola. Sono esclusi gli spazi, i caratteri speciali, la punteggiatura.
    private allCharsRegex(value: string): boolean { return /^\w+$/.test(value); }
    // Solo i carrattere numerici interi o decimali, punto (.) o virgola (,) come separatore migliaia
    private decimalDigitsRegex(value: string): boolean { return /^[0-9]*[\.\,]*[0-9]+$/.test(value); }
    // Solo i caratteri alfabetici maiuscoli, compresi quelli accentati e anderscore (_)
    private alphaUpperAccentRegex(value: string): boolean { return /^[\p{Lu}\p{Pc}]*$/.test(value); }
    // Solo i caratteri alfabetici minuscoli compresi quelli accentati e anderscore (_)
    private alphaLowerAccentRegex(value: string): boolean { return /^[\p{Ll}\p{Pc}]*$/.test(value); }
    // Solo i caratteri alfabetici maiuscoli compresi quelli accentati, numerici e anderscore (_)
    private alphaUpperAccentNumRegex(value: string): boolean { return /^[\p{Lu}\p{N}\p{Pc}]*$/.test(value); }
    // Solo i caratteri alfabetici minuscoli compresi quelli accentati, numerici e anderscore (_)
    private alphaLowerAccentNumRegex(value: string): boolean { return /^[\p{Ll}\p{N}\p{Pc}]*$/.test(value); }
    // Caratteri  alfabetici compresi quelli accentati, numerici, punteggiatura, spazi, parentesi ecc..
    private letterSpaceNumberPunctuationRegex(value: string): boolean { return /^[\p{L}\p{N}\p{P}\p{Zs}]*$/.test(value); }
    // Caratteri  alfabetici maiuscoli compresi quelli accentati, numerici, punteggiatura, spazi, parentesi ecc..
    private alphaUpperSpaceNumberPunctuationRegex(value: string): boolean { return /^[\p{Lu}\p{N}\p{P}\p{Zs}]*$/.test(value); }
    // Solo i caratteri alfabetici maiuscoli compresi quelli accentati, numerici, simboli (esempio $) e anderscore (_)
    private alphaUpperAccentNumSymbolRegex(value: string): boolean { return /^[\p{Lu}\p{N}\p{Pc}\p{S}]*$/.test(value); }
    // Solo i caratteri alfabetici maiuscoli compresi quelli accentati, numerici, simboli (esempio $) e anderscore (_),  punteggiatura, spazi, parentesi
    private salphaUpperAccentNumberSymbolPunctuationRegex(value: string): boolean { return /^[\p{Lu}\p{N}\p{S}\p{P}\p{Zs}]*$/.test(value); }
    // Caratteri  alfabetici maiuscoli compresi quelli accentati, numerici,
    // punteggiatura, parentesi, barre, underscore, meno, apici, cancelletto, percentuale, e commerciale, chiocciola, asterisco.
    // Esclusi simboli $,£,^,§,+,°, ç e spazi.
    private alphaUpperNumberPunctuationRegex(value: string): boolean { return /^[\p{Lu}\p{N}\p{P}]*$/.test(value); }
    // Tutti i caratteri ASCII da 32(20) a 126(7E), sono esclusi i quindi caratteri accentati e i caratteri di controllo.
    private printableAsciiChars(value: string): boolean { return /"^[ -~]*$/.test(value); }

}

import { Expose, Type } from "../../../serialization/class-transformer/decorators";
import { ZoomDataDto } from "./zoom-data.dto";

export class ZoomDataListDto {

    constructor() {
        this.zooms = [];
    }

    @Expose()
    @Type(() => ZoomDataDto)
    zooms: ZoomDataDto[];
}
import { ZoomFilterViewModel } from './zoom-filter-view-model';
import { ZoomMetaData } from '../../../meta-data/zoom-meta-data';
import { ModifiedSubscriberInterface } from '../../modified-subscriber.interface';
import { ZoomOperatorViewModelProperty } from '../zoom-operator-view-model-property';
import { FilterVariablesType } from '../../../domain-models/find-options/filter-variables-type.enum';
import { GuidZoomPropertyViewModel } from '../property-view-model/guid-zoom-property-view-model';

export class GuidZoomFilterViewModel extends ZoomFilterViewModel {
    constructor(metadata: ZoomMetaData, modifiedSubscriber: ModifiedSubscriberInterface) {
        super(metadata);
        this.operator = new ZoomOperatorViewModelProperty(ZoomOperatorViewModelProperty.operatorsForGuid, modifiedSubscriber);
        this.filterValue = new GuidZoomPropertyViewModel(metadata.guidMetaData, modifiedSubscriber);
        this.filterVariablesType = FilterVariablesType.UserFilter;
        this.init();
    }
}

export abstract class BaseCommandItem {
    displayName: string;
    position: number;
    name: string;
    description: string;
    keyTip: string;

    private _tooltip: string;

    get tooltip(): string {
        if (!this._tooltip) {
            return this.displayName;
        }
        return null;
    }

    set tooltip(value: string) {
        if (this._tooltip !== value) {
            this._tooltip = value;
        }
    }

    constructor(name = '', displayName = '') {
        this.displayName = displayName;
        this.name = name;
    }
}

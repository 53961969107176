<nts-base-numeric-box #baseNumericBox
*ngIf="useChips == false" 
    [value]="propertyViewModel.value"
    [isDisabled]="!filter.isEnabled || !propertyViewModel.enabled"
    (onFocus)="selectAllContent($event)"
    [maskSettings]="this.inputMaskSettings" [minValue]="this.minValue"
    [maxValue]="this.maxValue"></nts-base-numeric-box>
<nts-base-chips-box
    #baseChipsBox
    [isDisabled]="!filter.isEnabled || !propertyViewModel.enabled"
    (onValueChange)="valueChange($event)"
    [value]="valueForChips"
    *ngIf="useChips == true">
</nts-base-chips-box>
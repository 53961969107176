import { Inject, Injectable, Optional } from '@angular/core';
import { MessageResourceManager } from '../../resources/message-resource-manager';
import { MessageCodes } from '../../resources/message-codes';
import { ModalService } from '../../view-models/modal/modal.service';
import { MessageButton } from '../../view-models/modal/message-button';
import { MessageResult } from '../../view-models/modal/message-result';
import { ModalViewModelInterface } from '../../view-models/modal/modal-view-model.interface';
import { ModalResult } from '../../view-models/modal/modal-result';
import { Information } from '../../messages/information';
import { BaseError } from '../../messages/base-error';
import { PopupViewModel } from '../../view-models/modal/popup-view-model';
import { ModalContainerComponent } from './modal-container.component';
import { ExternalModalViewModel } from '../../view-models/modal/external-modal-view-model';
import { ZoomResult } from '../../domain-models/zoom/zoom-result';
import { ZoomOrchestratorViewModel } from '../../view-models/zoom/zoom-orchestrator-view-model';
import { ZoomUIStarterArgs } from '../../view-models/zoom/zoom-ui-starter-args';
import { IFrameGetter } from '../../starter/ui-starter';
import { EnvironmentConfiguration, UNPROXIED_ENVIRONMENT } from '@nts/std/src/lib/environments';
import { EnvironmentHelper } from '@nts/std/src/lib/environments';
import { MetaDataDescriptions } from '../../meta-data/meta-data-descriptions';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RoutingService } from '../../routing/routing.service';
import { AuthService } from '../../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class ModalComponentService extends ModalService {

  async showZoomAsync(viewModel: ZoomOrchestratorViewModel, maximizable = true): Promise<ModalResult<ZoomResult>> {
    viewModel.modalTitle = MessageResourceManager.Current.getMessageWithArgs(MessageCodes.Zoom) + ' -> ' + viewModel.rootDomainModelDisplayName;

    return await this.showCustomModalWithResultAsync<ZoomUIStarterArgs, ZoomResult>(
      viewModel, 
      false, 
      maximizable,
      true, 
      {
        disableClose: true,
        panelClass: ['zoom-container']
      },
      true
    );
  }

  async showMessageAsync(
    title: string, 
    message: string, 
    buttons: MessageButton,
    metaDataDescriptionsButtons: MetaDataDescriptions[] = []
  ): Promise<MessageResult> {

    const top = `${this.routingService.inIframe ? 50 : 100}px`;

    const popUpViewModel = new PopupViewModel();
    popUpViewModel.list = [{
      message,
      detail: null
    }];
    return await this.showStandardModalAsync(
      buttons, 
      title, 
      popUpViewModel, {
        position: {
          top
        }
      }, 
      metaDataDescriptionsButtons);
  }

  /**
   * Utilizzare per visualizzare popup partendo da un errore
   */
  async showExceptionPopUp(
    error: Error, 
    caption = '', 
    message = '',
    metaDataDescriptionsButton: MetaDataDescriptions = null
  ) {

    const popUpViewModel = new PopupViewModel();
    popUpViewModel.list = [{
      message: message ? message : error.message,
      detail: this.getExceptionDetail(error)
    }];

    popUpViewModel.caption = caption ? caption : this.errorCaption;

    return await this.showStandardModalAsync(
      MessageButton.Ok, 
      popUpViewModel.caption, 
      popUpViewModel, 
      {
        disableClose: true
      }, 
      metaDataDescriptionsButton ? [metaDataDescriptionsButton] : []
    );
  }

  private get informationCaption() {
    return MessageResourceManager.Current.getMessage(MessageCodes.Information);
  };

  private get warningCaption() {
    return MessageResourceManager.Current.getMessage(MessageCodes.Warning);
  }

  private get errorCaption() {
    return MessageResourceManager.Current.getMessage(MessageCodes.Error);
  }

  constructor(
    public dialog: MatDialog,
    // private readonly bsModalService: BsModalService,
    private routingService: RoutingService,
    public override route: ActivatedRoute,
    private readonly environment: EnvironmentConfiguration,
    private readonly authService: AuthService,
    @Optional()
    @Inject(UNPROXIED_ENVIRONMENT) private readonly unproxiedEnvironment: EnvironmentConfiguration
  ) {
    super();
  }

  async showStandardModalAsync(
    dialogButtons: MessageButton, 
    title: string, 
    viewModel: ModalViewModelInterface<any, void>, 
    config: MatDialogConfig<any> = {
      disableClose: true
    },
    metaDataDescriptionsButtons: MetaDataDescriptions[] = [],
    isCentered = false
  ): Promise<MessageResult> {

    if(this.routingService.inIframe) {
      config.backdropClass = 'in-iframe';
    }

    if(this.routingService.inIframe) {
      const inIframeDialogClass = 'in-iframe';
      config = {...config, panelClass: (config?.panelClass?.length > 0) ? [inIframeDialogClass, ...config.panelClass] : [inIframeDialogClass]}  
    }

    if (isCentered === false) {
      const top = `${this.routingService.inIframe ? 50 : 100}px`;
      if (config?.position?.top == null) {
        const position =  {...(config?.position ?? {top: null}), top};
        config = {...config, position};
      }
    }

    const dialogRef = this.dialog.open(ModalContainerComponent, config);

    return await dialogRef.componentInstance.showStandardModalAsync(
      dialogButtons, 
      title, 
      viewModel,
      metaDataDescriptionsButtons
    );
  }

  async showCustomModalWithResultAsync<TInputArgs, TResult>(
    viewModel: ModalViewModelInterface<TInputArgs, TResult>, 
    showCancel = true,
    maximizable = false,
    maximized = false,
    config: MatDialogConfig<any> = {},
    isCentered = false
    ): Promise<ModalResult<TResult>> {

    if(this.routingService.inIframe) {
      config.backdropClass = 'in-iframe';
    }

    if (maximized) {
      const fullscreenDialogClass = 'fullscreen-dialog';

      if (config?.panelClass && typeof config?.panelClass === 'string' && config?.panelClass?.length > 0) {
        config = {...config, panelClass: [config?.panelClass, fullscreenDialogClass]}  
      } else {
        config = {...config, panelClass: (config?.panelClass?.length > 0) ? [fullscreenDialogClass, ...config.panelClass] : [fullscreenDialogClass]}  
      }   

    }

    if(this.routingService.inIframe) {
      const inIframeDialogClass = 'in-iframe';

      if (config?.panelClass && typeof config?.panelClass === 'string' && config?.panelClass?.length > 0) {
        config = {...config, panelClass: [config?.panelClass, inIframeDialogClass]}  
      } else {
        config = {...config, panelClass: (config?.panelClass?.length > 0) ? [inIframeDialogClass, ...config.panelClass] : [inIframeDialogClass]}  
      }      
    }

    if (isCentered === false) {
      const top = `${this.routingService.inIframe ? 50 : 100}px`;
      if (config?.position?.top == null) {
        const position =  {...(config?.position ?? {top: null}), top};
        config = {...config, position};
      }
    }

    if (maximized === true) {
      const top = `${this.routingService.inIframe ? 25 : 55}px`;
      const position =  {...(config?.position ?? {top: null}), top};
      config = {...config, position};
    }

    const dialogRef = this.dialog.open(ModalContainerComponent, config);

    // const component = this.bsModalService.show(ModalContainerComponent, options).content as ModalContainerComponent;
    return await dialogRef.componentInstance.showCustomModalWithResultAsync<TResult>(viewModel, showCancel, maximizable, maximized);
  }

  async showExternalModal<TResult>(
    baseUrl: string, 
    jsonIdentity: string = null, 
    additionalQueryParams = new URLSearchParams(),
    modalTitle?: string, 
    externalReturn = true,
    supportRemoteClosingCheck = false,
    customExternalModalViewModel = null,
    supportRemoteStatus = false,
  ): Promise<ModalResult<TResult>> {

    const tenantId = await this.authService.getTenantId();
    const enterpriseData = await this.authService.getEnterpriseData(tenantId);

    const externalModalViewModel = customExternalModalViewModel ?? new ExternalModalViewModel(this);
    externalModalViewModel.baseUrl = EnvironmentHelper.getProxiedUrl(
      baseUrl, this.environment, this.unproxiedEnvironment
    );

    externalModalViewModel.jsonIdentity = jsonIdentity;
    externalModalViewModel.additionalQueryParams = additionalQueryParams ?? new URLSearchParams();

    if(this.ovm){
      this.ovm.isModalOpen = true;
    }

    if (enterpriseData?.enterpriseId != null && enterpriseData?.enterpriseId > -1) {
      externalModalViewModel.additionalQueryParams.set(AuthService.ENTERPRISE_ID_QUERY_KEY, enterpriseData.enterpriseId);
    }

    if (enterpriseData?.companyId != null && enterpriseData?.companyId > -1) {
      externalModalViewModel.additionalQueryParams.set(AuthService.COMPANY_ID_QUERY_KEY, enterpriseData.companyId);
    }

    externalModalViewModel.modalTitle = modalTitle;
    externalModalViewModel.externalReturn = externalReturn;
    externalModalViewModel.supportRemoteClosingCheck = supportRemoteClosingCheck;
    externalModalViewModel.supportRemoteStatus = supportRemoteStatus;
    const res = (this.showCustomModalWithResultAsync<IFrameGetter, TResult>(
      externalModalViewModel, 
      true, 
      false, 
      false,
      {
        panelClass: ['external-modal-container'],
      },
      true
    ));

    res.then(async () => {
      if(this.ovm){
        this.ovm.isModalOpen = false;
      }
    })


    return await res;
  }

  /**
   * Utilizzare per visualizzare popup partendo da una response
   */
  async showPopUp(
    informations: Array<Information>, 
    errors: Array<BaseError>,
    caption = '',
    metaDataDescriptionsButton: MetaDataDescriptions = null
  ) {

    const popUpViewModel = new PopupViewModel();

    if (errors.length > 0) {
      popUpViewModel.caption = caption ? caption : this.errorCaption;

      const errorList = errors.map((e) => {
        let detail = '';
        detail = this.visitErrors(detail, e);

        let message = e.description;
        if (e.code && e.code?.length > 0) {
          message += `<br>Codice: ${e.code}`;
        }
        if (e.propertyName && e.propertyName?.length > 0) {
          message += `<br>PropertyName: ${e.propertyName}`;
        }
        return {
          message,
          detail
        }
      })

      // TODO aggiungere warning/exception

      popUpViewModel.list = errorList;

    } else if (informations.length > 0) {
      // Non ci sono errori, solo informazioni, allora il messaggio principale è quello della prima informazione e lo stile è di tipo Information
      popUpViewModel.caption = caption ? caption : this.informationCaption;

      const infoList = informations.map((i) => {
        const sb = '';
        this.visitInformations(sb, i);
        return {
          message: `Information: ${i.description}<br> Code: ${i.code}<br>PropertyName: ${i.propertyName}<br>`,
          detail: sb
        }
      })

      popUpViewModel.list = infoList;
    }

    return await this.showStandardModalAsync(
      MessageButton.Ok, 
      popUpViewModel.caption, 
      popUpViewModel,
      {
        disableClose: true
      }, 
      metaDataDescriptionsButton ? [metaDataDescriptionsButton] : []
    );
  }

  private visitErrors(sb: string, error: BaseError): string {
    if (error != null) {
      if (error.objectName && error.objectName?.length > 0) {
        sb += `${error.objectName}<br>`;
      }

      if (error.stackTrace && error.stackTrace?.length > 0) {
        sb += `${error.stackTrace}<br>`;
      }
      return this.visitErrors(sb, error.innerError);
    }
    return sb;
  }

  private visitInformations(sb: string, information: Information) {
    if (information != null) {
      const errorFormat = `DMName: ${information.objectName}<br>`;
      sb += errorFormat;
    }
  }

  private getExceptionDetail(exception: Error) {
    let detail = '';

    if (exception != null) {
      let sb = '';
      sb += `Message: ${exception.message} <br>`;
      sb += `Stack trace: ${exception.stack} <br>`;

      detail = sb;
    }

    return detail;
  }
}


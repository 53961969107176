import { NTSReflection } from '@nts/std/src/lib/utility';

const COLLECTION_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY = 'collectionViewModelTypeDecoratorMetadataKey';

export function CollectionViewModelTypeDecorator(collectionViewModelType: any) {

    // if (!(collectionViewModelType.prototype instanceof CollectionViewModel)) {
    //     throw new Error(`Invalid Type provided: ${collectionViewModelType} must instance of CollectionViewModel!`);
    // }

    return (target: any, propertyKey: any) => {
        Reflect.defineMetadata(COLLECTION_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, collectionViewModelType, target, propertyKey);
    };
}

export class CollectionViewModelTypeInspector {
    static isApplied(subject: any, propertyName: string): boolean {
        return NTSReflection.hasPropertyMetadata(
            COLLECTION_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject, propertyName);
    }

    static getValue(subject: any, propertyName: string): any {
        let value = NTSReflection.getPropertyMetadata(
            COLLECTION_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject, propertyName);

        // Verifica che sia una funzione con due parametri
        if (typeof value === 'function' && value.length === 2) {
            value = value(subject, propertyName);
        }
        return value;
    }
}

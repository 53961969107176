import { DatasourceCollectionViewModelInterface } from './datasource-collection-view-model.interface';
import { ItemViewModel } from './item-view-model';
import { BaseIdentity } from '../domain-models/base-identity';
import { CollectionViewModel } from './collection-view-model';
import { IDatasource, IGetRowsParams, PaginationChangedEvent } from 'ag-grid-community';
import { EventEmitter } from '@angular/core';
import { CoreModel } from '../domain-models';
import { Subject } from 'rxjs';
import { LogService } from '@nts/std/src/lib/utility';

export class DatasourceCollectionViewModel<
    TItemViewModel extends ItemViewModel<TItemModel, TItemIdentity>,
    TItemModel extends CoreModel<TItemIdentity>,
    TItemIdentity extends BaseIdentity>
    extends CollectionViewModel<TItemViewModel, TItemModel, TItemIdentity>
    implements DatasourceCollectionViewModelInterface<TItemViewModel> {

    override onFocusRequested: Subject<void> = new Subject();
    paginationChanged = new Subject<PaginationChangedEvent>();
    datasourceUpdated: EventEmitter<any> = new EventEmitter<any>();
    /**
     * Passa true se è il primo caricamento
     */
    paginatedCollectionItemsLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
    
    /**
     * Passa true se è il primo caricamento
     */
    paginatedCollectionItemsError: EventEmitter<boolean> = new EventEmitter<boolean>();
    skipItemPostInit = false;
    datasource: IDatasource = {
        rowCount: null,
        getRows: async (params: IGetRowsParams): Promise<void> => {

            const currentTake = this.getTakeParameter(params);
            this._currentTake = currentTake;
            let currentSkip = this.getSkipParameter(params);
            this._currentSkip = currentSkip;

            LogService.debug(
                'asking for ' + params.startRow + ' to ' + params.endRow
            );

            let collectionsItems: TItemModel[];
            collectionsItems = await this.getPaginatedCollectionItems(currentTake, currentSkip);

            // Errore durante il recupero
            if (!collectionsItems) {

                LogService.warn(
                    'Pagination API failed!'
                );
                this.paginatedCollectionItemsError.emit(params.startRow === 0);
                this.clear(false, false);
                return params.successCallback(this, 0);
            }

            for (let i = 0; i < collectionsItems.length; i++) {
                const item = await this.createByDomainModel(collectionsItems[i], true, false, this.isMock, this.skipItemPostInit);
                
                if (this.length === params.startRow + i) {
                    this.addCollectionItem(item, false, true, false);
                } else {
                    this.setCollectionItem(params.startRow + i, item, false);
                }
                
            }

            var lastRow = -1;
            if (this.length <= (params.startRow + currentTake - 1)) {
                lastRow = this.length;
            }

            params.successCallback(this.slice(params.startRow, params.startRow + currentTake), lastRow);

            this.paginatedCollectionItemsLoaded.emit(params.startRow === 0);

            while ((((currentSkip - params.startRow) + currentTake) < (params.endRow - params.startRow))) {
                currentSkip = currentSkip + currentTake;
                const result = await this.getPaginatedCollectionItems(currentTake, currentSkip);
                if (result?.length === currentTake) {

                    for (let i = 0; i < result.length; i++) {
                        const item = await this.createByDomainModel(result[i], true, false, this.isMock, this.skipItemPostInit);
                        
                        if (this.length === currentSkip + i) {
                            this.addCollectionItem(item, false, true, false);
                        } else {
                            this.setCollectionItem(currentSkip + i, item, false);
                        }
                        
                    }

                    var lastRow = -1;
                    if (this.length <= (currentSkip + currentTake - 1)) {
                        lastRow = this.length;
                    }

                    params.successCallback(this.slice(params.startRow, currentSkip + currentTake), lastRow);

                    this.paginatedCollectionItemsLoaded.emit(currentSkip === 0);

                    // collectionsItems = [...collectionsItems, ...result];
                } else {
                    if (result?.length > 0) {

                        for (let i = 0; i < result.length; i++) {
                            const item = await this.createByDomainModel(result[i], true, false, this.isMock, this.skipItemPostInit);
                            
                            if (this.length === currentSkip + i) {
                                this.addCollectionItem(item, false, true, false);
                            } else {
                                this.setCollectionItem(currentSkip + i, item, false);
                            }
                            
                        }

                        var lastRow = -1;
                        if (this.length <= (currentSkip + currentTake - 1)) {
                            lastRow = this.length;
                        }

                        params.successCallback(this.slice(params.startRow, currentSkip + currentTake), lastRow);

                        this.paginatedCollectionItemsLoaded.emit(currentSkip === 0);
                    }                    
                    break;
                }                
            }

        }
    };

    private _currentTake: number;
    private _currentSkip: number;

    override async postInit(): Promise<void> {
        await super.postInit();
        this.datasourceUpdated.subscribe(() => {
            this.clear(false, false);
        });
    }

    private internalMaxPagination = 0;

    get maxPagination(): number {
        return this.internalMaxPagination;
    }

    setMaxPagination(value: number) {
        this.internalMaxPagination = value;
    }

    getTakeParameter(params: IGetRowsParams): number {
        const take = params.endRow - params.startRow + 1;
        if (this.maxPagination > 0) {
            return take > this.maxPagination ? this.maxPagination : take;
        }
        return take;
    }

    getSkipParameter(params: IGetRowsParams): number {
        return params.startRow;
    }

    async getPaginatedCollectionItems(take: number, skip: number): Promise<TItemModel[] | null> {
        return [];
    }
}

import { Component, Input, OnInit, QueryList, ViewChildren } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LogService } from "@nts/std/src/lib/utility";
import { merge } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { RootModelTypeNameInspector } from "../../api-clients/decorators/root-model-type-name.decorator";
import { LayoutDefinitionIdentity } from "../../domain-models/layout/layout-definition.identity";
import { PanelUserLayoutDataDto } from "../../domain-models/layout/panel-user-layout-data.dto";
import { CustomFieldMetaData, ExternalLayoutMetaData, FieldTypes, GridMetaData, PanelState, SimpleFieldMetaData, UserLayoutMetaData, UserPanelMetaData } from "../../layout-meta-data";
import { LayoutMetaData } from "../../layout-meta-data/layout-meta-data";
import { PanelMetaData } from "../../layout-meta-data/panel-meta-data";
import { SpacerMetaData } from "../../layout-meta-data/spacer-meta-data";
import { CoreOrchestratorViewModelInterface } from "../../view-models/core-orchestrator-view-model.interface";
import { OrchestratorViewModelInterface } from "../../view-models/orchestrator-view-model.interface";
import { RootViewModelInterface } from "../../view-models/root-view-model.interface";
import { BaseFieldComponent } from "../controls/core/base-field/base-field.component";
import { CustomFieldComponent } from "../controls/core/custom-field/custom-field.component";
import { ExternalFieldComponent } from "../controls/core/external-field/external-field.component";
import { GridFieldComponent } from "../controls/core/grid-field/grid-field.component";
import { SimpleFieldComponent } from "../controls/core/simple-field/simple-field.component";
import { ExpandableComponent } from "../shared/expandable/expandable.component";
import { ViewComponentInterface } from "../view-component.interface";

@UntilDestroy()
@Component({
    template: '',
    standalone: true,
})
export abstract class BaseAggregateComponent<TRootViewModel extends RootViewModelInterface, TOrchestratorViewModel extends CoreOrchestratorViewModelInterface = OrchestratorViewModelInterface > implements ViewComponentInterface<RootViewModelInterface>, OnInit {
    @Input() orchestratorViewModel: TOrchestratorViewModel;
    @ViewChildren(ExpandableComponent) expandableComponentList: QueryList<ExpandableComponent>;

    /**
     * Viene impostata solo quando si è in un layout personalizzato
     */
    layoutIdentity?: LayoutDefinitionIdentity;

    get rootViewModel(): TRootViewModel {
        return this.orchestratorViewModel.rootViewModel as TRootViewModel;
    }

    ngOnInit() {
        LogService.log(RootModelTypeNameInspector.getValue(this.orchestratorViewModel.apiClient) + ' - INIT');
    }

    onViewModelLoaded() {
        this.orchestratorViewModel.templateLayoutMetaData = this.buildLayoutMetaDataFromExpandableComponentList(this.expandableComponentList);
    }

    private buildLayoutMetaDataFromExpandableComponentList(expandableList: QueryList<ExpandableComponent>): LayoutMetaData {
        const layoutMetaData = new LayoutMetaData();
        if (expandableList?.length > 0) {
            expandableList.forEach((expandable) => {

                // Abilito le chiamate per la personalizzazione dei pannelli utente solo se è stato impostato un id
                if (expandable.panelId?.length > 0) {

                    if (this.orchestratorViewModel.userLayoutMetaData?.panels.length > 0) {
                        const panelLayoutMetaData = this.orchestratorViewModel.userLayoutMetaData?.panels.find((p) => p.panelId === expandable.panelId);
                        if (panelLayoutMetaData) {
                            expandable.isCollapsed = panelLayoutMetaData.panelState === PanelState.Collapsed;
                            expandable.showAdditionalFields = panelLayoutMetaData.panelState === PanelState.Expanded;
                        }
                    }

                    merge(
                        expandable.onCollapsed,
                        expandable.onExpanded,
                        expandable.onExpandedAdditionalFields,
                        expandable.onCollapsedAdditionalFields
                    ).pipe(untilDestroyed(this), debounceTime(1000)).subscribe(async () => {
                        const dto = new PanelUserLayoutDataDto();
                        dto.layoutIdentity = this.layoutIdentity;
                        dto.panelMetaData.panelId = expandable.panelId;
                        if (expandable.isCollapsed) {
                            dto.panelMetaData.panelState = PanelState.Collapsed;
                        } else {
                            dto.panelMetaData.panelState = expandable.showAdditionalFields ? PanelState.Expanded : PanelState.Opened;
                        }
                        await this.orchestratorViewModel.setPanelUserLayoutData(dto);
                    })
                }

                const panel = new PanelMetaData();
                if (expandable.isCollapsed) {
                    panel.panelState = PanelState.Collapsed;
                } else {
                    if (expandable.showAdditionalFields) {
                        panel.panelState = PanelState.Expanded
                    } else {
                        panel.panelState = PanelState.Opened
                    }
                }

                panel.panelId = expandable.panelId;

                panel.isVisible = !expandable.isHidden;
                panel.promotedFields = expandable.promotedFields.map((pf) => {

                    if (pf.propertyPath?.length > 0) {
                        return pf.propertyPath + '.' + pf.propertyName
                    }
                    return pf.propertyName;
                });

                panel.descriptions.displayName = expandable.title;

                expandable.expandableChildren.forEach((child, index: number) => {
                    switch (child.type) {
                        case FieldTypes.Spacer:
                            const spacerField = new SpacerMetaData();
                            const spacerChild = child as BaseFieldComponent;
                            spacerField.name = spacerChild.name;
                            spacerField.position = index + 1;
                            spacerField.isAdditionalField = spacerChild.additionalField;
                            spacerField.path = spacerChild.path;
                            spacerField.isFullColumn = spacerChild.fullColumn;
                            spacerField.descriptions.description = spacerChild.description;
                            spacerField.descriptions.displayName = spacerChild.displayName;
                            spacerField.isVisible = !spacerChild.isHidden;
                            spacerField.isDisabled = spacerChild.isDisabled;
                            panel.spacers.push(spacerField);
                            break;

                        case FieldTypes.Simple:
                            const simpleField = new SimpleFieldMetaData();
                            const simpleChild = child as SimpleFieldComponent;
                            simpleField.name = simpleChild.name;
                            simpleField.position = index + 1;
                            simpleField.isAdditionalField = simpleChild.additionalField;
                            simpleField.path = simpleChild.path;
                            simpleField.isMultiLine = simpleChild.multiline;
                            simpleField.isFullColumn = simpleChild.fullColumn;
                            simpleField.descriptions.description = simpleChild.description;
                            simpleField.descriptions.displayName = simpleChild.displayName;
                            simpleField.isVisible = !simpleChild.isHidden;
                            simpleField.isDisabled = simpleChild.isDisabled;
                            panel.simpleFields.push(simpleField);
                            break;

                        case FieldTypes.Grid:

                            const gridField = new GridMetaData();
                            const gridChild = child as GridFieldComponent;

                            gridChild.onColumnsChanged.pipe(untilDestroyed(this), debounceTime(1000)).subscribe(async (dto) => {
                                dto.layoutIdentity = this.layoutIdentity;
                                await this.orchestratorViewModel.setGridUserLayoutDataAsync(dto);
                            })

                            gridField.name = gridChild.name;
                            gridField.position = index + 1;
                            gridField.isAdditionalField = gridChild.additionalField;
                            gridField.path = gridChild.path;
                            gridField.isFullColumn = gridChild.fullColumn;
                            gridField.descriptions.description = gridChild.description;
                            gridField.descriptions.displayName = gridChild.displayName;
                            gridField.isVisible = !gridChild.isHidden;
                            gridField.isDisabled = null;
                            gridField.gridColumns = gridChild.columnDefinition;
                            gridField.areAllColumnsDisabled = gridChild.isDisabled;
                            gridField.areAllCommandsDisabled = gridChild.areAllCommandsDisabled;

                            panel.grids.push(gridField);
                            break;

                        case FieldTypes.External:
                            const externalField = new ExternalLayoutMetaData();
                            const externalChild = child as ExternalFieldComponent;
                            externalField.name = externalChild.name;
                            externalField.position = index + 1;
                            externalField.isAdditionalField = externalChild.additionalField;
                            externalField.path = externalChild.path;
                            externalField.isFullColumn = externalChild.fullColumn;
                            externalField.externalFields = externalChild.externalCodes;
                            externalField.availableExternalCodes = externalChild.externalCodesAvailables;
                            externalField.externalDecodes = externalChild.externalDecodes;
                            externalField.availableExternalDecodes = externalChild.externalDecodesAvailables;
                            externalField.descriptions.description = externalChild.description;
                            externalField.descriptions.displayName = externalChild.displayName;
                            externalField.showCodeInDescription = externalChild.showCodeInDescription;
                            externalField.isVisible = !externalChild.isHidden;
                            externalField.isDisabled = externalChild.isDisabled;
                            panel.externals.push(externalField);
                            break;

                        case FieldTypes.Custom:
                            const customField = new CustomFieldMetaData();
                            const customChild = child as CustomFieldComponent;
                            customField.name = customChild.name;
                            customField.position = index + 1;
                            customField.isAdditionalField = customChild.additionalField;
                            customField.path = customChild.path;
                            customField.isFullColumn = customChild.fullColumn;
                            customField.descriptions.description = customChild.description;
                            customField.descriptions.displayName = customChild.displayName;
                            customField.isVisible = !customChild.isHidden;
                            customField.isDisabled = customChild.isDisabled;
                            customField.uniqueId = customChild.uniqueId;
                            panel.customFields.push(customField);
                            break;
                    }
                })

                // TODO tooltip
                panel.descriptions.description = expandable.title;
                layoutMetaData.panels.push(panel);
            });
        }
        return layoutMetaData;
    }
}

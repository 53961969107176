import { Observable, of } from 'rxjs';
import { NewViewModelState } from './new-view-model-state';
import { UnchangedViewModelState } from './unchanged-view-model-state';
import { ViewModelState } from './view-model-state';
import { ViewModelStateContextInterface } from './view-model-state-context.interface';
import { ViewModelStates } from './view-model-states';

export class VersionedViewModelState extends ViewModelState {

    constructor(context: ViewModelStateContextInterface) {
        super(context);
    }

    get value() {
        return ViewModelStates.Versioned;
    }

    override create() {
        this.context.currentState = new NewViewModelState(this.context);
    }

    override canStoreAndCreate(): Observable<boolean> {
        return of(false);
    }

    override getBySnapShotIdentity() {
        this.context.currentState = new VersionedViewModelState(this.context);
    }

    override getByIdentity() {
        this.context.currentState = new UnchangedViewModelState(this.context);
    }

    override modify() {

    }

    override canStartRelatedClient() {
        return of(false);
    }

    override canCreate(): Observable<boolean> {
        return of(!this.startedAsRelatedClient || !this.viewModel);
    }

    override canRestore(): Observable<boolean> {
        return of(true);
    }

    override canStore(): Observable<boolean> {
        return of(false);
    }

    override canRemove(): Observable<boolean> {
        return of(false);
    }

    override canVersioned(): Observable<boolean> {
        return of(true);
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { ZoomFilterViewModel } from '../../view-models/zoom/filter-view-model/zoom-filter-view-model';
import { FilterOperators } from '../../domain-models/find-options/filter';

@Pipe({
    name: 'zoomAvailablesFilter',
    pure: false,
    standalone: true
})
export class ZoomAvailablesFilterPipe implements PipeTransform {
    transform(items: Array<ZoomFilterViewModel>, filterOnlyWithOperatorPopulated: boolean): Array<any> {
        return items.filter(
            // o nel caso in cui devo filtrare i filtri con operatore impostato, solo quelli impostati (indipendentemente dalla visibilità)
            item => 
                // se l'elemento è visibile e non sono nella modalità filtra solo quelli con operatore
                (item.isHidden === false && item.isVisible === true && filterOnlyWithOperatorPopulated === false) || 

                // se sono nella modalità filtra solo quelli con operatore, visualizza tutti i filtri con operatore indipendentemente dalla visibilità
                (item.isHidden === false && filterOnlyWithOperatorPopulated === true && item.operator.currentValue !== FilterOperators.None)

        );
    }
}
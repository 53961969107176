import { Filter } from './filter';
export class FilterCollection extends Array<Filter> {

    constructor() {
      super();
      Object.setPrototypeOf(this, FilterCollection.prototype);
    }

    override toString(): string {
        let sb = '';
        this.forEach(function (element, index)  {
            sb += ' ' + element.toString();
        });
        return sb;
    }
}

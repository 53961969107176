export enum TenantTypes {
    /**
     * Azineda: utente finale del sistema
     */
    EndUser = 0,

    /**
     * Partner: rivenditore dei prodotti del sistema
     */
    Partner = 1,

    /**
     * Provider: gestisce il sistema
     */
    Provider = 2
}

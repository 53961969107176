import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  template: '',
  standalone: true
})
export abstract class NtsAppRootComponent {
  isLoading: boolean = true;
  // constructor(public router: Router) { }
  // ngOnInit() {
  //   this.router.events.subscribe((event) => {
  //     console.log(event);
  //   });
  // }
}
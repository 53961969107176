import { Expose } from '../../serialization/class-transformer/decorators';
import { FindValuesOptions } from './find-values-options';

export class FindValuesExternalOptions extends FindValuesOptions {

    @Expose()
    fullRootModelName: string;

    constructor(findOptions?: FindValuesOptions) {
        super(findOptions);
        this.outputProperties = findOptions.outputProperties;
    }
}

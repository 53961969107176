import { ReaderApiClient } from '../reader-api-client';
import { CoreModel } from '../../domain-models/core-model';
import { BaseIdentity } from '../../domain-models/base-identity';
import { RemoveRequest } from '../../requests/remove-request';
import { Observable } from 'rxjs';
import { ServiceResponse } from '../../responses/service-response';
import { CrudHttpCommunication } from './crud-http-communication';
import { StoreRequest } from '../../requests/store-request';
import { CreateResponse } from '../../responses/create-response';
import { CrudApiClientInterface } from './crud-api-client.interface';
import { StoreResponse } from '../../responses/store-response';
import { CacheOptionsInterface } from '../web-api-service-agent';
import { HttpHeaders } from '@angular/common/http';
import { PinIdentityToDashboardDto } from '../../requests/pin-identity-to-dashboard.dto';
import { GenericServiceRequest } from '../../requests/generic-service-request';
import { Injectable } from '@angular/core';


@Injectable()
export class CrudApiClient<TModel extends CoreModel<TIdentity>, TIdentity extends BaseIdentity>
    extends ReaderApiClient<TModel, TIdentity>
    implements CrudApiClientInterface {

    protected createCrudHttpCommunication(): CrudHttpCommunication {
        return new CrudHttpCommunication(this.agent);
    }

    create(
        cacheOptions: CacheOptionsInterface = {
            bypass: false,
            expirationTime: undefined,
            force: false,
        },
        customHeaders: HttpHeaders = null
    ): Observable<CreateResponse<TModel, TIdentity>> {

        // Default for create
        cacheOptions = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,
            enterpriseBarrier: false,
            tenantBarrier: true,
            ...cacheOptions
        };

        const communication = this.createCrudHttpCommunication();
        return communication.create<TModel, TIdentity>(
            this.rootModelType,
            cacheOptions,
            customHeaders
        );
    }

    createML(
        cacheOptions: CacheOptionsInterface = {
            bypass: false,
            expirationTime: undefined,
            force: false,
        },
    ): Observable<CreateResponse<TModel, TIdentity>> {
        const communication = this.createCrudHttpCommunication();
        return communication.createML<TModel, TIdentity>(
            this.rootModelType,
            cacheOptions
        );
    }

    remove(request: RemoveRequest<TIdentity>): Observable<ServiceResponse> {
        const communication = this.createCrudHttpCommunication();
        return communication.remove<TIdentity>(request);
    }

    store(request: StoreRequest<TModel>): Observable<StoreResponse<TModel, TIdentity>> {
        const communication = this.createCrudHttpCommunication();
        return communication.store<TModel, TIdentity>(request, this.rootModelType);
    }

    pinIdentityToDashboard(request: GenericServiceRequest<PinIdentityToDashboardDto<TIdentity>>): Observable<ServiceResponse> {
        const communication = this.createCrudHttpCommunication();
        return communication.pinIdentityToDashboard<TIdentity>(request);
    }
}

import { ViewModelTypeDecorator } from '../../decorators/view-model-type.decorator';
import { ModalViewModelInterface } from './modal-view-model.interface';

@ViewModelTypeDecorator(PopupViewModel)
export class PopupViewModel implements ModalViewModelInterface<any, void> {

    modalTitle: string;
    modalSubtitle = '';
    showFooter = true;

    modalCommands;

    private _list: {
        message: string
        detail: string
    }[];
    get list(): {
        message: string
        detail: string
    }[] {
        return this._list;
    }
    set list(v: {
        message: string
        detail: string
    }[]) {
        this._list = v;
    }


    private _caption: string;
    get caption(): string {
        return this._caption;
    }
    set caption(v: string) {
        this._caption = v;
    }

    async initialize(args: any): Promise<void> {
        throw new Error("PopupViewModel does't need call to initialize!")
    }

}

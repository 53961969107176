import { MessageResourceManager } from './../../../../resources/message-resource-manager';
import { ChangeDetectionStrategy, OnChanges, Component, Input, ViewChild, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { BasePropertyComponent } from '../base-property-component';
import { BaseEnumPropertyViewModel, EnumPropertyViewModelItem, NEnumPropertyViewModel } from '../../../../view-models/base-type/enum-property-view-model';
import { HttpClient } from '@angular/common/http';
import { BaseOffsetPropertyViewModel, NOffsetPropertyViewModel } from '../../../../view-models/base-type/offset-property-view-model';
import { filter, takeUntil } from 'rxjs/operators';
import { Observable, merge } from 'rxjs';
import { BaseRadioButtonTextBoxComponent } from '../base/base-radio-button-text-box/base-radio-button-text-box.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopperHelper } from '@nts/std/src/lib/utility';
import { BaseSecurityTextBoxComponent } from '../base';
import { AsyncPipe, NgIf } from '@angular/common';


@UntilDestroy()
@Component({
    selector: 'nts-radio-button-text-box',
    templateUrl: './radio-button-text-box.component.html',
    styleUrls: ['./radio-button-text-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        BaseRadioButtonTextBoxComponent,
        BaseSecurityTextBoxComponent,
        NgIf,
        AsyncPipe
    ]
})
export class RadioButtonTextBoxComponent extends BasePropertyComponent<number> implements OnChanges {
    
    @Input() override propertyViewModel: BaseEnumPropertyViewModel;
    @Input() isDisabled = false;
    @Input() showErrorTooltip = true;
    @Input() showErrorBorder = true;
    @Input() tabIndex: number;
    @Input() primaryColor = null;
    
    @ViewChild('baseRadioButtonTextBox', { static: true }) baseRadioButtonTextBox: BaseRadioButtonTextBoxComponent;
    
    value: EnumPropertyViewModelItem;

    get placeholder() {
        return MessageResourceManager.Current.getMessage('std_Enum_Nullable_DisplayName');
    }

    get input(): HTMLInputElement {
        // return selected input
        return this.baseRadioButtonTextBox.modelRefList[this.value?.key ?? 0].control.nativeElement;
    }

    get isNullable() {
        return this.propertyViewModel instanceof NEnumPropertyViewModel || this.propertyViewModel instanceof NOffsetPropertyViewModel;
    }
    
    constructor(private readonly http: HttpClient, cd: ChangeDetectorRef) {
        super(cd);
    }

    untilDestroyedThis(): <U>(source: Observable<U>) => Observable<U> {
        return untilDestroyed<this>(this)
    }

    override async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (!this.propertyViewModel) { throw new Error('Missing viewModel!'); }

        // offset
        if (this.propertyViewModel instanceof BaseOffsetPropertyViewModel) {
            await this.propertyViewModel.populateValueDescriptionsAsync(this.http);
        }
        this.initCombo();

        this.destroyOnChange$.next(true);

        // Rimane in ascolto quando cambia il pvm e il valore
        merge(
            this.propertyViewModel.customGetterValueChanged,
            this.propertyViewModel.propertyViewModelChanged,
            this.propertyViewModel.modelChanged,
            this.propertyViewModel.propertyChanged.pipe(filter((args) => args.propertyName === this.propertyViewModel.bindedValuePropertyName))
        ).pipe(
            this.untilDestroyedThis(),
            takeUntil(this.destroyOnChange$)
        ).subscribe(() => {
            this.initCombo();
            this.cd.detectChanges();
        });

        // Rimane in ascolto quando cambiano le property diverse dal valore: isVisible, isEnabled, ecc
        this.propertyViewModel.propertyChanged.pipe(
            this.untilDestroyedThis(),
            takeUntil(this.destroyOnChange$),
            filter((args) => args.propertyName !== this.propertyViewModel.bindedValuePropertyName)
        ).subscribe(() => {
            this.cd.detectChanges();
        });

    }

    getTitle(): string {
        if (this.propertyViewModel.value != null && this.propertyViewModel.valueDescriptions != null) {
            const value = this.propertyViewModel.valueDescriptions.find(vd => vd.key === this.propertyViewModel.value);
            return value && value.description ? value.description : this.placeholder;
        } else {
            return this.placeholder;
        }
    } 

    onBlur(): void {
        if (this.propertyViewModel.isEnabled && !this.isDisabled) {
            if (this.propertyViewModel instanceof NEnumPropertyViewModel && this.value == null) {
                this.valueChange.emit(null);
                if (this.modelUpdate) {
                    this.propertyViewModel.value = null;
                }
            } else {
                this.value = this.propertyViewModel.valueDescriptions.find(x => x.key === this.propertyViewModel.value);
            }
        }
    }

    onChange(option): void {
        this.value = option;
        this.valueChange.emit(this.value != null ? this.value.key : null);
        if (this.modelUpdate) {
            this.propertyViewModel.value = this.value != null ? this.value.key : null;
        }
    }

    showErroTooltip(): void {
        if (this.baseRadioButtonTextBox?.popperError) {
            PopperHelper.show(this.baseRadioButtonTextBox?.popperError);
        }
    }

    private initCombo(): void {
        this.value = this.propertyViewModel.valueDescriptions.find(x => x.key === this.propertyViewModel.value);
    }  
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { UICommandInterface } from '../../../../view-models/commands/ui-command.interface';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { RibbonButtonComponent } from '../../../shared/buttons/ribbon-button/ribbon-button.component';
@UntilDestroy()
@Component({
    selector: 'nts-commands-bar',
    templateUrl: './commands-bar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./commands-bar.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        RibbonButtonComponent,
        AsyncPipe,
        NgFor
    ]
})
export class CommandsBarComponent implements OnChanges {

    @Input() commands: UICommandInterface[] = [];
    @Input() title = '';
    @Input() rtl = false;

    commandsChanged: Subject<void> = new Subject();

    constructor(private readonly cd: ChangeDetectorRef) {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['commands']) {
            this.commands = changes['commands'].currentValue;
            this.commandsChanged.next();
            this.cd.detectChanges();
        }
    }
}

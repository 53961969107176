import { ColumnsProviderInterface } from './columns_provider.interface';
import { ColumnInfoCollection } from '.';
import { ExternalColumnMapInfo } from './external_column_map_info';
import { ColumnInfo } from './column-info';
import { UserLayoutMetaData } from '../layout-meta-data/user-layout-meta-data';

export class ColumnsProvider implements ColumnsProviderInterface {

    readonly customGridColumns: Map<string, ColumnInfoCollection>;
    readonly gridColumns: Map<string, ColumnInfoCollection>;
    readonly userLayoutMetaData: UserLayoutMetaData;

    constructor(
        gridColumns: Map<string, ColumnInfoCollection>, 
        customGridColumns: Map<string, ColumnInfoCollection> = null,
        userLayoutMetaData: UserLayoutMetaData = null
    ) {
        this.gridColumns = gridColumns;
        this.customGridColumns = customGridColumns ?? new Map<string, ColumnInfoCollection>();
        this.userLayoutMetaData = userLayoutMetaData ?? new UserLayoutMetaData();
    }

    getExternalColumns(entityTypeName: string, externalColumnsMapInfo: ExternalColumnMapInfo[]): ColumnInfo[] {
        return [];
    }
}

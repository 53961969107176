import { forkJoin, from, identity, Observable, of } from 'rxjs';
import { catchError, map, switchMap, timeout } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationManager } from './configuration-manager';
import extend from 'lodash-es/extend';
import { LogService } from '../log/log.service';
import { LocalstorageHelper, SerializabledDataType } from '../localstorage-helper';
import { TIMEOUT_CACHE } from '../cache/cache-settings';
import { OnlineService } from '../offline.service';

export enum ConfigurationLoaderFileType {
    StdConfiguration = 'stdConfiguration',
    Configuration = 'configuration'
}

export const CONFIGURATION_LOADER_STORAGE_KEY = 'jsonConfiguration'

@Injectable({
    providedIn: 'root',
})
export class ConfigurationLoader {

    constructor(
        private http: HttpClient,
        private onlineService: OnlineService
    ) { }

    getConfiguration(): Observable<any> {

        return from(LocalstorageHelper.getStorageItem(CONFIGURATION_LOADER_STORAGE_KEY, undefined, false, false)).pipe(
           switchMap((storedJsonConfiguration) => {
                
                let obs: Observable<SerializabledDataType> | any;

                if(this.onlineService.isOnline === false) {
                    if (storedJsonConfiguration) {
                        // Caso offline e cache presente, ritorna subito l'oggetto storedJsonConfiguration
                        obs = of(storedJsonConfiguration)
                    } else {
                        // Caso offline e cache non presente, ritorna oggetto vuoto
                        obs = of({})
                    }
                } else {
                    obs = forkJoin({
                        [ConfigurationLoaderFileType.StdConfiguration]: this.http.get('assets/std/configuration.json').pipe(
                            catchError(e => {
                                LogService.warn('missing std configuration.json!', e);
                                return of({});
                            }) as any
                        ) ,
                        [ConfigurationLoaderFileType.Configuration as any] : this.http.get('assets/configuration.json').pipe(
                            catchError(e => {
                                LogService.warn('missing ms configuration.json!', e);
                                return of({});
                            }) as any
                        ).pipe(
                            storedJsonConfiguration ?
                                // Caso online e cache presente, ritorna l'oggetto storedJsonConfiguration solot dopo un timeout di TIMEOUT_CACHE
                                timeout({ 
                                    first: TIMEOUT_CACHE,
                                    with: () => {
                                        return of(storedJsonConfiguration);
                                    }
                                }) as any :
                                // Caso online e cache non presente, ritorna identity
                                identity
                        )
                    } as any) 
                }

                return obs.pipe(
                    map(async (configurations: any) => {
                        if (
                            configurations[ConfigurationLoaderFileType.StdConfiguration] || 
                            configurations[ConfigurationLoaderFileType.Configuration]
                        ) {
                            await LocalstorageHelper.setStorageItem(CONFIGURATION_LOADER_STORAGE_KEY, configurations, undefined, false, false);
                        }                
                        const extendedRes = extend(
                            configurations[ConfigurationLoaderFileType.StdConfiguration], 
                            configurations[ConfigurationLoaderFileType.Configuration]
                        );
                        return ConfigurationManager.Current.config = extendedRes;
                    })
                );
            })
        )
    }
}
import { Injectable } from "@angular/core";
import { SwUpdate, VersionEvent } from "@angular/service-worker";
import { interval, Observable, of, switchMap } from "rxjs";
import { tap} from "rxjs/operators";
import { LogService } from "./log/log.service";
import { OnlineService } from "./offline.service";

export enum UpdateServiceStatusType {
    NoUpdateAvailable = 0,
    Updating = 1,
    UpdatedNeedReload = 2,
    UpdateError = 3
}

export interface UpdateServiceData {
    status: UpdateServiceStatusType,
    event: any;
}

@Injectable()
export class UpdateService {

    constructor(
        private readonly updates: SwUpdate,
        onlineService: OnlineService
    ) {
        if (updates.isEnabled) {

            onlineService.isOnline$.pipe(switchMap((online) => {
                if (online) {
                    // Verifica aggiornamenti ogni ora
                    return interval(60 * 60 * 1000).pipe(tap(() => {
                        LogService.info('Checking for updates...');
                        updates.checkForUpdate();
                    }))
                } else {
                    return of(null);
                }
            })).subscribe();
        }
    }

    get updateNotification(): Observable<VersionEvent> {
        return this.updates.versionUpdates;
    }
}
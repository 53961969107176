import { Expose } from '../../../serialization/class-transformer/decorators';
import { IdentityTypeNameDecorator } from '../../decorators/identity-type-name.decorator';
import { StandardReportIdentity } from '../standard-report/standard-report.identity';

@IdentityTypeNameDecorator('CustomReportItemIdentity')
export class GeneratedCustomReportItemIdentity extends StandardReportIdentity {

	private _customReportItemId: number;

	@Expose()
	get customReportItemId(): number {
		return this.getValue<number>(() => this._customReportItemId, 'customReportItemId');
	}
	set customReportItemId(value: number) {
		this.setValue<number>(() => { this._customReportItemId = value; }, value, 'customReportItemId');
	}
}

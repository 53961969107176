import { Meta } from "@angular/platform-browser";

export class MobileHelper {

    static fixZoomIssue(el: HTMLElement, metaService: Meta) {
        el.addEventListener('touchstart', () => this.zoomDisable(metaService));

        el.addEventListener('touchend', () => setTimeout(() => this.zoomEnable(metaService), 500));
    }

    static zoomEnable(metaService: Meta) {
        metaService.removeTag('name=viewport');
        metaService.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1.0, user-scalable=1, shrink-to-fit=no' });
    }

    static zoomDisable(metaService: Meta) {
        metaService.removeTag('name=viewport');
        metaService.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1.0, user-scalable=0, shrink-to-fit=no' });
    }
}
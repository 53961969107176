import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { GravatarModule } from "ngx-gravatar";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'nts-user-image',
    templateUrl: './user-image.component.html',
    styleUrls: ['./user-image.component.scss'],
    standalone: true,
    imports: [
        GravatarModule
    ]
})
export class UserImageComponent {

    @Input() base64: string|null = null;
    @Input() email = null;
    @Input() size = 32;
    @Input() fallback = 'retro';
}

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Notify } from './models/notify';
import { NotifyService } from './notify.service';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink } from '@angular/router';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgClass } from '@angular/common';

@Component({
  selector: 'nts-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    FontAwesomeModule,
    NgScrollbarModule,
    NgClass
  ]
})
export class NotifyComponent implements OnInit, AfterViewInit {

  notifyList = new Array<Notify>();

  constructor(
    private navbarService: NotifyService,
    faIconLibrary: FaIconLibrary) {
    faIconLibrary.addIconPacks(far);
  }

  ngOnInit() {

    this.notifyList = this.navbarService.getNotifyList();
    // rimango in ascolto se ci sono modifiche alle notifiche
    this.navbarService.notifyChanged.subscribe(value => {
      this.notifyList = this.navbarService.getNotifyList();
    });
  }

  ngAfterViewInit() {
  }

  removeNotify(id: string) {
    this.navbarService.removeNotify(id);
  }

  markAllNotifyAsRead(event: Event) {
    this.navbarService.markAllAsRead();
  }
}

import { BaseError } from '../../messages/base-error';
import { Information } from '../../messages/information';
import { MessageButton } from './message-button';
import { MessageResult } from './message-result';
import { ModalViewModelInterface } from './modal-view-model.interface';
import { ModalResult } from './modal-result';
import { Injectable } from '@angular/core';
import { ZoomUIStarterArgs } from '../zoom/zoom-ui-starter-args';
import { ZoomResult } from '../../domain-models/zoom/zoom-result';
import { MetaDataDescriptions } from '../../meta-data/meta-data-descriptions';
import { MatDialogConfig } from '@angular/material/dialog';
import { CoreOrchestratorViewModelInterface } from '../core-orchestrator-view-model.interface';
import { ActivatedRoute } from '@angular/router';


@Injectable()
export abstract class ModalService {

    ovm: CoreOrchestratorViewModelInterface;
    route?: ActivatedRoute;

    abstract showStandardModalAsync(
        modalButtons: MessageButton,
        title: string,
        viewModel: ModalViewModelInterface<any, void>,
        options?: any,
        metaDataDescriptionsButtons?: MetaDataDescriptions[]
    ): Promise<MessageResult>;

    abstract showCustomModalWithResultAsync<TResult>(
        viewModel: ModalViewModelInterface<any, TResult>, 
        showCancel?: boolean, 
        maximizable?: boolean, 
        maximized?: boolean, 
        config?: MatDialogConfig<any>,
        isCentered?: boolean
    ): Promise<ModalResult<TResult>>;

    abstract showExternalModal<TResult>(
        url: string,
        jsonIdentity: string, 
        additionalQueryParams?: URLSearchParams,
        modalTitle?: string, 
        externalReturn?: boolean, 
        supportRemoteClosingCheck?: boolean,
        customExternalModalViewModel?: ModalViewModelInterface<any, TResult>,
        supportRemoteStatus?: boolean
    ): Promise<ModalResult<TResult>>

    abstract showZoomAsync(viewModel: ModalViewModelInterface<ZoomUIStarterArgs, ZoomResult>, maximizable?: boolean): Promise<ModalResult<ZoomResult>>;

    abstract showMessageAsync(title: string, message: string, buttons: MessageButton, metaDataDescriptionsButtons?: MetaDataDescriptions[]): Promise<MessageResult>;

    abstract showExceptionPopUp(error: Error, caption?: string, message?: string, metaDataDescriptionsButton?: MetaDataDescriptions);

    abstract showPopUp(informations: Array<Information>, errors: Array<BaseError>, caption?: string, metaDataDescriptionsButton?: MetaDataDescriptions): Promise<MessageResult>;

}

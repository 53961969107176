import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorNotificationService } from '@nts/std/src/lib/utility';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { BaseError } from '../../../messages/base-error';
import { MessageResourceManager } from '../../../resources/message-resource-manager';
import { UIStarter } from '../../../starter/ui-starter';
import { ComponentErrorInterface } from '../component-error.interface';
import { filter, take } from 'rxjs';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { FilledButtonComponent } from '../../shared/buttons/filled-button/filled-button.component';
import { NgFor, NgIf } from '@angular/common';
import { NTSTranslatePipe } from '../../pipe/nts-translation-pipe';

@Component({
    selector: 'nts-internal-server-error-page',
    templateUrl: './internal-server-error.component.html',
    styleUrls: ['./internal-server-error.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        SvgIconComponent,
        FilledButtonComponent,
        NTSTranslatePipe,
        NgIf,
        NgFor
    ]
})
export class InternalServerErrorComponent implements ComponentErrorInterface, OnInit {

    // popolata tramite injecton
    errors: BaseError[];
    errorTitle: string;
    errorDescription: string;
    errorDetailTitle: string;
    errorDetailDescription: string;
    errorDetailMoreDescription: string;
    showDetailMore = false;
    expandDetailMore = false;
    showMoreLimit = 0; // 0 disabilitato

    constructor(
        public readonly environmentConfiguration: EnvironmentConfiguration,
        private readonly router: Router,
        private readonly cd: ChangeDetectorRef,
        private errorNotificationService: ErrorNotificationService,
        
    ) {
        this.errorTitle = MessageResourceManager.Current.getMessage('std_PageError500_Title');
        this.errorDescription = MessageResourceManager.Current.getMessage('std_PageError500_Description');
        this.errorDetailDescription = MessageResourceManager.Current.getMessage('std_PageError500_DetailDescription');       
    }

    updateErrorData() {
        if (this.errorDetailDescription.length > this.showMoreLimit && this.showMoreLimit > 0) {
            this.errorDetailMoreDescription = this.errorDetailDescription.substring(this.showMoreLimit, this.errorDetailDescription.length);
            this.errorDetailDescription = this.errorDetailDescription.substring(0, this.showMoreLimit);
        }
        this.cd.markForCheck();
    }
    
    ngOnInit(): void {
        this.updateErrorData();

        // se viene inizializzata la pagina senza error mi metto in ascolto degli errori della notification
        if (!this.errors) {
            this.errorNotificationService.$error
            .pipe(
                filter((err) => err != null),
                take(1)
            )
            .subscribe(({error, uuid}) => {
                
                const singleError = new BaseError();
                singleError.description = error.message ? error.message : error.toString()
                singleError.stackTrace = error.stack;
                singleError.uuid = uuid;
                
                this.errors = [singleError];
                this.errorDetailDescription =  singleError.description;
                this.updateErrorData();
            })
        }       
    }

    redirectToDashBoard(): void {
        if (this.environmentConfiguration.production === true) {
            UIStarter.redirectToDashboard();
        } else {
            this.router.navigate(['/']);
        }
    }

    toggleShowMore(): void {
        this.expandDetailMore = !this.expandDetailMore;
    }
}

import { Expose, Type } from "../../serialization";
import { ReportInfoDto } from "./report-info.dto";

export class MultiReportInfoDto {

    constructor() {
        this.reportInfoList = [];
    }

    @Expose()
    @Type(() => ReportInfoDto)
    reportInfoList: ReportInfoDto[];

}

import { UICommandInterface } from './ui-command.interface';
import { MessageResourceManager } from '../../resources/message-resource-manager';
import { UICommandSettingsManagerInterface } from './ui-command-settings-manager.interface';

export class ReportUICommandSettingsManager implements UICommandSettingsManagerInterface {

    setUICommand(en: string, ui: UICommandInterface): UICommandInterface {

        // Provo ad utilizzare le risorse dedicate nel ms, altrimenti uso quelle di std
        const displayName = MessageResourceManager.Current.getMessageIfExists('CMD_' + en);
        const tooltip = MessageResourceManager.Current.getMessageIfExists('CMD_' + en + '_Tooltip');

        ui.displayName = displayName?.length > 0 ? displayName : MessageResourceManager.Current.getMessage('std_CMD_Report');
        ui.description = ui.displayName;
        ui.name = en;
        ui.tooltip = tooltip?.length > 0 ? tooltip : MessageResourceManager.Current.getMessage('std_CMD_Report_Tooltip');;
        ui.iconClass = 'print';
        return ui;
    }
}

import { NgFor, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from "@angular/core";
import { PopperHelper } from "@nts/std/src/lib/utility";
import { NgxPopperjsDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from "ngx-popperjs";

let nextId = 0;

@Component({
    selector: 'nts-base-toggle-text-box',
    templateUrl: './base-toggle-text-box.component.html',
    styleUrls: ['./base-toggle-text-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgxPopperjsModule,
        NgIf,
        NgFor
    ]
})
export class BaseToggleTextBoxComponent {
    @Input() label = '';
    @Input() tabIndex = -1;
    @Input() isDisabled = false;
    @Input() checkStatus = false;
    @Input() customClasses = '';
    @Input() errorList: string[] = [];
    @Input() showErrorTooltip = true;
    @Input() showTitleTooltip = true;
    @Input() showErrorBorder = true;
    @Input() labelText: string;
    @Input() showLabelText = true;
    @Input() primaryColor = null;
    @Input() isReadOnly = false;

    @Output() onChange = new EventEmitter<boolean>();
    @Output() onClick = new EventEmitter<void>();

    @ViewChild('toggleBox', { static: true }) toggleBox: ElementRef;
    @ViewChild('popperInfo', { static: false }) popperInfo: NgxPopperjsDirective;
    @ViewChild('popperError', { static: false }) popperError: NgxPopperjsDirective;

    id = `tog-${nextId++}`;
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;

    constructor(protected cd: ChangeDetectorRef) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['errorList']) {
            this.checkPopper();
        }
    }

    keydownSpace(e: any): void {
        if (!this.isDisabled && !this.isReadOnly) {
            e.preventDefault();
            e.stopPropagation();
            this.toggleValue();
        } else {
            e.preventDefault();
            e.stopPropagation();
        }       
    }

    click(e: any): void {
        if (!this.isDisabled && !this.isReadOnly) {
            e.preventDefault();
            e.stopPropagation();
            this.toggleValue();
        } else {
            e.preventDefault();
            e.stopPropagation();
        }
        this.onClick.emit();
    }

    toggleValue(): void {
        if (!this.isDisabled && !this.isReadOnly) {
            this.checkStatus = !this.checkStatus;
            this.toggleBox.nativeElement.checked = this.checkStatus;
            this.onChange.emit(this.checkStatus);
            this.cd.detectChanges();
        }
    }
    
    private checkPopper() {
        if (this.popperError && (this.errorList == null || this.errorList.length === 0)) {
            PopperHelper.hide(this.popperError);
        }   
        if (this.popperInfo && this.errorList?.length > 0) {
            PopperHelper.hide(this.popperInfo);
        }
    }
}

import { CoreModel } from '../domain-models/core-model';
import { GenericServiceResponse } from './generic-service-response';
import { BaseIdentity } from '../domain-models/base-identity';
import { ResponseCachedDecorator } from './decorators/response-cached.decorator';


@ResponseCachedDecorator()
export class GetByIdentityCachedResponse<
    TModel extends CoreModel<TIdentity>,
    TIdentity extends BaseIdentity>
    extends GenericServiceResponse<TModel> {

}

import { BaseIdentity, CoreModel } from '../domain-models';
import { MasterViewModelInterface } from './master-view-model.interface';
import { map, Observable, of, startWith } from 'rxjs';
import { CommandTypes } from './commands/command-types';
import { CommandFactory } from './commands/command-factory';
import { ExtendedFilterAwareInterface } from './extended-filter-aware.interface';
import { ItemViewModel } from './item-view-model';
import { SearchResultCollectionViewModel } from './search-result.collection-view-model';
import { UICommandInterface } from './commands/ui-command.interface';
import { MasterViewModelSearchResultCollectionViewModelInterface } from './master-view-model-search-result.collection-view-model.interface';

export class MasterViewModelSearchResultCollectionViewModel<
    TItemViewModel extends ItemViewModel<TModel, TIdentity>,
    TModel extends CoreModel<TIdentity>,
    TIdentity extends BaseIdentity>
    extends SearchResultCollectionViewModel<TItemViewModel, TModel, TIdentity> implements MasterViewModelSearchResultCollectionViewModelInterface<TItemViewModel> {

    toggleExtendedFilterCommand: UICommandInterface;
    
    constructor() {
        super();

        // Typescript 2.1 BREAKING CHANGE
        // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, MasterViewModelSearchResultCollectionViewModel.prototype);

        this.showOpenGridSettingsCommands = false;
        this.showGridFocusCommands = true;
    }

    protected override async executeFocusGridCommand(): Promise<void> {
        await super.executeFocusGridCommand();
        const mvm = this.parent as MasterViewModelInterface;
        mvm.isFullScreen = true;
    }

    protected override async executeExitFocusGridCommand(): Promise<void> {
        await super.executeExitFocusGridCommand();
        const mvm = this.parent as MasterViewModelInterface;
        mvm.isFullScreen = false;
    }

    protected override initCommand() {
        super.initCommand();
        this.toggleExtendedFilterCommand = this.getUICommandDefaultSettingsManager().setUICommand(CommandTypes.ToggleExtendedFilter,
            CommandFactory.createUICommand(
                (x) => this.toggleExtendedFilter(),
                () => of(true),
                null,
                () => this.isVisibleToggleExtendedFilterCommand()
            ));
        this.toggleExtendedFilterCommand.iconClass = 'eye-close';
        this.standardCommandList.set('toggleExtendedFilterCommand', this.toggleExtendedFilterCommand);
    }

    protected async toggleExtendedFilter(): Promise<void> {
        const mvm = (this.parent as any) as ExtendedFilterAwareInterface;
        mvm.showExtendedFilter = !mvm.showExtendedFilter;
        this.toggleExtendedFilterCommand.iconClass = mvm.showExtendedFilter ? 'eye-close' : 'eye-alt';
    }

    protected isVisibleToggleExtendedFilterCommand(): Observable<boolean> {
        const mvm = (this.parent as any) as ExtendedFilterAwareInterface;
        if (mvm) {
            return mvm.propertyChanged.pipe(
                startWith(null),
                map(() => mvm.enableExtendedFilter))
        } else {
            return of(false);
        }
    }

    protected override onCollectionChanged() {
        // Non deve fare niente al cambio della collection
    }
}

import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NumericZoomPropertyViewModel } from '../../../../view-models/zoom/property-view-model/numeric-zoom-property-view-model';
import { BaseZoomFilterPropertyTextBox } from '../base-zoom-filter-property-text-box';
import { BaseNumericBoxComponent, NumericMaskSettings } from '../../../controls/core/base/base-numeric-box/base-numeric-box.component';
import { BaseNumericPropertyViewModel } from '../../../../view-models/base-type/base-numeric-property-view-model';
import IMask from 'imask';
import { BaseChipsBoxComponent } from '../../../controls/core/base/base-chips-box/base-chips-box.component';
import { NgIf } from '@angular/common';
import { AllowedChars } from '../../../../meta-data/allowed-chars';
import { TextValidator } from '../../../../domain-models/decorators/text.decorator';
import { ZoomFilterViewModel } from '../../../../view-models/zoom/filter-view-model/zoom-filter-view-model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FilterOperators } from '../../../../domain-models/find-options/filter';
import { NumericZoomFilterViewModel } from '../../../../view-models/zoom/filter-view-model/numeric-zoom-filter-view-model';

@UntilDestroy()
@Component({
  selector: 'nts-zoom-filter-numeric-text-box',
  styleUrls: ['./zoom-filter-numeric-text-box.component.scss'],
  templateUrl: './zoom-filter-numeric-text-box.component.html',
  standalone: true,
  imports: [
    BaseNumericBoxComponent,
    BaseChipsBoxComponent,
    NgIf
  ]
})
export class ZoomFilterNumericTextBoxComponent extends BaseZoomFilterPropertyTextBox<number> implements OnInit, AfterViewInit, OnChanges {

  @Input() filter: NumericZoomFilterViewModel;

  @ViewChild('baseNumericBox', { static: false }) baseNumericBox: BaseNumericBoxComponent;
  @ViewChild('baseChipsBox', { static: false }) baseChipsBox: BaseChipsBoxComponent;
  
  override propertyViewModel: NumericZoomPropertyViewModel;
  inputMaskSettings: NumericMaskSettings;
  maxValue: number;
  minValue: number;
  valueForChips = [];
  digits: number;
  useChips: boolean = false;

  get formattedValue(): string {
    if (this.propertyViewModel.value == null) { return null; }
    return this.propertyViewModel.value.toString().replace('.', BaseNumericPropertyViewModel.getDecimalSeparator());
  }

  protected get input(): HTMLInputElement {
    if (this.useChips) {
        return this.baseChipsBox.chipsBox.inputViewChild.nativeElement;
    }
    return this.baseNumericBox.numericBox.nativeElement;
  }

  get inputValue(): number {
    if (this.input) {
        if (this.baseNumericBox?.mask != null) {
            const mask = IMask.createMask({
                ...this.baseNumericBox.getMaskedNumberOptions(this.baseNumericBox.maskSettings) as any,
                mapToRadix: []
            });
            mask.resolve(this.input.value);
            return mask.typedValue;
        } else {
            return this.input.value as any;
        }            
    } else {
        return null;
    }
  } 

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  override ngOnInit() {
    if (!this.propertyViewModel) { throw new Error('Missing viewModel!'); }
    this.digits = BaseNumericPropertyViewModel.getDecimalDigits(this.propertyViewModel.propertyName, this.propertyViewModel.integerLimit, this.propertyViewModel.decimalLimit);
    const integerDigits = BaseNumericPropertyViewModel.getIntegerDigits(this.propertyViewModel.propertyName, this.propertyViewModel.integerLimit, this.propertyViewModel.decimalLimit);
    this.maxValue = this.calculateMaxValue(this.digits, integerDigits);
    this.minValue = this.calculateMinValue(this.digits, integerDigits);

    this.inputMaskSettings = {
      decimalLimit: this.propertyViewModel.decimalLimit,
      integerLimit: this.propertyViewModel.integerLimit,
      useThousandSeparator: this.propertyViewModel.useThousandSeparator,
      nullable: true,
      min: this.minValue, // minimum value
      max: this.maxValue, // maximum value
      // digitsOptional: true,
    }

    // this.inputMaskSettings = {
    //   alias: 'numeric',
    //   allowMinus: true,
    //   allowPlus: false,
    //   unmaskAsNumber: true,
    //   radixPoint: BaseNumericPropertyViewModel.getDecimalSeparator(),
    //   groupSeparator: this.propertyViewModel.decimalLimit ? BaseNumericPropertyViewModel.getThousandSeparator() : '',
    //   SetMaxOnOverflow: false,
    //   showMaskOnHover: false,
    //   digits, //: this.propertyViewModel.decimalLimit ? this.propertyViewModel.decimalLimit : 0,
    //   digitsOptional: true,
    //   prefix: '',
    //   min: this.minValue, // minimum value
    //   max: this.maxValue, // maximum value
    //   clearMaskOnLostFocus: false,
    //   placeholder: '',
    //   nullable: true,
    //   positionCaretOnClick: 'none',
    // };

    this.filter.isEnableStatusChanged.pipe(untilDestroyed(this)).subscribe(() => {
      this.cd.detectChanges();
    });

    if (this.filter) {
      this.filter.operator.operatorChanged.pipe(untilDestroyed(this)).subscribe(() => {
        this.checkChips();
        // setTimeout(() =>this.setFocus(), 200);
        this.cd.detectChanges();
      })
      this.checkChips();
    }
    
    this.cd.detectChanges();
  }

  setFocus() {
    this.input.focus();
  }

  ngAfterViewInit(): void {   
    super.ngOnInit();    
  }

  checkChips() {
    this.useChips = this.filter.operator.currentValue === FilterOperators.In;
    if (this.useChips) {
      setTimeout(() =>this.updateEventRegistration());
    }
    if (this.useChips) {
      this.valueForChips = this.filter.filterValues.map((v) =>v.value);
    }
  }

  updateEventRegistration() {
    this.registerEvents();
    (this.input as HTMLInputElement).onkeypress = (e) => {
      const charCode = (typeof e.which == null) ? e.keyCode : e.which;
      const charStr = String.fromCharCode(charCode);
          const allowedCharacters = new TextValidator();
          if (
              this.digits > 0 ? 
              !allowedCharacters.validateAllowedChars(charStr, AllowedChars.DecimalOnly) : 
              !allowedCharacters.validateAllowedChars(charStr, AllowedChars.NumbersOnly
          )) {
              return false;
          }
      return null;
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }

  calculateMinValue(decimalDigits: number, integerDigits: number): number {
    // const integerDigitsString = "9".repeat(integerDigits);
    // let decimalDigitsString = "";
    // if (decimalDigits > 0) {
    //   decimalDigitsString = "." + "9".repeat(decimalDigits);
    // }
    // return parseFloat(integerDigitsString + decimalDigitsString);
    return 0;
  }

  calculateMaxValue(decimalDigits: number, integerDigits: number): number {
    const integerDigitsString = "9".repeat(integerDigits);
    let decimalDigitsString = "";
    if (decimalDigits > 0) {
      decimalDigitsString = "." + "9".repeat(decimalDigits);
    }
    return parseFloat(integerDigitsString + decimalDigitsString);
  }

  selectAllContent($event: FocusEvent) {
    setTimeout(() => {
      const tgt = (<HTMLInputElement>$event.target);
      if (tgt === document.activeElement) {
        (<HTMLInputElement>$event.target).select();
      }
    }, 50);
  }

  protected override updateModel() {
    if (this.useChips) {
      // if (this.propertyViewModel.values !== this.valueForChips) {
      //   this.filter.set
      //   this.propertyViewModel.setValues(this.valueForChips);
      // }
      this.filter.setZoomPropertyViewModelValuesFromEntities(this.valueForChips)
    } else {
      super.updateModel();
    }
  }

  valueChange(val: any[]) {

    this.valueForChips = val;

    // if (this.propertyViewModel.metaDataAllowedCharacters !== AllowedChars.Any) {
    //   const casingVal = this.handleCharacterCasing(val);
    //   if (casingVal !== val) {
    //     this.input.value = casingVal;
    //   }

    //   const allowedCharacters = new TextValidator();
    //   const result = allowedCharacters.validateAllowedChars(casingVal, this.propertyViewModel.metaDataAllowedCharacters);
    // }
  }
}

export enum CloneTypes {
        
        /**
         * Non è un clone, external classiche
         */
        None = 0,
        
        /**
         * è un clone interno all'aggregato, i dati dell'external saranno replicati nel modello
         */
        LocalClone = 1,

        /**
         * il modello remoto viene replicato all'interno del microservizio
         */
        LocalReplica = 2
}
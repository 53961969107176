export enum AllowedChars {

    /**
     * Qualsiasi carattere
     */
    Any = 0,

    /**
     * Solamente numeri senza virgola
     */
    NumbersOnly = 1,

    /**
     * Qualsiasi lettera maiuscola o numeri
     */
    UppercaseLettersOrNumbersOnly = 2,

    /**
     * Qualsiasi lettera maiuscola
     */
    UppercaseLettersOnly = 3,

    /**
     * Qualsiasi lettera minuscola o numeri
     */
    LowercaseLettersOrNumbersOnly = 4,

    /**
     * Qualsiasi lettera maiuscola
     */
    LowercaseLettersOnly = 5,

    /**
     * Solamente numeri che possono avere anche la virgola
     */
    DecimalOnly = 90,
}

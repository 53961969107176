import { CoreModel } from '../domain-models/core-model';
import { GenericServiceResponse } from './generic-service-response';
import { ResponseCachedDecorator } from './decorators/response-cached.decorator';
import { BaseIdentity } from '../domain-models';

@ResponseCachedDecorator()
export class CreateResponse<
    TModel extends CoreModel<TIdentity>,
    TIdentity extends BaseIdentity>
    extends GenericServiceResponse<TModel> {

}

import { EventEmitter } from '@angular/core';
import { RelayCommand } from './relay-command';
import { ResultCommandInterface } from './result-command.interface';
import { Observable, of } from 'rxjs';

export class ResultCommand<TResult, TParameter = any> extends RelayCommand implements ResultCommandInterface<TResult, TParameter> {

    override executed: EventEmitter<TResult> = new EventEmitter<TResult>();
    closeModal = true;
    onlyInMoreOptions: boolean;
    
    protected override readonly _execute: (x: TParameter, y?: any) => Promise<TResult>;
    protected readonly _isHighlighted: (x: any) => Observable<boolean>;

    constructor(
        canExecute: () => Observable<boolean>,
        execute: (x: TParameter, y?: any) => Promise<TResult>,
        isHighlighted: (x: TParameter) => Observable<boolean> = null,
        isVisible: () => Observable<boolean> = null
    ) {
        super(canExecute, execute, isVisible);
        this._isHighlighted = isHighlighted;
    }

    override async execute(parameter: TParameter, y?: any): Promise<TResult> {
        const result = await this._execute(parameter, y);
        this.executed.emit(result);
        return result;
    }

    isHighlighted(parameter: any): Observable<boolean> {
        if (this._isHighlighted == null) {
            return of(false);
        }
        return this._isHighlighted(parameter);
    }
}

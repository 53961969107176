<nts-base-date-text-box 
    #baseDateTextBox 
    (onTimeZoneChange)="onTimeZoneChange($event)"
    (onDateValueChange)="onDateValueChange($event)"
    (onDateFocus)="onDateFocus($event)"
    (onDateSelect)="onDateSelect($event)"
    (onDateBlur)="onDateBlur()"
    (onTimeZoneBlur)="onTimeZoneBlur()"
    [valueDate]="valueDate"
    [appendTo]="appendTo" 
    [isRequired]="!isNullable"
    [showTime]="propertyViewModel.showTime"
    [showButtonBar]="showButtonBar"
    [isDisabled]="!propertyViewModel.enabled"
    [showOffset]="showOffset"
    [showTimeZone]="true"
    [valueTimeZone]="valueTimeZone" 
    [timeZoneOptions]="propertyViewModel.timeZones"
    (onFinishEditing)="onFinishEditing.emit($event)"></nts-base-date-text-box>
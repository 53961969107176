import { Component } from "@angular/core";
import { FieldTypes } from "../../../../layout-meta-data/field-types.enum";
import { BaseFieldComponentInterface } from "./base-field.component.interface";

@Component({
    template: ''
})
export abstract class BaseFieldComponent implements BaseFieldComponentInterface {
    type: FieldTypes;
    additionalField = false;
    isHidden: boolean;
    abstract get path(): string;
    abstract get name(): string;
    abstract get fullColumn(): boolean;
    abstract get description(): string;
    abstract get displayName(): string;
    abstract get isDisabled(): boolean;
}

import { Inject, Injectable, Optional } from "@angular/core";
import { ActivatedRoute, GuardsCheckStart, NavigationStart, Router, RouterStateSnapshot } from "@angular/router";
import { RoutingService } from "./routing.service";

let currentRouteServiceInstanceCounter = 0;

@Injectable({
    providedIn: 'root'
})
export class CurrentRouteService {

    private internalIsAuthenticatedRoute: boolean;

    get isAuthenticatedRoute() {
        return this.internalIsAuthenticatedRoute;
    }

    setAuthenticatedRouteFromGuard() {
        this.internalIsAuthenticatedRoute = true;
    }

    constructor(
        router: Router
    ) {
        currentRouteServiceInstanceCounter++;

        if (currentRouteServiceInstanceCounter > 1) {
            throw new Error('CurrentRouteService should be a singleton!')
        }

        router.events.subscribe(evt => { 
            if (evt instanceof NavigationStart) {
              this.internalIsAuthenticatedRoute = false;
            }
        });
    }
}
import { ValidationArguments } from "class-validator";
import { DomainModelCollectionInterface } from "../../domain-model-collection.interface";
import { BaseValidation } from "./base-validation";
import { MessageCodes } from "../../../resources/message-codes";
import { BaseValidator } from "../commons/base-validator";
import { ModelTypeInspector } from "../model-type.decorator";
import { MessageResourceManager } from "../../../resources/message-resource-manager";

export class UniqueValidation extends BaseValidation<DomainModelCollectionInterface> {

    private uniqueFields: string[];

    constructor(uniqueFields: string[]) {
        super();
        this.uniqueFields = uniqueFields;
    }

    validateImplementation(value: DomainModelCollectionInterface, args: ValidationArguments): boolean {
        if (this.uniqueFields != null && this.uniqueFields?.length > 0) {

            // Rimappo la collection con i campi che devo tenere in considerazione (uniqueFields)
            const mappedArr = value.collectionItems.map((i) => 
                this.uniqueFields
                    .map((f) =>i[f])
                    .join()
                    .toLowerCase() // handle case insensitive #6361
            )

            // La collection ha valori duplicati
            if (mappedArr.length !== new Set(mappedArr).size) {
                this.errorMessage = this.getMessageWithCodeValues(this.uniqueFields.length > 1 ? MessageCodes.PropertyCollectionUniqueFields :  MessageCodes.PropertyCollectionUniqueField, [
                    {
                        code: MessageCodes.PropertyCollectionUniqueFields_TAG_NomeCollection,
                        value: this.getDisplayName(args)
                    },
                    {
                        code: MessageCodes.PropertyCollectionUniqueFields_TAG_ListaColonne,
                        value: this.uniqueFields.map((f) => {
                            const itemModelType =  ModelTypeInspector.getValue(args.object[args.property])
                            const propertyMetaData = BaseValidator.getPropertyMetaData(itemModelType, f);
                            if (propertyMetaData?.descriptions?.displayName?.length > 0) {
                                return propertyMetaData?.descriptions?.displayName;
                            }

                            if (propertyMetaData?.descriptions?.displayNameKey?.length > 0) {
                                return MessageResourceManager.Current.getMessage(propertyMetaData?.descriptions?.displayNameKey)
                            }
                            return f;
                        }).join(", ")
                    },
                ]);
                
                
                return false;
            }
        }
        return true;
    }
}
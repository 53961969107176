import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BaseZoomFilterPropertyTextBox } from '../base-zoom-filter-property-text-box';
import { StringZoomPropertyViewModel } from '../../../../view-models/zoom/property-view-model/string-zoom-property-view-model';
import { AllowedChars } from '../../../../meta-data';
import { CharacterCasing } from '../../../../view-models/base-type/string-property-view-model';
import { TextValidator } from '../../../../domain-models/decorators/text.decorator';
import { BaseTextBoxComponent } from '../../../controls/core/base/base-text-box/base-text-box.component';
import { JsonPipe, NgClass, NgIf } from '@angular/common';
import { BaseChipsBoxComponent } from '../../../controls/core/base/base-chips-box/base-chips-box.component';
import { FilterOperators } from '../../../../domain-models/find-options/filter';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StringZoomFilterViewModel } from '../../../../view-models/zoom/filter-view-model/string-zoom-filter-view-model';

interface MaskSettings {
  mask: string;
  placeholderChar: string;
  overwrite?: boolean|'shift';
  lazy?: boolean;
  eager?: boolean;
  hideMaskOnBlur?: boolean;
}

export function createCharsetValidator(
  allowedChars: AllowedChars,
  characterCasing: CharacterCasing
) {
  return function validateCharset(c: UntypedFormControl) {
    const err = {
      charset: {
        valid: false
      }
    };

    const handleCharacterCasing = (text: string) => {
      switch (this.propertyViewModel.metadataCharacterCasing) {
        case CharacterCasing.Lower:
          return text.toLowerCase();
        case CharacterCasing.Upper:
          return text.toUpperCase();
        default:
          return text;
      }
    };

    const allowedCharacters = new TextValidator();
    const result = allowedCharacters.validateAllowedChars(handleCharacterCasing(c.value), this.propertyViewModel.metaDataAllowedCharacters);
    return !result ? err : null;
  };
}

@UntilDestroy()
@Component({
  selector: 'nts-zoom-filter-string-text-box',
  styleUrls: ['zoom-filter-string-text-box.component.scss'],
  templateUrl: './zoom-filter-string-text-box.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    BaseTextBoxComponent,
    BaseChipsBoxComponent,
    NgClass,
    NgIf,
    JsonPipe
  ]
})
export class ZoomFilterStringTextBoxComponent extends BaseZoomFilterPropertyTextBox<String> implements OnInit, AfterViewInit, OnChanges {
  
  @Input() maskSettings: MaskSettings;
  @Input() filter: StringZoomFilterViewModel;
  @Input() validateChipsFunc: (valueToValidate: string) => boolean = null;

  @ViewChild('baseTextBox', { static: false }) baseTextBox: BaseTextBoxComponent;
  @ViewChild('baseChipsBox', { static: false }) baseChipsBox: BaseChipsBoxComponent;
    
  override propertyViewModel: StringZoomPropertyViewModel;
  validateFn: Function;
  useChips: boolean = false;
  valueForChips = [];

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  protected get inputValue() {
    if (this.useChips) {
      const newValue = this.valueForChips?.join(';');
      return newValue;
    }
    return (<HTMLInputElement>this.baseTextBox.textBox.nativeElement).value;
  }

  protected get input(): HTMLInputElement {
    if (this.useChips) {
      return this.baseChipsBox.chipsBox.inputViewChild.nativeElement;
    } else {
      return this.baseTextBox.textBox.nativeElement;
    }
  }

  override ngOnInit() {
    if (!this.propertyViewModel) { throw new Error('Missing viewModel!'); }
    this.validateFn = createCharsetValidator(this.propertyViewModel.metaDataAllowedCharacters, this.propertyViewModel.metaDataCharacterCasing);
    this.checkChips();
    this.filter.operator.operatorChanged.pipe(untilDestroyed(this)).subscribe(() => {
      this.checkChips();

      // // Imnposto il focus al cambio solo se sono nel filterValue
      // if (this.filter.filterValue === this.propertyViewModel) {
      //   setTimeout(() =>this.setFocus(), 200);
      // }
      
      this.cd.detectChanges();
    })
    this.filter.isEnableStatusChanged.pipe(untilDestroyed(this)).subscribe(() => {
      this.cd.detectChanges();
    });
    this.cd.detectChanges();
  }

  setFocus() {
    this.input.focus();
  }

  checkChips() {
    this.useChips = this.filter.operator.currentValue === FilterOperators.In;
    if (this.useChips) {
      setTimeout(() =>this.updateEventRegistration());
    }
    if (this.useChips) {
      this.valueForChips = this.filter.filterValues.map((v) =>v.value);
    }
  }

  ngAfterViewInit(): void {   
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  updateEventRegistration() {
    this.registerEvents();
  }

  valueChange(val: string) {
    if (this.propertyViewModel.metaDataAllowedCharacters !== AllowedChars.Any) {
      const casingVal = this.handleCharacterCasing(val);
      if (casingVal !== val) {
        this.input.value = casingVal;
      }
      const allowedCharacters = new TextValidator();
      const result = allowedCharacters.validateAllowedChars(casingVal, this.propertyViewModel.metaDataAllowedCharacters);
    }
  }

  valueChangeForChips(val: string[]) {
    this.valueForChips = val;
  }

  protected override updateModel() {
    if (this.useChips) {
      this.filter.setZoomPropertyViewModelValuesFromEntities(this.valueForChips)   
    } else {
      super.updateModel();
    }
  }

  private handleCharacterCasing(text: string) {
    switch (this.propertyViewModel.metaDataCharacterCasing) {
      case CharacterCasing.Lower:
        return text.toLowerCase();
      case CharacterCasing.Upper:
        return text.toUpperCase();
      default:
        return text;
    }
  }
}

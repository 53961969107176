import { BehaviorSubject } from "rxjs";

export interface ClientConfigurationInterface {
    [key: string]: any
}

export class ClientConfigurationManager {

    private static instance: ClientConfigurationManager;
    config: ClientConfigurationInterface|null = null;
    loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    static get Current(): ClientConfigurationManager {
        if (this.instance == null) {
            if (this.instance == null) {
                this.instance = new ClientConfigurationManager();
            }
        }
        return this.instance;
    }
}

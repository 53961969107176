import { ReaderHttpCommunication } from '../reader-http-communication';
import { ServiceResponse } from '../../responses/service-response';
import { Observable } from 'rxjs';
import { RemoveRequest } from '../../requests/remove-request';
import { BaseIdentity, CoreModel } from '../../domain-models';
import { StoreRequest } from '../../requests/store-request';
import { StoreResponse } from '../../responses/store-response';
import { CreateResponse } from '../../responses/create-response';
import { CreateRequest } from '../../requests/create-request';
import { CacheOptionsInterface } from '../web-api-service-agent';
import { HttpHeaders } from '@angular/common/http';
import { PinIdentityToDashboardDto } from '../../requests/pin-identity-to-dashboard.dto';
import { GenericServiceRequest } from '../../requests/generic-service-request';

export class CrudHttpCommunication extends ReaderHttpCommunication {

    private static readonly createRequestUri: string = 'Create';
    private static readonly createMLRequestUri: string = 'CreateML';
    private static readonly removeRequestUri: string = 'Remove';
    private static readonly storeRequestUri: string = 'Store';
    private static readonly pinIdentityToDashboardRequestUri: string = 'PinIdentityToDashboard';

    create<TModel extends CoreModel<TIdentity>, TIdentity extends BaseIdentity>(
        modelType: any,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,
            expirationTime: undefined,
            force: false,
        },
        customHeaders: HttpHeaders = null,
    ): Observable<CreateResponse<TModel, TIdentity>> {
        const responseInstance = new CreateResponse<TModel, TIdentity>(modelType);
        return this.agent.invokePostWithResponseWithInstance<
            CreateRequest, CreateResponse<TModel, TIdentity>>(
                CrudHttpCommunication.createRequestUri, 
                new CreateRequest(), 
                responseInstance,
                false,
                customHeaders,
                null,
                null,
                cacheOptions
            );
    }

    createML<
        TModel extends CoreModel<TIdentity>, TIdentity extends BaseIdentity>(
            modelType: any,
            cacheOptions: CacheOptionsInterface = {
                bypass: false,
                expirationTime: undefined,
                force: false,
            },
        ): Observable<CreateResponse<TModel, TIdentity>> {
        const responseInstance = new CreateResponse<TModel, TIdentity>(modelType);
        return this.agent.invokePostWithResponseWithInstance<CreateRequest, CreateResponse<TModel, TIdentity>>(
            CrudHttpCommunication.createMLRequestUri, 
            new CreateRequest(), 
            responseInstance,
            false,
            null,
            null,
            null,
            cacheOptions
        );
    }

    remove<TIdentity extends BaseIdentity>(request: RemoveRequest<TIdentity>): Observable<ServiceResponse> {
        return this.agent.invokePostWithResponse<
            RemoveRequest<TIdentity>, ServiceResponse>(
                CrudHttpCommunication.removeRequestUri, request, ServiceResponse);
    }

    store<TModel extends CoreModel<TIdentity>, TIdentity extends BaseIdentity>(
        request: StoreRequest<TModel>, modelType: any): Observable<StoreResponse<TModel, TIdentity>> {
        const responseInstance = new StoreResponse<TModel, TIdentity>(modelType);
        return this.agent.invokePostWithResponseWithInstance<
            StoreRequest<TModel>, StoreResponse<TModel, TIdentity>>(
                CrudHttpCommunication.storeRequestUri, request, responseInstance);
    }

    pinIdentityToDashboard<TIdentity extends BaseIdentity>(request: GenericServiceRequest<PinIdentityToDashboardDto<TIdentity>>): Observable<ServiceResponse> {
        return this.agent.invokePostWithResponse<
            GenericServiceRequest<PinIdentityToDashboardDto<TIdentity>>, ServiceResponse>(
                CrudHttpCommunication.pinIdentityToDashboardRequestUri, request, ServiceResponse);
    }
    
}

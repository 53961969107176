import { OCCModel } from './occ-model';
import { SingleAggregateIdentity } from './single-aggregate-identity';
import { NumberDecorator } from './decorators/number.decorator';
import { Expose } from '../serialization/class-transformer/decorators';


export class SingleAggregateModel<TSingleAggregateIdentity extends SingleAggregateIdentity> extends OCCModel<TSingleAggregateIdentity> {

    private _fixedIdentity: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'SingleAggregateModel_FixedIdentity' })
    get fixedIdentity(): number {
        return this.getValueForIdentityValue<number>(() => this._fixedIdentity, 'fixedIdentity');
    }
    set fixedIdentity(value: number) {
        this.setValueForIdentityValue<number>(() => { this._fixedIdentity = value; }, value, 'fixedIdentity');
    }

}

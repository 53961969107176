<ng-template *ngIf="propertyViewModel?.securityAccess == null; then baseComponent else securityComponent"></ng-template>

<ng-template #baseComponent>
    <nts-base-radio-button-text-box 
        #baseRadioButtonTextBox 
        (onChange)="onChange($event)" 
        [tabIndex]="tabIndex"
        [showErrorTooltip]="showErrorTooltip"
        [showErrorBorder]="showErrorBorder"
        [hasError]="propertyViewModel.hasErrors"
        [errorList]="propertyViewModel?.errors$ | async"
        [isDisabled]="isDisabled || !propertyViewModel.isEnabled || propertyViewModel?.securityAccess != null"
        [label]="propertyViewModel.metadataDescription" 
        [value]="value" 
        [options]="propertyViewModel.visibleValueDescriptions"
        [isNullable]="isNullable"
        [placeholder]="placeholder"
        >
    </nts-base-radio-button-text-box>
</ng-template>

<ng-template #securityComponent>
    <nts-base-security-text-box #baseSecurityTextBox [accessMode]="propertyViewModel.securityAccess"
        [value]="initialValue != null ? initialValue : propertyViewModel.formattedValue"></nts-base-security-text-box>
</ng-template>

import { Expose } from "../../../serialization/class-transformer/decorators";

export class OutputDataOrderDto {

    @Expose()
    public position: number;

    @Expose()
    public propertyName: string;
    
    @Expose()
    public isVisible: boolean;
    
    @Expose()
    public caption: string;
}
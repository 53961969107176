import { BaseValidator } from "../domain-models/decorators/commons/base-validator";
import { ModelInterface } from "../domain-models/model.interface";
import { AggregateMetaData } from "../meta-data/aggregate-meta-data";
import { PropertyMetaData } from "../meta-data/property-meta-data";
import { CustomPropertyViewModelInspector } from "./decorators/custom-property-view-model.decorator";
import { ModifiedSubscriberInterface } from "./modified-subscriber.interface";
import { PropertyViewModelInitializationInfo } from "./property-view-model-initialization-info";
import { PropertyViewModelInterface } from "./property-view-model.interface";
import { ViewModelEventDispatcher } from "./view-model-event-dispatcher";
import { ViewModelInterface } from "./view-model.interface";

export class PropertyViewModelFactory {

    static createPVMInitializationInfo<TPropertyMetaData extends PropertyMetaData>(
        classInstance: any,
        propertyName: string,
        propertyMetaData: PropertyMetaData,
        parent: any,
        modifiedSubscriber: ModifiedSubscriberInterface,
        eventDispatcher: ViewModelEventDispatcher,
        domainModel: ModelInterface,
        setModel = true,
        setParent = true,
        path = ''
    ): PropertyViewModelInitializationInfo {

        // Verifica se il campo è un custom property view model
        const isCustom = CustomPropertyViewModelInspector.isApplied(parent, propertyName);

        // Recupera il metadato dal view model se è custom altrimenti lo prende dal propertyMetaData passato, fallback propertyMetaData passato
        const calculatedPropertyMetaData = this.buildPropertyMetaData<TPropertyMetaData>(classInstance, propertyName, propertyMetaData, isCustom) || propertyMetaData;

        const pvmi = new PropertyViewModelInitializationInfo();

        // Default customgetter/custom setter for custom pvm
        if (isCustom) {
            pvmi.customGetter = () => {
                return pvmi.customValue;
            }
            pvmi.customSetter = async (newValue) => {
                pvmi.customValue = newValue;
            }
        }

        pvmi.propertyName = propertyName;
        pvmi.propertyMetaData = calculatedPropertyMetaData;
        // pvmi.domainModelMetadata = this.domainModelMetaData;
        pvmi.model = setModel ? domainModel : null;
        pvmi.parent = setParent ? parent : null;
        pvmi.eventDispatcher = eventDispatcher;
        pvmi.isCustom = isCustom;
        pvmi.useMessageResourceKey = (classInstance?.aggregateMetaData as AggregateMetaData)?.useMessageResourceKey ?? true;
        pvmi.modifiedSubscriber = modifiedSubscriber;
        pvmi.path = path;
        return pvmi;
    }

    /**
     * Restituisce il property metadata recuperandolo dai metadati.
     * Se esiste un decoratore custom utilizza quello inidicato nel relativo validatore associato.
     *
     * @template T                          Tipo del property metadata
     * @param propertyName                  Nome del property name
     * @param propertyMetaData              Property Meta Data
     * @param isCustomPVM                   Indicare se il property view model è custom
     */
    static buildPropertyMetaData<T extends PropertyMetaData>(
        classInstance: any,
        propertyName: string,
        propertyMetaData: PropertyMetaData,
        isCustomPVM: boolean,
    ): T {
        if (isCustomPVM) {
            return BaseValidator.getPropertyMetaData<T>(classInstance, propertyName) as T;
        } else {
            const domainModelInstance = classInstance?.getDomainModel();
            if (domainModelInstance) {
                const customDomainModelMetaData = BaseValidator.getPropertyMetaData<T>(domainModelInstance, propertyName);
                if (customDomainModelMetaData) {
                    // merge con il propertyMetaData di provenienza
                    const merged = this.mergeMetadata(propertyMetaData, customDomainModelMetaData);
                    customDomainModelMetaData.set(merged);
                    return customDomainModelMetaData;
                }
            }

            return propertyMetaData as T;
        }
    }

    static mergeMetadata(sourceMetadata, targetMetadata){
        for (let key in targetMetadata) {
            if (targetMetadata.hasOwnProperty(key)) {
                if (sourceMetadata.hasOwnProperty(key) && typeof sourceMetadata[key] === 'object' && typeof targetMetadata[key] === 'object') {
                    sourceMetadata[key] = this.mergeMetadata(sourceMetadata[key], targetMetadata[key]);
                } else {
                    if(targetMetadata[key] != null){
                        sourceMetadata[key] = targetMetadata[key];
                    }
                }
            }
        }
        for (let key in sourceMetadata) {
            if (sourceMetadata.hasOwnProperty(key) && (!targetMetadata.hasOwnProperty(key) || targetMetadata[key] == null)) {
                targetMetadata[key] = sourceMetadata[key];
            }
        }
        return targetMetadata;        
    }

    static async createPropertyViewModel<TPropertyViewModel extends PropertyViewModelInterface>(
        propertyName: string, 
        propertyViewModelType: any, 
        metaData: PropertyMetaData,
        parent: ViewModelInterface,
        modifiedSubscriber: ModifiedSubscriberInterface,
        classInstance: any,
        eventDispatcher: ViewModelEventDispatcher,
        model: ModelInterface,
        setModel = true,
        setParent = true,
        path = ''
    ): Promise<TPropertyViewModel> {
        const initializationInfo = this.createPVMInitializationInfo(classInstance, propertyName, metaData, parent, modifiedSubscriber, eventDispatcher, model, setModel, setParent, path);
        const pvm = new propertyViewModelType(initializationInfo) as TPropertyViewModel;
        return pvm;
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { MessageResourceManager } from '../../resources/message-resource-manager';

@Pipe({
  name: 'NTSTranslate',
  standalone: true
})
export class NTSTranslatePipe implements PipeTransform {

  transform(key: string, args?: string[]): any {
    if (args && args.length > 0) {
      return MessageResourceManager.Current.getMessageWithStrings(key, ...args);
    } else {
      return MessageResourceManager.Current.getMessage(key);
    }
  }
}

import { empty, from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { EnterpriseDataDto } from './dto/enterprise-data.dto';
import { CurrentRouteService } from '../routing/current-route.service';
import { cloneDeep } from 'lodash-es';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private currentRouteService: CurrentRouteService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return from(this.authService.getAccessToken()).pipe(switchMap((accessToken: string) => {

      return from(this.authService.getTenantId(accessToken)).pipe(switchMap((tenantId: number) => {
        
        return from(this.authService.getEnterpriseData(tenantId)).pipe(switchMap((enterpriseData: EnterpriseDataDto) => {
          
          if (accessToken != null && this.currentRouteService.isAuthenticatedRoute) {
            // Get the auth token from the service.
            const authToken = 'Bearer ' + accessToken
      
            // Clone the request and replace the original headers with
            // cloned headers, updated with the authorization.
            // Se l'autenticazione è già presente nella richiesta non la sovrascrive
            req = req.clone({
              headers: req.headers.has(AuthService.AUTHORIZAZION_HEADER_NAME) ? req.headers : req.headers
                .set(AuthService.AUTHORIZAZION_HEADER_NAME, authToken)
            });
          }
  
          if (enterpriseData != null && this.currentRouteService.isAuthenticatedRoute) {
            
            // se la company id non è stata passata la rimuovo prima di passarla al server o non è stata trovata
            if (enterpriseData.companyId === 0 || enterpriseData.companyId === -1) {
              delete enterpriseData.companyId;
            }

            if (enterpriseData.enterpriseId > 0) {
              req = req.clone({
                headers: req.headers.has(AuthService.ENTERPRISE_DATA_HEADER_NAME) ? req.headers : req.headers
                  .set(AuthService.ENTERPRISE_DATA_HEADER_NAME, JSON.stringify(enterpriseData))
              });
            }            
          }
  
          return next.handle(req)
            .pipe(
              catchError(
                (error) => {
                  if (this.isAuthenticationError(error)) {
                    this.authService.notifySessionExpired();
                    // STOP Observable chain
                    return empty();
                  } else {
                    throw error;
                  }
                }
              )
            );
        }))
      }))
    }))
  }

  private isAuthenticationError(error: any): boolean {
    return (error instanceof HttpErrorResponse && error.status === 401);
  }

}

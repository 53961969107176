import { Component, Input, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy, OnChanges, SimpleChanges, ElementRef, EventEmitter, Output, HostBinding, ContentChildren, QueryList, OnInit } from '@angular/core';
import { PropertyViewModelInterface } from '../../../view-models/property-view-model.interface';
import { Subscription, Subject, merge, Observable, of } from 'rxjs';
import { takeUntil, startWith, map, filter } from 'rxjs/operators';
import { MessageCodes } from '../../../resources/message-codes';
import { MessageResourceManager } from '../../../resources/message-resource-manager';
import { BaseFieldComponent } from '../../controls/core/base-field/base-field.component';
import { NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { AsyncPipe, NgFor, NgForOf, NgIf } from '@angular/common';
import { TextButtonComponent } from '../buttons/text-button/text-button.component';
import { CommandFactory, UICommandInterface } from '../../../view-models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'nts-expandable',
  templateUrl: './expandable.component.html',
  styleUrls: ['./expandable.component.scss'],
  standalone: true,
  imports: [
    TextButtonComponent,
    NgxPopperjsModule,
    NgIf,
    NgFor, 
    NgForOf,
    AsyncPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpandableComponent implements OnChanges, OnDestroy, OnInit {

  private _isCollapsed: boolean;
  private toggleAdditionalFieldsCommand: UICommandInterface
  private toggleIsCollapsedCommand: UICommandInterface
  private onChanges$ = new Subject<SimpleChanges>();

  @Input() title = MessageResourceManager.Current.getMessage('std_Expandable_Main_DisplayName');
  @Input() panelId?: string = null;
  @Input() disableAdditionalFields = false;
  @Input() disableToggle = false;
  @Input() showBorder = true;
  @Input() showHeader = true;
  @Input() promotedFields: PropertyViewModelInterface[] = [];
  @Input() commands: UICommandInterface[] = [];
  @Input() defaultCommands: UICommandInterface[] = [];
  @HostBinding('class.isCollapsed')
  @Input()
  set isCollapsed(value: boolean) {
      this.setCollapsed(value);    
  }
  get isCollapsed(): boolean {
    return this._isCollapsed;
  }

  @Output() isCollapsedChange = new EventEmitter<boolean>();

  @Input() isDisabled = false;

  @Output()
  onExpanded: EventEmitter<any> = new EventEmitter();

  @Output()
  onCollapsed: EventEmitter<any> = new EventEmitter();

  @Output()
  onCollapsedAdditionalFields: EventEmitter<void> = new EventEmitter();

  @Output()
  onExpandedAdditionalFields: EventEmitter<void> = new EventEmitter();

  @HostBinding('class.is-hidden')
  @Input() isHidden = false;

  @ContentChildren('expandableChild') expandableChildren: QueryList<BaseFieldComponent>;

  promotedFieldsFiltered: PropertyViewModelInterface[] = [];
  ngxPopperjsTriggers = NgxPopperjsTriggers;
  ngxPopperjsPlacements = NgxPopperjsPlacements;
  
  private _showAdditionalFields = false;
  @Input()
  set showAdditionalFields(value: boolean) {
    this.setAdditionalFieldVisibility(value);
  }
  get showAdditionalFields(): boolean {
    return this._showAdditionalFields;
  }

  // Mostra dati aggiuntivi
  showMoreDescription = MessageResourceManager.Current.getMessage(MessageCodes.ExpandableShowMoreDescription);
  
  // Nascondi dati aggiuntivi
  showLessDescription = MessageResourceManager.Current.getMessage(MessageCodes.ExpandableShowLessDescription);
  
  // Apri pannello
  openPanelDescription = MessageResourceManager.Current.getMessage(MessageCodes.ExpandableOpenPanelDescription);
  
  // Chiudi pannello
  closePanelDescription = MessageResourceManager.Current.getMessage(MessageCodes.ExpandableClosePanelDescription);

  private externalEntityChangedSubscriptionDestroy$: Subject<boolean> = new Subject<boolean>();
  private propertyViewModelChangedSubscriptions: Subscription[] = [];

  constructor(public readonly el: ElementRef, private readonly cd: ChangeDetectorRef) {
    this.toggleAdditionalFieldsCommand = CommandFactory.createUICommand(
      
      // Execute
      () => this.toggleAdditionalFields(),

      // Can
      () => this.canExecuteToggleAdditionalFields(),
      null,

      // Visibility
      () => this.onChanges$.pipe(
        filter((onChanges: SimpleChanges) => onChanges['disableAdditionalFields'] != null), 
        startWith(!this.disableAdditionalFields), 
        map(() => !this.disableAdditionalFields)
      )
    )

    this.toggleIsCollapsedCommand = CommandFactory.createUICommand(

      // Execute
      () => this.toggleIsCollapsed(),

      // Can
      null,

      null,

      // Visibility
      () => this.onChanges$.pipe(
        filter((onChanges: SimpleChanges) => onChanges['disableToggle'] != null), 
        startWith(!this.disableToggle), 
        map(() => !this.disableToggle)
      )

    )

    this.defaultCommands = [
      this.toggleAdditionalFieldsCommand,
      this.toggleIsCollapsedCommand
    ]

   }

  ngOnInit(): void {
   
    this.updateToggleAdditionalFieldDescriptions();
    this.updateToggleAdditionalFieldStyles();
    
    this.updateToggleIsCollapsedDescriptions();
    this.updateToggleIsCollapsedStyles();

    this.cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {

    this.onChanges$.next(changes);

    if (changes['showAdditionalFields']) {
      this.updateToggleAdditionalFieldDescriptions();
      this.updateToggleAdditionalFieldStyles();
    }

    if (changes['isCollpased']) {
      this.updateToggleIsCollapsedDescriptions();
      this.updateToggleIsCollapsedStyles();
    }

    this.externalEntityChangedSubscriptionDestroy$.next(true);

    this.promotedFields.forEach(pvm => {
      this.propertyViewModelChangedSubscriptions.push(
        merge(
          pvm.propertyViewModelChanged,
          pvm.propertyChanged,
          pvm.onFocusRequested,
        )
        .pipe(takeUntil(this.externalEntityChangedSubscriptionDestroy$), startWith(null as any))
        .subscribe(() => {
          this.promotedFieldsFiltered = this.promotedFields.filter((field) => field.formattedValue.length > 0);
          this.cd.detectChanges();
        })
      );
    });
  }

  canExecuteToggleAdditionalFields(): Observable<boolean> {
    return this.isCollapsedChange.pipe(startWith(this.isCollapsed), map((isCollapsed) => !isCollapsed));
  }

  async toggleIsCollapsed() {
    this.isCollapsed = !this.isCollapsed
    this.updateToggleIsCollapsedDescriptions();
    this.updateToggleIsCollapsedStyles();
  }

  async toggleAdditionalFields() {
    if (!this.isCollapsed) {
      this.showAdditionalFields = !this.showAdditionalFields;
    }
    this.updateToggleAdditionalFieldDescriptions();
    this.updateToggleAdditionalFieldStyles();
  }

  updateToggleAdditionalFieldDescriptions() {
    this.toggleAdditionalFieldsCommand.tooltip$.next(this.showAdditionalFields ? this.showLessDescription : this.showMoreDescription)
  }

  updateToggleIsCollapsedDescriptions() {
    this.toggleIsCollapsedCommand.tooltip$.next(this.isCollapsed ? this.openPanelDescription : this.closePanelDescription)
  }

  updateToggleAdditionalFieldStyles() {
    this.toggleAdditionalFieldsCommand.iconClass$.next(this.showAdditionalFields ? 'eye-close' : 'eye-alt')
  }

  updateToggleIsCollapsedStyles() {
    this.toggleIsCollapsedCommand.iconClass$.next(this.isCollapsed ? 'de-compress' : 'compress')
  }

  setAdditionalFieldVisibility(show: boolean) {
    this._showAdditionalFields = show;
    if (this.showAdditionalFields) {
      this.onExpandedAdditionalFields.emit();
    } else {
      this.onCollapsedAdditionalFields.emit();
    }
    this.cd.detectChanges();
  }

  setCollapsed(isCollapsed: boolean) {
    if (this.disableToggle) {
      return;
    }
    this._isCollapsed = isCollapsed;
    if (isCollapsed) {
      this.onCollapsed.emit();
    } else {
      this.onExpanded.emit();
    }
    this.isCollapsedChange.emit(isCollapsed);
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    // Gestione specifica degli externalEntityChanged observables
    this.externalEntityChangedSubscriptionDestroy$.next(true);
    this.externalEntityChangedSubscriptionDestroy$.unsubscribe();
  }
}

import { FindValuesOptions } from './find-values-options';

export class ZoomOptions extends FindValuesOptions {

    constructor() {
        super();

        this.take = 200;
        this.skip = 0;
    }
}

<div class="ms-FocusZone css-159" data-focuszone-id="FocusZone70">
        <nav class="nav" role="navigation">
                <ul class="links" aria-label="Main website navigation">
                        <li *ngFor="let menu of menuList | listFilter: searchModel" class="link"
                                [class.isActive]="menu === currentMenu" [class.hasActiveChild]="menu.hasActiveChild">
                                <a (click)="onSelect(menu)" [ngSwitch]="searchModel?.length" [title]="menu.label"
                                        aria-label="" class="ms-Link root-151" tabindex="-1">
                                        <ng-container *ngSwitchCase="0">
                                                {{ menu?.label }}
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                                {{getBeforeMatched(menu)}}<span
                                                        class="matchesFilter">{{getMatched(menu)}}</span>{{getAfterMatched(menu)}}
                                        </ng-container>
                                </a>
                        </li>
                </ul>
        </nav>
</div>

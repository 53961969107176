import { NgModule } from '@angular/core';
import { ExtStringTextBoxComponent } from './ext-string-text-box/ext-string-text-box.component';
import { ExtNumericTextBoxComponent } from './ext-numeric-text-box/ext-numeric-text-box.component';
import { EnumTextBoxComponent } from './enum-text-box/enum-text-box.component';
import { DateTextBoxComponent } from './date-text-box/date-text-box.component';
import { DateOffsetTextBoxComponent } from './date-offset-text-box/date-offset-text-box.component';
import { ExtAutocompleteTextBoxComponent } from './ext-autocomplete-text-box/ext-autocomplete-text-box.component';
import { NumericTextBoxComponent } from './numeric-text-box/numeric-text-box.component';
import { StringTextBoxComponent } from './string-text-box/string-text-box.component';
import { CommandsBarComponent } from './commands-bar/commands-bar.component';
import { ExtEnumTextBoxComponent } from './ext-enum-text-box/ext-enum-text-box.component';
import { RadioButtonTextBoxComponent } from './radio-button-text-box/radio-button-text-box.component';
import { TimeSpanTextBoxComponent } from './time-span-text-box';
import { BaseControlsModule } from './base/base-controls.module';
import { CheckboxTextBoxComponent } from './checkbox-text-box/checkbox-text-box.component';
import { ToggleTextBoxComponent } from './toggle-text-box/toggle-text-box.component';
import { ExtNewAutocompleteTextBoxComponent } from './ext-new-autocomplete-text-box/ext-new-autocomplete-text-box.component';

@NgModule({
    imports: [
        BaseControlsModule,
        CheckboxTextBoxComponent,
        CommandsBarComponent,
        DateOffsetTextBoxComponent,
        DateTextBoxComponent,
        EnumTextBoxComponent,
        ExtAutocompleteTextBoxComponent,
        ExtNewAutocompleteTextBoxComponent,
        ExtEnumTextBoxComponent,
        ExtNumericTextBoxComponent,
        ExtStringTextBoxComponent,
        NumericTextBoxComponent,
        RadioButtonTextBoxComponent,
        StringTextBoxComponent,
        TimeSpanTextBoxComponent,
        ToggleTextBoxComponent,
    ],
    exports: [
        BaseControlsModule,
        CheckboxTextBoxComponent,
        CommandsBarComponent,
        DateOffsetTextBoxComponent,
        DateTextBoxComponent,
        EnumTextBoxComponent,
        ExtAutocompleteTextBoxComponent,
        ExtNewAutocompleteTextBoxComponent,
        ExtEnumTextBoxComponent,
        ExtNumericTextBoxComponent,
        ExtStringTextBoxComponent,
        NumericTextBoxComponent,
        RadioButtonTextBoxComponent,
        StringTextBoxComponent,
        TimeSpanTextBoxComponent,
        ToggleTextBoxComponent,
    ]
})
export class CoreComponentsModule {

}

<div class="toolbar-section">
  <div class="toolbar-control-content">
    <div class="horizontal-container">
      <div class="root-container">
        <div class="inline-command-bar">
          <div class="action-list-control-container">
            <div class="horizontal-flex-container">

              <!-- Toggle filter with populated operator -->
              <div class="command-bar-button-container">
                <nts-text-button [class.active]="zoomParametersViewModel.showFilterWithPopulatedOperator"
                  [title]="zoomParametersViewModel.filterWithPopulatedOperatorButtonTitle" [icon]="'task-list'"
                  (onClicked)="zoomParametersViewModel.showFilterWithPopulatedOperator = !zoomParametersViewModel.showFilterWithPopulatedOperator">
                </nts-text-button>
              </div>

              <!-- Toggle selection in filter -->
              <div class="command-bar-button-container">
                <nts-text-button [class.active]="zoomParametersViewModel.showSelectionsFilterOptions"
                  [title]="zoomParametersViewModel.selectionsFiltersOptionsButtonTitle" [icon]="'eye-empty'"
                  (onClicked)="zoomParametersViewModel.showSelectionsFilterOptions = !zoomParametersViewModel.showSelectionsFilterOptions">
                </nts-text-button>
              </div>

              <!-- Toggle advanced command -->
              <div class="command-bar-button-container">
                <nts-text-button [class.active]="zoomParametersViewModel.showAdvancedFilters"
                  [title]="zoomParametersViewModel.advancedButtonTitle" [icon]="'fx-rounded'"
                  (onClicked)="zoomParametersViewModel.toggleAdvancedFilters()"></nts-text-button>
              </div>

              <!-- Toggle sorting -->
              <div class="command-bar-button-container">
                <nts-text-button [class.active]="zoomParametersViewModel.showSortingGroups"
                  [title]="zoomParametersViewModel.sortingGroupButtonTitle" icon="sort"
                  (onClicked)="zoomParametersViewModel.toggleSortingGroups()"></nts-text-button>
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- Clear filters command -->
      <div class="horizontal-flex-container-item-layout">
        <div class="command-bar-button-container">
          <nts-text-button [title]="zoomParametersViewModel.clearCommand.tooltip"
            [label]="zoomParametersViewModel.clearCommand.displayName"
            [isDisabled]="!(zoomParametersViewModel.clearCommand.canExecute$ | async)"
            (onClicked)="zoomParametersViewModel.clearCommand.execute()"></nts-text-button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="zoom-parameters-content" [class.is-hidden]="zoomParametersViewModel.showSortingGroups">
  <div #parameterLabels class="parameter-column parameter-column-labels">
    <!-- <div class="zoom-parameter-label">{{ zoomParametersViewModel.headerPropertyName }}</div> -->
    <div
      *ngFor="let filter of (zoomParametersViewModel.filters | zoomAvailablesFilter: zoomParametersViewModel.showFilterWithPopulatedOperator)"
      class="zoom-filter-name level-{{filter.metaData.level}}">
      
      <div class="header">
        <div class="zoom-filter-name-container">
          <nts-base-checkbox-text-box 
            *ngIf="zoomParametersViewModel.showSelectionsFilterOptions"
            (onChange)="zoomParametersViewModel.notifyModified()"
            [(checkStatus)]="filter.isSelected"
            [label]="filter.propertyNameMap.displayName" 
            [isDisabled]="!filter.isSelectable">
          </nts-base-checkbox-text-box>
  
          <nts-label-box (click)="findExternal($event, filter)"
            [class.label-multiline]="filter.operator.currentValue == zoomParametersViewModel.filterOperators.Between"
            [class.zoom-label]="filter.externalDomainModelNameToZoom !== ''" [title]="filter.propertyNameMap.displayName"
            [label]="filter.propertyNameMap.displayName"></nts-label-box>
          
        </div>
        
        <nts-text-button *ngIf="filter.externalDomainModelNameToZoom !== ''"
          [icon]="'search'" (onClicked)="findExternal($event, filter)">
        </nts-text-button>
  
        <nts-text-button *ngIf="hasChildren(filter) && !zoomParametersViewModel.showFilterWithPopulatedOperator && filter.isExternalRemote == false"
          [title]="(filter.isCollapsed ? 'std_ExpandableShowMore_Description' : 'std_ExpandableShowLess_Description') | NTSTranslate"
          [icon]="filter.isCollapsed ? 'eye-alt' : 'eye-close'" (onClicked)="toggleChildrenVisibility(filter)">
        </nts-text-button>
  
        <nts-text-button
          [title]="'Espandi/Riduci'" (onClicked)="toggleExpandedFilter(filter)"
          [icon]="filter.isExpanded ? 'compress' : 'de-compress'">
        </nts-text-button>
      </div>
      <div *ngIf="filter.isExpanded" class="footer">
        <div #parameterOperators class="parameter-column parameter-column-operators">
          <!-- <div class="zoom-parameter-label">{{ zoomParametersViewModel.headerOperatorName }}</div> -->
          <div class="filter-operator">
            <nts-zoom-filter-operator-text-box [operator]="filter.operator"></nts-zoom-filter-operator-text-box>
          </div>
        </div>
        <div #parameterValues class="parameter-column parameter-values">
          <div class="filter-value-container">
            <div class="filter-value" #filters *ngIf="filter.operator.currentValue != zoomParametersViewModel.filterOperators.In"
              [ngClass]="{'advanced-filters-show': zoomParametersViewModel.showAdvancedFilters}">
              <div class="filter-value-list" *ngIf="!filter.showVariables" @openClose>
                <nts-zoom-filter-string-text-box *ngIf="isFilterString(filter.filterValue) && !filter.showVariables"
                  [filter]="filter"
                  [propertyViewModel]="filter.filterValue" (focus)="parameterFocus($event, filter)"
                  (keyDown)="keyDown($event)">
                </nts-zoom-filter-string-text-box>
                <nts-zoom-filter-string-text-box *ngIf="isFilterGuid(filter.filterValue) && !filter.showVariables"
                  [filter]="filter"
                  [validateChipsFunc]="guidMaskSettings.validateMaskOnBlurFunc"
                  [maskSettings]="guidMaskSettings"
                  [propertyViewModel]="filter.filterValue" (focus)="parameterFocus($event, filter)"
                  (keyDown)="keyDown($event)">
                </nts-zoom-filter-string-text-box>
                <nts-zoom-filter-numeric-text-box *ngIf="isFilterNumeric(filter.filterValue) && !filter.showVariables"
                  [filter]="filter"
                  [propertyViewModel]="filter.filterValue" [updateOnBlur]="true" (focus)="parameterFocus($event, filter)"
                  (keyDown)="keyDown($event)">
                </nts-zoom-filter-numeric-text-box>
                <nts-zoom-filter-bool-text-box *ngIf="isFilterBool(filter.filterValue) && !filter.showVariables"
                  [filter]="filter"
                  [propertyViewModel]="filter.filterValue">
                </nts-zoom-filter-bool-text-box>
                <nts-zoom-filter-date-text-box *ngIf="isFilterDate(filter.filterValue) && !filter.showVariables"
                  [filter]="filter"
                  [propertyViewModel]="filter.filterValue">
                </nts-zoom-filter-date-text-box>
                <nts-zoom-filter-date-offset-text-box
                  *ngIf="isFilterDateTimeOffset(filter.filterValue) && !filter.showVariables"
                  [filter]="filter"
                  [propertyViewModel]="filter.filterValue">
                </nts-zoom-filter-date-offset-text-box>
                <nts-zoom-filter-enum-text-box *ngIf="isFilterEnum(filter.filterValue) && !filter.showVariables"
                  [filter]="filter"
                  [propertyViewModel]="filter.filterValue">
                </nts-zoom-filter-enum-text-box>
              </div>
              <div class="variables-container" *ngIf="filter.showVariables" @openClose>
      
                <nts-base-enum-text-box *ngIf="filter.showVariables" [value]="filter.filterVariableValue" optionLabel="label"
                  dataKey="'value'" [options]="filter.filterVariables" [appendTo]="'body'"
                  [isDisabled]="filter.OperatorDoesNotRequireValue"
                  [placeholder]="zoomParametersViewModel.variablesPlaceholderTitle"
                  (onChange)="zoomParametersViewModel.onFilterVariableChange(filter)"
                  (onBlur)="zoomParametersViewModel.onFilterVariableBlur(filter)" [isNullable]="true">
                </nts-base-enum-text-box>
      
              </div>
              <div class="variables-button-container"
                *ngIf="zoomParametersViewModel.showAdvancedFilters && filter.filterVariables.length > 0">
      
                <nts-text-button *ngIf="zoomParametersViewModel.showAdvancedFilters"
                  [title]="zoomParametersViewModel.variablesButtonTitle" [icon]="'filter'"
                  [class.var-visible]="filter.showVariables" (onClicked)="zoomParametersViewModel.showVariables(filter)">
                </nts-text-button>
              </div>
            </div>
            <!-- Between -->
            <div class="filter-value" *ngIf="filter.operator.currentValue == zoomParametersViewModel.filterOperators.Between"
              #filters
              [ngClass]="{'advanced-filters-show': zoomParametersViewModel.showAdvancedFilters, 'filter-value-between':filter.operator.currentValue == zoomParametersViewModel.filterOperators.Between }">
              <div class="filter-value-list" *ngIf="!filter.showVariables2" @openClose>
                <div
                  *ngIf="isFilterString(filter.filterValue) && filter.operator.currentValue == zoomParametersViewModel.filterOperators.Between && !filter.showVariables2">
                  <nts-zoom-filter-string-text-box [filter]="filter" [propertyViewModel]="filter.filterValue2" [updateOnBlur]="true"
                    (focus)="parameterFocus($event, filter)" (keyDown)="keyDown($event)"
                    [ngClass]="'filter-value-text-between'">
                  </nts-zoom-filter-string-text-box>
                </div>
                <div
                  *ngIf="isFilterNumeric(filter.filterValue) && filter.operator.currentValue == zoomParametersViewModel.filterOperators.Between && !filter.showVariables2">
                  <nts-zoom-filter-numeric-text-box [filter]="filter" [propertyViewModel]="filter.filterValue2" [updateOnBlur]="true"
                    (focus)="parameterFocus($event, filter)" (keyDown)="keyDown($event)"
                    [ngClass]="'filter-value-text-between'">
                  </nts-zoom-filter-numeric-text-box>
                </div>
                <div
                  *ngIf="isFilterDate(filter.filterValue) && filter.operator.currentValue == zoomParametersViewModel.filterOperators.Between && !filter.showVariables2">
                  <nts-zoom-filter-date-text-box [propertyViewModel]="filter.filterValue2"
                    [ngClass]="'filter-value-text-between'">
                  </nts-zoom-filter-date-text-box>
                </div>
                <div
                  *ngIf="isFilterDateTimeOffset(filter.filterValue) && filter.operator.currentValue == zoomParametersViewModel.filterOperators.Between && !filter.showVariables2">
                  <nts-zoom-filter-date-offset-text-box [propertyViewModel]="filter.filterValue2"
                    [ngClass]="'filter-value-text-between'">
                  </nts-zoom-filter-date-offset-text-box>
                </div>
              </div>
              <div class="variables-container" *ngIf="filter.showVariables2" @openClose>
                <div class="mt-1"
                  *ngIf="filter.showVariables2 && filter.operator.currentValue == zoomParametersViewModel.filterOperators.Between"
                  @openClose>
      
                  <nts-base-enum-text-box *ngIf="filter.showVariables2" [value]="filter.filterVariable2Value"
                    optionLabel="'label'" dataKey="'value'" [options]="filter.filterVariables" [appendTo]="'body'"
                    [isDisabled]="filter.OperatorDoesNotRequireValue"
                    [placeholder]="zoomParametersViewModel.variablesPlaceholderTitle"
                    (onChange)="zoomParametersViewModel.onFilterVariable2Change(filter)" [isNullable]="true"
                    (onBlur)="zoomParametersViewModel.onFilterVariable2Blur(filter)" [isNullable]="true">
                  </nts-base-enum-text-box>
                </div>
              </div>
              <div class="variables-button-container"
                *ngIf="zoomParametersViewModel.showAdvancedFilters && filter.filterVariables.length > 0">
                <nts-text-button
                  *ngIf="zoomParametersViewModel.showAdvancedFilters && filter.operator.currentValue == zoomParametersViewModel.filterOperators.Between"
                  [title]="zoomParametersViewModel.variablesButtonTitle" [icon]="'filter'"
                  [class.var-visible]="filter.showVariables2" (onClicked)="zoomParametersViewModel.showVariables2(filter)">
                </nts-text-button>
              </div>
            </div>
            <!-- In -->
            <div class="filter-value" *ngIf="filter.operator.currentValue == zoomParametersViewModel.filterOperators.In" #filters>
              <div class="filter-value-list" *ngIf="!filter.showVariables" @openClose>
                <ng-container *ngIf="isFilterString(filter.filterValue) && !filter.showVariables">
                  <nts-zoom-filter-string-text-box *ngIf="isFilterString(filter.filterValue) && !filter.showVariables"
                    [filter]="filter"
                    [propertyViewModel]="filter.filterValue" (focus)="parameterFocus($event, filter)"
                    (keyDown)="keyDown($event)">
                  </nts-zoom-filter-string-text-box>
                </ng-container>
                <ng-container *ngIf="isFilterNumeric(filter.filterValue) && !filter.showVariables">
                  <nts-zoom-filter-numeric-text-box *ngIf="isFilterNumeric(filter.filterValue) && !filter.showVariables"
                  [filter]="filter"
                  [propertyViewModel]="filter.filterValue" [updateOnBlur]="true" (focus)="parameterFocus($event, filter)"
                  (keyDown)="keyDown($event)">
                 </nts-zoom-filter-numeric-text-box>
                </ng-container>
                <ng-container *ngIf="isFilterEnum(filter.filterValue) && !filter.showVariables">
                  <nts-zoom-filter-enum-text-box [propertyViewModel]="filterIn" *ngFor="let filterIn of filter.filterValues">
                  </nts-zoom-filter-enum-text-box>
                </ng-container>
                <ng-container *ngIf="isFilterDate(filter.filterValue) && !filter.showVariables">
                  <nts-zoom-filter-date-text-box [propertyViewModel]="filterIn" *ngFor="let filterIn of filter.filterDateTimeValues"
                    [ngClass]="'filter-value-text-in'">
                  </nts-zoom-filter-date-text-box>
                </ng-container>
                <ng-container *ngIf="isFilterDateTimeOffset(filter.filterValue) && !filter.showVariables">
                  <nts-zoom-filter-date-offset-text-box [propertyViewModel]="filterIn" *ngFor="let filterIn of filter.filterDateTimeValues"
                    [ngClass]="'filter-value-text-in'">
                  </nts-zoom-filter-date-offset-text-box>
                </ng-container>
              </div>
              <div class="in-buttons-container" *ngIf="isFilterDate(filter.filterValue) || isFilterDateTimeOffset(filter.filterValue)">
                <div class="filter-value-actions" *ngFor="let filterIn of filter.filterDateTimeValues; let last = last; let first = first">
                  <nts-text-button
                    [title]="zoomParametersViewModel.addInFieldButtonTitle" *ngIf="last" [icon]="'plus'"
                    (onClicked)="zoomParametersViewModel.addInField(filter)">
                  </nts-text-button>
                  <nts-text-button
                    [title]="zoomParametersViewModel.addInFieldButtonTitle" *ngIf="!first" [icon]="'trash'"
                    (onClicked)="zoomParametersViewModel.deleteInField(filter, filterIn)">
                  </nts-text-button>            
                </div>
              </div>
              <div class="in-buttons-container" *ngIf="isFilterEnum(filter.filterValue)">
                <div class="filter-value-actions" *ngFor="let filterIn of filter.filterValues; let last = last; let first = first">
                  <nts-text-button
                    [title]="zoomParametersViewModel.addInFieldButtonTitle" *ngIf="last" [icon]="'plus'"
                    (onClicked)="zoomParametersViewModel.addInField(filter)">
                  </nts-text-button>
                  <nts-text-button
                    [title]="zoomParametersViewModel.addInFieldButtonTitle" *ngIf="!first" [icon]="'trash'"
                    (onClicked)="zoomParametersViewModel.deleteInField(filter, filterIn)">
                  </nts-text-button>            
                </div>
              </div>
            </div>
        </div>
      </div>
      
    </div>
  </div>
  
</div>
<nts-zoom-parameters-sorting-groups *ngIf="zoomParametersViewModel.filters"
  [class.is-hidden]="!zoomParametersViewModel.showSortingGroups"
  [parameters]="zoomParametersViewModel"
  (onSortingGroupsChanged)="zoomParametersViewModel.notifyModified()">
</nts-zoom-parameters-sorting-groups>
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Route, Router, RouterLink } from '@angular/router';
import { OnlineService, UpdateService } from '@nts/std/src/lib/utility';
import { TokenService } from '../../auth/token.service';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { RoutingService } from '../../routing/routing.service';
import { UIStarter } from '../../starter';
import { BaseContainerComponent } from '../layout/base-container/base-container.component';
import { ToastMessageService } from '../layout/toast-message/toast-message.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { firstValueFrom } from 'rxjs';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { AuthService } from '../../auth/auth.service';
import { TelemetryService } from '@nts/std/src/lib/telemetry';
import { CurrentRouteService } from '../../routing';

@UntilDestroy()
@Component({
    selector: 'nts-entry-page',
    templateUrl: './entry-page.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    styleUrls: [
        './entry-page.component.scss'
    ],
    imports: [
        NgClass,
        RouterLink,
        NgFor,
        NgIf,
    ]
})
export class EntryPageComponent extends BaseContainerComponent implements OnInit {
    
    @ViewChild('documentPlaceHolder', { read: ViewContainerRef, static: true }) override documentPlaceHolder: ViewContainerRef;

    registeredEntities = [];

    constructor(
        protected override readonly routingService: RoutingService,
        protected override readonly activatedRoute: ActivatedRoute,
        protected override readonly tokenService: TokenService,
        protected override readonly toastMessageService: ToastMessageService,
        protected override readonly updateService: UpdateService,
        protected override readonly onlineService: OnlineService,
        protected override readonly authService: AuthService,
        protected override readonly telemetryService: TelemetryService,
        protected override readonly environmentConfiguration: EnvironmentConfiguration,
        protected override readonly cd: ChangeDetectorRef,
        protected override readonly componentFactoryResolver: ComponentFactoryResolver,
        protected override readonly currentRouteService: CurrentRouteService,
        protected readonly router: Router,
    ) {
        super(
            routingService, 
            activatedRoute, 
            tokenService, 
            toastMessageService,
            updateService, 
            onlineService, 
            authService,
            telemetryService,
            environmentConfiguration,
            cd,
            componentFactoryResolver,
            currentRouteService
        );
    }

    override async ngOnInit(): Promise<void> {

        super.ngOnInit();

        window.document.title = `ENTITY LIST - ${this.environmentConfiguration.appTitle}`;

        if (this.environmentConfiguration.envType === 'PROD') {
            UIStarter.redirectToDashboard();
            return;
        }

        const params = await firstValueFrom(this.activatedRoute.queryParams);

        if (this.environmentConfiguration.envType === 'TEST' && params['test-e2e'] !== 'true') {
            UIStarter.redirectToDashboard();
            return;
        }

        this.populateEntites('', this.router.config);
        this.registeredEntities.sort();
        TelemetryService.trackPageView();
        this.cd.detectChanges();
    }

    populateEntites(parent: string, config: Route[]) {
        for (let i = 0; i < config.length; i++) {
            const route = config[i];
            if (route.path !== '' && route.path.startsWith('manage/')) {
                this.registeredEntities.push(route.path.substring(route.path.indexOf('/') + 1));
            }
            if (route.children) {
                const currentPath = route.path ? parent + '/' + route.path : parent;
                this.populateEntites(currentPath, route.children);
            }
        }
    }

    protected untilDestroyed() {
        return untilDestroyed(this);
    }    
}

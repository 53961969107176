import { EventEmitter } from '@angular/core';
import { BaseError } from '../messages/base-error';
import { BaseViewModel } from './base-view-model';
import { MessageContainer, SourceMessage } from './message-container';
import { PropertyViewModelPropertyChangedEventArgs } from './property-view-property-changed-event-args';
import { ViewModelEventDispatcher } from './view-model-event-dispatcher';

export abstract class BasePropertyViewModel extends BaseViewModel {

    propertyName: string;
    propertyViewModelChanged: EventEmitter<void> = new EventEmitter();
    modelChanged: EventEmitter<void> = new EventEmitter();
    propertyChanged: EventEmitter<PropertyViewModelPropertyChangedEventArgs> = new EventEmitter();

    override get isRequired() {
        return this.internalIsRequired;
    }

    override get hasErrors(): boolean {
        return this.messageContainerCollection.length > 0;
    }

    override get isVisible() {
        return this._isVisible;
    }
    override set isVisible(value: boolean) {
        if (this._isVisible !== value) {
            this._isVisible = value;
            this.onPropertyChanged('isVisible');
        }
    }

    get bindedValuePropertyName() {
        return 'value';
    }

    get propertyPath() {
        return '';
    }

    get metadataDescription() {
        return this._metadataDescription;
    }
    set metadataDescription(value: string) {
        if (this._metadataDescription !== value) {
            this._metadataDescription = value;
            this.onPropertyChanged('metadataDescription');
        }
    }

    get metadataShortDescription() {
        return this._metadataShortDescription;
    }
    set metadataShortDescription(value: string) {
        if (this._metadataShortDescription !== value) {
            this._metadataShortDescription = value;
            this.onPropertyChanged('metadataShortDescription');
        }
    }

    protected _metadataDescription: string;
    protected _metadataShortDescription: string;

    constructor(name: string) {
        super();
        this.propertyName = name;
    }

    onPropertyChanged(propertyName: string = null) {
        const args = new PropertyViewModelPropertyChangedEventArgs();
        args.propertyName = propertyName;
        this.propertyChanged.emit(args);
    }

    override getErrors(): Array<string> {
        return this.errors$.value;
    }

    addErrorMessageIfNotExist(err: BaseError, sourceMessage: SourceMessage, emitOnAddMessageInViewModel = true) {
        // verifica che non ci sia già un errore con lo stesso codice e propertyname
        if (!this.messageContainerCollection.find(x => x.contains(err))) {
            this.messageContainerCollection.splice(0, 0, MessageContainer.fromBaseAndSourceMessage(err, sourceMessage, this.uniqueId));
            if (this['eventDispatcher'] && emitOnAddMessageInViewModel) {
                const ed = (this as any).eventDispatcher as ViewModelEventDispatcher;
                ed.onAddMessageInViewModel.next({ viewModel: this, messages: [err] });
            }
            this.updateCurrentErrors();
            this.onErrorStatusChanged.next();
        }
    }
}

import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { BaseCellRendererComponent } from '../base_cell_renderer.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AsyncPipe, NgIf } from '@angular/common';
import { DefaultCellRendererComponent } from '../default_cell_renderer.component';
import { NumericCellRendererComponent } from '../numeric_cell_renderer.component';
import { BaseNumericPropertyViewModel } from '../../../../../view-models/base-type/base-numeric-property-view-model';
import { StringPropertyViewModel } from '../../../../../view-models/base-type/string-property-view-model';
import { DateCellRendererComponent } from '../date_cell_renderer.component';
import { BaseDateTimePropertyViewModel } from '../../../../../view-models/base-type/date-time-property-view-model';
import { BaseEnumPropertyViewModel } from '../../../../../view-models/base-type/enum-property-view-model';
import { EnumCellRendererComponent } from '../enum_cell_renderer.component';
import { ColumnCellRendererParams } from '../column_cell_renderer_params.interface';
import { BehaviorSubject } from 'rxjs';
import { BoolCellRendererComponent } from '../bool-cell-renderer/bool-cell-renderer.component';
import { BaseBoolPropertyViewModel } from '../../../../../view-models/base-type/bool-property-view-model';
import { BaseDateTimeOffsetPropertyViewModel } from '../../../../../view-models/base-type/date-time-offset-property-view-model';
// create your cellEditor as a Angular component@UntilDestroy()
@UntilDestroy()
@Component({
    selector: 'nts-dynamic-text-box-renderer-cell',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        AsyncPipe,
        DefaultCellRendererComponent,
        BoolCellRendererComponent,
        DateCellRendererComponent,
        EnumCellRendererComponent,
        NumericCellRendererComponent,
    ],
    template: 
        `<nts-default-text-box-renderer-cell *ngIf="!params" [params]="params"></nts-default-text-box-renderer-cell>
         <nts-bool-text-box-renderer-cell *ngIf="isBool$ | async" [params]="params"></nts-bool-text-box-renderer-cell>
         <nts-date-text-box-renderer-cell *ngIf="isDate$ | async" [params]="params"></nts-date-text-box-renderer-cell>
         <nts-default-text-box-renderer-cell *ngIf="isString$ | async" [params]="params"></nts-default-text-box-renderer-cell>
         <nts-enum-text-box-renderer-cell *ngIf="isEnum$ | async" [params]="params"></nts-enum-text-box-renderer-cell>
         <nts-numeric-text-box-renderer-cell *ngIf="isNumeric$ | async" [params]="params"></nts-numeric-text-box-renderer-cell>`,
    styleUrls: [`./dynamic-cell-renderer.component.scss`]
})
export class DynamicCellRendererComponent extends BaseCellRendererComponent {

    isBool$ = new BehaviorSubject<boolean>(false);
    isDate$ = new BehaviorSubject<boolean>(false);
    isString$ = new BehaviorSubject<boolean>(false);
    isEnum$ = new BehaviorSubject<boolean>(false);
    isNumeric$ = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly cd: ChangeDetectorRef,
    ) {
        super();
    }

    override agInit(params: ColumnCellRendererParams): void {
        super.agInit(params);
        this.updateComponentType();
    }

    updateComponentType() {
        if (this.propertyViewModel instanceof BaseBoolPropertyViewModel) {
            this.isBool$.next(true);
        } else if (this.propertyViewModel instanceof BaseDateTimePropertyViewModel) {
            this.isDate$.next(true);
        } else if (this.propertyViewModel instanceof BaseDateTimeOffsetPropertyViewModel) {
            this.isDate$.next(true);
        } else if (this.propertyViewModel instanceof StringPropertyViewModel) {
            this.isString$.next(true);        
        } else if (this.propertyViewModel instanceof BaseEnumPropertyViewModel) {
            this.isEnum$.next(true);
        } else if (this.propertyViewModel instanceof BaseNumericPropertyViewModel) {
            this.isNumeric$.next(true);
        }
    }

    override refresh(params: ColumnCellRendererParams) {
        this.params = params;
        this.updateComponentType();
        return true;
    }
}

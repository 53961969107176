import { Expose } from "../../serialization/class-transformer/decorators";
import { BaseIdentity } from "../base-identity";
import { IdentityTypeNameDecorator } from "../decorators/identity-type-name.decorator";

@IdentityTypeNameDecorator('ServiceInfoIdentity')
export class ServiceInfoIdentity extends BaseIdentity {

    // tslint:disable-next-line: variable-name
    private _fullName: string;

    @Expose()
    public get fullName(): string {
        return this.getValue<string>(() => this._fullName, 'fullName');
    }
    public set fullName(value: string) {
        this.setValue<string>(() => { this._fullName = value; }, value, 'fullName');
    }
}
import { Expose } from "../../serialization/class-transformer/decorators";
import { ServiceDataAccessInterface } from "./service-data-access.interface";

export class ServiceDataAccessDto implements ServiceDataAccessInterface {
    
    @Expose()
    objectName: string;
    
    @Expose()
    canAccess: boolean;

}
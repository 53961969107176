import { Component, OnInit } from '@angular/core';
import { LogService } from '@nts/std/src/lib/utility';
import { CustomFieldMetaData, PanelState, SimpleFieldMetaData } from '../../layout-meta-data';
import { ExternalLayoutMetaData } from '../../layout-meta-data/external-layout-meta-data';
import { FieldMetaData } from '../../layout-meta-data/field-meta-data';
import { FieldTypes } from '../../layout-meta-data/field-types.enum';
import { GridMetaData } from '../../layout-meta-data/grid-meta-data';
import { LayoutMetaData } from '../../layout-meta-data/layout-meta-data';
import { PanelMetaData } from '../../layout-meta-data/panel-meta-data';
import { ColumnInfoCollection } from '../../view-models/column-info-collection';
import { ExternalViewModelInterface } from '../../view-models/external-view-model.interface';
import { PropertyViewModelInterface } from '../../view-models/property-view-model.interface';
import { RootViewModelInterface } from '../../view-models/root-view-model.interface';
import { BaseAggregateComponent } from '../base-aggregate/base-aggregate.component';
import { ExpandableComponent } from '../shared/expandable/expandable.component';
import { NgFor, NgIf } from '@angular/common';
import { SpacerBoxComponent } from '../controls/spacer-box/spacer-box.component';
import { LabelledTextBoxComponent } from '../controls/labelled-text-box/labelled-text-box.component';
import { GridComponent } from '../controls/grid/grid.component';
import { DecodeTextBoxComponent } from '../controls/decode-text-box/decode-text-box.component';
import { ExternalListComponent } from '../controls/external-list/external-list.component';
import { CustomFieldContainerComponent } from '../controls/custom-field-container/custom-field-container.component';

@Component({
    selector: 'nts-dynamic-presentation-component',
    templateUrl: 'dynamic-presentation.component.html',
    styleUrls: ['./dynamic-presentation.component.scss'],
    standalone: true,
    imports: [
        ExpandableComponent,
        NgIf,
        SpacerBoxComponent,
        LabelledTextBoxComponent,
        GridComponent,
        DecodeTextBoxComponent,
        ExternalListComponent,
        CustomFieldContainerComponent,
        NgFor
    ]
})

export class DynamicPresentationComponent extends BaseAggregateComponent<RootViewModelInterface> implements OnInit {

    fieldType = FieldTypes;
    panelState = PanelState

    get layoutMetaData(): LayoutMetaData {
        return this.orchestratorViewModel.layoutMetaData
    }

    private enableCache = false;
    private promotedFieldsCache = {};
    private propertyViewModelCache = {};
    private collectionViewModelColumnCache = {};

    override ngOnInit() {
        LogService.log('DynamicPresentation - INIT');
    }

    getCustomFieldMetaDataFromFieldMetaData(field: FieldMetaData) {
        return field as CustomFieldMetaData;
    }

    getSimpleFieldMetaDataFromFieldMetaData(field: FieldMetaData) {
        return field as SimpleFieldMetaData;
    }

    getGridMetaDataFromFieldMetaData(field: FieldMetaData) {
        return field as GridMetaData;
    }

    getExternalMetaDataFromFieldMetaData(field: FieldMetaData) {
        return field as ExternalLayoutMetaData;
    }

    getPromotedFields(panel: PanelMetaData, panelIndex: number) {
        if (this.promotedFieldsCache[panelIndex] && this.enableCache) {
            return this.promotedFieldsCache[panelIndex];
        }
        return panel.promotedFields.map((fieldFullPath: string) => {
            const correctedPath = this.fixSelectedItemInPath(fieldFullPath);
            const result = correctedPath.split('.').reduce((acc, current) => acc[current], this.orchestratorViewModel.rootViewModel);
            this.promotedFieldsCache[panelIndex] = result;
            return result;
        })
    }

    getPropertyFromFieldMetaData(field: FieldMetaData): PropertyViewModelInterface | ExternalViewModelInterface {
        const cacheKey = field.path?.length > 0 ? field.path + '.' + field.name : field.name;
        if (this.propertyViewModelCache[cacheKey] && this.enableCache) {
            return this.propertyViewModelCache[cacheKey];
        }

        let result: PropertyViewModelInterface | ExternalViewModelInterface;

        if (field.path?.length > 0) {
            result = this.getResolvedPropertyPath(field)[field.name];
        } else {
            result = this.orchestratorViewModel.rootViewModel[field.name];
        }
        this.propertyViewModelCache[cacheKey] = result;
        return result;
    }

    getPropertyViewModelFromFieldMetaData(field: FieldMetaData): PropertyViewModelInterface {
        return this.getPropertyFromFieldMetaData(field) as PropertyViewModelInterface;
    }

    getCollectionViewModelFromGridFieldMetaData(field: GridMetaData) {
        if (field.path?.length > 0) {
            return this.getResolvedPropertyPath(field)[field.name];
        } else {
            return this.orchestratorViewModel.rootViewModel[field.name];
        }

    }

    getCollectionViewModelColumnsFromGridFieldMetaData(field: GridMetaData): ColumnInfoCollection {
        const cacheKey = field.path?.length > 0 ? field.path + '.' + field.name : field.name;
        if (this.collectionViewModelColumnCache[cacheKey]) {
            return this.collectionViewModelColumnCache[cacheKey];
        }

        if (field.gridColumns?.gridFields.length > 0) {

            field.gridColumns.sortedFields.filter(f => f.isVisible).forEach((f) => {
                if (f.path.endsWith('selectedItem')) {
                    return f.name;
                }
                const path = f.path.substring(f.path.lastIndexOf('.selectedItem.') + '.selectedItem.'.length);
                return path?.length > 0 ? path + '.' + f.name : f.name;
            })

            const propertyList = field.gridColumns.gridFields.filter((f) => f.isVisible === true).map(
                (f) => {
                    if (f.path.endsWith('selectedItem')) {
                        return f.name;
                    }
                    const path = f.path.substring(f.path.lastIndexOf('.selectedItem.') + '.selectedItem.'.length);
                    return path?.length > 0 ? path + '.' + f.name : f.name;
                })

            const widthDefinition = field.gridColumns.gridFields.filter((f) => f.isVisible === true).map(
                (f) => {

                    let fullPropertyName = f.name;
                    if (!f.path.endsWith('selectedItem')) {
                        const path = f.path.substring(f.path.lastIndexOf('.selectedItem.') + '.selectedItem.'.length);
                        fullPropertyName = path?.length > 0 ? path + '.' + f.name : f.name;
                    }
                    const isAutoSize = f.isAutoSize;
                    const width = f.width;
                    return {
                        fullPropertyName, isAutoSize, width
                    }
                })

            const autocompleteDefinition = field.gridColumns.gridFields.filter((f) => f.isVisible === true).map(
                (f) => {

                    let fullPropertyName = f.name;
                    if (!f.path.endsWith('selectedItem')) {
                        const path = f.path.substring(f.path.lastIndexOf('.selectedItem.') + '.selectedItem.'.length);
                        fullPropertyName = path?.length > 0 ? path + '.' + f.name : f.name;
                    }
                    if (f.externalRef) {
                        const foundExternalField = f.externalRef.externalFields.find((ef) =>ef.name === f.name);
                        if (foundExternalField) {
                            return {
                                fullPropertyName, isAutocomplete: foundExternalField.isAutocomplete
                            }
                        }                        
                    }
                    return null;
                    
                }).filter((e) => e)

            const column = this.rootViewModel.getGridColumns(
                field.path?.length > 0 ? field.path + '.' + field.name : field.name,
                field.gridColumns.modelName,
                propertyList,
                // TODO da gestire external
                // field.columnDefinition.gridFields.filter(f => f.isVisible && s.externalRef != null).map((external) => {
                //     return {
                //         externalPropertyName: external.name,
                //         externalCodesPropertyName: external.externalRefexternalFields.map((ec) => {
                //             return {
                //                 autocomplete: ec.isAutocomplete,
                //                 propertyName: ec.name
                //             }
                //         }),
                //         externalDecodesPropertyName: external.externalDecodes
                //     } as ExternalColumnMapInfo;
                // }),
                field.areAllColumnsDisabled ? false : field.gridColumns.gridFields.filter((f) => f.isDisabled).map((f) => {
                    if (f.path.endsWith('selectedItem')) {
                        return f.name;
                    }
                    const path = f.path.substring(f.path.lastIndexOf('.selectedItem.') + '.selectedItem.'.length);
                    return path?.length > 0 ? path + '.' + f.name : f.name;
                }),
                propertyList,
                widthDefinition,
                autocompleteDefinition
            );

            this.collectionViewModelColumnCache[cacheKey] = column;
            return column;

            //     if (field.columnDefinition.orders) {
            //         ColumnsUtils.sortColumnByArray(column, field.columnDefinition.orders)
            //     } else {
            //         // TODO gestire ordinamento default
            //         // ColumnsUtils.sortColumnByArray(column, field.columnDefinition.orders)
            //     }

            //     this.collectionViewModelColumnCache[cacheKey] = column;
            //     return this.collectionViewModelColumnCache[cacheKey];

        } else {
            if (field.path?.length > 0) {
                return this.getResolvedPropertyPath(field)[field.name + 'Columns'];
            } else {
                return this.orchestratorViewModel.rootViewModel[field.name + 'Columns'];
            }
        }
    }

    getExternalLayoutMetaData(field: FieldMetaData): ExternalLayoutMetaData {
        return field as ExternalLayoutMetaData; 
    }

    getExternalPropertyViewModelFromExternalMetaData(field: ExternalLayoutMetaData): ExternalViewModelInterface {
        return this.getPropertyFromFieldMetaData(field) as ExternalViewModelInterface;
    }

    getExternalPropertyViewModelCodeFromExternalMetaData(field: ExternalLayoutMetaData) {
        const externalPvm = this.getExternalPropertyViewModelFromExternalMetaData(field);
        return externalPvm[field.externalFields[0].name];
    }

    getExternalPropertyViewModelDecodeFromExternalMetaData(field: ExternalLayoutMetaData) {
        const externalPvm = this.getExternalPropertyViewModelFromExternalMetaData(field);
        return externalPvm[field.externalDecodes[0]];
    }

    getResolvedPropertyPath(field: FieldMetaData) {
        const correctedPath = this.fixSelectedItemInPath(field.path);
        return correctedPath.split('.').reduce((acc, current) => acc[current], this.orchestratorViewModel.rootViewModel);
    }

    /**
     * Corregge i path che hanno i selected item
     * @param path path da correggere
     * @returns path corretto
     */
    fixSelectedItemInPath(path) {
        return path.replace(/selectedItem/g, 'selectedItemOrMocked');
    }
}

<nts-loader [isVisible]="orchestratorViewModel.actionInProgress || !orchestratorViewModel.documentEnabled"
    [text]="loaderText" [animation]="loaderAnimation" [skipHeader]="false"></nts-loader>

<div class="main-container" [class.has-header-bar]="!inIframe" [ngClass]="orchestratorViewModel.customClasses">
    <form class="root-form layout-wide">
        <div class="layout-page layout-page-embedded">
            <div class="layout-gutter-left"></div>
            <div class="layout-content">

                <!-- header -->
                <nts-page-header *ngIf="orchestratorViewModel?.showPageHeader" [orchestratorViewModel]="orchestratorViewModel" [updateStatus]="updateStatus" (onUpdateStatusClicked)="showUpdateNotificationToast($event)"></nts-page-header>

                <div class="layout-body">

                    <div class="layout-gutter-left"></div>
                    <div class="layout-content">
                        <!-- sidebar sinistra (filtri/master)-->
                        <div *ngIf="viewModelLoaded && (isMasterDetail || hasSnapShotFeature)" 
                            class="layout-aside-left layout-expandable"
                            [@sideBarLeftOpenClose]="((orchestratorViewModel.eventDispatcher.onNavigationPanelVisibilityChanged$ | async) ? (orchestratorViewModel.eventDispatcher.onNavigationPanelCollapsed | async) ? 'closed' : ( masterViewModel?.isFullScreen ? 'fullScreen' : 'open') : 'closed')">
                            <nts-navigation-panel class="navigation-panel"
                                (onMasterAreaSelected)="orchestratorViewModel.eventDispatcher.onMasterAreaSelected.next(true)"
                                (onSnapShotSelected)="orchestratorViewModel.eventDispatcher.onSnapShotSelected.next(true)"
                                (onNavigationPanelCollapsed)="orchestratorViewModel.eventDispatcher.onNavigationPanelCollapsed.next(true)"
                                [snapShotListViewModel]="snapShotListViewModel" 
                                [masterViewModel]="masterViewModel"
                                [eventDispatcher]="orchestratorViewModel.eventDispatcher">
                            </nts-navigation-panel>
                        </div>

                        <div class="nts-content" [class.layout-collapsed]="masterViewModel?.isFullScreen">
                            <!-- detail -->
                            <div class="nts-content-header" [class.has-border]="(viewModelLoaded && ((isMasterDetail && masterViewModel != null) || hasSnapShotFeature)) || (viewModelLoaded && orchestratorViewModel?.showToolBar) || (viewModelLoaded && !(orchestratorViewModel.eventDispatcher.onNotificationBarCollapsed | async)) || (viewModelLoaded && !(orchestratorViewModel.eventDispatcher.onValidationBarCollapsed | async)) || (viewModelLoaded && orchestratorViewModel.wingViewModel)">

                                <div class="aside-left-toggle-container" 
                                    *ngIf="viewModelLoaded && ((isMasterDetail && masterViewModel != null) || hasSnapShotFeature)" 
                                    [@sideBarLeftToggleVisibility]="(orchestratorViewModel.eventDispatcher.onNavigationPanelVisibilityChanged$ | async) ? ((orchestratorViewModel.eventDispatcher.onNavigationPanelCollapsed | async)  ? 'visible' : 'hidden') : 'hidden'">
                                    <nts-text-button (onClicked)="orchestratorViewModel.eventDispatcher.onNavigationPanelCollapsed.next(false)" [icon]="'sidebar-expand'"></nts-text-button>
                                </div>

                                <!-- toolbar -->
                                <nts-tool-bar *ngIf="viewModelLoaded && orchestratorViewModel?.showToolBar"
                                    [commandsGroup]="orchestratorViewModel.toolBarViewModel.commandsPage[0]?.commandGroups"
                                    [defaultCommand]="orchestratorViewModel.toolBarViewModel.defaultCommand"
                                    [defaultCommandType]="orchestratorViewModel.toolBarViewModel.defaultCommandType"                         
                                    [moreOptionsCommand]="orchestratorViewModel.toolBarViewModel.getMoreOptionsCommand()"
                                    [moreOptionsMenuItemList$]="moreOptionsMenuItemList$"
                                    [mobileMenuItemList$]="mobileMenuItemList$"
                                    [mobileMenuCommand]="orchestratorViewModel.toolBarViewModel.getMobileMenuCommand()"
                                    (onToolBarReady)="onToolBarReady()">
                                </nts-tool-bar>

                                <!-- notification -->
                                <nts-notification *ngIf="viewModelLoaded && !(orchestratorViewModel.eventDispatcher.onNotificationBarCollapsed | async)" 
                                    [orchestratorViewModel]="orchestratorViewModel" 
                                    [class.has-layout-aside-right]="viewModelLoaded && orchestratorViewModel.wingViewModel" 
                                    [class.layout-aside-right-collapsed]="orchestratorViewModel.eventDispatcher.onWingCollapsed  | async"
                                    [class.has-margin]="viewModelLoaded && (isMasterDetail || hasSnapShotFeature) && orchestratorViewModel.eventDispatcher.onNavigationPanelCollapsed | async">
                                </nts-notification>

                                <!--validation -->
                                <nts-validation *ngIf="viewModelLoaded && !(orchestratorViewModel.eventDispatcher.onValidationBarCollapsed | async)" 
                                    [orchestratorViewModel]="orchestratorViewModel"
                                    [class.has-layout-aside-right]="viewModelLoaded && orchestratorViewModel.wingViewModel" 
                                    [class.layout-aside-right-collapsed]="orchestratorViewModel.eventDispatcher.onWingCollapsed  | async"
                                    [class.has-margin]="viewModelLoaded && (isMasterDetail || hasSnapShotFeature) && orchestratorViewModel.eventDispatcher.onNavigationPanelCollapsed | async">
                                </nts-validation>                                

                                <!-- sidebar destra (wing) -->
                                <div *ngIf="viewModelLoaded && orchestratorViewModel.wingViewModel"
                                    class="layout-aside-right" [class.layout-collapsed]="orchestratorViewModel.eventDispatcher.onWingCollapsed | async">
                                    
                                    <nts-wing-container class="scrollable-content" [wingViewModel]="orchestratorViewModel.wingViewModel">
                                    </nts-wing-container>
                                    <div class="wing-toggle" (click)="orchestratorViewModel.eventDispatcher.onWingCollapsed.next(!orchestratorViewModel.wingViewModel.isCollapsed)">
                                        <svg-icon [key]="orchestratorViewModel.wingViewModel.isCollapsed ? 'arrow-back' : 'arrow-forward'"></svg-icon>
                                    </div>
                                </div>
                            </div>
                            <div class="nts-document-content" [class.has-tool-bar]="orchestratorViewModel?.showToolBar" [class.has-layout-aside-right]="viewModelLoaded && orchestratorViewModel.wingViewModel" [class.layout-aside-right-collapsed]="orchestratorViewModel.eventDispatcher.onWingCollapsed  | async">

                                <div class="layout-main scrollable-content" 
                                [class.wing-closed]="orchestratorViewModel?.wingViewModel?.isCollapsed != null ? (orchestratorViewModel.wingViewModel.isCollapsed) : null"
                                [class.wing-opened]="orchestratorViewModel?.wingViewModel?.isCollapsed != null ? (!orchestratorViewModel.wingViewModel.isCollapsed) : null"
                                    [class.layout-collapsed]="masterViewModel?.isFullScreen">

                                    <div class="layout-gutter-left"></div>
                                    <div class="layout-content document-placeholder-container">

                                        <!-- detail content -->
                                        <ng-template #documentPlaceHolder></ng-template>

                                    </div>
                                    <div class="layout-gutter-right"></div>

                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="layout-gutter-right">
                        
                    </div>

                </div>

            </div>
            <div class="layout-gutter-right"></div>
        </div>

    </form>
</div>
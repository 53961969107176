import { Observable, of } from 'rxjs';
import { ModifiedViewModelState } from './modified-view-model-state';
import { NewViewModelState } from './new-view-model-state';
import { VersionedViewModelState } from './versioned-view-model-state';
import { ViewModelState } from './view-model-state';
import { ViewModelStateContextInterface } from './view-model-state-context.interface';
import { ViewModelStates } from './view-model-states';


export class UnchangedViewModelState extends ViewModelState {
  constructor(context: ViewModelStateContextInterface) {
    super(context);
  }

  get value() {
    return ViewModelStates.Unchanged;
  }

  override canCreate(): Observable<boolean> {
    return of(!this.startedAsRelatedClient || !this.viewModel);
  }

  override create() {
    this.context.currentState = new NewViewModelState(this.context);
  }

  override canRemove(): Observable<boolean> {
    return of(!this.context.isDeactivable && !this.startedAsRelatedClient);
  }

  override remove() {
    this.context.currentState = new NewViewModelState(this.context);
  }

  override canStoreAndCreate(): Observable<boolean> {
    return of(false);
  }

  override storeAndCreate() {
    this.context.currentState = new NewViewModelState(this.context);
  }

  override getByIdentity() {
    this.context.currentState = new UnchangedViewModelState(this.context);
  }

  override modify() {
    this.context.currentState = new ModifiedViewModelState(this.context);
  }

  override canStartRelatedClient() {
    return of(true);
  }

  override canRestore(): Observable<boolean> { return of(false); }

  override restore() {
    this.context.currentState = new UnchangedViewModelState(this.context);
  }

  override canStore(): Observable<boolean> {
    return of(true);
  }

  override store() {
    this.context.currentState = new UnchangedViewModelState(this.context);
  }

  override canVersioned(): Observable<boolean> {
    return of(true);
  }

  override getBySnapShotIdentity() {
    this.context.currentState = new VersionedViewModelState(this.context);
  }
}

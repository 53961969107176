import { NgClass, NgIf } from '@angular/common';
import { CoreOrchestratorViewModelInterface } from '../../view-models/core-orchestrator-view-model.interface';
import { Input, Component } from '@angular/core';

@Component({
    selector: 'nts-messages-area-container',
    templateUrl: './messages-area-container.component.html',
    styleUrls: ['./messages-area-container.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgClass
    ]
})
export class MessagesAreaContainerComponent {

    @Input() orchestratorViewModel: CoreOrchestratorViewModelInterface;
    @Input() viewModelLoaded: boolean;
}

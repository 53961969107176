import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { BaseZoomFilterPropertyComponent } from '../base-zoom-filter-property-component';
import { DateTimeZoomPropertyViewModel } from '../../../../view-models/zoom/property-view-model/date-time-zoom-property-view-model';
import { BaseDateTextBoxComponent } from '../../../controls/core/base/base-date-text-box/base-date-text-box.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateTimeZoomFilterViewModel } from '../../../../view-models/zoom/filter-view-model/date-time-zoom-filter-view-model';

@UntilDestroy()
@Component({
  selector: 'nts-zoom-filter-date-text-box',
  templateUrl: './zoom-filter-date-text-box.component.html',
  styleUrls: ['./zoom-filter-date-text-box.component.scss'],
  standalone: true,
  imports: [
    BaseDateTextBoxComponent
  ]
})
export class ZoomFilterDateTextBoxComponent extends BaseZoomFilterPropertyComponent<Date> implements OnInit, OnChanges, AfterViewInit {

  @Input() filter: DateTimeZoomFilterViewModel;
  @Input() dateTabIndex!: number;
  
  @Output() onFinishEditing = new EventEmitter();
  
  @ViewChild('baseDateTextBox', { static: false }) baseDateTextBox!: BaseDateTextBoxComponent;
  
  override propertyViewModel!: DateTimeZoomPropertyViewModel;
  value!: Date|null;

  get input(): HTMLInputElement {
    return this.baseDateTextBox.dateMaskBox.dateBox.nativeElement;
  }

  constructor(private cd: ChangeDetectorRef) {
    super();
  }
  
  ngOnInit() {
    if (!this.propertyViewModel) { throw new Error('Missing viewModel!'); }
    this.value = this.propertyViewModel.typedValue;

    this.filter.isEnableStatusChanged.pipe(untilDestroyed(this)).subscribe(() => {
      this.cd.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    super.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.propertyViewModel) {
      this.value = this.propertyViewModel.typedValue;
    }
  }

  onDateSelect($event: any) {
    this.propertyViewModel.typedValue = this.value;
  }

  onDateBlur() {
    if (this.value == null) {
      this.propertyViewModel.typedValue = null;
    } else {
      this.propertyViewModel.typedValue = this.value;
    }
  }

  onDateFocus($event: any): void {
    this.input.select();
  }

  onDateValueChange($event: Date | null): void {
    this.value = $event
    
    this.onDateBlur();
    //this.onFinishEditing.emit(this.valueDate);
  }
  
}

<ng-template *ngIf="propertyViewModel?.securityAccess == null; then baseComponent else securityComponent"></ng-template>

<ng-template #baseComponent>
    <nts-base-numeric-box #baseNumericBox (onBlur)="blur($event)" (onFocus)="selectAllContent($event)"
        [maskSettings]="this.maskSettings" [minValue]="this.propertyViewModel.minValue ?? null"
        [maxValue]="this.propertyViewModel.maxValue ?? null" [tabIndex]="tabIndex"
        [showErrorTooltip]="showErrorTooltip"
        [listenClickOutside]="listenClickOutside"
        [showErrorBorder]="showErrorBorder"
        [customCommandList]="customCommandList"
        [defaultBorderColor]="propertyViewModel.defaultColor"
        (onFinishEditing)="onFinishEditing.emit($event)"
        [activeBorderColor]="propertyViewModel.activeColor"
        [hoverBorderColor]="propertyViewModel.hoverColor"
        [errorList]="propertyViewModel?.errors$ | async"
        [isDisabled]="isDisabled || (!softDisable && !propertyViewModel.isEnabled)"
        [value]="initialValue != null ? initialValue : propertyViewModel.value"
        [isReadonly]="isDisabled || (softDisable && !propertyViewModel.isEnabled)" [placeholder]="placeholder" 
        [attr.data-property-name]="propertyViewModel.propertyName">
    </nts-base-numeric-box>
</ng-template>

<ng-template #securityComponent>
    <nts-base-security-text-box 
        #baseSecurityTextBox [accessMode]="propertyViewModel.securityAccess"
        [value]="initialValue != null ? initialValue : propertyViewModel.formattedValue"></nts-base-security-text-box>
</ng-template>

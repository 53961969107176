import { registerDecorator, ValidationArguments, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator';
import { BaseValidator } from './commons/base-validator';
import { AllowedCharsValidation } from './validations/allowed-chars-validation';
import { AllowedChars } from '../../meta-data/allowed-chars';
import { StringMetaData } from '../../meta-data/string-meta-data';
import { BaseValidationDecoratorInterface } from './commons/base-validation-decorator.interface';
import { MetaDataUtils } from '../../meta-data/meta-data-utils';

export interface TextDecoratorInterface extends BaseValidationDecoratorInterface {
    allowedChars?: AllowedChars;
}

@ValidatorConstraint({ name: 'textValidator', async: false })
export class TextValidator extends BaseValidator<string> implements ValidatorConstraintInterface {

    override validate(value: string, args: ValidationArguments): boolean {
        const decorator = args.constraints[0] as TextDecoratorInterface;
        const result = super.validate(value, args) &&
            this.validateAllowedChars(value, decorator.allowedChars, args);

        return result;
    }

    validateAllowedChars(value: string, allowedChars: AllowedChars, args?: ValidationArguments) {
        const allowCharsValidation = new AllowedCharsValidation(allowedChars);
        if (!allowCharsValidation.validate(value, args)) {
            this.errorMessage = allowCharsValidation.errorMessage;
            return false;
        }
        return true;
    }

    protected static override  getPropertyMetaDataFromDecoratorData<TDecoratorData extends BaseValidationDecoratorInterface>(
        decoratorData: TDecoratorData, propertyName: string) {
        const propertyMetaData = new StringMetaData();
        propertyMetaData.context = decoratorData.context;
        // propertyMetaData.maxLen = (decoratorData as TextDecoratorInterface).maxLength;
        propertyMetaData.isRequired = (decoratorData as TextDecoratorInterface).isRequired;
        propertyMetaData.descriptions.descriptionKey = (decoratorData as TextDecoratorInterface).descriptionKey;
        propertyMetaData.descriptions.displayNameKey = (decoratorData as TextDecoratorInterface).displayNameKey;
        propertyMetaData.name = MetaDataUtils.toPascalCase(propertyName);
        return propertyMetaData;
    }
}

export function TextDecorator(decoratorInterface: TextDecoratorInterface) {
    return (object: object, propertyName: string) => {

        // Metodo di base per tutti i decoratori
        BaseValidator.initBaseValidator(decoratorInterface, object, propertyName);

        // Aggiunge informazioni alla property sulla validazione
        TextValidator.buildPropertyMetaData<TextDecoratorInterface>(
            object, propertyName, decoratorInterface);

        registerDecorator({
            target: object.constructor,
            propertyName,
            options: {context: decoratorInterface.context},
            constraints: [decoratorInterface],
            validator: TextValidator
        });
    };
}

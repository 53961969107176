import { RuntimeVariables } from '../../../domain-models/find-options/filter';

export class FilterVariableViewModel {
    value: RuntimeVariables;
    label: string;

    constructor(value: RuntimeVariables, label: string) {
        this.value = value;
        this.label = label;
    }
}

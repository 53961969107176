import { NgModule } from '@angular/core';
import { LoaderComponent } from './loader/loader.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { NgInitDirective } from './init/ng-init.directive';
import { ButtonsModule } from './buttons/button.module';
import { MenuItemContentComponent } from './menu/menu-item-content.component';
import { ExpandableComponent } from './expandable/expandable.component';

@NgModule({
    imports: [
        ButtonsModule,
        CheckboxComponent,
        ExpandableComponent,
        NgInitDirective,
        LoaderComponent,
        MenuItemContentComponent,
        SpinnerComponent
    ],
    declarations: [
        
    ],
    exports: [
        ButtonsModule,
        CheckboxComponent,
        ExpandableComponent,
        NgInitDirective,
        LoaderComponent,
        MenuItemContentComponent,
        SpinnerComponent        
    ],
    providers: []
})
export class SharedModule {

}

import { GenericServiceResponse } from './generic-service-response';
import { AggregateMetaData } from '../meta-data';
import { ResponseCachedDecorator } from './decorators/response-cached.decorator';
import { ResponseResultTypeDecorator } from './decorators/response-result-type.decorator';

@ResponseCachedDecorator()
@ResponseResultTypeDecorator(AggregateMetaData)
export class MetaDataResponse extends GenericServiceResponse<AggregateMetaData> {

}

import { ComponentLocator } from '../components/component-locator';
import { StaticProvider } from '@angular/core';
import { NotString } from '../types';

// Vedi https://github.com/ag-grid/ag-grid/issues/3415
// export function patchAgGrid() {
//     // Prevent a DOMException after editing cells with a custom cell editor.
//     Utils.clearElement = (el: HTMLElement) => {
//         el.innerHTML = '';
//     };
// }

export abstract class NtsAppModule {
    abstract registerAll(...params);

    constructor(
        protected componentLocator: ComponentLocator,
        ...params) {

        this.registerAll(...params);

        // patchAgGrid();
    }

    registerAllForDomainModelType(
        domainModelName: string,
        orchestratorViewModelType: any,
        rootViewModelType: any,
        componentType: any,
        apiClientType: any,
        wingViewModelType?: any,
        wingComponentType?: any
    ) {

        this.componentLocator.registerAllForDomainModelType(
            domainModelName,
            orchestratorViewModelType,
            rootViewModelType,
            componentType,
            apiClientType,
            wingViewModelType,
            wingComponentType
        );
    }

    registerAllForDomainModelTypeWithProviders(
        domainModelName: string,
        orchestratorViewModelType: NotString<any>,
        rootViewModelType: NotString<any>,
        componentType: NotString<any>,
        orchestratorViewModelProviders: StaticProvider[],
        wingViewModelType?: NotString<any>,
        wingComponentType?: NotString<any>
    ) {

        this.componentLocator.registerAllForDomainModelTypeWithProviders(
            domainModelName,
            orchestratorViewModelType,
            rootViewModelType,
            componentType,
            orchestratorViewModelProviders,
            wingViewModelType,
            wingComponentType
        );

    }

}

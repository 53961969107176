import { ZoomApiClient } from "../../../../api-clients/zoom/zoom-api-client";
import { map, of, firstValueFrom, Observable, startWith } from "rxjs";
import { ToastMessageService } from "../../../../components/layout/toast-message/toast-message.service";
import { FilledButtonType } from "../../../../components/shared/buttons/filled-button/filled-button.component";
import { ViewModelTypeDecorator } from "../../../../decorators/view-model-type.decorator";
import { StringDecorator } from "../../../../domain-models/decorators/string.decorator";
import { StringMetaData } from "../../../../meta-data/string-meta-data";
import { MessageResourceManager } from "../../../../resources/message-resource-manager";
import { StringPropertyViewModel } from "../../../base-type/string-property-view-model";
import { CommandFactory } from "../../../commands/command-factory";
import { UIResultCommandInterface } from "../../../commands/ui-result-command.interface";
import { CustomPropertyViewModelDecorator } from "../../../decorators/custom-property-view-model.decorator";
import { ModalViewModelInterface } from "../../../modal/modal-view-model.interface";
import { ModalService } from "../../../modal/modal.service";
import { ModifiedSubscriberInterface } from "../../../modified-subscriber.interface";
import { PropertyViewModelFactory } from "../../../property-view-model-factory";
import { ViewModelEventDispatcher } from "../../../view-model-event-dispatcher";
import { ZoomConfigurationDto } from "../../../../domain-models/zoom/dto/zoom-configuration.dto";



@ViewModelTypeDecorator(AddZoomModalViewModel)
export class AddZoomModalViewModel implements ModalViewModelInterface<any, any> {

    filledButtonType = FilledButtonType;

    modalTitle = MessageResourceManager.Current.getMessage('std_AddZoomModalViewModel_Title');
    modalSubtitle = '';
    modalCommands = [];
    showFooter = true;
    adding = false;

    modifiedSubscriber: ModifiedSubscriberInterface;
    eventDispatcher: ViewModelEventDispatcher;

    private confirmCommand: UIResultCommandInterface<ZoomConfigurationDto, any>;
    zoomConfiguration: ZoomConfigurationDto;

    constructor(
        public modalService: ModalService,
        public toastMessageService: ToastMessageService,
        public zoomApiClient: ZoomApiClient,
    ) {
        this.setupCommands();
    }

    canAddZoom(): Observable<boolean> {
        return this.name.propertyChanged.pipe(
        startWith(null),
        map(()=>(this.name?.value?.length > 0 && this.adding === false)));
    }

    async addZoom(): Promise<ZoomConfigurationDto> {
        this.adding = true;
        this.name.isEnabled = false;

        this.zoomConfiguration.title = this.name.value;

        const response = await firstValueFrom(this.zoomApiClient.setZoomDataAsync(this.zoomConfiguration));
        this.toastMessageService.showToastsFromResponse(response);
        
        if(response.operationSuccedeed){
            this.confirmCommand.closeModal = true;
            this.zoomConfiguration.zoomIdentity = response.result;
        } else {
            this.confirmCommand.closeModal = false;
        }
        this.adding = false;
        this.name.isEnabled = true;
        
        return this.zoomConfiguration;
    }

    private setupCommands() {

        this.confirmCommand = CommandFactory.createResultUICommand(
            () => this.addZoom(),
            () => this.canAddZoom()
        );
        this.confirmCommand.displayName = MessageResourceManager.Current.getMessage('std_CMD_AddZoomModalViewModel_Confirm_DisplayName');
        this.confirmCommand.closeModal = false;
        this.confirmCommand.isDefault = true;
        
        this.modalCommands.push(this.confirmCommand);
    }

    async initialize(): Promise<void> {
    }

     // tslint:disable-next-line: member-ordering variable-name
     private _name: StringPropertyViewModel;

    @CustomPropertyViewModelDecorator()
    @StringDecorator({ displayNameKey: 'std_AddZoomModalViewModel_Name_DisplayName' })
    public get name(): StringPropertyViewModel {
        if (this._name == null) {
            const init = PropertyViewModelFactory.createPVMInitializationInfo<StringMetaData>(
                this, 'name', null, this, this.modifiedSubscriber, this.eventDispatcher, null, false, false
            );
            this._name = new StringPropertyViewModel(init);
            this._name.instantModelChange = true;
        }
        return this._name;
    }

}

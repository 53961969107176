import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Notify } from './models/notify';


@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  newNotifyArrived = new ReplaySubject<Notify>();
  notifyChanged = new BehaviorSubject<Notify>(null);
  private _notifyList = Array<Notify>();

  constructor() {
    this._notifyList = []

  }

  addNotify(oNotify: Notify) {
    this._notifyList.push(oNotify);
    this.notifyChanged.next(null);
  }
  getNotifyList() {
    return this._notifyList;
  }

  removeNotify(id: string) {
    for (let i = this._notifyList.length - 1; i >= 0; i -= 1) {
      if (this._notifyList[i].id === id) {
        this.removeNotifyByIndex(i);
        break;
      }
    }
    this.notifyChanged.next(null);
  }

  private removeNotifyByIndex(index: number) {
    this._notifyList.splice(index, 1);
  }

  markAllAsRead() {
    this._notifyList = [];
    this.notifyChanged.next(null);
  }


}


import { Expose } from '../../serialization/class-transformer/decorators';
import { AutoCompleteOptions } from './auto-complete-options';

export class AutoCompleteExternalOptions extends AutoCompleteOptions {

    /**
     * Full name dell'external per cui si vuole eseguire l'autocomplete
     */
    @Expose()
    fullRootModelName: string;

}

import { ZoomFilterViewModel } from './zoom-filter-view-model';
import { ModifiedSubscriberInterface } from '../../modified-subscriber.interface';
import { ZoomMetaData } from '../../../meta-data/zoom-meta-data';
import { ZoomOperatorViewModelProperty } from '../zoom-operator-view-model-property';
import { EnumZoomPropertyViewModel } from '../property-view-model/enum-zoom-property-view-model';
import { ZoomPropertyViewModelInterface } from '../property-view-model/zoom-property-view-model.interface';
import { FilterOperators } from '../../../domain-models/find-options/filter';

export class EnumZoomFilterViewModel extends ZoomFilterViewModel {

    constructor(metadata: ZoomMetaData, private modifiedSubscriber: ModifiedSubscriberInterface) {
        super(metadata);
        this.operator = new ZoomOperatorViewModelProperty(
            ZoomOperatorViewModelProperty.operatorsForEnum,
            modifiedSubscriber
        );
        this.filterValue = new EnumZoomPropertyViewModel(metadata.enumMetaData, modifiedSubscriber);
        this.internalZoomPropertyViewModelValues = [new EnumZoomPropertyViewModel(metadata.enumMetaData, modifiedSubscriber)]
        this.init();
    }

    override addNewFilterInValues() {
        let vm: ZoomPropertyViewModelInterface<number>;
        vm = this.getEmptyFilter();
        vm.setEnabled(true);
        if (this.filterValues?.length > 0) {
            this.setZoomPropertyViewModelValues([...this.filterValues, vm]);
        } else {
            this.setZoomPropertyViewModelValues([vm]);
        }    
    }

    override deleteFilterInValues(inToRemove: ZoomPropertyViewModelInterface<number>) {
        const indexToRemove = this.filterValues.indexOf(inToRemove);
        
        if (this.filterValues?.length > 0 && indexToRemove > -1) {
          this.filterValues.splice(indexToRemove, 1);
          this.modifiedSubscriber.notifyModified();
        }
    }

    protected override async onOperatorChanged(value: FilterOperators): Promise<void> {
        await super.onOperatorChanged(value);
        if (this.OperatorDoesNotRequireValue) {
            for (const filter of this.filterValues) {
                filter.setEnabled(false);
                await filter.resetValue();
            }
        } else {
            for (const filter of this.filterValues) {
                filter.setEnabled(true);
            }
        }
    }

    getEmptyFilter(): EnumZoomPropertyViewModel {
        return new EnumZoomPropertyViewModel(this.metaData.enumMetaData, this.modifiedSubscriber);
    }

    setZoomPropertyViewModelValuesFromEntities(entities: Array<number>) {
        this.setZoomPropertyViewModelValues(entities.map((e:number) => {
            const vm: ZoomPropertyViewModelInterface<number> = this.getEmptyFilter();
            vm.setValue(e);
            return vm;
        }))
    }

    get filterValues(): Array<ZoomPropertyViewModelInterface<number>> {
        return this.internalZoomPropertyViewModelValues;
    }

    private internalZoomPropertyViewModelValues: Array<ZoomPropertyViewModelInterface<number>> = [];

    setZoomPropertyViewModelValues(pvmToSet: Array<ZoomPropertyViewModelInterface<number>>) {
        if (this.internalZoomPropertyViewModelValues != pvmToSet) {
            this.internalZoomPropertyViewModelValues = pvmToSet;
            this.modifiedSubscriber.notifyModified();
        }   
    }
}

import { Inject, InjectionToken, Optional } from '@angular/core';
import { Injectable } from '@angular/core';
import { LogEntry, TenantProfileLoggerAwareInterface, UserProfileLoggerAwareInterface } from './log-entry';
import { LogLevel } from './log-level.enum';
import { LogPublisher } from './log-publisher';
import { LogPublishersService } from './log-publisher.service';
import { Router } from '@angular/router';

// #Reference: https://www.codemag.com/article/1711021/Logging-in-Angular-Applications
// #Application insight: https://timdeschryver.dev/blog/configuring-azure-application-insights-in-an-angular-application#installing-application-insights-libraries
// https://docs.microsoft.com/it-it/azure/azure-monitor/app/javascript-angular-plugin

export const LOG_LEVEL = new InjectionToken<LogLevel>('utility.log.level');
export const LOG_DECYCLE_LIB = new InjectionToken<DecycleLibInterface>('utility.log.decycle.lib');

export interface DecycleLibInterface {
    decycle<T>(obj: T): T;
}

@Injectable({
    providedIn: 'root'
})
export class LogService {

    static logService: LogService;
    private static userProfile: UserProfileLoggerAwareInterface|null;
    private static tenantProfile: TenantProfileLoggerAwareInterface|null;

    logWithDate: boolean = true;
    logWithTenant: boolean = true;
    logWithUser: boolean = true;
    publishers: LogPublisher[];
    userDescription = '';
    tenantDescription = '';
    
    constructor(
        private readonly publishersService: LogPublishersService,
        private router: Router,
        @Inject(LOG_DECYCLE_LIB) private readonly decycleLib: DecycleLibInterface,

        @Optional()
        @Inject(LOG_LEVEL) private readonly logLevel: LogLevel
    ) {
        // Set publishers
        this.publishers = this.publishersService.publishers;
        this.logLevel = this.logLevel ?? LogLevel.All;
    }

    static updateUserProfile(userProfile: UserProfileLoggerAwareInterface) {
        this.userProfile = userProfile;        
    }

    static updateTenantProfile(tenantProfile: TenantProfileLoggerAwareInterface) {
        this.tenantProfile = tenantProfile;
    }

    static clearProfiles() {
        this.userProfile = null
        this.tenantProfile = null;
    }

    debug(msg: string, ...optionalParams: any[]) {
        this.writeToLog(msg, LogLevel.Debug, optionalParams);
    }

    info(msg: string, ...optionalParams: any[]) {
        this.writeToLog(msg, LogLevel.Info, optionalParams);
    }

    warn(msg: string, ...optionalParams: any[]) {
        this.writeToLog(msg, LogLevel.Warn, optionalParams);
    }

    error(msg: string, ...optionalParams: any[]) {
        this.writeToLog(msg, LogLevel.Error, optionalParams);
    }

    fatal(msg: string, ...optionalParams: any[]) {
        this.writeToLog(msg, LogLevel.Fatal, optionalParams);
    }

    log(msg: string, ...optionalParams: any[]) {
        this.writeToLog(msg, LogLevel.All, optionalParams);
    }

    static debug(msg: string, ...optionalParams: any[]) {
        this.logService.debug(msg, optionalParams);
    }

    static info(msg: string, ...optionalParams: any[]) {
        this.logService.info(msg, optionalParams);
    }

    static warn(msg: string, ...optionalParams: any[]) {
        this.logService.warn(msg, optionalParams);
    }

    static error(msg: string, ...optionalParams: any[]) {
        this.logService.error(msg, optionalParams);
    }

    static fatal(msg: string, ...optionalParams: any[]) {
        this.logService.fatal(msg, optionalParams);
    }

    static log(msg: string, ...optionalParams: any[]) {
        this.logService.log(msg, ...optionalParams);
    }

    private writeToLog(msg: string, level: LogLevel, params: any[]) {
        if (this.shouldLog(level)) {
            let entry: LogEntry = new LogEntry();
            entry.message = msg;
            entry.level = level;
            entry.extraInfo = params;
            entry.logWithDate = this.logWithDate;
            entry.logWithTenant = this.logWithTenant;
            entry.logWithUser = this.logWithUser;
            entry.tenantProfile = LogService.tenantProfile;
            entry.userProfile = LogService.userProfile;
            entry.decycleLib = this.decycleLib;
            for (let logger of this.publishers) {
                logger.log(entry);
            }
        }
    }

    private shouldLog(level: LogLevel): boolean {
        let ret: boolean = false;
        if ((level >= this.logLevel && level !== LogLevel.Off) || this.logLevel === LogLevel.All) {
            ret = true;
        }
        return ret;
    }
}

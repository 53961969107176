import { NgModule } from '@angular/core';
import { CoreComponentsModule } from './core/core.module';
import { DecodeTextBoxComponent } from './decode-text-box/decode-text-box.component';
import { SearchTextBoxComponent } from './search-text-box/search-text-box.component';
import { SpacerBoxComponent } from './spacer-box/spacer-box.component';
import { LabelledTextBoxComponent } from './labelled-text-box/labelled-text-box.component';
import { BoolCellRendererComponent } from './grid/cell_render_components/bool-cell-renderer/bool-cell-renderer.component';
import { DateCellEditorComponent } from './grid/cell_edit_components/date-cell-editor/date-cell-editor.component';
import { DateCellRendererComponent } from './grid/cell_render_components/date_cell_renderer.component';
import { EnumCellEditorComponent } from './grid/cell_edit_components/enum-cell-editor/enum-cell-editor.component';
import { EnumCellRendererComponent } from './grid/cell_render_components/enum_cell_renderer.component';
import { NumericCellEditorComponent } from './grid/cell_edit_components/numeric-cell-editor/numeric-cell-editor.component';
import { NumericCellRendererComponent } from './grid/cell_render_components/numeric_cell_renderer.component';
import { ExternalNumericCellEditorComponent } from './grid/cell_edit_components/external-numeric-cell-editor/external-numeric-cell-editor.component';
import { ExternalStringCellEditorComponent } from './grid/cell_edit_components/external-string-cell-editor/external-string-cell-editor.component';
import { CustomHeaderComponent } from './grid/header_components/custom_header_component';
import { CommandCellRendererComponent } from './grid/cell_render_components/command_cell_renderer.component';
import { GridComponent } from './grid/grid.component';
import { DefaultCellRendererComponent } from './grid/cell_render_components/default_cell_renderer.component';
import { ExternalCellRendererComponent } from './grid/cell_render_components/external-cell-renderer.component';
import { DateOffsetCellEditorComponent } from './grid/cell_edit_components/date-offset-cell-editor/date-offset-cell-editor.component';
import { StringCellEditorComponent } from './grid/cell_edit_components/string-cell-editor/string-cell-editor.component';
import { BoolCellEditorComponent } from './grid/cell_edit_components/bool-cell-editor/bool-cell-editor.component';
import { CustomFieldContainerComponent } from './custom-field-container/custom-field-container.component';
import { DefaultHeaderComponent } from './grid/header_components/default_header_component';
import { ExternalListComponent } from './external-list/external-list.component';
import { ExternalListCellEditorComponent } from './grid/cell_edit_components/external-list-cell-editor/external-list-cell-editor.component';
import { ColumnsSideBarComponent } from './grid/columns-side-bar/columns-side-bar.component';
import { GridSettingsModalContainerComponent } from './grid/grid-settings-modal-container/grid-settings-modal-container.component';

@NgModule({
    imports: [
        CoreComponentsModule,

        CustomFieldContainerComponent,
        DecodeTextBoxComponent,
        ExternalListComponent,
        
        GridComponent,

        BoolCellEditorComponent,
        DateCellEditorComponent,
        DateOffsetCellEditorComponent,
        EnumCellEditorComponent,
        ExternalListCellEditorComponent,
        ExternalNumericCellEditorComponent,
        ExternalStringCellEditorComponent,
        NumericCellEditorComponent,
        StringCellEditorComponent,
        BoolCellRendererComponent,
        CommandCellRendererComponent,
        DateCellRendererComponent,        
        DefaultCellRendererComponent,
        EnumCellRendererComponent,
        ExternalCellRendererComponent,
        NumericCellRendererComponent,
        ColumnsSideBarComponent,
        GridSettingsModalContainerComponent,
        CustomHeaderComponent,
        DefaultHeaderComponent,
        
        LabelledTextBoxComponent,
        SearchTextBoxComponent,
        SpacerBoxComponent,
    ],
    exports: [
        CoreComponentsModule,

        CustomFieldContainerComponent,
        DecodeTextBoxComponent,
        ExternalListComponent,
        
        GridComponent,

        BoolCellEditorComponent,
        DateCellEditorComponent,
        DateOffsetCellEditorComponent,
        EnumCellEditorComponent,
        ExternalListCellEditorComponent,
        ExternalNumericCellEditorComponent,
        ExternalStringCellEditorComponent,
        NumericCellEditorComponent,
        StringCellEditorComponent,
        BoolCellRendererComponent,
        CommandCellRendererComponent,
        DateCellRendererComponent,        
        DefaultCellRendererComponent,
        EnumCellRendererComponent,
        ExternalCellRendererComponent,
        NumericCellRendererComponent,
        ColumnsSideBarComponent,
        GridSettingsModalContainerComponent,
        CustomHeaderComponent,
        DefaultHeaderComponent,
        
        LabelledTextBoxComponent,
        SearchTextBoxComponent,
        SpacerBoxComponent,


    ]
})
export class ControlsComponentModule {
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'listFilter',
    standalone: true,
})
export class ListFilterPipe implements PipeTransform {

    transform(list: any[], filterText: string): any {
        return list ? list.filter(item => item.label.search(new RegExp(filterText, 'i')) > -1) : [];
    }

}

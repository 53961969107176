import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BaseCellEditorComponent } from '../base_cell_editor.component';
import { BasePropertyComponent } from '../../../core/base-property-component';
import { BaseEnumPropertyViewModel } from '../../../../../view-models/base-type/enum-property-view-model';
import { EnumTextBoxComponent } from '../../../core/enum-text-box/enum-text-box.component';
// create your cellEditor as a Angular component
@Component({
    selector: 'nts-enum-text-box-editor-cell',
    styleUrls: ['./enum-cell-editor.component.scss'],
    standalone: true,
    imports: [
        EnumTextBoxComponent
    ],
    template: `
        <nts-enum-text-box 
            #textBox 
            (valueChange)="valueChange($event)"
            (onFinishEditing)="onFinishEditing()"
            [style.width.px]="cellWidth"
            [showErrorTooltip]="false"
            [listenClickOutside]="true"
            [propertyViewModel]="enumPropertyViewModel"
            [showErrorBorder]="false"
            [modelUpdate]="false" 
            appendTo="body">
        </nts-enum-text-box>
    `
})
export class EnumCellEditorComponent extends BaseCellEditorComponent<number> implements AfterViewInit {

    @ViewChild('textBox', { static: true }) set content(content: BasePropertyComponent<number>) {
        this.textBox = content;
    }

    override textBox: BasePropertyComponent<number>;

    get enumPropertyViewModel(): BaseEnumPropertyViewModel {
        return this.propertyViewModel as BaseEnumPropertyViewModel;
    }

    get cellWidth() {
        return this.params.eGridCell.clientWidth;
    }

    override ngAfterViewInit() {
        setTimeout(() => {
            setTimeout(() => {
                this.textBox.focus();
            });
        });
    }

    override onKeyDown(event: KeyboardEvent) {
        const key = event.which || event.keyCode;
        if (key === 37 || key === 39) {
            // keyboard navigation
            // this.forcePropertyViewModelUpdate();
            event.stopPropagation();
            event.preventDefault();
            event.stopImmediatePropagation();
            this.navigateToNextCell(key);
        }
    }
    
    onFinishEditing() {
        this.params.stopEditing();
    }

    isPopup(): boolean {
        return true;
    }
}

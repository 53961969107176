import { ValidationArguments } from 'class-validator';
import { MessageResourceManager } from '../../../resources/message-resource-manager';
import { BaseValidationInterface } from './base-validation-interface';
import { MessageFormatter } from '../../../resources/message-formatter';
import { BaseValidator } from '../commons/base-validator';

export abstract class BaseValidation<TValue> implements BaseValidationInterface<TValue> {

    errorMessage: string;
    messageCode: string;

    constructor(messageCode?: string) {
        this.messageCode = messageCode;
    }

    validate(value: TValue, args: ValidationArguments): boolean {
        this.errorMessage = this.defaultMessage(args);
        return this.validateImplementation(value, args);
    }

    getMessageWithTag(args: ValidationArguments, tag: string): string {
        return BaseValidator.getMessageWithTag(args, tag, this.messageCode);
    }

    getDisplayName(args: ValidationArguments): string {
        return BaseValidator.getDisplayName(args);
    }

    getMessageWithCodeValues(baseMessage: string, tags: { code: string, value: string }[]): string {
        return BaseValidator.getMessageWithCodeValues(baseMessage, tags);
    }

    abstract validateImplementation(value: TValue, args: ValidationArguments): boolean;

    defaultMessage(args: ValidationArguments): string {
        const displayName = this.getDisplayName(args);
        return MessageFormatter.formatString(MessageResourceManager.Current.getMessage(this.messageCode), displayName);
    }
}

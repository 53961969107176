<ng-template *ngIf="propertyViewModel?.securityAccess == null; then baseComponent else securityComponent"></ng-template>

<ng-template #baseComponent>
    <div class="autocomplete-container"
        #popperInfo="popper"
        (mouseenter)="mouseEnter($event)"  
        (mouseleave)="mouseLeave($event)"
        [popper]="selectedItem?.description ?? ''"
        [popperDisabled]="!selectedItem?.description || selectedItem?.description?.length == 0 || (showErrorTooltip && !isDisabled && externalPropertyViewModel?.securityAccess == null && code?.securityAccess == null && code?.hasErrors == true)"
        [popperTrigger]="ngxPopperjsTriggers.hover"
        popperApplyClass="info"
        [popperPlacement]="ngxPopperjsPlacements.TOP"
        [popperPreventOverflow]="false"
        [popperHideOnScroll]="true"
        popperAppendTo="body"
        [popperDelay]="1500"
        [class.zoom-disabled]="isDisabled || !externalPropertyViewModel.isEnabled || !code.isEnabled || !externalPropertyViewModel.zoomSearchIsEnabled || externalPropertyViewModel?.securityAccess != null">
        <ng-select
            #popperError="popper"
            [popper]="tooltipErrTemplate"
            [popperDisabled]="!(showErrorTooltip && !isDisabled && externalPropertyViewModel?.securityAccess == null && code?.securityAccess == null && code?.hasErrors == true)"
            [popperTrigger]="ngxPopperjsTriggers.hover"
            popperApplyClass="error"
            [popperPlacement]="ngxPopperjsPlacements.TOP"
            [popperPreventOverflow]="false"
            [popperHideOnScroll]="true"
            popperAppendTo="body"
            (blur)="onBlur()" 
            (open)="onDropdownOpen()"
            (focus)="onFocus($event)" 
            (clear)="onClear($event)"
            (change)="onSelectedItem($event)"
            (close)="onDropdownClose()"
            [(ngModel)]="selectedItem"
            [appendTo]="appendTo"
            [readonly]="isDisabled || !externalPropertyViewModel.isEnabled || !code.isEnabled || externalPropertyViewModel?.securityAccess != null"
            [loading]="itemLoading"
            [items]="items$ | async"
            [multiple]="false"
            [placeholder]="typeToSearchText" 
            [clearable]="true" 
            [selectOnTab]="false"
            [keyDownFn]="keyDownFn.bind(this)"
            [trackByFn]="trackByFn"
            [minTermLength]="0"
            [typeahead]="itemInput$"
            [class.zoom-search-hidden]="!externalPropertyViewModel.zoomSearchIsVisible"
            [class.has-error]="showErrorBorder && code.hasErrors"
            [class.is-loading]="itemLoading"
            [class.has-clear]="inputRef?.showClear()"
            [class.deactivated]="isSelectedItemDeactivated()"
            class="ext-autocomplete"
            clearAllText="{{'Pulisci' | NTSTranslate}}" 
            loadingText="{{'CaricamentoInProgress' | NTSTranslate}}"
            notFoundText="{{'NessunElementoTrovato' | NTSTranslate}}" 
            dropdownPosition="bottom"  
            bindLabel="description">
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <span [attr.title]="item.description" class="ng-option-label {{inputRef?.dropdownId}}"  [ngOptionHighlight]="search"
                    [class.deactivated]="isItemDeactivated(item)">{{item.description}}</span>
            </ng-template>
            <ng-template ng-typetosearch-tmp>
            </ng-template>
            <ng-template ng-footer-tmp>
                <div class="last-search-container" *ngIf="lastSearchFeatureEnabled">
                    <div class="last-search-title">Ultime ricerche</div>
                    <ul class="last-search-list">
                        <li class="last-search-list-item" (click)="selectLastSearchItem('Layout')">Layout</li>
                        <li class="last-search-list-item" (click)="selectLastSearchItem('Security')">Security</li>
                    </ul>
                </div>
                <!-- <button (click)="startPresentation($event, false)" class="btn btn-sm btn-secondary">Aggiungi nuovo</button>
        <button *ngIf="this.selectedItem != null" (click)="startPresentation($event, true)"
            class="btn btn-sm btn-secondary">Visualizza</button> -->
                <!-- <nts-commands-bar [rtl]="false" [commands]="[addItemCommand, viewItemCommand, copyCommand]">
            </nts-commands-bar> -->

                <div class="actions-container">
                    <div class="left-actions">
                        <nts-text-button 
                            *ngIf="copyCommand.isVisible$ | async"
                            [title]="copyCommand.description"
                            [additionalClasses]="[inputRef?.dropdownId]"
                            [isDisabled]="!(copyCommand.canExecute$ | async)" 
                            [icon]="copyCommand.iconClass"
                            (onClicked)="copyCommand.execute()"></nts-text-button>
                        <nts-text-button 
                            *ngIf="viewItemCommand.isVisible$ | async"
                            [title]="viewItemCommand.description"
                            [additionalClasses]="[inputRef?.dropdownId]"    
                            [isDisabled]="!(viewItemCommand.canExecute$ | async)" 
                            [icon]="viewItemCommand.iconClass"
                            (onClicked)="viewItemCommand.execute()"></nts-text-button>
                    </div>
                    <div class="right-actions">
                        <nts-text-button 
                            *ngIf="addItemCommand.isVisible$ | async"
                            [title]="addItemCommand.description" 
                            [label]="showButtonLabels ? addItemCommand.displayName : null"
                            [additionalClasses]="[inputRef?.dropdownId]"
                            [isDisabled]="!(addItemCommand.canExecute$ | async)" 
                            [icon]="addItemCommand.iconClass"
                            (onClicked)="addItemCommand.execute()"></nts-text-button>
                    </div>
                </div>
            </ng-template>
            <!-- <ng-template ng-footer-tmp>
    Selected count: {{selectedPeople.length}}
</ng-template> -->
        </ng-select>
        <div class="autocomplete-actions"
            *ngIf="!isDisabled && externalPropertyViewModel.isEnabled && code.isEnabled && externalPropertyViewModel?.securityAccess == null">
            <nts-text-button 
                *ngIf="externalPropertyViewModel.zoomSearchIsVisible"
                [tabIndex]="-1" 
                (onClicked)="zoom()"
                icon="search"></nts-text-button>
            <nts-text-button *ngIf="isOpen" (onClicked)="closeDropDown()" [tabIndex]="-1" icon="vert"></nts-text-button>
            <nts-text-button *ngIf="!isOpen" (onClicked)="openDropDown()" [tabIndex]="-1" icon="vert"></nts-text-button>
        </div>
    </div>

    <popper-content #tooltipErrTemplate>
        <ng-container *ngFor="let item of code.errors$ | async">
            {{ item }}
        </ng-container>
    </popper-content>

</ng-template>

<ng-template #securityComponent>
    <nts-base-security-text-box #baseSecurityTextBox [accessMode]="propertyViewModel.securityAccess"
        [value]="initialValue != null ? initialValue : propertyViewModel.formattedValue"></nts-base-security-text-box>
</ng-template>
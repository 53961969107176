import { Filter } from './filter';
import cloneDeep from 'lodash-es/cloneDeep';
import { Expose, Type } from '../../serialization/class-transformer/decorators';
import { OrderBy } from '../autocomplete/auto-complete-options';

export class FindOptions {

    @Expose()
    @Type(() => Filter)
    filters: Filter[];

    @Expose()
    @Type(() => OrderBy)
    orderByPropertyNames: OrderBy[];

    @Expose()
    take?: number;

    @Expose()
    skip?: number;

    constructor(
        findOptions?: FindOptions, 
        filters: Filter[] = [],
        orderByPropertyNames: OrderBy[] = [],
        take: number = null,
        skip: number = null
    ) {
        this.filters = filters;
        this.orderByPropertyNames = orderByPropertyNames;
        this.take = take;
        this.skip = skip;

        if (findOptions) {
            this.filters = cloneDeep(findOptions.filters);
            this.orderByPropertyNames = findOptions.orderByPropertyNames;
            this.take = findOptions.take;
            this.skip = findOptions.skip;
        }
    }

    toString(): string {
        let sb = ' PageInfo: ' + 'Take ' + this.take + ' Skip ' + this.skip;;
        sb += ' Filters: ' + this.filters.toString();

        return sb;
    }
}

import { ZoomPropertyViewModel } from './zoom-property-view-model';
import { MessageContainer } from '../../message-container';
import { EnumPropertyViewModelItem } from '../../base-type/enum-property-view-model';
import { EnumMetaData } from '../../../meta-data/enum-meta-data';
import { ModifiedSubscriberInterface } from '../../modified-subscriber.interface';
import { EnumResource } from '../../../meta-data/enum-resource';
import { MessageResourceManager } from '../../../resources/message-resource-manager';

export class EnumZoomPropertyViewModel extends ZoomPropertyViewModel<number> {

  valueDescriptions: Array<EnumPropertyViewModelItem>;

  get currentItem(): EnumPropertyViewModelItem {
    return this._currentItem;
  }
  set currentItem(value: EnumPropertyViewModelItem) {
    // //purtroppo durante l'ordinamento può scattare un set a null del currentItem e questo non deve avvenire.
    // //solo il ResetValue setta a null il currentItem e qiestp viene chiamato quando si setta "Nessuno" sull'operatore
    if (this._currentItem !== value && value !== null) {
      this.typedValue = value.key;
      this._currentItem = value;
      this.onPropertyChanged('currentItem')
    }
  }

  private _currentItem: EnumPropertyViewModelItem;

  constructor(propertyMetaData: EnumMetaData, modifiedSubscriber: ModifiedSubscriberInterface) {
    super(propertyMetaData, modifiedSubscriber);

    const valuesResource = propertyMetaData.valuesResource;

    this.valueDescriptions = new Array<EnumPropertyViewModelItem>();

    if (valuesResource) {
      valuesResource.forEach((value: EnumResource) => {

        value.displayValue = value.displayValue ?? MessageResourceManager.Current.getMessage(value.resourceKey);
        value.displayExtValue = value.displayExtValue ?? MessageResourceManager.Current.getMessage(value.descriptionExtKey);

        // Considera solo le chiavi numeriche (evita di visualizzare la chiave $type)
        this.valueDescriptions.push(new EnumPropertyViewModelItem(
          value.enumValue,
          value.displayValue,
          value.displayExtValue));
      });
    }

    this.typedValue = null;
    this._currentItem = null;
  }

  override removeError(item: MessageContainer) {
    throw new Error("Method not implemented.");
  }

  override resetValue() {
    this.typedValue = null;
    this._currentItem = null;
    this.onPropertyChanged('currentItem')
  }

  setValue(propertyValue: Object) {
    if (propertyValue === null) {
      this.typedValue = null;
    } else {
      // TODO
      // let ic = propertyValue as IConvertible;
      // if (ic == null)
      // {
      //     throw new ArgumentException();
      // }

      // this.typedValue = ic.ToString(CultureInfo.InvariantCulture);
      this.typedValue = <number>propertyValue;

      if (this.typedValue !== null) {
        this.currentItem = this.valueDescriptions.find((obj) => {
          return obj.key === this.typedValue;
        });
      }
    }
  }
}

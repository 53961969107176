import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'NTSFilter',
    pure: false,
    standalone: true
})
export class NTSFilterPipe implements PipeTransform {
    transform(value: any[], filterValue: any, property: string): any[] {
        if (!value || value?.length === 0 || filterValue === undefined) {
            return value;
        }
        let filteredData: any[] = [];
        for (let data of value) {
        if (data[property] === filterValue) {
            filteredData.push(data);
        }
        }
        return filteredData;
    }
}
import { ZoomFilterViewModel } from './zoom-filter-view-model';
import { ModifiedSubscriberInterface } from '../../modified-subscriber.interface';
import { ZoomMetaData } from '../../../meta-data/zoom-meta-data';
import { ZoomOperatorViewModelProperty } from '../zoom-operator-view-model-property';

export class InternalCollectionZoomFilterViewModel extends ZoomFilterViewModel<any> {

    constructor(metadata: ZoomMetaData, private modifiedSubscriber: ModifiedSubscriberInterface) {
        super(metadata);
        this.operator = new ZoomOperatorViewModelProperty(
            ZoomOperatorViewModelProperty.operatorsForInternalCollection,
            modifiedSubscriber
        );
        this.init();
    }
}

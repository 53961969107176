export interface TimeZoneInterface<TKey = number> {
    key: TKey|null;
    description: string|null;
    descriptionExt: string|null;
}

export class TimeZoneData implements TimeZoneInterface<number> {
    key: number|null;
    description: string|null;
    descriptionExt!: string|null;
    timeZoneInfoId: string|null;
    timeSpan: number|null;

    constructor(key: number|null = null, timeZoneInfoId: string|null = null, timeSpan: number|null = null, description: string|null = null) {
        this.key = key;
        this.description = description;
        this.timeZoneInfoId = timeZoneInfoId;
        this.timeSpan = timeSpan;
    }
}

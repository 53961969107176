export interface ConfigurationInterface {
    baseAppUrl: string,
    authenticationAppUrl: string,
    appInsights?: {
        connectionString: string
        enableAutoRouteTracking: boolean
    },
    [key: string]: any
}

export class ConfigurationManager {

    private static instance: ConfigurationManager;
    config: ConfigurationInterface|null = null;

    static get Current(): ConfigurationManager {
        if (this.instance == null) {
            if (this.instance == null) {
                this.instance = new ConfigurationManager();
            }
        }
        return this.instance;
    }
}

import { Expose, Transform } from '../serialization/class-transformer/decorators';
import { AuditModelInterface } from './audit-model.interface';
import { BaseIdentity } from './base-identity';
import { DateTimeOffset } from './date-time-offset';
import { DeactivableModelInterface } from './deactivable-model.interface';
import { OCCModel } from './occ-model';


export class OCCAuditDeactivableModel<TIdentity extends BaseIdentity>
    extends OCCModel<TIdentity> implements AuditModelInterface, DeactivableModelInterface {

    constructor() {
        super();
        this.isActive = true;
    }

    private _updateUser: string;

    @Expose()
    get updateUser(): string {
        return this.getValue<string>(() => this._updateUser, 'updateUser');
    }
    set updateUser(value: string) {
        this.setValue<string>(() => { this._updateUser = value; }, value, 'updateUser');
    }

    private _updateDate: Date;

    @Expose()
    @Transform((value, obj, type) => {
        return DateTimeOffset.transform('updateDate', value, obj, type);
    })
    get updateDate(): Date {
        return this.getValue<Date>(() => this._updateDate, 'updateDate');
    }
    set updateDate(value: Date) {
        this.setValue<Date>(() => { this._updateDate = value; }, value, 'updateDate');
    }

    private _creationUser: string;

    @Expose()
    get creationUser(): string {
        return this.getValue<string>(() => this._creationUser, 'creationUser');
    }
    set creationUser(value: string) {
        this.setValue<string>(() => { this._creationUser = value; }, value, 'creationUser');
    }

    private _creationDate: Date;

    @Expose()
    @Transform((value, obj, type) => {
        return DateTimeOffset.transform('creationDate', value, obj, type);
    })
    get creationDate(): Date {
        return this.getValue<Date>(() => this._creationDate, 'creationDate');
    }
    set creationDate(value: Date) {
        this.setValue<Date>(() => { this._creationDate = value; }, value, 'creationDate');
    }

    private _isActive: boolean;

    @Expose()
    get isActive(): boolean {
        return this.getValue<boolean>(() => this._isActive, 'isActive');
    }
    set isActive(value: boolean) {
        this.setValue<boolean>(() => { this._isActive = value; }, value, 'isActive');
    }
}

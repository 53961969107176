import { Component, AfterViewInit, ViewChild, OnInit, Input } from '@angular/core';
import { AgEditorComponent, ICellEditorAngularComp } from 'ag-grid-angular';
import { ColumnCellEditorParams } from '../column_cell_editor_params.interface';
import { BaseNumericPropertyViewModel } from '../../../../../view-models/base-type/base-numeric-property-view-model';
import { GridComponent } from '../../grid.component';
import { LogService } from '@nts/std/src/lib/utility';
import { NumericTextBoxComponent } from '../../../core/numeric-text-box/numeric-text-box.component';
import { AsyncPipe } from '@angular/common';

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;
const KEY_ENTER = 13;
const KEY_TAB = 9;
const KEY_UP = 38;
const KEY_DOWN = 40;
const KEY_LEFT = 37;
const KEY_RIGHT = 39;

@Component({
    selector: 'nts-numeric-text-box-editor-cell',
    styleUrls: ['./numeric-cell-editor.component.scss'],
    standalone: true,
    imports: [
        NumericTextBoxComponent,
        AsyncPipe
    ],
    template: `
        <nts-numeric-text-box
            #textBox
            (keyDown)="onKeyDown($event)"
            (valueChange)="valueChange($event)"
            (onFinishEditing)="onFinishEditing()"
            [listenClickOutside]="true"
            [updateOnBlur]="false"
            [showErrorBorder]="false"
            [propertyViewModel]="propertyViewModel"
            [initialValue]="value"
            [selectAllOnFocus]="false"
            [style.width.px]="cellWidth"
            [style.height.px]="cellHeight"
            [modelUpdate]="false">
        </nts-numeric-text-box>
    `
})
export class NumericCellEditorComponent implements AgEditorComponent, AfterViewInit, OnInit {
        
    @Input()
    params: ColumnCellEditorParams;
    

    @ViewChild('textBox', { static: true }) textBox: NumericTextBoxComponent;

    value: number;
    returnedValue: number;
    highlightAllOnFocus = true;
    
    private cancelBeforeStart = false;
    
    ngOnInit(): void {
        if (this.params) {
            this.agInit(this.params);
        }
    }

    agInit(params: ColumnCellEditorParams): void {
        this.params = params;
        this.setInitialState(this.params);

        // only start edit if key pressed is a number, not a letter
        this.cancelBeforeStart =
            params.charPress && '1234567890'.indexOf(params.charPress) < 0;
    }

    get cellWidth() {
        return this.params.eGridCell.clientWidth;
    }

    get cellHeight() {
        return this.params.eGridCell.clientHeight;
    }

    onFinishEditing() {
        this.params.stopEditing();
    }

    setInitialState(params: any) {
        let startValue;
        let highlightAllOnFocus = true;

        if (params.keyPress === KEY_BACKSPACE || params.keyPress === KEY_DELETE) {
            // if backspace or delete pressed, we clear the cell
            startValue = '';
        } else if (params.charPress) {
            // if a letter was pressed, we start with the letter
            startValue = parseInt(params.charPress, 10);
            highlightAllOnFocus = false;
        } else {
            // otherwise we start with the current value
            startValue = params.columnInfo.fieldName.split('.').reduce(
                (o, i) => {
                    if (i === 'value') {
                        return o[i];
                    }
                    return o[i];
                }, this.params.data
            )
            if (params.keyPress === KEY_F2) {
                highlightAllOnFocus = false;
            }
        }

        this.value = startValue;
        this.returnedValue = this.value;
        this.highlightAllOnFocus = highlightAllOnFocus;
    }

    // for testing
    async setValue(newValue: any) {
        this.textBox.input.value = newValue;
        (this.textBox.input as any).inputmask.refreshValue = true;
        this.textBox.onInput(null);
    }

    valueChange(value: any) {
        this.returnedValue = value;
    }

    getValue(): any {
        return this.returnedValue;
    }

    isCancelBeforeStart(): boolean {
        return this.cancelBeforeStart;
    }

    onKeyDown(event: any): void {
        if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
            event.stopPropagation();
            return;
        }

        if (
            !this.finishedEditingPressed(event) &&
            !this.isKeyPressedNumeric(event) &&
            !event.ctrlKey
        ) {
           if (event.preventDefault) { event.preventDefault(); }
        }

        if (this.isUpOrDown(event)) {
            this.navigateToNextCell(event);
        }
    }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {

        window.setTimeout(() => {
            this.textBox.focus();
            if (this.highlightAllOnFocus) {
                this.textBox.input.select();

                this.highlightAllOnFocus = false;
            } else {
                // when we started editing, we want the carot at the end, not the start.
                // this comes into play in two scenarios: a) when user hits F2 and b)
                // when user hits a printable character, then on IE (and only IE) the carot
                // was placed after the first character, thus 'apply' would end up as 'pplea'
                const length = this.textBox.input.value
                    ? this.textBox.input.value.length
                    : 0;
                if (length > 0) {
                    const radixPoint = this.propertyViewModel.decimalLimit > 0 ? length - this.propertyViewModel.decimalLimit - 1 : length;
                    this.textBox.input.setSelectionRange(radixPoint, radixPoint);
                }
            }

            this.textBox.focus();
        });
    }

    private getCharCodeFromEvent(event: any): any {
        event = event || window.event;
        return typeof event.which == 'undefined' ? event.keyCode : event.which;
    }

    private isCharNumeric(charStr: string): boolean {
        return !!/\d/.test(charStr);
    }

    private isMinus(charStr: string): boolean {
        return charStr === '-';
    }

    private isNumberSeparator(charStr: string): boolean {
        return charStr === '.' || charStr === ',';
    }

    private isKeyPressedNumeric(event: any): boolean {
        const charCode = this.getCharCodeFromEvent(event);
        const charStr = event.key ? event.key : String.fromCharCode(charCode);
        // return this.isCharNumeric(charStr) || this.isMinus(charStr) || (this.propertyViewModel.decimalLimit > 0 && charStr === this.decimalSeparator);
        return this.isCharNumeric(charStr) || this.isMinus(charStr) || this.isNumberSeparator(charStr);
    }

    private deleteOrBackspace(event: any) {
        return (
            [KEY_DELETE, KEY_BACKSPACE].indexOf(this.getCharCodeFromEvent(event)) > -1
        );
    }

    private isLeftOrRight(event: any) {
        return [KEY_LEFT, KEY_RIGHT].indexOf(this.getCharCodeFromEvent(event)) > -1;
    }

    private isUpOrDown(event: any) {
        return [KEY_UP, KEY_DOWN].indexOf(this.getCharCodeFromEvent(event)) > -1;
    }

    private finishedEditingPressed(event: any) {
        const charCode = this.getCharCodeFromEvent(event);
        return charCode === KEY_ENTER || charCode === KEY_TAB;
    }

    private _propertyViewModel: BaseNumericPropertyViewModel;

    get propertyViewModel(): BaseNumericPropertyViewModel {
        if (!this._propertyViewModel) {
            this._propertyViewModel =  this.params.columnInfo.fieldName.split('.').reduce(
                (o, i) => {
                    if (i === 'value') {
                        return o;
                    }
                    return o[i];
                }, this.params.data) as BaseNumericPropertyViewModel;
        }
        return this._propertyViewModel;
    }

    get gridContext(): GridComponent {
        return this.params.context;
    }

    blur($event) {
        this.propertyViewModel.validate();
    }

    private navigateToNextCell(event: any) {

        const key = this.getCharCodeFromEvent(event);

        const fc = this.gridContext.gridOptions.api.getFocusedCell();
        LogService.debug('focused cell rowindex ' + fc.rowIndex, 'focused celll column ' + fc.column);
        if (key === KEY_DOWN) {
            // set selected cell on current cell + 1
            this.gridContext.gridOptions.api.forEachNode((node) => {
                if (fc.rowIndex + 1 === node.rowIndex) {
                    this.gridContext.gridOptions.api.setFocusedCell(node.rowIndex, fc.column);
                    this.gridContext.gridOptions.api.startEditingCell({
                        rowIndex: node.rowIndex,
                        colKey: fc.column
                    });
                }
            });
        } else if (key === KEY_UP) {
            // set selected cell on current cell + 1
            this.gridContext.gridOptions.api.forEachNode((node) => {
                if (fc.rowIndex - 1 === node.rowIndex) {
                    this.gridContext.gridOptions.api.setFocusedCell(node.rowIndex, fc.column);
                    this.gridContext.gridOptions.api.startEditingCell({
                        rowIndex: node.rowIndex,
                        colKey: fc.column
                    });
                }
            });
        } else if (key === KEY_LEFT) {
            this.gridContext.gridOptions.api.tabToPreviousCell();
        } else if (key === KEY_RIGHT) {
            this.gridContext.gridOptions.api.tabToNextCell();
        }
    }
}

<div class="layout-head">
    <div class="layout-gutter-left">
    </div>
    <div class="layout-content">
        <nts-text-button *ngIf="showRelatedItemsScrollArea"  (click)="scrollLeft()" icon="arrow-back"></nts-text-button>
        <div class="nts-container" #itemListContainer>
            <div class="tab-container" role="heading"  *ngIf="!showTitle">
                <p-tabMenu *ngIf="relatedItemList$" [model]="relatedItemList$ | async" [activeItem]="activeRelatedItem">
                    <ng-template pTemplate="item" let-item let-i="index">
                        <span *ngIf="item.visible"
                        popper="{{item.label}}"
                        [popperTrigger]="ngxPopperjsTriggers.hover"
                        [popperDelay]="1000"
                        popperApplyClass="info"
                        [popperPlacement]="ngxPopperjsPlacements.TOP"
                        [popperPreventOverflow]="false"
                        [popperHideOnScroll]="true"
                        popperAppendTo="body"
                        >{{item.label}}</span>
                    </ng-template>
                </p-tabMenu>
            </div>
            <div class="title-container" [attr.tabindex]="0" *ngIf="showTitle && orchestratorViewModel?.title?.length > 0"  role="heading" aria-level="1">
                <span *ngIf="orchestratorViewModel?.customIcon$ | async as customIcon" class ="icon-field"
                [popper]="customIcon.tooltip?.description ?? null"
                [popperTrigger]="ngxPopperjsTriggers.hover"
                [popperDelay]="1000"
                [popperApplyClass]="customIcon.tooltip?.type ?? null"
                [popperPlacement]="ngxPopperjsPlacements.TOP"
                [popperHideOnScroll]="true"
                [popperPreventOverflow]="false"
                popperAppendTo="body"
                [style.color]="customIcon.color ?? null"
                [ngClass]="customIcon.customClass ?? null"
                >
                    <svg-icon [key]="customIcon.key ?? null"></svg-icon>
                </span>
                <span *ngIf="orchestratorViewModel?.metadata?.rootMetaData?.userMetaData?.securityAccess != null" class="icon-field">
                    <svg-icon key="locked"></svg-icon>
                </span>
                <div class="truncate truncate-leading">
                    <span class="content"
                    popper="{{orchestratorViewModel.title}}"
                    [popperTrigger]="ngxPopperjsTriggers.hover"
                    popperApplyClass="info"
                    [popperDelay]="1000"
                    [popperPlacement]="ngxPopperjsPlacements.TOP"
                    [popperPreventOverflow]="false"
                    [popperHideOnScroll]="true"
                    popperAppendTo="body"
                    >{{orchestratorViewModel.title}}</span>
                </div>
            </div>
        </div>

        <nts-text-button *ngIf="showRelatedItemsScrollArea" (click)="scrollRight()" icon="arrow-forward"></nts-text-button>
        <div class="header-actions-container">
            <div class="update-checker" *ngIf="updateStatus?.status === updateServiceStatusType.UpdatedNeedReload" (click)="onUpdateStatusClicked.emit(updateStatus)" title="Aggiornamento completato, clicca per maggiori informazioni">
                <svg-icon key="refresh-double"></svg-icon>
            </div>
            <div class="update-checker updating" *ngIf="updateStatus?.status === updateServiceStatusType.Updating" (click)="onUpdateStatusClicked.emit(updateStatus)" title="Aggiornamento in corso">
                <svg-icon key="refresh-animated"></svg-icon>
            </div>
            <div class="update-checker error" *ngIf="updateStatus?.status === updateServiceStatusType.UpdateError" (click)="onUpdateStatusClicked.emit(updateStatus)" title="Aggiornamento non riuscito, clicca per maggiori informazioni">
                <svg-icon key="refresh-double"></svg-icon>
            </div>
            <div *ngIf="showCurrentState"
                class="saving-state-container">
                <div class="saving-state">
                    <svg-icon [key]="currentStateIconMapping[currentState]"></svg-icon>
                    <span 
                    popper="{{currentStateDescription}}"
                    [popperTrigger]="ngxPopperjsTriggers.hover"
                    [popperDelay]="1000"
                    popperApplyClass="info"
                    [popperPlacement]="ngxPopperjsPlacements.TOP"
                    [popperPreventOverflow]="false"
                    [popperHideOnScroll]="true"
                    popperAppendTo="body">
                        {{currentStateDescription}}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="layout-gutter-right">
    </div>
</div>

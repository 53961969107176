import { Expose } from "../../serialization/class-transformer/decorators";

export class CompanyInformationDto {
    
    @Expose()
    companyId: number;

    @Expose()
    name: string;

    @Expose()
    color: string;

    @Expose()
    isDefault: boolean;
}
import { BaseIdentity, CoreModel } from '../domain-models';
import { DatasourceCollectionViewModel } from './datasource-collection-view-model';
import { Observable, of } from 'rxjs';
import { ItemViewModel } from './item-view-model';

export class SearchResultCollectionViewModel<
    TItemViewModel extends ItemViewModel<TModel, TIdentity>,
    TModel extends CoreModel<TIdentity>,
    TIdentity extends BaseIdentity>
    extends DatasourceCollectionViewModel<TItemViewModel, TModel, TIdentity> {

    constructor() {
        super();

        // Typescript 2.1 BREAKING CHANGE
        // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, SearchResultCollectionViewModel.prototype);
        this.skipItemPostInit = true;
        this.showOpenGridSettingsCommands = false;
    }

    protected override canOpenGridSettingsCommand(): Observable<boolean> {
        return of(false);
    }
}

import { Component, Input, OnInit, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ZoomOperatorViewModelProperty } from '../../../view-models/zoom/zoom-operator-view-model-property';
import { FilterOperatorInfo } from '../../../view-models/zoom/filter-operator-info';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseEnumTextBoxComponent } from '../../controls/core/base/base-enum-text-box/base-enum-text-box.component';
import { TextButtonComponent } from '../../shared/buttons/text-button/text-button.component';

@Component({
  selector: 'nts-zoom-filter-operator-text-box',
  styleUrls: ['zoom-filter-operator-text-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    BaseEnumTextBoxComponent,
  ],
  template: `
  <nts-base-enum-text-box #baseEnumTextBox [value]="value" [options]="filteredValues" [appendTo]="'body'"
    [isDisabled]="!operator.isEnabled"
    optionLabel="shortName" dataKey="value" (onChange)="onChange($event)">
  </nts-base-enum-text-box>
  `
})
export class ZoomFilterOperatorTextBoxComponent implements OnInit, OnDestroy {

  @Input() operator: ZoomOperatorViewModelProperty;
  @ViewChild('baseEnumTextBox', { static: false }) baseEnumTextBox: BaseEnumTextBoxComponent;

  value: FilterOperatorInfo;
  filteredValues: Array<FilterOperatorInfo>;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
    if (!this.operator) { throw new Error('Missing viewModel!'); }
    this.filteredValues = this.operator.values;
    this.value = this.filteredValues.find(x => x.value === this.operator.currentValue);
    this.operator.operatorValuesChanged.subscribe(() => {
      this.filteredValues = this.operator.values;
    });

    this.operator.operatorChanged.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.updateCurrentValue();
    });

    this.operator.operatorEnableStatusChanged.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  private updateCurrentValue() {
    this.value = this.filteredValues.find(x => x.value === this.operator.currentValue);
    this.cd.detectChanges();
  }

  onChange($event) {
    this.operator.currentValue = $event.value.value;
  }
}

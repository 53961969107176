/**
 * Stati possibili per un domainmodel
 */
export enum DomainModelState {

    /**
     * Non modificato
     */
    Unchanged = 0,

    /**
     * Modificato
     */
    Modified = 1,

    /**
     * Nuovo
     */
    New = 2,

    /**
     * Rimosso
     */
    Removed = 3
}

import { TimeZoneData } from './time-zone-data';

export class TimeZoneDataCollectionCache {

    private static instance: TimeZoneDataCollectionCache;
    private static timeZoneDataCollectionMemoryCache: Array<TimeZoneData> = [];

    static get Instance(): TimeZoneDataCollectionCache {
        if (this.instance == null) {
            this.instance = new TimeZoneDataCollectionCache();
        }
        return this.instance;
    }

    getTimeZoneDataCollection(): Array<TimeZoneData>|null {
        if (TimeZoneDataCollectionCache.timeZoneDataCollectionMemoryCache.length > 0) {
            return TimeZoneDataCollectionCache.timeZoneDataCollectionMemoryCache;
        }
        else {
            return null;
        }
    }

    addTimeZoneDataCollection(timeZoneDataCollection: Array<TimeZoneData>) {
        // DateTimeOffset dto = DateTime.UtcNow.AddSeconds(int.Parse(MemoryCacheConfigElement.SlidingExpirationDefaultValue));
        TimeZoneDataCollectionCache.timeZoneDataCollectionMemoryCache.push(...timeZoneDataCollection);
    }
}

import { ValidationArguments, ValidationOptions, registerDecorator, isDefined, IS_DEFINED } from 'class-validator';
import { RequiredValidation } from '../validations/required-validation';
import { NTSReflection } from '@nts/std/src/lib/utility';
import { PropertyMetaData } from '../../../meta-data/property-meta-data';
import { MessageResourceManager } from '../../../resources/message-resource-manager';
import { ValidationMetadataArgs } from 'class-validator/cjs/metadata/ValidationMetadataArgs';
import { CodeValueMessageArg } from '../../../resources/code-value-message-arg';
import { BaseValidationDecoratorInterface } from './base-validation-decorator.interface';
import { BaseDecoratorInterface } from './base-decorator.interface';
import { AssociationMetaData } from '../../../meta-data';

const PROPERTY_META_DATA_KEY = 'PropertyMetaData';


export abstract class BaseValidator<TValue> {

    errorMessage: string;
    messageCode: string;

    // Metodo di base che deve essere eseguita da tutti i decoratori
    static initBaseValidator(baseDecoratorInterface: BaseValidationDecoratorInterface, classType: any, propertyName: string) {
        this.initBaseValidatorWithConstructor(baseDecoratorInterface, classType.constructor, propertyName);
    }

    // Metodo di base che deve essere eseguita da tutti i decoratori
    static initBaseValidatorWithConstructor(baseDecoratorInterface: BaseValidationDecoratorInterface, classTypeConstructor: any, propertyName: string) {

        // Applica il decoratore is defined sulla property, per gestire il required
        if (baseDecoratorInterface?.isRequired) {
            const args: ValidationMetadataArgs = {
                name: IS_DEFINED,
                target: classTypeConstructor,
                propertyName,
                options: {
                    message: (validationArguments: ValidationArguments) => {
                        return (new RequiredValidation()).defaultMessage(
                            {
                                object: validationArguments.object,
                                constraints: [baseDecoratorInterface],
                                property: propertyName
                            } as ValidationArguments
                        );
                    }
                } as ValidationOptions,
                validator: {
                    validate: (value): boolean => isDefined(value)
                },
            };

            // Registro il decoratore
            registerDecorator(
                args
            );
        }
    }

    /**
     * Aggiunge le informazioni passata nel decoratore decorando la proprietà con la chiave definita
     * in BaseValidationDecorator.PROPERTY_META_DATA_KEY in modo da poter recuperare successivamente queste informazioni
     *
     * @param subject           classe
     * @param propertyName      proprietà della classe
     * @param decoratorData     dati del decoratore
     */
    static buildPropertyMetaData<TDecoratorData extends BaseDecoratorInterface>(
        subject: any,
        propertyName: string,
        decoratorData: TDecoratorData
    ): void {
        const propertyMetaData = this.getPropertyMetaDataFromDecoratorData<TDecoratorData>(decoratorData, propertyName);
        Reflect.defineMetadata(PROPERTY_META_DATA_KEY, propertyMetaData, subject, propertyName);
    }

    /**
     * Recupera le informazioni aggiunte dal decoratore
     * @param subject       classe
     * @param propertyName  propietà della classe dove è stato applicato il decoratore
     */
    static getPropertyMetaData<TPropertyMetaData extends PropertyMetaData | AssociationMetaData>(subject: any, propertyName: string): TPropertyMetaData {
        return NTSReflection.getPropertyMetadata(
            PROPERTY_META_DATA_KEY, subject, propertyName);
    }

    /**
     * Restituisce il nome tradotto della proprietà
     */
    static getDisplayName(args: ValidationArguments): string {
        if (args && args.object && args.property) {

            let displayName = args.property;
            const displayNameKey = (args.constraints[0] as BaseValidationDecoratorInterface).displayNameKey;
            if (displayNameKey) {
                const translatedName = MessageResourceManager.Current.getMessageIfExists(displayNameKey);
                if (translatedName) {
                    displayName = translatedName;
                }
            }

            return displayName;
        }
        return '';
    }

    static getMessageWithTag(args: ValidationArguments, tag: string, messageCode): string {
        return this.getMessageWithCodeValues(messageCode, [{ code: tag, value: this.getDisplayName(args) }]);
    }

    static getMessageWithCodeValues(baseMessage: string, tags: { code: string, value: string }[]): string {
        const arr = new Array<CodeValueMessageArg>();
        for (const tag of tags) {
            const arg = new CodeValueMessageArg();
            arg.code = tag.code;
            arg.value = tag.value;
            arr.push(arg);
        }
        return MessageResourceManager.Current.getMessageWithArgs(baseMessage, arr);
    }

    /**
     * Restituisce un oggetto PropertyMetaData partendo dai dati del decoratore
     * @param decoratorData     dati del decoratore
     */
    protected static getPropertyMetaDataFromDecoratorData<TDecoratorData extends BaseValidationDecoratorInterface>(
        decoratorData: TDecoratorData, propertyName: string): PropertyMetaData | AssociationMetaData {
        throw new Error(`You should implements getPropertyMetaDataFromDecoratorData in ${this.name}`);
    }

    defaultMessage(args: ValidationArguments): string {
        if (this.messageCode?.length > 0) {
            return JSON.stringify({
                code: this.messageCode,
                message: this.errorMessage
            })
        }
        return this.errorMessage;
    }

    validate(value: TValue, args: ValidationArguments): boolean {
        const decorator = args.constraints[0] as BaseValidationDecoratorInterface;
        return this.validateRequired(value, decorator.isRequired, args);
    }

    validateRequired(value: TValue, isRequired: boolean, args: ValidationArguments): boolean {
        if (isRequired === true) {
            const requiredValidation = new RequiredValidation<TValue>();
            if (!requiredValidation.validate(value, args)) {
                this.errorMessage = requiredValidation.errorMessage;
                this.messageCode = requiredValidation.messageCode;
                return false;
            }
        }
        return true;
    }
}

import { Expose } from "../serialization/class-transformer/decorators";

// Property su cui è implementata una relazione
export class AssociationPropertyMetaData {

    /**
     * Property del modello contenente l'associazione
     */
    @Expose()
    principalPropertyName: string;

    /**
     * Property del modello a cui relazionarsi
     */
    @Expose()
    dependentPropertyName: string;

    constructor(
        principalPropertyName?: string,
        dependentPropertyName?: string,
    ) {
        this.principalPropertyName = principalPropertyName;
        this.dependentPropertyName = dependentPropertyName;
    }
}

import { ZoomPropertyViewModel } from './zoom-property-view-model';
import { MessageContainer } from '../../message-container';

export class GuidZoomPropertyViewModel extends ZoomPropertyViewModel<string> {

    override removeError(item: MessageContainer) {
        throw new Error("Method not implemented.");
    }

    override resetValue() {
        this.typedValue = null;
    }

    setValue(propertyValue: Object) {
        if (propertyValue === null) {
            this.typedValue = null;
        } else {
            // TODO
            // let ic = propertyValue as IConvertible;
            // if (ic == null)
            // {
            //     throw new ArgumentException();
            // }

            // this.typedValue = ic.ToString(CultureInfo.InvariantCulture);
            this.typedValue = String(propertyValue);
        }
    }
}

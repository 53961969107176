<div class="toolbar-section">
  <div class="toolbar-control-content">
    <div class="horizontal-container">
     
      <div class="root-container">
        <div class="inline-command-bar">
          <div class="action-list-control-container">
            <div class="horizontal-flex-container">
            </div>
          </div>
        </div>
      </div>

      <!-- exportcsv command -->
      <div class="command-bar-button-container"
        *ngIf="exportCsvCommand.isVisible$ | async">
        
        <nts-ribbon-button 
            [title]="exportCsvCommand.tooltip"
            [tabIndex]="0"
            [icon]="exportCsvCommand.iconClass"
            [isDisabled]="!(exportCsvCommand.canExecute$ | async)"
            (onClicked)="exportCsvCommand.execute()"></nts-ribbon-button>
      </div>

      <!-- Per il momento questo componente è stato disabilitato vedremo se servirà in futuro -->
      <!-- Toggle column options -->
      <!-- <div class="command-bar-button-container">
        <button type="button"
            [class.active]="!columnsSidebarCollapsed"
            (click)="columnsSidebarCollapsed = !columnsSidebarCollapsed"
            [attr.title]="columnsSidebarCollapsedTitle"
            [attr.aria-label]="columnsSidebarCollapsedTitle" data-is-focusable="true"
            [attr.aria-pressed]="!columnsSidebarCollapsed">
            <div class="flexContainer">
                <i data-icon-name="ColumnOptions" aria-hidden="false" class="ms-Icon--ColumnOptions"></i>
            </div>
        </button>
      </div> -->

    </div>
  </div>

</div>

<div class="zoom-grid-wrapper">
  <ag-grid-angular 
  tabindex="0" 
  class="ag-theme-nts zoom-results-grid"
  [rowModelType]="'infinite'"
  [rowBuffer]="0"
  [cacheBlockSize]="100"
  [cacheOverflowSize]="2"
  [maxConcurrentDatasourceRequests]="1"
  [columnDefs]="columnDefinitions" 
  [suppressNoRowsOverlay]="true"
  [infiniteInitialRowCount]="1"
  [maxBlocksInCache]="100"
  [suppressLoadingOverlay]="true" 
  [headerHeight]="34" 
  [rowHeight]="34" 
  [gridOptions]="gridOptions"
  (selectionChanged)="zoomResultsViewModel.selectionChanged.next()">
</ag-grid-angular>
  <!-- Per il momento questo componente è stato disabilitato vedremo se servirà in futuro -->
  <!-- <nts-grid-columns-sidebar
    [columnsViewModel]="columnDefinitions"
    [gridOptions]="gridOptions"
    [actionInProgress]="columnsSidebarActionInProgress"
    [isCollapsed]="columnsSidebarCollapsed"
    (columnsSideBarCollapsed)="onColumnsSidebarCollapsed($event)">
  </nts-grid-columns-sidebar> -->
</div>

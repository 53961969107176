import { BaseIdentity } from '../domain-models/base-identity';
import { Expose } from '../serialization/class-transformer/decorators';
import { ServiceRequest } from './service-request';

export class GetAllSnapShotsFrameRequest<TIdentity extends BaseIdentity> extends ServiceRequest {

  @Expose()
  identity: TIdentity;

  @Expose()
  take: number;

  @Expose()
  skip: number;

}

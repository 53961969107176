import { ServiceResponse } from './service-response';
import { Expose } from '../serialization/class-transformer/decorators';
import { ResponseCachedDecorator } from './decorators/response-cached.decorator';


@ResponseCachedDecorator()
export class UserCanAccessToServiceResponse extends ServiceResponse {

    @Expose()
    userCanAccessToService: boolean;
}

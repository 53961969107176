import { Expose } from '../../serialization/class-transformer/decorators';
import { FindOptions } from './find-options';

export class FindValuesOptions extends FindOptions {

    @Expose()
    outputProperties: string[];

    constructor(findOptions?: FindOptions, outputProperties: string[] = []) {
        super(findOptions);
        this.outputProperties = outputProperties;
    }
}

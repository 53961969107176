import { MetaDataDescriptions } from '../meta-data/meta-data-descriptions';
import { Expose, Type } from '../serialization/class-transformer';
import { FieldTypes } from './field-types.enum';

export class FieldMetaData {

    constructor() {
        this.descriptions = new MetaDataDescriptions();
        this.customDescriptions = new MetaDataDescriptions();
    }

    @Expose()
    fieldType: FieldTypes;

    @Expose()
    name: string;

    @Expose()
    path: string;

    @Expose()
    isFullColumn: boolean;

    @Expose()
    position: number;

    @Expose()
    isAdditionalField: boolean;

    @Expose()
    isVisible: boolean;

    @Expose()
    isDisabled: boolean;

    @Expose()
    @Type(() => MetaDataDescriptions)
    descriptions: MetaDataDescriptions;

    @Expose()
    @Type(() => MetaDataDescriptions)
    customDescriptions: MetaDataDescriptions;

    @Expose()
    fullPathName?: string;

    @Expose()
    isAdditionalRequired?: boolean;

    @Expose()
    isOriginalRequired?: boolean;
}

import { Expose, Type } from "../../serialization/class-transformer/decorators";

export class IncludeInfo {

    @Expose()
    propertyName: string;

    @Expose()
    entityName: string;

    @Expose()
    includeColumns: boolean;

    @Expose()
    @Type(() => IncludeInfo)
    includes: Array<IncludeInfo>;

    static flatten(include: IncludeInfo): Array<IncludeInfo> {

        const incls = new Array<IncludeInfo>();
        if (include.includes != null && include.includes.length > 0) {
            include.includes.forEach(i => {
                incls.push(i);
            });
        }

        return incls;
    }

    constructor() {
        this.includes = new Array<IncludeInfo>();
    }

}

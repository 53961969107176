
import { AssociationOptionsInterface } from './association-options.interface';
import { BaseDecoratorInterface } from './commons/base-decorator.interface';
import { ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments, registerDecorator } from 'class-validator';
import { BaseValidator } from './commons/base-validator';
import { RequiredDecoratorInterface } from './commons/required-decorator.interface';
import { AssociationPropertyMetaData } from '../../meta-data/association-property-meta-data';
import { MetaDataUtils } from '../../meta-data/meta-data-utils';
import { ExternalMetaData } from '../../meta-data';

export interface ExternalDecoratorInterface extends BaseDecoratorInterface, AssociationOptionsInterface, RequiredDecoratorInterface {
    isRemote?: boolean;
    parentIdentityPropertyPathName?: string;
}

@ValidatorConstraint({ name: 'externalValidator', async: false })
export class ExternalValidator extends BaseValidator<any> implements ValidatorConstraintInterface {

    override validate(value: number, args: ValidationArguments): boolean {
        return super.validate(value, args);
    }

    protected static override getPropertyMetaDataFromDecoratorData<TDecoratorData extends ExternalDecoratorInterface>(
        decoratorData: TDecoratorData, propertyName: string) {
        const propertyMetaData = new ExternalMetaData();
        propertyMetaData.context = decoratorData.context;
        propertyMetaData.descriptions.descriptionKey = decoratorData.descriptionKey;
        propertyMetaData.descriptions.displayNameKey = decoratorData.displayNameKey;
        propertyMetaData.parentIdentityPropertyPathName = decoratorData.parentIdentityPropertyPathName;
        propertyMetaData.isRequired = decoratorData.isRequired;
        propertyMetaData.isRemote = decoratorData.isRemote;
        if (decoratorData.principalPName1) {
            const ass = new AssociationPropertyMetaData();
            ass.principalPropertyName = decoratorData.principalPName1;
            ass.dependentPropertyName = decoratorData.dependantPName1;
            propertyMetaData.associationProperties.push(ass);
        }

        if (decoratorData.principalPName2) {
            const ass = new AssociationPropertyMetaData();
            ass.principalPropertyName = decoratorData.principalPName2;
            ass.dependentPropertyName = decoratorData.dependantPName2;
            propertyMetaData.associationProperties.push(ass);
        }

        if (decoratorData.principalPName3) {
            const ass = new AssociationPropertyMetaData();
            ass.principalPropertyName = decoratorData.principalPName3;
            ass.dependentPropertyName = decoratorData.dependantPName3;
            propertyMetaData.associationProperties.push(ass);
        }

        if (decoratorData.principalPName4) {
            const ass = new AssociationPropertyMetaData();
            ass.principalPropertyName = decoratorData.principalPName4;
            ass.dependentPropertyName = decoratorData.dependantPName4;
            propertyMetaData.associationProperties.push(ass);
        }

        propertyMetaData.principalPropertyName = MetaDataUtils.toPascalCase(propertyName);
        return propertyMetaData;
    }
}

export function ExternalDecorator(decoratorInterface: ExternalDecoratorInterface) {
    return (object: object, propertyName: string) => {

        // Metodo di base per tutti i decoratori
        BaseValidator.initBaseValidator(decoratorInterface, object, propertyName);

        // Aggiunge informazioni alla property sulla validazione sul tipo della classe
        ExternalValidator.buildPropertyMetaData<ExternalDecoratorInterface>(
            object.constructor, propertyName, decoratorInterface);
        // Aggiunge informazioni alla property sulla validazione sull'instanza della classe
        ExternalValidator.buildPropertyMetaData<ExternalDecoratorInterface>(
            object, propertyName, decoratorInterface);

        registerDecorator({
            target: object.constructor,
            propertyName,
            options: {context: decoratorInterface.context},
            constraints: [decoratorInterface],
            validator: ExternalValidator
        });
    };
}

export class ExternalInspector {
    static isApplied(subject: any, propertyName: string): boolean {
        let propertyMetaData = ExternalValidator.getPropertyMetaData(subject, propertyName);
        if (!propertyMetaData) {
            propertyMetaData = ExternalValidator.getPropertyMetaData(subject.constructor, propertyName);
        }
        return propertyMetaData instanceof ExternalMetaData;
    }

    static getValue(subject: any, propertyName: string): ExternalMetaData {
        let propertyMetaData = ExternalValidator.getPropertyMetaData<ExternalMetaData>(subject, propertyName);
        if (!propertyMetaData) {
            propertyMetaData = ExternalValidator.getPropertyMetaData<ExternalMetaData>(subject.constructor, propertyName);
        }
        return propertyMetaData;
    }
}

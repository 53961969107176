<div class="main-container" *ngIf="rootViewModel">
    <nts-expandable *ngFor="let panel of layoutMetaData.panels; let i = index" [panelId]="panel.panelId" [isCollapsed]="
        panel.panelState == panelState.Collapsed" [isHidden]="!panel.isVisible"
        [title]="panel.descriptions.displayName" [showAdditionalFields]="panel.panelState == panelState.Expanded" [disableAdditionalFields]="panel.additionalFields.length == 0"
        [promotedFields]="getPromotedFields(panel, i)">
        <ng-template ngFor let-field [ngForOf]="panel.sortedFields">
            <nts-spacer-box #expandableChild *ngIf="field.fieldType === fieldType.Spacer" [isHidden]="!field.isVisible"
                [additionalField]="field.isAdditionalField" [fullColumn]="field.isFullColumn">
            </nts-spacer-box>
            <nts-labelled-text-box #expandableChild *ngIf="field.fieldType === fieldType.Simple"
                [isHidden]="!field.isVisible" [additionalField]="field.isAdditionalField"
                [fullColumn]="field.isFullColumn"
                [isDisabled]="field.isDisabled"
                [multiline]="getSimpleFieldMetaDataFromFieldMetaData(field).isMultiLine"
                [propertyViewModel]="getPropertyViewModelFromFieldMetaData(field)">
            </nts-labelled-text-box>
            <nts-grid #expandableChild *ngIf="field.fieldType === fieldType.Grid" [isHidden]="!field.isVisible"
                [areAllCommandsDisabled]="getGridMetaDataFromFieldMetaData(field).areAllCommandsDisabled"
                [isDisabled]="getGridMetaDataFromFieldMetaData(field).areAllColumnsDisabled"    
                [additionalField]="field.isAdditionalField" [fullColumn]="field.isFullColumn" [dynamicHeight]="true"
                [collectionViewModel]="getCollectionViewModelFromGridFieldMetaData(getGridMetaDataFromFieldMetaData(field))"
                [columns]="getCollectionViewModelColumnsFromGridFieldMetaData(getGridMetaDataFromFieldMetaData(field))">
            </nts-grid>
            <nts-decode-text-box #expandableChild *ngIf="field.fieldType === fieldType.External"
                [isHidden]="!field.isVisible" [additionalField]="field.isAdditionalField"
                [fullColumn]="field.isFullColumn"
                [isDisabled]="field.isDisabled"
                [showCodeInDescription]="getExternalLayoutMetaData(field).showCodeInDescription"
                [externalPropertyViewModel]="getExternalPropertyViewModelFromExternalMetaData(getExternalMetaDataFromFieldMetaData(field))"
                [code]="getExternalPropertyViewModelCodeFromExternalMetaData(getExternalMetaDataFromFieldMetaData(field))"
                [decodeDescription]="getExternalPropertyViewModelDecodeFromExternalMetaData(getExternalMetaDataFromFieldMetaData(field))">
            </nts-decode-text-box>
            <nts-external-list #expandableChild 
                *ngIf="field.fieldType === fieldType.ExternalList"
                [isHidden]="!field.isVisible" 
                [additionalField]="field.isAdditionalField"
                [fullColumn]="field.isFullColumn"
                [isDisabled]="field.isDisabled"
                [showCodeInDescription]="getExternalLayoutMetaData(field).showCodeInDescription"
                [externalPropertyViewModel]="getExternalPropertyViewModelFromExternalMetaData(getExternalMetaDataFromFieldMetaData(field))"
                [code]="getExternalPropertyViewModelCodeFromExternalMetaData(getExternalMetaDataFromFieldMetaData(field))"
                [decodeDescription]="getExternalPropertyViewModelDecodeFromExternalMetaData(getExternalMetaDataFromFieldMetaData(field))">
            </nts-external-list>
            <nts-custom-field-container #expandableChild *ngIf="field.fieldType === fieldType.Custom"
                [isHidden]="!field.isVisible" [additionalField]="field.isAdditionalField"
                [isDisabled]="field.isDisabled"
                [fullColumn]="field.isFullColumn" [metaData]="getCustomFieldMetaDataFromFieldMetaData(field)"
                [ovm]="orchestratorViewModel">
            </nts-custom-field-container>
        </ng-template>
    </nts-expandable>
</div>

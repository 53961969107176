import { SnapShotFrameIdentity } from '../domain-models/snap-shot-frame-identity';
import { SnapShotFrame } from '../domain-models/snap-shot-frame';
import { NDateTimeOffsetPropertyViewModel } from './base-type/date-time-offset-property-view-model';
import { NumericPropertyViewModel } from './base-type/numeric-property-view-model';
import { StringPropertyViewModel } from './base-type/string-property-view-model';
import { ItemViewModel } from './item-view-model';


export class SnapShotFrameViewModel extends ItemViewModel<SnapShotFrame, SnapShotFrameIdentity> {

    get identity(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._identity = value; }, this._identity, 'identity');
    }    

    get updateUser(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._updateUser = value; }, this._updateUser, 'updateUser');
    }    

    get updateUserCode(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._updateUserCode = value; }, this._updateUserCode, 'updateUserCode');
    }

    get updateDate(): NDateTimeOffsetPropertyViewModel {
        return this.getNDateTimeOffsetPropertyViewModel((value) => { this._updateDate = value; }, this._updateDate, 'updateDate');
    }
    
    get creationUser(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._creationUser = value; }, this._creationUser, 'creationUser');
    }

    get creationUserCode(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._creationUserCode = value; }, this._creationUserCode, 'creationUserCode');
    }

    get creationDate(): NDateTimeOffsetPropertyViewModel {
        return this.getNDateTimeOffsetPropertyViewModel((value) => { this._creationDate = value; }, this._creationDate, 'creationDate');
    }

    private _identity: NumericPropertyViewModel;
    private _updateUser: StringPropertyViewModel;
    private _updateUserCode: NumericPropertyViewModel;
    private _updateDate: NDateTimeOffsetPropertyViewModel;
    private _creationUser: StringPropertyViewModel;
    private _creationUserCode: NumericPropertyViewModel;
    private _creationDate: NDateTimeOffsetPropertyViewModel;

    override async postInit(): Promise<void> {
        await super.postInit();
        this.creationDate.showTime = true;
        this.updateDate.showTime = true;
    }
}

import { Expose } from '../../serialization/class-transformer';

export class CookiePreferencesDto {

    constructor() {
        this.analyticsCookies = false;
        this.essentialCookies = true;
    }

    @Expose()
    analyticsCookies: boolean;
    
    @Expose()
    essentialCookies: boolean;
}

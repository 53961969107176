import { ErrorHandler, Inject, Injectable, Optional } from "@angular/core";
import { TelemetryService } from "@nts/std/src/lib/telemetry";
import { EnvironmentConfiguration } from "@nts/std/src/lib/environments";
import { LogService } from "../log/log.service";
import { UUIDHelper } from "../uuid-helper";
import { ErrorNotificationServiceInterface, ERROR_NOTIFICATION_SERVICE } from "./error-notification-service.interface";

@Injectable()
export class CustomErrorHandlerService implements ErrorHandler {
    
    private defaultErrorHandler: ErrorHandler;

    constructor(
        private telemetryService: TelemetryService,
        private env: EnvironmentConfiguration,
        @Optional()
        @Inject(ERROR_NOTIFICATION_SERVICE) private readonly errorNotificationService: ErrorNotificationServiceInterface
    ) {
        this.defaultErrorHandler = new ErrorHandler();
    }
    
    handleError(error: any) {

        const uuid = UUIDHelper.generateUUID();

        if (!this.env.production) {
            this.defaultErrorHandler.handleError(error);
        }

        if (
            this.telemetryService.initialized
        ) { 
            this.telemetryService.trackException({
                id: uuid,
                exception: error,
                properties: {
                    uuid
                }
            });
            if (this.env.production) {
                LogService.error(error);
            }
            LogService.error('Il tuo errore è stato tracciato, questo è il codice univoco generato per questo errore: ' + uuid)

        }
        
        if (this.errorNotificationService) {
            this.errorNotificationService.showError(uuid, error)
        }       
    }
} 
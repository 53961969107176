<div class="validation-panel" *ngIf="orchestratorViewModel?.allViewModelErrorsBuildedList?.length > 0">
    
    <div class="validation-panel-left">
        <div class="validation-panel-icon">
            <svg-icon key="warning"></svg-icon>
        </div>
    </div>
    <div class="validation-panel-content" [class.expanded]="itemToShow > 1">
        <div class="validation-panel-top" *ngIf="orchestratorViewModel?.allViewModelErrorsBuildedList?.length > 1">
            <div class="validation-panel-title">
                {{
                    ('std_Validation_More_Error' | NTSTranslate: [orchestratorViewModel?.allViewModelErrorsBuildedList?.length.toString()])
                }} <a [routerLink]="[]" (click)="showMore()" *ngIf="orchestratorViewModel?.allViewModelErrorsBuildedList.length > (itemToShow + 1)" role="button" class="validation-panel-bottom-bar-show-button">{{'std_Validation_Show_All_Error' | NTSTranslate}}</a>
            </div>
        </div>
        <div class="validation-panel-bottom" [class.one-validation]="orchestratorViewModel?.allViewModelErrorsBuildedList.length == 1">
            <ng-container [ngSwitch]="orchestratorViewModel?.allViewModelErrorsBuildedList.length">
                <div *ngSwitchCase="1" class="validation-panel-message">
                    <div class="validation-panel-message-text"> 
                        {{ orchestratorViewModel?.allViewModelErrorsBuildedList[0].message }}
                        <a *ngIf="orchestratorViewModel?.allViewModelErrorsBuildedList[0].propertyName && orchestratorViewModel?.allViewModelErrorsBuildedList[0].objectName" href="" (click)="focusMessage($event, orchestratorViewModel?.allViewModelErrorsBuildedList[0])" role="button" class="validation-panel-goto">{{'std_Validation_Goto' | NTSTranslate}}</a>
                    </div>
                </div>
    
                <ng-container *ngSwitchDefault>
                    <div class="validation-panel-message" *ngFor="let error of orchestratorViewModel?.allViewModelErrorsBuildedList.slice(0, itemToShow)">
                        <div class="validation-panel-message-text"> 
                            - {{ error.message }}
                            <a *ngIf="error.propertyName && error.objectName" href="" (click)="focusMessage($event, error)" role="button" class="validation-panel-goto">{{'std_Validation_Goto' | NTSTranslate}}</a>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
    
            <div class="validation-panel-bottom-bar">
                <a [routerLink]="[]" (click)="showLess()" *ngIf="orchestratorViewModel?.allViewModelErrorsBuildedList.length == itemToShow && itemToShow != defaultItemToShow" role="button" class="validation-panel-bottom-bar-show-button">{{'std_Validation_Show_Less_Error' | NTSTranslate}}</a>
            </div>
        </div>
    </div>
    <div class="validation-panel-right">
        <nts-text-button 
            icon="cancel"
            (onClicked)="orchestratorViewModel.eventDispatcher.onValidationBarCollapsed.next(true)"></nts-text-button>
    </div> 
</div>
import { Observable, of } from 'rxjs';
import { BaseViewModelInterface } from '../base-view-model.interface';
import { CoreOrchestratorViewModelInterface } from '../core-orchestrator-view-model.interface';
import { ViewModelStateContextInterface } from './view-model-state-context.interface';
import { ViewModelStates } from './view-model-states';

export abstract class ViewModelState {

  private _startedAsRelatedClient = false;

  protected get startedAsRelatedClient() {
    return this._startedAsRelatedClient;
  }

  protected get viewModel(): BaseViewModelInterface {
    return this._context.viewModel;
  }

  get context() {
    return this._context;
  }

  abstract get value(): ViewModelStates;

  canRestore(): Observable<boolean> { return of(false); }
  restore() { }

  canCreate(): Observable<boolean> { return of(false); }
  create() { }

  canStore(): Observable<boolean> { return of(false); }
  store() { }

  canRemove(): Observable<boolean> { return of(false); }
  remove() { }

  canStoreAndCreate(): Observable<boolean> { return of(false); }
  storeAndCreate() { }

  canValidate() { return of(true); }
  validate() { }

  canFind() { return of(!this.startedAsRelatedClient); }
  find() { }

  canGetByIdentity() { return of(true); }
  getByIdentity() { }

  canGetByObject() { return of(true); }
  getByObject() { }

  canModify() { return of(true); }
  modify() { }

  canStartRelatedClient() { return of(false); }

  canVersioned() { return of(false); }
  getBySnapShotIdentity() {

  }

  constructor(private _context: ViewModelStateContextInterface) {
    if ((_context as CoreOrchestratorViewModelInterface).startedAsRelatedClient != null) {
      this._startedAsRelatedClient = (_context as CoreOrchestratorViewModelInterface).startedAsRelatedClient;
    }
  }
}

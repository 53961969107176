import { NTSReflection } from '@nts/std/src/lib/utility';
import { NotString } from '../../../types';


const NM_ROOT_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY = 'nmRootViewModelTypeDecoratorMetaDataKey';

export function NmRootViewModelTypeDecorator<T>(nmRootType: NotString<T>) {
    return (target: any) => {
        Reflect.defineMetadata(NM_ROOT_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, nmRootType, target);
    };
}

export class NmRootViewModelTypeInspector {

    static META_DATA_KEY = NM_ROOT_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY;
    static DECORATOR_NAME = '@NmRootViewModelTypeDecorator';

    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            NM_ROOT_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): any {
        return NTSReflection.getClassMetadata(
            NM_ROOT_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }
}

import { MessageResourceManager } from './../../../resources/message-resource-manager';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { BaseError } from '../../../messages/base-error';
import { ComponentErrorInterface } from '../component-error.interface';
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { FilledButtonComponent } from '../../shared/buttons/filled-button/filled-button.component';
import { NTSTranslatePipe } from '../../pipe/nts-translation-pipe';

@Component({
    selector: 'nts-offline-mode-page',
    templateUrl: './offline-mode.component.html',
    styleUrls: ['./offline-mode.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        SvgIconComponent,
        FilledButtonComponent,
        NTSTranslatePipe
    ]
})
export class OfflineModeComponent implements ComponentErrorInterface, OnInit {

    // popolata tramite injecton
    errors: BaseError[];
    errorTitle: string;
    errorDescription: string;
    errorDetailDescription: string;
    errorDetailMoreDescription: string;
    showDetailMore = false;
    expandDetailMore = false;
    showMoreLimit = 0; // 0 disabilitato

    constructor(
        public readonly environmentConfiguration: EnvironmentConfiguration,
        private readonly router: Router,
        private readonly cd: ChangeDetectorRef
    ) {
        this.errorTitle = MessageResourceManager.Current.getMessage('std_PageOfflineMode_Title');
        this.errorDescription = MessageResourceManager.Current.getMessage('std_PageOfflineMode_Description');
        this.errorDetailDescription = MessageResourceManager.Current.getMessage('std_PageOfflineMode_DetailDescription');
    }

    ngOnInit(): void {
        if (this.errorDetailDescription.length > this.showMoreLimit && this.showMoreLimit > 0) {
            this.errorDetailMoreDescription = this.errorDetailDescription.substring(this.showMoreLimit, this.errorDetailDescription.length);
            this.errorDetailDescription = this.errorDetailDescription.substring(0, this.showMoreLimit);
            this.showDetailMore = true;
        }
        this.cd.detectChanges();
    }

    retry(): void {
        window.location.reload();
    }
}

import { Expose } from '../serialization/class-transformer/decorators';
import { FieldMetaData } from './field-meta-data';
import { FieldTypes } from './field-types.enum';

export class CustomFieldMetaData extends FieldMetaData {

    constructor() {
        super();
        this.fieldType = FieldTypes.Custom;
    }

    @Expose()
    uniqueId: string;
}

export enum CollectionChangedAction {

    /**
     * Quando viene aggiunto alla fine un elemento
     */
    add,

    /**
     * Quando viene rimosso un elemento
     */
    remove,

    /**
     * Quando viene modificato un elemento
     */
    edit,

    /**
     * Quando viene pulita la collection
     */
    clear,

    /**
     * Quando viene inserito un elemento ad un determinato indice. vedi itemIndex
     */
    insert,

    /**
     * Quando vengono aggiunti più elementi alla volta
     */
    set
}
export class CollectionChangedEventArgs<T> {
    constructor(
        public action: CollectionChangedAction,
        public items: Array<T>,
        public itemsIndex?: Array<number>
    ) { }
}

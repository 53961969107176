import 'reflect-metadata';

export class NTSReflection {

    static getGetters(t: any): string[] {
        const getters = Reflect.ownKeys(t).filter((name) => {
            return name && name !== '__proto__' && Reflect.getOwnPropertyDescriptor(t, name) != null &&
                typeof Reflect.getOwnPropertyDescriptor(t, name)?.get === 'function';
        }) as string[];
        return getters;
    }

    static getMembers(t: any): string[] {
        return Object.keys(t).filter(key => !((typeof key === 'string' && key.charAt(0) === '_') || key === '__proto__'));
    }

    static getSetters(t: any): string[] {
        return Reflect.ownKeys(t).filter(name => {
            return typeof Reflect.getOwnPropertyDescriptor(t, name)?.set === 'function';
        }) as string[];
    }

    static getPropertyNames(t: any): string[] {
        const keys = new Array<string>();
        this.getPropertyNamesForType(t, keys);
        return [...new Set(keys)];
    }

    static getPropertyNamesForType(t: any, keys: string[]) {
        if (t.constructor.name !== 'Object') {
            this.getPropertyNamesForType(Object.getPrototypeOf(t), keys);
        }
        keys.push(...this.getMembers(t), ...this.getGetters(t));
    }

    static getPropertyValue<T>(propertyName: string, instance: any): T {
        return instance[propertyName] as T;
    }

    static getTypedPropertyValue<T>(propertyName: string, instance: any): T {
        return this.getPropertyValue(propertyName, instance) as T;
    }

    static getPropertyMetadata(metadataKey: string, target: any, propertyName: string) {
        return Reflect.getMetadata(metadataKey, target, propertyName);
    }

    static hasPropertyMetadata(metadataKey: string, target: any, propertyName: string) {
        return Reflect.hasMetadata(metadataKey, target, propertyName);
    }

    static getClassMetadata(metadataKey: string, target: any) {
        if (typeof target === 'object') { // se sto passando l'istanza della classe 
            const parentTarget = Object.getPrototypeOf(target).constructor; // recupero la classe
            return Reflect.getMetadata(metadataKey, parentTarget);
        } else { // typeof target === 'function' se sto passando la classe
            return Reflect.getMetadata(metadataKey, target);
        }
    }

    static hasClassMetadata(metadataKey: string, target: any): boolean {
        if (typeof target === 'object') { // se sto passando l'istanza della classe 
            const parentTarget = Object.getPrototypeOf(target).constructor; // recupero la classe
            return Reflect.hasMetadata(metadataKey, parentTarget);
        } else { // typeof target === 'function' se sto passando la classe
            return Reflect.hasMetadata(metadataKey, target);
        }
    }

    static defineMetadata(metadataKey: string, metadata: any) {
        return Reflect.metadata(metadataKey, metadata);
    }

    static setPropertyValue<TValue>(propertyName: string, instance: any, newValue: TValue): void {
        if (instance != null) {
            instance[propertyName] = newValue;
        }
    }
}

import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ZoomResultsColumnCellRendererParams } from './zoom-results-column-cell-renderer-params.interface';
import moment from 'moment';
import { NgIf } from '@angular/common';


// create your cellEditor as a Angular component
@Component({
    template: `
        <div *ngIf="this.params?.data; else loading">
            {{ value }}
        </div>
        <ng-template #loading><div class="lds-ring"><div></div><div></div><div></div><div></div></div></ng-template>
    `,
    standalone: true,
    imports: [
        NgIf
    ]
})
export class ZoomResultsDateTimeOffsetCellRendererComponent implements AgRendererComponent {
    params: ZoomResultsColumnCellRendererParams;
    private _value = null;

    refresh(params: any): boolean {
        return true;
    }
    agInit(params: ZoomResultsColumnCellRendererParams): void {
        this.params = params;
    }

    get value() {
        if (this._value == null) {
            const currentValue = this.params.data[Number.parseInt(this.params.colDef.field, 10)];
            if (currentValue != null) {
                const parsedZone = moment(currentValue).parseZone();
                this._value = parsedZone.local().format('L LT');
            } else {
                this._value = '';
            }
        }
        return this._value;
    }
}

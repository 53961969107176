import { NgxPopperjsDirective } from "ngx-popperjs";
import { fromEvent, takeUntil } from "rxjs";

export class PopperHelper {

    static hide(popper: any) {
        if (popper) {

            if (!popper?._shown && popper?._scheduledShowTimeoutCtrl$) {
                popper._scheduledShowTimeoutCtrl$.next();
                return;
            }

            popper._shown = false;
            if (popper._popperContentRef) {
                popper._popperContentRef?.instance?.hide();
            }
            else if (popper._popperContent) {
                popper._popperContent.hide();
            }
            popper?.popperOnHidden?.emit(this);
            popper?._globalEventListenersCtrl$?.next();
        }    
    }

    static show(popper: any, documentClickListener = false) {
        if (popper) {
            setTimeout(() => {
                
                if (popper._shown) {
                    popper._scheduledHideTimeoutCtrl$.next();
                    return;
                }

                popper._shown = true;
                const popperRef = popper._popperContent;
                const element = popper.getRefElement();
                if (popperRef.referenceObject !== element) {
                    popperRef.referenceObject = element;
                }
                popper._setContentProperties(popperRef);
                popperRef.show();
                popper.popperOnShown.emit(this);
                if (popper.timeoutAfterShow > 0) {
                    popper.scheduledHide(null, popper.timeoutAfterShow);
                }
                fromEvent(document, "click")
                    .pipe(takeUntil(popper._globalEventListenersCtrl$), takeUntil(popper._destroy$))
                    .subscribe({
                        next: (e: MouseEvent) => popper.hideOnClickOutsideHandler(e)
                    }as any);
                fromEvent(popper._getScrollParent(popper.getRefElement()), "scroll")
                    .pipe(takeUntil(popper._globalEventListenersCtrl$), takeUntil(popper._destroy$))
                    .subscribe({
                        next: (e: MouseEvent) => popper.hideOnScrollHandler(e)
                    } as any);

            }, 100)            
        }
    }
}
import { BaseIdentity } from '../domain-models/base-identity';
import { Expose } from '../serialization/class-transformer/decorators';
import { ServiceRequest } from './service-request';

export class RemoveRequest<TIDentity extends BaseIdentity> extends ServiceRequest {

    @Expose()
    identity: TIDentity;

}

import { BaseIdentity } from '../domain-models/base-identity';
import { BaseRowNumberModel } from '../domain-models/base-row-number-model';
import { NumericPropertyViewModel } from './base-type/numeric-property-view-model';
import { BaseRowNumberItemViewModel } from './base-row-number-item-view-model';
import { BaseRowNumberCollectionViewModel } from './base-row-number-collection-view-model';
import { DomainModelRowNumberCollection } from '../domain-models/domain-model-row-number-collection';
export class BaseRowNumberCollectionViewModelHelper {

    static getDomainModelCollection<TModel extends BaseRowNumberModel<TIdentity>,
        TIdentity extends BaseIdentity>(entities: DomainModelRowNumberCollection<TModel, TIdentity>): Array<TModel> {
        return entities.collectionItems.sort((a, b) => a.rowNumber - b.rowNumber);
    }

    static syncRowNumbers<
        TItemViewModel extends BaseRowNumberItemViewModel<TModel, TIdentity>,
        TModel extends BaseRowNumberModel<TIdentity>,
        TIdentity extends BaseIdentity>(
            collection: BaseRowNumberCollectionViewModel<TItemViewModel, TModel, TIdentity>
        ) {
        for (let i = 0; i < collection.length; i++) {
            const property: NumericPropertyViewModel = collection[i].rowNumber as NumericPropertyViewModel;
            if (property != null) {
                property.value = i;
            }
        }
    }
}

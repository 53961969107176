import { UserPanelMetaData } from '../../layout-meta-data/user-panel-meta-data';
import { Expose, Type } from '../../serialization';
import { BaseLayoutDataDto } from './base-layout-data.dto';

export class PanelUserLayoutDataDto extends BaseLayoutDataDto {

    constructor() {
        super();
        this.panelMetaData = new UserPanelMetaData();
    }

    @Expose()
    @Type(() => UserPanelMetaData)
    panelMetaData: UserPanelMetaData
}

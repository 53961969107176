<div class="zoom-list-container">
    <div class="toolbar-section">
        <div class="toolbar-control-content">
          <div class="horizontal-container">
            <div class="root-container">
              <div class="inline-command-bar">
                <div class="action-list-control-container">
                  <div class="horizontal-flex-container" [class.flex-row-reverse]="false">
                    <div class="horizontal-flex-container-item-layout">
                        <div class="command-bar-button-container">
                            <ng-select
                                #select
                                class="zoom-list-autocomplete"
                                [items]="zoomListViewModel.zoomDataList.zooms"
                                [(ngModel)]="zoomListViewModel.selectedZoomData"
                                [searchable]="false"
                                [clearable]="false"
                                [groupBy]="groupByFn"
                                (change)="onChange($event)"
                                (open)="onOpen($event)"
                                appendTo=".zoom-list-container"
                                placeholder="Seleziona Zoom"
                                bindLabel="title">
                                <ng-template ng-footer-tmp>
                                    <nts-text-button 
                                        *ngIf="zoomListViewModel.addZoomCommand.isVisible$ | async"
                                        [title]="zoomListViewModel.addZoomCommand.description"
                                        [label]="zoomListViewModel.addZoomCommand.displayName?.length > 0 ? zoomListViewModel.addZoomCommand.displayName : null"
                                        [isDisabled]="!(zoomListViewModel.addZoomCommand.canExecute$ | async)" 
                                        [icon]="zoomListViewModel.addZoomCommand.iconClass"
                                        (onClicked)="select.close(); zoomListViewModel.addZoomCommand.execute()"></nts-text-button>
                                </ng-template>
                                <ng-template ng-label-tmp let-item="item">
                                    <svg-icon [key]="filterIcon" fontSize="24px"></svg-icon>
                                    <span class="filter-title">{{item.title}}</span>                                    
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                  <div class="item-container">
                                    <div class="item-label">{{item.title}}</div>
                                    <div class="item-icon">
                                      <svg-icon class="standard-item" *ngIf="item.title === 'Standard'" key="home" fontSize="18px"></svg-icon>
                                      <svg-icon class="standard-item"  *ngIf="item.title !== 'Standard' && !item.isPreferred" [key]="getFilterIconFromZoomData(item)" fontSize="18px"></svg-icon>
                                      <svg-icon class="preferred-item" *ngIf="item.isPreferred" key="star" fontSize="18px"></svg-icon>
                                    </div>
                                  </div>
                                </ng-template>
                                <ng-template ng-optgroup-tmp let-item="item" let-index="index">
                                </ng-template>
                                <ng-template ng-header-tmp>
                                  <div class="search-container">
                                      <nts-text-button [icon]="'search'"></nts-text-button>
                                      <nts-base-text-box 
                                          (onValueChange)="select.filter($any($event))"
                                          [tabIndex]="0"
                                          [showErrorTooltip]="false"
                                          [showErrorBorder]="false"
                                          [value]="''"
                                          [placeholder]="'Ricerca'">
                                      </nts-base-text-box>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>

                    <ng-template ngFor let-command [ngForOf]="firstGroupCommands" let-i="index">
                      <div class="horizontal-flex-container-item-layout">
                        <div class="command-bar-button-container responsive-ribbon-button">
                          <nts-ribbon-button 
                            *ngIf="firstGroupCommandList[i].isVisible"
                            [title]="command.tooltip"
                            [tabIndex]="0"
                            [icon]="command.iconClass"
                            [commands]="command.children"
                            [isDisabled]="!(firstGroupCommandList[i].isEnabled)"
                            (onClicked)="command.execute()">
                          </nts-ribbon-button>
                        </div>
                      </div>
                    </ng-template>

                    <div class="navigation-bar-row-separator">
                    </div>

                    <ng-template ngFor let-command [ngForOf]="secondGroupCommands" let-i="index">
                      <div class="horizontal-flex-container-item-layout">
                        <div class="command-bar-button-container responsive-ribbon-button">
                          <nts-ribbon-button 
                            *ngIf="secondGroupCommandList[i].isVisible"
                            [title]="command.tooltip"
                            [tabIndex]="0"
                            [label]="command.displayName"
                            [icon]="command.iconClass"
                            [commands]="command.children"
                            [isDisabled]="!(secondGroupCommandList[i].isEnabled)"
                            (onClicked)="command.execute()">
                          </nts-ribbon-button>
                        </div>
                      </div>
                    </ng-template>

                    <div class="navigation-bar-row-separator">
                    </div>

                    <ng-template ngFor let-command [ngForOf]="thirdGroupCommands" let-i="index">
                      <div class="horizontal-flex-container-item-layout">
                        <div class="command-bar-button-container responsive-ribbon-button">
                          <nts-ribbon-button 
                            *ngIf="thirdGroupCommandList[i].isVisible"
                            [title]="command.tooltip"
                            [tabIndex]="0" 
                            [label]="command.displayName"
                            [icon]="command.iconClass"
                            [commands]="command.children"
                            [isDisabled]="!(thirdGroupCommandList[i].isEnabled)"
                            (onClicked)="command.execute()">
                          </nts-ribbon-button>
                        </div>
                      </div>
                    </ng-template>

                    <div class="default-actions-container">

                      <nts-filled-button #findCommand
                          [title]="zoomParametersViewModel.findCommand.tooltip"
                          [label]="zoomParametersViewModel.findCommand.displayName"
                          [icon]="zoomParametersViewModel.findCommand.iconClass"
                          [isLoading]="(zoomParametersViewModel.findCommand.loading$ | async)"
                          [type]="filledButtonTypeEnum.Success"
                          [isDisabled]="!(zoomParametersViewModel.findCommand.canExecute$ | async)"
                          (onClicked)="zoomParametersViewModel.findCommand.execute()"></nts-filled-button>

                     </div>
                  </div>
                </div>
              </div>
            </div>    
          </div>
        </div>
      </div>
</div>
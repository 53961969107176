import { Expose, Type } from "../../../serialization/class-transformer/decorators";
import { ZoomIdentity } from "../zoom.identity";

export class ZoomDataDto  {
    
    @Expose()
    @Type(() => ZoomIdentity)
    zoomIdentity: ZoomIdentity;

    @Expose()
    title: string;

    @Expose()
    isPreferred: boolean;

    @Expose()
    roleId: number;

    constructor(
        zoomIdentity: ZoomIdentity = undefined, 
        title: string = undefined, 
        isPreferred: boolean = undefined,
        roleId: number = undefined
    ) {
        this.zoomIdentity = zoomIdentity;
        this.title = title;
        this.isPreferred = isPreferred;
        this.roleId = roleId;
    }
}
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ErrorNotificationServiceInterface } from "./error-notification-service.interface";

@Injectable()
export class ErrorNotificationService implements ErrorNotificationServiceInterface {
    
    $error = new BehaviorSubject<{uuid: string, error: any}|null>(null);
    
    showError(uuid: string, error: any) {
        this.$error.next({
            uuid, error
        })
    }
}
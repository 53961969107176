import { PropertyViewModel } from '../property-view-model';
import { PropertyViewModelInitializationInfo } from '../property-view-model-initialization-info';
import { ModelInterface } from '../../domain-models/model.interface';
import { NumericMetaData } from '../../meta-data/numeric-meta-data';
import { NumeralService } from '@nts/std/src/lib/utility';
import { takeUntil } from 'rxjs/operators';
import { NtsMaskedNumber } from '../../components/controls/core/base/base-numeric-box/base-numeric-box.component';
import { BaseError } from '../../messages/base-error';
import { BaseValidator } from '../../domain-models/decorators/commons/base-validator';
import { ValidationArguments } from 'class-validator';
import { NumberValidator } from '../../domain-models/decorators/number.decorator';
import { SourceMessage } from '../message-container';
import { ValidationErrorCodes } from '../../resources/validation-error-codes';
import { RangeValidator } from '../../domain-models/decorators/validations/range-validation';

export abstract class BaseNumericPropertyViewModel extends PropertyViewModel<number>  {

    static MAX_DECIMAL_DIGIT = 16;
    static MAX_INTEGER_DIGIT = 16;
    static MAX_DIGIT = 16;

    private internalMinValue: null | number = null;

    /**
     * Se impostato agisce direttamente sull'input senza consentire l'inserimento di un valore più basso di quello definito
     */
    get minValue(): number | null {
        return this.internalMinValue;
    }
    set minValue(value: number | null) {
        if (this.internalMinValue !== value) {
            this.internalMinValue = value;
            this.onPropertyChanged('minValue');
        }
    }

    private internalMinValueValidation: null | number = null;

    /**
     * Se impostato visualizza un errore se viene inserito un valore più basso di quello definito
     * Non viene considerato se è già stata impostata la property minValue
     */
    get minValueValidation(): number | null {
        return this.internalMinValueValidation;
    }
    set minValueValidation(value: number | null) {
        if (this.internalMinValueValidation !== value) {
            this.internalMinValueValidation = value;
            this.validate();
            this.onPropertyChanged('minValueValidation');
        }
    }

    private internalMaxValue: null | number = null;

    /**
     * Se impostato agisce direttamente sull'input senza consentire l'inserimento di un valore più alto di quello definito
     */
    get maxValue(): number | null {
        return this.internalMaxValue;
    }
    set maxValue(value: number | null) {
        if (this.internalMaxValue !== value) {
            this.internalMaxValue = value;
            this.onPropertyChanged('maxValue');
        }
    }

    private internalMaxValueValidation: null | number = null;

    /**
     * Se impostato visualizza un errore se viene inserito un valore più basso di quello definito
     * Non viene considerato se è già stata impostata la property maxValue
     */
    get maxValueValidation(): number | null {
        return this.internalMaxValueValidation;
    }
    set maxValueValidation(value: number | null) {
        if (this.internalMaxValueValidation !== value) {
            this.internalMaxValueValidation = value;
            this.validate();
            this.onPropertyChanged('maxValueValidation');
        }
    }

    decimalLimit: number;
    integerLimit: number;
    format: string;
    private internalUseThousandSeparator = false;

    override get value(): number {
        return super.getValue();
    }
    override set value(updatedValue: number) {
        this.setValueAsync(updatedValue);
    }

    get useThousandSeparator(): boolean {
        return this.internalUseThousandSeparator;
    }
    set useThousandSeparator(value: boolean) {
        if (this.internalUseThousandSeparator !== value) {
            this.internalUseThousandSeparator = value;
            this.onPropertyChanged('useThousandSeparator');
        }
    }
    static getDecimalDigits(propertyName: string, integerLimit: number | null, decimalLimit: number | null): number {
        if (integerLimit != null && decimalLimit != null && (integerLimit + decimalLimit) > this.MAX_DIGIT) {
            throw new Error("I metadati della propery " + propertyName + " superano i limite massimo di " + this.MAX_DIGIT);
        }
        if (integerLimit == null && decimalLimit != null) {
            return decimalLimit < this.MAX_DECIMAL_DIGIT ? decimalLimit : this.MAX_DECIMAL_DIGIT;
        }
        if (integerLimit != null && decimalLimit == null) {
            return 0;
        }
        if (integerLimit == null && decimalLimit == null) {
            return 0;
        }
        if (integerLimit != null && decimalLimit != null) {
            return decimalLimit < this.MAX_DECIMAL_DIGIT ? decimalLimit : this.MAX_DECIMAL_DIGIT;
        }
        return null;
    }

    static getIntegerDigits(propertyName: string, integerLimit: number, decimalLimit: number): number {
        if (integerLimit != null && decimalLimit != null && (integerLimit + decimalLimit) > this.MAX_DIGIT) {
            throw new Error("I metadati della propery " + propertyName + " superano i limite massimo di " + this.MAX_DIGIT);
        }
        if (integerLimit == null && decimalLimit != null) {
            return (this.MAX_DIGIT - decimalLimit) > this.MAX_INTEGER_DIGIT ? this.MAX_INTEGER_DIGIT : (this.MAX_DIGIT - decimalLimit);
        }
        if (integerLimit != null && decimalLimit == null) {
            return integerLimit < this.MAX_INTEGER_DIGIT ? integerLimit : this.MAX_INTEGER_DIGIT;
        }
        if (integerLimit == null && decimalLimit == null) {
            return this.MAX_INTEGER_DIGIT < this.MAX_DIGIT ? this.MAX_INTEGER_DIGIT : this.MAX_DIGIT;
        }
        if (integerLimit != null && decimalLimit != null) {
            return integerLimit < this.MAX_INTEGER_DIGIT ? integerLimit : this.MAX_INTEGER_DIGIT;
        }
        return null;
    }

    constructor(
        initInfo: PropertyViewModelInitializationInfo,
        valueIsNullable: boolean
    ) {
        super(initInfo, true);

        const metaData = initInfo.propertyMetaData as NumericMetaData;

        this.minValueValidation = metaData.minValue;
        this.maxValueValidation = metaData.maxValue;

        this.decimalLimit = metaData.maxDecimalPrecision;
        this.integerLimit = metaData.maxIntegerPrecision;
        if (this.decimalLimit > 0) {
            this.useThousandSeparator = true;
        }

        this.updateFormat();

        this.propertyChanged.pipe(takeUntil(this.destroySubscribers$)).subscribe((args) => {
            if (args.propertyName == 'useThousandSeparator') {
                this.updateFormat();
            }
        });
    }

    override getModelErrors(propertyName: string): BaseError[] {

        const allPropertyErrors: BaseError[] = super.getModelErrors(propertyName);

        const minValue = this.minValueValidation ?? this.minValue;
        const maxValue = this.maxValueValidation ?? this.maxValue;

        // Se è valorizzatoa livello di pvm
        if (minValue != null || maxValue != null) {
            // Pulisco gli errori aggiunti dal domain model (quelli derivanti dai decoratori)
            const cleanedPropertyErrors = allPropertyErrors.filter(
                (propertyError) => propertyError.code !== ValidationErrorCodes.NumberMustBeGreater &&
                    propertyError.code !== ValidationErrorCodes.NumberMustBeLess &&
                    propertyError.code !== ValidationErrorCodes.NumberOutOfRange
            )

            const validator = new RangeValidator(minValue, maxValue, this.metadataShortDescription);
            if (!validator.validate(this.value)) {
                const e = new BaseError();
                e.description = validator.errorMessage;
                e.code = validator.messageCode;
                e.propertyName = this.propertyName;
                cleanedPropertyErrors.push(e);
            }

            return cleanedPropertyErrors;
        }

        return allPropertyErrors;
    }

    static getDecimalSeparator() {
        return (NumeralService.Current as any).localeData(NumeralService.Current.locale()).delimiters.decimal;
    }

    getNumeric(value: string): number {

        const mask = new NtsMaskedNumber({
            mask: Number,
            scale: this.decimalLimit ? this.decimalLimit : null,
            signed: false,
            padFractionalZeros: true,
            normalizeZeros: true,
            overwrite: 'shift',
            max: this.maxValue,
            min: this.minValue,
            thousandsSeparator: this.useThousandSeparator ? BaseNumericPropertyViewModel.getThousandSeparator() : '',
            radix: BaseNumericPropertyViewModel.getDecimalSeparator(),
            mapToRadix: [BaseNumericPropertyViewModel.getDecimalSeparator()],
            nullable: true
        } as any)

        mask.value = value;
        return mask.typedValue;
    }

    static getThousandSeparator() {
        return (NumeralService.Current as any).localeData(NumeralService.Current.locale()).delimiters.thousands;
    }

    static getNumericFormat(integerLimit: number, decimalLimit: number, useThousandSeparator: boolean, propertyName: string = ''): string {
        let format;
        if (decimalLimit === 0 || !decimalLimit) {
            // è un int
            format = useThousandSeparator ? '0,0' : '0';
        } else {
            // è un decimal
            format = useThousandSeparator ? '0,0.' : '0.';
            if (decimalLimit) {
                const digits = BaseNumericPropertyViewModel.getDecimalDigits(propertyName, integerLimit, decimalLimit);
                format += '0'.repeat(digits);
            }
        }
        return format;
    }

    updateFormat() {
        this.format = BaseNumericPropertyViewModel.getNumericFormat(this.integerLimit, this.decimalLimit, this.useThousandSeparator, this.propertyName);
    }

    override validateCustomPropertyViewModel(baseErrors: BaseError[]): boolean {
        let result = true;
        if (this.parent) {
            const propertyMetaData: NumericMetaData = BaseValidator.getPropertyMetaData(this.parent, this.propertyName);

            const args: Partial<ValidationArguments> = {
                constraints: [NumberValidator.getDecoratorDataFromPropertyMetaData(propertyMetaData)],
                object: this.parent,
                property: this.propertyName
            }

            const validator = new NumberValidator();
            result = validator.validate(this.value, args as any);

            if (result === false) {
                const messageError = new BaseError();
                messageError.propertyName = this.propertyName;
                messageError.objectName = this.parent.classType;
                messageError.description = validator.errorMessage;
                messageError.code = 'VALIDATION_ERROR';
                baseErrors.push(messageError);
            }
        }

        return result;
    }

    override async setValueAsync(updatedValue: number) {
        if (this._isCustom && this.customSetter == null) {
            throw new Error('You must implement value setter');
        }
        if (this.value !== updatedValue) {
            if (this.customSetter != null) {
                await this.customSetter(updatedValue);
                if (this.isCustom) {
                    this.validate()
                } else if (this.model != null) {
                    this.validate();
                } else if (this.parent != null && this.parent.validate) {
                    this.parent.validate();
                }
                this.onPropertyChanged(this.bindedValuePropertyName);
            } else {
                // quano faccio il sync model scatta onPropertyChanged partendo dal modello
                this.syncModel(updatedValue);
                this.validate();
            }

        }
    }

    override setModel(model: ModelInterface) {
        super.setModel(model);
    }

    override async resetValue(useDefaultValue: boolean): Promise<void> {
        await this.setValueAsync(useDefaultValue ? 0 : this._defaultFaultBackValue);
    }

    setDefaultValueFromLayoutMetaData(defaultValue: string): void {
        if (defaultValue?.length > 0) {
            const splittedWithDecimal = defaultValue.split(this.getDecimalSeparator())
            if (splittedWithDecimal.length > 1) {
                this._layoutDefaultValue = parseFloat(defaultValue);
            } else {
                this._layoutDefaultValue = parseInt(defaultValue, 10);
            }
        }
    }

    private getDecimalSeparator() {
        return (NumeralService.Current as any).localeData(NumeralService.Current.locale()).delimiters.decimal;
    }
}


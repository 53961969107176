<div class="columns-container" [@isCollapsedChanged]="isSafeCollapsed"
  (@isCollapsedChanged.start)="animationStarted($event)" (@isCollapsedChanged.done)="animationDone($event)"
  [ngClass]="{ 'collapsed': isSafeCollapsed && !isClosing && !isOpening }">
  <!-- <nts-loader [isVisible]="actionInProgress"></nts-loader> -->
  <div class="columns-header">
    <strong [@isClosing]="isClosing">{{columnsPanelTitle}}</strong>
  </div>
  <div class="columns-content">
    <div [@isClosing]="isClosing">
      <div class="columns-content-header">
        <nts-checkbox [(value)]="!allColumnsHide" (valueChange)="onAllColumnsHideEvent()"
          [title]="selectDeselectAllTitle"></nts-checkbox>
        <input class="search-columns" [(ngModel)]="filterText" placeholder="{{filterTextPlaceholder}}">
      </div>

      <div cdkDropList class="column-list" (cdkDropListDropped)="drop($event)">

        <div class="column-box" *ngFor="let item of filterFunction()" cdkDrag>
          <nts-checkbox (valueChange)="onColumnChangeEvent($event, item)" [value]="item.isVisible">
          </nts-checkbox>
          <div [title]="item.headerTooltip" class="column-label" (click)="debug(item)">{{item.header}}</div>
          <div *ngIf="filterText.length === 0" class="column-move-handle" cdkDragHandle>
            <i class="dx-icon-dragvertical"></i>
            <!-- <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
              <path
                d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
              </path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg> -->
          </div>
          <div class="column-placeholder" *cdkDragPlaceholder></div>
        </div>
      </div>

      <!-- <div class="column-item" *ngFor="let item of filterFunction()" cdkDrag>
        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
        <nts-checkbox (valueChange)="onColumnChangeEvent($event, item)" [value]="item.isVisible" [label]="item.header">
        </nts-checkbox>
      </div> -->
    </div>
  </div>
</div>

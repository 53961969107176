import { BaseNumericPropertyViewModel } from './base-numeric-property-view-model';
import { PropertyViewModelInitializationInfo } from '../property-view-model-initialization-info';
import { NumeralService } from '@nts/std/src/lib/utility';

export class NumericPropertyViewModel extends BaseNumericPropertyViewModel {

    getFormattedValue(value: number) {
        if (value == null || typeof value !== 'number') {
            // TODO: we should throw an exception
            return '';
        }
        return NumeralService.Current(value).format(this.format);
    }

    constructor(
        initInfo: PropertyViewModelInitializationInfo,
    ) {
        super(initInfo, false);
        this._defaultFaultBackValue = 0;
    }
}

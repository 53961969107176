import { CommandsPage } from './commands-page';
import { BaseCommandItem } from './base-command-item';

export class CommandsCategory extends BaseCommandItem {
    pages: Array<CommandsPage> = new Array<CommandsPage>();

    addPage(page: CommandsPage) {
        if (this.pages.find(p => p.name === page.name) == null) {
            this.pages.push(page);
        }
    }

    removeEmptyPages() {
        this.pages.forEach(p => p.removeEmptyGroups());
        const pages = this.pages.filter(p => p.commandGroups.length > 0);
        this.pages = new Array<CommandsPage>();
        this.pages.push(...pages);
    }
}

import { registerDecorator, ValidationArguments, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator';
import { TextDecoratorInterface, TextValidator } from './text.decorator';
import { EmailValidation } from './validations/email-validation';
import { StringLengthValidation } from './validations/string-length-validation';
import { StringMetaData } from '../../meta-data/string-meta-data';
import { BaseValidator } from './commons/base-validator';
import { BaseValidationDecoratorInterface } from './commons/base-validation-decorator.interface';
import { MetaDataUtils } from '../../meta-data/meta-data-utils';

export interface StringDecoratorInterface extends TextDecoratorInterface {
    /**
     * Lunghezza massima della stringa
     */
    maxLength?: number;

    /**
     * Lunghezza minima della stringa
     */
    minLength?: number;

    /**
     * La stringa deve contenere un indirizzo e-mail
     */
    isEmail?: boolean;

    /**
     * Indica che si tratta della descrizione principale dell'oggetto
     */
    isMainDescription?: boolean;
}

@ValidatorConstraint({ name: 'stringValidator', async: false })
export class StringValidator extends TextValidator implements ValidatorConstraintInterface {

    protected static override getPropertyMetaDataFromDecoratorData<TDecoratorData extends BaseValidationDecoratorInterface>(
        decoratorData: TDecoratorData, propertyName: string) {
        const propertyMetaData = new StringMetaData();
        propertyMetaData.context = decoratorData.context;
        propertyMetaData.isRequired = (decoratorData as StringDecoratorInterface).isRequired;
        propertyMetaData.isEmail = (decoratorData as StringDecoratorInterface)?.isEmail;
        propertyMetaData.maxLen = (decoratorData as StringDecoratorInterface)?.maxLength;
        propertyMetaData.descriptions.descriptionKey = (decoratorData as StringDecoratorInterface)?.descriptionKey;
        propertyMetaData.descriptions.displayNameKey = (decoratorData as StringDecoratorInterface)?.displayNameKey;
        propertyMetaData.allowedCharacters = (decoratorData as StringDecoratorInterface)?.allowedChars;
        propertyMetaData.name = MetaDataUtils.toPascalCase(propertyName);
        return propertyMetaData;
    }

    static getDecoratorDataFromPropertyMetaData(
        propertyMetaData: StringMetaData) {
        const decoratorData: StringDecoratorInterface = {};
        decoratorData.isRequired = propertyMetaData.isRequired;
        decoratorData.isEmail = propertyMetaData.isEmail;
        decoratorData.maxLength = propertyMetaData.maxLen;
        decoratorData.descriptionKey = propertyMetaData.descriptions.descriptionKey;
        decoratorData.displayNameKey = propertyMetaData.descriptions.displayNameKey;
        decoratorData.allowedChars = propertyMetaData.allowedCharacters;
        decoratorData.context = propertyMetaData.context;
        return decoratorData;
    }

    override validate(value: string, args: ValidationArguments): boolean {
        const decorator = args.constraints[0] as StringDecoratorInterface;
        const result = super.validate(value, args) &&
            this.validateLength(value, decorator.minLength, decorator.maxLength, args) &&
            this.validateEmail(value, decorator.isEmail, args);
        return result;
    }

    validateLength(value: string, minLength: number, maxLength: number, args): boolean {
        const stringLengthValidation = new StringLengthValidation(minLength, maxLength);
        if (!stringLengthValidation.validate(value, args)) {
            this.errorMessage = stringLengthValidation.errorMessage;
            return false;
        }
        return true;
    }

    validateEmail(value: string, isEmail: boolean, args: ValidationArguments): boolean {
        if (isEmail === true) {
            const emailValidation = new EmailValidation();
            if (!emailValidation.validate(value, args)) {
                this.errorMessage = emailValidation.errorMessage;
                return false;
            }
        }
        return true;
    }
}

export function StringDecorator(decoratorInterface?: StringDecoratorInterface) {
    return (object: object, propertyName: string) => {

        // Metodo di base per tutti i decoratori
        BaseValidator.initBaseValidator(decoratorInterface, object, propertyName);

        // Aggiunge informazioni alla property sulla validazione sul tipo della classe
        StringValidator.buildPropertyMetaData<StringDecoratorInterface>(
            object.constructor, propertyName, decoratorInterface);
        // Aggiunge informazioni alla property sulla validazione sull'instanza della classe
        StringValidator.buildPropertyMetaData<StringDecoratorInterface>(
            object, propertyName, decoratorInterface);

        registerDecorator({
            target: object.constructor,
            propertyName,
            options: {context: decoratorInterface.context},
            constraints: [decoratorInterface],
            validator: StringValidator
        });
    };
}


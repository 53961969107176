<p-pickList 
    [dragdrop]="true"
    [source]="parameters.filters | NTSFilter: true:'isSelected' | NTSFilter: null:'orderBy'"
    [target]="target"
    [showSourceControls]="false"
    (onMoveToSource)="onMoveToSource($event)"
    (onMoveAllToTarget)="onMoveAllToTarget($event)"
    (onMoveToTarget)="onMoveToTarget($event)"
    (onMoveAllToSource)="onMoveAllToSource($event)"
    (onTargetReorder)="onTargetReorder($event)">
    <ng-template let-filter pTemplate="item">
        <div class="item-container">
            <nts-label-box
            [class.zoom-label]="filter.externalDomainModelNameToZoom !== ''" [title]="filter.propertyNameMap.displayName"
            [label]="filter.propertyNameMap.displayName"></nts-label-box>
            <svg-icon (click)="toggleSort($event, filter)" [key]="getSortIcon(filter)"></svg-icon>
        </div>
    </ng-template>
</p-pickList>
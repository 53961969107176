import { DateTimeMetaData } from '../../meta-data/date-time-meta-data';
import { registerDecorator, ValidationArguments, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator';
import { RangeValidation } from './validations/range-validation';
import { BaseValidator } from './commons/base-validator';
import { BaseValidationDecoratorInterface } from './commons/base-validation-decorator.interface';
import { MetaDataUtils } from '../../meta-data/meta-data-utils';

export interface DateDecoratorInterface extends BaseValidationDecoratorInterface {
    minValue?: Date;
    maxValue?: Date;
    isDateTimeOffset?: boolean;
}

@ValidatorConstraint({ name: 'dateValidator', async: false })
export class DateValidator extends BaseValidator<Date> implements ValidatorConstraintInterface {

    override validate(value: Date, args: ValidationArguments): boolean {
        const decorator = args.constraints[0] as DateDecoratorInterface;
        const result = super.validate(value, args) &&
            this.validateRangeDate(value, decorator.minValue, decorator.maxValue, args);
        return result;
    }

    protected static override getPropertyMetaDataFromDecoratorData<TDecoratorData extends BaseValidationDecoratorInterface>(
        decoratorData: TDecoratorData, propertyName: string) {
        const propertyMetaData = new DateTimeMetaData();
        propertyMetaData.context = decoratorData.context;
        propertyMetaData.isRequired = (decoratorData as DateDecoratorInterface).isRequired;
        propertyMetaData.isDateTimeOffset = (decoratorData as DateDecoratorInterface).isDateTimeOffset;
        propertyMetaData.descriptions.descriptionKey = (decoratorData as DateDecoratorInterface).descriptionKey;
        propertyMetaData.descriptions.displayNameKey = (decoratorData as DateDecoratorInterface).displayNameKey;
        propertyMetaData.name = MetaDataUtils.toPascalCase(propertyName);
        return propertyMetaData;
    }

    private validateRangeDate(value: Date, minValue: Date, maxValue: Date, args: ValidationArguments) {
        const rangeValidation = new RangeValidation(minValue, maxValue);
        if (!rangeValidation.validate(value, args)) {
            this.errorMessage = rangeValidation.errorMessage;
            return false;
        }
        return true;
    }
}

export function DateDecorator(decoratorInterface: DateDecoratorInterface) {
    return (object: object, propertyName: string) => {

        // Metodo di base per tutti i decoratori
        DateValidator.initBaseValidator(decoratorInterface, object, propertyName);

        // Aggiunge informazioni alla property sulla validazione sul tipo della classe
        DateValidator.buildPropertyMetaData<DateDecoratorInterface>(
            object.constructor, propertyName, decoratorInterface);
        // Aggiunge informazioni alla property sulla validazione sull'instanza della classe
        DateValidator.buildPropertyMetaData<DateDecoratorInterface>(
            object, propertyName, decoratorInterface);

        registerDecorator({
            target: object.constructor,
            propertyName,
            options: {context: decoratorInterface.context},
            constraints: [decoratorInterface],
            validator: DateValidator
        });
    };
}

import { NgClass, NgFor } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { PopperHelper } from "@nts/std/src/lib/utility";
import { NgxPopperjsDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from "ngx-popperjs";

@Component({
    selector: 'nts-base-text-area-box',
    templateUrl: './base-text-area-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./base-text-area-box.component.scss'],
    standalone: true,
    imports: [
        NgxPopperjsModule,
        FormsModule,
        NgClass,
        NgFor
    ]
})
export class BaseTextAreaBoxComponent implements OnInit, OnChanges {
    @Input() maxLength = 524288;
    @Input() rows = 5;
    @Input() tabIndex = -1;
    @Input() isDisabled = false;
    @Input() value = "";
    @Input() isReadonly = false;
    @Input() placeholder = "";
    @Input() customClasses = "";
    @Input() errorList: string[] = [];
    @Input() showErrorTooltip = true;
    @Input() showErrorBorder = true;
@Input() primaryColor = null;
    @Input() defaultBorderColor = null;
    @Input() activeBorderColor = null;
    @Input() hoverBorderColor = null;

    @Output() onBlur = new EventEmitter();
    @Output() onFocus = new EventEmitter();
    @Output() onValueChange = new EventEmitter();

    @ViewChild('textArea', { static: true }) textArea: ElementRef;
    @ViewChild(NgxPopperjsDirective, { static: true }) popperError: NgxPopperjsDirective;

    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;
    overrideBorderColor = null;
    isActive = false;
    isHover = false;

    constructor(private cd : ChangeDetectorRef) {

    }

    ngOnInit(): void {
        this.handleOverridesColors();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['errorList']) {
            this.checkPopper();
        }
    }

    blur(e): void {
        this.isActive = false;
        this.handleOverridesColors();
        this.popperError?.hide();
        this.onBlur.emit(e);
    }

    focus(e): void {
        this.isActive = true;
        this.handleOverridesColors();
        this.onFocus.emit(e);
    }

    mouseEnter(e) {
        this.isHover = true;
        this.handleOverridesColors();
    }

    mouseLeave(e) {
        this.isHover = false;
        this.handleOverridesColors();
    }

    valueChange(value: any): void {
        this.onValueChange.emit(value);
    }

    handleOverridesColors() {
        if (!this.defaultBorderColor || !this.activeBorderColor || !this.hoverBorderColor) {
            // devono essere impostate tutte e tre le variabili
            return;
        }

        this.overrideBorderColor = this.defaultBorderColor;
        if (this.isActive && !this.isDisabled) {
            this.overrideBorderColor = this.activeBorderColor;
        }
        if (this.isHover && !this.isDisabled) {
            this.overrideBorderColor = this.hoverBorderColor;
        }
    }

    private checkPopper() {
        if (this.popperError && (this.errorList == null || this.errorList.length === 0)) {
            PopperHelper.hide(this.popperError);
        }   
        // if (this.popperInfo && this.errorList?.length > 0) {
        //     PopperHelper.hide(this.popperInfo);
        // }
    }
}

import { HttpCommunication } from './http-communication';
import { MetaDataRequest } from '../requests/meta-data-request';
import { Observable } from 'rxjs';
import { MetaDataResponse } from '../responses/meta-data-response';
import { GenericServiceResponse } from '../responses/generic-service-response';
import { GenericServiceRequest } from '../requests/generic-service-request';
import { AuthTokenDto } from '../domain-models/dto/auth-token.dto';
import { HttpHeaders } from '@angular/common/http';
import { TenantProfile } from '../domain-models/license/tenant-profile';
import { GenericArrayServiceResponse } from '../responses/generic-array-service-response';
import { AutoCompleteRequest } from '../requests/auto-complete-request';
import { AutoCompleteExternalOptions } from '../domain-models/autocomplete/auto-complete-external-options';
import { FindValuesResponse } from '../responses/find-values-response';
import { UserProfile } from '../domain-models/user/user-profile';
import { VersionDataDto } from '../domain-models/dto/version-data.dto';
import { map } from 'rxjs/operators';
import { VersionManager } from '../resources/version-manager';
import { UserCanAccessToServiceResponse } from '../responses/user-can-access-to-service-response';
import { MultiReportInfoDto } from '../domain-models/report/multi-report-info.dto';
import { PrintOutDemandDto } from '../domain-models/report/print-out-demand.dto';
import { OperationIdentity } from '../domain-models/report/operation.identity';
import { OperationStateResultDto } from '../domain-models/spool/operation-state-result-dto';
import { AttachmentIdentity } from '../domain-models/spool/attachment.identity';
import { AggregateMetaData } from '../meta-data/aggregate-meta-data';
import { LayoutMetaData } from '../layout-meta-data/layout-meta-data';
import { DraftLayoutUIInfoDto } from '../domain-models/layout/draft-layout-ui-info.dto';
import { CreateDraftLayoutDataDto } from '../domain-models/layout/create-draft-layout-data.dto';
import { AvailableLayoutsInfoDto } from '../domain-models/layout/available-layouts-info.dto';
import { GetAvailableLayoutsDataDto } from '../domain-models/layout/get-available-layouts-data.dto';
import { LayoutDefinitionIdentity } from '../domain-models/layout/layout-definition.identity';
import { ServiceResponse } from '../responses/service-response';
import { PanelUserLayoutDataDto } from '../domain-models/layout/panel-user-layout-data.dto';
import { UserLayoutMetaData } from '../layout-meta-data/user-layout-meta-data';
import { GridUserLayoutDataDto } from '../domain-models/layout/grid-user-layout-data.dto';
import { BaseLayoutDataDto } from '../domain-models/layout/base-layout-data.dto';
import { GenericArrayServiceRequest } from '../requests/generic-array-service-request';
import { ServiceDataObjectDto } from '../auth/dto/service-data-object.dto';
import { ServiceDataAccessDto } from '../auth/dto/service-data-access.dto';
import { CookiePreferencesResponse } from '../responses/cookie-preferences-response';
import { FindValuesOptions } from '../domain-models/find-options/find-values-options';
import { FindValuesExternalRequest } from '../requests/find-values-external-request';
import { FindValuesExternalOptions } from '../domain-models/find-options/find-values-external-options';
import { CacheOptionsInterface } from './web-api-service-agent';
import { EnterpriseListResponse } from '../responses/enterprise-list-response';
import { TIMEOUT_CACHE } from '@nts/std/src/lib/utility';

export class ActionHttpCommunication extends HttpCommunication {

    static readonly getMetaDataRequestUri: string = 'GetMetaData';
    static readonly getPresentationMetaDataRequestUri: string = 'GetPresentationMetaData';
    static readonly getAvailableLayoutsRequestUri: string = 'GetAvailableLayouts';
    static readonly setPanelUserLayoutDataRequestUri: string = 'SetPanelUserLayoutData';
    static readonly setGridUserLayoutDataRequestUri: string = 'SetGridUserLayoutData';
    static readonly getUserLayoutMetaDataRequestUri: string = 'GetUserLayoutMetaData';
    static readonly createDraftLayoutRequestUri: string = 'CreateDraftLayout';
    static readonly clearUserLayoutMetaDataRequestUri: string = 'ClearUserLayoutMetaData';
    static readonly findValuesRequestUri: string = 'FindValues';

    getMetaDataAsync(
        includeDescriptions: boolean = false,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,
            expirationTime: TIMEOUT_CACHE,
            force: false,
            enableTimeout: true,
            tenantBarrier: true,
            enterpriseBarrier: false,
            userBarrier: false
        },
        retryRequest = 0
    ): Observable<MetaDataResponse> {

    // Default for getMetaDataAsync
    cacheOptions = {
        bypass: false,                         
        expirationTime: undefined,                  
        force: false,
        enterpriseBarrier: false,
        tenantBarrier: true,
        userBarrier: true,
        ...cacheOptions
    };

        const request: MetaDataRequest = new MetaDataRequest();
        request.includeDescriptions = includeDescriptions;
        request.includeSecurityMetaData = true;
        return this.agent.invokePostWithResponse<MetaDataRequest, MetaDataResponse>(
            ActionHttpCommunication.getMetaDataRequestUri, 
            request,
            MetaDataResponse,
            false,
            null,
            null,
            null,
            cacheOptions,
            retryRequest
        ).pipe(map(response => {
                    if (response.result) {
                        this.recursiveUpdateUseMessageResourceKeyToAggregateMetaData(response.result, includeDescriptions);
                    }
                    return response
                }));
    }

    getAvailableLayoutsAsync(
        layoutDefinitionIdentity?: LayoutDefinitionIdentity,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
        retryRequest = 0
    ): Observable<GenericServiceResponse<AvailableLayoutsInfoDto>> {
        
        // Default for getAvailableLayoutsAsync
        cacheOptions = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,
            enterpriseBarrier: false,
            tenantBarrier: true,
            userBarrier: true,
            ...cacheOptions
        };
        
        const response: GenericServiceResponse<AvailableLayoutsInfoDto> = new GenericServiceResponse<AvailableLayoutsInfoDto>(AvailableLayoutsInfoDto);
        const request: GenericServiceRequest<GetAvailableLayoutsDataDto> = new GenericServiceRequest<GetAvailableLayoutsDataDto>();
        request.requestData = new GetAvailableLayoutsDataDto();
        request.requestData.requestedMetaDataLayoutIdentity = layoutDefinitionIdentity;
        return this.agent.invokePostWithResponseWithInstance<GenericServiceRequest<GetAvailableLayoutsDataDto>, GenericServiceResponse<AvailableLayoutsInfoDto>>(
            ActionHttpCommunication.getAvailableLayoutsRequestUri, 
            request, 
            response,
            false,
            null,
            null,
            null,
            cacheOptions,
            retryRequest
        );
    }

    setPanelUserLayoutDataAsync(
        dto: PanelUserLayoutDataDto,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
        retryRequest = 0
    ): Observable<ServiceResponse> {
        const request: GenericServiceRequest<PanelUserLayoutDataDto> = new GenericServiceRequest<PanelUserLayoutDataDto>();
        request.requestData = dto;
        return this.agent.invokePostWithResponse<GenericServiceRequest<PanelUserLayoutDataDto>, ServiceResponse>(
            ActionHttpCommunication.setPanelUserLayoutDataRequestUri, 
            request, 
            ServiceResponse,
            false,
            null,
            null,
            null,
            cacheOptions,
            retryRequest
        );
    }

    setGridUserLayoutDataAsync(
        dto: GridUserLayoutDataDto,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
        retryRequest = 0
    ): Observable<ServiceResponse> {
        const request: GenericServiceRequest<GridUserLayoutDataDto> = new GenericServiceRequest<GridUserLayoutDataDto>();
        request.requestData = dto;
        return this.agent.invokePostWithResponse<GenericServiceRequest<GridUserLayoutDataDto>, ServiceResponse>(
            ActionHttpCommunication.setGridUserLayoutDataRequestUri, 
            request, 
            ServiceResponse,
            false,
            null,
            null,
            null,
            cacheOptions,
            retryRequest
        );
    }

    getUserLayoutMetaDataAsync(
        dto: BaseLayoutDataDto,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
        retryRequest = 0
    ): Observable<GenericServiceResponse<UserLayoutMetaData>> {
        
        // Default for getUserLayoutMetaDataAsync
        cacheOptions = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,
            enterpriseBarrier: false,
            tenantBarrier: true,
            userBarrier: true,
            ...cacheOptions
        };
        
        const request: GenericServiceRequest<BaseLayoutDataDto> = new GenericServiceRequest<BaseLayoutDataDto>();
        request.requestData = dto;
        const response: GenericServiceResponse<UserLayoutMetaData> = new GenericServiceResponse<UserLayoutMetaData>(UserLayoutMetaData);
        return this.agent.invokePostWithResponseWithInstance<GenericServiceRequest<BaseLayoutDataDto>, GenericServiceResponse<UserLayoutMetaData>>(
            ActionHttpCommunication.getUserLayoutMetaDataRequestUri, 
            request, 
            response,
            false,
            null,
            null,
            null,
            cacheOptions,
            retryRequest
        );
    }

    createDraftLayout(
        layoutMetaData: LayoutMetaData,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
        retryRequest = 0
    ): Observable<GenericServiceResponse<DraftLayoutUIInfoDto>> {
        const request: GenericServiceRequest<CreateDraftLayoutDataDto> = new GenericServiceRequest<CreateDraftLayoutDataDto>();
        const dto = new CreateDraftLayoutDataDto();
        dto.metaData = layoutMetaData;
        request.requestData = dto;
        const response: GenericServiceResponse<DraftLayoutUIInfoDto> = new GenericServiceResponse<DraftLayoutUIInfoDto>(DraftLayoutUIInfoDto);
        return this.agent.invokePostWithResponseWithInstance<GenericServiceRequest<CreateDraftLayoutDataDto>, GenericServiceResponse<DraftLayoutUIInfoDto>>(
            ActionHttpCommunication.createDraftLayoutRequestUri, 
            request, 
            response,
            false,
            null,
            null,
            null,
            cacheOptions,
            retryRequest
        );
    }

    clearUserLayoutMetaDataAsync(
        dto: BaseLayoutDataDto,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
        retryRequest = 0
    ): Observable<GenericServiceResponse<Boolean>> {
        const request: GenericServiceRequest<BaseLayoutDataDto> = new GenericServiceRequest<BaseLayoutDataDto>();
        request.requestData = dto;
        const response: GenericServiceResponse<Boolean> = new GenericServiceResponse<Boolean>(Boolean);
        return this.agent.invokePostWithResponseWithInstance<GenericServiceRequest<BaseLayoutDataDto>, GenericServiceResponse<Boolean>>(
            ActionHttpCommunication.clearUserLayoutMetaDataRequestUri, 
            request, 
            response,
            false,
            null,
            null,
            null,
            cacheOptions,
            retryRequest
        );
    }

    getPresentationMetaDataAsync(
        identity: LayoutDefinitionIdentity,
        cacheOptions: CacheOptionsInterface = null,
        retryRequest = 0
    ): Observable<MetaDataResponse> {

        // Default for getUserProfile
        cacheOptions = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,
            enterpriseBarrier: false,
            tenantBarrier: true,
            userBarrier: true,
            ...cacheOptions
        };

        const request: GenericServiceRequest<LayoutDefinitionIdentity> = new GenericServiceRequest<LayoutDefinitionIdentity>();
        request.requestData = identity;
        return this.agent.invokePostWithResponse<
            GenericServiceRequest<LayoutDefinitionIdentity>, MetaDataResponse>(
                ActionHttpCommunication.getPresentationMetaDataRequestUri, 
                request, 
                MetaDataResponse,
                false,
                null,
                null,
                null,
                cacheOptions,
                retryRequest
            ).pipe(map(response => {
                if (response.result) {
                    this.recursiveUpdateUseMessageResourceKeyToAggregateMetaData(response.result, true);
                }
                return response
            }));
    }

    getUserProfile(
        cacheOptions: CacheOptionsInterface = null,
        retryRequest = 0,
        customHeaders: HttpHeaders = null
    ): Observable<GenericServiceResponse<UserProfile>> {

        // Default for getUserProfile
        cacheOptions = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,
            enterpriseBarrier: false,
            tenantBarrier: false,
            userBarrier: true,
            ...cacheOptions
        };

        const request = new GenericServiceRequest();
        const response: GenericServiceResponse<UserProfile> = new GenericServiceResponse<UserProfile>(UserProfile);
        return this.agent.invokePostWithOutBodyWithInstance
            <GenericServiceResponse<UserProfile>>(
                'GetUserProfile',
                response,
                true,
                customHeaders,
                null,
                null,
                cacheOptions,
                retryRequest
            );
    }

    getCookiePreferences(
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,
            enableTimeout: true,
            tenantBarrier: false,
            enterpriseBarrier: false,
            userBarrier: true                                      
        },
        retryRequest: number = 0,
        customHeaders: HttpHeaders = null
    ): Observable<CookiePreferencesResponse> {

        // Default for getCookiePreferences
        cacheOptions = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,
            enterpriseBarrier: false,
            tenantBarrier: false,
            userBarrier: true,
            ...cacheOptions
        };

        return this.agent.invokeGetWithOutQuery<CookiePreferencesResponse>(
            'GetCookiePreferences', 
            CookiePreferencesResponse, 
            true,
            customHeaders,
            null,
            null,
            cacheOptions,
            retryRequest
        );
    }

    getEnterprisesList(
        cacheOptions: CacheOptionsInterface = null,
        retryRequest: number = 0,
        customHeaders: HttpHeaders = null
    ): Observable<EnterpriseListResponse> {

        // Default for getEnterprisesList
        cacheOptions = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,
            enterpriseBarrier: false,
            tenantBarrier: true,
            ...cacheOptions
        };

        const response: EnterpriseListResponse = new EnterpriseListResponse();
        return this.agent.invokePostWithOutBodyWithInstance
            <EnterpriseListResponse>(
                'GetEnterprisesList', 
                response, 
                true,
                customHeaders,
                null,
                null,
                cacheOptions,
                retryRequest
            );
    }

    getTenantProfile(
        cacheOptions: CacheOptionsInterface = null,
        retryRequest: number = 0
    ): Observable<GenericServiceResponse<TenantProfile>> {

        // Default for getVersionData
        cacheOptions = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,
            enterpriseBarrier: false,
            tenantBarrier: true,
            ...cacheOptions
        };

        const response: GenericServiceResponse<TenantProfile> = new GenericServiceResponse<TenantProfile>(TenantProfile);
        return this.agent.invokePostWithOutBodyWithInstance
            <GenericServiceResponse<TenantProfile>>(
                'GetTenantProfile', 
                response, 
                true,
                null,
                null,
                null,
                cacheOptions,
                retryRequest
            );
    }

    getVersionData(
        cacheOptions: CacheOptionsInterface = null,
        retryRequest = 0
    ): Observable<GenericServiceResponse<VersionDataDto>> {

        // Default for getVersionData
        cacheOptions = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,
            ...cacheOptions
        };

        const response: GenericServiceResponse<VersionDataDto> = new GenericServiceResponse<VersionDataDto>(VersionDataDto);
        return this.agent.invokeGetWithOutQueryWithInstance
            <GenericServiceResponse<VersionDataDto>>(
                'GetVersionData', 
                response,
                false,
                null,
                null,
                null,
                cacheOptions,
                retryRequest
            ).pipe(map((res) => {
                res.result.frameworkJsVersion = VersionManager.Current.version.FrameworkJsVersion;
                res.result.frameworkJsInformativeVersion = VersionManager.Current.version.FrameworkJsInformativeVersion;
                res.result.jsVersion = VersionManager.Current.version.JsVersion;
                res.result.jsInformativeVersion = VersionManager.Current.version.JsInformativeVersion;
                return res;
            }));
    }

    getAvailableTenants(
        cacheOptions: CacheOptionsInterface = null,
        retryRequest: number = 0
    ): Observable<GenericArrayServiceResponse<TenantProfile>> {

        // Default for getAvailableTenants
        cacheOptions = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,
            ...cacheOptions
        };

        const response: GenericArrayServiceResponse<TenantProfile> = new GenericArrayServiceResponse<TenantProfile>(TenantProfile);
        return this.agent.invokePostWithOutBodyWithInstance<GenericArrayServiceResponse<TenantProfile>>(
            'GetAvailableTenants', 
            response, 
            true,
            null,
            null,
            null,
            cacheOptions,
            retryRequest
        );
    }

    getAvailableReports(
        cacheOptions: CacheOptionsInterface = null,
        retryRequest: number = 0
    ): Observable<GenericServiceResponse<MultiReportInfoDto>> {

        // Default for getAvailableReports
        cacheOptions = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,
            ...cacheOptions
        };

        const response: GenericServiceResponse<MultiReportInfoDto> = new GenericServiceResponse<MultiReportInfoDto>(MultiReportInfoDto);
        return this.agent.invokeGetWithOutQueryWithInstance<GenericServiceResponse<MultiReportInfoDto>>(
            'GetAvailableReports', 
            response,
            false,
            null,
            null,
            null,
            cacheOptions,
            retryRequest
        );
    }

    getOperationState(
        customControllerAddress: string, 
        operationIdentity: OperationIdentity,
        cacheOptions: CacheOptionsInterface = null,
        retryRequest = 0
    ): Observable<GenericServiceResponse<OperationStateResultDto>> {

        // Default for getOperationState
        cacheOptions = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,
            ...cacheOptions
        };

        const request: GenericServiceRequest<OperationIdentity> = new GenericServiceRequest<OperationIdentity>();
        request.requestData = operationIdentity;
        const response: GenericServiceResponse<OperationStateResultDto> = new GenericServiceResponse<OperationStateResultDto>(OperationStateResultDto);
        return this.agent.invokePostWithResponseWithInstance<GenericServiceRequest<OperationIdentity>, GenericServiceResponse<OperationStateResultDto>>(
            'GetOperationState', 
            request, 
            response, 
            false, 
            null, 
            null, 
            customControllerAddress,
            cacheOptions,
            retryRequest
        );
    }

    downloadPrintOutFile(
        customControllerAddress: string, 
        attachmentIdentity: AttachmentIdentity,
    ): Observable<Blob> {
        const request: GenericServiceRequest<AttachmentIdentity> = new GenericServiceRequest<AttachmentIdentity>();
        request.requestData = attachmentIdentity;
        return this.agent.invokePostDownloadFile<GenericServiceRequest<AttachmentIdentity>, Blob>(
            'DownloadPrintOutFile', request, false, null, null, customControllerAddress);
    }

    printOutFileDemand<TParams, TRequestDto extends PrintOutDemandDto<TParams>>(
        customControllerAddress: string, 
        dto: TRequestDto,
    ): Observable<GenericServiceResponse<OperationIdentity>> {
        const request = new GenericServiceRequest<TRequestDto>();
        request.requestData = dto;
        const response: GenericServiceResponse<OperationIdentity> = new GenericServiceResponse<OperationIdentity>(OperationIdentity);
        return this.agent.invokePostWithResponseWithInstance<GenericServiceRequest<TRequestDto>, GenericServiceResponse<OperationIdentity>>(
            'PrintOutFileDemand', request, response, false, null, null, customControllerAddress);
    }

    refreshToken(
        refreshToken: string,
        retryRequest = 0
    ): Observable<GenericServiceResponse<AuthTokenDto>> {
        const response: GenericServiceResponse<AuthTokenDto> = new GenericServiceResponse<AuthTokenDto>(AuthTokenDto);
        const headers = new HttpHeaders().append('Authorization', 'Bearer ' + refreshToken);
        return this.agent.invokePostWithOutBodyWithInstance
            <GenericServiceResponse<AuthTokenDto>>('RefreshToken', response, true, headers, undefined, undefined, undefined, retryRequest);
    }

    /**
     * Verifica se l'utente corrente ha accesso ad uno specifico servizio
     * 
     * @param objectName 
     * @param cacheOptions 
     * @returns Response con Result a true se ha l'accesso altrimenti false
     */
    userCanAccessToService(
        objectName: string,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
        retryRequest = 0
    ): Observable<UserCanAccessToServiceResponse> {

        // Default for userCanAccessToService
        cacheOptions = {
            userBarrier: true,
            tenantBarrier: true,
            enterpriseBarrier: false,
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,
            ...cacheOptions
        };

        const request = new GenericServiceRequest<ServiceDataObjectDto>();
        request.requestData = new ServiceDataObjectDto();
        request.requestData.objectName = objectName;
        const response: UserCanAccessToServiceResponse = new UserCanAccessToServiceResponse();
        return this.agent.invokePostWithResponseWithInstance<GenericServiceRequest<ServiceDataObjectDto>, UserCanAccessToServiceResponse>(
            'UserCanAccessToService', 
            request, 
            response, 
            true,
            null,
            null,
            null,
            cacheOptions,
            retryRequest
        );
    }

    /**
     * Verifica se l'utente corrente ha accesso ad una serie di servizi
     * 
     * @param objectNames 
     * @returns Lista di servizi con indicazione se l'utente ha accesso o no
     */
    userCanAccessToServices(
        objectNames: string[],
        cacheOptions: CacheOptionsInterface = null,
        retryRequest = 0,
    ): Observable<GenericArrayServiceResponse<ServiceDataAccessDto>> {

        // Default for userCanAccessToServices
        cacheOptions = {
            userBarrier: true,
            tenantBarrier: true,
            enterpriseBarrier: false,
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,
            ...cacheOptions
        };

        const request = new GenericArrayServiceRequest<ServiceDataObjectDto>();
        request.requestData = objectNames.map((objectName) => {
            const dto = new ServiceDataObjectDto();
            dto.objectName = objectName;
            return dto
        } )
        const response: GenericArrayServiceResponse<ServiceDataAccessDto> = new GenericArrayServiceResponse<ServiceDataAccessDto>(ServiceDataAccessDto);
        return this.agent.invokePostWithResponseWithInstance<
            GenericArrayServiceRequest<ServiceDataObjectDto>, 
            GenericArrayServiceResponse<ServiceDataAccessDto>>(
                'UserCanAccessToServices', 
                request, 
                response, 
                true,
                null,
                null,
                null,
                cacheOptions,
                retryRequest
            );
    }

    autoComplete(
        autoCompleteOptions: AutoCompleteExternalOptions,
        cacheOptions: CacheOptionsInterface = null,
        retryRequest = 0
    ): Observable<FindValuesResponse> {

        // Default for autoComplete
        cacheOptions = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,
            ...cacheOptions
        };

        const request = new AutoCompleteRequest();
        request.requestData = autoCompleteOptions;
        const response: FindValuesResponse = new FindValuesResponse(FindValuesResponse);
        return this.agent.invokePostWithResponseWithInstance
            <AutoCompleteRequest, FindValuesResponse>(
                'AutoComplete', 
                request, 
                response, 
                true,
                null,
                null,
                null,
                cacheOptions,
                retryRequest
            );
    }

    findValues(
        entityToLookUp: string = null, 
        findOptions: FindValuesOptions,
        entityToLookUpFullName: string, 
        rootModelName: string = null,
        cacheOptions: CacheOptionsInterface = null,
        retryRequest = 0,
        customHeaders: HttpHeaders = null
    ): Observable<FindValuesResponse> {

        // Default for findValues
        cacheOptions = {
            bypass: false,                         
            expirationTime: undefined,   
            enterpriseBarrier: true,
            tenantBarrier: true,               
            userBarrier: true,               
            force: false,
            ...cacheOptions
        };

        const request = new FindValuesExternalRequest();
        const requestData = new FindValuesExternalOptions(findOptions);
        requestData.fullRootModelName = entityToLookUpFullName;
        request.requestData = requestData;
        return this.agent.invokePostWithResponse<
        FindValuesExternalRequest, FindValuesResponse>(
            ActionHttpCommunication.findValuesRequestUri, 
            request, 
            FindValuesResponse, 
            true,
            customHeaders,
            null, 
            null,
            cacheOptions,
            retryRequest
        );
    }

    // Fino  a quando il server non ci restituisce l'oggetto corretto sono costretto a gestirlo qui
    private recursiveUpdateUseMessageResourceKeyToAggregateMetaData(aggregateMetaData: AggregateMetaData, useMessageResourceKey: boolean, processedAggregateMetaData = []) {
        processedAggregateMetaData.push(aggregateMetaData.rootFullName);
        aggregateMetaData.useMessageResourceKey = useMessageResourceKey;
        for (const dm of [...aggregateMetaData.domainModels, aggregateMetaData.rootMetaData]) {
            for (const external of dm.externals) {
                if (processedAggregateMetaData.indexOf(external.dependentAggregateMetaData.rootFullName) === -1) {
                    this.recursiveUpdateUseMessageResourceKeyToAggregateMetaData(external.dependentAggregateMetaData, useMessageResourceKey, processedAggregateMetaData);
                }
            }
        }
    }
}

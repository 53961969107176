import { BaseIdentity } from '../domain-models/base-identity';
import { CoreOrchestratorViewModelInterface } from './core-orchestrator-view-model.interface';
import { RootViewModel } from './root-view-model';
import { MasterViewModel } from './master-view-model';
import { ModelInterface } from '../domain-models/model.interface';
import { ExternalViewModelInterface } from './external-view-model.interface';
import { InternalViewModelInterface } from './internal-view-model.interface';
import { AggregateMetaData } from '../meta-data/aggregate-meta-data';
import { InternalRelationMetaData } from '../meta-data/internal-relation-meta-data';
import { CollectionViewModelInterface } from './collection-view-model.interface';
import { ItemViewModel } from './item-view-model';
import { ExternalMetaData, InternalCollectionMetaData } from '../meta-data/domain-model-meta-data';
import { DomainModelCollectionInterface } from '../domain-models/domain-model-collection.interface';
import { MasterDetailOrchestratorViewModelInterface } from './master-detail-orchestrator-view-model.interface';
import { CoreModel } from '../domain-models/core-model';
import { CollectionViewModel } from './collection-view-model';
import { CollectionChangedEventArgs, CollectionChangedAction } from './collection-changed-event-args';
import { MasterDetailRootViewModel } from './master-detail-root-view-model';
import { SnapShotListViewModel } from './snap-shot-list-view-model';
import { OrchestratorViewModelInterface } from './orchestrator-view-model.interface';
import { OCCAuditDeactivableModel } from '../domain-models/occ-audit-deactivable-model';
import { ClassType } from '../serialization/class-transformer/ClassTransformer';

export class ViewModelFactory {

    static async createItemViewModel<
        TItemViewModel extends ItemViewModel<TModel, TIdentity>,
        TModel extends CoreModel<TIdentity>,
        TIdentity extends BaseIdentity>(
            itemViewModelType: any,
            domainModel: TModel,
            aggregateMetadata: AggregateMetaData,
            orchestratorViewModel: CoreOrchestratorViewModelInterface,
            ownerCollectionVM: CollectionViewModelInterface<ItemViewModel<TModel, TIdentity>>,
            isNewItem: boolean,
            path: string,
            isMockedViewModel: boolean,
            skipPostInit: boolean = false,
            itemModelType: ClassType<TModel> = null
        ): Promise<TItemViewModel> {

        const rvm = new itemViewModelType() as TItemViewModel;

        await rvm.preInit();
        await rvm.initItemViewModel(domainModel, aggregateMetadata, orchestratorViewModel, ownerCollectionVM, isNewItem, path, isMockedViewModel, itemModelType);
        if (!skipPostInit) {
            await rvm.postInit();
        }

        if (ownerCollectionVM) {
            rvm.viewModelChanged.subscribe(() => {
                ownerCollectionVM.collectionChanged.emit(new CollectionChangedEventArgs(CollectionChangedAction.edit, [rvm]));
            });
        }

        return rvm;
    }
    static async createCollectionViewModel<TCollectionViewModelType extends CollectionViewModelInterface<any>>(
        collectionViewModelType: any,
        collection: DomainModelCollectionInterface,
        aggregateMetaData: AggregateMetaData,
        orchestratorViewModel: CoreOrchestratorViewModelInterface,
        internalCollectionMetaData: InternalCollectionMetaData,
        path: string,
        isMockedViewModel = false,
        itemModelType: ClassType<ModelInterface> = null
    ): Promise<TCollectionViewModelType> {

        const ivm: TCollectionViewModelType = new collectionViewModelType();

        if (!(ivm instanceof CollectionViewModel)) {
            throw new Error(
                `${ivm.constructor.name} must be an instance of CollectionViewModel, check constructor implementation of ${collectionViewModelType}`);
        }
        await ivm.preInit();
        await ivm.init(
            collection,
            aggregateMetaData,
            orchestratorViewModel,
            internalCollectionMetaData,
            path,
            isMockedViewModel,
            itemModelType
        );
        await ivm.postInit();
        return ivm;
    }

    static async createInternalViewModel(
        internalViewModelType: any,
        internalModel: ModelInterface,
        aggregateMetadata: AggregateMetaData,
        orchestratorViewModel: CoreOrchestratorViewModelInterface,
        relationMetadata: InternalRelationMetaData,
        skipPostInit = false,
        path: string = '',
        isMockedViewModel = false,
        parent = null,
        internalModelType : ClassType<ModelInterface> = null
    ): Promise<InternalViewModelInterface> {

        const ivm: InternalViewModelInterface = new internalViewModelType();
        await ivm.preInit();
        await ivm.initInternalViewModel(
            internalModel,
            aggregateMetadata,
            orchestratorViewModel,
            relationMetadata,
            path,
            isMockedViewModel,
            parent,
            internalModelType
        );
        if (!skipPostInit) {
            await ivm.postInit();
        }
        return ivm;
    }

    static async createExternalViewModel(
        externalViewModelType: any,
        externalDomainModel: ModelInterface,
        aggregateMetadata: AggregateMetaData,
        orchestratorViewModel: CoreOrchestratorViewModelInterface,
        externalMetaData: ExternalMetaData,
        path: string,
        isMockedViewModel = false,
        externalDomainModelType: ClassType<ModelInterface> = null,
        overrideDecoratorData: ExternalMetaData = null,
        processedNulledModel: string[] = []
    ): Promise<ExternalViewModelInterface> {

        // Eredito useMessageResourceKey dall'aggregato padre
        externalMetaData.dependentAggregateMetaData.useMessageResourceKey = aggregateMetadata.useMessageResourceKey;

        const ivm: ExternalViewModelInterface = new externalViewModelType();
        await ivm.preInit();
        await ivm.initExternalViewModel(
            externalDomainModel,
            aggregateMetadata,
            orchestratorViewModel,
            externalMetaData,
            path,
            isMockedViewModel,
            externalDomainModelType,
            overrideDecoratorData,
            processedNulledModel
        );
        await ivm.postInit();
        return ivm;
    }

    static async createMasterViewModel<
        TRootViewModel extends MasterDetailRootViewModel<TModel, TIdentity>,
        TViewModel extends MasterViewModel<TRootViewModel, TModel, TIdentity>,
        TModel extends CoreModel<TIdentity>,
        TIdentity extends BaseIdentity>(
            masterViewModelType: any,
            domainModel: TModel,
            aggregateMetadata: AggregateMetaData,
            orchestratorViewModel: MasterDetailOrchestratorViewModelInterface,
            rootDomainModelType: any,
            skipPostInit = false
        ): Promise<TViewModel> {
        const mvm: TViewModel = new masterViewModelType() as TViewModel;
        await mvm.preInit();
        await mvm.initMasterViewModel(domainModel, aggregateMetadata, orchestratorViewModel, rootDomainModelType);
        if (!skipPostInit) {
            await mvm.postInit();
        }
        return mvm;
    }

    static async createSnapShotListViewModel<
        TViewModel extends SnapShotListViewModel<TModel, TIdentity>,
        TModel extends OCCAuditDeactivableModel<TIdentity>,
        TIdentity extends BaseIdentity>(
            listViewModelType: any,
            domainModel: TModel,
            aggregateMetadata: AggregateMetaData,
            orchestratorViewModel: OrchestratorViewModelInterface,
            rootDomainModelType: any,
            skipPostInit = false
        ): Promise<TViewModel> {
        const mvm: TViewModel = new listViewModelType() as TViewModel;
        await mvm.preInit();
        await mvm.init(
            domainModel,
            aggregateMetadata,
            orchestratorViewModel,
            rootDomainModelType
        );
        if (!skipPostInit) {
            await mvm.postInit();
        }
        return mvm;
    }

    static async createRootViewModel<
        TViewModel extends RootViewModel<TModel, TIdentity>,
        TModel extends CoreModel<TIdentity>,
        TIdentity extends BaseIdentity>(
            rootViewModelType: any,
            domainModel: TModel,
            aggregateMetadata: AggregateMetaData,
            orchestratorViewModel: CoreOrchestratorViewModelInterface,
            skipPostInit = false,
            rootDomainModelType = null
        ): Promise<RootViewModel<TModel, TIdentity>> {

        const rvm: TViewModel = new rootViewModelType() as TViewModel;
        await rvm.preInit();
        await rvm.initRootViewModel(domainModel, aggregateMetadata, orchestratorViewModel, undefined, undefined, undefined, undefined, rootDomainModelType);
        if (!skipPostInit) {
            await rvm.postInit();
        }
        return rvm;

    }
}

import { Component, ChangeDetectorRef, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { BaseCellRendererComponent } from './base_cell_renderer.component';
import { GridComponent } from '../grid.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopperHelper } from '@nts/std/src/lib/utility';
import { NgxPopperjsDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';

// create your cellEditor as a Angular component
@UntilDestroy()
@Component({
    selector: 'nts-default-text-box-renderer-cell',
    // E' necessario disabilitare ChangeDetectionStrategy.OnPush altrimenti se la griglia parte nascosta e poi si visualizza
    // le righe seppur popolate rimangono vuote
    // changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgxPopperjsModule,
        NgFor,
        NgIf,
        AsyncPipe
    ],
    template: `
         <span>
            <div *ngIf="!params?.data" class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </span>
        <div  *ngIf="params?.data" class="read-template">
            <div #popperError="popper"
                [popper]="tooltipErrTemplate"
                popperAppendTo="body"
                popperApplyClass="error"
                [popperPreventOverflow]="false"
                [popperHideOnScroll]="true"
                [popperDisabled]="!propertyViewModel?.hasErrors"
                [popperTrigger]="ngxPopperjsTriggers.hover"
                [popperPlacement]="ngxPopperjsPlacements.TOP"
                [style.height.px]="cellHeight" class="renderer-cell">
                <div>{{ propertyViewModel?.value }}</div>
            </div>

            <popper-content #tooltipErrTemplate>
                <div *ngFor="let item of propertyViewModel?.errors$ | async">
                    {{ item }}
                </div>
            </popper-content>
        </div>`
})
export class DefaultCellRendererComponent extends BaseCellRendererComponent {

    @ViewChild('popperError', { static: false }) popperError: NgxPopperjsDirective;

    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;

    get gridContext(): GridComponent {
        return this.params.context;
    }

    constructor(
        public readonly el: ElementRef,
        private readonly cd: ChangeDetectorRef,
        private readonly renderer: Renderer2) {
        super();
    }

    override agInit(params: any): void {
        super.agInit(params);
        if (this.params?.data) {

            this.propertyViewModel.propertyChanged.pipe(untilDestroyed(this)).subscribe(() => {
                this.cd.detectChanges();
            });

            this.propertyViewModel.onFocusRequested.pipe(
                untilDestroyed(this),
            ).subscribe(() => {
                this.el.nativeElement.parentElement.parentElement.scrollIntoView();
                if (this.propertyViewModel?.hasErrors) {
                    PopperHelper.show(this.popperError);
                }
            });
        }
    }
}

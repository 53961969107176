import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { CoreOrchestratorViewModelInterface } from '../../../view-models/core-orchestrator-view-model.interface';
import { takeUntil } from 'rxjs/operators';
import { merge } from 'rxjs';
import { Subject } from 'rxjs';
import { MessageContainer } from '../../../view-models/message-container';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { RouterLink } from '@angular/router';
import { NTSTranslatePipe } from '../../pipe/nts-translation-pipe';
import { TextButtonComponent } from '../../shared/buttons/text-button/text-button.component';

@Component({
  selector: 'nts-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SvgIconComponent,
    NgIf,
    RouterLink,
    NgSwitch,
    NTSTranslatePipe,
    NgSwitchCase,
    NgSwitchDefault,
    NgFor,
    TextButtonComponent
  ]
})
export class ValidationComponent implements OnInit, OnDestroy {

  @Input() orchestratorViewModel!: CoreOrchestratorViewModelInterface;

  defaultItemToShow = 1;
  itemToShow = this.defaultItemToShow - 1;

  isCollapsed = true;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly cd: ChangeDetectorRef) {
  }

  ngOnInit() {

    merge(
      this.orchestratorViewModel.eventDispatcher.onAddMessageInViewModel,
      this.orchestratorViewModel.eventDispatcher.onClearMessagesInViewModel,
      this.orchestratorViewModel.eventDispatcher.onRefreshMessageInViewModel,
      this.orchestratorViewModel.eventDispatcher.onRemovedMessageInViewModel,
      this.orchestratorViewModel.eventDispatcher.onClearAllMessages
    ).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  showMore(){
    this.itemToShow = this.orchestratorViewModel?.allViewModelErrorsBuildedList?.length 
  }

  showLess(){
    this.itemToShow = this.defaultItemToShow - 1
  }

  focusMessage(event: any, message: MessageContainer) {
    event.preventDefault();
    this.showLess();
    this.orchestratorViewModel.focusMessageInViewModel(message);
  }
}

<div class="content">
    <svg-icon key="error-404"></svg-icon>
    <div class="error-title">{{errorTitle}}</div>
    <div class="error-description">{{errorDescription}}</div>
    <div class="more-info">
        <h3>{{'std_PageError_DetailTitle' | NTSTranslate}}:&nbsp;</h3>
        <p>{{errorDetailDescription}}<span *ngIf="showDetailMore && !expandDetailMore">...</span><span
                *ngIf="expandDetailMore">{{errorDetailMoreDescription}}</span></p>
        <nts-text-button *ngIf="showDetailMore" [icon]="expandDetailMore ? 'eye-close' : 'eye-alt'"
            (onClicked)="toggleShowMore()"></nts-text-button>
    </div>
    <nts-filled-button [type]="1" [title]="'std_GoBack_Dashboard' | NTSTranslate"
        [label]="'std_GoBack_Dashboard' | NTSTranslate" (onClicked)="redirectToDashBoard()">
    </nts-filled-button>
</div>

import { MessageType } from './message-type';
import { Message } from './message';
import { PostMessageBridgeFactory } from './post-message-bridge-factory';
import { EventEmitter, NgZone } from '@angular/core';
import { PostMessageBridgeInterface } from './post-message-bridge.interface';

export const DEFAULT_BRIDGE_NAME = 'tab-messages';
export class TabSocket {

    onClose: EventEmitter<any> = new EventEmitter();

    private bridge: PostMessageBridgeInterface | null;
    private pollingInterval: null | any = null;

    constructor(
        private zone: NgZone,
        private targetWindow: Window, 
        private name = DEFAULT_BRIDGE_NAME        
    ) {
        this.bridge = new PostMessageBridgeFactory(zone).makeInstance();
        this.bridge.setEnableLogging(false);
        const currentWindow: Window = window;

        this.bridge
            .connect(currentWindow, targetWindow)
            .makeBridge(name);

        if (targetWindow?.opener === window || targetWindow?.parent === window) {
            // On close event, post a message to peer
            window.addEventListener('unload', this.closeChild);
            this.pollingInterval = setInterval(() => {
                if (this.targetWindow.closed) {
                    clearInterval(this.pollingInterval);
                    this.onClose.emit();
                }
            }, 1000);            
        }
    }

    send<TMessageType extends MessageType = MessageType>(
        data: any,
        messageType?: TMessageType, 
        uuid: string = ''
    ) {
        this.bridge?.sendMessage(this.name, new Message(data, messageType, uuid));
    }

    sendWithConfirmAsync<TRequest, TResponse, TMessageType extends MessageType = MessageType>(
        message: TRequest, 
        messageType?: TMessageType, 
        timeout = 1000
    ): Promise<Message<TResponse>> {
        
        const messageUUID: string|undefined = this.bridge?.createUUID();
        this.bridge?.sendMessage(this.name, new Message<TRequest>(message, messageType, messageUUID));
        return new Promise<Message<TResponse>>((resolve, reject) => {
            const t = setTimeout(() => {
                reject('Timeout elapsed');
            }, timeout);
            const confirmListener = (ack: Message<TResponse>) => {
                if (ack.uuid === messageUUID) {
                    this.bridge?.removeListener(this.name, confirmListener);
                    clearTimeout(t);
                    resolve(ack);
                }
            };
            this.bridge?.addListener(this.name, confirmListener);
        });
    }

    closeChild() {
        this.targetWindow?.close();
    }

    listen<T>(cb: (message: Message<T>) => void) {
        this.bridge?.addListener(this.name, (message: Message<T>) => {
            if (message.data != null) {
                cb(message);
            }
        });
    }

    destroy() {
        window.removeEventListener('unload', this.closeChild);
        this.bridge?.removeAllListeners(this.name);
        this.bridge = null;
        clearInterval(this.pollingInterval);
    }
}

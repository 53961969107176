import { BaseIdentity } from '../../domain-models/base-identity';
import { CoreModel } from '../../domain-models/core-model';
import { ClassType } from '../../serialization/class-transformer/ClassTransformer';
import { NTSReflection } from '@nts/std/src/lib/utility';
import { ItemViewModel } from '../item-view-model';

const ITEM_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY = 'itemViewModelTypeDecoratorMetadataKey';

export function ItemViewModelTypeDecorator<TIdentity extends BaseIdentity, TModel extends CoreModel<TIdentity>, TViewModel extends ItemViewModel<TModel, TIdentity>>(itemViewModelType: ClassType<TViewModel>) {
    return (target: any) => {
        if (itemViewModelType instanceof ItemViewModel) {
            throw new Error(itemViewModelType + 'is not a ItemViewModel');
        }
        Reflect.defineMetadata(ITEM_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, itemViewModelType, target);
    };
}

export class ItemViewModelTypeInspector {
    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            ITEM_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): any {
        return NTSReflection.getClassMetadata(
            ITEM_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }
}

import { BaseCommandItem } from './base-command-item';
import { CommandsGroup } from './commands-group';

export class CommandsPage extends BaseCommandItem {
    commandGroups: Array<CommandsGroup> = new Array<CommandsGroup>();

    constructor(name: string, displayName: string, keyTip: string) {
        super();

        this.name = name;
        this.displayName = displayName;
        this.keyTip = keyTip;
    }

    removeEmptyGroups() {
        const cmds = this.commandGroups.filter(g => g.commands.length > 0);
        this.commandGroups = new Array<CommandsGroup>();
        this.commandGroups.push(...cmds);
    }

    addBefore(commandGroupName: string, group: CommandsGroup) {
        this.commandGroups.splice(this.commandGroups.findIndex(commandsGroup => commandsGroup.name === commandGroupName), 0, group);
    }

    addAfter(commandGroupName: string, group: CommandsGroup) {
        this.commandGroups.splice(this.commandGroups.findIndex(commandsGroup => commandsGroup.name === commandGroupName) + 1, 0, group);
    }

    addGroup(group: CommandsGroup, checkIfEmpty = false) {
        const item = this.commandGroups.find(commandsGroup => commandsGroup.name === group.name);
        if (item == null) {
            if (!checkIfEmpty || group.commands.length > 0) {
                this.commandGroups.push(group);
            }
        }
    }
}

import { ZoomPropertyViewModel } from './zoom-property-view-model';
import { MessageContainer } from '../../message-container';
import { BoolMetaData } from '../../../meta-data/bool-meta-data';
import { ModifiedSubscriberInterface } from '../../modified-subscriber.interface';

export class BoolZoomPropertyViewModel extends ZoomPropertyViewModel<boolean>  {
  
  constructor(propertyMetaData: BoolMetaData, modifiedSubscriber: ModifiedSubscriberInterface) {
    super(propertyMetaData, modifiedSubscriber);
    if (this.value == null) {
      this.resetValue();
    }
  }

  override removeError(item: MessageContainer) {
    throw new Error("Method not implemented.");
  }

  override resetValue() {
    this.typedValue = false;
  }

  setValue(propertyValue: Object) {
    if (propertyValue == null) {
      this.resetValue();
    } else {
      // TODO
      // let ic = propertyValue as IConvertible;
      // if (ic == null)
      // {
      //     throw new ArgumentException();
      // }

      // this.typedValue = ic.ToString(CultureInfo.InvariantCulture);
      this.typedValue = <boolean>propertyValue;
    }
  }
}

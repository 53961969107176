import { Expose } from '../serialization/class-transformer/decorators';
import { CompanySingleAggregateIdentity } from './company-single-aggregate-identity';
import { NumberDecorator } from './decorators/number.decorator';
import { OCCAuditDeactivableModel } from './occ-audit-deactivable-model';


export class CompanySingleAggregateAuditDeactivableModel<TCompanySingleAggregateIdentity extends CompanySingleAggregateIdentity>
    extends OCCAuditDeactivableModel<TCompanySingleAggregateIdentity>  {
    private _companySingleAggregateIdentity: number = 0;

    @Expose()
    @NumberDecorator({ displayNameKey: 'CompanySingleAggregateModel_CompanySingleAggregateIdentity' })
    get companySingleAggregateIdentity(): number {
        return this.getValueForIdentityValue<number>(() => this._companySingleAggregateIdentity, 'companySingleAggregateIdentity');
    }
    set companySingleAggregateIdentity(value: number) {
        this.setValueForIdentityValue<number>(() => { this._companySingleAggregateIdentity = value; }, value, 'companySingleAggregateIdentity');
    }
}

import { NTSReflection } from '@nts/std/src/lib/utility';

const MODEL_TYPE_NAME_META_DATA_IDENTIFICATION_KEY = 'modelTypeNameDecoratorMetaDataKey';

export function ModelTypeNameDecorator(modelTypeName: string) {
    return (target: any) => {
        Reflect.defineMetadata(MODEL_TYPE_NAME_META_DATA_IDENTIFICATION_KEY, modelTypeName, target);
    };
}

export class ModelTypeNameInspector {

    static META_DATA_KEY = MODEL_TYPE_NAME_META_DATA_IDENTIFICATION_KEY;
    static DECORATOR_NAME = '@ModelTypeNameDecorator';

    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            MODEL_TYPE_NAME_META_DATA_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): string {
        return NTSReflection.getClassMetadata(
            MODEL_TYPE_NAME_META_DATA_IDENTIFICATION_KEY, subject);
    }
}

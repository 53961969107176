import { DomainModelMetaData, InternalRelationMetaData, InternalCollectionMetaData } from '../../meta-data/domain-model-meta-data';
import { ZoomStarterMode } from '../../domain-models/zoom/zoom-starter-mode';
import { ZoomQueryContext } from './zoom-query-context';
import { AggregateMetaData, PropertyMetaData, StringMetaData, NumericMetaData, EnumMetaData, DateTimeMetaData, ExternalMetaData, MetaDataUtils } from '../../meta-data';
import { ZoomAdvancedOptions } from '../../domain-models/find-options/zoom-advanced-options';
import { ZoomMetaData } from '../../meta-data/zoom-meta-data';
import { FindOptions } from '../../domain-models/find-options/find-options';
import { MessageResourceManager } from '../../resources/message-resource-manager';
import { BoolMetaData } from '../../meta-data/bool-meta-data';
import { GuidMetaData } from '../../meta-data/guid-meta-data';

export class ZoomArgs {

    aggregateMetaData: AggregateMetaData;
    callerDomainModelName: string;
    callerDomainModelFullName: string;
    requestedDomainModelMetadata: DomainModelMetaData;
    domainModelMetaDataList: DomainModelMetaData[];
    zoomMetaDataList: ZoomMetaData[];
    zoomOptions: ZoomAdvancedOptions;
    zoomStarterMode: ZoomStarterMode;
    zoomQueryContext: ZoomQueryContext;

    extDependentAssociationPropertiesDisplayName: Map<string, Map<string, string>>;

    private traceRecursiveExternalWithSameDomainModelMetaData: any;

    constructor(
        requestedDomainModelMetaData: DomainModelMetaData,
        aggregateMetaData: AggregateMetaData,
        callerDomainModelName?: string,
        callerDomainModelFullName?: string,
        zoomOptions?: ZoomAdvancedOptions) {

        if (callerDomainModelName === undefined) {
            callerDomainModelName = requestedDomainModelMetaData.name;
        }

        if (callerDomainModelFullName === undefined) {
            callerDomainModelFullName = requestedDomainModelMetaData.fullName;
        }

        if (zoomOptions === undefined) {
            zoomOptions = new ZoomAdvancedOptions();
        }

        this.callerDomainModelName = callerDomainModelName;
        this.callerDomainModelFullName = callerDomainModelFullName;
        this.requestedDomainModelMetadata = requestedDomainModelMetaData;
        this.domainModelMetaDataList = aggregateMetaData.domainModels;
        this.zoomOptions = zoomOptions;
        this.zoomMetaDataList = this.getZoomSortedMetaData(this.zoomOptions);
        this.aggregateMetaData = aggregateMetaData;
    }

    // gestisco due liste di excluded, una per quelle da escludere sempre e una per quelle provvisorie del ciclo (il codice nelle internal)
    private getZoomMetaDataFromDomainModelMetaData(domainModelMetaData: DomainModelMetaData, defaultExcludedFields: string[], excludedFields: string[] = [], currentLevel = 1, maxRecursiveLevel = 5, parent: ZoomMetaData = null, rootPath: string = null, prefix: string = null): ZoomMetaData[] {

        const result = [];

        const currentExcludedFields = [...defaultExcludedFields, ...excludedFields];

        // Bools
        domainModelMetaData.bools.forEach((boolMetaData: BoolMetaData) => {
            if (currentExcludedFields.indexOf(boolMetaData.name) === -1) {
                const zpm = new ZoomMetaData();
                zpm.propertyMetadata = boolMetaData;
                zpm.booleanMetaData = boolMetaData;
                const currentDisplayName = boolMetaData.descriptions.displayName ??
                    MessageResourceManager.Current.getMessage(boolMetaData.descriptions.displayNameKey);

                zpm.rootPath = rootPath;
                zpm.parent = parent;
                zpm.level = currentLevel;
                zpm.displayName = prefix ?
                    prefix + ' -> ' + currentDisplayName :
                    currentDisplayName;

                if (zpm.level == 1) {
                    zpm.isRootIdentity = domainModelMetaData.identityNames.indexOf(zpm.propertyMetadata.name) > -1;
                    zpm.isIdentity = true;
                } else {
                    zpm.isIdentity = domainModelMetaData.identityNames.indexOf(zpm.propertyMetadata.name) > -1;
                }
                result.push(zpm);
            }
        });

        // Enums
        domainModelMetaData.enums.forEach((enumMetaData: EnumMetaData) => {
            if (currentExcludedFields.indexOf(enumMetaData.name) === -1) {
                const zpm = new ZoomMetaData();
                zpm.propertyMetadata = enumMetaData;
                zpm.enumMetaData = enumMetaData;
                const currentDisplayName = enumMetaData.descriptions.displayName ??
                    MessageResourceManager.Current.getMessage(enumMetaData.descriptions.displayNameKey);

                zpm.rootPath = rootPath;
                zpm.parent = parent;
                zpm.level = currentLevel;
                zpm.displayName = prefix ?
                    prefix + ' -> ' + currentDisplayName :
                    currentDisplayName;

                if (zpm.level == 1) {
                    zpm.isRootIdentity = domainModelMetaData.identityNames.indexOf(zpm.propertyMetadata.name) > -1;
                    zpm.isIdentity = true;
                } else {
                    zpm.isIdentity = domainModelMetaData.identityNames.indexOf(zpm.propertyMetadata.name) > -1;
                }
                result.push(zpm);
            }
        });

        // Strings
        domainModelMetaData.strings.forEach((stringMetaData: StringMetaData) => {
            if (currentExcludedFields.indexOf(stringMetaData.name) === -1 && !MetaDataUtils.checkIfPropertyIsACodeForRelation(domainModelMetaData, stringMetaData.name, true) || domainModelMetaData.identityNames.indexOf(stringMetaData.name) > -1) {
                const zpm = new ZoomMetaData();
                zpm.propertyMetadata = stringMetaData;
                zpm.stringMetaData = stringMetaData;
                const currentDisplayName = stringMetaData.descriptions.displayName ??
                    MessageResourceManager.Current.getMessage(stringMetaData.descriptions.displayNameKey);

                zpm.rootPath = rootPath;
                zpm.parent = parent;
                zpm.level = currentLevel;
                zpm.displayName = prefix ?
                    prefix + ' -> ' + currentDisplayName :
                    currentDisplayName;

                if (zpm.level == 1) {
                    zpm.isRootIdentity = domainModelMetaData.identityNames.indexOf(zpm.propertyMetadata.name) > -1;
                    zpm.isIdentity = true;
                } else {
                    zpm.isIdentity = domainModelMetaData.identityNames.indexOf(zpm.propertyMetadata.name) > -1;
                }
                result.push(zpm);
            }
        });

        // Guid
        domainModelMetaData.guids.forEach((guidMetaData: GuidMetaData) => {
            if (currentExcludedFields.indexOf(guidMetaData.name) === -1 && !MetaDataUtils.checkIfPropertyIsACodeForRelation(domainModelMetaData, guidMetaData.name, true) || domainModelMetaData.identityNames.indexOf(guidMetaData.name) > -1) {
                const zpm = new ZoomMetaData();
                zpm.propertyMetadata = guidMetaData;
                zpm.guidMetaData = guidMetaData;
                const currentDisplayName = guidMetaData.descriptions.displayName ??
                    MessageResourceManager.Current.getMessage(guidMetaData.descriptions.displayNameKey);

                zpm.rootPath = rootPath;
                zpm.parent = parent;
                zpm.level = currentLevel;
                zpm.displayName = prefix ?
                    prefix + ' -> ' + currentDisplayName :
                    currentDisplayName;

                if (zpm.level == 1) {
                    zpm.isRootIdentity = domainModelMetaData.identityNames.indexOf(zpm.propertyMetadata.name) > -1;
                    zpm.isIdentity = true;
                } else {
                    zpm.isIdentity = domainModelMetaData.identityNames.indexOf(zpm.propertyMetadata.name) > -1;
                }
                result.push(zpm);
            }
        });

        // Numerics
        domainModelMetaData.numerics.forEach((numericMetaData: NumericMetaData) => {
            const isACodeForRelation = MetaDataUtils.checkIfPropertyIsACodeForRelation(domainModelMetaData, numericMetaData.name, true);
            if ((currentExcludedFields.indexOf(numericMetaData.name) === -1 && !isACodeForRelation) || domainModelMetaData.identityNames.indexOf(numericMetaData.name) > -1) {
                const zpm = new ZoomMetaData();
                zpm.propertyMetadata = numericMetaData;
                zpm.numericMetaData = numericMetaData;
                const currentDisplayName = numericMetaData.descriptions.displayName ??
                    MessageResourceManager.Current.getMessage(numericMetaData.descriptions.displayNameKey);

                zpm.rootPath = rootPath;
                zpm.parent = parent;
                zpm.level = currentLevel;
                zpm.displayName = prefix ?
                    prefix + ' -> ' + currentDisplayName :
                    currentDisplayName;

                if (zpm.level == 1) {
                    zpm.isRootIdentity = domainModelMetaData.identityNames.indexOf(zpm.propertyMetadata.name) > -1;
                    zpm.isIdentity = true;
                } else {
                    zpm.isIdentity = domainModelMetaData.identityNames.indexOf(zpm.propertyMetadata.name) > -1;
                }
                result.push(zpm);
            }
        });

        // Datetimes
        domainModelMetaData.dateTimes.forEach((dateTimeMetaData: DateTimeMetaData) => {
            if (currentExcludedFields.indexOf(dateTimeMetaData.name) === -1) {
                const zpm = new ZoomMetaData();
                zpm.propertyMetadata = dateTimeMetaData;
                zpm.dateTimeMetaData = dateTimeMetaData;
                const currentDisplayName = dateTimeMetaData.descriptions.displayName ??
                    MessageResourceManager.Current.getMessage(dateTimeMetaData.descriptions.displayNameKey);

                zpm.rootPath = rootPath;
                zpm.parent = parent;
                zpm.level = currentLevel;
                zpm.displayName = prefix ?
                    prefix + ' -> ' + currentDisplayName :
                    currentDisplayName;

                if (zpm.level == 1) {
                    zpm.isRootIdentity = domainModelMetaData.identityNames.indexOf(zpm.propertyMetadata.name) > -1;
                    zpm.isIdentity = true;
                } else {
                    zpm.isIdentity = domainModelMetaData.identityNames.indexOf(zpm.propertyMetadata.name) > -1;
                }
                result.push(zpm);
            }
        });

        // Internals 1-1
        domainModelMetaData.internalRelations.forEach((internalRelation: InternalRelationMetaData) => {
            const currentInternalDisplayName = internalRelation.descriptions.displayName ?? MessageResourceManager.Current.getMessage(internalRelation.descriptions.displayNameKey);
            const innerPrefix = prefix ? prefix + ' -> ' + currentInternalDisplayName : currentInternalDisplayName
            const principalPropertyName = internalRelation.principalPropertyName;
            const innerRootPath = rootPath ? rootPath + '.' + principalPropertyName : principalPropertyName;

            const innerParent = new ZoomMetaData();
            // imposto come property metadata il primo identity
            // innerParent.propertyMetadata = internalRelation.dependentMetaData.getPropertyMetaData(internalRelation.dependentMetaData.identityNames[0]);
            const currentDisplayName = internalRelation.descriptions.displayName ??
                MessageResourceManager.Current.getMessage(internalRelation.descriptions.displayNameKey);

            // if (innerParent.propertyMetadata.getType() === 'String') {
            //     innerParent.stringMetaData = innerParent.propertyMetadata as StringMetaData;
            // } else {
            //     innerParent.numericMetaData = innerParent.propertyMetadata as NumericMetaData;
            // }
            innerParent.rootPath = innerRootPath;
            innerParent.internalRelation = internalRelation;
            innerParent.parent = parent;
            innerParent.level = currentLevel;
            innerParent.displayName = prefix ?
                prefix + ' -> ' + currentDisplayName :
                currentDisplayName;

            result.push(innerParent);
            if (currentLevel < maxRecursiveLevel) {
                result.push(
                    ...this.getZoomMetaDataFromDomainModelMetaData(
                        internalRelation.dependentMetaData,
                        // escludo le identity nelle internal 1-1, non sono necessarie perchè fanno riferimento all'aggregato principale
                        defaultExcludedFields,
                        internalRelation.dependentMetaData.identityNames,
                        currentLevel + 1,
                        maxRecursiveLevel,
                        innerParent,
                        innerRootPath,
                        innerPrefix
                    )
                );
            }
        });

        // Internals Collection
        domainModelMetaData.internalCollections.forEach((internalRelation: InternalCollectionMetaData) => {
            const currentInternalDisplayName = internalRelation.descriptions.displayName ?? MessageResourceManager.Current.getMessage(internalRelation.descriptions.displayNameKey);
            const innerPrefix = prefix ? prefix + ' -> ' + currentInternalDisplayName : currentInternalDisplayName
            const principalPropertyName = internalRelation.principalPropertyName;
            const innerRootPath = rootPath ? rootPath + '.' + principalPropertyName : principalPropertyName;

            const innerParent = new ZoomMetaData();
            // imposto come property metadata il codice
            // innerParent.propertyMetadata = internalRelation.dependentMetaData.getPropertyMetaData(internalRelation.dependentMetaData.identityNames[0]);
            const currentDisplayName = internalRelation.descriptions.displayName ??
                MessageResourceManager.Current.getMessage(internalRelation.descriptions.displayNameKey);

            // if (innerParent.propertyMetadata.getType() === 'String') {
            //     innerParent.stringMetaData = innerParent.propertyMetadata as StringMetaData;
            // } else {
            //     innerParent.numericMetaData = innerParent.propertyMetadata as NumericMetaData;
            // }
            innerParent.rootPath = innerRootPath;
            innerParent.internalCollection = internalRelation;
            innerParent.parent = parent;
            innerParent.level = currentLevel;
            innerParent.displayName = prefix ?
                prefix + ' -> ' + currentDisplayName :
                currentDisplayName;

            result.push(innerParent);
            if (currentLevel < maxRecursiveLevel) {
                result.push(
                    ...this.getZoomMetaDataFromDomainModelMetaData(
                        internalRelation.dependentMetaData,
                        defaultExcludedFields,
                        internalRelation.associationProperties.map((a) => a.dependentPropertyName),
                        currentLevel + 1,
                        maxRecursiveLevel,
                        innerParent,
                        innerRootPath,
                        innerPrefix
                    )
                );
            }
        });

        // External Locali
        domainModelMetaData.externals.forEach((external: ExternalMetaData) => {
            const currentExternalDisplayName = external.descriptions.displayName ?? MessageResourceManager.Current.getMessage(external.descriptions.displayNameKey);
            const innerPrefix = prefix ? prefix + ' -> ' + currentExternalDisplayName : currentExternalDisplayName
            const principalPropertyName = external.principalPropertyName;
            const innerRootPath = rootPath ? rootPath + '.' + principalPropertyName : principalPropertyName;

            const innerParent = new ZoomMetaData();
            // imposto come property metadata il codice
            // innerParent.propertyMetadata = external.dependentAggregateMetaData.rootMetaData.getPropertyMetaData(external.dependentAggregateMetaData.rootMetaData.identityNames[0]);
            const currentDisplayName = external.descriptions.displayName ??
                MessageResourceManager.Current.getMessage(external.descriptions.displayNameKey);

            // if (innerParent.propertyMetadata.getType() === 'String') {
            //     innerParent.stringMetaData = innerParent.propertyMetadata as StringMetaData;
            // } else {
            //     innerParent.numericMetaData = innerParent.propertyMetadata as NumericMetaData;
            // }
            innerParent.rootPath = innerRootPath;
            innerParent.external = external;
            innerParent.parent = parent;
            innerParent.level = currentLevel;
            innerParent.displayName = prefix ?
                prefix + ' -> ' + currentDisplayName :
                currentDisplayName;

            result.push(innerParent);

            // se l'aggregato a cui fa riferimento questo external ha lo stesso metadata del livello corrente
            // ne tengo traccia

            if (external.dependentAggregateMetaData.rootMetaData.fullName === domainModelMetaData.fullName) {
                if (!this.traceRecursiveExternalWithSameDomainModelMetaData[domainModelMetaData.fullName]) {
                    this.traceRecursiveExternalWithSameDomainModelMetaData[domainModelMetaData.fullName] = {
                        [currentLevel]: true
                    };
                } else {
                    this.traceRecursiveExternalWithSameDomainModelMetaData[domainModelMetaData.fullName][currentLevel] = true;
                }
            }

            if (
                (external.dependentAggregateMetaData.rootMetaData.fullName !== domainModelMetaData.fullName) ||

                // se l'aggregato a cui fa riferimento questo external ha lo stesso metadata del livello corrente
                // e si è creata una ricorsione su almeno 2 livelli, non ciclo l'aggregato a cui fa riferimento questo external
                // la ricorsione con domain model meta data uguali viene bloccata solo se è consecutiva e non alternata
                !(
                    currentLevel > 1 &&
                    external.dependentAggregateMetaData.rootMetaData.fullName === domainModelMetaData.fullName &&
                    this.traceRecursiveExternalWithSameDomainModelMetaData[domainModelMetaData.fullName] &&
                    this.traceRecursiveExternalWithSameDomainModelMetaData[domainModelMetaData.fullName][currentLevel - 1] === true
                )
            ) {
                if (currentLevel < maxRecursiveLevel) {
                    result.push(
                        ...this.getZoomMetaDataFromDomainModelMetaData(
                            external.dependentAggregateMetaData.rootMetaData,
                            defaultExcludedFields,
                            [],
                            currentLevel + 1,
                            maxRecursiveLevel,
                            innerParent,
                            innerRootPath,
                            innerPrefix
                        )
                    );
                }
            }
        });

        return result;
    }

    private getZoomSortedMetaData(findOptions: FindOptions): ZoomMetaData[] {

        const excludedFields = ['CurrentState'];
        this.traceRecursiveExternalWithSameDomainModelMetaData = {};
        const result = this.getZoomMetaDataFromDomainModelMetaData(this.requestedDomainModelMetadata, excludedFields, [])
        const identities = this.requestedDomainModelMetadata.identityNames;
        let position = 0;
        // assegna position incrementali solo alle identities, il resto ha la position fissa più alta delle identies
        result.forEach((res: ZoomMetaData) => {
            if (res?.propertyPath?.length > 0 && identities.indexOf(res.propertyPath) > -1) {
                res.position = ++position;
            } else {
                res.position = identities.length + 1;
            }
        });
        position = 0;
        result.sort((a, b) => {
            if (a.position < b.position) {
                return -1;
            }
            if (a.position > b.position) {
                return 1;
            }

            let stringA = a.propertyPath;

            if (a.internalCollection || a.internalRelation || a.external) {
                stringA = a.rootPath;
            }

            let stringB = b.propertyPath;

            if (b.internalCollection || b.internalRelation || b.external) {
                stringB = b.rootPath;
            }

            stringA = MetaDataUtils.toCamelCase(stringA);
            stringB = MetaDataUtils.toCamelCase(stringB);

            // se hanno lo stesso position, ordino alfabeticamente
            return stringA.localeCompare(stringB);
        }).map((r) => {
            // associo la position a tutti
            r.position = ++position;
            return r;
        });

        return result;
    }

}

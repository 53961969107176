import { HttpClient } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { LogService, OnlineService } from "@nts/std/src/lib/utility";
import { WebApiServiceAgent } from "../api-clients/web-api-service-agent";
import { AuthService } from "../auth/auth.service";
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { ResponseCacheService } from "../responses/response-cache.service";
import { RoutingService } from "../routing/routing.service";
import { TelemetryService } from "@nts/std/src/lib/telemetry";

@Injectable({
    providedIn: 'root'
})
export class NtsBootService {

    constructor(
        public httpClient: HttpClient,
        public zone: NgZone,
        public environmentConfiguration: EnvironmentConfiguration,
        public authService: AuthService,
        public routingService: RoutingService,
        public logService: LogService,
        public onlineService: OnlineService,
        public responseCacheService: ResponseCacheService,
        public webApiServiceAgent: WebApiServiceAgent,
        public telemetryService: TelemetryService
    ) {}
}
<div class="notification-panel" *ngIf="orchestratorViewModel?.allViewModelInformationsBuildedList?.length > 0">
    
    <div class="notification-panel-left">
        <div class="notification-panel-icon">
            <svg-icon key="info"></svg-icon>
        </div>
    </div>
    <div class="notification-panel-content" [class.expanded]="itemToShow > 1">
        <div class="notification-panel-top" *ngIf="orchestratorViewModel?.allViewModelInformationsBuildedList?.length > 1">
            <div class="notification-panel-title">
                {{
                    ('std_Notification_Message' | NTSTranslate: [orchestratorViewModel?.allViewModelInformationsBuildedList?.length.toString()])
                }} <a [routerLink]="[]" (click)="showMore()" *ngIf="orchestratorViewModel?.allViewModelInformationsBuildedList.length > (itemToShow + 1)" role="button" class="notification-panel-bottom-bar-show-button">{{'std_Notification_Show_All_Message' | NTSTranslate}}</a>
            </div>
        </div>
        <div class="notification-panel-bottom" [class.one-notification]="orchestratorViewModel?.allViewModelInformationsBuildedList.length == 1">
            <ng-container [ngSwitch]="orchestratorViewModel?.allViewModelInformationsBuildedList.length">
                <div *ngSwitchCase="1" class="notification-panel-message">
                    <div class="notification-panel-message-text"> 
                        {{ orchestratorViewModel?.allViewModelInformationsBuildedList[0].message }}
                    </div>
                </div>
    
                <ng-container *ngSwitchDefault>
                    <div class="notification-panel-message" *ngFor="let information of orchestratorViewModel?.allViewModelInformationsBuildedList.slice(0, itemToShow)">
                        <div class="notification-panel-message-text"> 
                            -  {{ information.message }}
                        </div>
                    </div>
                </ng-container>
            </ng-container>
    
            <div class="notification-panel-bottom-bar">
                <a [routerLink]="[]" (click)="showLess()" *ngIf="orchestratorViewModel?.allViewModelInformationsBuildedList.length == itemToShow && itemToShow != defaultItemToShow" role="button" class="notification-panel-bottom-bar-show-button">{{'std_Notification_Show_Less_Message' | NTSTranslate}}</a>
            </div>
        </div>
    </div>
    <div class="notification-panel-right">
        <nts-text-button 
            icon="cancel"
            (onClicked)="orchestratorViewModel.eventDispatcher.onNotificationBarCollapsed.next(true)"></nts-text-button>
    </div> 
</div>
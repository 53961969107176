import { Observable } from "rxjs/internal/Observable";
import { OperationIdentity } from "../report/operation.identity";
import { AttachmentIdentity } from "./attachment.identity";
import { OperationState } from "./operation-state";

export class SpoolProcess<TProcessResult> {

    CheckProcess$: Observable<{ operationState: OperationState, attachments: AttachmentIdentity[], processResult: TProcessResult }>;
    state: OperationState;
    operationIdentity: OperationIdentity
    downloadAttachements: boolean;
    spoolControllerAddress: string;
    attachments: AttachmentIdentity[]
    processResult: TProcessResult;

    constructor() { }
}

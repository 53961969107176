import { LogLevel } from './log-level.enum';
import { DecycleLibInterface } from './log.service';

export interface TenantProfileLoggerAwareInterface {
    id: number;
    businessName: string;
    vatNumber: string;
}

export interface UserProfileLoggerAwareInterface {
    id: number;
    name?: string;
    lastName?: string;
    loginInformation?: {
        email: string
    }
}

export class LogEntry {
    // Public Properties
    entryDate: Date = new Date();
    message: string = '';
    level: LogLevel = LogLevel.Debug;
    extraInfo: any[] = [];
    logWithDate: boolean = true;
    logWithTenant: boolean = true;
    logWithUser: boolean = true;
    tenantProfile!: TenantProfileLoggerAwareInterface|null;
    userProfile!: UserProfileLoggerAwareInterface|null;
    decycleLib!: DecycleLibInterface;

    buildLogString(): string {
        let ret: string = '';

        if (this.logWithDate) {
            ret = "[" + this.formatDate(new Date()) + "] - ";
        }

        if (this.logWithTenant && this.tenantProfile?.businessName) {
            ret += "[" + this.tenantProfile?.businessName + "] - ";
        }

        if (this.logWithUser && this.userProfile?.name && this.userProfile?.lastName) {
            ret += "[" + this.userProfile?.name + " " + this.userProfile?.lastName + "] - ";
        }

        ret += "Type: " + LogLevel[this.level];
        ret += " - Message: " + this.message;
        if (this.extraInfo.length) {
            ret += " - Extra Info: " + this.formatParams(this.extraInfo);
        }

        return ret;
    }

    private formatDate(date: Date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        hours = hours % 24;
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ':' + seconds;
        return (date.getFullYear() + "-" + date.getMonth() + 1) + "-" + date.getDate() + " " + strTime;
    }

    private formatParams(params: any[]): string {
        let ret: string = params.join(",");

        // Is there at least one object in the array?
        if (params.some(p => typeof p == "object")) {
            ret = "";

            // Build comma-delimited string
            for (let item of params) {
                // evito riferimenti circolari con l'utilizzo di una libreria di decycle
                ret += JSON.stringify(this.decycleLib.decycle(item)) + ",";
            }
        }

        return ret;
    }
}

import { MessageResourceManager } from './../../../../../resources/message-resource-manager';
import { AccessMode } from '../../../../../meta-data/access-mode.enum';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { NgClass } from '@angular/common';

@Component({
    selector: 'nts-base-security-text-box',
    templateUrl: './base-security-text-box.component.html',
    styleUrls: ['./base-security-text-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgxPopperjsModule,
        NgClass
    ]
})

export class BaseSecurityTextBoxComponent implements AfterViewInit, OnInit {
    @Input() tabIndex = -1;
    @Input() value: any;
    @Input() accessMode: AccessMode;

    @ViewChild('securityTextBox', { static: true }) securityTextBox: ElementRef;

    securityTooltipDescription = '';
    securityTooltipClass = '';
    securityAccessModeClass = '';
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;

    ngOnInit(): void {
        switch (this.accessMode) {
            case AccessMode.ReadOnly:
                this.securityTooltipDescription = MessageResourceManager.Current.getMessage('std_Security_Access_Mode_ReadOnly');
                this.securityTooltipClass = 'alert';
                this.value = this.value;
                break;
            case AccessMode.Deny:
                this.securityTooltipDescription = MessageResourceManager.Current.getMessage('std_Security_Access_Mode_Deny');
                this.securityTooltipClass = 'error';
                this.value = null;
                break;
        }
    }

    ngAfterViewInit(): void {
    }
}

<button 
    [attr.type]="buttonAttributeType"
    #button
    (click)="buttonClicked($event)"
    [class.is-disabled]="isDisabled" 
    [attr.tabindex]="tabIndex" 
    [popper]="title ?? ''"
    [popperDisabled]="!title || title?.length == 0 || isDisabled"
    [popperTrigger]="ngxPopperjsTriggers.hover"
    [popperApplyClass]="'info'"
    [popperPlacement]="ngxPopperjsPlacements.TOP"
    [popperPreventOverflow]="false"
    [popperHideOnScroll]="true"
    popperAppendTo="body"
    [popperDelay]="1000"
    [class.is-loading]="isLoading"  
    [ngClass]="filledButtonType[type]" 
    [attr.disabled]="isDisabled ? true : null"
    [attr.data-icon]="icon"
    [attr.data-label]="label">
    <svg-icon *ngIf="icon" 
        [key]="icon" 
        color="#FFFFFF"></svg-icon>
    <div class="button-label" 
        [class.has-icon]="icon">{{ label }}</div>
        <!-- <span class="loader"></span> -->
</button>
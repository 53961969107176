import { TimeSpan } from '../domain-models/time-span';
import { Expose, Transform } from '../serialization/class-transformer/decorators';
import { PropertyMetaData } from './property-meta-data';

/**
 * Metadati per property di tipo TimeSpan
 */
export class TimeSpanMetaData extends PropertyMetaData {

    /**
     * Eventuale valore minimo ammesso
     */
    @Expose()
    @Transform((value, obj, type) => {
		return TimeSpan.transform('minValue', value, obj, type);
	  })
    minValue?: TimeSpan;

    /**
     * Eventuale valore massimo ammesso
     */
    @Expose()
    @Transform((value, obj, type) => {
		return TimeSpan.transform('maxValue', value, obj, type);
	  })
    maxValue?: TimeSpan;

    @Expose()
    isAutoComputed?: boolean;

    protected override _type = 'TimeSpan';

    override set(data: any) {
      super.set(data);
      if (data?.minValue != null) {
          this.minValue = data.minValue;
      }
      if (data?.maxValue != null) {
          this.maxValue = data.maxValue;
      }
      if (data?.isAutoComputed != null) {
          this.isAutoComputed = data.isAutoComputed;
      }
    }
}

import { BaseViewModel } from '../base-view-model';
import { ZoomUIStarterArgs } from './zoom-ui-starter-args';
import { UICommandInterface } from '../commands/ui-command.interface';
import { RelayCommand } from '../commands/relay-command';
import { FindOptions } from '../../domain-models/find-options/find-options';
import { ZoomStarterMode } from '../../domain-models/zoom/zoom-starter-mode';
import { CommandsPage } from '../commands/commands-page';
import { ZoomQueryContext } from './zoom-query-context';
import { ZoomResultsViewModel } from './zoom-results-view-model';
import { ModalService } from '../modal';
import { ZoomApiClient } from '../../api-clients/zoom/zoom-api-client';
import { ZoomArgs } from './zoom-args';
import { ZoomQueryOutputOrder } from '../../domain-models/zoom/zoom-query-output-order';
import { MessageResourceManager } from '../../resources/message-resource-manager';
import { MessageCodes } from '../../resources/message-codes';
import { ZoomParametersViewModel } from './zoom-parameters-view-model';
import { MetaDataExternalRequest } from '../../requests/meta-data-external-request';
import { classToClass, classToPlain } from '../../serialization/class-transformer';
import { firstValueFrom } from 'rxjs';
import { ZoomListViewModel } from './zoom-list-view-model/zoom-list-view-model';
import { ToastMessageService } from '../../components/layout/toast-message/toast-message.service';
import { ZoomAdvancedOptions } from '../../domain-models/find-options/zoom-advanced-options';
import { AggregateMetaData } from '../../meta-data/aggregate-meta-data';
import { ZoomAvailablesFilterPipe } from '../../components/zoom/zoom-availables-filter.pipe.ts';
import { IGetRowsParams } from 'ag-grid-community';
import { ZoomResult } from '../../domain-models/zoom/zoom-result';


enum RetrieveDirection {
    Forward,
    Backward,
    Beginning
}

export abstract class BaseZoomOrchestratorViewModel extends BaseViewModel {

    get argsStarter(): ZoomUIStarterArgs {
        return this._argsStarter;
    }

    set argsStarter(value) {
        this._argsStarter = value;
    }

    private _loading = false;

    get loading() {
        return this._loading;
    }

    set loading(value: boolean) {
        this.zoomList.isLoading.next(value);
        this._loading = value;
    }

    getZoomResultSessionStorageKey() {
        return `${this.zoomList.args.requestedDomainModelMetadata.fullName}_ZoomResult`;
    }

    getZoomConfigurationSessionStorageKey() {
        return `${this.zoomList.args.requestedDomainModelMetadata.fullName}_ZoomConfiguration`;
    }

    parameterExpanderIsExpanded = true;
    resultsIsExpanded = false;
    isVisibleOpenCommand = false;
    isVisibleOkAndCancelCommand = true;
    results: ZoomResultsViewModel;
    parameters: ZoomParametersViewModel;
    zoomList: ZoomListViewModel;
    refreshCommand: RelayCommand;

    // Comandi solo per le modalità di apertura Result e Parameter dalla dashboard

    openCommand: UICommandInterface;
    rootDomainModelDisplayName: string;
    rootDomainModelDescription: string;
    callerDomainModelName: string;
    callerDomainModelFullName: string;
    requestedDomainModelName: string;
    defaultOptions: FindOptions;
    findInProgress = false;

    private _argsStarter: ZoomUIStarterArgs;
    private _extDependentAssociationPropertiesDisplayName: Map<string, Map<string, string>>;

    zoomQueryContext = new ZoomQueryContext();

    get ZoomStarterMode(): ZoomStarterMode {
        return this.argsStarter.zoomStarterMode;
    }

    commandsPage: Array<CommandsPage> = new Array<CommandsPage>();

    constructor(
        public readonly apiClient: ZoomApiClient,
        public readonly modalService: ModalService,
        public readonly toastMessageService: ToastMessageService,
        public readonly zoomAvailablesFilterPipe: ZoomAvailablesFilterPipe,
    ) {
        super();
        this.results = new ZoomResultsViewModel(apiClient);
        this.parameters = new ZoomParametersViewModel(modalService, apiClient, zoomAvailablesFilterPipe);
        this.zoomList = new ZoomListViewModel(apiClient, modalService, toastMessageService);
    }

    async updateZoomAdvancedOptions(
        options: ZoomAdvancedOptions
    ) {
        this.loading = true;
        this.argsStarter.options = options;

        const args = new ZoomArgs(
            this.metaData.rootMetaData,
            this.metaData,
            this.argsStarter.callerDomainModelName,
            this.argsStarter.callerDomainModelFullName,
            this.argsStarter.options
        );

        await this.parameters.init(args);
        await this.results.init(this, args, this.parameters.filters);
        this.loading = false;
    }

    protected metaData: AggregateMetaData;

    async initialize(argsStarter: ZoomUIStarterArgs): Promise<void> {
        if (this.argsStarter == null) {
            this.defaultOptions = classToClass(argsStarter.options);
        }

        this.argsStarter = argsStarter;
        this.callerDomainModelName = argsStarter.callerDomainModelName;
        this.callerDomainModelFullName = argsStarter.callerDomainModelFullName;
        this.requestedDomainModelName = argsStarter.requestedDomainModelName;

        const outputOrder = await this.checkAndLoadPredefinedQuery();

        const response = await this.getMetadata(argsStarter);

        if (response.operationSuccedeed) {

            this.metaData = response.result;

            this.rootDomainModelDisplayName = this.metaData.rootMetaData.descriptions.displayName ?? MessageResourceManager.Current.getMessage(this.metaData.rootMetaData.descriptions.displayNameKey);
            this.rootDomainModelDescription = this.metaData.rootMetaData.descriptions.description ?? MessageResourceManager.Current.getMessage(this.metaData.rootMetaData.descriptions.descriptionKey);

            const args = new ZoomArgs(
                this.metaData.rootMetaData,
                this.metaData,
                argsStarter.callerDomainModelName,
                argsStarter.callerDomainModelFullName,
                argsStarter.options
            );

            if (this.apiClient.rootDomainModelName == null) {
                this.apiClient.initRootDomainModelName(argsStarter.rootDomainModelName);
            }

            args.zoomStarterMode = argsStarter.zoomStarterMode;
            args.zoomQueryContext = this.zoomQueryContext;

            this._extDependentAssociationPropertiesDisplayName = null; //metadata.geExternalDependentAssociationPropertiesDisplayName();
            args.extDependentAssociationPropertiesDisplayName = this._extDependentAssociationPropertiesDisplayName;

            this.zoomList.setZoomParametersViewModel(this.parameters);
            this.zoomList.setZoomResultViewModel(this.results);

            this.zoomList.isLoading.subscribe((value) => {
                this._loading = value;
            });

            this.zoomList.updateZoomAdvancedOptionsEmitted.subscribe((z: ZoomAdvancedOptions) => {
                this.updateZoomAdvancedOptions(z);
            });
            
            await this.parameters.init(args);
            await this.results.init(this, args, this.parameters.filters);
            await this.zoomList.init(args);
            
            this.parameters.parametersChanged.subscribe(() => {
                this.zoomQueryContext.setChangedState();
            });

            this.parameters.findEmitted.subscribe(() => {
                this.find();
            });

            this.results.onSortChanged.subscribe(() => {
                this.parameters.notifyModified();
                this.parameters.onSortChangedFromExternal.next();
            });

            this.results.selectedResult.subscribe(async (zoomResult)=>{
                await this.storeZoomDataInLocalStorage(zoomResult)
            });

            if (outputOrder != null) {
                // aggiorna l'ordine delle colonne in base alla query predefinita
                this.updateOutputOrderedColumns(outputOrder);
            }

            this.zoomQueryContext.setUnchangedState();
        } else {
            let message = '';
            response.errors.forEach(e => message += e.description);

            throw new Error('ZoomException: ' + message);
        }
    }

    protected async storeZoomDataInLocalStorage(zoomResult: ZoomResult){
        const zoomConfiguration = await this.zoomList.currentZoomConfiguration();
        
        const zoomResultPlain = classToPlain(zoomResult, { strategy: 'excludeAll' });
        const zoomConfigurationPlain = classToPlain(zoomConfiguration, { strategy: 'excludeAll' });
        
        window.sessionStorage.setItem(this.getZoomResultSessionStorageKey(), JSON.stringify(zoomResultPlain));
        window.sessionStorage.setItem(this.getZoomConfigurationSessionStorageKey(), JSON.stringify(zoomConfigurationPlain));
    }

    // visto che i metadati degli external non sono più visibili con la chimata di default
    // devo per forza fare la chiamata per recuperare tutti i metadati che mi servono
    protected async getMetadata(argsStarter: ZoomUIStarterArgs) {
        const request = new MetaDataExternalRequest();
        request.fullRootModelName = argsStarter.requestedDomainModelFullName;
        request.includeDescriptions = true;
        request.includeSecurityMetaData = true;
        return await firstValueFrom(this.apiClient.getMetaDataExternal(request));
    }

    async find () {
        this.findInProgress = true;
        let options: ZoomAdvancedOptions = this.parameters.getCurrentOptions();

        // Collassa i filtri ed espandi i risultati
        this.parameterExpanderIsExpanded = false;
        this.parameters.rowToSetFocus = -1; // In questo modo non seleziona il primo filtro by def.
        this.resultsIsExpanded = true;

        await this.results.refreshColumns();

        this.results.getPaginatedCollectionItems = async (take: number, skip: number, params: IGetRowsParams) => {
            
            try {
                if (skip == 0) { 
                    
                    if (params?.sortModel?.length >= 0) {
                        // Ricalcola gli orderBy in base al sortModel passato
                        this.parameters.updateFiltersFromSortModel(params?.sortModel)
                    }
                }

                options.take = take;
                options.skip = skip;

                const result = await this.results.find(options);
                if (skip == 0) {
                    this.results.forceAutoSize.emit(); // forza aggiornamento dimensione solo la prima volta
                    this.findInProgress = false;
                }
                return result;
            } catch (error) {
                this.findInProgress = false;
                const popUpMessage = MessageResourceManager.Current.getMessage(MessageCodes.ZoomFindException);
                this.modalService.showExceptionPopUp(error, '', popUpMessage);
            }
            return null;
        };
        this.results.datasourceUpdated.emit();
    }

    private async checkAndLoadPredefinedQuery(): Promise<Array<ZoomQueryOutputOrder>> {
        if (this.argsStarter.zoomStarterMode === ZoomStarterMode.Zoom) {
            this.zoomQueryContext.zoomFromZoom = true;
        }
        return null;
    }

    private updateOutputOrderedColumns(outputOrder: Array<ZoomQueryOutputOrder>): void {
        for (const item of this.results.columns) {
            const order = outputOrder.find(x => x.fieldName.toUpperCase() === item.fieldName.toUpperCase());
            if (order != null) {
                item.orderIndex = order.index;
                item.isVisible = order.isVisible;
            }
        }
    }
}


import { Component, Input, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy, OnChanges, SimpleChanges, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

let nextId = 0;

@Component({
  selector: 'nts-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule
  ]
})
export class CheckboxComponent implements OnDestroy {

  @Input() label = '';
  @Input() title = this.label;
  @Input() isDisabled = false;
  @Input() readOnly = false;

  /**
   * Holds the current value of the slider
   */
  @Input() value = false;

  /**
   * Invoked when the model has been changed
   */
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('check', { static: true }) check: ElementRef;

  id = `nts-chk-${nextId++}`;

  constructor(public readonly el: ElementRef, private readonly cd: ChangeDetectorRef) { }

  get input(): HTMLInputElement {
    return this.check.nativeElement;
  }

  ngOnDestroy() {
  }
}

import { ZoomOptions } from './zoom-options';
import { IncludeInfo } from './include-info';
import { Expose, Type } from '../../serialization/class-transformer/decorators';
import { OutputDataOrderDto } from '../zoom/dto/output-data-order.dto';

export class ZoomAdvancedOptions extends ZoomOptions {

    @Expose()
    @Type(() => IncludeInfo)
    includes: Array<IncludeInfo>;

    @Expose()
    @Type(() => OutputDataOrderDto)
    outputDataOrderList: OutputDataOrderDto[];

    @Expose()
    allowEdit: boolean;

    constructor(zoomOptions?: ZoomOptions) {
        super();
        if (zoomOptions !== undefined) {
            this.filters = zoomOptions.filters;
            this.orderByPropertyNames = zoomOptions.orderByPropertyNames;
            this.skip = zoomOptions.skip;
            this.take = zoomOptions.take;
        }
    }

    firstOrDefaultIncludeInfo(predicate: any): IncludeInfo {
        const listIncludes: Array<IncludeInfo> = [];
        const incls: Array<IncludeInfo> = this.flattenInclude(this.includes, listIncludes);

        return incls.find(predicate);
    }

    private flattenInclude(includes: IncludeInfo[], incls: Array<IncludeInfo>): Array<IncludeInfo> {
        includes.forEach(i => {
            incls.push(i);
            if (i.includes && i.includes.length > 0) {
                this.flattenInclude(i.includes, incls);
            }
        });
        return incls;
    }


}

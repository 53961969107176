import { ZoomApiClient } from "../../../../api-clients/zoom/zoom-api-client";
import { map, of, firstValueFrom, Observable, startWith, BehaviorSubject, merge } from "rxjs";
import { ToastMessageService } from "../../../../components/layout/toast-message/toast-message.service";
import { FilledButtonType } from "../../../../components/shared/buttons/filled-button/filled-button.component";
import { ViewModelTypeDecorator } from "../../../../decorators/view-model-type.decorator";
import { StringDecorator } from "../../../../domain-models/decorators/string.decorator";
import { StringMetaData } from "../../../../meta-data/string-meta-data";
import { MessageResourceManager } from "../../../../resources/message-resource-manager";
import { StringPropertyViewModel } from "../../../base-type/string-property-view-model";
import { CommandFactory } from "../../../commands/command-factory";
import { UIResultCommandInterface } from "../../../commands/ui-result-command.interface";
import { CustomPropertyViewModelDecorator } from "../../../decorators/custom-property-view-model.decorator";
import { ModalViewModelInterface } from "../../../modal/modal-view-model.interface";
import { ModalService } from "../../../modal/modal.service";
import { ModifiedSubscriberInterface } from "../../../modified-subscriber.interface";
import { PropertyViewModelFactory } from "../../../property-view-model-factory";
import { ViewModelEventDispatcher } from "../../../view-model-event-dispatcher";
import { BoolDecorator } from "../../../../domain-models/decorators/bool.decorator";
import { BoolMetaData } from "../../../../meta-data/bool-meta-data";
import { ZoomDataDto } from "../../../../domain-models/zoom/dto/zoom-data.dto";
import { BoolPropertyViewModel } from "../../../base-type/bool-property-view-model";
import { ZoomIdentity } from "../../../../domain-models/zoom/zoom.identity";
import { UICommandInterface } from "../../../commands/ui-command.interface";

@ViewModelTypeDecorator(EditZoomModalViewModel)
export class EditZoomModalViewModel implements ModalViewModelInterface<any, any> {

    filledButtonType = FilledButtonType;

    modalTitle = MessageResourceManager.Current.getMessage('std_EditZoomModalViewModel_Title');
    modalSubtitle = '';
    modalCommands = [];
    showFooter = true;
    updating$ = new BehaviorSubject<boolean>(false);

    modifiedSubscriber: ModifiedSubscriberInterface;
    eventDispatcher: ViewModelEventDispatcher;

    private openCommand: UICommandInterface;
    private confirmCommand: UIResultCommandInterface<ZoomDataDto, any>;

    constructor(
        public zoomDataDto: ZoomDataDto,
        public modalService: ModalService,
        public toastMessageService: ToastMessageService,
        public zoomApiClient: ZoomApiClient,
    ) {
        this.setupCommands();
    }

    canEditZoom(): Observable<boolean> {
        return merge(this.title.propertyChanged, this.updating$).pipe(
            startWith(null),
            map(()=>(this.title?.value?.length > 0 && this.updating$.value === false)));        
    }

    async editZoom(): Promise<ZoomDataDto> {
        this.updating$.next(true);
        this.title.isEnabled = false;
        this.isPreferred.isEnabled = false;

        const zoomDataDto = new ZoomDataDto();
        zoomDataDto.zoomIdentity = new ZoomIdentity();
        zoomDataDto.zoomIdentity.zoomId = this.zoomDataDto.zoomIdentity.zoomId;
        zoomDataDto.title = this.title.value;
        zoomDataDto.isPreferred = this.isPreferred.value;

        const response = await firstValueFrom(this.zoomApiClient.editZoomDataAsync(zoomDataDto));
        this.toastMessageService.showToastsFromResponse(response);
        // this.confirmCommand.closeModal = response.operationSuccedeed;
        this.confirmCommand.closeModal = true;
        this.title.isEnabled = true;
        this.updating$.next(false);
        return zoomDataDto;
    }

    async openZoomData() {

    }

    private setupCommands() {

        this.openCommand = CommandFactory.createResultUICommand(
            () => this.openZoomData(),
            () => of(true)
        )

        this.openCommand.displayName = MessageResourceManager.Current.getMessage('std_CMD_EditZoomModalViewModel_Open_DisplayName');
        this.openCommand.closeModal = false;
        
        // this.modalCommands.push(this.openCommand);

        this.confirmCommand = CommandFactory.createResultUICommand(
            () => this.editZoom(),
            () => this.canEditZoom()
        );
        this.confirmCommand.displayName = MessageResourceManager.Current.getMessage('std_CMD_EditZoomModalViewModel_Confirm_DisplayName');
        this.confirmCommand.closeModal = false;
        this.confirmCommand.isDefault = true;
        this.modalCommands.push(this.confirmCommand);
    }

    async initialize(): Promise<void> {
    }

    // tslint:disable-next-line: member-ordering variable-name
    private _title: StringPropertyViewModel;

    @CustomPropertyViewModelDecorator()
    @StringDecorator({ displayNameKey: 'std_EditZoomModalViewModel_Title_DisplayName', isRequired: true })
    public get title(): StringPropertyViewModel {
        if (this._title == null) {
            const init = PropertyViewModelFactory.createPVMInitializationInfo<StringMetaData>(
                this, 'title', null, this, this.modifiedSubscriber, this.eventDispatcher, null, false, false
            );
            this._title = new StringPropertyViewModel(init);
            this._title.metadataMaxLength = 500;
            this._title.instantModelChange = true;
            this._title.value = this.zoomDataDto.title;
        }
        return this._title;
    }

    // tslint:disable-next-line: member-ordering variable-name
    private _isPreferred: BoolPropertyViewModel;

    @CustomPropertyViewModelDecorator()
    @BoolDecorator({ displayNameKey: 'std_EditZoomModalViewModel_IsPreferred_DisplayName' })
    public get isPreferred(): BoolPropertyViewModel {
        if (this._isPreferred == null) {
            const init = PropertyViewModelFactory.createPVMInitializationInfo<BoolMetaData>(
                this, 'isPreferred', null, this, this.modifiedSubscriber, this.eventDispatcher, null, false, false
            );
            this._isPreferred = new BoolPropertyViewModel(init);
            this._isPreferred.instantModelChange = true;
            this._isPreferred.value = this.zoomDataDto.isPreferred;
        }
        return this._isPreferred;
    }

}

import { catchError, from, map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientConfigurationManager } from './client-configuration-manager';
import { LocalstorageHelper } from '../localstorage-helper';
import { LogService } from '../log/log.service';

@Injectable({
    providedIn: 'root',
})
export class ClientConfigurationLoader {

    static LOCAL_STORAGE_CONFIGURATION_KEY = 'configuration';

    constructor(private http: HttpClient) { }

    getConfiguration(): Observable<Object> {

        return from(LocalstorageHelper.getStorageItem(ClientConfigurationLoader.LOCAL_STORAGE_CONFIGURATION_KEY, undefined, false)).pipe(
            map((localStorageConfiguration) => {
                localStorageConfiguration = localStorageConfiguration ?? {};
                const extendedRes: any = localStorageConfiguration;
                ClientConfigurationManager.Current.config = extendedRes;
                ClientConfigurationManager.Current.loaded.next(true);
                return ClientConfigurationManager.Current.config as Object;
            }),
            catchError((err) => {
                LogService.debug(err);
                return of({});
            })
        )
    }
}

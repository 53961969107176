import { AfterContentInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ZoomOrchestratorViewModel } from '../../../view-models/zoom/zoom-orchestrator-view-model';
import { ModalComponentInterface } from '../../modal/modal-component.interface';
import { MessageResourceManager } from '../../../resources/message-resource-manager';
import { ExpandableComponent } from '../../shared/expandable/expandable.component';
import { LoaderComponent } from '../../shared/loader/loader.component';
import { ZoomListComponent } from '../zoom-list/zoom-list.component';
import { ZoomParametersComponent } from '../zoom-parameters/zoom-parameters.component';
import { ZoomParametersMobileComponent } from '../zoom-parameters-mobile/zoom-parameters-mobile.component';
import { ZoomResultsComponent } from '../zoom-results/zoom-results.component';

@Component({
  selector: 'nts-zoom-container',
  templateUrl: './zoom-container.component.html',
  styleUrls: ['zoom-container.component.scss'],
  standalone: true,
  imports: [
    LoaderComponent,
    ZoomListComponent,
    ExpandableComponent,
    ZoomParametersComponent,
    ZoomParametersMobileComponent,
    ZoomResultsComponent
  ]
})
export class ZoomContainerComponent implements AfterContentInit, OnInit, ModalComponentInterface {

  @Input()
  viewModel: ZoomOrchestratorViewModel;

  @ViewChild('parametersPanel', { static: true })
  parametersPanel: ExpandableComponent;

  @ViewChild('resultsPanel', { static: true })
  resultsPanel: ExpandableComponent;

  enableParametersPanel = true;
  enableResultsPanel = true;
  splitter: any;

  parametersPanelTitle = MessageResourceManager.Current.getMessage('std_Zoom_Parameters');

  get resultsPanelTitle() {
    let title = MessageResourceManager.Current.getMessage('std_Zoom_Results');

    if (this.viewModel.zoomQueryContext.queryDescription) {
      title += ' ' + this.viewModel.zoomQueryContext.queryDescription
    }

    return title;
  }

  ngOnInit(): void {
    if (!this.viewModel) { throw new Error('Missing viewModel!'); }

    if (this.viewModel.parameterExpanderIsExpanded && this.viewModel.resultsIsExpanded) {
      this.viewModel.resultsIsExpanded = false;
    }
  }

  ngAfterContentInit() {
    this.parametersPanel.isCollapsed = false;
    this.resultsPanel.isCollapsed = false;
  }

  checkPreventResults($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  onResultsCollapsed() {
    this.viewModel.resultsIsExpanded = false;
  }

  onResultsExpanded() {
    this.viewModel.resultsIsExpanded = true;
  }

  onParametersCollapsed() {
    this.viewModel.parameterExpanderIsExpanded = false;
  }

  onParametersExpanded() {
    this.viewModel.parameterExpanderIsExpanded = true;
    this.viewModel.resultsIsExpanded = false;
  }
}

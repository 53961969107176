import { Injector } from '@angular/core';
import { ViewModelLocator } from '../view-model-locator';
import { ZoomOrchestratorViewModel } from './zoom-orchestrator-view-model';

export class ZoomOrchestratorViewModelResolver {

    static createZoomOrchestratorViewModel(): ZoomOrchestratorViewModel {

        let ovm: ZoomOrchestratorViewModel;
        // Composition e DI dinamica
        // NB Devono essere riescplicitati tutti i provider della catena di DI
        // in quanto non vengono considerati quelli globali

        const zoomOrchestratorProviders = ViewModelLocator.getZoomOrchestratorViewModelProviders();

        const injector = Injector.create({
            providers: zoomOrchestratorProviders
        });

        ovm = injector.get<ZoomOrchestratorViewModel>(ZoomOrchestratorViewModel);

        return ovm;

    }

}

import { Expose } from "../../serialization/class-transformer/decorators";
import { BaseIdentity } from "../base-identity";
import { IdentityTypeNameDecorator } from "../decorators/identity-type-name.decorator";

@IdentityTypeNameDecorator('LayoutDefinitionIdentity')
export class LayoutDefinitionIdentity extends BaseIdentity {

    private _code: string;

    @Expose()
    get code(): string {
        return this.getValue<string>(() => this._code, 'code');
    }
    set code(value: string) {
        this.setValue<string>(() => { this._code = value; }, value, 'code');
    }
}

import { NTSReflection } from '@nts/std/src/lib/utility';
import cloneDeep from 'lodash-es/cloneDeep';

const RESPONSE_CACHED_IDENTIFICATION_KEY = 'ResponseCachedKey';

export interface ResponseCachedDecoratorInterface {
    
    // Se impostato a true, la cache viene utilizzata solo per la modalità offline
    onlyOffline?: boolean, 

    // Tempo in secondi dopo il quale la cache viene considerata scaduta
    expirationTime?: number 
}

export function ResponseCachedDecorator(params?: ResponseCachedDecoratorInterface) {
    return (target: any) => {
        Reflect.defineMetadata(RESPONSE_CACHED_IDENTIFICATION_KEY, params ?? true, target);
    };
}

export class ResponseCachedInspector {

    static META_DATA_KEY = RESPONSE_CACHED_IDENTIFICATION_KEY;
    static DECORATOR_NAME = '@ResponseCachedDecorator';

    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            RESPONSE_CACHED_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): any {
        const result = NTSReflection.getClassMetadata(
            RESPONSE_CACHED_IDENTIFICATION_KEY, subject);
        return typeof result == 'function' ? result : cloneDeep(result);
    }
}

<nav class="nav" role="navigation">
  <ul class="links">
    <li *ngFor="let menuItem of this.getMenu() | menuListNewFilter: searchModel" [class.link]="menuItem.children.length === 0" [class.section]="menuItem.children.length > 0"  [class.has-active-child]="menuItem.hasActiveChild">
      <a *ngIf="menuItem.children.length === 0"  [ngSwitch]="searchModel?.length" [routerLink]="[ menuItem.route ]" [attr.title]="menuItem.title" [attr.aria-label]="menuItem.title" class="link" tabindex="-1">
        <ng-container *ngSwitchCase="0">
          {{ menuItem?.label }}
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{getBeforeMatched(menuItem)}}<span class="matches-filter">{{getMatched(menuItem)}}</span>{{getAfterMatched(menuItem)}}
        </ng-container>
      </a>
      <div *ngIf="menuItem.children.length > 0" class="collapsible-section" [class.is-collapsed]="menuItem.isCollapsed">
        <button tabindex="0" (click)="menuItem.isCollapsed = !menuItem.isCollapsed" [attr.title]="menuItem.title">
          <i data-icon-name="ChevronDown" class="ms-Icon--ChevronDown"></i>
          <span>{{ menuItem.label }}</span>
        </button>
        <div class="collapsible-section-body" *ngIf="!menuItem.isCollapsed">
          <ul class="links">
            <li *ngFor="let child of menuItem.children | menuListNewFilter: searchModel" class="link">
              <a [ngSwitch]="searchModel?.length" [routerLink]="[ child.route ]" [attr.title]="child.title" [attr.aria-label]="child.title" class="link" tabindex="-1">
                <ng-container *ngSwitchCase="0">
                  {{ child?.label }}
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{getBeforeMatched(child)}}<span class="matches-filter">{{getMatched(child)}}</span>{{getAfterMatched(child)}}
                </ng-container>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </li>    
  </ul>
</nav>
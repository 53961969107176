import { ClassAdditionalInfo } from "./class-additional-info";
import { ClassInformationType } from "./class-information-type";
import { ClassInformationInterface } from "./class-information.interface";

export class ClassInformationUtility {

    static checkClassType(instance: ClassInformationInterface, classInformationType: ClassInformationType): boolean {
        return instance.classType === classInformationType;
    }

    static checkClassAdditionalInfo(instance: ClassInformationInterface, classAdditionalInfo: ClassAdditionalInfo): boolean {
        return instance.classAdditionalInfo === classAdditionalInfo;
    }
}

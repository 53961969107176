import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ZoomParametersViewModel } from '../../../view-models/zoom/zoom-parameters-view-model';
import { OrderByType } from '../../../domain-models/autocomplete/auto-complete-options';
import { ZoomFilterViewModel } from '../../../view-models/zoom/filter-view-model/zoom-filter-view-model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { PickListModule } from 'primeng/picklist';
import { LabelBoxComponent } from '../../controls/core/base/label-box/label-box.component';
import { NTSFilterPipe } from '../../pipe/nts-filter-pipe';

@UntilDestroy()
@Component({
    selector: 'nts-zoom-parameters-sorting-groups',
    templateUrl: 'zoom-parameters-sorting-groups.component.html',
    styleUrls: ['./zoom-parameters-sorting-groups.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        SvgIconComponent,
        PickListModule,
        LabelBoxComponent,
        NTSFilterPipe
    ]
})
export class ZoomParametersSortingGroupsComponent implements OnInit {

    @Input() parameters:ZoomParametersViewModel;
    @Output() onSortingGroupsChanged = new EventEmitter<void>();

    target: Array<ZoomFilterViewModel>;

    constructor(private cd: ChangeDetectorRef) {

    }

    loadTargetFromFilters(): ZoomFilterViewModel[] {
        return this.parameters.filters
            .filter((f) => f.orderBy != null && f.isSelected === true)
            .sort((a,b) => a.orderByIndex - b.orderByIndex);
    }

    ngOnInit(): void {
        this.target = this.loadTargetFromFilters();
        this.parameters.onSortChangedFromExternal.pipe(untilDestroyed(this)).subscribe(() => {
            
            // // Save older filters
            // const oldFiltersSort: {
            //     orderBy: OrderByType;
            //     orderByIndex: number;
            //     colId: string;
            // }[] = this.parameters.filters.map((f) => ({
            //     orderBy: f.orderBy,
            //     orderByIndex: f.orderByIndex,
            //     colId: f.propertyNameMap.propertyPath
            // }))
            
            this.target = this.loadTargetFromFilters();

            // const filterUnchanged = this.target.every((f, i) => {
            //     if(oldFiltersSort[i].colId === f.propertyNameMap.propertyPath) {
            //         return oldFiltersSort[i].orderBy == f.orderBy && oldFiltersSort[i].orderByIndex == f.orderByIndex;
            //     }
            // })

            // if (!filterUnchanged) {
            //     this.onSortingGroupsChanged.emit();
            // }

            // this.parameters.sortInitializationFinished.next(true);
            
            this.cd.detectChanges();
        })
    }

    onMoveToTarget(e: {items: ZoomFilterViewModel[]}) {
        if (e?.items?.length > 0) {
            for (const zoom of e.items) {
                zoom.orderBy = OrderByType.Ascending;
            }
        }
        this.rebuildTargetOrderByIndex();
        this.onSortingGroupsChanged.emit();
    }

    onMoveAllToTarget(e: {items: ZoomFilterViewModel[]}) {
        if (e?.items?.length > 0) {
            for (const zoom of e.items) {
                zoom.orderBy = OrderByType.Ascending;
            }
        }
        this.rebuildTargetOrderByIndex();
        this.onSortingGroupsChanged.emit();
    }

    onMoveToSource(e: {items: ZoomFilterViewModel[]}) {
        if (e?.items?.length > 0) {
            for (const zoom of e.items) {
                zoom.orderBy = null;
                zoom.orderByIndex = null;
            }
        }
        this.rebuildTargetOrderByIndex();
        this.onSortingGroupsChanged.emit();
    }

    getSortIcon(filter: ZoomFilterViewModel) {
        if(filter.orderBy == OrderByType.Ascending) {
           return 'sort-up';
        } else {
            return 'sort-down';
        }
    }

    toggleSort(event, filter: ZoomFilterViewModel) {
        if (event) {
            event.preventDefault();
        }
        if(filter.orderBy == OrderByType.Ascending) {
            filter.orderBy = OrderByType.Descending
        } else {
            filter.orderBy = OrderByType.Ascending
        }
        this.onSortingGroupsChanged.emit();
    }

    onMoveAllToSource(e: {items: ZoomFilterViewModel[]}) {
        if (e?.items?.length > 0) {
            for (const zoom of e.items) {
                zoom.orderBy = null;
                zoom.orderByIndex = null;
            }
        }
        this.onSortingGroupsChanged.emit();
    }

    onTargetReorder(e: {items: ZoomFilterViewModel[]}) {
        this.rebuildTargetOrderByIndex();
        this.onSortingGroupsChanged.emit();
    }

    rebuildTargetOrderByIndex() {
        for (const [i, zoom] of this.target.entries()) {
            zoom.orderByIndex = i;
        }
    }
  
}



<div class="input-container"
    [style.borderColor]="showErrorBorder && errorList?.length > 0 ? null : (overrideBorderColor ? overrideBorderColor : null)"
    [class.has-error]="showErrorBorder && errorList?.length > 0"
    [class.is-disabled]="isDisabled"
    [class.is-readonly]="isReadonly">

    <p-chips
        (onFocus)="focus($event)"
        (onBlur)="blur($event)"
        (mouseenter)="mouseEnter($event)"
        (mouseleave)="mouseLeave($event)"
        #popperError="popper"
        [class.is-multiline]="multiline ? multiline : null"
        [popper]="tooltipErrTemplate"
        [popperDisabled]="!showErrorTooltip || !errorList || errorList?.length === 0"
        [popperTrigger]="ngxPopperjsTriggers.hover"
        popperApplyClass="error"
        [popperPlacement]="ngxPopperjsPlacements.TOP"
        [popperPreventOverflow]="false"
        [popperHideOnScroll]="true"
        popperAppendTo="body"
        [tabindex]="tabIndex"
        [disabled]="isDisabled"
        [placeholder]="placeholder"
        [field]="field"
        [addOnTab]="addOnTab"
        [addOnBlur]="addOnBlur"
        [max]="maxEntries"
        [separator]="chipsSeparator"
        [allowDuplicate]="allowDuplicate"
        [showClear]="showClear"
        [imask]="mask"
        class="chips"
        [imaskElement]="getMaskElement"
        [ngClass]="customClasses"
        [class.has-commands]="customCommandList?.length > 0">
    </p-chips>

    <div class="command-list-container"
        *ngIf="customCommandList?.length > 0"
        [class.is-disabled]="isDisabled">
        <ng-template ngFor let-command [ngForOf]="customCommandList">
            <nts-ribbon-button [title]="command.tooltip"
                [tabIndex]="0"
                [isLoading]="command.loading$ | async"
                [icon]="command.iconClass"
                [isDisabled]="!(command.canExecute$ | async)"
                (onClicked)="command.execute()">
            </nts-ribbon-button>
        </ng-template>
    </div>
</div>

<popper-content #tooltipErrTemplate>
    <ng-container *ngFor="let item of errorList">
        {{ item }}
    </ng-container>
</popper-content>

import { CoreOrchestratorViewModelInterface } from './core-orchestrator-view-model.interface';
import { ViewModel } from './view-model';
import { AggregateMetaData, MetaDataUtils } from '../meta-data';
import { ExternalViewModelTypeInspector } from './decorators/external-view-model-type.decorator';
import { ModelInterface } from '../domain-models/model.interface';
import { ViewModelFactory } from './view-model-factory';
import { ExternalDomainModelChangedEventArgs } from './external-domain-model-changed-event-args';
import { ViewModelInterface } from './view-model.interface';
import { InternalViewModelTypeInspector } from './decorators/internal-view-model-type.decorator';
import { CollectionViewModelTypeInspector } from './decorators/collection-view-model-type.decorator';
import { BaseViewModelInterface } from './base-view-model.interface';
import { CoreModel, BaseIdentity, ModelTypeInspector, ExternalInspector } from '../domain-models';
import { LogService } from '@nts/std/src/lib/utility';
import { ExternalViewModelInterface } from './external-view-model.interface';
import { ExternalDomainModelTypeInspector } from './decorators/external-domain-model-type.decorator';
import { CloneTypes } from '../domain-models/clone-types.enum';
import { TypeMetadata } from '../serialization/class-transformer/metadata/TypeMetadata';
import { defaultMetadataStorage } from '../serialization/class-transformer/storage';
import { ClassType } from '../serialization/class-transformer/ClassTransformer';
import { TypeHelpOptions } from '../serialization/class-transformer/metadata/ExposeExcludeOptions';

export class AggregateElementViewModel<
    TModel extends CoreModel<TIdentity>,
    TIdentity extends BaseIdentity>
    extends ViewModel<TModel, TIdentity> {

    protected getExternalViewModelType(propertyName: string): any {
        return ExternalViewModelTypeInspector.getValue(this, propertyName);
    }

    protected getInternalViewModelType(propertyName: string): any {
        return InternalViewModelTypeInspector.getValue(this, propertyName);
    }

    protected getCollectionViewModelType(propertyName: string): any {
        return CollectionViewModelTypeInspector.getValue(this, propertyName);
    }

    protected async initAggregateElementViewModel(
        domainModel: TModel,
        aggregateMetadata: AggregateMetaData,
        orchestratorViewModel: CoreOrchestratorViewModelInterface,
        domainModelName: string = undefined,
        isMockedViewModel = false,
        domainModelType: ClassType<TModel> = null,
        processedNulledModel = []
    ) {

        await this.initViewModel(domainModel, aggregateMetadata, orchestratorViewModel, domainModelName, domainModelType);

        if (!this.domainModelMetaData) {
            console.error(`Non è stato trovato il domainModelMetaData di ${domainModelName}!`)
        }

        // Popolo gli eventuali Internal ViewModels
        if (this.domainModelMetaData.internalRelations.length > 0 && domainModel) {
            await this.buildInternalViewModels(isMockedViewModel);
        }

        // Popolo gli eventuali Collection ViewModels
        if (this.domainModelMetaData.internalCollections.length > 0 && domainModel) {
            await this.buildInternalCollectionViewModels(isMockedViewModel);
        }

        // Popolo i dati relativi alle decodifiche (External)
        // Verifico eventuali refernze circolari quando il modello è nullo, al momento è stato impostato un limite di 2 volte per lo stesso modello
        if (this.domainModelMetaData.externals.length > 0 && (domainModel || processedNulledModel.filter(x => x === this.domainModelMetaData.fullName).length < 2)) {
            if (!domainModel) {
              processedNulledModel.push(this.domainModelMetaData.fullName);
            }
            await this.buildExternalViewModels(isMockedViewModel, false, processedNulledModel);
        }

        this.relationViewModels.forEach(x => {
            x.parent = this;
        });
    }

    protected async buildExternalViewModels(
      isMockedViewModel = false,
      forceCreateViewModels = false,
      processedNulledModel: string[] = []
    ) {

        for (const externalMetaData of this.domainModelMetaData.externals) {

            // TODO
            //     if (Properties.ContainsKey(externalRelation.PropertyName))
            //     {
            //         //Verifico se esiste l'entity nell'aggregato
            //         if (entityProperties.ContainsKey(externalRelation.PropertyName))
            //         {

            const propertyName = MetaDataUtils.toCamelCase(externalMetaData.principalPropertyName);

            // Creo il DecodeViewModel
            const externalViewModelType = this.getExternalViewModelType(propertyName);
            let externalModelType: ClassType<ModelInterface> = null;

            if (externalViewModelType != null) {

                externalModelType = ExternalDomainModelTypeInspector.getValue(externalViewModelType);
                if (externalMetaData.cloneType === CloneTypes.LocalReplica && this.domainModel[propertyName] == null) {
                    this.domainModel.emitChanges = false;
                    this.domainModel[propertyName] = new externalModelType();
                    this.domainModel.emitChanges = true;
                }
            }

            const externalDomainModel: ModelInterface = (this.domainModel && this.domainModel[propertyName]) ? this.domainModel[propertyName] : null;

            if (externalViewModelType !== undefined) {

                const mergedDecoratorData = ExternalInspector.getValue(this.domainModelType, propertyName)
                const externalViewModel = await ViewModelFactory.createExternalViewModel(
                    externalViewModelType,
                    externalDomainModel,
                    this.aggregateMetaData,
                    this.modifiedSubscriber as CoreOrchestratorViewModelInterface,
                    externalMetaData,
                    [this.reservedPath, this.reservedName].filter((w) => w?.length > 0).join('.'),
                    isMockedViewModel,
                    externalModelType,
                    mergedDecoratorData,
                    processedNulledModel
                );
                externalViewModel.parent = this;
                this[propertyName] = externalViewModel;

                this.relationViewModels.set(MetaDataUtils.toCamelCase(externalMetaData.principalPropertyName), externalViewModel);
                externalViewModel.externalDomainModelChanged.subscribe((arg) => this.externalEntityChangedHandler(arg, externalViewModel));

                // TODO
                // var item = evm as ISupportHighlight;
                // if (item != null)
                // {
                // 	EntityPropertiesMap.Add(p.Name, item);
                // }

                // se il suo backing field è popolato ma il suo ref non lo è eseguo una setcodevalue che mi permette di popolare correttamente il suo ref
                if (isMockedViewModel === false && externalMetaData.associationProperties?.length === 1) {
                    const backingFieldPropertyName = MetaDataUtils.toCamelCase(externalMetaData.associationProperties[0].principalPropertyName);
                    const backingField = this.getProperty(backingFieldPropertyName);
                    const extVm = this[propertyName] as ExternalViewModelInterface;

                    if (extVm.checkAndFixNullExternal) {
                        const extFieldPropertyName = MetaDataUtils.toCamelCase(externalMetaData.associationProperties[0].dependentPropertyName);
                        const extField = extVm?.getProperty(extFieldPropertyName);

                        if (
                            // se il backing field è diverso da null
                            backingField?.value != null &&
                            (
                                // il suo external è nullo
                                extField?.value == null ||  //o
                                // è diverso dal suo backing field
                                backingField?.value != extField?.value
                            )) {
                            // Se l'identity è valida
                            if (extVm.identityIsValid({ [backingField.propertyMetaData.name]: backingField.value })) {
                                const oldValue1 = extVm.backingFieldCanNotifyModified;
                                const oldValue2 = extField.canNotifyModified;
                                extVm.backingFieldCanNotifyModified = false;
                                extField.canNotifyModified = false;
                                await extVm.setCodeValue(backingField?.value);
                                extVm.backingFieldCanNotifyModified = oldValue1;
                                extField.canNotifyModified = oldValue2;

                            }

                        }
                    }


                }

            } else {
                // potrebbe anche esserci una ExternalList abbinata a questa entity; in tal caso non occorre fare nulla
                // in quanto il ViewModel è già sato creato quando viene invocato il metodo GetExternalListPropertyViewModel
                // throw new InvalidOperationException
                // ("In the class '" + this.GetType().Name + "' the type of property '" + externalRelation.PropertyName + "' must be descendant of 'ExternalViewModel'");
                // throw new Error('INVALID PROPERTY');
            }

        }
    }

    protected async buildInternalViewModels(isMockedViewModel = false) {

        for (const internalRelation of this.domainModelMetaData.internalRelations) {

            // TODO
            // //Verifico se questa internalRelation è stata esposta nel ViewModel e se esiste l'entity nell'aggregato
            // if (Properties.ContainsKey(internalRelation.PropertyName) &&
            //         entityProperties.ContainsKey(internalRelation.PropertyName))

            // PATCH PER camelCase DEVE ESSERE PascalCase
            const propertyName = MetaDataUtils.toCamelCase(internalRelation.principalPropertyName);

            const internalModel: ModelInterface = this.domainModel[propertyName];
            if (internalModel != null) {

                // Recupero il tipo del modello dell'internal dal decoratore @Type
                let internalModelType = null
                const typeMetadata: TypeMetadata = defaultMetadataStorage.findTypeMetadata(this.domainModelType, propertyName);
                if (typeMetadata && typeMetadata.typeFunction({
                    newObject: new this.domainModelType()
                } as any)) {
                    internalModelType = typeMetadata.typeFunction({
                        newObject: new this.domainModelType()
                    } as TypeHelpOptions) as ClassType<any>
                }

                const internalViewModelType = this.getInternalViewModelType(propertyName);
                if (internalViewModelType != null) {
                    const ivm = await ViewModelFactory.createInternalViewModel(
                        internalViewModelType,
                        internalModel,
                        this.aggregateMetaData,
                        this.modifiedSubscriber as CoreOrchestratorViewModelInterface,
                        internalRelation,
                        false,
                        [this.reservedPath, this.reservedName].filter((w) => w?.length > 0).join('.'),
                        isMockedViewModel,
                        this,
                        internalModelType
                    );
                    this[propertyName] = ivm;
                    this.relationViewModels.set(propertyName, ivm as ViewModelInterface);

                    ivm.viewModelChanged.subscribe(e => {
                        this._viewModelChangeDebouncer.next();
                    });
                } else {
                    // throw new InvalidOperationException
                    // ("In the class '" + this.GetType().Name + "' the type of property '" + internalRelation.PropertyName + "' must be descendant of 'InternalViewModel'");

                    // TODO: Da riattivare dopo il controllo di esistenza della prop nel viewmodel
                    // throw new Error('INVALID PROPERTY');
                    LogService.warn(`Campo '${propertyName}' non trovato nel view model ${this.constructor.name}`);
                }

            } else {
                // TODO: la entity è null, valutare cosa fare
                // Credo che la entity abbia un metodo per creare una entity vuota, di default:
                // la cosa giusta è forse creare un ViewModel passando quella di default
            }

        }

        // TODO: resta il caso ManyToMany: non so come va trattato
    }

    protected async buildInternalCollectionViewModels(isMockedViewModel: boolean) {

        for (const internalCollection of this.domainModelMetaData.internalCollections) {

            // PATCH PER camelCase DEVE ESSERE PascalCase
            const propertyName = MetaDataUtils.toCamelCase(internalCollection.principalPropertyName);
            const collection = this.domainModel[propertyName];

            if (collection !== undefined) {

                // Creo il ViewModel
                const collectionViewModelType = this.getCollectionViewModelType(propertyName);

                // Recupero il tipo del modello della collection dal decoratore @Type
                let collectionModelType = null
                const typeMetadata: TypeMetadata = defaultMetadataStorage.findTypeMetadata(this.domainModelType, propertyName);
                if (typeMetadata && typeMetadata.typeFunction({
                    newObject: new this.domainModelType()
                } as any)) {
                    collectionModelType = typeMetadata.typeFunction({
                        newObject: new this.domainModelType()
                    } as TypeHelpOptions) as ClassType<any>
                }

                // Recupero il modello dell'item della collection dal suo decoratore
                let itemModelType = null;
                if (collectionModelType) {
                    itemModelType = ModelTypeInspector.getValue(collectionModelType);
                }

                // itemModelType = collection;
                if (collectionViewModelType !== undefined) {
                    const ivm = await ViewModelFactory.createCollectionViewModel(
                        collectionViewModelType,
                        collection,
                        this.aggregateMetaData,
                        this.modifiedSubscriber as CoreOrchestratorViewModelInterface,
                        internalCollection,
                        [this.reservedPath, this.reservedName].filter((w) => w?.length > 0).join('.'),
                        isMockedViewModel,
                        itemModelType
                    );
                    this[propertyName] = ivm;
                    this.relationViewModels.set(propertyName, ivm as BaseViewModelInterface);
                    // ivm.collectionChanged.subscribe(() => {
                    //     if ((this.externalRetriever as OrchestratorViewModelInterface).currentState.value !== ViewModelStates.Research) {
                    //         this.validate();
                    //     }
                    // });
                } else {
                    // throw new InvalidOperationException
                    // ("In the class '" + this.GetType().Name + "' the type of property '" + internalRelation.PropertyName + "' must be descendant of 'InternalViewModel'");

                    // TODO: Da riattivare dopo il controllo di esistenza della prop nel viewmodel
                    // throw new Error('INVALID PROPERTY');
                    LogService.warn(`Campo '${propertyName}' non trovato nel view model ${this.constructor.name}`);
                }

                // PropertyInfo viewModelProperty = Properties[internalRelation.PropertyName];
                // if (viewModelProperty.PropertyType.BaseType.UnderlyingSystemType.Name.StartsWith("EntityCollectionViewModel"))
                // {
                //     var cvm = ViewModelFactory.CreateEntityCollectionViewModel
                // (viewModelProperty.PropertyType, (IEntityCollection)entityCollection, Metadata, (IDocumentViewModel)ModifiedSubsciber, internalRelation);
                //     viewModelProperty.SetValue(this, cvm);
                //     ChildViewModel.Add((IViewModel)cvm);
                // }
                // else
                // {
                //     throw new InvalidOperationException
                // ("In the class '" + this.GetType().Name + "' the type of property '"
                //  + internalRelation.PropertyName + "' must be descendant of 'EntityCollectionViewModel'");
                // }
            } else {
                // TODO: la entity è null, valutare cosa fare
                // Credo che la entity abbia un metodo per creare una entity vuota, di default:
                // la cosa giusta è forse creare un ViewModel passando quella di default
            }
        }

        // TODO: resta il caso ManyToMany: non so come va trattato
    }

    protected externalEntityChangedHandler(arg: ExternalDomainModelChangedEventArgs, externalViewModel: ExternalViewModelInterface): void {
        if (this.domainModel) {
            const name = arg.senderName;
            // assegnamento del nuovo domain model esterno
            // this.domainModel[name] = arg.domainModel;
            this.domainModel.setPropertyValue(name, arg.domainModel);

            externalViewModel.validate();

            this._viewModelChangeDebouncer.next();
        }
    }
}

<div class="columns-container">
    <div class="columns-content-header">
        <nts-checkbox [(value)]="!viewModel.allColumnsHide" (valueChange)="viewModel.onAllColumnsHideEvent()"
            [title]="viewModel.selectDeselectAllTitle"></nts-checkbox>
        <input class="search-columns" [(ngModel)]="viewModel.filterText"
            placeholder="{{viewModel.filterTextPlaceholder}}">
    </div>

    <div cdkDropList class="column-list" (cdkDropListDropped)="drop($event)">

        <div class="column-box" *ngFor="let item of filterFunction()" cdkDrag>
            <nts-checkbox (valueChange)="onColumnChangeEvent($event, item)" [value]="item.isVisible">
            </nts-checkbox>
            <div [title]="item.headerTooltip" (click)="debug(item)" class="column-label">{{item.header}}</div>
            <div *ngIf="viewModel.filterText.length === 0" class="column-move-handle" cdkDragHandle>
                <i class="dx-icon-dragvertical"></i>
            </div>
            <div class="column-placeholder" *cdkDragPlaceholder></div>
        </div>
    </div>
</div>

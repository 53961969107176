import { Injectable, StaticProvider } from '@angular/core';

@Injectable()
export class ViewModelLocator {

    private static _registeredDomainModelNameOrchestratorViewModelType = new Map<string, any>();
    private static _registeredOrchestratorViewModelProviders = new Map<any, StaticProvider[]>();
    private static _registeredZoomOrchestratorViewModelProviders: StaticProvider[];
    private static _registeredOrchestratorViewModelWingViewModel = new Map<any, StaticProvider[]>();

    static getOrchestratorViewModelType(domainModelName: string) {
        return ViewModelLocator._registeredDomainModelNameOrchestratorViewModelType.get(domainModelName?.toLowerCase());
    }

    static getOrchestratorViewModelProviders(orchestratorViewModelType: any) {
        return ViewModelLocator._registeredOrchestratorViewModelProviders.get(orchestratorViewModelType);
    }

    static registerOrchestratorViewModelWingViewModel(orchestratorViewModelType: any, wingViewModelType: any) {
        ViewModelLocator._registeredOrchestratorViewModelWingViewModel.set(orchestratorViewModelType, wingViewModelType);
    }

    static getWingVieModelType(orchestratorViewModelType: any): any {
        return ViewModelLocator._registeredOrchestratorViewModelWingViewModel.get(orchestratorViewModelType);
    }

    static registerDomainModelNameOrchestratorViewModel(domainModelName: string, orchestratorViewModelType: any, orchestratorViewModelProviders: StaticProvider[]) {
        ViewModelLocator._registeredDomainModelNameOrchestratorViewModelType.set(domainModelName, orchestratorViewModelType);
        ViewModelLocator._registeredOrchestratorViewModelProviders.set(orchestratorViewModelType, orchestratorViewModelProviders);
    }

    static registerZoomOrchestratorViewModelProviders(providers: StaticProvider[]) {
        ViewModelLocator._registeredZoomOrchestratorViewModelProviders = providers;
    }

    static getZoomOrchestratorViewModelProviders() {
        return ViewModelLocator._registeredZoomOrchestratorViewModelProviders;
    }
}

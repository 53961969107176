<div class="layout-gutter-left"></div>
<div class="layout-content">

    <div class="aside-left-control">
        <div class="aside-left-control-header">

            <div class="actions left-actions">
                <nts-text-button  *ngIf="masterViewModel" (onClicked)="onMasterAreaSelected.emit()" icon="table-rows" [title]="'CMD_NavigationPanel_SelectMasterArea_Description' | NTSTranslate"></nts-text-button>
                <nts-text-button  *ngIf="snapShotListViewModel" (onClicked)="onSnapShotSelected.emit()" icon="history" [title]="'CMD_NavigationPanel_SelectSnapShot_Description' | NTSTranslate"></nts-text-button>
            </div>
            <div class="actions right-actions">
                <nts-text-button (onClicked)="onNavigationPanelCollapsed.emit()" [icon]="'sidebar-collapse'" [title]="'CMD_NavigationPanel_Close_Description' | NTSTranslate"></nts-text-button>
            </div>
            
        </div>
        <div class="aside-left-control-content">
            <nts-master-area-container class="master-area-container" [hidden]="!masterViewModel?.isSelected"
                [masterViewModel]="masterViewModel" *ngIf="masterViewModel">
            </nts-master-area-container>
            <nts-snap-shot-list-container class="snap-shot-list-container" [hidden]="!snapShotListViewModel?.isSelected"
                [snapShotListViewModel]="snapShotListViewModel" *ngIf="snapShotListViewModel">
            </nts-snap-shot-list-container>
        </div>
    </div>

</div>
<div class="layout-gutter-right"></div>
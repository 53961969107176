import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { Message, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { FilledButtonComponent } from '../../shared/buttons/filled-button/filled-button.component';
import { AsyncPipe, CommonModule, NgFor, NgIf } from '@angular/common';
import { UICommandInterface } from '../../../view-models';

@Component({
  selector: 'nts-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ToastModule,
    SvgIconComponent,
    FilledButtonComponent,
    NgIf,
    CommonModule,
    AsyncPipe
  ]
})
export class ToastMessageComponent {

  constructor(
    private messageService: MessageService,
  ) {

  }

  async onCommandExecute(message: Message, key: string, commandData: {command: UICommandInterface, data: any}) {
    await commandData.command.execute(commandData.data);
    message.data.commandExecuted?.next(commandData.command);
    this.messageService.clear(key);
  }

  // TODO fare porting alla versione con i comandi
  onConfirmUpdate(message: Message, key: string) {
    message.data.confirmEvent?.next(message);
    this.messageService.clear(key);
  }

  onReject(key: string) {
    this.messageService.clear(key);
  }
}

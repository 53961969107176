import { BaseMessage } from '../messages/base-message';
import { Information } from '../messages/information';
import { BaseError } from '../messages/base-error';

export enum SourceMessage {
    Validation,
    ValidationDecode,
    Api,
    ViewModel
}

export class MessageContainer {

    private messageValidate: string;

    constructor(private _baseMessage: BaseMessage, private _sourceMessage: SourceMessage, private _uniqueId?: string) {

    }

    static fromBaseMessage(baseMessage: BaseMessage, uniqueId: string) {
        return new MessageContainer(baseMessage, SourceMessage.Api, uniqueId);
    }

    static fromBaseAndSourceMessage(baseMessage: BaseMessage, sourceMessage: SourceMessage, uniqueId: string) {
        return new MessageContainer(baseMessage, sourceMessage, uniqueId);
    }

    // Messaggi di errore visibili con il bordo rosso e tooltip
    // quelli da rendere in rosso nell'area messaggi
    get isErrorMessage(): boolean {
        return this._baseMessage.type === 'error';
    }

    get isInformationMessage(): boolean {
        return this._baseMessage.type === 'information';
    }

    get isValidationMessage() {
        return this._sourceMessage === SourceMessage.Validation;
    }

    get uniqueId(): string {
        return this._uniqueId;
    }

    get propertyName() {
        return this._baseMessage ? this._baseMessage.propertyName : '';
    }

    get objectName() {
        return this._baseMessage ? this._baseMessage.objectName : '';
    }

    get sourceMessage() {
        return this._sourceMessage;
    }

    get baseMessage() {
        return this._baseMessage;
    }

    get message(): string {
        if (this._baseMessage != null) {
            return this._baseMessage.description;
        }
        return this.messageValidate;
    }

    contains(msg: BaseMessage) {

        if (this._baseMessage != null) {

            if (msg instanceof BaseError &&
                this._baseMessage.description == msg.description) {
                return true;
            }

            if ((this._baseMessage.objectName || msg.objectName)
                && (this._baseMessage.objectName !== msg.objectName)) {
                return false;
            }

            if (msg instanceof Information &&
                this._baseMessage.code !== msg.code) {
                return false;
            }

            if (msg instanceof BaseError &&
                this._baseMessage.description !== msg.description) {
                return false;
            }

            if ((this._baseMessage.propertyName || msg.propertyName)
                &&
                (this._baseMessage.propertyName !== msg.propertyName)) {
                return false;
            }

            return true;

        }

        return false;

    }
}

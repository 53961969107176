import { ToastMessageService } from './../components/layout/toast-message/toast-message.service';
import { AuthService } from './../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { StaticProvider } from '@angular/core';
import { ProvidersForApiClient } from '../api-clients/providers-for-api-client';
import { ModalService } from './modal/modal.service';
import { ProvidersForServiceAgent } from '../api-clients/providers-for-service-agent';
import { ZoomApiClient } from '../api-clients/zoom/zoom-api-client';
import { ZoomOrchestratorViewModel } from './zoom/zoom-orchestrator-view-model';
import { WebApiServiceAgent } from '../api-clients/web-api-service-agent';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { OnlineService } from '@nts/std/src/lib/utility';
import { ResponseCacheService } from '../responses/response-cache.service';
import { ZoomAvailablesFilterPipe } from '../components/zoom/zoom-availables-filter.pipe.ts';

export class ProvidersForViewModel {

    static staticProvidersForOrchestratorViewModel(
        orchestratorViewModelType: any,
        apiClientType: any,
        modalServiceProvider,
        httpClient: HttpClient,
        environmentConfiguration: EnvironmentConfiguration,
        authService: AuthService,
        toastMessageService: ToastMessageService,
        onlineService: OnlineService,
        responseCacheService: ResponseCacheService,
        webApiServiceAgent: WebApiServiceAgent
    ): StaticProvider[] {
        return [
            ...ProvidersForApiClient.staticProvidersForApiClient(
                apiClientType,
                httpClient,
                environmentConfiguration,
                authService,
                onlineService,
                responseCacheService,
                webApiServiceAgent
            ),
            {
                provide: AuthService,
                useValue: authService
            },
            {
                provide: ModalService,
                useValue: modalServiceProvider,
            },
            {
                provide: ToastMessageService,
                useValue: toastMessageService,
            },
            {
                provide: orchestratorViewModelType,
                deps: [apiClientType, ModalService, EnvironmentConfiguration, AuthService, ToastMessageService, OnlineService]
            }
        ];
    }

    static staticProvidersForZoomOrchestratorViewModel(
        modalService: ModalService,
        httpClient: HttpClient,
        environmentConfiguration: EnvironmentConfiguration,
        authService: AuthService,
        toastMessageService: ToastMessageService,
        onlineService: OnlineService,
        responseCacheService: ResponseCacheService,
        webApiServiceAgent: WebApiServiceAgent
    ): StaticProvider[] {
        return [
            {
                provide: ZoomAvailablesFilterPipe
            },
            {
                provide: ToastMessageService,
                useValue: toastMessageService,
            },
            {
                provide: ZoomOrchestratorViewModel,
                deps: [ZoomApiClient, ModalService, ToastMessageService, ZoomAvailablesFilterPipe]
            },
            {
                provide: ZoomApiClient,
                deps: [WebApiServiceAgent]
            },
            {
                provide: ModalService,
                useValue: modalService,
                deps: []
            },
            ...ProvidersForServiceAgent.allStatic(
                httpClient, 
                environmentConfiguration, 
                authService, 
                onlineService,
                responseCacheService,
                webApiServiceAgent
            )
        ];
    }
}

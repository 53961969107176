import { ValidationArguments } from 'class-validator';
import { MessageCodes } from '../../../resources/message-codes';
import { BaseValidation } from './base-validation';

export class EmailValidation extends BaseValidation<string> {

    constructor() {
        super(MessageCodes.PropertyEmailNotValid);
    }

    validateImplementation(value: string, args: ValidationArguments): boolean {
        const isValid = this.isEmail(value);
        if (!isValid) {
            this.errorMessage = this.getMessageWithTag(args, MessageCodes.PropertyEmailNotValid_TAG_NomeCampo);
        }
        return isValid
    }

    private isEmail(value: string): boolean {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    }
}

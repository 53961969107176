import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'menuListNewFilter',
    standalone: true,
})
export class MenuListNewFilterPipe implements PipeTransform {

    transform(list: any[], filterText: string): any {
        if (!filterText || filterText.length === 0) {
            return list;
        }
        return list ? list.filter(item => this.recursiveSearch([item], filterText)) : [];
    }

    private recursiveSearch(items: any[], filterText: string) {
        let found = false;
        items.some((i) => {
            found = (i.label.search(new RegExp(filterText, 'i')) > -1 && (!i.children || i.children.length === 0)) ||
                (i.children?.length > 0 ? this.recursiveSearch(i.children, filterText) : false);
            return found;
        });
        return found;
    }

}

import { UICommandInterface } from './ui-command.interface';
import { MessageResourceManager } from '../../resources/message-resource-manager';
import { UICommandSettingsManagerInterface } from './ui-command-settings-manager.interface';

export class RelatedUICommandSettingsManager implements UICommandSettingsManagerInterface {

    setUICommand(en: string, ui: UICommandInterface): UICommandInterface {

        ui.displayName = MessageResourceManager.Current.getMessage('CMD_' + en);
        ui.name = en;
        ui.tooltip = ui.displayName;
        return ui;
    }
}

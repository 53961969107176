import { UIStarter } from '../starter/ui-starter';
import { LogService } from '@nts/std/src/lib/utility';

export class PresentationCache {

    private static _registeredPresentation = new Map<string, string>();

    static async registerRelated(relatedDomainModelFullName: string): Promise<boolean> {
        const result = await PresentationCache.addIfNotExist(relatedDomainModelFullName);
        LogService.debug('PresentationCache registerRelated completed', result);
        return result;
    }

    static async registerCrudQ(domainModelFullName: string): Promise<boolean> {
        const result = await PresentationCache.addIfNotExist(domainModelFullName);
        LogService.debug('PresentationCache registerCrudQ completed', result);
        return result;
    }

    static async registerLongOp(domainModelFullName: string): Promise<boolean> {
        const result = await PresentationCache.addIfNotExist(domainModelFullName);
        LogService.debug('PresentationCache LongOp completed', result);
        return result;
    }

    static async registerActivity(domainModelFullName: string): Promise<boolean> {
        const result = await PresentationCache.addIfNotExist(domainModelFullName);
        LogService.debug('PresentationCache registerActivity completed', result);
        return result;
    }

    /**
     * Verifica se è già stata fatta la richiesta a find ui info per il domain model full name passato.
     * Nel caso in cui non fosse stata ancora fatta la richiesta, procede a richiedere a find ui info l'url.
     * 
     * @param domainModelFullName domain model full name to use with find ui info
     * @returns true if start client url is found otherwise return false
     */
    static async addIfNotExist(domainModelFullName: string): Promise<boolean> {
        if (!PresentationCache.contains(domainModelFullName)) {
            const result = await UIStarter.findStartClient(domainModelFullName);
            if (result?.length) {
                PresentationCache._registeredPresentation.set(domainModelFullName, result);
            }
            return result?.length > 0;
        }
        return true;
    }

    static async set(domainModelFullName: string, address: string): Promise<boolean> {
        PresentationCache._registeredPresentation.set(domainModelFullName, address.toLowerCase());
        return true;
    }

    static contains(domainModelFullName: string): boolean {
        return PresentationCache._registeredPresentation.has(domainModelFullName);
    }

    static get(domainModelFullName: string): string {
        return PresentationCache._registeredPresentation
            // Remove duplicated slash
            .get(domainModelFullName)?.replace(/([^:]\/)\/+/g, "$1");
    }
}

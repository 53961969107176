import { ZoomFilterCoreComponentsModule } from './core';
import { ZoomContainerComponent } from './zoom-container/zoom-container.component';
import { NgModule } from '@angular/core';
import { ZoomListComponent } from './zoom-list/zoom-list.component';
import { ZoomParametersComponent } from './zoom-parameters/zoom-parameters.component';
import { ZoomParametersMobileComponent } from './zoom-parameters-mobile/zoom-parameters-mobile.component';
import { ZoomParametersSortingGroupsComponent } from './zoom-parameters-sorting-groups/zoom-parameters-sorting-groups.component';
import { ZoomResultsComponent } from './zoom-results/zoom-results.component';


@NgModule({
    imports: [
       ZoomFilterCoreComponentsModule,
       ZoomContainerComponent,
       ZoomListComponent,
       ZoomParametersComponent,
       ZoomParametersMobileComponent,
       ZoomParametersSortingGroupsComponent,
       ZoomResultsComponent
    ],
    exports: [
        ZoomFilterCoreComponentsModule,
        ZoomContainerComponent,
        ZoomListComponent,
        ZoomParametersComponent,
        ZoomParametersMobileComponent,
        ZoomParametersSortingGroupsComponent,
        ZoomResultsComponent
    ]
})
export class ZoomComponentModule {

}

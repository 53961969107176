import { OrderByType } from '../../domain-models/autocomplete/auto-complete-options';
import { ColumnInfo, SettingsType } from '../column-info';

export class ZoomColumnInfo extends ColumnInfo {

    orderIndex: number;
    orderByIndex?: number;
    orderBy?: OrderByType;

    constructor(propertyName: string, orderIndex: number) {
        super(propertyName);
        this.settings = SettingsType.Default;
        this.orderIndex = orderIndex;
    }

    override resourceName(): string {

        if (this.settings === SettingsType.PropertyType) {
            return this.propertyTypeName;
        }

        // TODO: Verificare il toString()
        return this.settings.toString();
    }
}


import { Expose, Transform } from '../serialization/class-transformer/decorators';
import { DateTimeOffset } from './date-time-offset';

export class SnapShotFrameItem {

    @Expose()
    identity: number;

    @Expose()
    updateUser: string;

    @Expose()
    @Transform((value, obj, type) => {
        return DateTimeOffset.transform('updateDate', value, obj, type);
    })
    updateDate: Date;

    @Expose()
    creationUser: string;

    @Expose()
    @Transform((value, obj, type) => {
        return DateTimeOffset.transform('creationDate', value, obj, type);
    })
    creationDate: Date;

    @Expose()
    tenantId: number;
}

<div class="content">
    <svg-icon key="error-403"></svg-icon>
    <div class="error-title">{{errorTitle}}</div>
    <div class="error-description">{{errorDescription}}</div>
    <div class="more-info">
        <h3>{{'std_PageError_DetailTitle' | NTSTranslate}}:&nbsp;</h3>
        <p>{{errorDetailDescription}}<span *ngIf="showDetailMore && !expandDetailMore">...</span><span
                *ngIf="expandDetailMore">{{errorDetailMoreDescription}}</span></p>
    </div>

    <nts-filled-button [type]="3" [title]="'std_ChangeAccountRequest' | NTSTranslate"
        [label]="'std_ChangeAccountRequest' | NTSTranslate" (onClicked)="logOut()">
    </nts-filled-button>
</div>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { EditZoomModalViewModel } from '../../../../../view-models/zoom/zoom-list-view-model/modals/edit-zoom-modal-view-model';
import { ModalComponentInterface } from '../../../../modal/modal-component.interface';
import { LabelledTextBoxComponent } from '../../../../controls/labelled-text-box/labelled-text-box.component';

@Component({
    selector: 'nts-edit-zoom-modal-container',
    templateUrl: 'edit-zoom-modal-container.component.html',
    styleUrls: ['./edit-zoom-modal-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        LabelledTextBoxComponent
    ]
})
export class EditZoomModalContainerComponent implements ModalComponentInterface {

    viewModel: EditZoomModalViewModel;

    public async initialize(): Promise<void> {
        await this.viewModel.initialize();
    }

    constructor(
        private cd: ChangeDetectorRef
    ) { }
}

import { NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import { MenuListNewFilterPipe } from './menu-list-new-filter.pipe';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'nts-menu-list-new',
  templateUrl: './menu-list-new.component.html',
  styleUrls: ['./menu-list-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    MenuListNewFilterPipe,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    RouterLink
  ]
})
export class MenuListNewComponent implements OnInit {

  @Input() searchModel = '';
  @Input() menuList = [];
  @Output() selectMenu = new EventEmitter<void>();

  constructor(
  ) {}

  ngOnInit(): void {
  }

  getMenu() {
    if (this.searchModel && this.searchModel.length > 0) {
      return cloneDeep(this.menuList).map((i) => {
        this.recursiveExpandMenu([i]);
        return i;
      });
    } else {
      return this.menuList;
    }
  }

  private recursiveExpandMenu(menuItems: any[]) {
    menuItems.forEach((i) => {
      i.isCollapsed = false;
      if (i.children?.length > 0) {
        this.recursiveExpandMenu(i.children);
      }
    });
  }

  getBeforeMatched(menu) {
    const index = menu.label.toLowerCase().indexOf(this.searchModel.toLowerCase());
    if (index === -1) {
      return '';
    }
    return menu.label.substring(0, index);
  }

  getMatched(menu) {
    const index = menu.label.toLowerCase().indexOf(this.searchModel.toLowerCase());
    if (index === -1) {
      return '';
    }
    return menu.label.substring(index, index + this.searchModel.length);
  }

  getAfterMatched(menu) {
    const index = menu.label.toLowerCase().indexOf(this.searchModel.toLowerCase());
    if (index === -1) {
      return menu.label;
    }
    return menu.label.substring(index + this.searchModel.length);
  }
}

import { NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ListFilterPipe } from './menu-list-filter.pipe';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    ListFilterPipe,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault

  ]
})
export class MenuListComponent implements OnInit {

  menuList = [];
  currentMenu = null;

  @Input() searchModel = '';
  @Output() selectMenu = new EventEmitter<void>();

  constructor(
  ) {
    this.menuList = [
      {
        label: 'Modulo',
        route: '/manage/modules',
        isActive: false,
        hasActiveChild: false
      },
      {
        label: 'Prodotto',
        route: '/manage/prodotto',
        isActive: false,
        hasActiveChild: false
      },
      {
        label: 'Tenant',
        route: '/manage/tenant',
        isActive: false,
        hasActiveChild: false
      },
      {
        label: 'Service Info',
        route: '/manage/serviceinfo',
        isActive: false,
        hasActiveChild: false
      }
    ];
  }

  ngOnInit(): void {
  }

  getBeforeMatched(menu) {
    const index = menu.label.toLowerCase().indexOf(this.searchModel.toLowerCase());
    return menu.label.substring(0, index);
  }

  getMatched(menu) {
    const index = menu.label.toLowerCase().indexOf(this.searchModel.toLowerCase());
    return menu.label.substring(index, index + this.searchModel.length);
  }

  getAfterMatched(menu) {
    const index = menu.label.toLowerCase().indexOf(this.searchModel.toLowerCase());
    return menu.label.substring(index + this.searchModel.length);
  }

  onSelect(menu: any): void {
    this.currentMenu = menu;
    this.selectMenu.emit(menu);
  }

}

import { ZoomPropertyViewModel } from './zoom-property-view-model';
import { MessageContainer } from '../../message-container';
import { NumericMetaData } from '../../../meta-data/numeric-meta-data';
import { ModifiedSubscriberInterface } from '../../modified-subscriber.interface';
import { BaseNumericPropertyViewModel } from '../../base-type/base-numeric-property-view-model';

export class NumericZoomPropertyViewModel extends ZoomPropertyViewModel<number> {
  
  metaDataMinValue: number;
  metaDataMaxValue: number;
  metaDataPrecision: number;
  metaDataScale: number;
  mask: string;
  format: string;
  decimalLimit: number;
  integerLimit: number;
  useThousandSeparator = false;

  constructor(propertyMetaData: NumericMetaData, modifiedSubscriber: ModifiedSubscriberInterface) {
    super(propertyMetaData, modifiedSubscriber);

    this.metaDataMinValue = propertyMetaData.minValue;
    this.metaDataMaxValue = propertyMetaData.maxValue;

    if (propertyMetaData.maxIntegerPrecision == null) {
      propertyMetaData.maxIntegerPrecision = 10;
    }

    this.decimalLimit = propertyMetaData.maxDecimalPrecision;
    this.integerLimit = propertyMetaData.maxIntegerPrecision;

    if (this.decimalLimit > 0) {
      this.useThousandSeparator = true;
    }

    this.format = BaseNumericPropertyViewModel.getNumericFormat(this.integerLimit, this.decimalLimit, this.useThousandSeparator, propertyMetaData.name);
  }

  override removeError(item: MessageContainer) {
    throw new Error("Method not implemented.");
  }

  setValue(propertyValue: Object) {
    if (propertyValue === null) {
      this.typedValue = null;
    } else {
      let v = parseFloat(propertyValue.toString());
      // Se è maggiore dell'int, imposto l'int max value.
      // Non ho però modo di capire quando è short.
      if (v > 2147483647) {
        v = 2147483647;
      }
      this.typedValue = v;
    }
  }
}

import { ZoomPropertyViewModel } from './zoom-property-view-model';
import { MessageContainer } from '../../message-container';
import { CharacterCasing } from '../../base-type/string-property-view-model';
import { AllowedChars } from '../../../meta-data/allowed-chars';
import { StringMetaData } from '../../../meta-data/string-meta-data';
import { ModifiedSubscriberInterface } from '../../modified-subscriber.interface';

export class StringZoomPropertyViewModel extends ZoomPropertyViewModel<string> {

    metaDataCharacterCasing: CharacterCasing;
    metaDataAllowedCharacters: AllowedChars;
    metaDataMaxLength: number;

    constructor(propertyMetaData: StringMetaData, modifiedSubscriber: ModifiedSubscriberInterface) {

        super(propertyMetaData, modifiedSubscriber);

        this.metaDataAllowedCharacters = propertyMetaData.allowedCharacters !== undefined ? propertyMetaData.allowedCharacters : AllowedChars.Any;
        this.metaDataMaxLength = propertyMetaData.maxLen;
        this.metaDataCharacterCasing = CharacterCasing.Normal;

        if (this.metaDataAllowedCharacters === AllowedChars.UppercaseLettersOnly ||
            this.metaDataAllowedCharacters === AllowedChars.UppercaseLettersOrNumbersOnly) {
            this.metaDataCharacterCasing = CharacterCasing.Upper;
        } else if (this.metaDataAllowedCharacters === AllowedChars.LowercaseLettersOnly ||
            this.metaDataAllowedCharacters === AllowedChars.LowercaseLettersOrNumbersOnly) {
            this.metaDataCharacterCasing = CharacterCasing.Lower;
        }

    }

    override removeError(item: MessageContainer) {
        throw new Error("Method not implemented.");
    }

    override resetValue() {
        this.typedValue = null;
    }

    setValue(propertyValue: Object) {
        if (propertyValue === null) {
            this.typedValue = null;
        } else {
            // TODO
            // let ic = propertyValue as IConvertible;
            // if (ic == null)
            // {
            //     throw new ArgumentException();
            // }

            // this.typedValue = ic.ToString(CultureInfo.InvariantCulture);
            this.typedValue = String(propertyValue);
        }
    }    
}

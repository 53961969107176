import { Input, Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Subject, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseNumericPropertyViewModel } from '../../view-models/base-type/base-numeric-property-view-model';
import { BaseBoolPropertyViewModel } from '../../view-models/base-type/bool-property-view-model';
import { BaseDateTimeOffsetPropertyViewModel } from '../../view-models/base-type/date-time-offset-property-view-model';
import { BaseDateTimePropertyViewModel } from '../../view-models/base-type/date-time-property-view-model';
import { BaseEnumPropertyViewModel } from '../../view-models/base-type/enum-property-view-model';
import { BaseOffsetPropertyViewModel } from '../../view-models/base-type/offset-property-view-model';
import { StringPropertyViewModel } from '../../view-models/base-type/string-property-view-model';
import { ExternalViewModel } from '../../view-models/external-view-model';
import { MasterViewModelInterface } from '../../view-models/master-view-model.interface';
import { TextButtonComponent } from '../shared/buttons/text-button/text-button.component';
import { LoaderComponent } from '../shared/loader/loader.component';
import { SearchTextBoxComponent } from '../controls/search-text-box/search-text-box.component';
import { LabelBoxComponent } from '../controls/core/base/label-box/label-box.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { StringTextBoxComponent } from '../controls/core/string-text-box/string-text-box.component';
import { NumericTextBoxComponent } from '../controls/core/numeric-text-box/numeric-text-box.component';
import { EnumTextBoxComponent } from '../controls/core/enum-text-box/enum-text-box.component';
import { ToggleTextBoxComponent } from '../controls/core/toggle-text-box/toggle-text-box.component';
import { DateTextBoxComponent } from '../controls/core/date-text-box/date-text-box.component';
import { DateOffsetTextBoxComponent } from '../controls/core/date-offset-text-box/date-offset-text-box.component';
import { GridComponent } from '../controls/grid/grid.component';
import { ExtNewAutocompleteTextBoxComponent } from '../controls/core/ext-new-autocomplete-text-box/ext-new-autocomplete-text-box.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'nts-master-area-container',
    templateUrl: './master-area-container.component.html',
    styleUrls: ['./master-area-container.component.scss'],
    standalone: true,
    imports: [
        TextButtonComponent,
        LoaderComponent,
        SearchTextBoxComponent,
        LabelBoxComponent,
        NgFor,
        NgIf,
        StringTextBoxComponent,
        NumericTextBoxComponent,
        EnumTextBoxComponent,
        ToggleTextBoxComponent,
        DateTextBoxComponent,
        DateOffsetTextBoxComponent,
        ExtNewAutocompleteTextBoxComponent,
        GridComponent,
        AsyncPipe
    ]
})
export class MasterAreaContainerComponent implements OnInit, OnDestroy {

    gridOptions: GridOptions;
    private destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private _cd: ChangeDetectorRef
    ) { }

    @Input() masterViewModel: MasterViewModelInterface;

    ngOnInit() {
        if (this.masterViewModel.searchPropertyNames.length === 0) {
            throw new Error('You must set searchPropertyNames!');
        }

        merge(
            this.masterViewModel.searchCompleted,
            this.masterViewModel.selectionChanged,
            this.masterViewModel.actionInProgress$
        ).pipe(takeUntil(this.destroy$)).subscribe(
            () => {
                 this._cd.detectChanges() 
            }
        );

        this.masterViewModel.selectionUpdated.pipe(takeUntil(this.destroy$)).subscribe((vm) => {
            const selectedNodes = this.gridOptions.api.getSelectedNodes();
            if (selectedNodes?.length === 1) {
                selectedNodes[0].updateData(vm);
            }
            this._cd.detectChanges() 
        })

        // Resetto lo scroll alla prima riga se è il primo caricamento
        this.masterViewModel.searchCompleted.pipe(takeUntil(this.destroy$)).subscribe((firstLoad) => {
            if (this.gridOptions && this.masterViewModel.searchResult.length > 0 && firstLoad) {
                this.gridOptions.api.ensureIndexVisible(0);
            }
        })
    }

    // TODO Tommy per il momento utilizziamo questo metodo altrimenti quando avremo la collection di item vm nel master view model
    // si utilizzerà il getter nella item vm

    ngOnDestroy() {
        this.destroy$.next(true);
        // Now let's also unsubscribe from the subject itself:
        this.destroy$.unsubscribe();
    }

    // Utilizzata per il full screen
    // Se si imposta a false, automaticamnte viene fatta la getbyidentity della selezione che c'era in full screen
    // Questa logica è nel master view model
    selectRow() {
        this.masterViewModel.searchResult.exitFocusGridCommand.execute();
        this._cd.detectChanges();
    }

    isString(filter) {
        return filter instanceof StringPropertyViewModel;
    }

    isDate(filter) {
        return filter instanceof BaseDateTimePropertyViewModel;
    }

    isNumeric(filter) {
        return filter instanceof BaseNumericPropertyViewModel;
    }

    isOffset(filter) {
        return filter instanceof BaseOffsetPropertyViewModel;
    }

    isDateTimeOffset(filter) {
        return filter instanceof BaseDateTimeOffsetPropertyViewModel;
    }

    isBool(filter) {
        return filter instanceof BaseBoolPropertyViewModel;
    }

    isEnum(filter) {
        return filter instanceof BaseEnumPropertyViewModel;
    }

    isExternal(filter) {
        return filter instanceof ExternalViewModel;
    }

    toggleMasterAreaVisibility() {
        this.masterViewModel.isSelected = !this.masterViewModel.isSelected
    }

    onGridOptionsReady(e) {
        this.gridOptions = e;
        this.masterViewModel.onSearchGridOptionsReady$.next(e)
    }
}

import { Expose, Type } from "../../serialization/class-transformer/decorators";
import { LayoutDefinitionIdentity } from "./layout-definition.identity";

export class GetAvailableLayoutsDataDto {

    constructor() {
        this.requestedMetaDataLayoutIdentity = new LayoutDefinitionIdentity();
    }

    @Expose()
    @Type(() => LayoutDefinitionIdentity)
    requestedMetaDataLayoutIdentity: LayoutDefinitionIdentity;

}

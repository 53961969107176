import { BaseIdentity } from '../domain-models/base-identity';
import { AggregateElementViewModel } from './aggregate-element-view-model';
import { CoreOrchestratorViewModelInterface } from './core-orchestrator-view-model.interface';
import { AggregateMetaData } from '../meta-data/aggregate-meta-data';
import { CollectionViewModelInterface } from './collection-view-model.interface';
import { ItemViewModelInterface } from './item-view-model.interface';
import { CoreModel } from '../domain-models';
import { ClassInformationInterface, ClassInformationType } from '@nts/std/src/lib/utility';
import { ClassType } from '../serialization/class-transformer/ClassTransformer';

export class ItemViewModel<
    TModel extends CoreModel<TIdentity>,
    TIdentity extends BaseIdentity>
    extends AggregateElementViewModel<TModel, TIdentity> implements ItemViewModelInterface, ClassInformationInterface {

    classType = ClassInformationType.ItemViewModel;

    async initItemViewModel(
        domainModel: TModel,
        metadata: AggregateMetaData,
        orchestratorViewModel: CoreOrchestratorViewModelInterface,
        ownerCollection?: CollectionViewModelInterface<ItemViewModel<TModel, TIdentity>>,
        isNewItem?: boolean,
        path: string = '',
        isMockedViewModel = false,
        domainModelType: ClassType<TModel> = null
    ) {
        this.reservedPath = path;
        this.reservedName = '';
        this.isMock = isMockedViewModel;
        
        await this.initAggregateElementViewModel(
            domainModel, 
            metadata, 
            orchestratorViewModel, 
            undefined, 
            this.isMock,
            domainModelType
        );
    }
}

import { MetaDataDescriptions } from '../meta-data/meta-data-descriptions';
import { Expose, Type } from '../serialization/class-transformer';
import { CustomFieldMetaData } from './custom-field-meta-data';
import { ExternalLayoutMetaData } from './external-layout-meta-data';
import { FieldMetaData } from './field-meta-data';
import { FieldTypes } from './field-types.enum';
import { GridMetaData } from './grid-meta-data';
import { PanelState } from './panel-state.enum';
import { SimpleFieldMetaData } from './simple-field-meta-data';
import { SpacerMetaData } from './spacer-meta-data';

export class PanelMetaData {

    constructor() {
        this.descriptions = new MetaDataDescriptions();

        this.simpleFields = [];
        this.spacers = [];
        this.grids = [];
        this.externals = [];
        this.customFields = [];
        this.promotedFields = [];
        this.fieldType = FieldTypes.Panel;
    }

    @Expose()
    panelState: PanelState;

    @Expose()
    fieldType: FieldTypes;

    @Expose()
    isVisible: boolean;

    @Expose()
    @Type(() => MetaDataDescriptions)
    descriptions: MetaDataDescriptions;

    @Expose()
    @Type(() => SimpleFieldMetaData)
    simpleFields: SimpleFieldMetaData[];

    @Expose()
    @Type(() => SpacerMetaData)
    spacers: SpacerMetaData[];

    @Expose()
    @Type(() => GridMetaData)
    grids: GridMetaData[];

    @Expose()
    @Type(() => ExternalLayoutMetaData)
    externals: ExternalLayoutMetaData[];

    @Expose()
    @Type(() => CustomFieldMetaData)
    customFields: CustomFieldMetaData[];

    @Expose()
    promotedFields: string[];

    @Expose()
    position: number;

    @Expose()
    panelId: string;

    @Expose()
    get additionalFields(): string[] {
        return [...this.simpleFields, ...this.grids, ...this.externals]
            .filter((field) => field?.isAdditionalField == true)
            .map((field) => field?.path?.length > 0 ? field.path + '.' + field.name : field.name)
    }

    @Expose()
    get sortedFields(): FieldMetaData[] {
        return [...this.spacers, ...this.simpleFields, ...this.grids, ...this.externals, ...this.customFields].sort((a, b) => a.position - b.position);
    }
}

export enum ZoomStarterMode {
  
  // Non impostato
  NotSet = 0,
  
  // Zoom avviata con F6
  F6 = 1,

  // Zoom avviata dalla Dashboard
  Dashboard = 2,

  // Zoom avviata da un'altro Zoom
  Zoom = 3,

  // Zoom avviata con F5 (dalla maschera principale)
  F5 = 4,
}

import { Expose } from '../serialization/class-transformer/decorators';
import { NumberDecorator } from './decorators/number.decorator';
import { OCCAuditDeactivableModel } from './occ-audit-deactivable-model';
import { SingleAggregateIdentity } from './single-aggregate-identity';


export class SingleAggregateAuditDeactivableModel<TIdentity extends SingleAggregateIdentity>
    extends OCCAuditDeactivableModel<TIdentity>  {
    private _fixedIdentity: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'SingleAggregateModel_FixedIdentity' })
    get fixedIdentity(): number {
        return this.getValueForIdentityValue<number>(() => this._fixedIdentity, 'fixedIdentity');
    }
    set fixedIdentity(value: number) {
        this.setValueForIdentityValue<number>(() => { this._fixedIdentity = value; }, value, 'fixedIdentity');
    }
}

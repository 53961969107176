import { ModifiedSubscriberInterface } from '../../modified-subscriber.interface';
import { BasePropertyViewModel } from '../../base-property-view-model';
import { ZoomPropertyViewModelInterface } from './zoom-property-view-model.interface';
import { EventEmitter } from '@angular/core';
import { MessageResourceManager } from '../../../resources/message-resource-manager';
import { DescriptionsMetaData, NameMetaData } from '../../../meta-data';

export abstract class ZoomPropertyViewModel<T> extends BasePropertyViewModel implements ZoomPropertyViewModelInterface<T> {

    protected modifiedSubscriber: ModifiedSubscriberInterface;
    enabled!: boolean;

    focus = new EventEmitter();

    private val!: T|null;

    isToUserValidation = false;

    abstract setValue(propertyValue: T): void;

    get typedValue(): T|null {
        return this.val;
    }
    set typedValue(valueToSet: T|null) {
        if (this.val != valueToSet) {
            this.val = valueToSet;
            this.onPropertyChanged('value');
            this.modifiedSubscriber.notifyModified();
        }        
    }

    constructor(propertyMetaData: NameMetaData & DescriptionsMetaData, modifiedSubscriber: ModifiedSubscriberInterface) {
        super(propertyMetaData.name);
        this.metadataDescription = propertyMetaData.descriptions.description ?? MessageResourceManager.Current.getMessage(propertyMetaData.descriptions.descriptionKey);
        this.metadataShortDescription = propertyMetaData.descriptions.displayName ??MessageResourceManager.Current.getMessage(propertyMetaData.descriptions.displayNameKey);
        this.modifiedSubscriber = modifiedSubscriber;
    }

    get value(): T {
        return this.val;
    }

    resetValue() {
        // TODO
        this.typedValue = null;
    }

    setEnabled(enabled: boolean) {
        // NOTA: non uso la property Value, ma direttamente il backing-field perchè non voglio
        // scatenare la chiamata a NotifyModified; infatti questo metodo viene richiamato
        // da una modifica all'operatore dello zoom che già fa una NotifyModified
        this.enabled = enabled;
        this.propertyViewModelChanged.emit();
        // OnPropertyChanged("Enabled");

        if (!enabled) {
            // this.val = default(T);
            // OnPropertyChanged("Value");
        }
    }

    setFocus() {
        this.focus.emit();
    }

}


import { Injectable } from '@angular/core';
import { LogConsole } from './log-console';
import { LogPublisher } from './log-publisher';


@Injectable({
    providedIn: 'root'
})
export class LogPublishersService {
    constructor() {
        // Build publishers arrays
        this.buildPublishers();
    }

    publishers: LogPublisher[] = [];

    // Build publishers array
    buildPublishers(): void {
        // Create instance of LogConsole Class
        this.publishers.push(new LogConsole());

        // // Create instance of LogLocalStorage Class
        // this.publishers.push(new LogLocalStorage());
    }
}

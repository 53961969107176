import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BaseZoomFilterPropertyTextBox } from '../base-zoom-filter-property-text-box';
import { NumericMaskSettings } from '../../../controls/core/base/base-numeric-box/base-numeric-box.component';
import { BaseNumericPropertyViewModel } from '../../../../view-models/base-type/base-numeric-property-view-model';
import { AsyncPipe, NgIf } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ExternalZoomFilterViewModel } from '../../../../view-models/zoom/filter-view-model/external-zoom-filter-view-model';
import { BaseExternalBoxComponent, ExternalBoxCommandUtility } from '../../../controls/core/base/base-external-box/base-external-box.component';
import { ExternalZoomPropertyViewModel } from '../../../../view-models/zoom/property-view-model/external-zoom-property-view-model';
import { BehaviorSubject, Observable, Subject, map, merge, of, startWith, takeUntil } from 'rxjs';
import { AutoCompleteExternalOptions } from '../../../../domain-models/autocomplete/auto-complete-external-options';
import { MetaDataUtils } from '../../../../meta-data/meta-data-utils';
import { MessageResourceManager } from '../../../../resources/message-resource-manager';
import { UICommandInterface } from './../../../../view-models/commands/ui-command.interface';
import { UICommandSettingsManager } from './../../../../view-models/commands/ui-command-settings-manager';
import { CommandFactory } from './../../../../view-models/commands/command-factory';
import { CommandTypes } from './../../../../view-models/commands/command-types';
import { PresentationCache } from '../../../../cache/presentation-cache';
import { ModalResult } from '../../../../view-models/modal/modal-result';
import { ModalService } from '../../../../view-models/modal/modal.service';
import { Filter, FilterOperators } from '../../../../domain-models/find-options/filter';

@UntilDestroy()
@Component({
  selector: 'nts-zoom-filter-external-text-box',
  styleUrls: ['./zoom-filter-external-text-box.component.scss'],
  templateUrl: './zoom-filter-external-text-box.component.html',
  standalone: true,
  imports: [
    BaseExternalBoxComponent,
    NgIf,
    AsyncPipe
  ]
})
export class ZoomFilterExternalTextBoxComponent extends BaseZoomFilterPropertyTextBox<{ identity: {[key:string]: string|number}; description?: string; isValid?: boolean; }> implements OnInit, AfterViewInit, OnChanges {

  @Input() filter: ExternalZoomFilterViewModel;

  @ViewChild('baseExternalBox', { static: false }) baseExternalBox: BaseExternalBoxComponent;
  
  override propertyViewModel: ExternalZoomPropertyViewModel;
  inputMaskSettings: NumericMaskSettings;
  maxValue: number;
  minValue: number;
  valueForChips = [];
  viewItemCommand: UICommandInterface
  digits: number;
  updatedExternalValue = new BehaviorSubject<{identity: {[key:string]: string|number}, description?: string, isValid?: boolean}>(null);
  destroyOldPropertyViewModelSubscriber = new Subject<void>()
  isDropdownOpen = new BehaviorSubject<boolean>(false);
  placeholder = 'Non definito';
  isLocked = false;
  isLockedMessage = 'Campo bloccato';
  basePlainPascalCaseFixedIdentity: {[key:string]: string|number}|null = null;


  get formattedValue(): string {
    if (this.propertyViewModel.value == null) { return null; }
    return this.propertyViewModel.value.toString().replace('.', BaseNumericPropertyViewModel.getDecimalSeparator());
  }

  protected get input(): HTMLInputElement {
    return this.baseExternalBox?.inputRef.searchInput.nativeElement;
  }

  get inputValue(): { identity: {[key:string]: string|number}; description?: string; isValid?: boolean; } {
    if (this.input) {
        return this.input.value as any;         
    } else {
        return null;
    }
  } 

  constructor(
    private cd: ChangeDetectorRef,
    private modalService: ModalService,
  ) {
    super();
  }

  override ngOnInit() {
    if (!this.propertyViewModel) { throw new Error('Missing viewModel!'); }

    if (this.filter) {
      this.filter.operator.operatorChanged.pipe(untilDestroyed(this)).subscribe(() => {
        // setTimeout(() =>this.setFocus(), 200);
        this.cd.detectChanges();
      })
    }
    
    // this.placeholder = 'Compila prima la ditta!'
    // this.isLocked = true;


    this.updatedExternalValue.next(this.propertyViewModel.value);

    const manager = new UICommandSettingsManager();

    this.viewItemCommand = manager.setUICommand(CommandTypes.ViewItem,
      CommandFactory.createUICommand(
          async (externalUtility: ExternalBoxCommandUtility) => {
            this.startPresentation(externalUtility);
          },
          () => merge(
            this.updatedExternalValue
          ).pipe(
              startWith(null),
              map(() => this.updatedExternalValue.value?.identity != null)
          ),
          // () => this.externalPropertyViewModel.propertyChanged.pipe(
          //     startWith(null),
          //     map(() => this.externalPropertyViewModel.zoomViewIsVisible)),
    ));

    // if (this.filter.parentExternalFilter) {
    //   this.filter.operator.isEnabled = this.filter.parentExternalFilter.operator.value != 
    //   this.filter.parentExternalFilter.operator.operatorChanged.pipe(untilDestroyed(this)).subscribe(async (value: FilterOperators) => {
    //     this.filter.operator.currentValue = value;
    //   });
    // }

    if (this.filter.parentExternalFilter) {
      //   this.filter.operator.isEnabled = this.filter.parentExternalFilter.operator.value != 
        this.filter.parentExternalFilter.operator.operatorChanged.pipe(untilDestroyed(this)).subscribe(async (value: FilterOperators) => {
          if (value === FilterOperators.None) {
            this.filter.operator.currentValue = value;
          }
        });


        this.checkLockedLogics();
        this.checkParentFilterValue();

        this.filter.parentExternalFilter.filterValue.propertyChanged.pipe(untilDestroyed(this)).subscribe(async (value) => {
          this.checkLockedLogics();
          this.checkParentFilterValue();
        })
    }

    this.filter.isEnableStatusChanged.pipe(untilDestroyed(this)).subscribe(() => {
      this.cd.detectChanges();
    });

    this.cd.detectChanges();
  }

  checkParentFilterValue() {
    if (this.filter.parentExternalFilter.filterValue?.value == null) {
      this.propertyViewModel.setValue(null);
      this.basePlainPascalCaseFixedIdentity = null;
    } else {
      if (this.filter.parentExternalFilter.isExternal) {
        const fvm = this.filter.parentExternalFilter as ExternalZoomFilterViewModel;
        if (fvm.filterValue?.value?.identity != null) {
          this.basePlainPascalCaseFixedIdentity = this.getBasePlainPAscalCaseFixedIdentityFromParentExternalZoomFilterViewModel(fvm)
        } else {
          this.basePlainPascalCaseFixedIdentity = null
        }        
      }
    }
  }

  getBasePlainPAscalCaseFixedIdentityFromParentExternalZoomFilterViewModel(fvm: ExternalZoomFilterViewModel): { [key: string]: string | number; }|null {
    const basePlainPascalCaseFixedIdentity = {};
    for (const parentAssociation of fvm.metaData.external.associationProperties) {
      const localAssociation = this.filter.metaData.external.associationProperties.find(a => a.principalPropertyName === parentAssociation.principalPropertyName)
      if (localAssociation) {
        basePlainPascalCaseFixedIdentity[localAssociation.dependentPropertyName] = fvm.filterValue.value.identity[parentAssociation.dependentPropertyName];
      }
    }
    return basePlainPascalCaseFixedIdentity;
  }

  checkLockedLogics() {
    if(this.filter.parentExternalFilter.filterValue.value == null) {
      this.isLocked = true;
      this.isLockedMessage = 'Compila prima: ' + this.filter.parentExternalFilter.propertyNameMap.displayName;
    } else {
      this.isLocked = false;
    }
  }

  protected override registerOnBlurEvent() {
    // Faccio l'ovveride per disibalitare il comportamento standard (blur dell'input)
    // per utilizzare funzione onBlur
  }

  protected override registerOnKeyDownEvent() {
    // Faccio l'ovveride per disibalitare il comportamento standard (OnKeyDown dell'input)
  }

  onBlur() {
    this.updateModel();
  }  

  setFocus() {
    setTimeout(() => this.input.select());
  }

  ngAfterViewInit(): void {   
    super.ngOnInit();    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['propertyViewModel']) {
      if (this.updatedExternalValue.value?.identity != this.propertyViewModel.value?.identity) {
        this.updatedExternalValue.next(this.propertyViewModel.value);
      }      
      this.destroyOldPropertyViewModelSubscriber.next()
      this.propertyViewModel.propertyChanged.pipe(untilDestroyed(this), takeUntil(this.destroyOldPropertyViewModelSubscriber)).subscribe(() => {
        if (this.updatedExternalValue.value?.identity != this.propertyViewModel.value?.identity) {
          this.updatedExternalValue.next(this.propertyViewModel.value);
          this.cd.detectChanges();
        } 
      })
    }    
  }

  calculateMinValue(decimalDigits: number, integerDigits: number): number {
    // const integerDigitsString = "9".repeat(integerDigits);
    // let decimalDigitsString = "";
    // if (decimalDigits > 0) {
    //   decimalDigitsString = "." + "9".repeat(decimalDigits);
    // }
    // return parseFloat(integerDigitsString + decimalDigitsString);
    return 0;
  }

  calculateMaxValue(decimalDigits: number, integerDigits: number): number {
    const integerDigitsString = "9".repeat(integerDigits);
    let decimalDigitsString = "";
    if (decimalDigits > 0) {
      decimalDigitsString = "." + "9".repeat(decimalDigits);
    }
    return parseFloat(integerDigitsString + decimalDigitsString);
  }

  selectAllContent($event: FocusEvent) {
    setTimeout(() => {
      const tgt = (<HTMLInputElement>$event.target);
      if (tgt === document.activeElement) {
        (<HTMLInputElement>$event.target).select();
      }
    }, 50);
  }

  getExactItemFromTermCallback(
    term: string, 
    showCodeInDescription: boolean,
    basePlainPascalCaseFixedIdentity: {[key:string]: string|number}|null
  ): Observable<{ description: string, identity: {[key:string]: string|number}, all: any }> {
      
    // se ho più di un campo nell'identity non posso ricercarlo e non ho una basePlainPascalCaseFixedIdentity
    if (this.filter.metaData.external.dependentAggregateMetaData.rootMetaData.identityNames?.length > 1 && !basePlainPascalCaseFixedIdentity) {
      return of(null);
    }

    let autoCompleteOptions = new AutoCompleteExternalOptions();
    autoCompleteOptions.outputProperties = [...this.filter.metaData.external.dependentAggregateMetaData.rootMetaData.identityNames];
    autoCompleteOptions.propertySearchList = [...this.filter.metaData.external.dependentAggregateMetaData.rootMetaData.identityNames];

    if (basePlainPascalCaseFixedIdentity) {
      const baseKeys = Object.keys(basePlainPascalCaseFixedIdentity);
      for (const baseKey of baseKeys) {
        const newFilter = new Filter();
        newFilter.name = baseKey;
        newFilter.value = basePlainPascalCaseFixedIdentity[baseKey];
        autoCompleteOptions.additionalFilters.push(newFilter)  
      }
    }

    const mainDescription = this.filter.metaData.external.dependentAggregateMetaData.rootMetaData.strings.find((s) =>s.isMainDescription);

    if(mainDescription) {
      autoCompleteOptions.outputProperties = [...autoCompleteOptions.outputProperties, mainDescription.name];
    } else if(this.filter.metaData.external.dependentAggregateMetaData.rootMetaData.strings?.length > 0) {
      // Sullo zoom non possiamo aggiungere la prima stringa a caso al posto della main description come facciamo nelle maschere standard, visto
      // che poi non sarà possibile configurare l'autocomplete dalla maschera di partenza
      autoCompleteOptions.outputProperties = [...autoCompleteOptions.outputProperties];
    }

      autoCompleteOptions.searchValue = term;
      autoCompleteOptions.fullRootModelName = this.filter.metaData.external.dependentAggregateMetaData.rootFullName;

      return this.filter.zoomParametersViewModel.apiClient.autoComplete(autoCompleteOptions)
        .pipe(
          map((r) => {
              const result = r.result.map((properties: Array<string|number>) => this.parseAutocompleteDataFromPropertyArray(properties, autoCompleteOptions, showCodeInDescription, basePlainPascalCaseFixedIdentity))
              if (result.length > 0) {
                return result[0];
              }
              return null;
          }))
  }

  getItemFromIdentityCallback(
    identity: {[key:string]: string|number}, 
    showCodeInDescription: boolean,
    basePlainPascalCaseFixedIdentity: {[key:string]: string|number}|null
  ): Observable<{ description: string, identity: {[key:string]: string|number}, all: any }> {
    // this.filter.zoomParametersViewModel.apiClient.autoComplete(options)

    let autoCompleteOptions = new AutoCompleteExternalOptions();
    autoCompleteOptions.outputProperties = [...this.filter.metaData.external.dependentAggregateMetaData.rootMetaData.identityNames];
    autoCompleteOptions.propertySearchList = [...this.filter.metaData.external.dependentAggregateMetaData.rootMetaData.identityNames];

    if (basePlainPascalCaseFixedIdentity) {
      const baseKeys = Object.keys(basePlainPascalCaseFixedIdentity);
      for (const baseKey of baseKeys) {
        const newFilter = new Filter();
        newFilter.name = baseKey;
        newFilter.value = basePlainPascalCaseFixedIdentity[baseKey];
        autoCompleteOptions.additionalFilters.push(newFilter)  
      }
    }

    const mainDescription = this.filter.metaData.external.dependentAggregateMetaData.rootMetaData.strings.find((s) =>s.isMainDescription);

    if(mainDescription) {
      autoCompleteOptions.outputProperties = [...autoCompleteOptions.outputProperties, mainDescription.name];
    } else if(this.filter.metaData.external.dependentAggregateMetaData.rootMetaData.strings?.length > 0) {
      // Sullo zoom non possiamo aggiungere la prima stringa a caso al posto della main description come facciamo nelle maschere standard, visto
      // che poi non sarà possibile configurare l'autocomplete dalla maschera di partenza
      autoCompleteOptions.outputProperties = [...autoCompleteOptions.outputProperties];
    }

    const identityValues = Object.values(identity);
    if (identityValues?.length > 0) {
      autoCompleteOptions.searchValue = Object.values(identity)[0].toString();
      autoCompleteOptions.fullRootModelName = this.filter.metaData.external.dependentAggregateMetaData.rootFullName;

      return this.filter.zoomParametersViewModel.apiClient.autoComplete(autoCompleteOptions)
        .pipe(
          map((r) => {
              const result = r.result.map((properties: Array<string|number>) => this.parseAutocompleteDataFromPropertyArray(properties, autoCompleteOptions, showCodeInDescription, basePlainPascalCaseFixedIdentity))
              if (result.length > 0) {
                return result[0];
              }
              return null;
          }))
    }
    return of(null)
  }

  getItemsFromTermCallback(
    term: string, 
    showCodeInDescription: boolean,
    basePlainPascalCaseFixedIdentity: {[key:string]: string|number}|null
  ): Observable<{ description: string, identity: {[key:string]: string|number}, all: any }[]> {

    const [termToSearch] = showCodeInDescription ? term.split(' - ') : [term];

    let autoCompleteOptions = new AutoCompleteExternalOptions();
    autoCompleteOptions.outputProperties = [...this.filter.metaData.external.dependentAggregateMetaData.rootMetaData.identityNames];
    autoCompleteOptions.propertySearchList = [...this.filter.metaData.external.dependentAggregateMetaData.rootMetaData.identityNames];

    if (basePlainPascalCaseFixedIdentity) {
      const baseKeys = Object.keys(basePlainPascalCaseFixedIdentity);
      for (const baseKey of baseKeys) {
        const newFilter = new Filter();
        newFilter.name = baseKey;
        newFilter.value = basePlainPascalCaseFixedIdentity[baseKey];
        autoCompleteOptions.additionalFilters.push(newFilter)  
      }
    }

    const mainDescription = this.filter.metaData.external.dependentAggregateMetaData.rootMetaData.strings.find((s) =>s.isMainDescription);
    if(mainDescription) {
      autoCompleteOptions.outputProperties = [...autoCompleteOptions.outputProperties, mainDescription.name];
      autoCompleteOptions.propertySearchList = [...autoCompleteOptions.propertySearchList, mainDescription.name];
    } else if(this.filter.metaData.external.dependentAggregateMetaData.rootMetaData.strings?.length > 0) {
      // Sullo zoom non possiamo aggiungere la prima stringa a caso al posto della main description come facciamo nelle maschere standard, visto
      // che poi non sarà possibile configurare l'autocomplete dalla maschera di partenza
      autoCompleteOptions.outputProperties = [...autoCompleteOptions.outputProperties];
      autoCompleteOptions.propertySearchList = [...autoCompleteOptions.propertySearchList];
    }

    autoCompleteOptions.searchValue = termToSearch;
    autoCompleteOptions.fullRootModelName = this.filter.metaData.external.dependentAggregateMetaData.rootFullName;

    return this.filter.zoomParametersViewModel.apiClient.autoComplete(autoCompleteOptions)
      .pipe(
        map(r => r.result.map((properties: Array<string|number>) => {
          return this.parseAutocompleteDataFromPropertyArray(properties, autoCompleteOptions, showCodeInDescription, basePlainPascalCaseFixedIdentity);
        }))
      )
  }

  parseAutocompleteDataFromPropertyArray(
    properties: Array<string|number>, 
    autoCompleteOptions: AutoCompleteExternalOptions, 
    showCodeInDescription: boolean = true,
    basePlainPascalCaseFixedIdentity: {[key:string]: string|number}|null
  ): { description: string, identity: {[key:string]: string|number}, all: any } {
    // TODO Tommy gestire meglio la lista degli input properties
    // il primo elemento deve essere l'id
    const all = autoCompleteOptions.outputProperties.map((key, index) => {
        return {
            [key]: properties[index]
        };
    })

    const identity = {};

    for (const [index, value] of this.filter.metaData.external.dependentAggregateMetaData.rootMetaData.identityNames.entries()) {
      identity[value] = properties[index];
    }

    return {
        identity,
        description: this.getDescriptionFromProperties(autoCompleteOptions, properties, showCodeInDescription, basePlainPascalCaseFixedIdentity),
        all
    };
  }

  private getDescriptionFromProperties(autoCompleteOptions: AutoCompleteExternalOptions, properties: any[], showCodeInDescription = true, basePlainPascalCaseFixedIdentity: {[key:string]: string|number}|null): string {


    return autoCompleteOptions.outputProperties.map((currentPropertyNameInPascalCase, index) => {
        // Nasconde il campo isActive nella descrizione se è stato impostato this._hideIsActiveField
        if (currentPropertyNameInPascalCase === 'IsActive') {
            return null;
        }

        // Nascondo le property del basePlainPascalCaseFixedIdentity
        if (basePlainPascalCaseFixedIdentity && currentPropertyNameInPascalCase in basePlainPascalCaseFixedIdentity) {
          return null;
        }

        if (!showCodeInDescription) {
            if (this.filter.metaData.external.dependentAggregateMetaData.rootMetaData.identityNames.indexOf(currentPropertyNameInPascalCase) > -1) {
                return null;
            }
        }
        const metaData = this.filter.metaData.external.dependentAggregateMetaData.rootMetaData.enums.find((en) =>
            en.name === currentPropertyNameInPascalCase
        );

        let formattedValue = properties[index];

        if (metaData) {
            const enumResource = metaData.valuesResource.find((e) => e.enumValue === formattedValue);
            if (!enumResource) {
                return null;
            } else {
                formattedValue = MessageResourceManager.Current.getMessage(enumResource.resourceKey);
            }
        }
        return formattedValue;
    }).filter(item => item).join(' - ');

  }

  valueChange(val: {identity: {[key:string]: string|number}, description?: string, isValid?: boolean}) {
    this.updatedExternalValue.next(val);
  }

  protected override updateModel() {

      if (this.propertyViewModel.value?.identity != this.updatedExternalValue.value?.identity) {
        if (this.updatedExternalValue.value?.isValid === false) {
          this.propertyViewModel.setValue(null);
        } else {
          this.propertyViewModel.setValue(this.updatedExternalValue.value);
          
        }        
      }      
  }

  async startPresentation(externalUtility: ExternalBoxCommandUtility): Promise<void> {
    if (this.propertyViewModel.isEnabled) {
      externalUtility.closeDropdown();
      externalUtility.clearPoppers();

      const domainModelFullName = this.filter.metaData.external.dependentAggregateMetaData.rootFullName;

      await PresentationCache.addIfNotExist(domainModelFullName);
      const resultBaseUrl = PresentationCache.get(domainModelFullName);

      if (resultBaseUrl != null && resultBaseUrl !== '') {

          let jsonIdentity = '{}';

              jsonIdentity = JSON.stringify(this.filter.filterValue.value);
          
              const result = await this.showExternalModalWithResultAsync<string>(
                  resultBaseUrl, 
                  jsonIdentity, 
                  undefined,
                  this.filter.metaData.external.descriptions.description,
                  false
              );
              if (result.cancel) {
                  // this.eventDispatcher.externalModalReturned.emit();
                  // this.externalPresentationCompleted.emit();
              } else {
                  // this.f8Return(result.result);
              }

      } else {

          // TODO: Notificare che non esiste la gestione del related

      }

    }

  }

  async showExternalModalWithResultAsync<TResult>(
    url: string, 
    jsonIdentity: string, 
    additionalQueryParams = new URLSearchParams(),
    modalTitle?: string, 
    externalReturn = true,
    supportRemoteClosingCheck = false,
    customExternalModalViewModel = null
): Promise<ModalResult<TResult>> {
    return this.modalService.showExternalModal(
        url, 
        jsonIdentity,
        additionalQueryParams,
        modalTitle, 
        externalReturn, 
        supportRemoteClosingCheck,
        customExternalModalViewModel
    );
}
}
import cloneDeep from 'lodash-es/cloneDeep';
import { of, Subscription } from 'rxjs';
import { map, take, startWith } from 'rxjs/operators';
import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ZoomResultsBoolCellRendererComponent } from './zoom-results-bool-cell-renderer.component';
import { ZoomResultsDateTimeCellRendererComponent } from './zoom-results-datetime-cell-renderer.component';
import { ZoomResultsDateTimeOffsetCellRendererComponent } from './zoom-results-datetime-offset-cell-renderer.component';
import { ZoomResult } from '../../../domain-models/zoom/zoom-result';
import { ZoomResultsViewModel } from '../../../view-models/zoom/zoom-results-view-model';
import { GridOptions, GridApi, ColDef, ColumnApi, GridReadyEvent} from 'ag-grid-community';
import { ZoomResultsDefaultCellRendererComponent } from './zoom-results-default-cell-renderer.component';
import moment from 'moment';
import { CommandFactory, CommandTypes, UICommandInterface, UICommandSettingsManager, ZoomColumnInfo } from '../../../view-models';
import { ToastMessage, ToastMessageType } from '../../layout/toast-message/toast-message';
import { ToastMessageService } from '../../layout/toast-message/toast-message.service';
import { MessageResourceManager } from '../../../resources/message-resource-manager';
import { OrderByType } from '../../../domain-models/autocomplete/auto-complete-options';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OutputDataOrderDto } from '../../../domain-models/zoom/dto/output-data-order.dto';
import { ZoomAdvancedOptions } from '../../../domain-models/find-options/zoom-advanced-options';
import { AsyncPipe, NgIf } from '@angular/common';
import { RibbonButtonComponent } from '../../shared/buttons/ribbon-button/ribbon-button.component';
import { AgGridModule } from 'ag-grid-angular';

@UntilDestroy()
@Component({
  selector: 'nts-zoom-results',
  templateUrl: './zoom-results.component.html',
  styleUrls: ['./zoom-results.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    RibbonButtonComponent,
    AsyncPipe,
    AgGridModule,
    
  ]
})
export class ZoomResultsComponent
  implements OnInit, OnChanges, OnDestroy {
  
  @Input()
  zoomResultsViewModel: ZoomResultsViewModel;

  // @Output()
  onCompleted: EventEmitter<ZoomResult> = new EventEmitter<ZoomResult>();

  gridOptions: GridOptions;

  columnsSidebarActionInProgress = false;
  columnsSidebarCollapsed = true;
  columnsSidebarCollapsedTitle = 'Colonne'; // TODO traduzione
  columnDefinitions: ColDef[] = [];

  exportCsvCommand: UICommandInterface;

  private api: GridApi;
  private columnApi: ColumnApi
  private subscriptions: Subscription[] = [];
  

  @HostListener('keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.subscriptions.push(
        this.zoomResultsViewModel.rowDoubleClickCommand
          .canExecute$
          .pipe(take(1))
          .subscribe(async (res) => {
            if (res) {
              this.onCompleted.emit(
                await this.zoomResultsViewModel.rowDoubleClickCommand.execute(
                  () => null
                )
              );
            }
          })
      );
    }
  }

  constructor(
    private readonly toastService: ToastMessageService
  ) {
    this.gridOptions = {} as GridOptions;
    this.gridOptions.rowSelection = 'single';
    this.gridOptions.suppressDragLeaveHidesColumns = true;
    this.gridOptions.stopEditingWhenCellsLoseFocus = false;
    this.gridOptions.suppressColumnVirtualisation = true;
    this.gridOptions.onGridReady = e => this.onGridReady(e);
    this.gridOptions.onRowDoubleClicked = event => {
      this.subscriptions.push(
        this.zoomResultsViewModel.rowDoubleClickCommand
          .canExecute$
          .pipe(take(1))
          .subscribe(async (res) => {
            if (res) {
              this.onCompleted.emit(
                await this.zoomResultsViewModel.rowDoubleClickCommand.execute(
                  () => event.rowIndex
                )
              );
            }
          })
      );
    };

    this.gridOptions.defaultColDef = {
      editable: false
    };

    // change selection with keys
    this.gridOptions.navigateToNextCell = params => {
      var previousCell = params.previousCellPosition;
      var suggestedNextCell = params.nextCellPosition;

      const KEY_UP = 'ArrowUp';
      const KEY_DOWN = 'ArrowDown';
      const KEY_LEFT = 'ArrowLeft';
      const KEY_RIGHT = 'ArrowRight';

      switch (params.key) {
        case KEY_DOWN:
          previousCell = params.previousCellPosition;
          // set selected cell on current cell + 1
          this.api.forEachNode(node => {
            if (previousCell.rowIndex + 1 === node.rowIndex) {
              node.setSelected(true);
            }
          });
          return suggestedNextCell;
        case KEY_UP:
          previousCell = params.previousCellPosition;
          // set selected cell on current cell - 1
          this.api.forEachNode(node => {
            if (previousCell.rowIndex - 1 === node.rowIndex) {
              node.setSelected(true);
            }
          });
          return suggestedNextCell;
        case KEY_LEFT:
        case KEY_RIGHT:
          return suggestedNextCell;
        default:
          throw 'this will never happen, navigation is always on of the 4 keys above';
      }
    };
  }

  ngOnInit(): void {
    const manager = new UICommandSettingsManager();
    this.exportCsvCommand = manager.setUICommand(
      CommandTypes.ExportCSV,
      CommandFactory.createUICommand(
        async (x) => this.exportCsv(),
        () => this.zoomResultsViewModel.resultChanged.pipe(startWith('first-time'), map(() => this.zoomResultsViewModel?.results > 0))
      ));


      this.zoomResultsViewModel.columnInfoChanged
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.refreshColumnDefinitions();       
        })
  }


  private exportCsv(): void {

    if (this.zoomResultsViewModel.isLastPage !== this.zoomResultsViewModel.isFirstPage) {
      this.toastService.showToast({
        title: MessageResourceManager.Current.getMessage('std_Warning'),
        message: MessageResourceManager.Current.getMessage('std_Export_Grid_Pagination_Message'),
        type: ToastMessageType.info
      } as ToastMessage);
    }
    this.gridOptions.api.exportDataAsCsv({
      suppressQuotes: false,
      columnSeparator: ',',
      // customHeader:
      // customFooter:
    });
  }


  ngOnChanges(changes: SimpleChanges): void { }

  ngOnDestroy(): void {
    this.zoomResultsViewModel.destroy.emit();

    this.subscriptions.forEach(s => s.unsubscribe());
  }

  autoSizeColumns(columnApi: ColumnApi) {
    columnApi.autoSizeAllColumns();
    // const panel = this.api['gridPanel'];
    // const availableWidth = panel['eBodyViewport'].clientWidth;
    // const columns = panel['columnController'].getAllDisplayedColumns();
    // const usedWidth = panel['columnController'].getWidthOfColsInList(columns);

    // // se le colonne non occupano tutto lo spazio ridimensiono le colonne per occupare tutta la finestra
    // if (usedWidth < availableWidth) {
    //   this.api.sizeColumnsToFit();
    // }
  }

  onGridReady(e: GridReadyEvent) {
    this.api = e.api;
    this.columnApi = e.columnApi;
    this.columnApi.autoSizeAllColumns();

    this.api.addEventListener('componentStateChanged', () => {

      this.autoSizeColumns(e.columnApi);
    });

    this.subscriptions.push(
      this.zoomResultsViewModel.forceAutoSize.subscribe(r => {
        setTimeout(() =>this.autoSizeColumns(e.columnApi));
      }),
      this.zoomResultsViewModel.datasourceUpdated.subscribe(() => {
        this.gridOptions.api.setDatasource(this.zoomResultsViewModel.datasource);
      }),
    );

    this.gridOptions.onColumnMoved = async (e) => {
      const colDefs = this.columnApi.getColumnState();
      const options: ZoomAdvancedOptions = this.zoomResultsViewModel.getZoomOptions();
      // Se ho un outputDataOrderList vuol dire che sono in uno zoom custom
      if (options.outputDataOrderList?.length >= 0) {
        for (let i = 0; i < colDefs.length; i++) {
          if (colDefs[i]?.colId?.length > 0) {
            const colId = colDefs[i]?.colId;
            
            const outputDataOrder = options.outputDataOrderList.find((o) => o.propertyName === colId);
            if (outputDataOrder) {
              outputDataOrder.position = i;
            } else {
              const output = new OutputDataOrderDto();
              output.isVisible = !colDefs[i].hide;
              output.propertyName = colId;
              output.position = i;
              options.outputDataOrderList.push(output)
            }
          }
        }
      } else {
        // Se sono nello standard non ho l'outputDataOrderList così lo ricreo
        options.outputDataOrderList = [];
        for (let i = 0; i < colDefs.length; i++) {
          if (colDefs[i]?.colId?.length > 0) {
            const colId = colDefs[i]?.colId;
            const output = new OutputDataOrderDto();
            output.isVisible = !colDefs[i].hide;
            output.propertyName = colId;
            output.position = i;
            options.outputDataOrderList.push(output);
          }
        }
      }
      this.zoomResultsViewModel.resultsChanged.next();
    };

    this.gridOptions.onSortChanged = e => {
      if (
        this.zoomResultsViewModel.selection != null &&
        this.api != null &&
        this.api.getSelectedNodes().length > 0
      ) {
        const node = this.api.getSelectedNodes()[0];
        node.setSelected(true, true);
        
        this.api.ensureIndexVisible(node.rowIndex);
        this.api.redrawRows();
      }
    };

    this.gridOptions.onRowSelected = e => {
      this.zoomResultsViewModel.selection = this.api.getSelectedRows()[0];
    };


    this.gridOptions.onRowDataChanged = () => {
      let i = 0;
      this.api.forEachNode(node => {
        if (i == 0) {
          node.setSelected(true);
          this.api.setFocusedCell(0, '0');
        }
        i++;
      });
    };

    this.gridOptions.onColumnVisible = e => {
      if (e.column.getColId() != 'rowHeader') {
        const colId = e.column.getColDef().colId;
        const column = this.zoomResultsViewModel.columns.find((c) => c.propertyName === colId);
        if (column) {
          column.isVisible = e.visible
        }
      }
    };
  }

  private getCellRenderer(propertyTypeName: string) {
    switch (propertyTypeName) {
      case 'Bool':
        return ZoomResultsBoolCellRendererComponent;
      case 'DateTime':
        return ZoomResultsDateTimeCellRendererComponent;
      case 'DateTimeOffset':
        return ZoomResultsDateTimeOffsetCellRendererComponent;
      default:
        return ZoomResultsDefaultCellRendererComponent;
    }
  }

  private getComparator(propertyTypeName: string) {
    switch (propertyTypeName) {
      case 'DateTime':
        return this.createDateComparator;
      case 'DateTimeOffset':
        return this.createDateComparator;
      default:
        return undefined;
    }
  }

  private createDateComparator(dateA, dateB) {
    if (dateA && dateB) {
      if (moment(dateB).isAfter(dateA)) return -1;
      return 1;
    } else if (dateA) {
      return 1;
    } else {
      return -1;
    }
  }

  refreshColumnDefinitions() {
    const oldColumnsDefinitions = cloneDeep(this.columnDefinitions);
    this.columnDefinitions = [];
    const columns: ZoomColumnInfo[] = cloneDeep(this.zoomResultsViewModel.columns);
    columns.sort((a, b) => a.orderIndex - b.orderIndex);

    for (let i = 0; i < columns.length; i++) {
      // let valueGetter = this.columns[i].propertyName;
      const field: ZoomColumnInfo =
        this.zoomResultsViewModel.columns.find(
          c => c.propertyName === columns[i].propertyName
        ); //this.zoomResultsViewModel.columns[i].propertyName;

      const fieldIndex: string =
        this.zoomResultsViewModel.columns.findIndex(
          c => c.propertyName === columns[i].propertyName
        )?.toString();
      const oldColumn = oldColumnsDefinitions
        ? oldColumnsDefinitions.find(c => c.field == fieldIndex)
        : null;
      const hide = oldColumn ? oldColumn.hide : false;
      const colDef: ColDef = {
        colId: field.propertyName, // i.toString(),
        headerName: columns[i].header,
        sortable: false,
        field: fieldIndex,
        sortIndex: columns[i].orderByIndex,
        // headerComponent: DefaultHeaderComponent,
        sort: columns[i].orderBy == null ? 
          null : 
          (columns[i].orderBy === OrderByType.Ascending ? 'asc' : 'desc'),
        hide: !columns[i].isVisible || hide,
        cellRenderer: this.getCellRenderer(
          columns[i].propertyTypeName
        ),
        cellRendererParams: {
          columnInfo: columns[i]
        },
        comparator: this.getComparator(columns[i].propertyTypeName)
      };
      this.columnDefinitions.push(colDef);
    }
  }

  onColumnsSidebarCollapsed(collapsed: boolean) {
    this.columnsSidebarCollapsed = collapsed;
  }
}

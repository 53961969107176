import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SvgIconComponent } from "@ngneat/svg-icon";
import { NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from "ngx-popperjs";

@Component({
    selector: 'nts-label-box',
    templateUrl: './label-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./label-box.component.scss'],
    standalone: true,
    imports: [
        NgxPopperjsModule,
        NgIf,
        SvgIconComponent
    ]
})
export class LabelBoxComponent {
    @Input() isLocked = false;
    @Input() isEnabled = true;
    @Input() isRequired = false;
    @Input() label = "";
    @Input() title = "";
    @Input() showTitleTooltip = true;

    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;
}
import { DateTimeOffset } from '../date-time-offset';
import { DateTime } from '../date-time';
import { Expose, Transform, Type } from '../../serialization/class-transformer/decorators';

export enum FilterOperators {
    None = -1,
    Equals = 0,
    NotEquals = 1,
    GreaterThan = 2,
    GreaterOrEqualThan = 3,
    LessThan = 4,
    LessOrEqualThan = 5,
    IsNull = 6,
    IsNotNull = 7,
    Contains = 8,
    StartWith = 9,
    EndWith = 10,
    Between = 11,
    In = 12
}

export enum RuntimeVariables {
    Today = 0, // TODO: attenzione, in questo caso occorre indicare l'offset e non utilizzare l'ora
    CurrentUser = 1
}

export class Filter {

    /**
     * Nome della property. Deve essere comprensivo del path nel caso in cui sia di una entità all'interno di un aggregato
     */
    @Expose()
    name: string;

    /**
     * Valore con cui eseguire la ricerca
     */
    @Expose()
    value: any;

    /**
     * Lista di valori con cui eseguire la ricerca
     */
    @Expose()
    values: any[];

    /**
     * Lista di valori con cui eseguire la ricerca nel caso di DateTimeOffset
     */
    @Expose()
    @Type(() =>DateTimeOffset)
    @Transform((value, obj, type) => {
        return DateTimeOffset.transform('dateTimeOffSetValues', value, obj, type);
    })
    dateTimeOffSetValues?: DateTimeOffset[];

    /**
     * Lista di valori con cui eseguire la ricerca nel caso di DateTime
     */
    @Expose()
    @Transform((value, obj, type) => {
        return DateTime.transform('dateTimeValues', value, obj, type);
    })
    dateTimeValues?: Date[];

    /**
     * Valore con cui eseguire la ricerca nel caso di DateTimeOffset
     */
    @Expose()
    @Transform((value, obj, type) => {
        return DateTimeOffset.transform('dateTimeOffSetValue', value, obj, type);
    })
    dateTimeOffSetValue?: DateTimeOffset;

    /**
     * Valore con cui eseguire la ricerca nel caso di DateTime
     */
    @Expose()
    @Transform((value, obj, type) => {
        return DateTime.transform('dateTimeValue', value, obj, type);
    })
    dateTimeValue?: Date;

    /**
     * Nel caso di opeatore between rappresenta il valore maggiore con cui effettuare la ricerca
     */
    @Expose()
    upperValue: any;

    /**
     * Nel caso di opeatore between rappresenta il valore maggiore con cui effettuare la ricerca
     */
    @Expose()
    @Transform((value, obj, type) => {
        return DateTimeOffset.transform('dateTimeOffSetUpperValue', value, obj, type);
    })
    dateTimeOffSetUpperValue?: DateTimeOffset;

    /**
     * Nel caso id opeatore between rappresenta il valore maggiore con cui effettuare la ricerca
     */
    @Expose()
    @Transform((value, obj, type) => {
        return DateTime.transform('dateTimeUpperValue', value, obj, type);
    })
    dateTimeUpperValue?: Date;

    /**
     * Indica se come valore utilizzare una varibile di ambiente
     */
    @Expose()
    runtimeVariable?: RuntimeVariables;

    /**
     * Nel caso id opeatore between rappresenta il valore maggiore con cui effettuare la ricerca
     */
    @Expose()
    runtimeVariableUperrValue?: RuntimeVariables;

    /**
     * Operatore con cui effettuare la ricerca
     */
    @Expose()
    operator: FilterOperators;

    /**
     * Se true forza l'applicazione del filtro in AND
     */
    @Expose()
    applyAndLogic: boolean;

    
    // I filtri locked non vengono salvati, ma servono solo per nascondere determinati filtri preimpostati
    isLocked: boolean;
}

import { NTSReflection } from '@nts/std/src/lib/utility';
import { RootViewModel } from '../view-models/root-view-model';
import { BaseIdentity } from '../domain-models/base-identity';
import { CoreModel } from '../domain-models/core-model';
import { ClassType } from '../serialization/class-transformer/ClassTransformer';

const ROOT_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY = 'rootViewModelTypeDecoratorMetaDataKey';

export function RootViewModelTypeDecorator<TIdentity extends BaseIdentity, TModel extends CoreModel<TIdentity>, TViewModel extends RootViewModel<TModel, TIdentity>>(rootViewModelType: ClassType<TViewModel>) {
    return (target: any) => {
        if (
            !((rootViewModelType as any).prototype instanceof RootViewModel)
        ) {
            throw new Error(RootViewModelTypeInspector.DECORATOR_NAME + ' Invalid Type: ' +
                Object.getPrototypeOf((rootViewModelType as any).prototype).constructor.name +
                '. Allowed Types: instance of RootViewModel');
        }
        Reflect.defineMetadata(ROOT_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, rootViewModelType, target);
    };
}

export class RootViewModelTypeInspector {

    static META_DATA_KEY = ROOT_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY;
    static DECORATOR_NAME = '@RootViewModelTypeDecorator';

    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            ROOT_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): any {
        return NTSReflection.getClassMetadata(
            ROOT_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }
}

import { ViewModelTypeDecorator } from '../../decorators/view-model-type.decorator';
import { ModalViewModelInterface } from './modal-view-model.interface';
import { UIStarter, IFrameGetter } from '../../starter/ui-starter';
import { UIResultCommandInterface } from '../commands/ui-result-command.interface';
import { CommandFactory } from '../commands/command-factory';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { MessageResourceManager } from '../../resources/message-resource-manager';
import { MessageButton } from './message-button';
import { MessageResult } from './message-result';
import { MessageCodes } from '../../resources/message-codes';
import { ModalService } from './modal.service';
import { LogService, Message, MessageType, TabSocket, StatusMessageInterface } from '@nts/std/src/lib/utility';

@ViewModelTypeDecorator(ExternalModalViewModel)
export class ExternalModalViewModel implements ModalViewModelInterface<IFrameGetter, void> {

    modalTitle: string;
    modalSubtitle = '';
    showFooter = true;
    supportRemoteClosingCheck = false;
    supportRemoteStatus = false;
    baseUrl: string;
    jsonIdentity: string;
    additionalQueryParams = new URLSearchParams();
    externalReturn: boolean;
    socket: TabSocket;
    onActionInProgress = new Subject<boolean>();
    modalCommands = [];
    cancelAction: Subject<void> = new Subject();
    selectCommand: UIResultCommandInterface<string, any>;

    closeModal = () => this.canICloseModal();

    private _message: string;
    get message(): string {
        return this._message;
    }
    set message(v: string) {
        this._message = v;
    }


    private _caption: string;
    get caption(): string {
        return this._caption;
    }
    set caption(v: string) {
        this._caption = v;
    }


    private _detail: string;
    get detail(): string {
        return this._detail;
    }
    set detail(v: string) {
        this._detail = v;
    }

    async initialize(args: IFrameGetter): Promise<void> {
        if (this.externalReturn) {
            this.socket = await UIStarter.startIframeClientAndReturn(
                this.baseUrl,
                (payload) => {
                    switch (payload.type) {
                        case MessageType.ExternalReturn:
                            this.selectCommand.execute(payload.data)
                            break;
                        default:
                            break;
                    }
                },
                () => this.cancelAction.next(),
                this.jsonIdentity,
                this.additionalQueryParams,
                args
            );
        } else if (this.supportRemoteClosingCheck) {
            this.socket = await UIStarter.startIframeClientAndReturn(
                this.baseUrl,
                (payload) => {
                    switch (payload.type) {
                        case MessageType.ExternalReturn:
                            this.selectCommand.execute(payload.data)
                            break;
                        default:
                            break;
                    }
                },
                () => this.cancelAction.next(),
                this.jsonIdentity,
                this.additionalQueryParams,
                args,
                false
            );
        } else {
            UIStarter.startIframeClientAndWithoutReturn(
                this.baseUrl,
                () => this.cancelAction.next(),
                this.jsonIdentity,
                this.additionalQueryParams,
                args
            );
        }
    }

    async canICloseModal(): Promise<boolean> {
        if (this.supportRemoteClosingCheck) {
            this.onActionInProgress.next(true);
            const response = await this.checkStatus();
            this.onActionInProgress.next(false);

            if (response.hasModalOpen) {
                // TODO fare vedere un messaggio che dice chiudere prima le modali figlie
                return false;
            }

            if (response.pendingChanges === true) {
                const result = await this.modalService.showMessageAsync(
                    MessageResourceManager.Current.getMessage(MessageCodes.Warning),
                    MessageResourceManager.Current.getMessage('Modal_CloseConfirmMessage'),
                    MessageButton.YesNo);
                if (result === MessageResult.Yes) {
                    this.socket.destroy();
                }
                return result === MessageResult.Yes;
            }
        }
        return true;
    }

    async checkStatus(): Promise<StatusMessageInterface> {
        if (this.supportRemoteStatus) {
            const response = await this.socket.sendWithConfirmAsync<string, StatusMessageInterface>('check-status', MessageType.AskStatus).catch((err) => {
                LogService.log(err);
                return new Message(false);
            });
            if (response.data !== false) {
                const data: StatusMessageInterface = response.data as StatusMessageInterface;
                return {
                    hasModalOpen: data.hasModalOpen,
                    pendingChanges: data.pendingChanges,
                    version: data.version
                }
            }
        }
        if (this.supportRemoteClosingCheck) {
            const response = await this.socket.sendWithConfirmAsync<string, boolean>('check-pending-changes', MessageType.AskPendingChanges).catch((err) => {
                LogService.log(err);
                return new Message(false);
            });

            return {
                hasModalOpen: false,
                pendingChanges: response.data === true,
                version: 0
            }
        }
        return {
            hasModalOpen: false,
            pendingChanges: false,
            version: 0
        };
    }

    constructor(
        public modalService: ModalService,
    ) {
        this.setupCommands();
    }

    private setupCommands() {

        this.selectCommand = CommandFactory.createResultUICommand(
            async (returnedJsonIdentity: string) => {
                return returnedJsonIdentity;
            },
            () => of(true)
        );
        this.selectCommand.closeModal = true;
        this.selectCommand.isDefault = true;
        this.selectCommand.isVisible$ = new BehaviorSubject<boolean>(false);
        this.modalCommands.push(this.selectCommand);
    }
}

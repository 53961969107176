import { Expose } from "../serialization/class-transformer/decorators";

export class MetaDataDescriptions {

    @Expose()
    displayNameKey: string;

    @Expose()
    shortNameKey: string;

    @Expose()
    descriptionKey: string;

    @Expose()
    displayName: string;

    @Expose()
    shortName: string;

    @Expose()
    description: string;

}

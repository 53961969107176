<button #button
    [attr.type]="buttonAttributeType"
    (click)="buttonClicked($event)" 
    (keydown)="onItemKeyDown($event)"
    [class.is-disabled]="isDisabled" 
    [attr.tabindex]="tabIndex" 
    [popper]="title ?? ''"
    [popperDisabled]="!title || title?.length == 0 || isDisabled"
    [popperTrigger]="ngxPopperjsTriggers.hover"
    [popperApplyClass]="'info'"
    [popperPlacement]="ngxPopperjsPlacements.TOP"
    [popperPreventOverflow]="false"
    [popperHideOnScroll]="true"
    popperAppendTo="body"
    [popperDelay]="1000"
    [class.has-notification]="hasNotification"
    [class.is-loading]="isLoading" 
    [class.has-text]="label?.length > 0"
    [ngClass]="[buttonSize[size]].concat(additionalClasses)"    
    [attr.disabled]="isDisabled ? true : null"
    [attr.data-icon]="icon"
    [attr.data-label]="label">
    <svg-icon *ngIf="icon" [key]="icon"></svg-icon>
    <div class="button-label" [class.has-icon]="icon">{{ label }}</div>
    <!-- <span class="loader"></span> -->
</button>

<nts-menu *ngIf="commands" class="menu-container" #menu [popup]="true" [model]="menuItems" appendTo="body" (onShow)="menuOpened.next()">
    <ng-template let-onItemKeyDown="onItemKeyDown" let-item pTemplate="item">
        
        <a (keydown)="onItemKeyDown($event)" 
            [attr.href]="item.url||null" 
            class="p-menuitem-link" [class.is-loading]="item.loading | async" [class.is-hightlighted]="item.hightlighted | async"
            [attr.tabindex]="item.disabled ? null : '0'" 
            [attr.data-automationid]="item.automationId" 
            [attr.target]="item.target" 
            [attr.title]="item.title" 
            [attr.id]="item.id"
            [ngClass]="{'p-disabled':item.disabled}" 
            (click)="menu.itemClick($event, item)" role="menuitem">
            <span class="p-menuitem-text" *ngIf="item.escape !== false; else htmlLabel">{{item.label}}</span>
            <ng-template #htmlLabel>
                <span class="p-menuitem-text" [innerHTML]="item.label"></span>
            </ng-template>
            <span class="p-loader-container">
                <svg-icon key="refresh-animated"></svg-icon>
            </span>
        </a>

    </ng-template>
</nts-menu>
import { BaseSecurityTextBoxComponent } from './../base/base-security-text-box/base-security-text-box.component';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild, SimpleChanges, OnChanges, EventEmitter, Output, AfterViewInit, OnDestroy, ChangeDetectorRef, NgZone, ElementRef, Renderer2 } from '@angular/core';
import { ExternalViewModelInterface } from '../../../../../lib/view-models/external-view-model.interface';
import { BaseExternalPropertyTextBox } from '../base-external-property-text-box';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NTSTranslatePipe } from '../../../pipe/nts-translation-pipe';
import { TextButtonComponent } from '../../../shared/buttons/text-button/text-button.component';
import { BaseExternalBoxComponent } from '../base/base-external-box/base-external-box.component';
import { UICommandInterface } from '../../../../view-models/commands/ui-command.interface';
import { CommandTypes } from '../../../../view-models/commands/command-types';
import { UICommandSettingsManager } from '../../../../view-models/commands/ui-command-settings-manager';
import { CommandFactory } from '../../../../view-models/commands/command-factory';
import { BehaviorSubject, Observable, Subject, from, map, merge, of, startWith, switchMap, takeUntil } from 'rxjs';
import { MessageResourceManager } from '../../../../resources/message-resource-manager';
import { ExternalViewModel } from '../../../../view-models/external-view-model';
import { PropertyViewModelInterface } from '../../../../view-models/property-view-model.interface';
import { ExternalDomainModelChangedEventArgs } from '../../../../view-models/external-domain-model-changed-event-args';
import { PropertyViewModelPropertyChangedEventArgs } from '../../../../view-models/property-view-property-changed-event-args';
import { PropertyViewModel } from '../../../../view-models/property-view-model';

@UntilDestroy()
@Component({
    selector: 'nts-ext-new-autocomplete-text-box',
    templateUrl: './ext-new-autocomplete-text-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./ext-new-autocomplete-text-box.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        FormsModule,
        AsyncPipe,
        NTSTranslatePipe,
        NgOptionHighlightModule,
        TextButtonComponent,
        BaseSecurityTextBoxComponent,
        BaseExternalBoxComponent,
        NgFor
    ]
})
export class ExtNewAutocompleteTextBoxComponent extends
    BaseExternalPropertyTextBox<{ identity: { [key: string]: string | number }, description?: string, isValid?: boolean }> {

    @Input() override externalPropertyViewModel: ExternalViewModelInterface;
    @Input() appendTo = 'app-root' //'.nts-document-content';
    @Input() scrollElementClass = 'layout-main scrollable-content'
    @Input() showErrorTooltip = true;
    @Input() showErrorBorder = true;
    @Input() isDisabled = false;
    @Input() isSearchable = true;
    @Input() isLoading = false;
    @Input() isLocked = false;
    @Input() isLockedMessage = 'Campo bloccato';
    @Input() showCodeInDescription = true;
    @Input() decodeProperties: string[] = null;
    @Input() searchProperties: string[] = null;

    // @Input() code: PropertyViewModelInterface;
    // @Input() decodeDescription: PropertyViewModelInterface;
    // @Input() autogenerateCodeDescription = false;
    @Input() enableOutsideClickListener = false;

    @Output() onFinishEditing = new EventEmitter();
    @Output() propertyViewModelInitialized = new EventEmitter<void>();

    // @ViewChild('popperInfo', { static: false }) popperInfo: NgxPopperjsDirective;
    // @ViewChild('popperError', { static: false }) popperError: NgxPopperjsDirective;
    // @ViewChild(NgSelectComponent, { static: false }) inputRef: NgSelectComponent;
    @ViewChild('baseSecurityTextBox', { static: false }) baseSecurityTextBox: BaseSecurityTextBoxComponent;
    @ViewChild('baseExternalBox', { static: false }) baseExternalBox: BaseExternalBoxComponent;

    addItemCommand: UICommandInterface;
    searchCommand: UICommandInterface;
    viewItemCommand: UICommandInterface;
    copyCommand: UICommandInterface;
    onExternalBoxValueChange = new Subject<{ identity: { [key: string]: string | number }, description?: string, isValid?: boolean }>();
    destroyOldPropertyViewModelSubscriber = new Subject<void>();
    updatedExternalValue = new BehaviorSubject<{ identity: { [key: string]: string | number }, description?: string, isValid?: boolean } | null>(null);

    ngOnInit() {

        const manager = new UICommandSettingsManager();
        this.addItemCommand = manager.setUICommand(CommandTypes.CreateItem,
            CommandFactory.createUICommand(
                async (x) => this.startPresentation(null, false),
                () => this.externalPropertyViewModel.externalDomainModelChanged.pipe(
                    startWith(null),
                    map(() => this.externalPropertyViewModel.zoomAddIsEnabled)),
                null,
                () => this.externalPropertyViewModel.externalDomainModelChanged.pipe(
                    startWith(null),
                    map(() => this.externalPropertyViewModel.zoomAddIsVisible)),
            ));
        this.addItemCommand.description = MessageResourceManager.Current.getMessage('CMD_Create');

        this.searchCommand = manager.setUICommand(CommandTypes.CreateItem,
            CommandFactory.createUICommand(
                async (x) => this.zoom(),
                () => this.externalPropertyViewModel.externalDomainModelChanged.pipe(
                    startWith(null),
                    map(() => this.externalPropertyViewModel.zoomSearchIsEnabled)),
                null,
                () => this.externalPropertyViewModel.externalDomainModelChanged.pipe(
                    startWith(null),
                    map(() => this.externalPropertyViewModel.zoomSearchIsVisible)),
            ));
        // this.searchCommand.description =  MessageResourceManager.Current.getMessage('CMD_Search');
        this.searchCommand.iconClass = "search";

        this.viewItemCommand = manager.setUICommand(CommandTypes.ViewItem,
            CommandFactory.createUICommand(
                async (x) => {
                    this.baseExternalBox.isDropdownOpen.next(false);
                    this.startPresentation(null, true)
                },
                () => merge(
                    this.updatedExternalValue,
                    this.externalPropertyViewModel.externalDomainModelChanged
                ).pipe(
                    startWith(null),
                    map(() => this.updatedExternalValue?.value?.identity != null && this.externalPropertyViewModel.zoomViewIsEnabled)
                ),
                null,
                () => this.externalPropertyViewModel.propertyChanged.pipe(
                    startWith(null),
                    map(() => this.externalPropertyViewModel.zoomViewIsVisible)),
            ));
        this.viewItemCommand.description = MessageResourceManager.Current.getMessage('CMD_View_Tooltip');

        this.copyCommand = manager.setUICommand(CommandTypes.Copy,
            CommandFactory.createUICommand(
                async (x) => {
                    this.copyMessage(
                        this.getDescription(this.showCodeInDescription)
                    )
                    this.baseExternalBox.isDropdownOpen.next(false);
                },
                () => merge(
                    this.updatedExternalValue,
                    this.externalPropertyViewModel.externalDomainModelChanged
                ).pipe(
                    startWith(null),
                    map(() => this.updatedExternalValue?.value?.identity != null && this.externalPropertyViewModel.zoomViewIsEnabled)
                ),
                null,
                () => of(true)
            ));
        this.copyCommand.description = MessageResourceManager.Current.getMessage('CMD_CopyToClipboard_Tooltip');

        this.onExternalBoxValueChange.pipe(this.untilDestroyedThis()).subscribe(async (value) => {
            // console.log('onExternalBoxValueChange', value)
            // this.setCodeValue()
            this.isLoading = true;
            this.cd.detectChanges();
            this.updatedExternalValue.next(value);
            this.isLoading = false;
            this.valueChange.emit(value)
            if (value?.isValid == null || value.isValid != false) {
                await this.externalPropertyViewModel.setPlainPascalCaseIdentity(value?.identity)
            }
        })

    }
    
    onBlur() {
        if (!this.isDisabled && this.externalPropertyViewModel.isEnabled) {
            this.externalPropertyViewModel.validate();
        }
    }

    untilDestroyedThis(): <U>(source: Observable<U>) => Observable<U> {
        return untilDestroyed<this>(this)
    }



    // dropDownClosed = new Subject<void>();
    // isOpen = false;
    // dropdownPosition: DropdownPosition = 'bottom';
    // lastInputText: string;
    // itemLoading = false;
    // items$: Observable<{ description: string, identity: any, all: any }[]>;
    // itemInput$ = new Subject<string>();
    language: { twoLetterISOLanguageName, cultureName, description };
    // languages: Array<{ twoLetterISOLanguageName, cultureName, description }>;
    // selectedItem: { description: string, identity: any, all: any } = null;
    // selectedItemChanged: Subject<void> = new Subject();
    // searchFinished: Subject<void> = new Subject();
    // addItemCommand: UICommandInterface;
    // viewItemCommand: UICommandInterface;
    // copyCommand: UICommandInterface;
    // searchInputMouseDownListener: any;
    // searchInputMouseUpListener: any;
    // elementClickListener: any;
    // typeToSearchText: string;
    // lastSearchFeatureEnabled = false;
    // ngxPopperjsTriggers = NgxPopperjsTriggers;
    // ngxPopperjsPlacements = NgxPopperjsPlacements;
    // showButtonLabels = false;
    // overrideBorderColor = null;
    // isActive = false;
    // isHover = false;

    get inputValue(): { identity: { [key: string]: string | number }, description?: string, isValid?: boolean } {
        return null;
        // return this.code.getValue();
    }

    get input(): HTMLInputElement {
        return (this.propertyViewModel?.securityAccess == null ? this.baseExternalBox.inputRef.searchInput.nativeElement : this.baseSecurityTextBox.securityTextBox.nativeElement);
    }

    showErroTooltip(): void {
        if (this.baseExternalBox) {
            this.baseExternalBox.showErroTooltip();
        }
    }

    // private dropdownOpening = false;
    // private mouseDownOnSearchInput = false;
    // private resizeEvent = null;
    // private onFocusEnabled = true;

    // constructor(
    //     cd: ChangeDetectorRef,
    //     public readonly el: ElementRef,
    //     private readonly zone: NgZone,
    //     private readonly renderer: Renderer2,
    // //     private readonly onlineService: OnlineService
    // ) {
    //     super(cd);
    // }

    getDescription(showCodeInDescription: boolean): string {
        return this.externalPropertyViewModel.getDescription(showCodeInDescription, this.decodeProperties);
    }

    checkValue() {

        if (this.externalPropertyViewModel.hasDecodeError) {

        } else {
            const currentPlainIdentityFromDomainModel = this.externalPropertyViewModel.getCurrentPlainIdentityFromDomainModel();
            const description = this.getDescription(this.showCodeInDescription);
            if (this.updatedExternalValue.value?.identity == null && description == null) {
                this.updatedExternalValue.next(null);
            }
            if (this.updatedExternalValue.value?.identity != currentPlainIdentityFromDomainModel) {

                if (currentPlainIdentityFromDomainModel == null && description == null) {
                    // this.updatedExternalValue.next(null);
                } else {
                    this.updatedExternalValue.next(
                        {
                            identity: currentPlainIdentityFromDomainModel,
                            description: this.getDescription(this.showCodeInDescription),
                            isValid: !this.externalPropertyViewModel.hasErrors
                        }
                    );
                }
            }
            this.destroyOldPropertyViewModelSubscriber.next()
            this.externalPropertyViewModel.externalDomainModelChanged.pipe(this.untilDestroyedThis(), takeUntil(this.destroyOldPropertyViewModelSubscriber)).subscribe(() => {

                if (this.externalPropertyViewModel.hasDecodeError) {

                } else {
                    const currentPlainIdentityFromDomainModel = this.externalPropertyViewModel.getCurrentPlainIdentityFromDomainModel();
                    if (this.updatedExternalValue.value?.identity != currentPlainIdentityFromDomainModel) {
                        const description = this.getDescription(this.showCodeInDescription);
                        if (currentPlainIdentityFromDomainModel == null && description == null) {
                            this.updatedExternalValue.next(null);
                        } else {
                            this.updatedExternalValue.next(
                                {
                                    identity: currentPlainIdentityFromDomainModel,
                                    description: this.getDescription(this.showCodeInDescription),
                                    isValid: !this.externalPropertyViewModel.hasErrors
                                }
                            );
                        }
                        this.cd.detectChanges();
                    }
                }


            })
        }
    }

    override updateModel() {
        // Override update model
    }

    override async zoom(): Promise<void> {
        this.baseExternalBox.isDropdownOpen.next(false);
        await super.zoom(this.baseExternalBox.inputRef);
        this.baseExternalBox.isDropdownOpen.next(false);
    }

    override ngOnChanges(changes: SimpleChanges): void {

        this.destroyOnChange$.next(true);

        if(changes['externalPropertyViewModel'] != null) {
            this.updatedExternalValue.next(null);
        }        

        this.externalPropertyViewModel.parentIdentity$.pipe(
            this.untilDestroyedThis(),
            takeUntil(this.destroyOnChange$),
            switchMap((parentIdentity) => {
                if (parentIdentity instanceof ExternalViewModel) {
                    const arg = new ExternalDomainModelChangedEventArgs()
                    arg.domainModel = parentIdentity.domainModel;
                    return parentIdentity.externalDomainModelChanged.pipe(startWith(arg));
                } else if (parentIdentity instanceof PropertyViewModel) {
                    const arg = new PropertyViewModelPropertyChangedEventArgs()
                    arg.value = parentIdentity.value;
                    arg.propertyName = parentIdentity.bindedValuePropertyName;
                    return (parentIdentity as PropertyViewModelInterface).propertyChanged.pipe(startWith(arg));;
                } else {
                    return of(null)
                }
            })).subscribe((value: ExternalDomainModelChangedEventArgs | PropertyViewModelPropertyChangedEventArgs) => {
                if (this.externalPropertyViewModel.parentIdentity$.value instanceof ExternalViewModel) {
                    const val = value as ExternalDomainModelChangedEventArgs;
                    if (val?.domainModel == null) {
                        this.isLocked = true;
                        this.isLockedMessage = 'Compila prima: ' + this.externalPropertyViewModel.parentIdentity$.value.metadataShortDescription;
                    } else {
                        this.isLocked = false;
                    }
                } else if (this.externalPropertyViewModel.parentIdentity$.value instanceof PropertyViewModel) {
                    const val = value as PropertyViewModelPropertyChangedEventArgs;
                    if (val?.propertyName == this.externalPropertyViewModel.parentIdentity$.value.bindedValuePropertyName && val?.value == null) {
                        this.isLocked = true;
                        this.isLockedMessage = 'Compila prima: ' + this.externalPropertyViewModel.parentIdentity$.value.metadataShortDescription;
                    } else if (val?.propertyName == 'value' && val?.value != null) {
                        this.isLocked = false;
                    }
                }
                this.cd.detectChanges();
            })

        merge(
            this.externalPropertyViewModel.resettedData,
            this.externalPropertyViewModel.externalDomainModelChanged,
            this.externalPropertyViewModel.propertyChanged,
        ).pipe(
            this.untilDestroyedThis(),
            takeUntil(this.destroyOnChange$)
        ).subscribe(() => {
            this.checkValue()
            // this.checkAsyncSelected();
            // this.typeaheadLoading = false;
            this.cd.detectChanges();
        });

        this.externalPropertyViewModel.resettedData.pipe(
            this.untilDestroyedThis(),
            takeUntil(this.destroyOnChange$)
        ).subscribe(() => {
            this.updatedExternalValue.next({ identity: null, isValid: true });
            this.cd.detectChanges();
        });

        //     this.externalPropertyViewModel.decodeCompleted.pipe(
        //         this.untilDestroyedThis(),
        //         takeUntil(this.destroyOnChange$)
        //     ).subscribe(() => {
        //         this.checkAsyncSelected();
        //         this.itemLoading = false;
        //         this.cd.detectChanges();
        //     });

        // this.checkLockedLogics();
        // this.checkParentFilterValue();

        // this.filter.parentExternalFilter.filterValue.propertyChanged.pipe(this.untilDestroyedThis()).subscribe(async (value) => {
        // this.checkLockedLogics();
        // this.checkParentFilterValue();
        // })

        //     merge(
        //         this.externalPropertyViewModel.propertyChanged,
        //         this.onlineService.isOnline$
        //     ).pipe(
        //         this.untilDestroyedThis(),
        //         takeUntil(this.destroyOnChange$)
        //     ).subscribe(() => {
        //         this.cd.detectChanges();
        //     });

        //     this.externalPropertyViewModel.resettedData.pipe(
        //         this.untilDestroyedThis(),
        //         takeUntil(this.destroyOnChange$)
        //     ).subscribe(async () => {
        //         if (this.selectedItem != null) {
        //             await this.onSelectedItem(null);
        //             this.cd.detectChanges();
        //         }
        //     });

        this.externalPropertyViewModel.codeProperties.forEach(pvm => {
            merge(
                pvm.propertyViewModelChanged,
                pvm.propertyChanged,
                pvm.onErrorStatusChanged
            ).pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$)
            ).subscribe(() => {
                // if (!pvm?.hasErrors) {
                //     PopperHelper.hide(this.popperError);
                // }
                // PopperHelper.hide(this.popperInfo);
                this.cd.detectChanges();

                // this.checkAsyncSelected();
            });

            //         pvm.decodePendingUpdated.pipe(
            //             this.untilDestroyedThis(),
            //             takeUntil(this.destroyOnChange$)
            //         ).subscribe((decodePending: boolean) => {
            //             this.itemLoading = decodePending;
            //             this.cd.detectChanges();

            //             // this.checkAsyncSelected();
            //         });
        });

        //     this.checkAsyncSelected();


        //this.lastInputText = '';
        this.checkValue();

        //     if (this.autogenerateCodeDescription) {
        //         this.code = this.externalPropertyViewModel.codeProperties.values().next().value;
        //         this.decodeDescription = this.externalPropertyViewModel.automaticDecodeDescription;
        //     }

        //     this.propertyViewModelInitialized.emit();
    }

    getExactItemFromTermCallback(
        term: string,
        showCodeInDescription: boolean,
        basePlainPascalCaseFixedIdentity: { [key: string]: string | number } | null
    ): Observable<{ description: string, identity: { [key: string]: string | number }, all: any }> {

        let identity = {};

        if (basePlainPascalCaseFixedIdentity) {
            identity = { ...basePlainPascalCaseFixedIdentity };
        }

        for (const [key, pvm] of this.externalPropertyViewModel.codeProperties) {
            if (pvm.setIdentityOnSet) {
                identity[pvm.propertyMetaData.name] = term;
                break;
            }
        }


        return from(this.externalPropertyViewModel.setPlainPascalCaseIdentity(identity)).pipe(map(() => {
            // return from(this.externalPropertyViewModel.setCodeValue(term)).pipe(map(() => {

            const dm = this.externalPropertyViewModel.getDomainModel();

            if (dm) {
                return {
                    description: this.getDescription(showCodeInDescription),
                    identity: this.externalPropertyViewModel.getCurrentPlainIdentityFromDomainModel(),
                    all: this.externalPropertyViewModel.getDomainModel()
                }
            } else {
                return null;
            }
        }))
    }

    getItemsFromTermCallback(
        term: string,
        showCodeInDescription: boolean,
        basePlainPascalCaseFixedIdentity: { [key: string]: string | number } | null
    ): Observable<{ description: string, identity: { [key: string]: string | number }, all: any }[]> {
        return this.externalPropertyViewModel.execAutocomplete(
            term,
            this.language ? this.language.twoLetterISOLanguageName : null,
            showCodeInDescription,
            basePlainPascalCaseFixedIdentity,
            this.decodeProperties,
            this.searchProperties
        )
    }

    getItemsCallback(
        showCodeInDescription: boolean,
        basePlainPascalCaseFixedIdentity: { [key: string]: string | number } | null
    ): Observable<{ description: string, identity: { [key: string]: string | number }, all: any }[]> {
        return this.externalPropertyViewModel.execExternalList(
            this.language ? this.language.twoLetterISOLanguageName : null,
            showCodeInDescription,
            basePlainPascalCaseFixedIdentity,
            this.decodeProperties,
        )
    }

    getItemFromIdentityCallback(
        identity: { [key: string]: string | number },
        showCodeInDescription: boolean,
        basePlainPascalCaseFixedIdentity: { [key: string]: string | number } | null
    ): Observable<{ description: string, identity: { [key: string]: string | number }, all: any }> {
        return from(this.externalPropertyViewModel.setPlainPascalCaseIdentity(identity)).pipe(map(() => {

            const dm = this.externalPropertyViewModel.getDomainModel();

            if (dm) {
                return {
                    description: this.getDescription(showCodeInDescription),
                    identity: this.externalPropertyViewModel.getCurrentPlainIdentityFromDomainModel(),
                    all: this.externalPropertyViewModel.getDomainModel()
                }
            } else {
                return null;
            }
        }))
    }

    private copyMessage(val: string): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }


    // override ngOnDestroy(): void {
    //     super.ngOnDestroy();
    //     this.unbindListener();
    // }

    // override startPresentation(event: any, showRead: boolean): void {
    //     if (!this.isDisabled && (showRead ? this.externalPropertyViewModel.zoomViewIsEnabled : this.externalPropertyViewModel.zoomAddIsEnabled)) {
    //         this.inputRef.close();
    //         PopperHelper.hide(this.popperInfo);
    //         PopperHelper.hide(this.popperError);
    //         super.startPresentation(event, showRead);
    //     }
    // }






    // getItemFromIdentityCallback(identity: {[key:string]: string|number}, showCodeInDescription: boolean, basePlainPascalCaseFixedIdentity: {[key:string]: string|number}|null): Observable<{ description: string, identity: {[key:string]: string|number}, all: any }> {
    //     // return this.externalPropertyViewModel.execDecode()
    //     return null;
    // }

    // ngOnInit() {

    //     const manager = new UICommandSettingsManager();
    //     this.addItemCommand = manager.setUICommand(CommandTypes.CreateItem,
    //         CommandFactory.createUICommand(
    //             async (x) => this.startPresentation(null, false),
    //             () => this.externalPropertyViewModel.propertyChanged.pipe(
    //                 startWith(null),
    //                 map(() => this.externalPropertyViewModel.zoomAddIsEnabled)),
    //             null,
    //             () => this.externalPropertyViewModel.propertyChanged.pipe(
    //                 startWith(null),
    //                 map(() => this.externalPropertyViewModel.zoomAddIsVisible)),
    //         ));
    //     this.addItemCommand.description =  MessageResourceManager.Current.getMessage('CMD_Create');
    //     this.viewItemCommand = manager.setUICommand(CommandTypes.ViewItem,
    //         CommandFactory.createUICommand(
    //             async (x) => this.startPresentation(null, true),
    //             () =>
    //                 merge(
    //                     this.selectedItemChanged,
    //                     this.externalPropertyViewModel.propertyChanged
    //                 ).pipe(
    //                     startWith(null),
    //                     map(() => this.selectedItem != null && this.externalPropertyViewModel.zoomViewIsEnabled)),
    //             null,
    //             () => this.externalPropertyViewModel.propertyChanged.pipe(
    //                 startWith(null),
    //                 map(() => this.externalPropertyViewModel.zoomViewIsVisible)),
    //         ));
    //     this.viewItemCommand.description = MessageResourceManager.Current.getMessage('CMD_View_Tooltip');

    //     this.copyCommand = manager.setUICommand(CommandTypes.Copy,
    //         CommandFactory.createUICommand(
    //             async (x) => {
    //                 this.copyMessage(this.showCodeInDescription ? this.getCodeAndDescription() : [this.code?.getValue(), this.decodeDescription?.getValue()].filter(s => s?.length > 0).join(' - '));
    //                 this.inputRef.close();
    //             },
    //             () => this.selectedItemChanged.pipe(
    //                 startWith(null),
    //                 map(() => this.selectedItem != null)),
    //             null,
    //             () => of(true)
    //         ));
    //     this.copyCommand.description = MessageResourceManager.Current.getMessage('CMD_CopyToClipboard_Tooltip');

    //     if (!this.externalPropertyViewModel) { throw new Error('Missing viewModel for externalPropertyViewModel!'); }

    //     if (this.autogenerateCodeDescription) {
    //         this.code = this.externalPropertyViewModel.codeProperties.values().next().value;
    //         this.decodeDescription = this.externalPropertyViewModel.automaticDecodeDescription;
    //     }

    //     if (!this.code) { throw new Error('Missing viewModel for code!'); }
    //     if (!this.decodeDescription) { throw new Error('Missing viewModel for decodeDescription!'); }

    //     this.loadItems();

    //     if (this.enableOutsideClickListener) {
    //         this.bindDocumentClickListener();
    //     }

    //     this.autocompleteMinSearchLength = this.code.autocompleteMinSearchLength;
    //     this.typeToSearchText = `Inserisci ${this.autocompleteMinSearchLength} o più caratteri`;
    //     this.cd.detectChanges();
    // }

    // ngAfterViewInit(): void {
    //     this.init();

    //     this.searchInputMouseDownListener = this.inputRef?.searchInput?.nativeElement.addEventListener('mousedown', () => {
    //         this.mouseDownOnSearchInput = true;
    //     });

    //     this.searchInputMouseUpListener = this.inputRef?.searchInput?.nativeElement.addEventListener('mouseup', () => {
    //         this.mouseDownOnSearchInput = false;
    //     });

    //     this.elementClickListener = this.inputRef?.element.addEventListener('click', () => {
    //         if (!this.code.isEnabled) {
    //             const range = document.createRange();
    //             const node = this.inputRef.element.getElementsByClassName('ng-value-label')[0];
    //             if (node) {
    //                 range.selectNode(node);
    //                 window.getSelection().removeAllRanges();
    //                 window.getSelection().addRange(range);
    //             }

    //             // this.copyCommand.execute();
    //         }
    //     });

    //     this.handleOverridesColors();
    // }

    // handleOverridesColors() {
    //     if (!this.externalPropertyViewModel.defaultColor || !this.externalPropertyViewModel.activeColor || !this.externalPropertyViewModel.hoverColor) {
    //         // devono essere impostate tutte e tre le variabili
    //         return;
    //     }

    //     this.overrideBorderColor = this.externalPropertyViewModel.defaultColor;
    //     if (this.isActive && !this.isDisabled && this.propertyViewModel.isEnabled && this.externalPropertyViewModel.isEnabled) {
    //         this.overrideBorderColor = this.externalPropertyViewModel.activeColor;
    //     }
    //     if (this.isHover && !this.isDisabled && this.propertyViewModel.isEnabled && this.externalPropertyViewModel.isEnabled) {
    //         this.overrideBorderColor = this.externalPropertyViewModel.hoverColor;
    //     }

    //     const container = this.inputRef.element.querySelector(".ng-select-container") as any;
    //     if (container){
    //         container.style.borderColor = this.overrideBorderColor
    //     }

    //     const dropdown = (this.inputRef.dropdownPanel as any)?._dropdown;
    //     if (dropdown){
    //         dropdown.style.borderColor = this.overrideBorderColor;
    //     }

    //     const dropdownFooter = (this.inputRef.dropdownPanel as any)?._dropdown?.querySelector(".ng-dropdown-footer");
    //     if (dropdownFooter){
    //         dropdownFooter.style.borderColor = this.overrideBorderColor;
    //     }
    // }

    // async onClear(e): Promise<void> {
    //     this.inputRef.close();
    //     await this.onSelectedItem(null);
    // }

    // isSelectedItemDeactivated(): boolean {
    //     const dm = this.externalPropertyViewModel.getDomainModel();
    //     return dm instanceof OCCAuditDeactivableModel ? !dm.isActive : false;
    // }

    // isItemDeactivated(item): boolean {
    //     const dm = new this.externalPropertyViewModel.externalDomainModelType();
    //     if (dm instanceof OCCAuditDeactivableModel) {
    //         if (item.all) {
    //             const obj = item?.all.find(i => i.hasOwnProperty('IsActive'));
    //             if (obj) {
    //                 return !obj.IsActive;
    //             }
    //         }
    //     }
    //     return false;
    // }

    // selectLastSearchItem(value: string): void {
    //     this.inputRef.searchInput.nativeElement.value = value;
    //     this.inputRef.searchInput.nativeElement.dispatchEvent(new Event('input'));
    // }

    // onFocus(event): void {

    //     this.isActive = true;
    //     this.handleOverridesColors();

    //     if (this.isDisabled || !this.propertyViewModel.isEnabled || !this.externalPropertyViewModel.isEnabled) {
    //         return;
    //     }
    //     // if (this.selectedItem != null && this.onFocusEnabled) {
    //     //     this.inputRef.searchInput.nativeElement.value = this.initialValue ? this.initialValue : this.code.getValue();
    //     //     this.initialValue = null;
    //     //     this.inputRef.searchInput.nativeElement.dispatchEvent(new Event('input'));
    //     //     const pos = this.inputRef.searchInput.nativeElement.value ? this.inputRef.searchInput.nativeElement.value.length : 0;
    //     //     // this.inputRef.searchInput.nativeElement.setSelectionRange(pos, pos);
    //     //     this.inputRef.searchInput.nativeElement.selectionStart = 0;
    //     //     this.inputRef.searchInput.nativeElement.selectionEnd = pos;
    //     //     // if (!this.mouseDownOnSearchInput) {
    //     //     this.inputRef.searchInput.nativeElement.select();
    //     //     // }
    //     // }
    //     if (this.selectedItem != null && this.onFocusEnabled) {
    //         this.inputRef.searchInput.nativeElement.value = this.initialValue ? this.initialValue : (this.showCodeInDescription ? this.code.getValue() : (this.decodeDescription.getValue() ?? ''));
    //         this.initialValue = null;
    //         this.inputRef.searchInput.nativeElement.dispatchEvent(new Event('input'));
    //         const pos = this.inputRef.searchInput.nativeElement.value ? this.inputRef.searchInput.nativeElement.value.length : 0;
    //         //     // this.inputRef.searchInput.nativeElement.setSelectionRange(pos, pos);
    //         this.inputRef.searchInput.nativeElement.selectionStart = 0;
    //         this.inputRef.searchInput.nativeElement.selectionEnd = pos;
    //         //     // if (!this.mouseDownOnSearchInput) {
    //         this.inputRef.searchInput.nativeElement.select();
    //         // }
    //     }
    // }

    // closeDropDown(): void {
    //     this.inputRef.close();
    // }

    // openDropDown(): void {
    //     this.inputRef.open();
    // }

    // updateButtonLabelsVisibility() {
    //     this.showButtonLabels = this.inputRef.element.clientWidth > 300;
    //     this.cd.detectChanges();
    // }

    // mouseEnter(e) {
    //     this.isHover = true;
    //     this.handleOverridesColors();
    // }

    // mouseLeave(e) {
    //     this.isHover = false;
    //     this.handleOverridesColors();
    // }

    // async onBlur(): Promise<void> {

    //     this.isActive = false;
    //     this.handleOverridesColors();

    //     this.popperError?.hide();
    //     this.popperInfo?.hide();

    //     if (this.externalPropertyViewModel.decodeInProgress || this.onSelectionInProgress) {
    //         return;
    //     }

    //     if (this.lastInputText != null &&
    //         this.lastInputText !== (this.showCodeInDescription ? this.code.getValue() : (this.decodeDescription.getValue() ?? '')) &&
    //         this.lastInputText !== ''
    //     ) {
    //         // Solo nel caso in cui il dropdown non è visibile o se è visibile non ha un elemento preselezionato
    //         if (this.inputRef?.dropdownPanel?.markedItem == null) {
    //             this.selectedItem = null;
    //             await this.externalPropertyViewModel.setCodeValue(this.lastInputText);
    //             this.valueChange.emit(this.lastInputText);
    //         } else {
    //             // devo resettare il last input text
    //             this.lastInputText = null;
    //         }
    //     }
    // }

    // private onSelectionInProgress = false;

    // async onSelectedItem(selectedItem: { description: string, identity: any, all: any }): Promise<void> {
    //     this.onSelectionInProgress = true;
    //     if (selectedItem && selectedItem.identity != null) {
    //         await this.externalPropertyViewModel.setCodeValue(selectedItem.identity);
    //     } else {
    //         await this.externalPropertyViewModel.setCodeValue(null);
    //         this.selectedItem = null;
    //         this.inputRef.searchInput.nativeElement.value = '';
    //     }
    //     this.lastInputText = '';
    //     // this.cd.detectChanges();
    //     this.onSelectionInProgress = false;
    //     this.valueChange.emit(selectedItem?.identity);

    //     this.selectedItemChanged.next();

    // }

    // trackByFn(item: { description: string, identity: any, all: any }) {
    //     return item.identity;
    // }

    // keyDownFn(event: KeyboardEvent): boolean {
    //     if (event.key === 'Tab') {
    //         // Necessario per forzare il blur nella griglia

    //         // Se ho un elemento marcato nel dropdown
    //         if (this.inputRef?.dropdownPanel?.markedItem?.value) {
    //             const markedItem = this.inputRef.dropdownPanel.markedItem.value as { description: string, identity: any, all: any }

    //             // Se l'identity è diversa da quella selezionata
    //             if (this.code.getValue()?.toString() !== markedItem.identity.toString()) {
    //                 this.onSelectedItem(markedItem);
    //             }

    //         }

    //         this.onBlur();

    //         // if (this)
    //         // setTimeout(() => this.onFocus(null))
    //     } else if (event.key === 'Del') {
    //         // TODO se si trova alla poszione zero con il cursore ed esiste un modello cancellare il modello
    //         // if (this.inputRef.)
    //     } else if (event.key === 'F6' && event.ctrlKey && this.externalPropertyViewModel.isEnabled) {
    //         this.zoom();
    //     }
    //     return true;
    // }

    // onDropdownOpen(): void {
    //     this.isOpen = true;
    //     this.updateButtonLabelsVisibility();

    //     fromEvent(document.getElementsByClassName(this.scrollElementClass), 'scroll').pipe(
    //         this.untilDestroyedThis(),
    //         takeUntil(this.dropDownClosed),
    //         throttleTime(1000)
    //     ).subscribe((e: Event) =>  {
    //         this.onFocus(null);
    //         this.inputRef.close();
    //     });

    //     setTimeout(() => {
    //         this.handleOverridesColors()
    //     })
    // }

    // onDropdownClose(): void {
    //     this.isOpen = false;
    //     this.dropDownClosed.next();
    // }



    

    // private unbindListener() {
    //     if (this.documentClickListener) {
    //         this.documentClickListener();
    //         this.documentClickListener = null;
    //     }

    //     if (this.searchInputMouseDownListener) {
    //         this.searchInputMouseDownListener();
    //         this.searchInputMouseDownListener = null;
    //     }

    //     if (this.searchInputMouseUpListener) {
    //         this.searchInputMouseUpListener();
    //         this.searchInputMouseUpListener = null;
    //     }

    //     if (this.elementClickListener) {
    //         this.elementClickListener();
    //         this.elementClickListener = null;
    //     }
    // }

    // private getCodeAndDescription() {

    //     if(this.decodeProperties.length == 0){
    //         return this.decodeDescription.getValue() == null ?
    //         `${this.code.getValue()}` :
    //         this.showCodeInDescription ? [this.code?.getValue().toString(), this.decodeDescription?.getValue()].filter(v => v?.length > 0).join(' - ') : this.decodeDescription.getValue();
    //     } else {
    //         let values = [];
    //         this.decodeProperties.forEach( p => {
    //             values.push(this.externalPropertyViewModel.getProperty(p).value.toString());
    //         })
    //         return values.filter(v => v?.length > 0).join(' - ');
    //     }
    // }

    // private copyMessage(val: string): void {
    //     const selBox = document.createElement('textarea');
    //     selBox.style.position = 'fixed';
    //     selBox.style.left = '0';
    //     selBox.style.top = '0';
    //     selBox.style.opacity = '0';
    //     selBox.value = val;
    //     document.body.appendChild(selBox);
    //     selBox.focus();
    //     selBox.select();
    //     document.execCommand('copy');
    //     document.body.removeChild(selBox);
    // }

    // private checkAsyncSelected(): void {
    //     const codeValue = this.code?.getValue();
    //     const isNullable = this.code instanceof NNumericPropertyViewModel;
    //     if (this.code?.hasErrors && this.selectedItem?.identity === codeValue) {
    //         // Se il codice ha errori, e ho già selezionato un elemento con lo stesso codice,
    //         // non faccio niente
    //     } else if (codeValue != null && (isNullable ? true : (codeValue !== 0))) {
    //         this.selectedItem = {
    //             identity: this.code.getValue(),
    //             description: this.getCodeAndDescription(),
    //             all: null
    //         };

    //     } else {
    //         this.selectedItem = null;
    //     }
    //     PopperHelper.hide(this.popperInfo);
    //     this.cd.detectChanges();
    // }

    // private loadItems(): void {

    //     // Quando termina la ricerca
    //     this.searchFinished.pipe(
    //         this.untilDestroyedThis(),
    //     ).subscribe(() => {
    //         // se esiste un marked item
    //         if (this.inputRef?.dropdownPanel?.markedItem) {
    //             // // resetto lastInputText
    //             // this.lastInputText = null;
    //             if (this.inputRef?.dropdownPanel?.markedItem?.value) {
    //                 const identity = (this.inputRef?.dropdownPanel?.markedItem?.value as { description: string, identity: any, all: any })?.identity;
    //                 if (identity) {
    //                     this.valueChange.emit(identity);
    //                 }
    //             }
    //         }
    //     });

    //     this.itemInput$.pipe(
    //         this.untilDestroyedThis(),
    //         distinctUntilChanged()
    //     ).subscribe((term) => {
    //         if (term != null) {
    //             this.lastInputText = term;
    //             // Gestisce la casistica di cancellazione senza pulire l'elemento selezionato
    //             if (term !== '') {
    //                 this.valueChange.emit(term);
    //             }
    //         }
    //     });

    //     this.items$ = concat(
    //         of([]), // default items
    //         this.itemInput$.pipe(
    //             this.untilDestroyedThis(),
    //             distinctUntilChanged(),
    //             tap((term) => {
    //                 if (term?.length > 0) {
    //                     this.itemLoading = true
    //                 }
    //             }),
    //             switchMap(
    //                 (term) => {
    //                     if (
    //                         term?.length > 0 &&
    //                         this.code?.getValue() &&
    //                         term === this.code?.getValue().toString() &&
    //                         this.decodeDescription.getValue() != null &&
    //                         !this.code.hasErrors
    //                     ) {
    //                         this.itemLoading = false;
    //                         this.cd.detectChanges();
    //                         return of([
    //                             {
    //                                 identity: this.code.getValue(),
    //                                 description: this.getCodeAndDescription(),
    //                                 all: [{
    //                                     IsActive: !this.isSelectedItemDeactivated()
    //                                 }]
    //                             }
    //                         ]);
    //                     } else if (term?.length >= this.autocompleteMinSearchLength) {

    //                         return this.externalPropertyViewModel.execAutocomplete(
    //                             term, this.language ? this.language.twoLetterISOLanguageName : null, this.showCodeInDescription
    //                         ).pipe(
    //                             map((items) => items.sort((x, y) => {

    //                                 const lowerTerm = term.toLowerCase();

    //                                 if (x.description.toLowerCase().startsWith(lowerTerm) && y.description.toLowerCase().startsWith(lowerTerm)) {
    //                                     return x.description.localeCompare(y.description);
    //                                 } else if (x.description.toLowerCase().startsWith(lowerTerm) && !y.description.toLowerCase().startsWith(lowerTerm)) {
    //                                     return -1;
    //                                 } else if (!x.description.toLowerCase().startsWith(lowerTerm) && y.description.toLowerCase().startsWith(lowerTerm)) {
    //                                     return 1;
    //                                 } else {
    //                                     // (!x.description.toLowerCase().startsWith(lowerTerm) && !y.description.toLowerCase().startsWith(lowerTerm)) {
    //                                     if (x.description.toLowerCase().indexOf(lowerTerm) > -1 && y.description.toLowerCase().indexOf(lowerTerm) == 0) {
    //                                         return -1;
    //                                     } else if (x.description.toLowerCase().indexOf(lowerTerm) == 0 && y.description.toLowerCase().indexOf(lowerTerm) > -1) {
    //                                         return 1;
    //                                     } else {
    //                                         return x.description.localeCompare(y.description);
    //                                     }
    //                                 }
    //                             })),
    //                             catchError(() => of([])), // empty list on error
    //                             tap(
    //                                 () => {
    //                                     this.itemLoading = false;
    //                                     this.cd.detectChanges();
    //                                     this.searchFinished.next();
    //                                 }
    //                             )
    //                         );
    //                     } else {
    //                         this.itemLoading = false;
    //                         this.searchFinished.next();
    //                         this.cd.detectChanges();
    //                         return of([]);
    //                     }
    //                 }
    //             )
    //         )
    //     );
    // }
}

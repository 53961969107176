import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseZoomFilterPropertyComponent } from './base-zoom-filter-property-component';

@Component({
  template: '',
  standalone: true,
})
export abstract class BaseZoomFilterPropertyTextBox<T> extends BaseZoomFilterPropertyComponent<T> implements OnInit {

  @Output()
  keyDown = new EventEmitter();

  @Output()
  focus = new EventEmitter();

  @Input()
  updateOnBlur: boolean;

  ngOnInit() {
    super.init();
    this.registerEvents();
  }

  protected abstract get inputValue(): T;

  protected validate(): boolean {
    return true;
  }

  protected registerOnBlurEvent() {
    this.input.onblur = (ev: Event) => {
      this.updateModel();

      // if (this.propertyViewModel !== undefined) {
      //   this.externalPropertyViewModel.lostFocus();
      // }

    };
  }

  protected registerOnInputEvent() {
    this.input.oninput = (ev: Event) => {
      if (!this.updateOnBlur) {
        this.updateModel();
      }
    };
  }

  protected registerOnFocusEvent() {
    this.input.onfocus = (ev: Event) => {
      this.focus.emit(ev);

      // if (this.externalPropertyViewModel !== undefined) {
      //   this.externalPropertyViewModel.gotFocus(this.propertyViewModel);
      // }
    };
  }

  protected registerOnKeyDownEvent() {
    this.input.onkeydown = (ev: KeyboardEvent) => {
      if (ev.key === 'Tab') {
        this.updateModel();
      }
      this.keyDown.emit(ev);
    };
  }


  protected registerEvents() {
    this.registerOnKeyDownEvent();
    this.registerOnBlurEvent();
    this.registerOnInputEvent();
    this.registerOnFocusEvent();
  }

  protected updateModel() {
    if (this.propertyViewModel.value !== this.inputValue) {
      this.propertyViewModel.setValue(this.inputValue);
      // Validate
      if (this.validate) {

      }
    }
  }
}

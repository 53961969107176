import { Observable, of } from 'rxjs';
import { NewModifiedViewModelState } from './new-modified-view-model-state';
import { UnchangedViewModelState } from './unchanged-view-model-state';
import { VersionedViewModelState } from './versioned-view-model-state';
import { ViewModelState } from './view-model-state';
import { ViewModelStateContextInterface } from './view-model-state-context.interface';
import { ViewModelStates } from './view-model-states';

export class NewViewModelState extends ViewModelState {
  constructor(context: ViewModelStateContextInterface) {
    super(context);
  }

  get value() {
    return ViewModelStates.New;
  }

  override canStartRelatedClient() {
    return of(this.startedAsRelatedClient);
  }

  override canStoreAndCreate(): Observable<boolean> {
    return of(!this.startedAsRelatedClient);
  }

  override storeAndCreate() {
    this.context.currentState = new NewViewModelState(this.context);
  }

  override getByIdentity() {
    this.context.currentState = new UnchangedViewModelState(this.context);
  }

  override modify() {
    this.context.currentState = new NewModifiedViewModelState(this.context);
  }

  override canRestore(): Observable<boolean> { return of(false); }

  override restore() {
    this.context.currentState = new UnchangedViewModelState(this.context);
  }

  override canStore(): Observable<boolean> {
    return of(true);
  }

  override store() {
    this.context.currentState = new UnchangedViewModelState(this.context);
  }

  override getBySnapShotIdentity() {
    this.context.currentState = new VersionedViewModelState(this.context);
  }
}

<nts-loader [isVisible]="viewModel.findInProgress || viewModel.loading" [skipHeader]="false"></nts-loader>
<nts-zoom-list [zoomListViewModel]="viewModel.zoomList" [zoomParametersViewModel]="viewModel.parameters"></nts-zoom-list>
<div class="zoom-container-box">
  <nts-expandable #parametersPanel class="parameters-wrapper"
    [title]="parametersPanelTitle"
    [isCollapsed]="!viewModel.parameterExpanderIsExpanded" [isDisabled]="!enableParametersPanel"
    (onCollapsedAdditionalFields)="viewModel.parameters.collapseAllChildrenFilters()"
    (onExpandedAdditionalFields)="viewModel.parameters.expandAllChildrenFilters()"
    (onExpanded)="onParametersExpanded()"
    (onCollapsed)="onParametersCollapsed()">

    <nts-zoom-parameters class="only-desktop" [zoomParametersViewModel]="viewModel.parameters"></nts-zoom-parameters>
    <nts-zoom-parameters-mobile class="only-mobile" [zoomParametersViewModel]="viewModel.parameters"></nts-zoom-parameters-mobile>
  </nts-expandable>
  <nts-expandable #resultsPanel class="results-wrapper"
    [title]="resultsPanelTitle"
    [disableAdditionalFields]="true"
    [isCollapsed]="!viewModel.resultsIsExpanded" (onExpanded)="onResultsExpanded()"
    (onCollapsed)="onResultsCollapsed()">
    <div class="result-wrapper-container">
      <nts-zoom-results [zoomResultsViewModel]="viewModel.results">
      </nts-zoom-results>
    </div>
  </nts-expandable>
</div>
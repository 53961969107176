import { LongOpOrchestratorViewModelInterface } from './long-op-orchestrator-view-model.interface';
import { filter, take, tap } from 'rxjs/operators';
import { CoreToolBarViewModel } from '../core-tool-bar-view-model';
import { ToolBarViewModelInterface } from '../tool-bar-view-model.interface';
import { CommandsCategory } from '../commands/commands-category';
import { UICommandInterface } from '../commands/ui-command.interface';
import { CommandFactory } from '../commands/command-factory';
import { CommandTypes } from '../commands/command-types';
import { FilledButtonType } from '../../components/shared/buttons/filled-button/filled-button.component';
import { firstValueFrom } from 'rxjs';

export class LongOpToolBarViewModel extends CoreToolBarViewModel implements ToolBarViewModelInterface {

    override get orchestrator(): LongOpOrchestratorViewModelInterface {
        return this._orchestrator as LongOpOrchestratorViewModelInterface;
    }

    async buildMenu(defaultCategory: CommandsCategory): Promise<void> {
        // aspetto il caricamento dei meta dati
        await firstValueFrom(this.orchestrator.eventDispatcher.onMetaDataLoaded.pipe(filter((loaded) =>loaded), take(1)));
        
        this.addHomeCommandGroup();
        this.addActionCommandGroup();
        
        // caricamento asincrono per i report (senza await)
        firstValueFrom(this.addReportCommandGroup().pipe(tap((command) => this.menuItemChanged.next(command))));

        await this.addRelatedCommandGroup();
        this.addCustomCommandGroups();
        this.addCustomPages();
        
        // caricamento asincrono per il menù altro (senza await)
        firstValueFrom(this.addAdditionalCommandsGroups().pipe(tap((command) => this.menuItemChanged.next(command))));
        
        this.setDefaultCommand();
    }

    protected override setDefaultCommand() {
        this._defaultCommand = this.getExecuteLongOpCommand();
        this._defaultCommandType = FilledButtonType.Success;
    }

    protected getExecuteLongOpCommand(): UICommandInterface {
        return CommandFactory.createUICommandWithType(
            CommandTypes.ExecuteLongOp,
            async (x) => { await this.orchestrator.executeLongOp(); },
            () => this.orchestrator.canExecuteLongOp(),
            null,
            () => this.orchestrator.isVisibleExecuteLongOp()
        )
    }
}

<div class="main-container" #labelledContainer 
    [class.full-width]="isFullWidth">
    <div class="label-container" [class.full-width]="isFullWidth">
        <nts-label-box [isRequired]="showLabelRequired ? propertyViewModel.isRequired : false"
            [title]="propertyViewModel.metadataDescription"
            [label]="propertyViewModel.metadataShortDescription"></nts-label-box>
    </div>

    <div class="edit-container">
        <nts-numeric-text-box
            *ngIf="isNumeric"
            [isDisabled]="isDisabled"
            [customCommandsName]="customCommandsName" 
            [propertyViewModel]="numericPropertyViewModel" 
            [tabIndex]="tabIndex" 
            [updateOnBlur]="!propertyViewModel.instantModelChange">
        </nts-numeric-text-box>
        
        <nts-enum-text-box 
            *ngIf="isEnum"
            [isDisabled]="isDisabled" 
            [customCommandsName]="customCommandsName" 
            [appendTo]="appendToBody ? 'body': null" 
            [propertyViewModel]="enumPropertyViewModel" 
            [tabIndex]="tabIndex">
        </nts-enum-text-box>

        <nts-radio-button-text-box 
            *ngIf="isRadio"
            [isDisabled]="isDisabled"  
            [propertyViewModel]="enumPropertyViewModel"
            [tabIndex]="tabIndex">
        </nts-radio-button-text-box>
        
        <nts-toggle-text-box 
            *ngIf="isBool && useToggle"
            [isDisabled]="isDisabled" 
            [propertyViewModel]="propertyViewModel"
            [tabIndex]="tabIndex">
        </nts-toggle-text-box>

        <nts-checkbox-text-box 
            *ngIf="isBool && !useToggle"
            [isDisabled]="isDisabled" 
            [propertyViewModel]="propertyViewModel" 
            [tabIndex]="tabIndex">
        </nts-checkbox-text-box>

        <nts-date-text-box 
            *ngIf="isDate"
            [isDisabled]="isDisabled"
            [customCommandsName]="customCommandsName"
            [propertyViewModel]="datePropertyViewModel" 
            [appendTo]="appendToBody ? 'body': labelledContainer"
            [tabIndex]="tabIndex">
        </nts-date-text-box>

        <nts-string-text-box 
            *ngIf="isString"
            [isDisabled]="isDisabled"
            [useChips]="useChips"
            [chipsOptions]="chipsOptions"
            [customCommandsName]="customCommandsName"
            [multiline]="multiline" 
            [propertyViewModel]="stringPropertyViewModel"
            [tabIndex]="tabIndex"
            [updateOnBlur]="!propertyViewModel.instantModelChange"></nts-string-text-box>

        <nts-time-span-text-box 
            *ngIf="isTimeSpan"
            [isDisabled]="isDisabled" 
            [customCommandsName]="customCommandsName"
            [propertyViewModel]="timeSpanPropertyViewModel"
            [tabIndex]="tabIndex"></nts-time-span-text-box >
        
        <nts-string-text-box 
            *ngIf="isGuid"
            [isDisabled]="isDisabled" 
            [customCommandsName]="customCommandsName"
            [propertyViewModel]="stringPropertyViewModel" 
            [tabIndex]="tabIndex"
            [updateOnBlur]="!propertyViewModel.instantModelChange"></nts-string-text-box>
        
        <nts-date-offset-text-box 
            *ngIf="isDateTimeOffset"
            [isDisabled]="isDisabled"
            [customCommandsName]="customCommandsName"
            [propertyViewModel]="dateTimeOffsetPropertyViewModel"
            [appendTo]="appendToBody ? 'body': labelledContainer" 
            [tabIndex]="tabIndex"></nts-date-offset-text-box>
        
        <nts-enum-text-box 
            *ngIf="isOffset"
            [isDisabled]="isDisabled" 
            [customCommandsName]="customCommandsName" 
            [appendTo]="appendToBody ? 'body': null" 
            [propertyViewModel]="enumPropertyViewModel" 
            [tabIndex]="tabIndex">
        </nts-enum-text-box>
    </div>
</div>
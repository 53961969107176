import { Injectable } from '@angular/core';
import { ServiceInfo } from './service-info';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';

@Injectable()
export class EndPointResolver {

    constructor(
        private readonly environmentConfiguration: EnvironmentConfiguration
    ) { }

    getWebApiUriForRootDomainModel(rootDomainModelName: string): ServiceInfo {

        const url = this.environmentConfiguration.baseAppUrl.replace(/\/$/, "") + // Remove trailing slash
            this.environmentConfiguration.prefixApi.replace(/\/$/, "") + // Remove trailing slash
            '/' + rootDomainModelName;

        const serviceInfo = new ServiceInfo();

        serviceInfo.address = url;
        serviceInfo.envType = this.environmentConfiguration.envType;

        return serviceInfo;

    }

    getWebApiUriForFrameworkService(): ServiceInfo {

        const url = this.environmentConfiguration.baseAppUrl.replace(/\/$/, "") + // Remove trailing slash 
            this.environmentConfiguration.prefixApi.replace(/\/$/, "") + // Remove trailing slash
            '/FrameworkService';

        const serviceInfo = new ServiceInfo();

        serviceInfo.address = url;
        serviceInfo.envType = this.environmentConfiguration.envType;

        return serviceInfo;

    }
}

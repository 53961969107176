import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { merge } from "rxjs";
import { ZoomDataDto } from "../../../domain-models/zoom/dto/zoom-data.dto";
import { UICommandInterface } from "../../../view-models/commands/ui-command.interface";
import { ZoomListViewModel } from "../../../view-models/zoom/zoom-list-view-model/zoom-list-view-model";
import { ZoomParametersViewModel } from "../../../view-models/zoom/zoom-parameters-view-model";
import { FilledButtonComponent, FilledButtonType } from "../../shared/buttons/filled-button/filled-button.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from "@angular/forms";
import { TextButtonComponent } from "../../shared/buttons/text-button/text-button.component";
import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { SvgIconComponent } from "@ngneat/svg-icon";
import { BaseTextBoxComponent } from "../../controls/core/base/base-text-box/base-text-box.component";
import { RibbonButtonComponent } from "../../shared/buttons/ribbon-button/ribbon-button.component";

@UntilDestroy()
@Component({
    selector: 'nts-zoom-list',
    styleUrls: ['zoom-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './zoom-list.component.html',
    standalone: true,
    imports: [
        NgSelectModule,
        FormsModule,
        TextButtonComponent,
        AsyncPipe,
        SvgIconComponent,
        BaseTextBoxComponent,
        RibbonButtonComponent,
        FilledButtonComponent,
        NgFor,
        NgIf
    ]
  })
  export class ZoomListComponent implements OnInit, AfterViewInit {
    
    @Input() zoomListViewModel!: ZoomListViewModel;
    @Input() zoomParametersViewModel!: ZoomParametersViewModel;
    @ViewChild('findCommand', { static: true }) findCommand!: FilledButtonComponent;
    
    filterIcon = 'filter';
    filledButtonTypeEnum = FilledButtonType
    firstGroupCommands: UICommandInterface[] = [];
    firstGroupCommandList: { 
        isEnabled: boolean, 
        isVisible: boolean
    }[] = [];

    secondGroupCommands: UICommandInterface[] = [];
    secondGroupCommandList: { 
        isEnabled: boolean, 
        isVisible: boolean
    }[] = [];

    thirdGroupCommands: UICommandInterface[] = [];
    thirdGroupCommandList: { 
        isEnabled: boolean, 
        isVisible: boolean
    }[] = [];

    groupByFn = (item: ZoomDataDto) => 
        item.isPreferred === true || 
        item.title === this.zoomListViewModel.standardZoomData.title ?  'Predefiniti' : 'Altri';

    constructor(
        private readonly cd: ChangeDetectorRef,
    ) {

    }
    
    ngAfterViewInit(): void {
        this.zoomParametersViewModel.findCommandFocus.pipe(untilDestroyed(this)).subscribe(()=> {
            this.findCommand.button.nativeElement.focus();
        });
    }

    ngOnInit(): void {

        this.firstGroupCommands = [
            this.zoomListViewModel.starCommand, 
            this.zoomListViewModel.unstarCommand,
            this.zoomListViewModel.editCommand
        ];
        this.detectChangesForCommands(this.firstGroupCommands, this.firstGroupCommandList);

        this.secondGroupCommands = [this.zoomListViewModel.storeCommand];
        this.detectChangesForCommands(this.secondGroupCommands, this.secondGroupCommandList);

        this.thirdGroupCommands = [this.zoomListViewModel.pinToDashboardCommand];
        this.detectChangesForCommands(this.thirdGroupCommands, this.thirdGroupCommandList);

        merge(
            this.zoomListViewModel.selectedZoomDataChanged,
            this.zoomListViewModel.selectedZoomDataPropertyChanged
        ).pipe(untilDestroyed(this)).subscribe(() => {
            this.cd.detectChanges();
        })

        this.updateMainFilterIcon();

        this.cd.detectChanges();
    }

    detectChangesForCommands(commands: UICommandInterface[], commandList: { 
        isEnabled: boolean, 
        isVisible: boolean
    }[]) {

        commands.map(() => {
            commandList.push({ 
                isEnabled: false, 
                isVisible: false
            });
        })

        commands.forEach((command, index) => {
            command.canExecute$.pipe(untilDestroyed(this)).subscribe((can) => {
                commandList[index].isEnabled = can as any;
                this.cd.detectChanges();
            })
            command.isVisible$.pipe(untilDestroyed(this)).subscribe((can) => {
                commandList[index].isVisible = can as any;
                this.cd.detectChanges();
            })
        })
    }

    onOpen(e: any) {

    }

    onChange(e: any) {
        this.updateMainFilterIcon()
        this.zoomListViewModel.selectedZoomDataChanged.next();
    }

    updateMainFilterIcon(){
        this.filterIcon = this.getFilterIconFromZoomData(this.zoomListViewModel.selectedZoomData);
    }

    getFilterIconFromZoomData(zommDataDto: ZoomDataDto): string{
        if(zommDataDto.zoomIdentity.zoomId == 0){
            return 'filter';
        } else {
            return zommDataDto.roleId > 0 ? 'group' : 'user';
        }
    }

  }
<!-- <nts-base-numeric-box #baseNumericBox
    [value]="propertyViewModel.value"
    [isDisabled]="!propertyViewModel.enabled"
    (onFocus)="selectAllContent($event)"
    [maskSettings]="this.inputMaskSettings" [minValue]="this.minValue"
    [maxValue]="this.maxValue"></nts-base-numeric-box> -->
<nts-base-external-box 
    #baseExternalBox 
    [viewItemCommand]="viewItemCommand"
    [showCodeInDescription]="true"
    [scrollElementClass]="'parameter-column parameter-values'"
    [getItemFromIdentityCallback]="getItemFromIdentityCallback.bind(this)"
    [getItemsFromTermCallback]="getItemsFromTermCallback.bind(this)"
    [getExactItemFromTermCallback]="getExactItemFromTermCallback.bind(this)"
    [isDropdownOpen]="isDropdownOpen"
    [placeholder]="placeholder"
    [isLocked]="isLocked"
    [isLockedMessage]="isLockedMessage"
    [basePlainPascalCaseFixedIdentity]="basePlainPascalCaseFixedIdentity"
    [isDisabled]="!propertyViewModel.enabled" 
    (valueChange)="valueChange($event)"
    (onBlur)="onBlur()"
    [value]="updatedExternalValue | async">
</nts-base-external-box>

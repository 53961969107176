import { MessageFormatter } from './message-formatter';
import { CodeValueMessageArg } from './code-value-message-arg';

export class MessageResourceManager {

    private static instance: MessageResourceManager;
    translations: {} = {};

    static get Current(): MessageResourceManager {
        if (this.instance == null) {
            this.instance = new MessageResourceManager();
        }
        return this.instance;
    }

    getMessageWithArgs(key: string, args: Array<CodeValueMessageArg> = new Array<CodeValueMessageArg>()) {
        let message = this.getMessage(key);
        args.forEach(arg => {
            message = message.split('{' + arg.code.replace('std_', '') + '}').join(arg.value);
        });

        return message;
    }

    getMessageWithStrings(key: string, ...args: string[]) {
        let message = this.getMessage(key);
        if (args != null) {
            message = MessageFormatter.formatString(message, ...args);
        }
        return message;
    }

    /**
     * ATTENZIONE questa funzione al momento non ha logica di fallback su std
     * deve essere passat espressamente il prefisso std_
     */
    getMessageIfExists(key: string, ...args: string[]) {
        const result = this.getMessage(key);
        if (result !== this.errorMessage(key)) {
            return result;
        }

        return '';
    }

    /**
     * Recupera il messaggio dalle risorse in base alla chiave passata.
     * Se la chiave passata non ha il prefisso std_ viene prima verificata se nelle
     * risorse dell'applicazione è presente la chiave e in caso fa il fallback sulla stessa chiave ma con prefisso std_
     * (verifica quindi anche le risorse di std)
     */
    getMessage(key: string): string {
        // Create a resource manager to retrieve resources.

        // Retrieve the value of the string resource
        // localized for the culture specified by ci.

        if (!key) {
            return '';
        }

        let message = null;
        message = this.translations[key];
        if (message == null) {
            if (key.startsWith('std_')) {
                return this.errorMessage(key);
            } else {
                message = this.translations['std_' + key];
                if (message == null) {
                    return this.errorMessage(key);
                }
            }
        }

        return message;
    }

    getMessageNoFaultback(key: string): any {
        // Retrieve the value of the string resource
        // if lo localized not found return null (used enum/radio tooltip)

        if (!key) {
            return null;
        }

        let message = null;
        message = this.translations[key];
        return message;
    }

    private errorMessage(key: string): string {
        return MessageFormatter.formatNumber('Localization code \'{0}\' not found', key);
    }
}



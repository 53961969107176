import { registerDecorator, ValidationArguments, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator';
import { BaseValidator } from './commons/base-validator';
import { BaseValidationDecoratorInterface } from './commons/base-validation-decorator.interface';
import { BaseDecoratorInterface } from './commons/base-decorator.interface';
import { EnumMetaData } from '../../meta-data/enum-meta-data';
import { MetaDataUtils } from '../../meta-data/meta-data-utils';
import { EnumResource } from '../../meta-data/enum-resource';
import { RequiredDecoratorInterface } from './commons/required-decorator.interface';

export interface EnumDecoratorInterface extends BaseDecoratorInterface, RequiredDecoratorInterface {
    valuesResource?: EnumResource[];
}

@ValidatorConstraint({ name: 'enumValidator', async: false })
export class EnumValidator extends BaseValidator<any> implements ValidatorConstraintInterface {

    protected static override getPropertyMetaDataFromDecoratorData<TDecoratorData extends BaseValidationDecoratorInterface>(
        decoratorData: TDecoratorData, propertyName: string) {
        const propertyMetaData = new EnumMetaData();
        propertyMetaData.context = decoratorData.context;
        propertyMetaData.isRequired = (decoratorData as EnumDecoratorInterface).isRequired;
        propertyMetaData.descriptions.descriptionKey = (decoratorData as EnumDecoratorInterface).descriptionKey;
        propertyMetaData.descriptions.displayNameKey = (decoratorData as EnumDecoratorInterface).displayNameKey;
        propertyMetaData.valuesResource = (decoratorData as EnumDecoratorInterface).valuesResource;
        propertyMetaData.name = MetaDataUtils.toPascalCase(propertyName);
        return propertyMetaData;
    }

    override validate(value: any, args: ValidationArguments): boolean {
        return super.validate(value, args);
    }
}

export function EnumDecorator(decoratorInterface: EnumDecoratorInterface) {
    return (object: object, propertyName: string) => {

        // Metodo di base per tutti i decoratori
        EnumValidator.initBaseValidator(decoratorInterface, object, propertyName);

        // Aggiunge informazioni alla property sulla validazione sul tipo della classe
        EnumValidator.buildPropertyMetaData<EnumDecoratorInterface>(
            object.constructor, propertyName, decoratorInterface);
        // Aggiunge informazioni alla property sulla validazione sull'instanza della classe
        EnumValidator.buildPropertyMetaData<EnumDecoratorInterface>(
            object, propertyName, decoratorInterface);

        registerDecorator({
            target: object.constructor,
            propertyName,
            options: {context: decoratorInterface.context},
            constraints: [decoratorInterface],
            validator: EnumValidator
        });
    };
}

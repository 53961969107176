<a [routerLink]="[]" dropdownToggle class="nav-link notification-toggle nav-link-lg" [class.beep]="notifyList.length > 0">
    <fa-icon [icon]="['far', 'bell']"></fa-icon>
</a>
<div class="dropdown-menu dropdown-list dropdown-menu-right" *dropdownMenu>
    <div class="dropdown-header">Notifications
        <div class="float-right">
            <a [routerLink]="[]" (click)="markAllNotifyAsRead($event)">Mark All As Read</a>
        </div>
    </div>
    <div class="dropdown-list-content dropdown-list-icons">
        <ng-scrollbar>
            <a [routerLink]="[]" (click)="removeNotify(notify.id)" class="dropdown-item dropdown-item-unread"
                *ngFor="let notify of notifyList">
                <div class="dropdown-item-icon text-white" [ngClass]="notify.type">
                    <fa-icon [icon]="notify.icon"></fa-icon>
                </div>
                <div class="dropdown-item-desc">
                    <span [innerHTML]="notify.text"></span>
                    <div class="time text-primary" [innerHTML]="notify.beutyTime()"></div>
                </div>
            </a>
        </ng-scrollbar>
    </div>
    <div class="dropdown-footer text-center">
        <a href="#">View All <fa-icon [icon]="['fas', 'chevron-right']"></fa-icon></a>
    </div>
</div>

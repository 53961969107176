import { CoreToolBarViewModel } from './core-tool-bar-view-model';
import { CommandsCategory } from './commands/commands-category';
import { UICommandInterface } from './commands/ui-command.interface';
import { CommandFactory } from './commands/command-factory';
import { CommandTypes } from './commands/command-types';
import { ToolBarViewModelInterface } from './tool-bar-view-model.interface';
import { OrchestratorViewModelInterface } from './orchestrator-view-model.interface';
import { filter, take, tap } from 'rxjs/operators';
import { FilledButtonType } from '../components/shared/buttons/filled-button/filled-button.component';
import { firstValueFrom } from 'rxjs';

export class ToolBarViewModel extends CoreToolBarViewModel implements ToolBarViewModelInterface {

    override get orchestrator(): OrchestratorViewModelInterface {
        return this._orchestrator as OrchestratorViewModelInterface;
    }

    async buildMenu(defaultCategory: CommandsCategory): Promise<void> {
        // aspetto il caricamento dei meta dati
        await firstValueFrom(this.orchestrator.eventDispatcher.onMetaDataLoaded.pipe(filter((loaded) =>loaded), take(1)));

        this.addHomeCommandGroup();
        this.addActionCommandGroup();

        // caricamento asincrono per i report (senza await)
        firstValueFrom(this.addReportCommandGroup().pipe(tap((command) => this.menuItemChanged.next(command))));

        await this.addRelatedCommandGroup();
        this.addCustomCommandGroups();
        this.addCustomPages();

        // caricamento asincrono per il menù altro (senza await)
        firstValueFrom(this.addAdditionalCommandsGroups().pipe(tap((command) => this.menuItemChanged.next(command))));

        this.addSidebarCommandGroup();
        this.setDefaultCommand();
    }

    protected override setDefaultCommand() {
        this._defaultCommand = CommandFactory.createUICommandWithType(
            CommandTypes.Store,
            async (x) => { await this.orchestrator.store(); },
            () => this.orchestrator.canStore(),
            null,
            () => this.orchestrator.isVisibleStore()
        );
        this._defaultCommandType = FilledButtonType.Success;
    }

    protected override addHomeCommands(): Array<UICommandInterface> {
        const items = super.addHomeCommands();

        items.push(
            CommandFactory.createUICommandWithType(
                CommandTypes.Create,
                async (x) => { await this.orchestrator.create(); },
                () => this.orchestrator.canCreate(),
                null,
                () => this.orchestrator.isVisibleCreate()
            )
        );

        items.push(
            CommandFactory.createUICommandWithType(
                CommandTypes.Find,
                (x) => this.orchestrator.find(),
                () => this.orchestrator.canFind(),
                null,
                () => this.orchestrator.isVisibleFind()
            )
        );

        items.push(
            CommandFactory.createUICommandWithType(
                CommandTypes.Restore,
                (x) => this.orchestrator.restore(),
                () => this.orchestrator.canRestore(),
                null,
                () => this.orchestrator.isVisibleRestore()
            )
        );

        items.push(
            CommandFactory.createUICommandWithType(
                CommandTypes.Remove,
                (x) => this.orchestrator.remove(),
                () => this.orchestrator.canRemove(),
                null,
                () => this.orchestrator.isVisibleRemove()
            )
        );

        items.push(
          CommandFactory.createUICommandWithType(
              CommandTypes.PinToDashboard,
              async (x) => { await this.orchestrator.pinIdentityToDashboard()},
              () => this.orchestrator.canPinIdentityToDashboard(),
              null,
              () => this.orchestrator.isVisiblePinIdentityToDashboard()
          )
        );

        /*items.push(
            CommandFactory.createUICommandWithType(
                CommandTypes.Validate, (x) => this.orchestrator.fullValidate(),
                (x) => this.orchestrator.canValidate(x)));*/

        return items;
    }

    protected override addSidebarCommands(): Array<UICommandInterface> {
        const items = super.addSidebarCommands();

        items.push(
            CommandFactory.createUICommandWithType(
                CommandTypes.ToggleLeftSidebar,
                (x) => this.orchestrator.toggleLeftSideBar(),
                () => this.orchestrator.canToggleLeftSideBar(),
                (x) => this.orchestrator.isHighlightedToggleLeftSideBar(x),
                () => this.orchestrator.isVisibleToggleLeftSideBar(),
            )
        );

        items.push(
            CommandFactory.createUICommandWithType(
                CommandTypes.ToggleRightSidebar,
                (x) => this.orchestrator.toggleRightSideBar(),
                () => this.orchestrator.canToggleRightSideBar(),
                (x) => this.orchestrator.isHighlightedToggleRightSideBar(x),
                () => this.orchestrator.isVisibleToggleRightSideBar(),
            )
        );

        items.push(
            CommandFactory.createUICommandWithType(
                CommandTypes.ToggleSnapShot,
                (x) => this.orchestrator.toggleSnapShotSideBar(),
                () => this.orchestrator.canToggleSnapShotSideBar(),
                (x) => this.orchestrator.isHighlightedToggleSnapShotSideBar(x),
                () => this.orchestrator.isVisibleToggleSnapShotSideBar(),
            )
        );

        return items;
    }
}

import { NTSReflection } from '@nts/std/src/lib/utility';
import { NotString } from '../../../types';

const NM_ITEMS_TYPE_META_DATA_IDENTIFICATION_KEY = 'nmItemsTypeDecoratorMetaDataKey';

export function NmItemsTypeDecorator<T>(nmItemsType: NotString<T>) {
    return (target: any) => {
        Reflect.defineMetadata(NM_ITEMS_TYPE_META_DATA_IDENTIFICATION_KEY, nmItemsType, target);
    };
}

export class NmItemsTypeInspector {

    static META_DATA_KEY = NM_ITEMS_TYPE_META_DATA_IDENTIFICATION_KEY;
    static DECORATOR_NAME = '@NmItemsTypeDecorator';

    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            NM_ITEMS_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): any {
        return NTSReflection.getClassMetadata(
            NM_ITEMS_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }
}

import { Expose } from '../serialization/class-transformer/decorators';
import { ServiceRequest } from './service-request';

export class MetaDataRequest extends ServiceRequest {

    @Expose()
    includeDescriptions: boolean;

    @Expose()
    includeSecurityMetaData: boolean;
}

import { ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { BaseContainerComponent } from '../base-container/base-container.component';
import { RoutingService } from '../../../routing/routing.service';
import { TokenService } from '../../../auth/token.service';
import { OnlineService, UpdateService } from '@nts/std/src/lib/utility';
import { ToastMessageService } from '../toast-message/toast-message.service';
import { AuthService } from '../../../auth/auth.service';
import { BaseError } from '../../../messages/base-error';
import { TelemetryService } from '@nts/std/src/lib/telemetry';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { NgIf } from '@angular/common';
import { CurrentRouteService } from '../../../routing/current-route.service';

@UntilDestroy()
@Component({
  selector: 'nts-empty-container',
  templateUrl: './empty-container.component.html',
  styleUrls: ['./empty-container.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    NgIf
  ]
})
export class EmptyContainerComponent extends BaseContainerComponent implements OnInit {

  @ViewChild('documentPlaceHolder', { read: ViewContainerRef, static: true }) override documentPlaceHolder: ViewContainerRef;

  constructor(
    protected override readonly routingService: RoutingService,
    protected override readonly activatedRoute: ActivatedRoute,
    protected override readonly tokenService: TokenService,
    protected override readonly toastMessageService: ToastMessageService,
    protected override readonly updateService: UpdateService,
    protected override readonly onlineService: OnlineService,
    protected override readonly authService: AuthService,
    protected override readonly telemetryService: TelemetryService,
    protected override readonly environmentConfiguration: EnvironmentConfiguration,
    protected override readonly cd: ChangeDetectorRef,
    protected override readonly componentFactoryResolver: ComponentFactoryResolver,
    protected override readonly currentRouteService: CurrentRouteService
  ) {
    super(
      routingService, 
      activatedRoute, 
      tokenService, 
      toastMessageService, 
      updateService, 
      onlineService,
      authService,
      telemetryService,
      environmentConfiguration,
      cd,
      componentFactoryResolver,
      currentRouteService
    )
  } 

  override ngOnInit(): void {
    super.ngOnInit();
  }

  protected untilDestroyed() {
    return untilDestroyed(this);
  }
}

import { AgRendererComponent } from 'ag-grid-angular';
import { ExternalColumnCellRendererParams } from './ext_column_cell_renderer_params.interface';
import { ExternalViewModelInterface } from '../../../../view-models/external-view-model.interface';
import { BasePropertyViewModel } from '../../../../view-models/base-property-view-model';
import { MetaDataUtils } from '../../../../meta-data/meta-data-utils';

export abstract class BaseExternalCellRendererComponent implements AgRendererComponent {
    protected params: ExternalColumnCellRendererParams;

    private _propertyViewModel: BasePropertyViewModel;
    private _externalPropertyViewModel: ExternalViewModelInterface;

    get externalPropertyViewModel(): ExternalViewModelInterface {
        if (!this._externalPropertyViewModel) { 
            return this.params.data[this.params.columnInfo.path];
        }
        return this._externalPropertyViewModel;
    }

    get propertyViewModel(): BasePropertyViewModel {
        if (!this._propertyViewModel) {
            if (this.params.columnInfo.propertyTypeName.indexOf('ExternalCode') > -1) {

                // Campo Code
                const code = this.params.columnInfo.fieldName.split('.').reduce(
                    (o, i) => {
                        if (i === 'value') {
                            return o;
                        }
                        return o[i];
                    }, this.params.data) as BasePropertyViewModel;
                const associationMetaData = this.externalPropertyViewModel.externalMetaData.associationProperties.find((ass) =>
                    MetaDataUtils.toCamelCase(ass.dependentPropertyName) === code.propertyName
                );
                const backingFieldPropertyName = MetaDataUtils.toCamelCase(associationMetaData.principalPropertyName);
                const backingField = this.params.data[backingFieldPropertyName];
                if (!backingField) {
                    throw new Error(`ATTENZIONE: il backing field ${backingFieldPropertyName} non è stato trovato nella classe ${this.params.data.constructor.name}, questo comporta dei malfunzionamenti nella griglia!`)
                }
                this._propertyViewModel = backingField;
                this._externalPropertyViewModel = code.parent as ExternalViewModelInterface;
            } else {
                // Campo decode
                this._propertyViewModel = this.params.columnInfo.fieldName.split('.').reduce(
                    (o, i) => {
                        if (i === 'value') {
                            return o;
                        }
                        return o[i];
                    }, this.params.data);
            }
        }
        return this._propertyViewModel;
    }

    agInit(params: any): void {
        this.params = params;
    }

    getValue() {
        return this.params.value;
    }

    refresh(params: any): boolean {
        return true;
    }

    get cellHeight() {
        return this.params.eGridCell.clientHeight;
    }

}

import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ZoomResultsColumnCellRendererParams } from './zoom-results-column-cell-renderer-params.interface';
import { NgIf } from '@angular/common';
import { BaseCheckboxTextBoxComponent } from '../../controls/core/base/base-checkbox-text-box/base-checkbox-text-box.component';
// create your cellEditor as a Angular component
@Component({
    standalone: true,
    imports: [
        NgIf,
        BaseCheckboxTextBoxComponent
    ],
    template: `
        <div  *ngIf="this.params?.data; else loading" style="display: flex; height: 100%; align-items: center; justify-content: center;">
            <nts-base-checkbox-text-box 
                (onClick)="onClick()"
                [isDisabled]="true"
                [checkStatus]="value">
            </nts-base-checkbox-text-box>
        </div>
        <ng-template #loading><div class="lds-ring"><div></div><div></div><div></div><div></div></div></ng-template>
    `,
    styles: [`

        nts-base-checkbox-text-box ::ng-deep .checkbox-wrapper:focus-within { 
            outline: none;
            outline-color: unset;
            outline-width: 0;
            background-color: unset;
            border-radius: 0;
        }
    `],
})
export class ZoomResultsBoolCellRendererComponent implements AgRendererComponent {
    params: ZoomResultsColumnCellRendererParams;
    refresh(params: any): boolean {
        return true;
    }
    agInit(params: ZoomResultsColumnCellRendererParams): void {
        this.params = params;
    }

    onClick() {
        this.params.node.setSelected(true, true);
    }

    get value() {
        return this.params.data[Number.parseInt(this.params.colDef.field, 10)];
    }
}

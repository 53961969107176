import { NgFor } from '@angular/common';
import { EnumItem, EnumItemInterface } from './../../../../../view-models/base-type/enum-property-view-model';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from "@angular/core";
import { FormsModule, NgModel } from '@angular/forms';
import { NgxPopperjsDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { PopperHelper } from '@nts/std/src/lib/utility';

let nextId = 0;

@Component({
    selector: 'nts-base-radio-button-text-box',
    templateUrl: './base-radio-button-text-box.component.html',
    styleUrls: ['./base-radio-button-text-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgxPopperjsModule,
        NgFor,
        FormsModule,
    ]
})
export class BaseRadioButtonTextBoxComponent implements OnChanges {
    @Input() label = '';
    @Input() tabIndex = -1;
    @Input() isDisabled = false;
    @Input() isNullable = false;
    @Input() value: EnumItemInterface;
    @Input() options: Array<EnumItemInterface>;
    @Input() customClasses = '';
    @Input() errorList: string[] = [];
    @Input() showErrorTooltip = true;
    @Input() showErrorBorder = true;
    @Input() hasError = false;
    @Input() primaryColor = null;
    @Input() placeholder = "";

    @Output() onChange = new EventEmitter<EnumItemInterface>();

    @ViewChildren(NgModel) modelRefList: QueryList<NgModel>;
    @ViewChild('popperError', { static: true }) popperError: NgxPopperjsDirective;
    @ViewChild('popperInfo', { static: true }) popperInfo: NgxPopperjsDirective;

    name = `rdb-${nextId++}`;
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;

    constructor(protected readonly cd: ChangeDetectorRef) { }


    ngOnInit() {
        if (this.isNullable) {
            const clear = new EnumItem(null, this.placeholder);
            this.options.unshift(clear);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['errorList']) {
            this.checkPopper();
        }
    }

    keydownArrowUp(e: any, option: EnumItemInterface): void {
        // e.preventDefault();
        // e.stopPropagation();
        this.findPrevOption(option);
    }

    keydownArrowLeft(e: any, option: EnumItemInterface): void {
        // e.preventDefault();
        // e.stopPropagation();
        this.findPrevOption(option);
    }

    findPrevOption(option: EnumItemInterface): void {
        const prevOption = this.findNewOption('prev', this.options.indexOf(option));
        this.changeOption(prevOption);
    }

    keydownArrowDown(e: any, option: EnumItemInterface): void {
        //e.preventDefault();
        //e.stopPropagation();
        this.findNextOption(option);
    }

    keydownArrowRight(e: any, option: EnumItemInterface): void {
        //e.preventDefault();
        //e.stopPropagation();
        this.findNextOption(option);
    }

    findNextOption(option: EnumItemInterface): void {
        const prevOption = this.findNewOption('next', this.options.indexOf(option));
        this.changeOption(prevOption);
    }

    findNewOption(searchMode: string, startIndex: number, index = -1): EnumItemInterface {
        // esco, sono tornato alla partenza
        if (startIndex === index) {
            return this.options[startIndex];
        }
        // prima ricorsione start e index sono uguali
        if (index === -1) {
            index = startIndex;
        }
        let newIndex = index;
        if (searchMode === 'prev') {
            // prev
            newIndex = (index + this.options.length - 1) % this.options.length;
        } else {
            // next
            newIndex = (index + 1) % this.options.length;
        }
        const option = this.options[newIndex];
        if (option.disabled) {
            return this.findNewOption(searchMode, startIndex, newIndex);
        }
        return option;
    }

    click(e: any, option: EnumItemInterface): void {
        if (!this.isDisabled) {
            e.preventDefault();
            e.stopPropagation();
            this.changeOption(option);
        }
    }

    changeOption(option: EnumItemInterface): void {
        if (!this.isDisabled && !option.disabled && this.value !== option) {
            this.value = option;
            //const element = this.modelRefList.find(x => { return x.value == option.key });
            this.onChange.emit(option);
            this.cd.detectChanges();
        }
    }

    private checkPopper() {
        if (this.popperError && (this.errorList == null || this.errorList.length === 0)) {
            PopperHelper.hide(this.popperError);
        }
        if (this.popperInfo && this.errorList?.length > 0) {
            PopperHelper.hide(this.popperInfo);
        }
    }
}

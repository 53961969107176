import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { EnterpriseDataDto } from './dto/enterprise-data.dto';

@Injectable()
export class CurrentSessionService {

    constructor(private readonly authService: AuthService) {}

    async getUserName(): Promise<string> {
        return this.authService.getUserName();
    }

    async getEnterpriseData(): Promise<EnterpriseDataDto> {
        const tenantId = await this.authService.getTenantId();
        if (tenantId > 0) {
            return this.authService.getEnterpriseData(tenantId);
        }
        return null;        
    }
}
